import { makeStyles } from "@material-ui/core/styles"

import FormikSelectField from "common/Formik/FormikSelectField"

type Props = {
  theme: string
  name: string
  label?: string
  placeholder: string
  children?: React.ReactNode
  type?: string
  required?: boolean
  multiline?: boolean
  rows?: number
  multiple?: boolean
}

const CustomSelectField: React.FC<Props> = ({
  theme,
  name,
  label,
  placeholder,
  children,
  type,
  required,
  multiline = false,
  rows,
  multiple = false
}) => {
  const classes = useStyles()
  return (
    <FormikSelectField
      theme={theme}
      label={label}
      multiline={multiline}
      minRows={rows}
      required={required}
      name={name}
      placeholder={placeholder}
      MenuProps={{
        className: classes.dropdownStyle
      }}
      type={type}
      multiple={multiple}
      value={name}
      variant="outlined"
    >
      {children}
    </FormikSelectField>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  dropdownStyle: {
    "& .MuiMenu-paper": {
      border: `1px solid ${themeColors.offWhite}`,
      boxShadow: themeColors.boxShadow3
    }
  }
}))

export default CustomSelectField
