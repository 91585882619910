import SmoothScroll from "smooth-scroll/dist/smooth-scroll"

export const scrollBelowElement = (
  scrollBelowRef: React.RefObject<HTMLElement>
) => {
  const ref = scrollBelowRef.current
  if (ref) {
    try {
      const scroll = new SmoothScroll()
      scroll.animateScroll(ref.offsetTop + ref.offsetHeight, { speed: 200 })
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
}

export const scrollToElement = (scrollContainer: string) => {
  try {
    const element = document.getElementById(scrollContainer)
    if (element) {
      const scrollPosition = element.offsetTop
      const scroll = new SmoothScroll()
      scroll.animateScroll(scrollPosition, { speed: 200 })
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
}
