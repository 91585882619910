import { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const Pending: React.FC<Props> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 14C4.7 14 2 11.3 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 11.3 11.3 14 8 14Z"
      fill="#F8B323"
    />
    <path d="M9 4H7V9H12V7H9V4Z" fill="#F8B323" />
  </svg>
)

export default Pending
