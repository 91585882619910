import { useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"

import { Order } from "api/orders"
import Typography from "common/Typography"
import ReturnModal from "pages/AccountPage/AccountOrders/ReturnModal"
import ReturnStore from "store/ReturnStore"

type Props = {
  orderRow: Order
}

const Returns: React.FC<Props> = ({ orderRow }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    actions: { setOrder }
  } = useStore(ReturnStore)

  const [modalOpen, setModalOpen] = useState(false)

  const handleReturn = () => {
    setOrder(orderRow)
    setModalOpen(true)
  }

  return (
    <div>
      <Typography
        onClick={handleReturn}
        variant="link3"
        className={classes.link}
      >
        {t("account.return")}
      </Typography>
      {modalOpen && <ReturnModal />}
    </div>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  link: {
    paddingRight: spacing(1.5),
    cursor: "pointer"
  }
}))

export default Returns
