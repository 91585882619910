import { Article } from "store/AnonymousShoppingListStore"

const classes = {
  image: "width: 120; margin-bottom: 16;",
  paragraph:
    "color: #575757;  font-size: 14px; font-family: HelveticaNeue, Arial, sans-serif;  font-weight: 300; line-height: 1.5; margin: 0; padding: 16 0;",
  name: "color: #103D82;  font-size: 14px; font-family: HelveticaNeue, Arial, sans-serif;  font-weight: 700; margin: 0",
  tableRow: "border-top: solid 1px #DDDDDD;",
  titleWrapper: "display: flex;  padding-bottom: 24; flex-direction: column;",
  title:
    "font-family: HelveticaNeue, Arial, sans-serif;  font-size: 16; color: #103D82;  margin: 0",
  table:
    "width: 100%; border-collapse: collapse; border-bottom: solid 1px #DDDDDD",
  tableParagraph:
    "color: #575757;  font-size: 14px; font-family: HelveticaNeue, Arial, sans-serif;  font-weight: 600; line-height: 1.5; margin: 0; text-align: left; padding: 16 0;"
}

export const printTemplate = (
  shoppingList: Article[],
  shoppingListTitle: string,
  articleNumber: string,
  article: string,
  quantity: string,
  variant: string
) => {
  const printWindow = window.open("", "", "width=700,height=800") as Window
  printWindow.document.write(
    `
    <html>
      <head>
        <title>${shoppingListTitle}</title>
      </head>
      <body>
        <div style="${classes.titleWrapper}">
        <img src="https://dlia05aap05rx6prod.dxcloud.episerver.net/globalassets/mail/logo-delaval-blue.jpg" style="${classes.image}"/>
        <h1 style="${classes.title}">
          ${shoppingListTitle}
        </h1>
        </div>
        <div>
          <table style="${classes.table}">
            <tr>
              <th><p style="${classes.tableParagraph}">${articleNumber}</p></th>
              <th><p style="${classes.tableParagraph}">${article}</p></th>
              <th><p style="${classes.tableParagraph}">${variant}</p></th>
              <th>
                <p style="${classes.tableParagraph} text-align: right;">
                  ${quantity}
                </p>
              </th>
            </tr>
        </div>
        </body>
    </html>
    `
  )

  shoppingList.map((list: Article, i: number) => {
    return printWindow.document.write(`
          <tr" key=${i}>
            <td style="${classes.tableRow}">
              <p style="${classes.paragraph}">${list.articleNumber}</p>
            </td>
            <td style="${classes.tableRow}">
              <p style="${classes.paragraph}">${list.name}</p>
            </td>
            <td style="${classes.tableRow}">
              <p style="${classes.paragraph}">${
                list.variationValue ? list.variationValue : ""
              }</p>
            </td>
            <td style="${classes.tableRow}">
            <p style="${classes.paragraph} text-align: right;">${
              list.quantity
            }</p>
            </td>
          </tr>
      `)
  })

  printWindow.document.write(`</table>`)

  printWindow.document.close()

  printWindow.focus()
  printWindow.print()
}
