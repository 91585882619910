import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const AnimalCow: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 82 77">
    <g clipPath="url(#AnimalCow)" fill="currentColor">
      <path d="M32.3 3.06c.03.02.07.03.1.05 2.14 1.49 4.47 2.63 6.8 3.77 2 .98 4 1.97 5.86 3.22 2.35 1.58 4.27 3.56 5.62 6.08.77 1.45 1.97 2.52 3.28 3.46 1.78 1.28 3.6 2.51 5.38 3.8 1.95 1.42 3.74 3.01 5.33 4.85.96 1.11 2.22 1.79 3.54 2.36 1.24.53 2.5.99 3.72 1.56.76.35 1.45.83 2.18 1.25.12.07.23.13.35.18 2.09.86 3.96 2.06 5.33 3.88 2.53 3.38 2.68 6.97.76 10.67-.85 1.63-2.08 2.97-3.51 4.11-.32.25-.69.45-1.04.66-.55.33-.94.78-1.18 1.39-.6 1.52-1.45 2.88-2.7 3.95-1.57 1.35-3.44 1.89-5.46 1.98-1.65.07-3.27-.18-4.89-.46-3.63-.63-7.22-.43-10.78.48-2.97.76-5.99.94-9.04.63-1.78-.18-3.51-.58-5.24-1.03-3.29-.86-6.37-.28-9.27 1.42-1.55.91-2.95 2.01-4.3 3.17-2.92 2.53-5.85 5.06-8.76 7.61-.66.58-1.26 1.22-1.92 1.81-.43.38-.88.74-1.37 1.02-.84.48-1.68.41-2.42-.22-.48-.41-.88-.89-1.32-1.35-.4-.42-.81-.84-1.2-1.27-.25-.28-.52-.56-.72-.88-.32-.52-.25-1 .18-1.43.16-.16.33-.33.53-.43 1.15-.63 2.1-1.52 3.09-2.35 3.21-2.72 6.42-5.42 9.62-8.14 1.22-1.03 2.38-2.13 3.64-3.1 3.3-2.53 7.06-3.66 11.21-3.45 1.55.08 3.06.39 4.58.67 3.18.59 6.38.74 9.61.45 1.75-.16 3.47-.51 5.2-.83 2.81-.53 5.61-.57 8.4.14 1.41.36 2.85.39 4.28-.01a6.876 6.876 0 003.75-2.64c1.25-1.73 2.64-3.33 4.06-4.92.37-.41.67-.91.92-1.41.47-.94.26-1.72-.59-2.33-.28-.2-.57-.39-.89-.51-2.05-.83-3.96-1.93-5.83-3.1-5.17-3.24-10.27-6.58-15.11-10.3-3.11-2.39-5.86-5.15-8.11-8.39-.55-.8-1.14-1.57-1.78-2.31-.63-.72-1.44-1.2-2.31-1.62-1.4-.67-2.79-1.36-4.18-2.05-.16-.08-.31-.19-.46-.28-1.52-.91-2.39-2.27-2.57-4.02-.17-1.57-.24-3.16-.35-4.74-.04-.35-.02-.69-.02-1.05z" />
      <path d="M14.51 7.34v.71c0 1.93.11 3.85.6 5.74.75 2.93 2.38 5.2 5 6.76.51.3 1 .68 1.42 1.1.75.75.96 1.68.54 2.66-.28.64-.67 1.24-1.03 1.84-.29.49-.63.96-.93 1.44-.7 1.13-1.48 1.29-2.5.46-1.4-1.15-2.84-2.26-4.14-3.52-3.13-3.04-5.01-6.79-5.85-11.05-.59-3.04-.53-6.05.4-9.03.34-1.09.83-2.09 1.66-2.9C10.78.47 12.15.08 13.64.02c2.08-.09 4.03.42 5.91 1.25 4.04 1.78 7.37 4.45 9.85 8.12.76 1.13 1.33 2.35 1.71 3.66.36 1.24.04 2.26-.91 3.1-.39.34-.81.63-1.23.94-.49.36-.98.72-1.48 1.08-.09.07-.18.14-.28.2-.6.37-1.08.29-1.5-.26-.22-.29-.39-.63-.57-.95-1.1-1.9-2.17-3.81-3.5-5.56-1.29-1.69-2.86-3.02-4.92-3.68-.69-.25-1.42-.39-2.21-.58zM.1 25.8c-.04-2.05-.12-4.03-.1-6.01.01-1.15.37-2.22 1.28-3.02.85-.75 1.88-1.11 3-1.21.72-.07 1.32.25 1.69.88.23.38.44.8.52 1.23.17.96.31 1.94.37 2.91.05.99-.39 1.8-1.26 2.29-.68.39-1.42.68-2.15.98-.98.4-1.97.79-2.79 1.48-.19.12-.34.27-.56.47zm38.79 5.42c-.32-.03-.65-.04-.96-.11-1.1-.23-1.85-.87-2.19-1.95-.29-.91-.31-1.84-.07-2.77.36-1.35 1.41-2.2 2.87-2.31.42-.03.86-.04 1.28.02.91.13 1.62.63 2.09 1.41.3.51.51 1.08.74 1.63.04.09.04.21.02.31-.13.56-.21 1.15-.41 1.69-.5 1.3-1.52 1.93-2.88 2.04-.16.01-.32 0-.49 0v.04z" />
    </g>
    <defs>
      <clipPath id="AnimalCow">
        <path fill="#fff" d="M0 0h82v77H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default AnimalCow
