import { MenuItem } from "@starrepublic/epi/cms/types/menu"

import { SelectableSimpleLink } from "store/PageStore"
import { FooterLink } from "types/content/footer"

export const countObjectDepth = (
  obj:
    | MenuItem
    | SelectableSimpleLink
    | Pick<MenuItem, "title" | "url">
    | FooterLink
): number => {
  if (typeof obj !== "object" || obj === null) {
    return 0
  }

  if (Array.isArray(obj)) {
    let maxArrayDepth = 0

    for (const item of obj) {
      const arrayItemDepth = countObjectDepth(item)
      if (arrayItemDepth > maxArrayDepth) {
        maxArrayDepth = arrayItemDepth
      }
    }

    return maxArrayDepth
  }

  let maxDepth = 0

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const depth = countObjectDepth(obj[key])
      if (depth > maxDepth) {
        maxDepth = depth
      }
    }
  }

  return maxDepth + 1
}

export const calculateArrayDepth = (nodes: MenuItem[]): number => {
  let maxDepth = 1

  for (const node of nodes) {
    const depth = node && calculateArrayDepth(node.children as MenuItem[]) + 1
    if (depth > maxDepth) {
      maxDepth = depth
    }
  }

  return maxDepth
}
