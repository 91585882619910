import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import AccountCompanyDetails from "./AccountCompanyDetails"
import AccountShippingDetails from "./AccountShippingDetails"
import AccountUserDetails from "./AccountUserDetails"

type AccountDetailsProps = {
  title?: string
}

const AccountDetails: React.FC<AccountDetailsProps> = ({ title = "" }) => {
  const classes = useStyles()

  return (
    <Box mb={6}>
      {title !== "" && (
        <Box mb={2} py={1}>
          <Typography variant="headline3">{title}</Typography>
        </Box>
      )}
      <Grid container spacing={3} className={classes.row}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} className={classes.paper}>
            <AccountUserDetails />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} className={classes.paper}>
            <AccountCompanyDetails />
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.row}>
        <Grid item md={6}>
          <Paper elevation={3} className={classes.paper}>
            <AccountShippingDetails />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  row: {
    marginBottom: spacing(2)
  },
  paper: {
    height: "100%"
  }
}))

export default AccountDetails
