import "babel-polyfill"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "array-from-polyfill"
import "es7-object-polyfill"
import "utils/setApiAcceptLanguage"

import * as ReactDOM from "react-dom"
import ResizeObserverPollyfill from "resize-observer-polyfill"

import { handleRerenders } from "utils/epiPreviewUtil"

import Root from "common/Root"
import { init } from "services/StarGA"
// import registerServiceWorker from 'registerServiceWorker';
window["ResizeObserver"] = window["ResizeObserver"] || ResizeObserverPollyfill

init("GTM-WP5MTLM", {
  startPageType: "StartPage",
  productPageType: "WebProduct",
  categoryPageType: "WebNode",
  checkoutPageType: "Checkout",
  cookiesPageType: "Cookies"
})

ReactDOM.render(<Root />, document.getElementById("root") as HTMLElement)
// registerServiceWorker();
handleRerenders()
