import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const CheckDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M9.376 14.387 14.893 20 26.709 8m1.092 5.592c.158.778.241 1.583.241 2.408 0 6.627-5.372 12-12 12-6.627 0-12-5.373-12-12 0-6.628 5.373-12 12-12 2.186 0 4.235.584 6 1.605"
      fill="none"
    />
  </SvgIcon>
)

export default CheckDeLaval
