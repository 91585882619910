import { useEffect, useState } from "react"
import useStore from "global-hook-store"

import ListItemText from "@material-ui/core/ListItemText"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"

import { SearchResultType } from "api/search"
import { OriginalPartsCategory } from "models/SiteSettings"
import originalPartsStore from "store/OriginalPartsStore"
import SiteSettingStore from "store/SiteSettingStore"

const CategoryFilter: React.FC = () => {
  const classes = useStyles()
  const [selectedCategory, setSelectedCategory] = useState("")

  const {
    actions: { setRootCategory, asyncSearch }
  } = useStore(originalPartsStore)

  const {
    state: { originalPartsCategory }
  } = useStore(SiteSettingStore)

  useEffect(
    () => {
      setSelectedCategory(
        JSON.stringify({
          title: originalPartsCategory.title,
          id: originalPartsCategory.id
        })
      )
      setRootCategory(originalPartsCategory.id)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const menuItems = (c: OriginalPartsCategory[], depth: number) => {
    let menuNodes = [] as JSX.Element[]
    c.forEach((category: OriginalPartsCategory) => {
      menuNodes.push(
        <MenuItem
          key={category.id}
          value={JSON.stringify({ title: category.title, id: category.id })}
          style={{ paddingLeft: 16 + 16 * depth }}
          classes={{ selected: classes.selectedMenuItem }}
        >
          <ListItemText primary={category.title} />
        </MenuItem>
      )
      if (category.children.length > 0) {
        const childrenMenuNodes = menuItems(category.children, depth + 1)
        menuNodes = menuNodes.concat(...childrenMenuNodes)
      }
    })
    return menuNodes
  }

  return (
    <div className={classes.root}>
      <Select
        className={classes.categorySelect}
        classes={{
          root: classes.inputRoot,
          icon: classes.inputIcon
        }}
        MenuProps={{ classes: { paper: classes.selectMenu } }}
        value={selectedCategory}
        autoWidth={true}
        renderValue={(value: string) => {
          const selected = JSON.parse(value)
          return selected.title
        }}
        onChange={(event: any) => {
          setSelectedCategory(event.target.value)
          setRootCategory(JSON.parse(event.target.value).id).then(() => {
            asyncSearch({
              type: SearchResultType.Products,
              q: "",
              limit: 100,
              offset: 0
            })
          })
        }}
      >
        {menuItems([originalPartsCategory] as OriginalPartsCategory[], 0)}
      </Select>
    </div>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, breakpoints, spacing }) => ({
    root: {
      marginRight: spacing(3),
      [breakpoints.down("sm")]: {
        width: "100%",
        marginRight: spacing(0),
        marginBottom: spacing(1)
      }
    },
    selectedMenuItem: {
      backgroundColor: `${themeColors.white} !important`,
      "&:hover": {
        backgroundColor: `${themeColors.offWhite} !important`
      },
      "& span": {
        fontWeight: 900
      }
    },
    inputRoot: {
      paddingLeft: 16,
      paddingRight: `48px !important;`,
      backgroundColor: `${themeColors.offWhite} !important`
    },
    selectMenu: {
      border: `1px solid ${themeColors.lightGray}`
    },
    inputIcon: {
      right: 12
    },
    categorySelect: {
      backgroundColor: themeColors.offWhite,
      height: 64,
      "&:before": {
        display: "none"
      },
      "&:after": {
        display: "none"
      },
      [breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    filterButton: {
      backgroundColor: themeColors.offWhite,
      borderRadius: 0,
      width: 64,
      height: 64,
      "&:hover": {
        backgroundColor: themeColors.offWhite
      }
    },
    selected: {
      fontWeight: "bold"
    }
  })
)

export default CategoryFilter
