import { BlockProps } from "@starrepublic/epi/cms"
import { FormikErrors } from "formik"

import Box from "@material-ui/core/Box"
import Tooltip from "@material-ui/core/Tooltip"

import Button from "common/Button"

type Props = BlockProps<{
  theme: string
  label: string
  isValid: boolean
  formElementId: "button" | "submit" | "reset" | undefined
  errors: FormikErrors<any>
  description?: string
}>

const SubmitButtonElementBlock: React.FunctionComponent<Props> = ({
  content: { label, formElementId, isValid, description, theme }
}) => {
  return (
    <Tooltip
      placement="top"
      arrow
      title={description ? description : ""}
      interactive
    >
      <Box display="flex" justifyContent="center">
        <Button
          id="dynamic-form-submit-button"
          type={formElementId}
          variant="primary"
          disabled={!isValid}
          theme={theme}
        >
          {label}
        </Button>
      </Box>
    </Tooltip>
  )
}

export default SubmitButtonElementBlock
