import { Link } from "react-router-dom"
import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { blockDisplay } from "utils/blockDisplay"

import BlockWrapper from "common/ContentWrapper"
import ResponsiveImage from "common/ResponsiveImage"
import Typography from "common/Typography"
import { ImageBlock } from "types/content/media"

type Article = {
  image: ImageBlock
  category: string
  url: string
  heading: string
  text: string
}

type Props = BlockProps<{
  heading: string
  items: Article[]
  viewAllLink: {
    url: string
    text: string
  }
}>

const MEDIA_BG_RATIO = 4 / 3

const PageListBlock: React.FC<Props> = ({ content, blockInfo }) => {
  const classes = useStyles()
  const _display = blockDisplay(blockInfo)

  const { heading, items } = content

  return (
    <BlockWrapper display={blockInfo!["_display"]}>
      <Box mb={5}>
        <Typography className={classes.headline} variant="headline2">
          {heading}
        </Typography>
      </Box>
      <Grid direction="column" container>
        {items &&
          items.map((article, key) => {
            return (
              <Link style={{ width: "100%" }} key={key} to={article.url}>
                <Box
                  display="flex"
                  className={classNames(classes.content, {
                    [classes.wrap]: _display.oneThird
                  })}
                >
                  <Box
                    width={{
                      xs: "100%",
                      sm: _display.oneThird ? "100%" : "auto"
                    }}
                  >
                    <ResponsiveImage
                      className={classes.media}
                      containerProps={{
                        className: classNames(classes.mediaContainer, {
                          [classes.mediaWidthSmall]: _display.oneThird
                        })
                      }}
                      image={article.image}
                    />
                  </Box>
                  <Grid>
                    <Box
                      className={classNames(classes.textContainer, {
                        [classes.textContainerSmall]: _display.oneThird
                      })}
                    >
                      <Box mb={1}>
                        <Typography
                          className={classes.headlineItem}
                          variant="headline5"
                        >
                          {article.heading}
                        </Typography>
                      </Box>
                      <Typography className={classes.category} variant="link2">
                        {article.category}
                      </Typography>
                      <Typography>{article.text}</Typography>
                    </Box>
                  </Grid>
                </Box>
              </Link>
            )
          })}
      </Grid>
    </BlockWrapper>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  media: {
    position: "absolute",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  mediaContainer: {
    position: "relative",
    paddingBottom: `${100 / MEDIA_BG_RATIO}%`,
    flex: 0,
    width: 290,
    [breakpoints.down("md")]: {
      width: 197
    },
    [breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  mediaWidthSmall: {
    width: "100%"
  },
  content: {
    flexWrap: "nowrap",
    textAlign: "left",
    position: "relative",
    overflow: "auto",
    outline: "none",
    padding: `${spacing(1)}px 0px`,
    [breakpoints.down("xs")]: {
      flexWrap: "wrap"
    }
  },
  wrap: {
    flexWrap: "wrap"
  },
  category: {
    fontWeight: "normal"
  },
  textContainer: {
    marginLeft: spacing(3),
    [breakpoints.down("xs")]: {
      marginLeft: 0,
      marginTop: spacing(2)
    }
  },
  textContainerSmall: {
    marginLeft: 0,
    marginTop: spacing(2)
  },
  headline: {
    [breakpoints.down("sm")]: {
      fontSize: 32
    }
  },
  headlineItem: {
    [breakpoints.up("sm")]: {
      fontSize: 28
    }
  }
}))

export default PageListBlock
