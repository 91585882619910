import api from "@starrepublic/epi/api"
import { ProductsApiParams } from "@starrepublic/epi/api/products"
import { AsyncState, asyncState, createStore } from "global-hook-store"

import SearchApi, { SearchResult, SearchResultType } from "api/search"
import Product from "models/Products"
import { WebProductContent } from "pages/WebProduct/types"
import PageStore from "store/PageStore"

const langRegex = new RegExp("^[a-zA-Z]{0,2}(-[a-zA-Z]{0,2})?$")
type SortSettings = {
  id: string
  name: string
  isSelected: boolean
}

type ProductList = {
  value: Product[]
  totalItems: number
  sortSettings: SortSettings[]
}

type ProductListingStore = {
  productList: AsyncState<ProductList>
  products: Product[]
  netPriceArticleList: string[]
  filteredProducts: Product[]
  searchedProductList: AsyncState<SearchResult>
  quickViewProduct: AsyncState<WebProductContent>
  quickViewOpen: boolean
  filterQuery: string
  productListRoute: string
  sort: string
}

const ProductListingStore = createStore(
  {
    productList: asyncState<ProductList>(),
    products: [] as Product[],
    netPriceArticleList: [] as string[],
    filteredProducts: [] as Product[],
    searchedProductList: asyncState<SearchResult>(),
    quickViewProduct: asyncState<WebProductContent>(),
    quickViewOpen: false,
    filterQuery: "",
    productListRoute: "",
    sort: "latest"
  } as ProductListingStore,
  {
    emptyProducts: (_, __: null, { reset }) => reset("productList"),
    fetchingProductList: async (
      _,
      payload: ProductsApiParams,
      { asyncAction }
    ) =>
      asyncAction(
        "productList",
        api.products.getProducts<ProductList>(payload)
      ),
    addProducts: (state) => ({
      ...state,
      products: state.products.concat(state.productList.data.value)
    }),
    setNetPriceArticleList: async (state) => ({
      ...state,
      netPriceArticleList: state.products.map(
        (item) => item.displayLowestPriceArticleNumber
      )
    }),
    addFilteredProducts: (state) => ({
      ...state,
      filteredProducts: state.filteredProducts.concat(
        state.searchedProductList.data[0].hits
      )
    }),
    setProductListRoute: (state, productListRoute: string) => ({
      ...state,
      productListRoute
    }),
    setFilterQuery: (state, filterQuery: string) => ({ ...state, filterQuery }),
    setSortProperty: (state, sort: string) => ({ ...state, sort }),
    loadProductList: async (state, __: null, { asyncAction }) => {
      const params = {
        route: state.productListRoute,
        sort: state.sort,
        limit: 15,
        offset: state.products.length,
        excludeIds: [],
        filters: {}
      }

      return asyncAction(
        "productList",
        api.products.getProducts<ProductList>(params)
      )
    },
    quickFilterSearch: async (state, __: null, { asyncAction }) => {
      const params = {
        type: SearchResultType.Products,
        q: state.filterQuery,
        sort: state.sort,
        limit: 15,
        offset: state.filteredProducts.length
      }

      return asyncAction(
        "searchedProductList",
        SearchApi.getRootCategorySearchResults(
          params,
          (PageStore.state.page.data &&
            PageStore.state.page.data.contentGuid) ||
            ""
        )
      )
    },
    setQuickViewOpen: (state, quickViewOpen: boolean) => ({
      ...state,
      quickViewOpen
    }),
    getQuickViewProduct: async (state, url: string, { asyncAction }) => {
      let parsedId = url

      const isLang: boolean = langRegex.test(url.split("/")[1])

      if (isLang) {
        parsedId = url.split("/").splice(2).join("/")
      }

      return asyncAction(
        "quickViewProduct",
        api.pages.getContentByIdentifier(parsedId, false)
      )
    },
    resetQuickFilter: (_, ___: null, { reset }) =>
      reset("searchedProductList", "filteredProducts", "filterQuery"),
    resetProductList: (_, __: null, { reset }) =>
      reset(
        "productList",
        "products",
        "searchedProductList",
        "filteredProducts",
        "filterQuery"
      ),
    resetFilteredProducts: (_, __: null, { reset }) => reset("filteredProducts")
  }
)

export default ProductListingStore
