import carts from "./carts"
import catalogs from "./catalogs"
import markets from "./markets"
import menus from "./menus"
import orders from "./orders"
import pages from "./pages"
import products from "./products"
import site from "./siteSettings"
import users from "./users"

export type ApiError = {
  status: number
  code: string
  message: string
}

export default {
  carts,
  menus,
  orders,
  catalogs,
  products,
  pages,
  users,
  site,
  markets
}
