import { useTranslation } from "react-i18next"
import { Formik, FormikActions } from "formik"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import EditPen from "common/EditPen"
import Typography from "common/Typography"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import OrderReferenceForm from "./OrderReferenceEditForm"
import validationSchema from "./validationSchema"

const OrderReference: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    preparedCheckout,
    orderReference,
    isEditingOrderReference,
    set: checkoutSet
  } = useCheckoutPaymentStore()

  type OrderReferenceFormType = {
    orderReference: string
  }

  const handleEdit = () => {
    checkoutSet.isEditingOrderReference(true)
  }

  const handleSubmit = (
    values: OrderReferenceFormType,
    { setSubmitting }: FormikActions<OrderReferenceFormType>
  ) => {
    checkoutSet.orderReference(values.orderReference.trim())
    checkoutSet.isEditingOrderReference(false)
    setSubmitting(false)
  }

  return (
    <Box pt={4}>
      {isEditingOrderReference ? (
        <Formik
          initialValues={
            {
              orderReference
            } as OrderReferenceFormType
          }
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur
          component={OrderReferenceForm}
          validationSchema={validationSchema(t)}
        />
      ) : orderReference ? (
        <>
          <div className={classes.orderReferenceWrapper}>
            <Typography
              variant="body1"
              component="span"
              className={classes.orderReferenceHeader}
            >
              {t("checkout.order_reference_header")}
            </Typography>
            {!preparedCheckout.data && (
              <span style={{ float: "right" }}>
                <EditPen onClick={handleEdit} />
              </span>
            )}
            <Typography variant="body1" component="p">
              {`${orderReference}`}
            </Typography>
          </div>
        </>
      ) : (
        <div className={classes.centerAlign}>
          <Typography
            variant="link3"
            component="span"
            className={classes.pointer}
            onClick={handleEdit}
          >
            {t("checkout.add_order_reference")}
          </Typography>
        </div>
      )}
    </Box>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  pointer: { cursor: "pointer" },
  centerAlign: { textAlign: "center" },
  orderReferenceHeader: { fontWeight: "bold" },
  orderReferenceWrapper: {
    minWidth: 300,
    border: `1px solid ${themeColors.lightGray}`,
    padding: spacing(3)
  }
}))

export default OrderReference
