import { useTranslation } from "react-i18next"
import { Formik, FormikActions } from "formik"

import { useCheckoutPaymentStore } from "store/CheckoutStore"
import PromoField from "./PromoField"

type SubmitType = {
  promoCode: string
}

const initialValues = {
  promoCode: ""
} as SubmitType

const ContactForm: React.FC = () => {
  const { t } = useTranslation()

  const { addPromoCode } = useCheckoutPaymentStore()

  const handleSubmit = async (
    values: SubmitType,
    { setSubmitting, setStatus }: FormikActions<SubmitType>
  ) => {
    addPromoCode(values.promoCode).then(() => {
      setSubmitting(false)
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      component={PromoField}
    />
  )
}

export default ContactForm
