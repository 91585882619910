import request from "@starrepublic/epi/api/request"

// import siteSettingsApi from "@starrepublic/epi/api/siteSettings"
import SiteSettings from "models/SiteSettings"
const cacheLocation = "EPI_SITE_DATA"
const cacheKey = (lang: string) => {
  return "/site"
}
const siteSettings = {
  get: (getCachedSettings: boolean) => {
    if (getCachedSettings) {
      const cachedValue = window[cacheLocation]
      if (cachedValue) {
        const key = cacheKey(cachedValue["_lang"] || "").toLowerCase()
        const type = cachedValue["_type"] || ""
        if (
          key === cachedValue["_apiRoute"] ||
          window.EPI_EDIT_MODE ||
          type.toLowerCase().includes("errorpage")
        ) {
          // if (clearCache) {
          //   window[cacheLocation] = undefined
          // }
          return new Promise<SiteSettings>(cachedValue as any)
        }
      }
    }
    return request.get<SiteSettings>(`/site`)
  }
}

export default siteSettings
