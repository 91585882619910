import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import { WebNodeContent } from "./types"

type Props = {
  content: WebNodeContent
}

const WebNodeHeader: React.FC<Props> = ({ content }) => {
  const classes = useStyles()
  const xhtmlCheck = content && content.text && content.text.startsWith("<")
  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.grid}>
        <Typography variant="headline1" className={classes.header}>
          {content.displayName}
        </Typography>
        {xhtmlCheck ? (
          <div className={classes.headerText}>
            <ThemeXhtmlString content={content.text} />
          </div>
        ) : (
          <Typography
            variant="body1"
            className={classes.headerText}
            hidden={!content.text}
          >
            {content.text}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors, pageMaxWidth }, spacing, breakpoints }) => ({
    root: {
      width: "100%",
      paddingTop: spacing(6),
      paddingBottom: spacing(6),
      background: themeColors.white,
      [breakpoints.down("sm")]: {
        paddingTop: spacing(3),
        paddingBottom: spacing(3)
      }
    },
    grid: {
      width: "100%",
      maxWidth: pageMaxWidth,
      margin: "auto",
      padding: spacing(0, 1.5)
    },
    header: {
      width: "100%",
      paddingBottom: spacing(2),
      textAlign: "center",
      [breakpoints.down("xs")]: {
        fontSize: 24
      }
    },
    headerText: {
      width: "100%",
      textAlign: "center",
      maxWidth: 600,
      margin: "auto",
      paddingBottom: spacing(3)
    }
  })
)

export default WebNodeHeader
