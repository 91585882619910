import { BlockProps } from "@starrepublic/epi/cms"

import Divider from "@material-ui/core/Divider"
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"

type Props = BlockProps<{
  description?: string
}>

const FormDividerElementBlock: React.FunctionComponent<Props> = ({
  content: { description }
}) => {
  const classes = useStyles()

  return (
    <Tooltip
      placement="top"
      arrow
      title={description ? description : ""}
      interactive
    >
      <Divider />
    </Tooltip>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    root: {}
  })
)
export default FormDividerElementBlock
