import { Link } from "react-router-dom"

import Box from "@material-ui/core/Box"
import { ButtonBaseProps } from "@material-ui/core/ButtonBase"

import { scrollToElement } from "utils/ui/scroll"

import { eventPdfTracker } from "services/StarGA"
import { getBlockIdFromLink, getTypeOfLink } from "./utils"

type Props = {
  title?: string
  link: string
  className?: string
  onClick?: (e: React.SyntheticEvent<HTMLElement>) => void
} & ButtonBaseProps

type LinkButtonBaseProps = Pick<Props, "className" | "onClick" | "link">

const LinkButtonBase: React.FC<LinkButtonBaseProps> = ({
  children,
  className,
  onClick,
  link
}) => {
  const handleOnClick = (event: React.MouseEvent<HTMLElement>, url: string) => {
    if (onClick) {
      onClick(event)
    }
    if (url.endsWith(".pdf")) {
      eventPdfTracker(url)
    }
  }

  return (
    <Box
      clone
      onClick={(event) => handleOnClick(event, link)}
      className={className}
    >
      {children}
    </Box>
  )
}

const RegularLinkButton: React.FC<Props> = ({ link, title, ...restProps }) => (
  <a href={link} title={title}>
    <LinkButtonBase link={link} {...restProps} />
  </a>
)

const ExternalLinkButton: React.FC<Props> = ({ link, title, ...restProps }) => (
  <a href={link} title={title} target="_blank" rel="noopener noreferrer">
    <LinkButtonBase link={link} {...restProps} />
  </a>
)

const InternalLinkButton: React.FC<Props> = ({ link, ...restProps }) => (
  <LinkButtonBase
    link={link}
    {...{ onClick: () => scrollToElement(link), ...restProps }}
  />
)

const RouterLinkButton: React.FC<Props> = ({ link, ...restProps }) => (
  <Link to={link}>
    <LinkButtonBase link={link} {...restProps} />
  </Link>
)

const LinkButton: React.FC<Props> = ({ link, children, ...restProps }) => {
  const type = getTypeOfLink(link)

  switch (type) {
    case "external":
      return (
        <ExternalLinkButton {...{ link, ...restProps }}>
          {children}
        </ExternalLinkButton>
      )

    case "internal": {
      const blockId = getBlockIdFromLink(link)
      const isBlockId = blockId && new RegExp(/^\d+$/).test(blockId)

      if (blockId && isBlockId) {
        return (
          <InternalLinkButton {...{ link: blockId, ...restProps }}>
            {children}
          </InternalLinkButton>
        )
      }

      return (
        <RouterLinkButton {...{ link, ...restProps }}>
          {children}
        </RouterLinkButton>
      )
    }

    case "contact":
      return (
        <RegularLinkButton {...{ link, ...restProps }}>
          {children}
        </RegularLinkButton>
      )

    default:
      return null
  }
}

export default LinkButton
