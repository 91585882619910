import { useEffect, useState } from "react"
import { ContentProps, PageInfo } from "@starrepublic/epi/cms"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"

import BreadcrumbsBar from "common/BreadcrumbsBar"
import UserAccountMenu from "common/UserAccountMenu"
import Wrapper from "common/Wrapper"
import { UserRegistrationModel } from "models/User"
import authStore from "store/AuthStore"
import AccountDetails from "./AccountDetails"
import AccountOrders from "./AccountOrders"
import AccountOverview from "./AccountOverview"
import AccountSubscriptions from "./AccountSubscriptions"
import DisplayLoginButton from "./DisplayLoginButton"

type Props = { pageInfo: PageInfo } & ContentProps

const AccountPage: React.FC<Props> = ({ pageInfo }) => {
  const classes = useStyles()

  const {
    state: { user },
    actions: { getUser }
  } = useStore(authStore)

  const [userData, setUserData] = useState<UserRegistrationModel>()

  useEffect(
    () => {
      if (!user.data) {
        getUser()
      } else {
        setUserData(user.data)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )

  const accountPages = () => {
    switch (pageInfo._type) {
      case "AccountDetailsPage":
        return <AccountDetails title={pageInfo.title} />
      case "AccountOrdersPage":
        return <AccountOrders title={pageInfo.title} />
      case "AccountSubscriptions":
        return <AccountSubscriptions title={pageInfo.title} />
      default:
        return <AccountOverview title={pageInfo.title} />
    }
  }

  return userData ? (
    <>
      <Grid className={classes.breadCrumbs}>
        <BreadcrumbsBar />
      </Grid>
      <Wrapper grid className={classes.wrapper}>
        <Hidden smDown>
          <UserAccountMenu />
        </Hidden>
        <Grid item xs={12} sm={12} md={10} className={classes.width}>
          <Grid className={classes.viewContainer}>{accountPages()}</Grid>
        </Grid>
      </Wrapper>
    </>
  ) : (
    <DisplayLoginButton />
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    breadCrumbs: {
      borderBottom: `1px solid ${themeColors.whiteGray}`
    },
    wrapper: {
      display: "flex",
      flexGrow: 1,
      alignItems: "flex-start",
      width: "100%",
      background: themeColors.white,
      boxShadow: "none",
      [breakpoints.down("sm")]: {
        padding: spacing(0, 1.5)
      }
    },
    viewContainer: {
      marginTop: spacing(4),
      paddingBottom: spacing(10),
      paddingLeft: spacing(3),
      [breakpoints.down("sm")]: {
        paddingLeft: 0
      }
    },
    width: {
      width: "100%"
    }
  })
)

export default AccountPage
