import { memo } from "react"
import { useTranslation } from "react-i18next"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import AddToCartOrShoppingListContainer from "common/AddToCartOrShoppingListContainer"
import LinkButton from "common/LinkButton"
import PricePerUnit from "common/Product/PricePerUnit"
import ProductPrice from "common/Product/ProductPrice"
import StockStatus from "common/Product/StockStatus"
import VariantPickerModal from "common/ProductListing/VariantPickerModal"
import ResponsiveImage from "common/ResponsiveImage"
import Typography from "common/Typography"
import Product from "models/Products"

type Props = {
  product: Product
}
const ProductRowMobile = ({ product }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const inventoryStatus = product.inventoryStatus === "NOT_IN_STOCK"

  return (
    <TableRow>
      <TableCell>
        <Grid className={classes.linkButton}>
          <LinkButton link={product._route}>
            <div>
              <ResponsiveImage
                lazy
                image={product && product.displayImage}
                className={classes.productImage}
              />
            </div>
          </LinkButton>
        </Grid>
      </TableCell>
      <TableCell className={classes.infoTableCell}>
        <Grid container>
          <Box display="flex">
            <StockStatus
              compact
              inventoryStatus={product.inventoryStatus}
              isDigitalService={product.isDigitalService}
            />
            <Typography>{product.displayLowestPriceArticleNumber}</Typography>
          </Box>
        </Grid>

        <Typography>{product.productName}</Typography>

        <Box pt={2} justifyContent="right" display="flex">
          <div className={classes.priceWrapper}>
            {product.isDigitalService ? (
              <PricePerUnit
                price={product.displayLowestPricePerX || ""}
                unit={product.displayLowestPricePerXUnitType || ""}
                size={"medium"}
              />
            ) : (
              <ProductPrice
                price={product.displayLowestPrice}
                discountedPrice={product.displayLowestDiscountedPrice}
                size={"medium"}
              />
            )}

            <Typography variant="smallText">{t("product.ex_vat")}</Typography>
          </div>

          {product.variations.length > 1 ? (
            <VariantPickerModal
              mobile
              link="/"
              productId={product._id}
              productName={product.displayName}
              disabled={inventoryStatus}
            />
          ) : product.variations.length === 1 ? (
            <AddToCartOrShoppingListContainer
              mobile
              disabled={inventoryStatus || product.displayLowestPrice === null}
              code={product.variations[0].code}
              large={false}
              amount={
                product.variations[0].displayPrice &&
                product.variations[0].displayPrice.amount
                  ? product.variations[0].displayPrice.amount
                  : 0
              }
              name={product.displayName}
              category={product.categoryStructure}
              image={
                product && product.displayImage && product.displayImage._url
              }
              variationValue={
                product.variations &&
                product.variations.length &&
                product.variations[0] &&
                product.variations[0].variationValue
              }
              link="/"
              digitalService={product["isDigitalService"]}
              displayPrice={product.displayLowestPrice}
              discountedPrice={product.displayLowestDiscountedPrice}
            />
          ) : null}
        </Box>
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  infoTableCell: {
    paddingBottom: 0,
    paddingRight: "0 !important",
    paddingTop: spacing(1)
  },
  linkButton: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      padding: 0
    }
  },
  productImage: {
    position: "initial",
    maxWidth: 100,
    maxHeight: 100,
    [breakpoints.down("sm")]: {
      width: 80,
      height: 80
    }
  },
  priceWrapper: {
    bottom: spacing(3),
    left: spacing(3),
    [breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "baseline"
    },
    [breakpoints.down("xs")]: {
      left: spacing(1.5),
      bottom: spacing(1.5)
    }
  }
}))

export default memo(ProductRowMobile)
