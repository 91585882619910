import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Form as FormikForm, FormikProps } from "formik"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import ErrorIcon from "@material-ui/icons/Error"

import vatRegistrationUtil from "utils/vatRegistrationUtil"

import Button from "common/Button"
import CustomTextField from "common/Formik/CustomTextField"
import Typography from "common/Typography"
import { VatNumberModel } from "models/User"
import registerStore from "store/RegisterStore"
import SiteSettingStore from "store/SiteSettingStore"
import { themeColors } from "theme"

type AutoFillFormProps = {
  selectedCountry: string
  vatNumberTitle: string
}

type Props = AutoFillFormProps & FormikProps<VatNumberModel>

const AutofillForm: React.FC<Props> = ({ selectedCountry, vatNumberTitle }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { customerInformation, continueVat },
    actions: { setContinueVat }
  } = useStore(registerStore)

  const {
    state: { disableVatNumberLookup }
  } = useStore(SiteSettingStore)

  const [vatCountryAdornment, setVatCountryAdornment] = useState(
    selectedCountry || "EN"
  )

  useEffect(
    () => {
      if (selectedCountry !== vatCountryAdornment) {
        setVatCountryAdornment(selectedCountry)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedCountry]
  )

  return (
    <div className={classes.root}>
      <Box display="flex" flexDirection="column" gridGap={8} mb={3}>
        <Typography variant="preamble" className={classes.preamble}>
          {t("checkout.account_information")}
        </Typography>
        {vatNumberTitle &&
        (customerInformation.data ||
          continueVat ||
          disableVatNumberLookup) ? null : (
          <Typography variant="smallText">
            {t("account.enter_vat_number_information")}
          </Typography>
        )}
      </Box>
      <Box>
        {vatNumberTitle &&
        (customerInformation.data || continueVat || disableVatNumberLookup) ? (
          <Box>
            {continueVat && (
              <Box pb={3}>
                <Typography
                  style={{ color: themeColors.primaryRed }}
                  variant="smallText"
                >
                  {t("account.vat_fetch_error_text")}
                </Typography>
              </Box>
            )}
            <Typography className={classes.vatLabel} variant="body2">
              {t("account.vat_label")}
            </Typography>
            <Typography className={classes.headlineVat} variant="headline4">
              {vatNumberTitle}
            </Typography>
          </Box>
        ) : (
          <FormikForm
            id="vat-auto-fill-form"
            noValidate
            className={classNames(classes.form, {
              [classes.errorLabel]: customerInformation.error === ""
            })}
          >
            <Grid item xs={12}>
              <Box display="flex">
                <Box width="100%" mr={3}>
                  <CustomTextField
                    adornment={vatRegistrationUtil(vatCountryAdornment)}
                    autoComplete="off"
                    required
                    name={"VatNumber"}
                    label={t("account.vat_label")}
                    type="text"
                    vatUtilOn
                    key={vatCountryAdornment}
                  />
                </Box>
                <Button
                  type="submit"
                  className={classes.searchButton}
                  variant="primary"
                >
                  {t("account.vat_search_button")}
                </Button>
              </Box>
              {customerInformation.error === "" && (
                <Box
                  pt={1}
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  gridGap={24}
                >
                  <Box display="flex" alignItems="center">
                    <Box display="flex" pr={1} color={themeColors.primaryRed}>
                      <ErrorIcon />
                    </Box>
                    <Typography
                      style={{ color: themeColors.primaryRed }}
                      variant="smallText"
                    >
                      {t("account.vat_no_business_found")}
                    </Typography>
                  </Box>
                  <Button
                    onClick={() => setContinueVat(true)}
                    variant="secondary"
                  >
                    {t("account.continue_anyway_button")}
                  </Button>
                </Box>
              )}
            </Grid>
          </FormikForm>
        )}
      </Box>
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    root: {
      padding: spacing(3),
      [breakpoints.down("sm")]: {
        padding: spacing(3)
      }
    },
    form: {
      width: "100%"
    },
    searchButton: {
      fontSize: 16,
      height: 55
    },
    preamble: {
      textAlign: "left",
      color: themeColors.darkGray,
      fontWeight: "bold"
    },
    headlineVat: {
      color: themeColors.colorBaseBlue650
    },
    vatLabel: {
      color: themeColors.colorBaseGray600,
      paddingBottom: spacing(0.5)
    },
    errorLabel: {
      "& label": {
        color: themeColors.primaryRed
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: themeColors.primaryRed
      }
    }
  })
)

export default AutofillForm
