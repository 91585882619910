import { useTranslation } from "react-i18next"

import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import Pen from "icons/Pen"

type Props = {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const EditPen: React.FunctionComponent<Props> = ({ onClick }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Typography
      variant="button1"
      className={classes.editButton}
      onClick={onClick}
    >
      <Pen /> {t("common.edit")}
    </Typography>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  editButton: {
    color: themeColors.primaryCyan,
    cursor: "pointer"
  }
}))

export default EditPen
