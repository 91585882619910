import { useCallback, useEffect, useRef } from "react"
import {
  Circle,
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs
} from "react-google-maps"
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer"
import useStore from "global-hook-store"
import debounce from "lodash/debounce"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import DealerSearchStore from "store/DealerSearchStore"
import DealerWindow from "./DealerWindow"
import mapStyles from "./mapStyles"

const GoogleMapConfig: React.FC = () => {
  const isMdUp = useMediaQuery(({ breakpoints }) => breakpoints.up("md"))

  const icon = {
    url: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDE3MiAxNzIiPg0KICA8ZyBmaWxsPSJub25lIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOm5vcm1hbCI+DQogICAgPHBhdGggZD0iTTAgMTcyVjBoMTcydjE3MnoiLz4NCiAgICA8cGF0aCBkPSJNODYgM0M1NiAzIDMxIDI4IDMxIDU4YzAgNDkgNTAgMTA3IDUyIDEwOWwzIDIgMy0yYzItMiA1Mi02MSA1Mi0xMDkgMC0zMC0yNS01NS01NS01NXptMCAzOGEyMSAyMSAwIDExMCA0MiAyMSAyMSAwIDAxMC00MnoiIGZpbGw9IiMwMGE2ZmYiLz4NCiAgPC9nPg0KPC9zdmc+",
    scaledSize: new google.maps.Size(32, 32)
  }

  const LocationIcon = {
    url: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAxOCAyMyIgZmlsbD0iI0ZGRkZGRiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xNyA5QzE3IDEzLjkgOSAyMiA5IDIyQzkgMjIgMSAxMy45IDEgOUMxIDMuOSA1LjEgMSA5IDFDMTIuOSAxIDE3IDMuOSAxNyA5WiIgc3Ryb2tlPSIjRkYzMzAwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+DQo8cGF0aCBkPSJNOSAxMkMxMC42NTY5IDEyIDEyIDEwLjY1NjkgMTIgOUMxMiA3LjM0MzE1IDEwLjY1NjkgNiA5IDZDNy4zNDMxNSA2IDYgNy4zNDMxNSA2IDlDNiAxMC42NTY5IDcuMzQzMTUgMTIgOSAxMloiIHN0cm9rZT0iI0ZGMzMwMCIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPC9zdmc+DQo=",
    scaledSize: new google.maps.Size(32, 32)
  }

  const markedIcon = {
    url: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDE3MiAxNzIiPg0KICA8ZyBmaWxsPSJub25lIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOm5vcm1hbCI+DQogICAgPHBhdGggZD0iTTAgMTcyVjBoMTcydjE3MnoiLz4NCiAgICA8cGF0aCBkPSJNODYgM0M1NiAzIDMxIDI4IDMxIDU4YzAgNDkgNTAgMTA3IDUyIDEwOWwzIDIgMy0yYzItMiA1Mi02MSA1Mi0xMDkgMC0zMC0yNS01NS01NS01NXptMCAzOGEyMSAyMSAwIDExMCA0MiAyMSAyMSAwIDAxMC00MnoiIGZpbGw9IiNmMzAiLz4NCiAgPC9nPg0KPC9zdmc+",
    scaledSize: new google.maps.Size(32, 32)
  }

  const clusterIcon = {
    url: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc0MCcgaGVpZ2h0PSc0MCcgZmlsbD0nbm9uZSc+PGNpcmNsZSBjeD0nMjAnIGN5PScyMCcgcj0nMjAnIGZpbGw9JyMwMDkxRkYnLz48L3N2Zz4=",
    scaledSize: new google.maps.Size(40, 40)
  }

  const {
    state: {
      allDealers,
      geoLocation,
      selectedDealer,
      searchedDealer,
      defaultMapZoom,
      mapZoom,
      allHeadquarters,
      mapSearch,
      searchTerm
    },
    actions: {
      setGeoLocation,
      setSearchedDealer,
      setMapZoom,
      setSelectedDealer,
      googleMapsSearch,
      setDeniedGeolocation
    }
  } = useStore(DealerSearchStore)

  const mapRef = useRef<GoogleMap>(null)

  const centerPoint = () => {
    if (searchedDealer.data && searchedDealer.data.center) {
      return {
        lat: searchedDealer.data.center.latitude,
        lng: searchedDealer.data.center.longitude
      }
    } else if (mapSearch && mapSearch.data) {
      return {
        lat: mapSearch.data.lat,
        lng: mapSearch.data.lng
      }
    }
  }

  const dealers = useCallback(() => {
    if (searchedDealer.data === undefined) {
      return []
    } else {
      if (searchedDealer.data) {
        return searchedDealer.data.dealers
      } else {
        return allDealers
      }
    }
  }, [allDealers, searchedDealer.data])

  const handleCenterChanged = debounce(() => {
    if (mapRef.current) {
      setGeoLocation({
        lat: mapRef.current.getCenter().lat(),
        lng: mapRef.current.getCenter().lng()
      })
    }
  }, 500)

  const handleZoomChanged = debounce(() => {
    if (mapRef.current) {
      setMapZoom(mapRef.current.getZoom())
    }
  }, 500)

  const getPosition = useCallback(() => {
    // Condition to check if map should center on searched input...
    // ...or return coordinates for user current location
    if (searchTerm !== "" && mapSearch.data) {
      const model = {
        searchTerm,
        userLocation: {
          latitude: mapSearch.data.lat,
          longitude: mapSearch.data.lng
        }
      }
      setSearchedDealer(model)
      return new Promise(() => {
        model.userLocation
      })
    }
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject)
    })
  }, [mapSearch.data, searchTerm, setSearchedDealer])

  const geocodeLatLng = useCallback(() => {
    const geocoder = new google.maps.Geocoder()

    getPosition()
      .then((position: any) => {
        const latlng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === "OK" && results[0]) {
            googleMapsSearch(results[0].formatted_address)
          }
        })
      })
      .catch((error) => {
        if (
          error.code === 1 &&
          allHeadquarters.length &&
          allHeadquarters[0].city
        ) {
          setDeniedGeolocation(true)
          googleMapsSearch(allHeadquarters[0].city)
        }
        const defaultMarketPostion = {
          lat: geoLocation.lat,
          lng: geoLocation.lng
        }
        geocoder.geocode({ location: defaultMarketPostion }, (results) => {
          if (results[0]) {
            googleMapsSearch(results[0].formatted_address)
          }
        })
      })
  }, [
    allHeadquarters,
    geoLocation.lat,
    geoLocation.lng,
    getPosition,
    googleMapsSearch,
    setDeniedGeolocation
  ])

  useEffect(() => {
    geocodeLatLng()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      handleZoomChanged()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapZoom]
  )

  return (
    <GoogleMap
      ref={mapRef}
      zoom={mapZoom}
      center={{ lat: geoLocation.lat, lng: geoLocation.lng }}
      defaultOptions={{
        styles: mapStyles as any,
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        panControl: false,
        zoomControl: true,
        rotateControl: false,
        fullscreenControl: false
      }}
      onCenterChanged={handleCenterChanged}
      onZoomChanged={handleZoomChanged}
    >
      {mapRef.current && mapSearch.data && (
        <MarkerClusterer
          ignoreHidden={true}
          averageCenter
          maxZoom={defaultMapZoom + 2}
          enableRetinaIcons
          styles={[
            {
              textColor: "white",
              fontFamily: "Arial",
              textSize: 18,
              height: clusterIcon.scaledSize.height,
              url: clusterIcon.url,
              width: clusterIcon.scaledSize.width
            }
          ]}
        >
          {dealers().map((dealer, key) => (
            <Marker
              key={`${dealer.id}-${key}`}
              options={{ optimized: false }}
              visible={true}
              icon={
                selectedDealer &&
                dealer.latitude === selectedDealer.latitude &&
                dealer.longitude === selectedDealer.longitude
                  ? markedIcon
                  : icon
              }
              position={{
                lat: dealer.latitude,
                lng: dealer.longitude
              }}
              onClick={() => setSelectedDealer(dealer)}
            />
          ))}

          {/* marker for searchlocation. Is now set false for now */}
          {centerPoint() && (
            <Marker
              options={{ optimized: false }}
              visible={false}
              icon={LocationIcon}
              position={centerPoint()}
            />
          )}

          {isMdUp && selectedDealer && (
            <InfoWindow
              position={{
                lat: selectedDealer.latitude,
                lng: selectedDealer.longitude
              }}
              options={{
                pixelOffset: new google.maps.Size(0, -30)
              }}
            >
              <DealerWindow onClose={() => setSelectedDealer(null)} />
            </InfoWindow>
          )}
          {centerPoint() && (
            <Circle
              visible={false} // Set this true if you want to see radius on map
              center={centerPoint()}
              radius={100000} // startRadius}
              options={{
                strokeColor: "#ff0000"
              }}
            />
          )}
        </MarkerClusterer>
      )}
    </GoogleMap>
  )
}

export default withScriptjs(withGoogleMap(GoogleMapConfig))
