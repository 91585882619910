import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const TagDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <g clipPath="url(#a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16 5C9.925 5 5 9.925 5 16s4.925 11 11 11 11-4.925 11-11S22.075 5 16 5ZM3 16C3 8.82 8.82 3 16 3s13 5.82 13 13-5.82 13-13 13S3 23.18 3 16Zm9.02-4.015c-2.203 2.187-2.198 5.758.002 7.962 2.204 2.208 5.79 2.213 7.976.004 2.166-2.189 2.161-5.689-.003-7.894-2.185-2.227-5.77-2.263-7.976-.072Zm-1.413 9.375c-2.975-2.98-2.992-7.82.003-10.794 3-2.98 7.858-2.92 10.813.09 2.922 2.977 2.94 7.728-.003 10.701-2.975 3.006-7.833 2.988-10.813.003Zm4.773-5.947a.844.844 0 0 0 .004 1.184.823.823 0 0 0 1.163-.004.829.829 0 0 0 .004-1.175.823.823 0 0 0-1.17-.005Zm-1.41 2.599c-1.094-1.095-1.12-2.909.004-4.021a2.823 2.823 0 0 1 3.974-.004 2.829 2.829 0 0 1 .005 4.028 2.822 2.822 0 0 1-3.983-.003Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="currentColor" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default TagDeLaval
