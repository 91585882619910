import request from "@starrepublic/epi/api/request"

import { Cart, LineItemProperties } from "models/Cart"

export type QuickItem = {
  itemCode: string
  quantity: number
}

const quickOrder = {
  reOrder: (cartTypeName: string, orderGroupId: number) =>
    request.post<Cart<LineItemProperties>>(
      `/quickorder/${cartTypeName}/${orderGroupId}/`
    ),
  items: (cartTypeName: string, items: QuickItem[]) =>
    request.post(`/carts/extended/${cartTypeName}/items`, items)
}

export default quickOrder
