import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const BulletList: React.FC<Props> = (props) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path
      d="M2 6a2 2 0 100-4 2 2 0 000 4zM2 14a2 2 0 100-4 2 2 0 000 4zM2 22a2 2 0 100-4 2 2 0 000 4zM23 5H8a1 1 0 010-2h15a1 1 0 010 2zM23 13H8a1 1 0 010-2h15a1 1 0 010 2zM23 21H8a1 1 0 110-2h15a1 1 0 110 2z"
      fill="#fff"
    />
  </SvgIcon>
)

export default BulletList
