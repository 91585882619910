import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"

const DownloadIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 16v6h20v-6"
      stroke="#00A6FF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
      fill="none"
    />
    <path d="M12 1v15" stroke="#00A6FF" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M6 10l6 6 6-6"
      stroke="#00A6FF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
      fill="none"
    />
  </SvgIcon>
)

export default DownloadIcon
