import { useTranslation } from "react-i18next"

import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"

type Props = {
  code: string
}

const VariantCode: React.FC<Props> = ({ code }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Typography variant="body2" className={classes.root}>
      {t("product.article_no")}: {code}
    </Typography>
  )
}

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    textAlign: "right",
    width: "100%",
    [breakpoints.down("sm")]: {
      textAlign: "left"
    }
  }
}))

export default VariantCode
