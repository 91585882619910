import {
  Content,
  ContentBlock,
  ContentInfo,
  ContentPage,
  ContentPropertyInfo,
  DynamicBlock,
  DynamicPage,
  PageInfo
} from "../types/content"

import { logDevMessage, Severity } from "./logging"

export const extractBlockPropsInfo = <T extends object>(
  pageInfo: PageInfo | undefined,
  content: T & ContentInfo
) => {
  const block = convertContentToBlock<T>(content)
  return getPropsInfoForContent(pageInfo, block, false)
}

export const getPropsInfoForContent = <T extends object>(
  pageInfo: PageInfo | undefined,
  content: Content<T>,
  log: boolean = true
): ContentPropertyInfo<T> => {
  const defaultPropsInfo = {} as ContentPropertyInfo<T>
  if (!pageInfo) {
    return defaultPropsInfo
  }

  const { info, data } = content
  let type = info._type
  if (!type) {
    log &&
      logDevMessage(
        `Type for item with id ${info._id} is missing, content might not render correctly`,
        Severity.Warn
      )
    return defaultPropsInfo
  }
  type = type[0].toLowerCase() + type.substring(1)

  if (!pageInfo._propertyInfo) {
    log &&
      logDevMessage(
        `Property info object on root content "${pageInfo._type}" is missing`,
        Severity.Warn
      )
    return defaultPropsInfo
  }

  const rootPropInfo = pageInfo._propertyInfo![type]
  if (!rootPropInfo) {
    log &&
      logDevMessage(
        `Property info for ${type} is missing, on-page-editing might not work correctly`,
        Severity.Warn
      )
    return defaultPropsInfo
  }

  const propertyKeys = Object.keys(data)
  if (propertyKeys.length === 0) {
    log && logDevMessage(`No properties found for ${type}`, Severity.Warn)
    return defaultPropsInfo
  }

  // check if property info exists for all properties in the content item and
  // log console warnings if it missing
  const propsInfo = Object.keys(data).reduce((acc, propName) => {
    const propInfo = rootPropInfo[propName]
    if (!propInfo) {
      log &&
        logDevMessage(
          `Property info for property "${propName}" on type "${type} is missing, on-page-editing might not work correctly`,
          Severity.Warn
        )
    }
    return { ...acc, [propName]: propInfo }
  }, {}) as ContentPropertyInfo<T>

  return propsInfo
}

export const convertContentToPage = <T>(page: DynamicPage): ContentPage<T> => {
  const {
    _type,
    _meta,
    _propertyInfo,
    _id,
    _lang,
    _parents,
    _children,
    _route,
    title,
    ...data
  } = page
  const info = {
    _id,
    _type,
    _lang,
    _meta: {
      created: new Date(_meta.created),
      changed: new Date(_meta.changed)
    },
    _route,
    _propertyInfo,
    _parents,
    _children,
    title
  }

  return { info, data: data as T }
}

export const convertContentToBlock = <T>(
  block: DynamicBlock
): ContentBlock<T> => {
  const { _id, _type, _display, _lang, ...data } = block
  const info = { _id, _type, _display }

  return { info, data: data as T }
}
