import { ContentProps, PageInfo } from "@starrepublic/epi/cms"

import WebNodeListing from "common/WebNodeListing"
import { WebNodeContent } from "./types"
import WebNodeDetails from "./WebNodeDetails"

type Props = { pageInfo: PageInfo } & ContentProps<WebNodeContent>

const GRID_VIEW = "gridView"

const WebNode: React.FC<Props> = ({ content, pageInfo }) => {
  if (content.listItemsViewType === GRID_VIEW) {
    return <WebNodeDetails content={content} pageInfo={pageInfo} />
  }
  return <WebNodeListing content={content} pageInfo={pageInfo} />
}

export default WebNode
