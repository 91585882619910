import ButtonBase from "@material-ui/core/ButtonBase"
import { makeStyles } from "@material-ui/core/styles"
import ArrowBack from "@material-ui/icons/ArrowBack"
import Clear from "@material-ui/icons/Clear"

import Logo from "common/Logo"
import Typography from "common/Typography"

type Props = {
  hasBackBtn: boolean
  onCloseButtonClick: (state: boolean) => void
  onBackButtonClick: () => void
  title?: string
}

const MainMenuHeader: React.FC<Props> = ({
  hasBackBtn,
  onBackButtonClick,
  onCloseButtonClick,
  title
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {hasBackBtn ? (
        <ButtonBase className={classes.button} onClick={onBackButtonClick}>
          <ArrowBack />
        </ButtonBase>
      ) : null}
      <div className={classes.titleWrapper}>
        {(title && (
          <Typography variant={"headline4"} className={classes.title}>
            {title}
          </Typography>
        )) || <Logo onClick={() => onCloseButtonClick(false)} />}
      </div>
      <ButtonBase
        className={classes.button}
        onClick={(e) => onCloseButtonClick(false)}
      >
        <Clear />
      </ButtonBase>
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  root: {
    background: themeColors.primaryBlue,
    zIndex: 1001,
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${themeColors.transparent}`,
    padding: spacing(1),
    position: "sticky",
    top: 0
  },
  titleWrapper: {
    flexGrow: 1,
    textAlign: "center"
  },
  title: {
    color: themeColors.white
  },
  button: {
    color: themeColors.white,
    padding: spacing(2)
  }
}))

export default MainMenuHeader
