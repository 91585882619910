import { Variation } from "@starrepublic/epi/api/products"

import Variant from "models/Variant"

enum variationTypes {
  LENGTH = "LENGTH",
  SIZE = "SIZE",
  VOLUME = "VOLUME",
  WEIGHT = "WEIGHT",
  FREE_TEXT = "FREE_TEXT",
  PACK_SIZE = "PACK_SIZE"
}

const pattern = /^\d+/g

const SIZES = ["XS", "S", "M", "L", "XL", "XXL", "3XL", "4XL"].reduce(
  (curr, acc, i) => ({ ...curr, [acc]: i }),
  {}
)

const variationComparator = (
  { variationValue: value1, variationType }: Variation<Variant>,
  { variationValue: value2 }: Variation<Variant>
): number => {
  if (variationType === variationTypes.SIZE) {
    return SIZES[value1] - SIZES[value2]
  }
  const firstValueMatchArray = (value1 || "").match(pattern)
  const secondValueMatchArray = (value2 || "").match(pattern)
  const firstValue = parseInt(
    (firstValueMatchArray && firstValueMatchArray[0]) || "0",
    10
  )
  const secondValue = parseInt(
    (secondValueMatchArray && secondValueMatchArray[0]) || "0",
    10
  )

  return firstValue - secondValue
}
export default variationComparator
