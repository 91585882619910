import { memo } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import { Price } from "models/Products"
import { CustomVariant } from "theme/typography"

type Props = {
  price: Price
  discountedPrice?: Price
  className?: string
  isInline?: boolean
  size?: "tiny" | "small" | "medium" | "large" | undefined
}

const ProductPrice: React.FC<Props> = ({
  price,
  discountedPrice,
  className,
  isInline = false,
  size
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const hasDiscountedPrice =
    discountedPrice !== undefined &&
    discountedPrice !== null &&
    discountedPrice.amount !== price.amount

  let variantName = "priceLarge"

  if (size === "small") {
    variantName = "priceSmall"
  } else if (size === "medium") {
    variantName = "priceMedium"
  } else if (size === "tiny") {
    variantName = "priceTiny"
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Typography textWrap="nowrap" variant={variantName as CustomVariant}>
        {hasDiscountedPrice ? (
          <>
            {isInline ? (
              <>
                <span className={classes.discountedPrice}>
                  {discountedPrice && discountedPrice.displayPrice}
                </span>
                <Typography
                  textWrap="nowrap"
                  variant={"priceTiny"}
                  component={"span"}
                  className={classNames(
                    classes.previousPrice,
                    classes.marginLeft
                  )}
                >
                  {price && price.displayPrice}
                </Typography>
              </>
            ) : (
              <div className={classes.bigPrice}>
                <Typography
                  textWrap="nowrap"
                  variant={"priceTiny"}
                  className={classes.previousPrice}
                >
                  {price && price.displayPrice}
                </Typography>
                <Typography
                  textWrap="nowrap"
                  className={classes.discountedPrice}
                  variant={variantName as CustomVariant}
                >
                  {discountedPrice && discountedPrice.displayPrice}
                </Typography>
              </div>
            )}
          </>
        ) : (
          (price && price.displayPrice) || t("product.no_price")
        )}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    display: "flex",
    alignItems: "flex-end",
    fontWeight: 400,
    whiteSpace: "nowrap"
  },
  previousPrice: {
    textDecoration: "line-through",
    color: themeColors.gray,
    marginRight: spacing(1)
  },
  marginLeft: {
    marginLeft: spacing(1)
  },
  discountedPrice: {
    color: themeColors.primaryRed,
    alignItems: "flex-end"
  },
  bigPrice: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column"
  },
  mediumPrice: {
    display: "flex",
    color: themeColors.primaryRed
  }
}))

export default memo(ProductPrice)
