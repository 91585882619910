import request from "@starrepublic/epi/api/request"

const content = {
  getAlternativeUrl: (
    currentLanguage: string,
    targetLanguage: string,
    contentUrl: string
  ) =>
    request.get<string>(
      `/contents/langroute/${currentLanguage}/${targetLanguage}/${contentUrl}`
    )
}

export default content
