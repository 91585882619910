import classNames from "classnames"
import useStore from "global-hook-store"

import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Zoom from "@material-ui/core/Zoom"
import CloseIcon from "@material-ui/icons/Close"

import Search from "icons/Search"
import SearchStore from "store/SearchStore"

const SearchBarButton: React.FC = () => {
  const classes = useStyles()

  const {
    state: { isSearchBarOpen },
    actions: { resetResult, setSearchBarOpen }
  } = useStore(SearchStore)

  const handleToggle = () => {
    setSearchBarOpen(!isSearchBarOpen).then(() => {
      resetResult()
    })
  }

  return (
    <IconButton
      id="search-button"
      aria-controls={isSearchBarOpen ? "searchOpen" : undefined}
      aria-haspopup="true"
      onClick={handleToggle}
      className={classNames(classes.headerButton, classes.root)}
    >
      <Zoom in={isSearchBarOpen}>
        <div className={classes.icon}>
          <CloseIcon />
        </div>
      </Zoom>
      <Zoom in={!isSearchBarOpen}>
        <div className={classes.icon}>
          <Search />
        </div>
      </Zoom>
    </IconButton>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    root: {
      padding: `${spacing(2)}px ${spacing(1.5)}px`,
      [breakpoints.down("sm")]: {
        padding: `${spacing(1.5)}px`
      }
    },
    headerButton: {
      color: themeColors.white,
      "& > span": {
        height: 24,
        width: 24
      }
    },
    icon: {
      position: "absolute",
      height: 24,
      width: 24
    }
  })
)

export default SearchBarButton
