const getTypeOfLink = (link: string) => {
  switch (true) {
    case new RegExp(/^(http(s?):)/).test(link):
      return "external"

    case link.startsWith("/"):
      return "internal"

    case link.startsWith("mailto:" || "tel"):
      return "contact"

    default:
      return ""
  }
}

const getBlockIdFromLink = (link: string) => {
  return link.split("/ ").toString().split("/").pop()
}

export { getTypeOfLink, getBlockIdFromLink }
