import { CSSProperties, useEffect, useRef, useState } from "react"
import classNames from "classnames"

import Box, { BoxProps } from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { isSearchBot } from "utils/device"

import useOnScreen from "hooks/useOnScreen"
import Overlay from "./Overlay"

export type ImageProps = JSX.IntrinsicElements["img"] & {
  lazy?: boolean
  color?: string
  containerProps?: BoxProps
  imageClassName?: string
  objectFit?: CSSProperties["objectFit"]
  relative?: boolean
  inline?: boolean
  isCategoryHeader?: boolean
  aspectRatio?: number
  overlay?: number
}

const TRANSITION_DURATION = 250
const TRANSITION_OPTIONS = `${TRANSITION_DURATION}ms cubic-bezier(0.4, 0.0, 0.2, 1)`
const IMAGE_SIZE_BASIS = 128

export const nearestDimension = (value: number, upperBound?: boolean) =>
  Math.max(
    IMAGE_SIZE_BASIS,
    value - (value % IMAGE_SIZE_BASIS) + (upperBound ? IMAGE_SIZE_BASIS : 0)
  )

const generateSourceSetValues = (url: string, containerWidth: number) => {
  const width = nearestDimension(containerWidth, true)
  const queryBinder = url.includes("?") ? "&" : "?"

  const srcSetValues = [] as string[]
  Array.from({ length: 2 }, (v, k) => k + 1).forEach((v, index) => {
    srcSetValues.push(`${url}${queryBinder}w=${width * v} ${index + 1}x`)
  })

  return srcSetValues.toString()
}

const useStyles = makeStyles(() => ({
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    maxHeight: "100vh",
    position: "absolute"
  },
  container: {
    maxHeight: "100vh",
    position: "relative"
  }
}))

type ImageStyleProps = {
  objectFit: CSSProperties["objectFit"]
  inline: boolean
  lazy?: boolean
  loaded?: boolean
}

type ContainerStyleProps = {
  color?: string
  relative: boolean
}

const imageStyle = ({ objectFit, inline, lazy, loaded }: ImageStyleProps) => ({
  ...(objectFit && objectFit !== "cover" && { objectFit }),
  ...(inline && { display: "block" }),
  ...(inline && { position: "initial" as CSSProperties["position"] }),
  opacity: loaded || !lazy ? 1 : 0,
  transition: `opacity ${TRANSITION_OPTIONS}`
})

const containerStyle = ({ color, relative }: ContainerStyleProps) => ({
  ...(color && { backgroundColor: color }),
  ...(!relative && { position: "initial" as CSSProperties["position"] })
})

/** Never use directly - use ResponsiveImage */
const Image = ({
  src,
  lazy,
  color,
  style,
  className,
  containerProps: { className: containerClassName, ...containerProps } = {},
  relative = true,
  objectFit = "cover",
  inline = false,
  overlay,
  alt,
  ...rest
}: ImageProps) => {
  const ref = useRef<HTMLImageElement>(null)

  const classes = useStyles()

  const isLazyLoaded = lazy && !isSearchBot()

  const [width, setWidth] = useState<number | null>(null)
  const [loaded, setLoaded] = useState(!isLazyLoaded)

  const baseProps = {
    ...rest,
    ref,
    style: {
      ...style,
      opacity: isLazyLoaded ? 0 : 1
    },
    className: classNames(classes.image, className)
  }
  let isOnScreen = useOnScreen(ref)
  const isSmall = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))
  const isMobile = useMediaQuery(({ breakpoints }) => breakpoints.down("xs"))

  const [srcSet, setSrcSet] = useState("")
  const [scaledSrc, setScaledSrc] = useState("")

  isOnScreen = !lazy || isOnScreen

  useEffect(() => {
    if (ref.current) {
      const width = ref.current && ref.current.offsetWidth
      setWidth(width)
    }
  }, [ref, isMobile, isSmall, isOnScreen])

  useEffect(() => {
    if (src && width && isOnScreen) {
      // Generate srcSet and scaledSrc
      const srcSetValues = generateSourceSetValues(src, width)
      setSrcSet(srcSetValues)
      const scaled = `${src}?w=${nearestDimension(width, true)}`
      setScaledSrc(scaled)
    }
  }, [src, width, isOnScreen])

  useEffect(() => {
    // Add loading event listener for opacity transition effect
    const imageRef = ref.current

    const onLoad = () => {
      setLoaded(true)

      if (imageRef) {
        imageRef.removeEventListener("load", onLoad)
      }
    }

    if (imageRef) {
      imageRef.addEventListener("load", onLoad)
    }

    return () => {
      setLoaded(false)

      if (imageRef) {
        imageRef.removeEventListener("load", onLoad)
      }
    }
  }, [ref])

  return (
    <Box
      className={classNames(classes.container, containerClassName)}
      style={containerStyle({ color, relative })}
      {...containerProps}
    >
      {overlay ? <Overlay opacity={overlay || 0} overlayIndex /> : null}
      <img
        draggable={false}
        // {...lazy && { loading: "lazy" }}
        {...baseProps}
        srcSet={srcSet}
        src={scaledSrc}
        alt={alt}
        style={imageStyle({
          objectFit,
          inline,
          lazy: isLazyLoaded,
          loaded
        })}
      />
    </Box>
  )
}

export default Image
