import { useTranslation } from "react-i18next"

import Box from "@material-ui/core/Box"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import PricePerUnit from "common/Product/PricePerUnit"
import ProductPrice from "common/Product/ProductPrice"
import Typography from "common/Typography"
import { LineItem } from "models/Cart"
import ItemActions from "./ItemActions"

type Props = { item: LineItem }

const OrderOverviewTableRow: React.FC<Props> = ({ item }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.imgCell}>
        <CardMedia image={item.properties.imageUrl} className={classes.media} />
      </TableCell>
      <TableCell className={classes.product}>
        <Typography variant={"body2"}>{item.code}</Typography>
        <Typography variant={"link2"}>
          {item.properties.productDisplayName}
        </Typography>
        <Typography variant="body2">
          {item.properties.variationValue}
        </Typography>

        <Hidden mdUp>
          {item.properties.isDigitalService ? (
            <PricePerUnit
              price={item.properties.variationPricePerX || ""}
              unit={item.properties.variationPricePerXUnitType || ""}
            />
          ) : (
            <ProductPrice
              price={{
                displayPrice: item.properties.originalPriceString,
                amount: item.properties.originalPrice
              }}
              discountedPrice={{
                displayPrice: item.placedPriceString,
                amount: item.placedPrice
              }}
              size={"tiny"}
              isInline={true}
            />
          )}
        </Hidden>
      </TableCell>
      <Hidden smDown>
        <TableCell className={classes.noWrap}>
          {item.properties.isDigitalService ? (
            <PricePerUnit
              price={item.properties.variationPricePerX || ""}
              unit={item.properties.variationPricePerXUnitType || ""}
            />
          ) : (
            <ProductPrice
              price={{
                displayPrice: item.properties.originalPriceString,
                amount: item.properties.originalPrice
              }}
              discountedPrice={{
                displayPrice: item.placedPriceString,
                amount: item.placedPrice
              }}
              size={"tiny"}
            />
          )}
        </TableCell>
        <TableCell>
          <ItemActions item={item} />
        </TableCell>
        <TableCell align="right" className={classes.noWrap}>
          <Typography variant={"priceTiny"}>
            {item.extendedPlacedPriceString}
          </Typography>
        </TableCell>
      </Hidden>
      <Hidden mdUp>
        <TableCell align="right">
          <Grid container direction="column" alignItems="flex-end">
            <Box pb={1}>
              <ItemActions item={item} />
            </Box>
            <Typography variant={"priceTiny"} className={classes.noWrap}>
              {`${t("cart.total_table_header")} ${
                item.extendedPlacedPriceString
              }`}
            </Typography>
          </Grid>
        </TableCell>
      </Hidden>
    </TableRow>
  )
}

const useStyles = makeStyles(({ breakpoints }) => ({
  tableRow: {
    "& td": {
      paddingTop: 16,
      paddingBottom: 16
    }
  },
  imgCell: {
    width: 100
  },
  media: {
    width: 50,
    height: 50
  },
  product: {
    paddingLeft: 0
  },
  price: {
    fontWeight: 300
  },
  noWrap: {
    whiteSpace: "nowrap"
  },
  quantity: {
    display: "flex",
    alignItems: "left",
    height: "100%",
    [breakpoints.down("sm")]: {
      justifyContent: "flex-end",
      lineHeight: 1.4
    }
  }
}))

export default OrderOverviewTableRow
