import { Fragment, useState } from "react"
import useStore from "global-hook-store"
import { useLocalStore } from "global-hook-store"

import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import { makeStyles } from "@material-ui/core/styles"

import { UpdateListArticle } from "api/anonymousShoppingList"
import Button from "common/Button"
import Typography from "common/Typography"
import AddToCartCheckIcon from "icons/AddToCartCheckIcon"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import {
  addToCart,
  addToShoppinglist,
  fenceCalculatorStepperClick
} from "services/StarGA"
import AddToCartStore from "store/AddToCartStore"
import AnonymousShoppingListStore from "store/AnonymousShoppingListStore"
import CartStore from "store/CartStore"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"
import marketStore from "store/MarketStore"
import orderStore from "store/OrderStore"
import SiteSettingsStore from "store/SiteSettingStore"
import snackbarStore, { SnackbarModel } from "store/SnackbarStore"
import { themeColors } from "theme"
import AlertStepper from "./AlertStepper"
import { FenceDataItem } from "./types"

type SummaryProps = {
  currencySymbol: string
  resetStepper: () => void
}

const Summary = ({ currencySymbol, resetStepper }: SummaryProps) => {
  const displayPdfButton = false
  const classes = useStyles()
  const [successfullyAdded, setSuccessfullyAdded] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [confirmResetOpen, setConfirmResetOpen] = useState(false)

  const {
    selectedCategory,
    selectedAccessories,
    categories,
    lengthOfFence,
    totalLengthOfFence,
    numberOfPosts,
    numberOfCornerPosts,
    numberOfFreeStandingGateOpenings,
    numberOfGateOpeningsInCorners,
    numberOfWireRows,
    selectedEnergizer,
    totalItems,
    fenceCalculatorLabels
  } = useFenceCalculatorStore()

  const isMainsAndBatteryEnergizer =
    selectedEnergizer.name &&
    selectedEnergizer.flags.mains &&
    selectedEnergizer.flags.battery

  const totalGateOpenings =
    numberOfFreeStandingGateOpenings + numberOfGateOpeningsInCorners

  const {
    state: { defaultMarket }
  } = useStore(marketStore)

  const {
    state: { isLoading },
    actions: { setIsLoading }
  } = useLocalStore(AddToCartStore)

  const {
    actions: { addItemsToCart }
  } = useStore(orderStore)

  const {
    actions: { fetchShoppingList, addToShoppingList }
  } = useStore(AnonymousShoppingListStore)

  const {
    actions: { openSnackbar }
  } = useStore(snackbarStore)

  const {
    actions: { getByCartTypeName }
  } = useStore(CartStore)

  const {
    state: { _siteType }
  } = useStore(SiteSettingsStore)
  // TODO: Ask about design pattern + if any other siteType values should show/add to cart
  const isWebShop = _siteType === "webShop"
  const isNoWebShop = _siteType === "noWebShop"
  const isPermanentSelected = selectedCategory === categories[0].category
  const currencyCode = defaultMarket && defaultMarket.defaultCurrency

  // TODO: Find a better solution to this or refactor api call instead of existing condition.
  // addToCart and addToShoppingList api's accepts different type of payload.
  const productsBundle = totalItems.map((item) => {
    return {
      itemCode: item.variant.articleNumber,
      quantity: item.variant.quantity
      // displayName: item.name
    }
  })

  const itemsBundle = totalItems.map((item) => {
    return {
      articleNumber: item.variant.articleNumber,
      quantity: item.variant.quantity
    }
  })

  const convertSelectedAccessories = selectedAccessories.map((item) => {
    return {
      articleNumber: item.itemCode,
      quantity: item.quantity
    }
  })

  const itemsForShoppingList = [...itemsBundle, ...convertSelectedAccessories]

  const handleAddToCart = async () => {
    if (!isLoading) {
      setSuccessfullyAdded(false)
      await setIsLoading(true)
    }
    await addItemsToCart([...selectedAccessories, ...productsBundle]).then(
      (state) => {
        setDisabled(true)
        if (state.bundleToCart.error) {
          openSnackbar({
            options: {
              message: "Error"
            },
            type: "error"
          })
          setSuccessfullyAdded(false)
          setDisabled(false)
        } else if (
          state.bundleToCart.data &&
          state.bundleToCart.data.validationIssues &&
          state.bundleToCart.data.validationIssues.length > 0
        ) {
          const index =
            state.bundleToCart.data!.validationIssues[0].value.split("|")
          const message = index[1]
          const type = index[0].toLowerCase() as SnackbarModel["type"]
          openSnackbar({
            options: {
              message
            },
            type
          })
          setSuccessfullyAdded(false)
          setDisabled(false)
        } else {
          setSuccessfullyAdded(true)

          totalItems.forEach((item) => {
            const product = {
              code: item.variant.articleNumber,
              name: item.name,
              amount: item.variant.price,
              quantity: item.variant.quantity,
              category: "" // item.category
            }
            addToCart(product, currencyCode)
          })
          selectedAccessories.forEach((item) => {
            const accessories = {
              code: item.itemCode,
              name: item.displayName,
              amount: item.price,
              quantity: item.quantity,
              category: "" // item.category
            }
            addToCart(accessories, currencyCode)
          })

          const options = {
            message: fenceCalculatorLabels.addedToCart
          }
          openSnackbar({
            options,
            type: "product"
          })
          fenceCalculatorStepperClick(
            fenceCalculatorLabels.addedToCart,
            "Submit to cart",
            8 // last step
          )
        }
        getByCartTypeName("basket")
        setIsLoading(false)
        setDisabled(false)
      }
    )
  }

  const handleAddToShoppingList = async () => {
    if (!isLoading) {
      setSuccessfullyAdded(false)
      setDisabled(true)
      await setIsLoading(true)
      await fetchShoppingList().then(async (state) => {
        if (state.shoppingList && state.shoppingList.data) {
          for (let index = 0; index < itemsForShoppingList.length; index++) {
            const item = itemsForShoppingList[index]
            const updateList = {
              articleNumber: item.articleNumber,
              quantity: item.quantity
            } as UpdateListArticle
            await addToShoppingList(updateList)
          }
          await fetchShoppingList()
          setSuccessfullyAdded(true)
          const options = {
            message: fenceCalculatorLabels.addedToShoppingList
          }
          openSnackbar({
            options,
            type: "product"
          })
          totalItems.forEach((item) => {
            const shoppingList = {
              code: item.variant.articleNumber,
              name: item.name
            }
            addToShoppinglist(shoppingList)
          })
          selectedAccessories.forEach((item) => {
            const shoppingList = {
              code: item.itemCode,
              name: item.displayName
            }
            addToShoppinglist(shoppingList)
          })
        }
        fenceCalculatorStepperClick(
          fenceCalculatorLabels.addedToShoppingList,
          "Submit to shopping list",
          8 // last step
        )
        setSuccessfullyAdded(true)
        setIsLoading(false)
        setDisabled(false)
      })
    }
  }

  const sumAccessoriesTotalPrice = (accessories: FenceDataItem[]) => {
    let total = 0
    for (let i = 0; i < accessories.length; i++) {
      total += accessories[i].price * accessories[i].quantity
    }
    return total
  }

  const totalPrice = () => {
    const accessoriesTotalPrice = sumAccessoriesTotalPrice(selectedAccessories)

    const totalCost = totalItems.reduce((acc, item) => {
      if (item.variant.quantity && item.variant.price) {
        return acc + item.variant.quantity * item.variant.price
      } else return acc
    }, 0)

    return totalCost + accessoriesTotalPrice
  }

  return (
    <>
      <Box mx="auto" my={8}>
        <Typography
          align="center"
          className={classes.heading}
          variant="headline2"
        >
          {fenceCalculatorLabels.summaryDescription}
        </Typography>
      </Box>
      {isPermanentSelected && (
        <Box mb={3}>
          <Typography className={classes.summaryLabel}>
            {fenceCalculatorLabels.summaryNotIncluded}
          </Typography>
          <Typography className={classes.summaryInfo}>
            {Math.ceil(numberOfPosts)}{" "}
            {fenceCalculatorLabels.summaryWoodenPostsNeeded}
          </Typography>
        </Box>
      )}

      <Box mb={2}>
        <Typography className={classes.summaryLabel}>
          {isPermanentSelected
            ? fenceCalculatorLabels.fenceTypePermanent
            : fenceCalculatorLabels.fenceTypeTemporary}{" "}
          {fenceCalculatorLabels.fence}
        </Typography>

        <Box mt={1} display="flex" flexDirection="column">
          <Box display="flex">
            <Typography bold variant="preamble" className={classes.darkText}>
              {totalLengthOfFence} {fenceCalculatorLabels.metresSuffix}
            </Typography>
            &nbsp;
            <Typography className={classes.summaryInfo}>
              ({lengthOfFence} x {numberOfWireRows}{" "}
              {fenceCalculatorLabels.wiresSuffix})
            </Typography>
          </Box>
          <Box>
            {numberOfCornerPosts > 0 && (
              <Typography className={classes.summaryInfo}>
                {numberOfCornerPosts} {fenceCalculatorLabels.cornersSuffix}
              </Typography>
            )}

            {totalGateOpenings > 0 && (
              <Typography className={classes.summaryInfo}>
                {numberOfFreeStandingGateOpenings +
                  numberOfGateOpeningsInCorners}{" "}
                {totalGateOpenings > 1
                  ? fenceCalculatorLabels.gateOpeningsSuffix
                  : fenceCalculatorLabels.gateOpeningSuffix}
              </Typography>
            )}

            {selectedEnergizer.name && (
              <Typography className={classes.summaryInfo}>
                {selectedEnergizer.flags.mains &&
                  fenceCalculatorLabels.energizerMains}{" "}
                {isMainsAndBatteryEnergizer && "/ "}
                {selectedEnergizer.flags.battery &&
                  fenceCalculatorLabels.energizerBattery}{" "}
                {fenceCalculatorLabels.powerSuffix}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box mt={4}>
        {totalItems.length > 0 && (
          <>
            <Box mb={2}>
              <Typography className={classes.summaryLabel}>
                {fenceCalculatorLabels.summaryFenceMaterials}
              </Typography>
            </Box>
            <Box>
              {totalItems.map(
                (item, key) =>
                  item.variant &&
                  item.variant.quantity && (
                    <Fragment key={`${item.variant.articleNumber}-${key}`}>
                      <Divider style={{ margin: ".25rem 0" }} />
                      <Box display="flex" flexDirection="column">
                        <div className={classes.materialLabel}>{item.name}</div>
                        <Box display="flex" justifyContent="space-between">
                          <Typography className={classes.darkText}>
                            {item.variant.quantity} x{" "}
                            {isWebShop && (
                              <span>
                                {item.variant.price.toFixed(2)} {currencySymbol}
                              </span>
                            )}
                          </Typography>
                          {isWebShop && (
                            <Typography className={classes.price}>
                              {(
                                item.variant.quantity * item.variant.price
                              ).toFixed(2)}{" "}
                              {currencySymbol}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Fragment>
                  )
              )}
            </Box>
          </>
        )}
        <Box>
          {selectedAccessories.length > 0 && (
            <Box mt={2}>
              <Box mb={2}>
                <Typography className={classes.summaryAccessoriesLabel}>
                  {fenceCalculatorLabels.summaryFenceAccessories}
                </Typography>
              </Box>
              {selectedAccessories.map((accessory, key) => (
                <Fragment key={`${accessory.itemCode}-${key}`}>
                  <Divider style={{ margin: ".25rem 0" }} />
                  <Box display="flex" flexDirection="column">
                    <div className={classes.materialLabel}>
                      {accessory.displayName}
                    </div>
                    <Box display="flex" justifyContent="space-between">
                      <Typography className={classes.darkText}>
                        {accessory.quantity} x{" "}
                        {isWebShop && (
                          <span>
                            {accessory.price.toFixed(2)} {currencySymbol}
                          </span>
                        )}
                      </Typography>
                      {isWebShop && (
                        <Typography className={classes.price}>
                          {(accessory.quantity * accessory.price).toFixed(2)}{" "}
                          {currencySymbol}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Fragment>
              ))}
            </Box>
          )}
        </Box>
        {isWebShop && (!!totalItems.length || !!selectedAccessories.length) && (
          <div className={classes.totalPriceContainer}>
            {fenceCalculatorLabels.summaryTotal} {totalPrice().toFixed(2)}{" "}
            {currencySymbol}
          </div>
        )}
        <Box
          mt={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Button
            style={{
              marginBottom: !confirmResetOpen ? "2rem" : 0,
              color: themeColors.infoDark
            }}
            variant="text"
            onClick={() => setConfirmResetOpen(true)}
            disabled={confirmResetOpen}
          >
            {fenceCalculatorLabels.reset}
          </Button>
          <AlertStepper
            alertMessage={fenceCalculatorLabels.resetWarning}
            confirmButtonText={fenceCalculatorLabels.confirm}
            cancelButtonText={fenceCalculatorLabels.cancel}
            onResetStepper={resetStepper}
            confirmChangeOpen={confirmResetOpen}
            onSetConfirmChangeOpen={(isConfirmResetOpen) =>
              setConfirmResetOpen(isConfirmResetOpen)
            }
          />
          {(isWebShop || isNoWebShop) && (
            <Button
              style={{
                margin: "0.25rem 0",
                padding: "0.875rem 0",
                fontSize: "0.875rem",
                fontWeight: 700
              }}
              fullWidth
              size="small"
              variant="primary"
              onClick={isWebShop ? handleAddToCart : handleAddToShoppingList}
              disabled={disabled}
              endIcon={
                isLoading ? (
                  <ButtonLoaderIcon />
                ) : (
                  successfullyAdded && <AddToCartCheckIcon />
                )
              }
            >
              {isWebShop
                ? fenceCalculatorLabels.addToCart
                : fenceCalculatorLabels.addToShoppingList}
            </Button>
          )}
          {displayPdfButton && (
            <Button
              style={{
                margin: "0.25rem 0",
                padding: "0.625rem 0",
                fontSize: "0.875rem",
                fontWeight: 700,
                color: themeColors.infoDark,
                border: `1px solid ${themeColors.infoDark}`
              }}
              fullWidth
              onClick={() =>
                console.log("TODO: Implement functionality to send copy as PDF")
              }
            >
              {fenceCalculatorLabels.sendAsPdf}
            </Button>
          )}
        </Box>
      </Box>
    </>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    heading: {
      fontSize: 24,
      [breakpoints.up("sm")]: {
        fontSize: 40
      }
    },
    summaryLabel: {
      color: themeColors.black,
      fontSize: "1.25rem",
      fontWeight: 700
    },
    summaryAccessoriesLabel: {
      color: themeColors.black,
      fontSize: "1rem",
      fontWeight: 700
    },
    summaryInfo: {
      color: themeColors.black,
      fontSize: "1rem",
      fontWeight: 400
    },
    materialLabel: {
      color: themeColors.primaryCyan,
      fontSize: "1rem",
      fontWeight: 700
    },
    price: {
      fontSize: "0.875rem",
      fontWeight: 700,
      color: themeColors.primaryBaseBlue,
      marginRight: spacing(1)
    },
    totalPriceContainer: {
      width: "100%",
      backgroundColor: themeColors.primaryLightCyan,
      margin: spacing(1, 0),
      padding: spacing(2, 1),
      border: `1px solid ${themeColors.mediumGray}`,
      borderLeftStyle: "none",
      borderRightStyle: "none",
      textAlign: "right",
      fontSize: "1rem",
      fontWeight: 700,
      color: themeColors.primaryBaseBlue
    },
    darkText: {
      color: themeColors.black
    }
  })
)

export default Summary
