import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const BulbDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M11.96 28h8.053M15.94 8.984a3.852 3.852 0 0 0-3.851 3.852m12.663-.02a8.754 8.754 0 1 0-12.749 7.79v2.067h7.991v-2.066a8.753 8.753 0 0 0 4.758-7.791Z"
      fill="none"
    />
  </SvgIcon>
)

export default BulbDeLaval
