import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { blockDisplay } from "utils/blockDisplay"

import TextAndMediaBlock from "blocks/TextAndMediaBlock"
import { TextAndVideoBlockCarouselItems } from "blocks/TextAndMediaBlock/types"
import ContentCarousel from "common/ContentCarousel"
type TextAndMediaCarouselBlockProps = BlockProps<TextAndVideoBlockCarouselItems>

const TextAndMediaCarouselBlock: React.FC<TextAndMediaCarouselBlockProps> = ({
  content,
  blockInfo,
  propsInfo
}) => {
  const classes = useStyles()
  const _display = blockDisplay(blockInfo)

  const isSmDown = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"))
  const isXsDown = useMediaQuery(({ breakpoints }) => breakpoints.down("xs"))

  const { items } = content
  const showOneImage = true
  return (
    <ContentCarousel
      className={classNames(classes.root, {
        [classes.preAndNextButtonSmall]:
          _display.half || _display.twoThird || isSmDown,
        [classes.preAndNextButtonBig]: _display.oneThird
      })}
      arrowsShow={true}
      slides={1}
      fullWithOnMobile={isXsDown}
      blockSlider={true}
      infiniteSlider={true}
    >
      {items.map((slide, index) => {
        return (
          <TextAndMediaBlock
            key={index}
            showOneImage={showOneImage}
            propsInfo={propsInfo[index]}
            content={slide}
            blockInfo={blockInfo}
            isCarousel
          />
        )
      })}
    </ContentCarousel>
  )
}
export default TextAndMediaCarouselBlock

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    height: "100%",
    "& .slick-track": {
      display: "flex",
      [breakpoints.up("sm")]: {
        height: "100%"
      }
    },
    "& .slick-slider, & .slick-list": {
      height: "100%"
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%"
      }
    }
  },
  preAndNextButtonSmall: {
    "& .slick-prev, & .slick-next": {
      top: 0,
      marginTop: "28%"
    }
  },
  preAndNextButtonBig: {
    "& .slick-prev, & .slick-next": {
      top: 0,
      marginTop: "38%"
    }
  }
}))
