import { BlockProps } from "@starrepublic/epi/cms"
import {
  CmsContextProps,
  withCmsContext
} from "@starrepublic/epi/cms/components/CmsContext"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import withWidth, { WithWidth } from "@material-ui/core/withWidth"

import BlockBackground from "blocks/BlockBackground"
import BlockGrid from "common/BlockGrid"
import Statistic, { StatisticContent } from "common/Statistic"
import Typography from "common/Typography"

export type StatisticsBlockContent = {
  _id: number
  _type: string
  title: string
  preamble: string
  theme?: string
  statistics: Array<StatisticContent>
}

type OwnProps = BlockProps<StatisticsBlockContent>

type Props = CmsContextProps & OwnProps & WithWidth

const StatisticsBlock: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { content, blockInfo } = props
  const darkTheme = content.theme
    ? content.theme.toLowerCase() === "#103d82"
    : false

  const isBgColorWhite =
    content.theme && content.theme.toLowerCase() === "#ffffff" ? true : false

  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))

  return (
    <BlockBackground
      content={content}
      className={classes.root}
      blockInfo={blockInfo}
    >
      <BlockGrid
        container
        direction={isSmUp ? "row" : "column"}
        wrap={isSmUp ? "wrap" : "nowrap"}
        justifyContent={"center"}
        alignItems={"center"}
        className={classNames({
          [classes.heroGrid]: content.title && content.title.length > 1
        })}
      >
        <Box
          width={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {(content.title || content.preamble) && (
            <Box textAlign="center" mb={{ xs: 3, md: 6, sm: 6 }}>
              {content.title && (
                <Typography
                  variant="headline2"
                  isDarkTheme={darkTheme}
                  className={classes.title}
                >
                  {content.title}
                </Typography>
              )}
              {content.preamble && (
                <Typography variant="body1" isDarkTheme={darkTheme}>
                  {content.preamble}
                </Typography>
              )}
            </Box>
          )}
          {content.statistics && (
            <Grid
              container
              direction="row"
              wrap="wrap"
              justifyContent="center"
              spacing={isSmUp ? 3 : 1}
            >
              {content.statistics.map((stat, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
                  <Statistic
                    isBgColorWhite={isBgColorWhite}
                    statistic={stat}
                    statStyles={{
                      statClass: darkTheme
                        ? classes.stat + " " + classes.statDark
                        : classes.stat,
                      descriptionClass: darkTheme
                        ? classes.descriptionDark
                        : "",
                      subTextClass: darkTheme
                        ? classes.subTextClass
                        : classes.subTextClassBlue
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </BlockGrid>
    </BlockBackground>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    root: {
      position: "relative",
      padding: spacing(0, 3.5),
      [breakpoints.down("sm")]: {
        padding: spacing(0, 0.5)
      }
    },
    heroGrid: {
      position: "relative",
      padding: `${spacing(3)}px 0`,
      [breakpoints.up("md")]: {
        padding: `${spacing(8)}px 0`
      }
    },
    title: {
      marginBottom: spacing(2),
      fontSize: 36
    },
    stat: {
      fontSize: 44,
      marginBottom: spacing(1),
      color: themeColors.primaryCyan,
      [breakpoints.down("sm")]: {
        fontSize: 36
      },
      [breakpoints.down("xs")]: {
        fontSize: 36
      }
    },
    statDark: {
      color: themeColors.white
    },
    descriptionDark: {
      color: themeColors.white
    },
    subTextClass: {
      marginTop: spacing(1),
      color: themeColors.white
    },
    subTextClassBlue: {
      marginTop: spacing(1),
      color: themeColors.primaryBlue
    },
    gridContainer: {
      backgroundColor: themeColors.offWhite
    }
  })
)

export default withCmsContext(withWidth()(StatisticsBlock))
