import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const SearchDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="m27.333 27.333-6.889-6.889m0 0A9.16 9.16 0 1 0 7.491 7.491a9.16 9.16 0 0 0 12.953 12.953Z"
      fill="none"
    />
  </SvgIcon>
)

export default SearchDeLaval
