import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const CustomersDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M4 21.333v-12h6.667M4 21.333V28h24v-6.667m-24 0h24m0 0v-12h-6.667m-10.666 0V4h10.666v5.333m-10.666 0h10.666M16 16v2.667"
      fill="none"
    />
  </SvgIcon>
)

export default CustomersDeLaval
