import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const CheckboxForm: React.FC<Props> = (props) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="#fff" stroke="currentColor" strokeWidth="2" d="M4 4h16v16H4z" />
  </SvgIcon>
)

export default CheckboxForm
