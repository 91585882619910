import { Fragment } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { makeStyles } from "@material-ui/core/styles"

import LinkButton from "common/LinkButton"
import Typography from "common/Typography"
import AuthStore from "store/AuthStore"
import ImpersonationStore from "store/ImpersonationStore"
import MenuListItem from "./MenuListItem"

type MenuItem = {
  title: string
  url: string
  isSelected: boolean
  subItems?: MenuItem[]
}

type Props = {
  subNavigation: MenuItem
}

const PageListMenu: React.FC<Props> = ({ subNavigation }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { user }
  } = useStore(AuthStore)

  const {
    actions: { open }
  } = useStore(ImpersonationStore)

  const canImpersonate = user.data && user.data.canImpersonate

  return (
    <Box my={4}>
      <Box mb={2}>
        {subNavigation.url && (
          <LinkButton link={subNavigation.url}>
            <Typography variant="headline5">{subNavigation.title}</Typography>
          </LinkButton>
        )}
      </Box>
      <List component="nav" className={classes.root}>
        {subNavigation.subItems &&
          subNavigation.subItems.length > 0 &&
          subNavigation.subItems.map((item, i) => (
            <Fragment key={i}>
              <MenuListItem {...item} />
            </Fragment>
          ))}
        {canImpersonate && (
          <ListItem className={classes.listItem} onClick={() => open()}>
            <Typography>{t("impersonation.switch_user")}</Typography>
          </ListItem>
        )}
      </List>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors }, breakpoints }) => ({
    root: {
      padding: 0,
      maxWidth: 334,
      marginRight: spacing(2),
      paddingLeft: spacing(1),
      background: themeColors.white,
      [breakpoints.down("sm")]: {
        maxWidth: "100%",
        paddingTop: spacing(0),
        marginRight: 0
      }
    },
    listItem: {
      margin: `${spacing(1)}px 0`,
      paddingTop: spacing(0.5),
      paddingBottom: spacing(0.5),
      paddingLeft: 0,
      cursor: "pointer",
      "& p": {
        color: themeColors.primaryCyan,
        fontSize: 16
      },
      "& > div": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        color: themeColors.primaryCyan,
        [breakpoints.down("sm")]: {
          padding: `0px ${spacing(3)}px`
        }
      }
    }
  })
)

export default PageListMenu
