import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { blockDisplay } from "utils/blockDisplay"
import DisplayOptions from "utils/ui/DisplayOptions"

import Typography from "common/Typography"
import { themeColors } from "theme"
import GridViewCard from "./GridViewCard"
import ListViewRow from "./ListViewRow"
import { ChildLinksBlockContent, ChildLinksContent } from "./types"

type Props = BlockProps<ChildLinksBlockContent>

const ChildLinksBlock: React.FC<Props> = ({ content, blockInfo }) => {
  const classes = useStyles()
  const GridView = content.listItemsViewType === "gridView" ? true : false
  const _display = blockDisplay(blockInfo)

  return content &&
    content.childPageLinks &&
    content.childPageLinks.length > 0 ? (
    <Box
      py={{ xs: 3, sm: 6, md: 8 }}
      className={classNames({
        [classes.rootFull]: _display
      })}
    >
      {(content.header || content.description) && (
        <Box pb={4}>
          {content.header && (
            <Box>
              <Typography align="center" variant="headline2">
                {content.header}
              </Typography>
            </Box>
          )}

          {content.description && (
            <Box mt={content.header ? 2 : 0}>
              <Typography align="center" variant="body1">
                {content.description}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      <Grid
        className={classNames({
          [classes.lightBorder]: !GridView,
          [classes.lighterBorder]:
            !GridView && content.sectionTheme === themeColors.primaryBlue,
          [classes.gridViewWidth]: GridView,
          [classes.content]: GridView
        })}
        container
        justifyContent={GridView ? "flex-start" : "center"}
        direction={GridView ? "row" : "column"}
      >
        {content.childPageLinks.map((item: ChildLinksContent) => {
          return (
            <div key={item.heading}>
              {GridView ? (
                <Grid
                  className={classes.articleContainer}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={
                    Number(DisplayOptions[blockInfo!["_display"]]) === 0.5
                      ? 6
                      : 3
                  }
                >
                  <GridViewCard {...item} sectionTheme={content.sectionTheme} />
                </Grid>
              ) : (
                <Grid container>
                  <ListViewRow {...item} sectionTheme={content.sectionTheme} />
                </Grid>
              )}
            </div>
          )
        })}
      </Grid>
    </Box>
  ) : null
}

const useStyles = makeStyles(
  ({ common: { themeColors }, breakpoints, spacing }) => ({
    rootFull: {
      margin: spacing(1.5, 2),
      [breakpoints.up("sm")]: {
        margin: spacing(3, 3)
      },
      [breakpoints.up("md")]: {
        marginBlock: spacing(4)
      }
    },
    gridViewWidth: {
      width: `calc(100% + ${spacing(3)}px)`,
      marginInline: spacing(-1.5)
    },
    content: {
      "& > div": {
        display: "contents"
      }
    },
    articleContainer: {
      padding: `0 ${spacing(1.5)}px 0`
    },
    lightBorder: {
      maxWidth: 814,
      margin: "0 auto",
      width: "100%",
      "& > div:last-child": {
        borderBottom: `1px solid ${themeColors.grayBorder}`
      }
    },
    lighterBorder: {
      "& > div:last-child": {
        borderBottom: `1px solid ${themeColors.transparent}`
      }
    }
  })
)

export default ChildLinksBlock
