import { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const Customer: React.FC<Props> = (props) => (
  <svg
    {...props}
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.962 12.567C8.84 13.698 8.311 16.23 6 17C3 18 1 19.125 1 23H21C21 19.125 19 18 16 17C13.688 16.229 13.16 13.698 13.038 12.567"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M11 13C8.239 13 6 10.761 6 8V6C6 3.239 8.239 1 11 1C13.761 1 16 3.239 16 6V8C16 10.761 13.761 13 11 13Z"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export default Customer
