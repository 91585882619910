import { useEffect, useRef, useState } from "react"
import useStore from "global-hook-store"
import debounce from "lodash/debounce"

import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import AddToCartCheckIcon from "icons/AddToCartCheckIcon"
import siteSettingStore from "store/SiteSettingStore"

const UpperSaleBanner: React.FC = () => {
  const classes = useStyles()

  const promoRef = useRef<HTMLDivElement>(null)

  const [styleObj, setStyleObj] = useState({})

  const debounceTimer = 1000
  const [width, setWidth] = useState(0)

  useEffect(() => {
    const handleResize = debounce(() => {
      setWidth(window.innerWidth)
    }, debounceTimer)

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(
    () => {
      if (promoRef.current) {
        setStyleObj(
          promoRef.current.clientWidth! > window.innerWidth
            ? { justifyContent: "flex-start" }
            : {}
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width, promoRef.current]
  )

  const {
    state: { header }
  } = useStore(siteSettingStore)

  return header && header.upperPromoText ? (
    <div className={classes.root}>
      <div className={classes.container} style={styleObj}>
        <Typography
          className={classes.upperPromoText}
          variant={"body1"}
          innerRef={promoRef}
        >
          <AddToCartCheckIcon className={classes.check} />{" "}
          {header.upperPromoText}
        </Typography>
      </div>
    </div>
  ) : null
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  root: {
    width: "100%",
    background: themeColors.darkBlue
  },
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    margin: "auto",
    height: 32,
    overflowX: "auto",
    overflowY: "hidden",
    justifyContent: "center"
  },
  upperPromoText: {
    color: themeColors.white,
    fontWeight: 500,
    fontSize: 12,
    height: 32,
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    padding: `0 ${spacing(1.5)}px 0 ${spacing(0.5)}px`
  },
  check: {
    height: 8,
    marginRight: 4
  }
}))

export default UpperSaleBanner
