/**
 * Material UI theme configuration
 */
import { createTheme } from "@material-ui/core/styles"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"

import themeColors from "./colors"
import { fonts } from "./typography"
export { default as themeColors } from "./colors"

const boxShadow = "0px 5px 10px rgba(0, 0, 0, 0.03)"
const boxShadow3 =
  "0px 0px 1px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.1)"
const breakpoints = createBreakpoints({})

export const pageMaxWidth = 1232
export const textMaxWidth = 700
export const aspectRatio21x9 = 21 / 9
export const aspectRatio16x9 = 16 / 9
export const aspectRatio4x3 = 4 / 3
export const aspectRatio3x2 = 3 / 2
export const aspectRatio1x1 = 1 / 1

export default createTheme({
  spacing: 8,

  common: {
    themeColors,
    boxShadow,
    gridSpacing: 12,
    gridSpacingMobile: 12,
    pageMargin: 12,
    pageMarginMobile: 4,
    pageMaxWidth,
    textMaxWidth,
    aspectRatio21x9,
    aspectRatio16x9,
    aspectRatio4x3,
    aspectRatio3x2,
    aspectRatio1x1,
    pageMaxWidthNarrow: 784
  },
  palette: {
    // themeColors: { delaval: themeColors },
    primary: {
      main: themeColors.primaryBlue,
      dark: themeColors.darkGray,
      contrastText: themeColors.white
    },
    secondary: {
      main: themeColors.whiteGray
    },
    text: {
      primary: themeColors.darkGray,
      secondary: themeColors.primaryCyan,
      disabled: themeColors.gray
    },
    background: {
      paper: themeColors.white,
      default: themeColors.white
    },
    success: {
      light: themeColors.successLight,
      main: themeColors.successMain,
      dark: themeColors.successDark
    },
    info: {
      light: themeColors.infoLight,
      main: themeColors.infoDark,
      dark: themeColors.infoDark
    },
    warning: {
      light: themeColors.warningLight,
      main: themeColors.warningMain,
      dark: themeColors.warningDark
    },
    error: {
      light: themeColors.errorLight,
      main: themeColors.primaryRed,
      dark: themeColors.primaryRed
    }
  },
  shape: {
    borderRadius: 0
  },

  shadows: [
    "none",
    "none",
    "none",
    boxShadow3,
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none"
  ],

  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          color: themeColors.colorBaseBlue900,
          overflowAnchor: "none",
          "& #CybotCookiebotDialog": {
            borderRadius: "0 !important"
          },
          "& #CybotCookiebotDialogPoweredbyCybot": {
            display: "none"
          },
          "& #CybotCookiebotDialogBodyButtonsWrapper button": {
            borderRadius: "0 !important"
          },
          "& #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection:hover":
            {
              border: `2px solid ${themeColors.colorBaseBlue600}!important`,
              background: `${themeColors.colorBaseCyan400}!important`,
              color: `${themeColors.colorBaseBlue600}!important`
            },
          "& #CybotCookiebotDialogBodyButtonDecline:hover": {
            border: `2px solid ${themeColors.colorBaseBlue600}!important`,
            background: `${themeColors.colorBaseCyan400}!important`,
            color: `${themeColors.colorBaseBlue600}!important`
          },
          "& .CybotCookiebotDialogNavItem a": {
            color: `${themeColors.colorBaseGray700}!important`
          },
          "& #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive":
            {
              color: `${themeColors.infoDark}!important`
            },
          "& #CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover": {
            color: `${themeColors.infoDark}!important`
          }
        }
      }
    },
    MuiDivider: {
      light: {
        color: themeColors.lightGray
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: themeColors.grayBorder
      },
      input: {
        paddingTop: 10,
        paddingBottom: 10,
        "&:-webkit-autofill": {
          borderRadius: 50
        }
      },
      inputAdornedEnd: {
        maxWidth: 100
      }
    },
    MuiCardContent: {
      root: {
        paddingTop: 24,
        paddingBottom: 24,
        textAlign: "center"
      }
    },
    MuiButton: {
      root: {
        fontWeight: 700,
        padding: "10px 24px",
        textTransform: "initial"
      },
      outlined: {
        padding: "9px 24px"
      },
      // Primary Button
      containedPrimary: {
        fontFamily: fonts.helveticaNeue.fontFamily,
        backgroundColor: "#0091FF",
        "&:hover": {
          backgroundColor: "#2E66BE"
        },
        "&:focus": {
          backgroundColor: "#2659A8"
        },
        "&:active": {
          backgroundColor: "#103D82"
        },
        "&[disabled]": {
          color: "#FFFFFF",
          backgroundColor: "#CCCCCC"
        }
      },
      // Secondary Button
      outlinedPrimary: {
        fontFamily: fonts.helveticaNeue.fontFamily,
        color: "#0091FF",
        borderColor: "#0091FF",
        backgroundColor: "#FFFFFF",
        "&:hover": {
          color: "#2E66BE",
          borderColor: "#2E66BE",
          backgroundColor: "#FFFFFF"
        },
        "&:focus": {
          color: "#2659A8",
          borderColor: "#2659A8",
          backgroundColor: "#E4F3FB"
        },
        "&:active": {
          color: "#2659A8",
          borderColor: "#2659A8",
          backgroundColor: "#B2E4FF"
        },
        "&[disabled]": {
          color: "#CCCCCC",
          borderColor: "#CCCCCC",
          backgroundColor: "transparent"
        }
      },
      // Text Button
      textPrimary: {
        fontFamily: fonts.helveticaNeue.fontFamily,
        color: "#0091FF",
        padding: "10px 16px",
        "&:hover": {
          color: "#2E66BE",
          backgroundColor: "#E4F3FB"
        },
        "&:focus": {
          color: "#2659A8",
          backgroundColor: "#E4F3FB"
        },
        "&:active": {
          color: "#0073CC",
          backgroundColor: "#B2E4FF"
        },
        "&[disabled]": {
          color: "#CCCCCC"
        }
      },
      // Destructive Button
      containedSecondary: {
        fontFamily: fonts.helveticaNeue.fontFamily,
        color: "#CC2900",
        backgroundColor: "#FFFFFF",
        "&:hover": {
          color: "#991F00",
          backgroundColor: "#FFD6CC"
        },
        "&:focus": {
          color: "#991F00",
          backgroundColor: "#FFD6CC"
        },
        "&:active": {
          color: "#991F00",
          backgroundColor: "#FFAD99"
        },
        "&[disabled]": {
          color: "#CCCCCC",
          backgroundColor: "transparent"
        }
      },
      // Destructive Button
      outlinedSecondary: {
        fontFamily: fonts.helveticaNeue.fontFamily,
        color: "#CC2900",
        borderColor: "#CC2900",
        backgroundColor: "#FFFFFF",
        "&:hover": {
          color: "#991F00",
          borderColor: "#991F00",
          backgroundColor: "#FFD6CC"
        },
        "&:focus": {
          color: "#991F00",
          borderColor: "#991F00",
          backgroundColor: "#FFD6CC"
        },
        "&:active": {
          color: "#991F00",
          borderColor: "#991F00",
          backgroundColor: "#FFAD99"
        },
        "&[disabled]": {
          color: "#CCCCCC",
          borderColor: "#CCCCCC",
          backgroundColor: "transparent"
        }
      }
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "transparent"
        }
      }
    },
    MuiNativeSelect: {
      select: {
        "&:focus": {
          backgroundColor: themeColors.white
        }
      },
      icon: {
        right: 7
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: `0px 0px 24px 0px`,
        [breakpoints.down("sm")]: {
          padding: 0
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        padding: 0
      },
      expandIcon: {
        color: themeColors.primaryRed
      }
    },
    MuiSnackbar: {
      root: {
        zIndex: 1000
      },
      anchorOriginTopRight: {
        top: "80px !important",
        // right: "16px !important",
        [breakpoints.down("sm")]: {
          right: 0,
          width: "100%"
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        color: themeColors.textDefault,
        "& .icon--": {
          "&success": {
            color: themeColors.successDark
          },
          "&warning": {
            color: themeColors.warningDark
          },
          "&info": {
            color: themeColors.infoDark
          },
          "&error": {
            color: themeColors.errorDark
          }
        }
      }
    },
    MuiFormHelperText: {
      root: {
        color: themeColors.primaryRed,
        borderColor: themeColors.primaryRed
      }
    },
    MuiTooltip: {
      tooltip: {
        maxWidth: 300,
        color: themeColors.white,
        backgroundColor: themeColors.primaryBaseBlue,
        fontFamily: fonts.helveticaNeue.fontFamily,
        fontSize: 14,
        lineHeight: "18px",
        padding: "6px 12px 8px",
        fontWeight: 400
      },
      arrow: {
        color: themeColors.primaryBaseBlue
      }
    },
    MuiTab: {
      root: {
        letterSpacing: 0
      }
    },
    MuiTabs: {
      indicator: {
        height: "100%"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: fonts.helveticaNeue.fontFamily
      }
    },
    MuiInputAdornment: {
      positionStart: {
        marginRight: 0,
        "& p": {
          fontFamily: fonts.helveticaNeue.fontFamily,
          color: themeColors.darkGray
        }
      },
      positionEnd: {
        marginLeft: 0,
        "& p": {
          fontFamily: fonts.helveticaNeue.fontFamily,
          color: themeColors.darkGray
        }
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
})
