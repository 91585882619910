import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

import LinkButton from "common/LinkButton"
import { SelectableSimpleLink } from "store/PageStore"
import Typography from "./Typography"

type Props = { menuItems?: SelectableSimpleLink[] }

const SecondarySubMenu = ({ menuItems }: Props) => {
  const classes = useStyles()

  return menuItems && menuItems.length > 0 ? (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {menuItems.map((item, i) => {
          return (
            <LinkButton
              key={i}
              link={item.url}
              className={classNames(classes.button, {
                [classes.selected]: item.isSelected
              })}
              color="primary"
            >
              <Typography className={classes.buttonText}>
                {item.title}
              </Typography>
            </LinkButton>
          )
        })}
      </div>
    </div>
  ) : null
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    root: {
      width: "100%",
      background: themeColors.offWhite,
      marginBottom: spacing(8)
    },
    wrapper: {
      position: "relative",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap"
    },
    button: {
      backgroundColor: themeColors.offWhite,
      padding: `${spacing(2.5)}px ${spacing(3)}px`,
      transition: "0.7s",
      [breakpoints.down("md")]: {
        padding: spacing(1)
      }
    },
    selected: {
      backgroundColor: themeColors.whiteGray
    },
    buttonText: {
      fontWeight: 300,
      fontSize: 16,
      whiteSpace: "nowrap",
      color: themeColors.darkGray,
      "&:hover": {
        color: themeColors.darkGray
      }
    }
  })
)

export default SecondarySubMenu
