import { asyncState, createStore } from "global-hook-store"

import api from "api"
import { CustomerInformation, UserRegistrationModel } from "models/User"

const registerStore = createStore(
  {
    newCustomer: asyncState<null>(),
    customerInformation: asyncState<CustomerInformation>(),
    vatNumberTitle: "",
    continueVat: false
  },
  {
    registerNewCustomer: async (
      _,
      payload: UserRegistrationModel,
      { asyncAction }
    ) => {
      payload.firstName = payload.billingAddress.firstName
      payload.lastName = payload.billingAddress.lastName
      payload.countryCode = payload.billingAddress.countryCode

      return asyncAction("newCustomer", api.users.registerNewCustomer(payload))
    },
    getCustomerInformation: async (_, vatNumber: string, { asyncAction }) =>
      asyncAction(
        "customerInformation",
        api.users.getCustomerInformation(vatNumber)
      ),
    setVatNumberTitle: async (state, vatNumberTitle: string) => ({
      ...state,
      vatNumberTitle
    }),
    setContinueVat: async (state, continueVat: boolean) => ({
      ...state,
      continueVat
    }),
    resetNewCustomer: (_, __: null, { reset }) => reset()
  }
)

export default registerStore
