// LinkOrButton.tsx
import { FC } from "react"

import Button from "common/Button"
import LinkButton from "common/LinkButton"
import ArrowRight from "icons/ArrowRight"

interface LinkOrButtonProps {
  linkText: string
  link: string
  showAsLinkButton: boolean
  showAsPrimaryButton: boolean
  theme: string
  onClick?: () => void
}

const LinkOrButton: FC<LinkOrButtonProps> = ({
  linkText,
  link,
  showAsLinkButton,
  showAsPrimaryButton,
  theme,
  onClick
}) => {
  if (linkText && link) {
    if (showAsLinkButton && !showAsPrimaryButton) {
      return (
        <LinkButton link={link}>
          <Button contrastColor={theme} endIcon={<ArrowRight />} variant="text">
            {linkText}
          </Button>
        </LinkButton>
      )
    } else {
      return (
        <LinkButton link={link}>
          <Button
            onClick={onClick}
            contrastColor={theme}
            variant={showAsPrimaryButton ? "primary" : "secondary"}
          >
            {linkText}
          </Button>
        </LinkButton>
      )
    }
  }
  return null
}

export default LinkOrButton
