import { SvgIconProps } from "@material-ui/core/SvgIcon"

const Czechia: React.FC<SvgIconProps> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48" {...props}>
    <g>
      <path fill="#E6E6E6" d="M48,24H20L2,6h44c1.105,0,2,0.895,2,2V24z" />
      <path fill="#D7141A" d="M48,40c0,1.105-0.895,2-2,2H2l18-18h28V40z" />
      <path
        fill="#11457E"
        d="M2,6C0.896,6,0,6.896,0,8v16v16c0,1.104,0.896,2,2,2l18-18L2,6z"
      />
    </g>
  </svg>
)

export default Czechia
