import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const Search: React.FC<Props> = (props) => (
  <SvgIcon
    {...props}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 18a7 7 0 110-14 7 7 0 010 14zm0-12a5 5 0 100 10 5 5 0 000-10zM21.8 18.3l-2.4-2.4a8 8 0 01-1.4 1.4l2.4 2.4a1 1 0 001.4 0c.4-.4.4-1 0-1.4z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default Search
