import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import useStore from "global-hook-store"

import { Box } from "@material-ui/core/"
import Divider from "@material-ui/core/Divider"
import { makeStyles } from "@material-ui/core/styles"

import { scrollToElement } from "utils/ui/scroll"

import Button from "common/Button"
import LinkText from "common/LinkText"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import ConfirmationIcon from "icons/Confirmation"
import * as gtm from "services/StarGA"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import marketStore from "store/MarketStore"
import SiteSettingStore from "store/SiteSettingStore"
import ContainerWrapper from "./Sections/common/ContainerWrapper"
import { ScrollContainers } from "./Checkout"

const Confirmation: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { confirmedOrder } = useCheckoutPaymentStore()

  const {
    state: { links }
  } = useStore(SiteSettingStore)

  const {
    state: { defaultMarket }
  } = useStore(marketStore)

  const currencyCode = defaultMarket && defaultMarket.defaultCurrency

  useEffect(() => {
    scrollToElement(ScrollContainers.orderConfirmation)
  }, [])

  useEffect(() => {
    if (confirmedOrder.data) {
      gtm.checkoutStepAction(4)
      gtm.enhancedTransaction(confirmedOrder.data, currencyCode)
    }
  }, [confirmedOrder.data, currencyCode])

  return (
    confirmedOrder.data && (
      <Wrapper
        className={classes.wrapper}
        id={ScrollContainers.orderConfirmation}
      >
        <ContainerWrapper>
          <div className={classes.container}>
            <div className={classes.textArea}>
              <div className={classes.headline}>
                <ConfirmationIcon className={classes.icon} />
                <Typography variant="headline3" className={classes.headerText}>
                  {t("confirmation.order_confirmation_title")}
                </Typography>
              </div>
              <div className={classes.orderDetails}>
                <Typography
                  variant="body1"
                  className={classes.confirmationBody}
                >
                  {t("confirmation.order_confirmation_body")}
                </Typography>
              </div>
              {confirmedOrder.data.properties.digitalServiceErrors &&
                confirmedOrder.data.properties.digitalServiceErrors.length >
                  0 && (
                  <div className={classes.orderDetails}>
                    <Typography
                      variant="body1"
                      className={classes.digitalServiceErrorsWrapper}
                    >
                      {t("checkout.digital_service_error_first_paragraph")}
                      <span className={classes.digitalServiceErrorRowWrapper}>
                        {confirmedOrder.data.properties.digitalServiceErrors.map(
                          (item, index) => {
                            const formattedItemText = `${item.variantId} - ${item.displayName}`
                            return item.url ? (
                              <LinkText
                                key={index}
                                link={item.url}
                                linkContentClassName={
                                  classes.digitalServiceErrorLink
                                }
                                linkText={formattedItemText}
                                external
                              />
                            ) : (
                              <span
                                key={index}
                                className={classes.digitalServiceErrorRow}
                              >
                                {formattedItemText}
                              </span>
                            )
                          }
                        )}
                      </span>
                      {t("checkout.digital_service_error_second_paragraph")}
                    </Typography>
                  </div>
                )}
            </div>
            <Typography variant="headline6" className={classes.summary}>
              {`${t("confirmation.order_summary_title")}: ${
                confirmedOrder.data.properties.trackingNumber
              }`}
            </Typography>

            <Divider className={classes.divider} />
            <div className={classes.orderDetails}>
              <div className={classes.orderDetail}>
                <Typography variant="body1" className={classes.bold}>
                  {t("checkout.total_excluding_vat")}
                </Typography>
                <Typography variant="body1">
                  {confirmedOrder.data.totalString}
                </Typography>
              </div>
              <div className={classes.orderDetail}>
                <Typography variant="body1" className={classes.bold}>
                  {t("checkout.total_vat")}
                </Typography>
                <Typography variant="body1">
                  {confirmedOrder.data.properties.sapVatAmountString}
                </Typography>
              </div>
              <div className={classes.orderDetail}>
                <Typography variant="body1" className={classes.bold}>
                  {t("checkout.total_including_vat")}
                </Typography>
                <Typography variant="body1">
                  {confirmedOrder.data.properties.totalCostIncVatString}
                </Typography>
              </div>
              <div className={classes.orderDetail}>
                <Typography variant="body1" className={classes.bold}>
                  {t("confirmation.order_detail_number")}
                </Typography>

                <Typography variant="body1">
                  {confirmedOrder.data.properties.trackingNumber}
                </Typography>
              </div>
              <div className={classes.orderDetail}>
                <Typography variant="body1" className={classes.bold}>
                  {t("confirmation.order_detail_delivery")}
                </Typography>
                <Typography variant="body1">
                  {confirmedOrder.data.properties.selectedDeliveryOptionString}
                </Typography>
              </div>
              <div className={classes.orderDetail}>
                <Typography variant="body1" className={classes.bold}>
                  {t("confirmation.order_detail_payment")}
                </Typography>
                <Typography variant="body1">
                  {confirmedOrder.data.properties.selectedPaymentOptionString}
                </Typography>
              </div>
              {confirmedOrder.data.properties.customerOrderReference && (
                <div className={classes.orderDetail}>
                  <Typography variant="body1" className={classes.bold}>
                    {t("checkout.order_reference")}
                  </Typography>
                  <Typography variant="body1">
                    {confirmedOrder.data.properties.customerOrderReference}
                  </Typography>
                </div>
              )}
            </div>
            <Divider className={classes.divider} />
          </div>
          <Box
            paddingTop={3}
            paddingBottom={9}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gridGap={16}
          >
            {links && links.orders && (
              <Link to={links.orders && links.orders.href}>
                <Button variant="secondary">
                  {t("confirmation.view_my_orders")}
                </Button>
              </Link>
            )}
            <Link to={"/"}>
              <Button variant="primary">
                {t("confirmation.continue_browsing")}
              </Button>
            </Link>
          </Box>
        </ContainerWrapper>
      </Wrapper>
    )
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  wrapper: {
    width: "100%",
    background: themeColors.offWhite,
    paddingTop: spacing(3),
    paddingBottom: spacing(6),
    display: "flex",
    alignItems: "flex-start",
    flexGrow: 1
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: spacing(3),
    paddingBottom: 0,
    background: themeColors.white
  },
  textArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flexWrap: "wrap",
    textAlign: "center",
    paddingTop: spacing(4),
    paddingBottom: spacing(3)
  },
  icon: {
    display: "flex",
    justifyContent: "center"
  },
  headline: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    width: "100%"
  },
  headerText: {
    paddingLeft: spacing(1),
    display: "flex",
    justifyContent: "center"
  },
  orderDetails: {
    width: "100%"
  },
  orderDetail: {
    display: "flex",
    justifyContent: "space-between"
  },
  confirmationBody: {
    margin: `${spacing(3)}px auto`,
    maxWidth: 470
  },
  digitalServiceErrorsWrapper: {
    margin: `${spacing(3)}px auto`,
    textAlign: "left",
    border: `3px dashed ${themeColors.secondaryYellow}`,
    padding: spacing(2),
    maxWidth: 470
  },
  digitalServiceErrorRowWrapper: {
    display: "block",
    marginTop: spacing(0.5),
    marginBottom: spacing(1),
    paddingLeft: spacing(1)
  },
  digitalServiceErrorRow: {
    display: "block"
    // marginBottom: spacing(1)
  },
  digitalServiceErrorLink: {
    display: "block",
    color: themeColors.primaryCyan
  },
  summary: {
    alignSelf: "flex-start"
  },
  divider: {
    backgroundColor: themeColors.lightGray,
    width: "100%",
    margin: `${spacing(2)}px 0`
  },
  estimatedDelivery: {
    padding: `${spacing(3)}px ${spacing(9)}px`,
    backgroundColor: themeColors.offWhite,
    display: "flex",
    marginBottom: spacing(3) + 4
  },
  bold: {
    fontWeight: "bold"
  },
  orderQuestions: {
    marginBottom: spacing(1) * (1 + 1 / 2)
  },
  estimatedDeliveryLabel: {
    marginRight: spacing(2)
  },
  backToSite: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 320
  }
}))

export default Confirmation
