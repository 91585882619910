import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"

import CheckoutSectionHeader from "../../common/CheckoutSectionHeader"
import ContainerWrapper from "../../common/ContainerWrapper"

import Spinner from "common/Spinner"
import Shipping from "icons/Shipping"
import { ScrollContainers } from "pages/Checkout/Checkout"
import AccountStore from "store/AccountStore"
import authStore from "store/AuthStore"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import DeliveryOptions from "./DeliveryOptions"

const Delivery: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { checkout, loadingShippingCheck } = useCheckoutPaymentStore()

  const {
    state: { user }
  } = useStore(authStore)

  const {
    state: { accountDetailsMissing }
  } = useStore(AccountStore)

  const isLoggedIn = !user.loading && user.data != null

  if (
    checkout.data &&
    checkout.data.cart &&
    checkout.data.cart.properties &&
    checkout.data.cart.properties.numberOfDigitalServices > 0 &&
    checkout.data.cart.properties.numberOfProducts === 0
  ) {
    return null
  }

  return (
    <div id={ScrollContainers.deliveryOptions} className={classes.root}>
      <ContainerWrapper isActive={isLoggedIn && !accountDetailsMissing}>
        <CheckoutSectionHeader
          title={t("checkout.shipping_title")}
          Icon={Shipping}
        />
        {isLoggedIn && !accountDetailsMissing && (
          <>
            {!loadingShippingCheck ? (
              <DeliveryOptions />
            ) : (
              <div className={classes.spinnerWrapper}>
                <Spinner />
              </div>
            )}
          </>
        )}
      </ContainerWrapper>
    </div>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%"
  },
  spinnerWrapper: {
    textAlign: "center",
    padding: spacing(3)
  }
}))

export default Delivery
