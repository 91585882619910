import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import BrochureIcon from "icons/BrochureIcon"
import { PdfAssets } from "models/Products"
import { eventPdfTracker } from "services/StarGA"

type Props = {
  link?: string
  pdfAssets?: PdfAssets[]
}

const Information: React.FC<Props> = ({ pdfAssets }) => {
  const classes = useStyles()
  return (
    <>
      {pdfAssets && pdfAssets.length > 0 && (
        <div className={classes.additionalInfo}>
          {pdfAssets.map((pdfAsset: PdfAssets, i) => {
            return (
              <a
                key={i}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                href={pdfAsset._url}
                onClick={() => eventPdfTracker(pdfAsset._url)}
              >
                <BrochureIcon className={classes.pdfIcon} />
                <Typography variant="body1" className={classes.pdfName}>
                  {pdfAsset.name || "NONAME"}
                </Typography>
              </a>
            )
          })}
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { gridSpacing, themeColors } }) => ({
    root: {},
    additionalInfo: {
      padding: spacing(0, 1.5),
      display: "flex",
      flexDirection: "column"
    },
    pdfName: {
      display: "inline-block",
      color: themeColors.primaryCyan
    },
    pdfIcon: {
      display: "inline-block",
      marginRight: spacing(1)
    },
    link: {
      display: "inline-block",
      color: themeColors.primaryCyan,
      "&:hover, &:active": {
        color: themeColors.primaryCyan
      }
    }
  })
)

export default Information
