import Box from "@material-ui/core/Box"

import Typography from "common/Typography"
import themeColors from "theme/colors"

export type StatisticContent = {
  stat: string
  description?: string
  subText?: string
  isBgColorWhite: boolean
}

export type StatStyleProps = {
  statClass?: string
  descriptionClass?: string
  subTextClass?: string
}

type Props = {
  statistic: StatisticContent
  isBgColorWhite: boolean
  statStyles?: StatStyleProps
}

const Statistic: React.FC<Props> = ({
  statistic,
  statStyles,
  isBgColorWhite
}) => {
  const { stat, description, subText } = statistic
  const { statClass, descriptionClass, subTextClass } = statStyles || {}
  return (
    <Box
      height={1}
      bgcolor={isBgColorWhite ? themeColors.offWhite : ""}
      p={isBgColorWhite ? 4 : 2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Typography textWrap="wrap" variant="headline3" className={statClass}>
        {stat}
      </Typography>
      <Typography
        textWrap="wrap"
        variant="headline6"
        className={descriptionClass}
      >
        {description}
      </Typography>
      <Typography textWrap="wrap" variant="body2" className={subTextClass}>
        {subText}
      </Typography>
    </Box>
  )
}

export default Statistic
