type Props = {
  className?: string
}

const CheckedOrange: React.FC<Props> = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <polyline
      fill="none"
      strokeMiterlimit="10"
      points="6,12 10,16 18,8"
      strokeLinecap="square"
      strokeWidth="2"
      stroke="#FF3300"
    />
  </svg>
)

export default CheckedOrange
