import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const CloseDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="m16 16-7.333 7.333M16 16 8.667 8.667M16 16l7.333-7.333M16 16l7.333 7.333"
      fill="none"
    />
  </SvgIcon>
)

export default CloseDeLaval
