import api from "@starrepublic/epi/api"
import { ProductsApiResponse } from "@starrepublic/epi/api/products"
import { asyncState, createStore } from "global-hook-store"

import Product from "models/Products"
type GetProductsPayload = {
  route: string
  limit: number
}
const SubCategoryCardStore = createStore(
  {
    subCategoryProducts: asyncState<ProductsApiResponse<Product>>()
  },
  {
    getProducts: async (
      currentState,
      payload: GetProductsPayload,
      { asyncAction }
    ) =>
      asyncAction(
        "subCategoryProducts",
        api.products.getProducts<Product>({
          route: payload.route,
          limit: payload.limit,
          offset: 0,
          excludeIds: [],
          filters: {}
        })
      )
  }
)

export default SubCategoryCardStore
