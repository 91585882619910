import { BlockProps } from "@starrepublic/epi/cms"

import Tooltip from "@material-ui/core/Tooltip"

import CustomTextField from "common/Formik/CustomTextField"

type Props = BlockProps<{
  theme: string
  label: string
  formElementId: string
  placeholder: string
  description: string
}>

const TextareaElementBlock: React.FC<Props> = ({
  content: { label, formElementId, placeholder, description, theme }
}) => (
  <Tooltip
    placement="top"
    arrow
    title={description ? description : ""}
    interactive
  >
    <div>
      <CustomTextField
        {...{ label, placeholder, theme }}
        name={formElementId}
        multiline
        minRows={5}
      />
    </div>
  </Tooltip>
)

export default TextareaElementBlock
