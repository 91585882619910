import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Search from "@material-ui/icons/Search"

import { SearchResultType } from "api/search"
import originalPartsStore from "store/OriginalPartsStore"

const SearchForm: React.FC = () => {
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState("")

  const searchInputRef = useRef<HTMLInputElement>(null)
  const classes = useStyles()

  const {
    state: { result, rootCategory },
    actions: { asyncSearch }
  } = useStore(originalPartsStore)

  useEffect(
    () => {
      if (rootCategory !== null && !result.loading) {
        setSearchTerm("")
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootCategory]
  )

  const handleSubmit = () => {
    if (!result.loading) {
      asyncSearch({
        type: SearchResultType.Products,
        q: searchTerm,
        limit: 100,
        offset: 0
      })
    }
  }

  return (
    <div className={classes.root}>
      <button
        type="submit"
        className={classes.searchButton}
        onClick={handleSubmit}
        disabled={result.loading}
      >
        <Search />
      </button>
      <TextField
        autoFocus
        name="searchTerm"
        variant="filled"
        placeholder={t("search.original_parts_placeholder")}
        className={classes.textField}
        inputRef={searchInputRef}
        autoComplete={"off"}
        disabled={result.loading}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.currentTarget.value)
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handleSubmit()
          }
        }}
      />
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    margin: "0 auto"
  },
  textField: {
    width: "100%",
    color: themeColors.darkGray,
    overflow: "hidden",
    background: themeColors.offWhite,
    padding: 0,
    "& .MuiFilledInput-input": {
      padding: spacing(3) - 1,
      background: themeColors.offWhite
    },
    "& .MuiFilledInput-underline:before": {
      display: "none"
    },
    "& .MuiFilledInput-underline:after": {
      display: "none"
    }
  },
  searchButton: {
    background: themeColors.offWhite,
    color: themeColors.darkGray,
    border: "none",
    outline: 0,
    width: 64,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "none"
    }
  }
}))

export default SearchForm
