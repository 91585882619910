import { useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"

type Props = { text: string }

const ProductDescriptionReadMore: React.FC<Props> = ({ text }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [showText, setShowText] = useState(true)
  const [textHeight, setTextHeight] = useState(0)

  const textRef = useRef<HTMLDivElement>(null)
  const xhtmlCheck = text.startsWith("<")

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (textRef.current) {
      setTextHeight(textRef.current.offsetHeight)
    }
  })

  const compressText = textHeight > 95

  const renderReadMore = () => {
    return compressText ? (
      <Typography
        textWrap="wrap"
        className={classes.readMore}
        onClick={() => setShowText(!showText)}
        variant="body1"
      >
        {showText
          ? t("product.description_read_more")
          : t("product.description_read_less")}
      </Typography>
    ) : null
  }

  return (
    <>
      <div className={classes.root}>
        <Box
          className={classNames(classes.text, {
            [classes.textCompressed]: compressText && showText
          })}
        >
          {xhtmlCheck ? (
            <div ref={textRef}>
              <ThemeXhtmlString content={text} />
            </div>
          ) : (
            <div ref={textRef}>
              <Typography textWrap="wrap" variant="body1">
                {text}
              </Typography>
            </div>
          )}
        </Box>
        {renderReadMore()}
      </div>
    </>
  )
}

const useStyles = makeStyles(({ common, transitions, breakpoints }) => ({
  root: {
    marginBottom: common.gridSpacing * 2
  },
  text: {
    height: "auto",
    transition: `max-height ${transitions.easing.easeInOut} ${transitions.duration.standard}ms, height ${transitions.easing.easeInOut} ${transitions.duration.standard}ms `
  },
  textCompressed: {
    maxHeight: 95,
    height: 95,
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
    [breakpoints.down("sm")]: {
      maxHeight: "none",
      height: "auto"
    }
  },
  readMore: {
    color: common.themeColors.primaryCyan,
    cursor: "pointer"
  }
}))

export default ProductDescriptionReadMore
