import { BlockProps } from "@starrepublic/epi/cms"

import MenuItem from "@material-ui/core/MenuItem"
import Tooltip from "@material-ui/core/Tooltip"

import CustomSelectField from "common/Formik/CustomSelectField"
import CustomTextField from "common/Formik/CustomTextField"

type Item = {
  caption: string
  value: string
  checked: boolean
}

type Props = BlockProps<{
  theme: string
  label: string
  formElementId: string
  items: Item[]
  allowMultiSelect: boolean
  placeholder: string
  description: string
}>

const SelectionElementBlock: React.FunctionComponent<Props> = ({
  content: {
    theme,
    label,
    formElementId,
    items,
    allowMultiSelect,
    placeholder,
    description
  }
}) => {
  return (
    <>
      {allowMultiSelect ? (
        <Tooltip
          placement="top"
          arrow
          title={description ? description : ""}
          interactive
        >
          <div>
            <CustomSelectField
              {...{ label, placeholder, theme }}
              name={formElementId}
              multiple
            >
              {items.map((item) => (
                <MenuItem
                  key={item.value}
                  value={item.value}
                  defaultValue={item.checked ? item.value : ""}
                >
                  {item.caption}
                </MenuItem>
              ))}
            </CustomSelectField>
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          placement="top"
          arrow
          title={description ? description : ""}
          interactive
        >
          <div>
            <CustomTextField
              {...{ label, placeholder, theme }}
              name={formElementId}
              select
            >
              {items.map((item) => (
                <option
                  key={item.value}
                  value={item.value}
                  defaultValue={item.checked ? item.value : ""}
                >
                  {item.caption}
                </option>
              ))}
            </CustomTextField>
          </div>
        </Tooltip>
      )}
    </>
  )
}

export default SelectionElementBlock
