import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"

type AccountOverviewProps = {
  title?: string
}

const AccountOverview: React.FC<AccountOverviewProps> = ({ title = "" }) => {
  const classes = useStyles()

  return (
    <Box mb={6}>
      {title !== "" && (
        <Box mb={2} py={1}>
          <Typography variant="headline3">{title}</Typography>
        </Box>
      )}
    </Box>
  )
}

const useStyles = makeStyles(() => ({
  minHeight: {
    minHeight: 144
  }
}))

export default AccountOverview
