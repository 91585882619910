import { RefObject, useEffect, useState } from "react"

import { isSearchBot } from "utils/device"

const useOnScreen = (
  ref: RefObject<HTMLElement>,
  options: {
    rootMargin?: string
    removeWhenOnScreen?: boolean
  } = {
    rootMargin: "0px",
    removeWhenOnScreen: true
  }
) => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)

  const { rootMargin, removeWhenOnScreen } = options

  useEffect(() => {
    if (!("IntersectionObserver" in window) || isSearchBot()) {
      // Assume intersecting if no support search bot
      setIntersecting(true)
      return
    }

    const elementRef = ref.current

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)

        if (
          entry.isIntersecting &&
          removeWhenOnScreen &&
          ref.current &&
          ref.current["_observer"]
        ) {
          ref.current["_observer"].unobserve(ref.current)
        }
      },
      {
        rootMargin
      }
    )
    if (elementRef) {
      observer.observe(elementRef)
      elementRef["_observer"] = observer
    }
    return () => {
      if (elementRef) {
        observer.unobserve(elementRef)
      }
    }
  }, [ref, rootMargin, removeWhenOnScreen])

  return isIntersecting
}

export default useOnScreen
