import { useEffect } from "react"
import { BlockProps } from "@starrepublic/epi/cms"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { blockDisplay } from "utils/blockDisplay"

import Typography from "common/Typography"
import EnergyCalculator from "store/EnergyCalculatorStore"
import CalculatorCosts from "./CalculatorCosts"
import CalculatorResult from "./CalculatorResult"
import { EnergyCalculatorBlockProps } from "./types"

type Props = BlockProps<EnergyCalculatorBlockProps>

const EnergyCalculatorBlock: React.FC<Props> = ({ content, blockInfo }) => {
  const classes = useStyles()
  const isXsDown = useMediaQuery(({ breakpoints }) => breakpoints.down("xs"))
  const isSmDown = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"))

  const _display = blockDisplay(blockInfo)
  const isTwoThirds = _display.twoThird

  const {
    state: { labels },
    actions: {
      setSliderEnergyCost,
      setSliderMilkProduction,
      setSliderConsumptionOfHotWater,
      setSliderTapWaterTemperature,
      setLabels,
      setXhtmlStrings,
      enableEmail
    }
  } = useStore(EnergyCalculator)

  const loadDefault = async () => {
    await setXhtmlStrings({
      checkboxLabel: content.checkboxLabel,
      contactLabel1: content.contactLabel1
    })
    await setLabels(content.labels)
    await setSliderMilkProduction(content.defaultMilkProduction)
    await setSliderEnergyCost(content.defaultEnergyCost)
    await setSliderConsumptionOfHotWater(content.defaultHotWaterConsumption)
    await setSliderTapWaterTemperature(content.defaultTapWaterTemp)
    await enableEmail(content.enableEmailInContactForm)
  }

  useEffect(
    () => {
      loadDefault()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Box mx="auto" py={6}>
      <Box mx="auto" maxWidth={782} pb={5}>
        <Typography
          align="center"
          className={classes.heading}
          variant="headline2"
        >
          {labels.title}
        </Typography>
        {labels.preamble && (
          <Typography
            align="center"
            variant="body1"
            className={classes.preamble}
          >
            {labels.preamble}
          </Typography>
        )}
      </Box>
      <Grid container spacing={isXsDown ? 2 : isSmDown ? 3 : 6}>
        <Grid item xs={12} sm={12} md={6}>
          <CalculatorCosts {...content} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CalculatorResult isTwoThirds={isTwoThirds} />
        </Grid>
      </Grid>
      <Box py={5}>
        <Typography
          align="center"
          variant="body2"
          className={classes.bottomText}
        >
          {labels.generalInformation}
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    heading: {
      fontSize: 40,
      [breakpoints.down("xs")]: {
        fontSize: 32
      }
    },
    bottomText: {
      color: themeColors.colorBaseGray500
    },
    preamble: {
      padding: spacing(2, 0, 0)
    }
  })
)

export default EnergyCalculatorBlock
