import * as yup from "yup"

import { ButtonElementArea, ElementArea } from "./types"

const generateValidationSchema = (
  elementsArea: (ElementArea | ButtonElementArea)[]
) => {
  const validationSchema = {}
  if (elementsArea) {
    elementsArea.forEach((element) => {
      const { formElementId, validatorMessages, _type } = element
      // If the form contains a Recaptcha we set it a required option
      if (_type === "RecaptchaElementBlock") {
        validationSchema[formElementId] = yup
          .string()
          .required("Recaptcha is required!")
      }
      if (formElementId && validatorMessages && validatorMessages.length) {
        validationSchema[formElementId] = yup.string()
        validatorMessages.forEach((rule) => {
          if (rule.validator === "integer") {
            validationSchema[element.formElementId] = yup
              .number()
              .integer(rule.message)
            // .positive(rule.message)
          } else if (rule.validator === "positiveinteger") {
            validationSchema[element.formElementId] = yup
              .number()
              .integer(rule.message)
              .positive(rule.message)
          } else {
            validationSchema[element.formElementId] = validationSchema[
              element.formElementId
            ][rule.validator](rule.message)
          }
        })
      }
    })
  }

  return yup.object().shape(validationSchema)
}

export default generateValidationSchema
