import request from "@starrepublic/epi/api/request"

import {
  ChangePasswordModel,
  CustomerInformation,
  ImpersonationUserModel,
  SapCustomerLookupModel,
  SapCustomerRegistrationModel,
  UserRegistrationModel
} from "models/User"

const users = {
  getUser: () => request.get<UserRegistrationModel>(`/membership/user`),
  deleteShippingAddress: (deliveryId: string) =>
    request.delete(`/membership/user/shippingaddress/${deliveryId}`),
  getImpersonationUsers: () =>
    request.get<ImpersonationUserModel>(`/membership/impersonate/users/me`),
  registerNewCustomer: (user: UserRegistrationModel) => {
    return request.post(`/membership/new`, user)
  },
  resetPassword: (username: string) =>
    request.get(`/membership/user/${username}/resetpassword`),
  changePassword: (model: ChangePasswordModel) =>
    request.post(`/membership/user/${model.email}/resetpassword`, model),
  registerExistingCustomer: (user: SapCustomerRegistrationModel) =>
    request.post(`/membership/existing`, user),
  updateCustomer: (sapCustomerNumber: string, vatNumber: string) =>
    request.post(`/membership/updatecustomer/${sapCustomerNumber}`, {
      vatNumber
    }),
  sapLookup: async (number: string) =>
    request.get<SapCustomerLookupModel>(`/membership/existing/${number}`),
  getCustomerInformation: async (vatNumber: string) =>
    request.post<CustomerInformation>(`/vat/`, { vatNumber })
}

export default users
