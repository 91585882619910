import { useTranslation } from "react-i18next"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import { makeStyles } from "@material-ui/core/styles"
import CancelIcon from "@material-ui/icons/Cancel"
import CheckIcon from "@material-ui/icons/Check"

import Typography from "common/Typography"

type Props = {
  open: boolean
  inputRef: React.RefObject<HTMLInputElement> | null | undefined
  renderAsBlock?: boolean
}

const CustomTextFieldPopper: React.FC<Props> = ({
  open,
  inputRef,
  renderAsBlock
}) => {
  const classes = useStyles()

  const { t } = useTranslation()
  if (!inputRef || !inputRef.current) {
    return null
  }

  const inputValue = inputRef.current.value

  const renderCriteria = (regex: string, text: React.ReactNode) => (
    <Grid container alignItems="flex-start" className={classes.line}>
      {inputValue.match(regex) ? (
        <CheckIcon className={classNames(classes.icon, classes.success)} />
      ) : (
        <CancelIcon className={classNames(classes.icon, classes.error)} />
      )}
      <Typography
        variant="body2"
        className={!inputValue.match(regex) ? classes.error : ""}
      >
        {text}
      </Typography>
    </Grid>
  )

  const renderContent = () => (
    <>
      {renderCriteria("(?=.{8,})", t("validation.password_length_requirement"))}
      {renderCriteria(
        "(?=.*[a-z])(?=.*[A-Z])",
        t("validation.password_letter_requirement")
      )}

      {renderCriteria("(.*[0-9])", t("validation.password_number_requirement"))}
      {renderCriteria(
        "(.*[!@#$%^&*])",
        <>
          {t("validation.password_special_character_requirement")} <br />
          {"! @ # $ % ^ & *"}
        </>
      )}
    </>
  )

  return renderAsBlock ? (
    <Box pt={1}>{renderContent()}</Box>
  ) : (
    <Popper
      className={classes.root}
      open={
        open &&
        !inputValue.match(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        )
      }
      anchorEl={inputRef.current}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper className={classes.popperPaper}>
            <Box p={3}>{renderContent()}</Box>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, spacing, zIndex }) => ({
    root: {
      zIndex: zIndex.drawer + 100
    },
    popperPaper: {
      border: `1px solid ${themeColors.grayBorder}`
    },
    icon: {
      marginRight: spacing(1),
      transition: "color ease-in-out 250ms",
      position: "relative",
      top: -1
    },
    line: {
      marginBottom: spacing(0.5),
      "&:last-child": {
        marginBottom: 0
      }
    },
    success: {
      color: themeColors.secondaryGreen,
      top: -2
    },
    error: {
      color: themeColors.primaryRed
    }
  })
)

export default CustomTextFieldPopper
