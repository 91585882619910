import { BlockResolver, ContentProps, PageInfo } from "@starrepublic/epi/cms"
import DisplayOptionsContentArea from "@starrepublic/epi/cms/components/DisplayOptionsContentArea"
import useStore from "global-hook-store"

import Divider from "@material-ui/core/Divider"

import DisplayOptions from "utils/ui/DisplayOptions"

import BreadcrumbsBar from "common/BreadcrumbsBar"
import {
  ChildContainer,
  ContainerWrapper,
  SectionComponent
} from "common/contentAreaContainers"
import SubHeaderExploreChildNodes from "common/Header/SubHeader/ExploreChildNodes"
import pageStore from "store/PageStore"

type Props = { pageInfo: PageInfo } & ContentProps

const LearnPage: React.FC<Props> = ({ content, pageInfo, propsInfo }) => {
  const { mainContent } = content
  const {
    state: { page }
  } = useStore(pageStore)

  return (
    <>
      <BreadcrumbsBar />
      <SubHeaderExploreChildNodes
        childNodes={(page.data && page.data.subMenuItems) || []}
      />
      {content.hero &&
      (content.hero.bigTitle || content.hero.backgroundImage) ? (
        <BlockResolver content={content.hero} pageInfo={pageInfo} />
      ) : null}
      <Divider />
      <DisplayOptionsContentArea
        content={mainContent}
        propInfo={propsInfo.mainContent}
        component={SectionComponent}
        pageInfo={pageInfo}
        containWhen={({ _display: display, _type: type }) =>
          (type &&
            type !== "SectionBlock" &&
            display &&
            (display === "auto" || DisplayOptions[display] !== 1)) ||
          false
        }
        childContainer={ChildContainer}
        container={ContainerWrapper}
      />
    </>
  )
}

export default LearnPage
