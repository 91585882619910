import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const BatteryIcon: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 41 40">
    <path
      fill="#fff"
      d="M30.563 9H6.938A3.937 3.937 0 0 0 3 12.938v13.5a3.937 3.937 0 0 0 3.938 3.937h23.625a3.937 3.937 0 0 0 3.937-3.938v-13.5A3.937 3.937 0 0 0 30.562 9Zm.562 17.438a.562.562 0 0 1-.563.562H6.938a.563.563 0 0 1-.562-.563v-13.5a.562.562 0 0 1 .563-.562h23.625a.562.562 0 0 1 .562.563v13.5ZM39 16.313v6.75a1.687 1.687 0 1 1-3.375 0v-6.75a1.687 1.687 0 1 1 3.375 0Zm-15.978 2.53a1.687 1.687 0 0 1 0 1.688l-2.25 3.938a1.69 1.69 0 0 1-2.812.21 1.689 1.689 0 0 1-.118-1.884l.812-1.42h-1.591a1.688 1.688 0 0 1-1.466-2.531l2.25-3.938a1.691 1.691 0 0 1 2.93 1.688L19.972 18h1.591a1.688 1.688 0 0 1 1.46.844Z"
    />
  </SvgIcon>
)

export default BatteryIcon
