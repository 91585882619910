import { BlockProps, editorAttributes } from "@starrepublic/epi/cms"

import { makeStyles } from "@material-ui/core/styles"

import Image from "common/Image"

type ImageFile = {
  _url: string
}

type Props = BlockProps<ImageFile>
const ImageFileBlock: React.FC<Props> = ({ content, blockInfo }) => {
  const classes = useStyles()

  return (
    <div {...editorAttributes(blockInfo)}>
      <Image className={classes.root} lazy={false} src={content._url} />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: "block",
    width: "100%"
  }
}))
export default ImageFileBlock
