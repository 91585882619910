import classNames from "classnames"

// import Card from "@material-ui/core/Card"
import Grid, { GridSize } from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"

const TRANSITION_DURATION = 200
const TRANSITION_OPTIONS = `${TRANSITION_DURATION}ms cubic-bezier(0.4, 0.0, 0.2, 1)`

type Props = {
  isActive?: boolean
  elevation?: number
  lg?: GridSize
  md?: GridSize
  sm?: GridSize
}

const ContainerWrapper: React.FC<Props> = ({
  children,
  isActive = true,
  elevation = 0,
  lg = 8,
  md = 8,
  sm = 12,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Grid
      container
      justifyContent="center"
      {...rest}
      className={classNames(classes.root, {
        [classes.active]: isActive
      })}
    >
      <Grid className={classes.grid} item lg={lg} md={md} sm={sm}>
        <Paper elevation={elevation} className={classes.cardContent}>
          {children}
        </Paper>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(({ common }) => ({
  root: {
    marginTop: common.gridSpacing,
    opacity: 0.5,
    pointerEvents: "none"
  },
  cardContent: {
    padding: 0
  },
  active: {
    opacity: 1,
    pointerEvents: "auto",
    transition: `opacity ${TRANSITION_OPTIONS}`
  },
  grid: {
    width: "100%"
  }
}))

export default ContainerWrapper
