import api from "@starrepublic/epi/api"
import { DynamicBlock, DynamicPage, SimpleLink } from "@starrepublic/epi/cms"
import { AsyncState, asyncState, createStore } from "global-hook-store"

import { isEpiPreview } from "utils/epiPreviewUtil"

import previewPageApi from "api/pages"
import { HeroTabContent } from "blocks/HeroBlock/types"
import * as gtm from "services/StarGA"

const langRegex = new RegExp("^[a-zA-Z]{0,2}(-[a-zA-Z]{0,2})?$")

export type ExploreMenu = {
  exploreMenuItems: SelectableSimpleLink[]
  title: string
  callToActionLink: string
  callToActionTitle: string
}

type Links = {
  href: string
  rel: string
}

export type SelectableSimpleLink = {
  isSelected: boolean
} & SimpleLink

export type CustomDynamicPage = {
  _settingsCacheKey?: string
  _links: Links[]
  mainContent: DynamicBlock[]
  hero?: HeroTabContent
  exploreMenu?: ExploreMenu
  subMenuItems?: SelectableSimpleLink[]
  showStoreMenu?: boolean
  metaDescription?: string | undefined
} & DynamicPage

type PageStoreType = {
  page: AsyncState<CustomDynamicPage | null>
  fetchLocation: boolean
  previousRoute: string
}

export const pageStore = createStore(
  {
    page: asyncState<CustomDynamicPage>(),
    fetchLocation: true,
    previousRoute: ""
  } as PageStoreType,
  {
    fetch: async (
      { page: previousPage },
      { id, useCache = true },
      { asyncAction }
    ) => {
      let parsedId = id

      let state = {} as PageStoreType

      if (isEpiPreview()) {
        state = await asyncAction("page", previewPageApi.getCachedPageContent())
      } else {
        const isLang: boolean = langRegex.test(id.split("/")[1])
        if (isLang) {
          parsedId = id.split("/").splice(2).join("/")
        }

        state = await asyncAction(
          "page",
          api.pages.getContentByIdentifier(parsedId, useCache)
        )

        gtm.setContentData(state.page.data)
      }

      return {
        ...state,
        previousRoute: (previousPage.data && previousPage.data._route) || ""
      }
    },
    setFetchLocation: (state, fetchLocation) => ({ ...state, fetchLocation })
  }
)

export default pageStore
