import { useState } from "react"
import { Slide } from "pure-react-carousel"

import Box from "@material-ui/core/Box"

import swipeHelper from "utils/swipeHelper"

import ResponsiveImage from "../ResponsiveImage"

import ExternalVideoAndImage from "blocks/TextAndMediaBlock/ExternalVideoAndImage"
import { ImageBlock, VideoType } from "types/content/media"

type CarouselItemProps = {
  mediaItem: ImageBlock | VideoType
  index: number
  naturalSlideHeight: number
  onClick?: (index: number) => void
}

const CarouselItem: React.FC<CarouselItemProps> = ({
  index,
  mediaItem,
  naturalSlideHeight
}) => {
  const [isSwipe, saveInitialClickPosition] = swipeHelper()
  const [isOpen, setOnClick] = useState(false)

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    saveInitialClickPosition(e.pageX, e.pageY)
  }

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!setOnClick || isSwipe(e.pageX, e.pageY)) {
      return
    }
    setOnClick(!isOpen)
  }

  const closeVideoFromModal = () => {
    setOnClick(false)
  }

  return (
    <Slide
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      index={index}
    >
      <Box zIndex={-1} height="100%" position="relative">
        {naturalSlideHeight !== 0 && mediaItem._type === "ImageFile" && (
          <ResponsiveImage image={mediaItem as ImageBlock} relative={false} />
        )}

        {mediaItem._type === "VideoFile" && (
          <ExternalVideoAndImage
            video={{ key: mediaItem["key"], value: mediaItem["value"] }}
            isHalf={false}
            isOneThird={false}
            clickStateFromSlider={isOpen}
            closeVideoFromModal={closeVideoFromModal}
          />
        )}
      </Box>
    </Slide>
  )
}

export default CarouselItem
