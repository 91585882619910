const enum ATTR_NAME {
  EpiEdit = "data-epi-edit"
}

type EditAttributes = {
  [ATTR_NAME.EpiEdit]: string
}

interface EditPropInfo {
  name?: string
}

const editorAttributes = (
  propInfo?: EditPropInfo
): EditAttributes | undefined => {
  if (!propInfo || !propInfo.name) {
    return undefined
  }

  return {
    [ATTR_NAME.EpiEdit]: propInfo.name
  }
}

export default editorAttributes
