import { useState } from "react"
import classNames from "classnames"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import ButtonBase from "@material-ui/core/ButtonBase"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Drawer from "@material-ui/core/Drawer"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import Image from "common/Image"
import Typography from "common/Typography"
import InfoOutlinedIcon from "icons/InfoOutlinedIcon"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"
import SiteSettingStore from "store/SiteSettingStore"
import FenceProductCardDetails from "./FenceProductCardDetails"
import { FenceProductVariant } from "./types"

type FenceProductProps = {
  product: FenceProductVariant
  selectedProduct: FenceProductVariant
  onSelectProduct: (articleNumber: string) => void
  currencySymbol: string
}

const FenceProductCard = ({
  product,
  selectedProduct,
  onSelectProduct,
  currencySymbol
}: FenceProductProps) => {
  const classes = useStyles()
  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))
  const [viewProductDetails, setViewProductDetails] = useState(false)

  const { fenceCalculatorLabels } = useFenceCalculatorStore()

  const {
    state: { enableEcommerce }
  } = useStore(SiteSettingStore)

  const isSelected =
    Object.keys(selectedProduct).length !== 0 &&
    selectedProduct.variant.articleNumber === product.variant.articleNumber

  const quantity = product.variant.quantity
  const length = product.variant.length

  return (
    <>
      <Card
        className={classNames(classes.card, {
          [classes.selected]: isSelected
        })}
      >
        <div className={classes.productWrapper}>
          <CardContent
            className={classes.cardContent}
            onClick={() => onSelectProduct(product.variant.articleNumber)}
          >
            <Image
              containerProps={{
                className: classes.imageContainer
              }}
              className={classes.image}
              lazy
              title={product.name}
              src={product.variant.productImage || product.productImage}
              alt={product.name}
            />

            <Typography variant="headline6" className={classes.title}>
              {product.name}
            </Typography>
            <Typography variant="headline6" className={classes.subtitle}>
              {quantity > length
                ? quantity > 1 &&
                  `${quantity} ${
                    quantity > 1
                      ? fenceCalculatorLabels.itemsSuffix
                      : fenceCalculatorLabels.itemSuffix
                  }`
                : `${length} ${fenceCalculatorLabels.metresSuffix}`}
            </Typography>
            {product.variant.color && (
              <Typography className={classes.subtitle}>
                {product.variant.color}
              </Typography>
            )}
          </CardContent>
          <CardActions className={classes.cardActions}>
            {enableEcommerce && (
              <Typography className={classes.price}>
                {product.variant.price.toFixed(2)} {currencySymbol}
              </Typography>
            )}
            <ButtonBase
              onClick={() => {
                setViewProductDetails(true)
              }}
              aria-label={fenceCalculatorLabels.info}
              className={classes.button}
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </ButtonBase>
          </CardActions>
        </div>
      </Card>
      {isSmUp ? (
        <Dialog
          onClose={() => setViewProductDetails(false)}
          open={viewProductDetails}
          maxWidth="md"
          fullWidth
        >
          <DialogContent dividers>
            <Box>
              <FenceProductCardDetails
                product={product}
                isSelected={isSelected}
                onSelectProduct={onSelectProduct}
                onSetProductDrawerOpen={setViewProductDetails}
                currencySymbol={currencySymbol}
              />
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <Drawer
          classes={{ paper: classes.drawer }}
          anchor="right"
          open={viewProductDetails}
          BackdropProps={{
            classes: { root: classes.overLay }
          }}
        >
          <FenceProductCardDetails
            product={product}
            isSelected={isSelected}
            onSelectProduct={onSelectProduct}
            onSetProductDrawerOpen={setViewProductDetails}
            currencySymbol={currencySymbol}
          />
        </Drawer>
      )}
    </>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  card: {
    width: "100%",
    position: "relative",
    // TODO: Decide how to do with fixed height...
    // height: ({ containerHeight }) => containerHeight,
    height: 221,
    display: "flex",
    border: `1px solid ${themeColors.lightGray}`,
    cursor: "pointer"
  },
  selected: {
    border: `3px solid ${themeColors.primaryBlue}`
  },
  productWrapper: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: spacing(1.5)
  },
  cardContent: {
    padding: 0
  },
  cardActions: {
    padding: 0
  },
  imageContainer: {
    // height: ({ imageHeight }) => imageHeight,
    height: 60,
    width: "100%",
    marginBottom: spacing(2)
  },
  image: {
    width: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    position: "initial"
  },
  title: {
    width: "100%",
    textAlign: "left",
    fontSize: 14,
    paddingBottom: 0
  },
  subtitle: {
    fontSize: 12,
    color: themeColors.black,
    textAlign: "left",
    fontWeight: 400,
    paddingTop: spacing(0.25)
  },
  price: {
    fontSize: 14,
    color: themeColors.black,
    textAlign: "left",
    fontWeight: 700,
    display: "flex",
    alignItems: "end"
  },
  button: {
    position: "absolute",
    bottom: 0,
    right: 0,
    cursor: "pointer",
    color: themeColors.white,
    background: themeColors.primaryCyan,
    borderRadius: 0,
    height: 46,
    minWidth: 46
  },
  infoIcon: {
    fill: "none"
  },
  drawer: {
    width: "100vw"
  },
  overLay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)"
  }
}))

export default FenceProductCard
