import { FC, useContext, useEffect } from "react"

import { DynamicPage } from "../types/content"
import { GetContentType } from "../types/props"
import {
  convertContentToPage,
  getPropsInfoForContent
} from "../utils/contentConverter"
import { logDevMessage, Severity } from "../utils/logging"

import CmsContext from "./CmsContext/context"
import OnPageEditing, { FetchContentProps } from "./OnPageEditing"

type OwnProps = {
  autoScroll?: boolean | ((content: DynamicPage) => boolean)
  content?: DynamicPage
} & FetchContentProps

type Props = OwnProps

const PageResolver: FC<Props> = ({
  content,
  fetchContent,
  autoScroll = true
}) => {
  useEffect(() => {
    if (
      content &&
      autoScroll &&
      (autoScroll instanceof Boolean ||
        (autoScroll instanceof Function && autoScroll(content)))
    ) {
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content && content._id])

  const { isEditMode, pageMap } = useContext(CmsContext)

  if (!content) return null

  const PageComponent = pageMap[content._type]
  if (!PageComponent) {
    logDevMessage(
      // prettier-ignore
      `No page component is found for type ${content._type}, nothing to render`,
      Severity.Error
    )
    return null
  }

  type ContentType = GetContentType<typeof PageComponent>

  const page = convertContentToPage<ContentType>(content)
  const propsInfo = isEditMode ? getPropsInfoForContent(content, page) : {}

  const { data, info } = page

  return isEditMode ? (
    <OnPageEditing
      content={data}
      propsInfo={propsInfo}
      pageInfo={info}
      fetchContent={fetchContent}
    >
      {(opeData: ContentType) => (
        <PageComponent
          content={opeData}
          propsInfo={propsInfo}
          pageInfo={info}
        />
      )}
    </OnPageEditing>
  ) : (
    <PageComponent content={data} propsInfo={propsInfo} pageInfo={info} />
  )
}

export default PageResolver
