type Props = {
  className?: string
}

const ArrowRightShort: React.FC<Props> = (props) => (
  <svg {...props} width="12" height="16" fill="none">
    <path
      d="M1.5-1.8 10.3 8l-8.9 9.8"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
)

export default ArrowRightShort
