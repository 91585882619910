import { FC, useRef, useState } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import ButtonBase from "@material-ui/core/ButtonBase"
import Drawer from "@material-ui/core/Drawer"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import Portal from "@material-ui/core/Portal"
import { makeStyles } from "@material-ui/core/styles"
import Clear from "@material-ui/icons/Clear"

import { countObjectDepth } from "utils/hierarchyDepthCounter"

import Button from "common/Button"
import { ConditionalWrapper } from "common/ConditionalWrapper"
import ContactModal from "common/ContactModal"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import ArrowDown from "icons/ArrowDown"
import ArrowRight from "icons/ArrowRight"
import Mail from "icons/Mail"
import {
  eventExploreMenuMobileTracker,
  eventExploreMenuTracker
} from "services/StarGA"
import contactModalStore from "store/ContactModalStore"
import { ExploreMenu, SelectableSimpleLink } from "store/PageStore"

type Props = {
  menu?: ExploreMenu
}

const SubHeaderExplore: FC<Props> = ({ menu }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  const {
    actions: { open: openContactModal }
  } = useStore(contactModalStore)
  if (!menu) {
    return null
  }

  const handleContactClick = () => {
    if (menu.callToActionLink) {
      navigate(menu.callToActionLink)
    } else {
      openContactModal()
    }
  }
  const exploreMenuItems = menu.exploreMenuItems.slice(1)
  const firstElementOfMenuItems = menu.exploreMenuItems.slice(0, 1)
  const renderExploreMenuItems = (
    items: SelectableSimpleLink[],
    firstButton?: boolean
  ) => {
    return items.map((item, i) => {
      const isActive = item.url === window.location.pathname
      return (
        <ConditionalWrapper
          key={i}
          condition={!!item.url}
          wrapper={(children) => <Link to={item.url}>{children}</Link>}
        >
          <Button
            onClick={() =>
              eventExploreMenuTracker(
                item.url,
                countObjectDepth(item),
                item.title
              )
            }
            className={classNames(classes.exploreButton, {
              [classes.firstButton]: firstButton,
              [classes.activeButton]: isActive,
              [classes.activeFirstButton]: isActive && firstButton
            })}
            variant={isActive ? "primary" : "text"}
          >
            {item.title}
          </Button>
        </ConditionalWrapper>
      )
    })
  }

  return (
    <>
      <Wrapper
        className={classes.root}
        containerClassName={classes.rootContainer}
      >
        <Grid container>
          <Grid item style={{ flexGrow: 1, display: "flex" }}>
            <Grid container>
              <Hidden mdUp>
                <ButtonBase
                  className={classNames(
                    classes.button,
                    classes.firstButtonMobile
                  )}
                  onClick={handleToggle}
                  ref={anchorRef}
                >
                  <Typography variant="headline6" className={classes.title}>
                    {menu.title}
                  </Typography>
                  <ArrowDown className={classes.menuArrow} />
                </ButtonBase>
                <Drawer
                  open={open}
                  anchor="right"
                  onClose={() => {
                    setOpen(false)
                  }}
                >
                  <Paper className={classes.mobileDrawer}>
                    <ButtonBase
                      onClick={(e) => setOpen(false)}
                      className={classNames(
                        classes.button,
                        classes.closeMobileDrawer
                      )}
                    >
                      <Clear />
                    </ButtonBase>
                    <MenuList
                      autoFocusItem={open}
                      className={classes.menuWrapper}
                    >
                      {menu.exploreMenuItems.map((item, i) => {
                        return (
                          <ConditionalWrapper
                            key={i}
                            condition={!!item.url}
                            wrapper={(children) => (
                              <Link to={item.url}>{children}</Link>
                            )}
                          >
                            <MenuItem
                              className={classNames(classes.popperMenuItem, {
                                [classes.activePopperMenuItem]: item.isSelected,
                                [classes.firstPopperMenuItem]: i === 0
                              })}
                              onClick={() => {
                                setOpen(false)
                                eventExploreMenuMobileTracker(
                                  item.url,
                                  countObjectDepth(item),
                                  item.title
                                )
                              }}
                            >
                              <Typography variant="body1">
                                {item.title}
                              </Typography>
                            </MenuItem>
                          </ConditionalWrapper>
                        )
                      })}
                    </MenuList>
                  </Paper>
                </Drawer>
              </Hidden>

              <Box width="100%" display="flex" justifyContent="space-between">
                <Hidden smDown>
                  {renderExploreMenuItems(firstElementOfMenuItems, true)}
                </Hidden>
                {menu.callToActionTitle && (
                  <Grid item>
                    <Hidden smDown>
                      <Button
                        onClick={handleContactClick}
                        endIcon={<ArrowRight className={classes.ctaIcon} />}
                      >
                        {menu.callToActionTitle}
                      </Button>
                    </Hidden>
                    <Hidden mdUp>
                      <Portal>
                        <ButtonBase
                          className={classNames(
                            classes.button,
                            classes.callToActionButton
                          )}
                          onClick={handleContactClick}
                        >
                          <Mail className={classes.ctaIcon} />
                        </ButtonBase>
                      </Portal>
                    </Hidden>
                  </Grid>
                )}
              </Box>
              <Hidden smDown>{renderExploreMenuItems(exploreMenuItems)}</Hidden>
            </Grid>
          </Grid>
          <ContactModal />
        </Grid>
      </Wrapper>
    </>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, breakpoints, spacing }) => ({
    root: {
      background: themeColors.white,
      borderBottom: `1px solid ${themeColors.lightGray}`
    },
    rootContainer: {
      padding: spacing(0, 1)
    },
    title: {
      whiteSpace: "nowrap",
      maxWidth: 235,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "inline-block"
    },
    menuArrow: {
      width: 16,
      height: 9,
      marginLeft: spacing(2),
      display: "none",
      [breakpoints.down("sm")]: {
        display: "inline-block",
        transform: "rotateZ(-90deg)"
      }
    },
    menuWrapper: {
      padding: 0
    },
    closeMobileDrawer: {
      marginLeft: "auto",
      display: "block"
    },
    mobileDrawer: {
      minWidth: "90vw"
    },
    popperMenuItem: {
      backgroundColor: themeColors.white,
      padding: spacing(1, 4),
      "& p": {
        color: themeColors.colorBaseCyan900
      }
    },
    firstPopperMenuItem: {
      paddingLeft: spacing(3),
      "& p": {
        fontWeight: 700,
        color: themeColors.primaryBlue,
        fontSize: 18
      }
    },
    activePopperMenuItem: {
      backgroundColor: themeColors.colorBaseCyan900,
      "& p": {
        color: themeColors.white
      },
      "&:hover": {
        "& p": {
          color: themeColors.primaryBlue
        }
      }
    },
    exploreButton: {
      fontWeight: 400,
      fontSize: 16,
      color: themeColors.colorBaseBlue600
    },
    button: {
      transition: "background-color 0.2s ease-in-out",
      padding: spacing(2.5),
      "&:hover": {
        backgroundColor: themeColors.colorBaseCyan450
      }
    },
    firstButton: {
      height: "100%",
      color: themeColors.primaryBlue,
      fontWeight: 700,
      fontSize: 20
    },

    firstButtonMobile: {
      width: "100%",
      justifyContent: "space-between"
    },
    activeFirstButton: {
      color: themeColors.white
    },
    activeButton: {
      backgroundColor: themeColors.colorBaseCyan900,
      color: themeColors.white
    },
    callToActionButton: {
      backgroundColor: themeColors.primaryCyan,
      "&:hover": {
        backgroundColor: themeColors.colorBaseCyan900
      },
      [breakpoints.down("sm")]: {
        position: "fixed",
        right: 24,
        bottom: 32,
        boxShadow: themeColors.boxShadow3,
        zIndex: 4
      }
    },
    ctaIcon: {
      marginLeft: spacing(1),
      color: themeColors.white,
      [breakpoints.down("sm")]: {
        marginLeft: 0
      }
    }
  })
)

export default SubHeaderExplore
