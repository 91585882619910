import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const PhoneDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="m16.837 23.9 3.58-3.508 6.25 1.255v5.02c-10.65 0-21.334-10.04-21.334-21.334h5.028l1.257 6.275-3.484 3.674"
      fill="none"
    />
  </SvgIcon>
)

export default PhoneDeLaval
