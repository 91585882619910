import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import PageListMenu from "pages/InformationPage/PageListMenu"
import authStore from "store/AuthStore"

const UserAccountMenu = () => {
  const classes = useStyles()
  const {
    state: { user }
  } = useStore(authStore)

  if (!user.data || user.data.userAccountMenu.length <= 0) {
    return null
  }

  const menu = user.data.userAccountMenu

  const userAccountMenu = () => {
    const subItemChildren = menu.slice(1).map((menu) => ({
      title: menu.title,
      url: menu.url,
      isSelected: menu.url === window.location.pathname ? true : false
    }))

    return {
      title: menu[0].title,
      url: menu[0].url,
      isSelected: menu[0].url === window.location.pathname ? true : false,
      subItems: subItemChildren
    }
  }

  return (
    <Grid className={classes.root} item xs={2}>
      <PageListMenu subNavigation={userAccountMenu()} />
    </Grid>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  root: {
    borderRight: `1px solid ${themeColors.whiteGray}`
  }
}))

export default UserAccountMenu
