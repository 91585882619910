import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"

const ArrowDown: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99994 9C7.74394 9 7.48794 8.902 7.29294 8.707L0.585938 2L1.99994 0.585999L7.99994 6.586L13.9999 0.585999L15.4139 2L8.70694 8.707C8.51194 8.902 8.25594 9 7.99994 9Z"
      fill={props.fill || "#FF3300"}
    />
  </SvgIcon>
)

export default ArrowDown
