import { SvgIconProps } from "@material-ui/core/SvgIcon"

const Ukraine: React.FC<SvgIconProps> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48" {...props}>
    <g>
      <path
        fill="#3A75C3"
        d="M48,24H0V8c0-1.105,0.895-2,2-2h44c1.105,0,2,0.895,2,2V24z"
      />
      <path
        fill="#F9DD17"
        d="M48,40c0,1.105-0.895,2-2,2H2c-1.105,0-2-0.895-2-2V24h48V40z"
      />
    </g>
  </svg>
)

export default Ukraine
