import {
  BlockResolver,
  ContentAreaProperty,
  ContentProps
} from "@starrepublic/epi/cms/"
import DisplayOptionsContentArea from "@starrepublic/epi/cms/components/DisplayOptionsContentArea"
import useStore from "global-hook-store"

import Hidden from "@material-ui/core/Hidden"

import DisplayOptions from "utils/ui/DisplayOptions"

import { HeroBlockContent } from "blocks/HeroBlock/types"
import BreadcrumbsBar from "common/BreadcrumbsBar"
import {
  ChildContainer,
  ContainerWrapper,
  SectionComponent
} from "common/contentAreaContainers"
import SubHeaderExploreChildNodes from "common/Header/SubHeader/ExploreChildNodes"
import Newsletter from "common/Newsletter"
import SiteSettingStore from "store/SiteSettingStore"

type StandardPageContent = {
  mainContent: ContentAreaProperty
  urlProp: string
  siteTitle: string
  hero?: HeroBlockContent
}

type Props = ContentProps<StandardPageContent>

const StartPage: React.FC<Props> = ({ content, propsInfo, pageInfo }) => {
  const {
    state: { enableEcommerce, productMenu }
  } = useStore(SiteSettingStore)

  const isStartPage = pageInfo && pageInfo._type === "SiteStartPage"

  return (
    <>
      <Hidden smDown>
        <Newsletter />
      </Hidden>
      <BreadcrumbsBar />
      {!isStartPage && enableEcommerce && (
        <SubHeaderExploreChildNodes childNodes={productMenu} />
      )}
      {content.hero &&
      (content.hero.bigTitle || content.hero.backgroundImage) ? (
        <BlockResolver content={content.hero} pageInfo={pageInfo} />
      ) : null}
      <DisplayOptionsContentArea
        content={content.mainContent}
        propInfo={propsInfo.mainContent}
        component={SectionComponent}
        pageInfo={pageInfo}
        containWhen={({ _display: display, _type: type }) =>
          (type &&
            type !== "SectionBlock" &&
            display &&
            (display === "auto" || DisplayOptions[display] !== 1)) ||
          false
        }
        childContainer={ChildContainer}
        container={ContainerWrapper}
      />
    </>
  )
}

export default StartPage
