import { DynamicBlock, PageInfo, PropertyInfo } from "../types/content"
import editorAttributes from "../utils/editorAttributes"

import ContentArea, { ContentAreaProps } from "./ContentArea"

const pushBlock = (
  blocks: DynamicBlock[],
  elements: Array<React.ReactElement<any>>,
  propInfo?: PropertyInfo,
  pageInfo?: PageInfo,
  component?: React.ComponentType,
  childContainer?: React.ComponentType<{ block: DynamicBlock }>
) => {
  if (blocks.length) {
    elements.push(
      <ContentArea
        key={`content-area-${elements.length}`}
        content={[...blocks]}
        propInfo={propInfo}
        pageInfo={pageInfo}
        component={component}
        childContainer={childContainer}
      />
    )
  }
  return []
}

type Props = {
  containWhen: (block: DynamicBlock) => boolean
  container: React.ComponentType
} & ContentAreaProps

const DisplayOptionsContentArea: React.FunctionComponent<Props> = ({
  content,
  pageInfo,
  propInfo,
  component,
  containWhen,
  container,
  childContainer
}) => {
  const elements: Array<React.ReactElement<any>> = []
  const blocks = content || []

  let containedBlocks: DynamicBlock[] = []
  let uncontainedBlocks: DynamicBlock[] = []

  //inline function to add contained items to elements array
  const pushContained = () =>
    pushBlock(
      uncontainedBlocks,
      elements,
      propInfo,
      pageInfo,
      container,
      childContainer
    )

  //inline function to add uncontained items to elements array
  const pushUncontained = () =>
    pushBlock(containedBlocks, elements, propInfo, pageInfo, component)

  blocks.forEach((block) => {
    if (block._display && containWhen(block)) {
      containedBlocks = pushUncontained()
      uncontainedBlocks.push(block)
    } else {
      uncontainedBlocks = pushContained()
      containedBlocks.push(block)
    }
  })
  // last blocks, flush them and add to elements array
  pushContained()
  pushUncontained()

  return <div {...editorAttributes(propInfo)}>{elements}</div>
}

export default DisplayOptionsContentArea
export type { Props }
