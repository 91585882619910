import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import LinkButton from "common/LinkButton"
import ResponsiveImage from "common/ResponsiveImage"
import Typography from "common/Typography"
import { ImageBlock } from "types/content/media"

type Props = {
  image: ImageBlock
  category: string
  url: string
  heading: string
  text: string
}

const ArticleCard: React.FC<Props> = ({
  image,
  category,
  url,
  heading,
  text
}) => {
  const classes = useStyles()

  return (
    <Grid>
      <Box>
        <LinkButton link={url}>
          <ResponsiveImage
            imagePlaceholder={true}
            className={classes.media}
            containerProps={{
              className: classes.mediaContainer
            }}
            image={image}
          />
        </LinkButton>
      </Box>
      <Box mt={1}>
        <Typography className={classes.category} variant="link2">
          {category}
        </Typography>
      </Box>
      <Box mt={2} mb={1}>
        <Typography className={classes.headline} variant="headline4">
          {heading}
        </Typography>
      </Box>
      <Grid>
        <Typography className={classes.preamble} variant="preamble">
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { aspectRatio16x9 } }) => ({
    media: {
      position: "absolute",
      cursor: "pointer",
      width: "100%",
      height: "100%",
      objectFit: "cover"
    },
    mediaContainer: {
      position: "relative",
      aspectRatio: aspectRatio16x9,
      flex: 0
    },
    category: {
      fontWeight: "normal"
    },
    preamble: {
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 5,
      "-webkit-box-orient": "vertical",
      [breakpoints.down("xs")]: {
        fontSize: spacing(1.5)
      }
    },
    headline: {
      wordBreak: "break-word",
      fontSize: 28,
      [breakpoints.down("sm")]: {
        fontSize: spacing(2)
      }
    }
  })
)

export default ArticleCard
