import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import { CustomVariant } from "theme/typography"

type OwnProps = {
  price: string
  className?: string
  unit: string
  size?: "large" | "medium"
  lightGray?: boolean
}

type Props = OwnProps

const PricePerUnit: React.FC<Props> = ({ price, unit, size, lightGray }) => {
  const classes = useStyles()

  let variantSize = "priceTiny" as CustomVariant

  if (size === "large") {
    variantSize = "priceLarge"
  } else if (size === "medium") {
    variantSize = "priceMedium"
  }

  return (
    <div className={classes.root}>
      <Typography
        variant={variantSize}
        className={classNames({ [classes.lightGray]: lightGray })}
        component={"span"}
      >
        {`${price} / ${unit}`}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  root: {
    display: "flex",
    alignItems: "flex-end",
    fontWeight: 400
  },
  lightGray: {
    color: themeColors.colorBaseGray600
  }
}))

export default PricePerUnit
