import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Tooltip from "@material-ui/core/Tooltip"

import AddToCartOrShoppingListContainer from "common/AddToCartOrShoppingListContainer"
import ProductPrice from "common/Product/ProductPrice"
import StockStatus from "common/Product/StockStatus"
import ResponsiveImage from "common/ResponsiveImage"
import Product, { Price } from "models/Products"
import { ImageAsset } from "models/Variant"
import originalPartsStore from "store/OriginalPartsStore"
import SiteSettingsStore from "store/SiteSettingStore"

type OriginalPart = {
  _displayPrice: Price
  _discountedPrice?: Price
  displayName: string
  displayImage: ImageAsset
  articleNumber: string
  status: "IN_STOCK" | "NOT_IN_STOCK" | "FEW_IN_STOCK"
}

const PartsTable: React.FC = () => {
  const { t } = useTranslation()

  const {
    state: { enableEcommerce, enableWishlist }
  } = useStore(SiteSettingsStore)

  const classes = useStyles()

  const {
    state: { result }
  } = useStore(originalPartsStore)

  return !result.loading && result.data ? (
    <Table className={classes.table} padding="normal">
      <TableHead>
        <TableRow>
          <TableCell className={classes.minWidth} />
          <TableCell className={classes.minWidth}>
            {t("product.article_no")}
          </TableCell>
          <TableCell>{t("account.line_item_name")}</TableCell>
          <TableCell className={classes.minWidth}>
            {t("product.availability")}
          </TableCell>
          {enableEcommerce && (
            <TableCell className={classes.minWidth}>
              {t("product.price")}
            </TableCell>
          )}
          <TableCell className={classes.minWidth} />
        </TableRow>
      </TableHead>
      <TableBody>
        {result.data[0] && result.data[0].hits.length > 0 ? (
          (result.data[0].hits as Product[]).map((item, index) => {
            const variation = item.variations[0]
            const hasNoImage = !(item.displayImage && item.displayImage._url)
            return (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell>
                  <Tooltip
                    arrow
                    title={
                      <img
                        alt="image tooltip"
                        className={classes.imageTooltipImage}
                        src={item.displayImage && item.displayImage._url}
                      />
                    }
                    placement={"right"}
                    classes={{
                      tooltip: classes.imageTooltip
                    }}
                    disableFocusListener={hasNoImage}
                    disableHoverListener={hasNoImage}
                    disableTouchListener={hasNoImage}
                  >
                    <div>
                      <ResponsiveImage
                        relative
                        image={item.displayImage}
                        className={classNames(classes.productImage, {
                          [classes.magnifier]: !hasNoImage
                        })}
                      />
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell>{variation ? variation.code : ""}</TableCell>
                <TableCell>{item.displayName}</TableCell>
                <TableCell>
                  <StockStatus
                    inventoryStatus={item.inventoryStatus}
                    isDigitalService={item.isDigitalService}
                  />
                </TableCell>
                {enableEcommerce && (
                  <TableCell className={classes.bold}>
                    <ProductPrice
                      price={item.displayLowestPrice}
                      discountedPrice={item.displayLowestDiscountedPrice}
                      isInline={true}
                      size={"small"}
                      className={classes.variantPrice}
                    />
                  </TableCell>
                )}
                <TableCell align="right">
                  <AddToCartOrShoppingListContainer
                    code={variation ? variation.code : "NO VARIANT HERE"}
                    amount={1}
                    disabled={
                      !item.inventoryStatus ||
                      item.inventoryStatus === "NOT_IN_STOCK" ||
                      !item.displayLowestPrice
                    }
                    name={"Test article"}
                    category={"Original Parts"}
                    image={item.displayImage && item.displayImage._url}
                    variationValue={item.productName2}
                    digitalService={item["isDigitalService"]}
                    discountedPrice={item.displayLowestDiscountedPrice}
                    displayPrice={item.displayLowestPrice}
                  />
                </TableCell>
              </TableRow>
            )
          })
        ) : (
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.noResultTableCell} colSpan={6}>
              {t(`search.no_original_parts_found`)}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  ) : null
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  table: {
    borderCollapse: "separate",
    "& th": {
      color: themeColors.darkGray,
      whiteSpace: "nowrap"
    }
  },
  tableRow: {
    height: 72,
    "&:last-child td": {
      borderBottom: 0
    }
  },
  bold: {
    fontWeight: "bold"
  },
  noWrap: {
    whiteSpace: "nowrap",
    display: "flex",
    "& > div > div": {
      borderBottom: `1px solid ${themeColors.whiteGray}`
    }
  },
  variantPrice: {
    fontSize: 16
  },
  minWidth: {
    width: 1
  },
  productImage: {
    position: "relative",
    width: 100,
    height: "auto",
    display: "block"
  },
  noResultTableCell: {
    textAlign: "center"
  },
  imageTooltip: {
    backgroundColor: "transparent",
    width: "auto",
    height: 132,
    padding: 0,
    display: "flex",
    alignItems: "center"
  },
  imageTooltipImage: {
    width: "auto",
    height: 132,
    display: "block",
    border: `1px solid ${themeColors.lightGray}`
  },
  magnifier: {
    cursor: "zoom-in"
  }
}))

export default PartsTable
