type Props = {
  className?: string
}

const AddToCartCheckIcon: React.FC<Props> = (props) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3 0.3L5 9.6L1.7 6.3C1.3 5.9 0.7 5.9 0.3 6.3C-0.1 6.7 -0.1 7.3 0.3 7.7L4.3 11.7C4.5 11.9 4.7 12 5 12C5.3 12 5.5 11.9 5.7 11.7L15.7 1.7C16.1 1.3 16.1 0.7 15.7 0.3C15.3 -0.1 14.7 -0.1 14.3 0.3Z"
      fill="currentColor"
    />
  </svg>
)

export default AddToCartCheckIcon
