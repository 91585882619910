import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const MailDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      d="m28 7.3-12 12-12-12m0-.6h24v18.6H4V6.7Z"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
    />
  </SvgIcon>
)

export default MailDeLaval
