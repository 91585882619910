import { BlockProps } from "@starrepublic/epi/cms"

import Box from "@material-ui/core/Box"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { ImageProductCarouselContent } from "blocks/ImageProductCarouselBlock/types"
import { pageMaxWidth, themeColors } from "theme"
import BundleCarousel from "./BundleCarousel"
import TextContent from "./TextContent"

type Props = BlockProps<ImageProductCarouselContent>

const BLOCK_MAX_WIDTH = 1440
const CONTENT_DESKTOP_MAX_WIDTH = pageMaxWidth
const CONTENT_TABLET_MAX_WIDTH = 652

const BundleBlock: React.FC<Props> = ({ content }) => {
  const PRODUCT_WIDTH_SMALL = 284
  const PRODUCT_WIDTH_BIG = 340

  const { backgroundColor, items } = content

  const isMdUp = useMediaQuery(({ breakpoints }) => breakpoints.up("md"))

  const productWidth =
    items.length > 2 ? PRODUCT_WIDTH_SMALL : PRODUCT_WIDTH_BIG
  const productsShowingInCarousel = items.length <= 2 ? items.length : 3
  const carouselPixelWidth = productWidth * productsShowingInCarousel
  const carouselPercentageWidth = `${
    (carouselPixelWidth / CONTENT_DESKTOP_MAX_WIDTH) * 100
  }%`

  const backgroundGradient = {
    background: `linear-gradient(${"90deg"},${themeColors.primaryBlue} 50%, ${backgroundColor} 50%)`
  }

  const backgroundGradientMobile = {
    background: `linear-gradient(${"180deg"},${themeColors.primaryBlue} 50%, ${backgroundColor} 50%)`,
    width: "100vw",
    marginLeft: "calc(50% - 50vw)"
  }

  return (
    <Box style={isMdUp ? backgroundGradient : backgroundGradientMobile}>
      <Box
        pt={{ xs: 4, sm: 4, md: 12 }}
        pb={{ xs: 2, sm: 2, md: 12 }}
        px={{ xs: 3, sm: 3, md: 4.5 }}
        display="flex"
        maxWidth={BLOCK_MAX_WIDTH}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: "column", sm: "column", md: "row" }}
          width="100%"
          mx="auto"
          maxWidth={{
            sm: CONTENT_TABLET_MAX_WIDTH,
            md: CONTENT_DESKTOP_MAX_WIDTH
          }}
        >
          <Box>
            <TextContent
              {...content}
              tabletMaxWidth={CONTENT_TABLET_MAX_WIDTH}
            />
          </Box>

          <Box
            display="flex"
            justifyContent="end"
            flexGrow={0}
            flexBasis={isMdUp ? carouselPercentageWidth : "100%"}
            maxWidth={isMdUp ? carouselPercentageWidth : "100%"}
          >
            <BundleCarousel
              {...content}
              carouselWidth={carouselPixelWidth}
              productsShowingInCarousel={productsShowingInCarousel}
              productWidthBig={PRODUCT_WIDTH_BIG}
              productWidthSmall={PRODUCT_WIDTH_SMALL}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BundleBlock
