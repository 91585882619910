import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FormikActions } from "formik"
import useStore from "global-hook-store"

import { Box, makeStyles } from "@material-ui/core/"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import { CustomerContactModel } from "api/customerContact"
import Typography from "common/Typography"
import authStore from "store/AuthStore"
import { UserDetailsModalFormType } from "./userDetailsValidationSchema"

export type CustomerPartialModel = Partial<CustomerContactModel>

const AccountUserDetails: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { user, updateUserDetailsResponse },
    actions: { getUser, updateUserDetails }
  } = useStore(authStore)

  const initialValues = {
    sapCustomerNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: ""
  }

  const [userValues, setUserValues] = useState(initialValues)
  const [formDirty, setFormDirty] = useState(false)
  const [formValid, setFormValid] = useState(true)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  const handleEditDialog = () => {
    setIsDialogOpen(true)
  }

  const populateUserValues = () => {
    if (user.data) {
      userValues.sapCustomerNumber = `${user.data.sapCustomerNumber}`
      userValues.firstName = `${user.data.firstName}`
      userValues.lastName = `${user.data.lastName}`
      userValues.email = `${user.data.email}`
      userValues.phone = `${user.data.phoneNumber}`
      setUserValues(userValues)
    }
  }

  useEffect(
    () => {
      if (!user.data) {
        getUser()
      } else {
        populateUserValues()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getUser, user.data]
  )

  const handleSubmit = (
    values: UserDetailsModalFormType,
    { setSubmitting }: FormikActions<UserDetailsModalFormType>
  ) => {
    if (user.data) {
      const payload = {
        id: user.data.id,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone
      } as CustomerContactModel
      updateUserDetails(payload).then(() => {
        setSubmitting(false)
        setIsDialogOpen(false)
        getUser()
      })
    }
  }

  return (
    <Box p={3}>
      <div className={classes.headline}>
        <Typography variant="headline6" className={classes.sectionName}>
          {t("common.user_details_title")}
        </Typography>
      </div>
      {user.data && (
        <Table>
          <TableBody>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Typography variant="smallText" className={classes.label}>
                  {t("account.customer_number_label")}
                </Typography>
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                <Typography variant="body1">
                  {user.data.sapCustomerNumber}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Typography variant="smallText" className={classes.label}>
                  {t("account.first_name_label")}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="body1">{user.data.firstName}</Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Typography variant="smallText" className={classes.label}>
                  {t("account.last_name_label")}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="body1">{user.data.lastName}</Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Typography variant="smallText" className={classes.label}>
                  {t("account.email_label")}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="body1">{user.data.email}</Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Typography variant="smallText" className={classes.label}>
                  {t("account.phone_label")}
                </Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="body1">{user.data.phoneNumber}</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    sectionName: {
      color: themeColors.darkGray,
      marginRight: spacing(2)
    },
    tableRow: {
      verticalAlign: "text-top",
      [breakpoints.down("sm")]: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        paddingBottom: spacing(1)
      }
    },
    tableCell: {
      border: "none",
      paddingLeft: 0,
      paddingBottom: spacing(1.5),
      paddingTop: spacing(1.5),
      "&:last-child": {
        padding: 0
      },
      [breakpoints.down("sm")]: {
        display: "flex",
        width: "100%",
        paddingBottom: spacing(0.5),
        paddingTop: spacing(0.5)
      }
    },
    label: {
      color: themeColors.gray
    },
    headline: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      marginBottom: spacing(2)
    }
  })
)

export default AccountUserDetails
