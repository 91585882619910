import i18next from "i18next"
import * as yup from "yup"

export interface MissingAccountInformationType {
  vatNumber: string
}

const validationSchema = (
  t: i18next.TranslationFunction,
  countryCode: string
) => {
  let regexp = /^[0-9]$/

  let translation = t("checkout.vat_number_format_message")

  if (countryCode === "FI") {
    regexp = /^[0-9]{8}$/
    translation = translation.replace("{value}", "FI99999999")
  }

  if (countryCode === "SE") {
    regexp = /^[0-9]{10}$/
    translation = translation.replace("{value}", "SE999999999901")
  }

  return yup.object<MissingAccountInformationType>().shape({
    vatNumber: yup
      .string()
      .required(t("checkout.vat_number_required_message"))
      .matches(regexp, translation)
  })
}

export default validationSchema
