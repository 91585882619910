import { PageInfo } from "@starrepublic/epi/cms"

import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"

import BreadcrumbsBar from "common/BreadcrumbsBar"
import ProductListing from "common/ProductListing"
import SubCategoryCard from "common/SubCategoryCard"
import { ProductMenu } from "common/WebNodeListing/ProductMenu"
import Wrapper from "common/Wrapper"
import { WebNodeContent } from "./types"
import WebNodeHeader from "./WebNodeHeader"

type Props = {
  content: WebNodeContent
  pageInfo: PageInfo
}

const WebNodeDetails: React.FC<Props> = ({ content, pageInfo }) => {
  const classes = useStyles()
  return (
    <>
      <BreadcrumbsBar />
      <Wrapper grid className={classes.root}>
        <Hidden smDown>
          <Grid md={4} lg={3} item>
            <ProductMenu />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={8} lg={9}>
          <WebNodeHeader content={content} />
          {content.children.map((child, index) => (
            <SubCategoryCard
              key={`${child._id}-${index}`}
              title={child.title}
              route={child._route}
            />
          ))}
          <ProductListing route={pageInfo._route} />
        </Grid>
      </Wrapper>
    </>
  )
}

const useStyles = makeStyles(({ breakpoints, common: { themeColors } }) => ({
  root: {
    background: themeColors.white,
    [breakpoints.down("sm")]: {
      padding: 0
    }
  }
}))

export default WebNodeDetails
