import { TextDecorationProperty, TextTransformProperty } from "csstype"

import createBreakpoints from "@material-ui/core/styles/createBreakpoints"
import {
  TypographyOptions,
  TypographyStyleOptions,
  Variant
} from "@material-ui/core/styles/createTypography"
const breakpoints = createBreakpoints({})

import themeColors from "./colors"

const htmlFontSize = 16
const fontSize = 16

const browserDefaultFontSize = 16

export const pxToRem = (size: number) => {
  const ratio = fontSize / browserDefaultFontSize
  return `${(size / htmlFontSize) * ratio}rem`
}

export type CustomVariant =
  | "headline1"
  | "headline2"
  | "headline3"
  | "headline4"
  | "headline5"
  | "headline6"
  | "subheading1"
  | "preamble"
  | "body"
  | "body1"
  | "body2"
  | "body3"
  | "link1"
  | "link2"
  | "link3"
  | "link4"
  | "button1"
  | "label"
  | "badge"
  | "caption"
  | "priceLarge"
  | "priceMedium"
  | "priceSmall"
  | "priceTiny"
  | "priceDiscount"
  | "smallText"
  | "listElement1"
  | "listElement2"
  | "breadCrumbs"
  | "tag"
  | "th"
  | "title"

const round = (value: number) => Math.round(value * 1e5) / 1e5

type VariantParams = {
  fontFamily: string
  size: number
  lineHeight: number
  fontStyle?: string
  letterSpacing?: number
  fontWeight?: number
  textTransform?: TextTransformProperty
  textDecoration?: TextDecorationProperty
  color: string
  breakpoints?: any // Should be typed but it's not working
}

const buildVariant = ({
  fontFamily,
  size,
  lineHeight,
  fontStyle,
  letterSpacing,
  fontWeight,
  textTransform,
  textDecoration,
  color,
  breakpoints
}: VariantParams) => ({
  color,
  fontFamily,
  fontWeight,
  fontStyle,
  lineHeight: lineHeight / size,
  fontSize: pxToRem(size),
  ...breakpoints,
  ...(textTransform && { textTransform }),
  ...(textDecoration && { textDecoration }),
  ...(letterSpacing && { letterSpacing: `${round(letterSpacing / size)}em` })
})

type Fonts = {
  [key: string]: {
    fontFamily: string
    fontWeight?: number
  }
}

export const fonts: Fonts = {
  helveticaNeue: {
    fontFamily: "HelveticaNeue, Arial, sans-serif",
    fontWeight: 300
  }
}

export const customTypography: {
  [key in CustomVariant]: TypographyStyleOptions
} = {
  headline1: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.primaryBlue,
    size: 36,
    lineHeight: 44,
    fontWeight: 700,
    breakpoints: {
      [breakpoints.up("md")]: {
        fontSize: pxToRem(44),
        lineHeight: 52 / 44
      }
    }
  }),
  headline2: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.primaryBlue,
    size: 32,
    lineHeight: 36,
    fontWeight: 700,
    breakpoints: {
      [breakpoints.up("md")]: {
        fontSize: pxToRem(40),
        lineHeight: 48 / 40
      }
    }
  }),
  headline3: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.primaryBlue,
    size: 28,
    lineHeight: 32,
    fontWeight: 700,
    breakpoints: {
      [breakpoints.up("md")]: {
        fontSize: pxToRem(36),
        lineHeight: 44 / 36
      }
    }
  }),
  headline4: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.primaryBlue,
    size: 24,
    lineHeight: 28,
    fontWeight: 700,
    breakpoints: {
      [breakpoints.up("md")]: {
        fontSize: pxToRem(32),
        lineHeight: 36 / 32
      }
    }
  }),
  headline5: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.primaryBlue,
    size: 20,
    lineHeight: 24,
    fontWeight: 700,
    breakpoints: {
      [breakpoints.up("md")]: {
        fontSize: pxToRem(28),
        lineHeight: 32 / 28
      }
    }
  }),
  headline6: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.primaryBlue,
    size: 16,
    lineHeight: 22,
    fontWeight: 700,
    breakpoints: {
      [breakpoints.up("md")]: {
        fontSize: pxToRem(18),
        lineHeight: 24 / 18
      }
    }
  }),
  subheading1: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.darkGray,
    size: 20,
    lineHeight: 24,
    fontWeight: 300
  }),
  preamble: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.darkGray,
    size: 16,
    lineHeight: 24
  }),
  body: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.colorBaseGray700,
    size: 20,
    lineHeight: 24,
    fontWeight: 300,
    breakpoints: {
      [breakpoints.up("md")]: {
        fontSize: pxToRem(24),
        lineHeight: 36 / 24
      }
    }
  }),
  body1: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.colorBaseGray700,
    size: 16,
    lineHeight: 24,
    fontWeight: 300
  }),
  body2: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.colorBaseGray700,
    size: 14,
    lineHeight: 20,
    fontWeight: 300
  }),
  body3: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.colorBaseGray700,
    size: 12,
    lineHeight: 16,
    fontWeight: 300
  }),
  smallText: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.colorBaseBlue900,
    size: 12,
    lineHeight: 16,
    fontWeight: 300
  }),
  listElement1: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.colorBaseBlue900,
    size: 16,
    lineHeight: 24,
    fontWeight: 300
  }),
  listElement2: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.colorBaseBlue900,
    size: 13,
    lineHeight: 20,
    fontWeight: 300
  }),
  button1: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.colorBaseBlue900,
    size: 14,
    lineHeight: 24,
    fontWeight: 700
  }),
  link1: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.primaryCyan,
    size: 18,
    lineHeight: 24,
    fontWeight: 700
  }),
  link2: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.primaryCyan,
    size: 16,
    lineHeight: 20,
    fontWeight: 700,
    textTransform: "none"
  }),
  link3: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.primaryCyan,
    size: 16,
    lineHeight: 20,
    fontWeight: 300
  }),
  link4: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.colorBaseBlue900,
    size: 14,
    lineHeight: 20,
    fontWeight: 300
  }),
  breadCrumbs: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.primaryCyan,
    size: 12,
    lineHeight: 15,
    fontWeight: 700
  }),
  tag: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.primaryCyan,
    size: 12,
    lineHeight: 16,
    fontWeight: 300
  }),
  priceLarge: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.darkerGray,
    size: 24,
    lineHeight: 24,
    fontWeight: 500
  }),
  priceMedium: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.darkerGray,
    size: 18,
    lineHeight: 24,
    fontWeight: 500
  }),
  priceSmall: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.darkerGray,
    size: 16,
    lineHeight: 20,
    fontWeight: 500
  }),
  priceTiny: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.darkerGray,
    size: 14,
    lineHeight: 20,
    fontWeight: 300
  }),
  priceDiscount: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.secondaryRed,
    size: 16,
    lineHeight: 24,
    fontWeight: 700
  }),
  label: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.darkGray,
    size: 14,
    lineHeight: 24
  }),
  badge: buildVariant({
    ...fonts.helveticaNeue,
    color: "inherit",
    size: 14,
    lineHeight: 20,
    fontWeight: 300
  }),
  caption: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.darkGray,
    size: 16,
    lineHeight: 24
  }),
  th: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.darkGray,
    size: 12,
    lineHeight: 20,
    fontWeight: 700
  }),
  title: buildVariant({
    ...fonts.helveticaNeue,
    color: themeColors.white,
    size: 56,
    lineHeight: 56,
    fontWeight: 700
  })
}

export const customToMuiVariantMapping: { [key in CustomVariant]: Variant } = {
  headline1: "h1",
  headline2: "h2",
  headline3: "h3",
  headline4: "h4",
  headline5: "h5",
  headline6: "h6",
  subheading1: "subtitle1",
  preamble: "h6",
  body: "body1",
  body1: "body1",
  body2: "body2",
  body3: "h2",
  button1: "h2",
  link1: "h2",
  link2: "h2",
  link3: "h2",
  link4: "h2",
  label: "h2",
  badge: "h2",
  caption: "caption",
  priceLarge: "h2",
  priceMedium: "h2",
  priceSmall: "h2",
  priceTiny: "h2",
  priceDiscount: "h2",
  smallText: "h2",
  listElement1: "h2",
  listElement2: "h2",
  breadCrumbs: "h2",
  tag: "h2",
  th: "h2",
  title: "h2"
}

export const muiToCustomVariantMapping: Partial<
  Record<Variant, CustomVariant>
> = {
  h1: "headline1",
  h2: "headline2",
  h3: "headline3",
  h4: "headline4",
  h5: "headline5",
  h6: "headline6",
  subtitle1: "subheading1",
  body1: "body1",
  body2: "body1",
  button: "button1",
  caption: "link1"
}

export const createMuiTypography = (): TypographyOptions => {
  const muiVariants = Object.keys(muiToCustomVariantMapping) as Variant[]
  const typographyStyles = muiVariants.reduce((acc, variant) => {
    const customVariant = muiToCustomVariantMapping[variant]
    const styles = customVariant ? customTypography[customVariant] : {}
    return { ...acc, [variant]: styles }
  }, {})

  return {
    ...typographyStyles,
    htmlFontSize,
    fontSize,
    fontFamily: fonts.helveticaNeue.fontFamily
  }
}
