import i18next from "i18next"
import * as yup from "yup"

import { Address } from "models/Checkout"

const validationSchema = (
  t: i18next.TranslationFunction,
  postalCodeValidationPatten: string,
  marketId: string
) => {
  return yup.object<Address>().shape({
    firstName: yup.string().required(t("checkout.first_name_required_message")),
    lastName: yup.string().required(t("checkout.last_name_required_message")),
    streetAddress: yup
      .string()
      .required(t("checkout.street_address_required_message")),

    countryCode: yup.string().required(t("checkout.country_required_message")),
    zipCode: yup
      .string()
      .required(t("checkout.zip_code_required_message"))
      .when("countryCode", {
        is: (value) => {
          return value === marketId
        },
        then: yup
          .string()
          .matches(
            postalCodeValidationPatten
              ? new RegExp(postalCodeValidationPatten)
              : /^[0-9]{5}$/,
            t("checkout.zip_code_format_message")
          )
      }),
    city: yup.string().required(t("checkout.city_required_message")),
    phone: yup
      .string()
      .required(t("checkout.phone_required_message"))
      .matches(
        /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
        t("checkout.phone_invalid_message")
      )
  })
}

export default validationSchema
