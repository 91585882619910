import { useEffect, useState } from "react"
import useStore from "global-hook-store"
import Cookies from "js-cookie"

import ButtonBase from "@material-ui/core/ButtonBase"
import Grid from "@material-ui/core/Grid"
import Slide from "@material-ui/core/Slide"
import Snackbar from "@material-ui/core/Snackbar"
import { makeStyles } from "@material-ui/core/styles"
import { TransitionProps } from "@material-ui/core/transitions"
import Clear from "@material-ui/icons/Clear"

import SiteSettingsStore from "store/SiteSettingStore"
import NewsletterContent from "./NewsletterContent"

const NEWSLETTER_COOKIE_NAME = "news_letter_closed"

const Newsletter: React.FC = () => {
  const classes = useStyles()

  const [isNewsletterVisible, setNewsletterVisible] = useState(
    !Cookies.get(NEWSLETTER_COOKIE_NAME)
  )

  const MESSAGE_ID = "news-letter-message-id"
  const [transition, setTransition] = useState<
    | React.ComponentType<
        TransitionProps & {
          children?: React.ReactElement<any, any> | undefined
        }
      >
    | undefined
  >(undefined)

  const {
    state: { displayNewsletterForm }
  } = useStore(SiteSettingsStore)

  const snackbarClasses = {
    root: classes.snackbarRoot
  }

  const snackbarContentProps = {
    "aria-describedby": MESSAGE_ID,
    classes: {
      root: classes.snackBarContentRoot,
      message: classes.snackBarContentMessage,
      action: classes.action
    }
  }

  const transitionSetting = (props: TransitionProps) => {
    return <Slide {...props} direction="up" />
  }

  const toggleNewsletter = () => {
    if (!Cookies.get(NEWSLETTER_COOKIE_NAME)) {
      const footerHeight =
        document.getElementsByTagName("footer")[0].clientHeight
      if (
        document.documentElement.scrollTop + window.innerHeight >
        document.documentElement.scrollHeight - footerHeight
      ) {
        setNewsletterVisible(false)
      } else {
        setNewsletterVisible(true)
      }
    }
  }

  useEffect(() => {
    setTransition(() => transitionSetting)
    window.addEventListener("scroll", toggleNewsletter)
    return () => {
      window.removeEventListener("scroll", toggleNewsletter)
    }
  }, [])

  if (!displayNewsletterForm) {
    return null
  }

  return (
    <Grid>
      <Snackbar
        id="newsletter-bar"
        classes={snackbarClasses}
        open={isNewsletterVisible}
        action={
          <ButtonBase
            className={classes.button}
            onClick={() => {
              Cookies.set(NEWSLETTER_COOKIE_NAME, "true", { expires: 120 }),
                setNewsletterVisible(false)
            }}
            id="close-newsletter-bar"
          >
            <Clear />
          </ButtonBase>
        }
        TransitionComponent={transition}
        ContentProps={snackbarContentProps}
        message={<NewsletterContent />}
        ClickAwayListenerProps={{
          mouseEvent: false
        }}
      />
    </Grid>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors, pageMaxWidth }, breakpoints, spacing }) => ({
    snackbarRoot: {
      bottom: 0,
      left: "50%",
      right: "auto",
      transform: "translateX(-50%)"
    },
    snackBarContentRoot: {
      width: "100vw",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      textAlign: "left",
      backgroundColor: themeColors.white,
      maxWidth: "100%",
      padding: 0,
      [breakpoints.up("md")]: {
        textAlign: "left"
      },
      [breakpoints.down("xs")]: {
        flexDirection: "column",
        "-ms-flex-direction": "row",
        justifyContent: "center"
      }
    },
    snackBarContentMessage: {
      width: "100%",
      padding: 0
    },
    button: {
      width: 24,
      color: themeColors.darkGray
    },
    action: {
      position: "absolute",
      top: 0,
      right: 0,
      margin: spacing(2)
    }
  })
)

export default Newsletter
