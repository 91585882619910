import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import { FooterLink } from "types/content/footer"

type SocialLinksProps = FooterLink

const IconCircle: React.FC<SocialLinksProps> = (Props) => {
  const { text, href, target, children } = Props
  const classes = useStyles()
  return (
    <a key={text} href={href} target={target}>
      <Box className={classes.socialImg}>{children}</Box>
    </a>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  socialImg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    background: `${themeColors.white}`,
    height: 48,
    width: 48,
    borderRadius: 24,
    marginInline: spacing(1),
    "& svg": {
      width: 32
    }
  }
}))

export default IconCircle
