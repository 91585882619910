import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"
import debounce from "lodash/debounce"

import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { SearchResultType } from "api/search"
import Search from "icons/Search"
import AuthStore from "store/AuthStore"
import SearchStore from "store/SearchStore"
import SiteSettingStore from "store/SiteSettingStore"
import SearchContent from "./SearchContent"

type Props = { fixedTop: number }

const SearchBar: React.FC<Props> = ({ fixedTop }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)

  const DEBOUNCED_SEARCH_TIMER = 1000
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { isSearchBarOpen },
    actions: { asyncSearch, resetResult, forceLoading, setSearchBarOpen }
  } = useStore(SearchStore)

  const {
    state: { header }
  } = useStore(SiteSettingStore)

  const {
    state: { user }
  } = useStore(AuthStore)

  const fixedTopValue =
    user.data && user.data.impersonatingUser
      ? fixedTop + 64
      : header && header.upperPromoText
        ? fixedTop + 32
        : fixedTop

  useEffect(
    () => {
      if (inputRef.current) {
        inputRef.current.value = ""
      }
      setSearchTerm("")
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.href]
  )

  const handleClose = (e?: React.MouseEvent<Document, MouseEvent>) => {
    if (e) {
      e.preventDefault()
    }
    setSearchBarOpen(false).then(() => {
      resetResult()
    })
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault()
      setSearchBarOpen(false)
    }
  }

  const valueIsValid = (value: string) => {
    return value && value.length > 2
  }

  const handleSearchChange = debounce((value: string) => {
    debouncedSearch(value)
    setSearchTerm(value)
  }, DEBOUNCED_SEARCH_TIMER)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (
      inputRef.current &&
      valueIsValid(inputRef.current.value) &&
      inputRef.current.value !== searchTerm
    ) {
      forceLoading()
    }
  }

  const searchLimit = useMediaQuery("(min-width:960px)") ? 8 : 4

  const debouncedSearch = (value: string) => {
    if (valueIsValid(value)) {
      asyncSearch({
        type: SearchResultType.Products,
        q: value,
        limit: searchLimit,
        offset: 0
      })
    }
  }

  return (
    <div className={classes.root} style={{ top: fixedTopValue }}>
      {isSearchBarOpen && (
        <Grow in={isSearchBarOpen}>
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                id="search"
                onKeyDown={handleListKeyDown}
                className={classes.menu}
              >
                <form
                  id="search-form"
                  onSubmit={handleSubmit}
                  className={classes.form}
                >
                  <div className={classes.textFieldArea}>
                    <button type="submit" className={classes.searchButton}>
                      <Search />
                    </button>
                    <TextField
                      autoFocus
                      name="searchTerm"
                      variant="filled"
                      inputRef={inputRef}
                      placeholder={t("search.placeholder_text")}
                      className={classes.textField}
                      onChange={(e) => {
                        handleSearchChange(e.currentTarget.value)
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          handleSubmit(e)
                        }
                      }}
                    />
                  </div>
                </form>
                <SearchContent
                  searchTerm={searchTerm}
                  onClose={() => {
                    handleClose()
                  }}
                />
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors, pageMaxWidth } }) => ({
    root: {
      position: "fixed",
      left: 0,
      right: 0,
      top: 0
    },
    paper: {
      width: "100%",
      padding: 0
    },
    menu: {
      padding: 0,
      width: "100%"
    },
    form: {
      color: themeColors.darkGray,
      width: "100%",
      background: themeColors.offWhite,
      borderBottom: `1px solid ${themeColors.grayBorder}`
    },
    textFieldArea: {
      display: "flex",
      width: "100%",
      maxWidth: pageMaxWidth,
      margin: "0 auto"
    },
    textField: {
      width: "100%",
      color: themeColors.darkGray,
      overflow: "hidden",
      background: themeColors.offWhite,
      padding: 0,
      "& .MuiFilledInput-input": {
        padding: spacing(3) - 1,
        background: themeColors.offWhite
      },
      "& .MuiFilledInput-underline:before": {
        display: "none"
      },
      "& .MuiFilledInput-underline:after": {
        display: "none"
      }
    },
    searchButton: {
      background: themeColors.offWhite,
      color: themeColors.darkGray,
      border: "none",
      outline: 0,
      width: 64,
      padding: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 0,
      "&:hover": {
        backgroundColor: "none"
      }
    }
  })
)

export default SearchBar
