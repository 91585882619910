import { FC, useState } from "react"

import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import { makeStyles } from "@material-ui/core/styles"

import Adyen from "pages/Checkout/Adyen"

type Props = {
  expansionIcon: any
}

const PaymentExpansionPanel: FC<Props> = () => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState<string | false>(false)

  const handleChange =
    (panel: string) => (_event: React.ChangeEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.expansionPanel}
        expanded={expanded === "panel"}
        onChange={handleChange("expansionPanel")}
      >
        <AccordionSummary id="expansionPanel" aria-controls="panel">
          <AccordionDetails className={classes.expansionDetails}>
            <Adyen />
          </AccordionDetails>
        </AccordionSummary>
      </Accordion>
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    position: "relative"
  },
  expansionPanel: {
    width: "100%",
    borderBottom: `1px solid ${themeColors.lightGray}`
  },
  expansionDetails: {
    width: "100%"
  }
}))

export default PaymentExpansionPanel
