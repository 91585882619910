import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"

import Remove from "icons/Remove"
import { LineItem } from "models/Cart"
import CartStore from "store/CartStore"
import { useCheckoutPaymentStore } from "store/CheckoutStore"

type Props = {
  item: LineItem
}

const ItemActions: React.FC<Props> = ({ item }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { selectedCart },
    actions: { updateItemQuantity }
  } = useStore(CartStore)
  const { fetchingCheckout, resetCheckoutStates } = useCheckoutPaymentStore()

  const [quantity, setQuantity] = useState(item.quantity)
  const [empty, setEmpty] = useState(false)

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value === "") {
      setEmpty(true)
    } else {
      const newQuantity = parseInt(value, 10) || 1

      setQuantity(newQuantity)
      setEmpty(false)
    }
  }

  useEffect(() => {
    setQuantity(item.quantity)
  }, [item.quantity])

  const reset = async () => {
    await resetCheckoutStates()
    await fetchingCheckout()
  }

  const handleQuantityBlur = () => {
    updateItemQuantity({
      quantity,
      cartTypeName: "basket",
      code: item.code,
      amount: item.placedPrice,
      name:
        item["properties"] && item["properties"]["productDisplayName"]
          ? item["properties"]["productDisplayName"]
          : "Missing name",
      category:
        item["properties"] && item["properties"]["categoryStructure"]
          ? item["properties"]["categoryStructure"]
          : "Missing category"
    }).then((res) => {
      if (
        res.cart &&
        res.cart.data &&
        res.cart.data["numberOfItems"] !== selectedCart["numberOfItems"]
      ) {
        reset()
      }
    })
  }

  const handleRemoveItem = () => {
    updateItemQuantity({
      cartTypeName: "basket",
      code: item.code,
      quantity: 0,
      amount: item.placedPrice,
      name:
        item["properties"] && item["properties"]["productDisplayName"]
          ? item["properties"]["productDisplayName"]
          : "Missing name",
      category:
        item["properties"] && item["properties"]["categoryStructure"]
          ? item["properties"]["categoryStructure"]
          : "Missing category"
    }).then((res) => {
      if (
        res.cart &&
        res.cart.data &&
        res.cart.data["numberOfItems"] !== selectedCart["numberOfItems"]
      ) {
        reset()
      }
    })
  }

  return (
    <div className={classes.wrap}>
      <TextField
        variant="outlined"
        onChange={handleQuantityChange}
        onBlur={handleQuantityBlur}
        InputProps={{
          classes: {
            root: classes.quantityInput,
            notchedOutline: classes.border,
            input: "cart-item-quantity-input"
          }
        }}
        inputProps={{
          style: {
            textAlign: "center",
            border: "none",
            padding: 0,
            fontSize: 14
          }
        }}
        className={classes.quantitySelector}
        value={empty ? "" : quantity}
      />
      <Remove
        onClick={handleRemoveItem}
        className={classNames("cart-item-remove-button", classes.removeIcon)}
      />
    </div>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  quantityInput: {
    height: "100%"
  },
  border: {
    borderRadius: 2
  },
  quantitySelector: {
    height: spacing(5),
    width: spacing(6)
  },
  removeIcon: {
    cursor: "pointer",
    width: spacing(4),
    height: spacing(4),
    marginLeft: spacing(1),
    marginTop: spacing(0.5)
  },
  wrap: {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1
  }
}))

export default ItemActions
