import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { FenceCalculatorBlockProps } from "../types"

import Accessory from "./Accessory"

type Props = {
  content: FenceCalculatorBlockProps
}

const SelectAccessories = ({ content }: Props) => {
  const { utilityProducts, currencySymbol } = content
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" pt={1}>
      <Grid
        className={classes.mb}
        style={{ display: "flex", flexDirection: "column" }}
      >
        {utilityProducts &&
          utilityProducts.map((product, key) => (
            <Accessory
              key={key}
              product={product}
              currencySymbol={currencySymbol}
            />
          ))}
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  my: {
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },
  mb: {
    marginBottom: spacing(4)
  },
  centered: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  tableHeadDetails: {
    "& p": {
      fontWeight: "bold"
    }
  },
  minWidth: {
    width: "1px",
    paddingLeft: 0
  }
}))

export default SelectAccessories
