import { useTranslation } from "react-i18next"

import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"

type Props = {
  error: string
  onRetry?: () => void
}

const ErrorMessage: React.FC<Props> = ({ error, onRetry }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Typography variant="headline1">
        {t("error.something_went_wrong")}
      </Typography>
      <Typography>{t(`error.${error.toLowerCase()}`)}</Typography>
      {onRetry && (
        <Button className={classes.button} onClick={onRetry}>
          {t("ErrorMessage.retry")}
        </Button>
      )}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 30
  },
  button: {
    marginTop: 15
  }
}))

export default ErrorMessage
