import { useEffect, useState } from "react"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { numberFormat } from "utils/numberFormat"
import { scrollToElement } from "utils/ui/scroll"

import Button from "common/Button"
import Typography from "common/Typography"
import EnergyCalculator from "store/EnergyCalculatorStore"
import marketStore from "store/MarketStore"
import theme from "theme"
import { RowProp } from "./Consumption"
import SliderInput from "./SliderInput"
import { EnergyCalculatorBlockProps } from "./types"

const CalculatorForm: React.FC<EnergyCalculatorBlockProps> = (props) => {
  const classes = useStyles()

  const {
    energyConsumptionCooling,
    enableEmailInContactForm,
    kiloWattOfEnergy,
    maxEnergyCost,
    maxHotWaterConsumption,
    maxMilkProduction,
    maxTapWaterTemp,
    minEnergyCost,
    minHotWaterConsumption,
    minMilkProduction,
    minTapWaterTemp,
    numberOfDays,
    stepperHotWater,
    stepperTapWater,
    stepperValueEnergyCost,
    stepperValueMilk,
    tempDifference
  } = props

  const COOLING_AND_HEATING = "coolingAndHeating"
  const HEATING = "heating"
  const COOLING = "cooling"

  const [consumptionHeating, setConsumptionHeating] = useState<RowProp[]>([])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCalculatorType((event.target as HTMLInputElement).value).then(() => {
      handleCalculate()
    })
  }
  const {
    state: {
      sliderEnergyCost,
      sliderMilkProduction,
      consumptionOfHotWater,
      tapWaterTemperature,
      calculationType,
      labels
    },
    actions: {
      setSliderEnergyCost,
      setSliderMilkProduction,
      setTotalEnergyNeededCooling,
      setTotalMilkCoolingCost,
      setSliderConsumptionOfHotWater,
      setSliderTapWaterTemperature,
      setCalculatorType,
      setMaxAmountOfWaterProduced,
      setEnergySavingsForHeating,
      resetEnergyCalc
    }
  } = useStore(EnergyCalculator)

  const {
    state: { markets }
  } = useStore(marketStore)
  const selectedMarket = markets.data && markets.data.find((x) => x.isSelected)
  const selectedLanguage = selectedMarket
    ? selectedMarket.selectedLanguage
    : "en"

  const defaultCurrency = selectedMarket
    ? selectedMarket.defaultCurrency
    : undefined

  const QUOTA = sliderMilkProduction / 1000 // Milk production per day / 1000ltr
  const anchorLink =
    labels.yearlyCostCoolingResultTitle &&
    labels.yearlyCostCoolingResultTitle.replace(/\s/g, "_")

  const isXsDown = useMediaQuery(({ breakpoints }) => breakpoints.down("xs"))

  const handleReset = () => {
    resetEnergyCalc()
  }

  const handleCalculate = async () => {
    const totalEnergyNeeded = QUOTA * energyConsumptionCooling * numberOfDays
    const totalMilkCoolingCost = totalEnergyNeeded * sliderEnergyCost
    const MaxAmountOfWaterCalc = sliderMilkProduction * 0.7

    await setTotalEnergyNeededCooling(totalEnergyNeeded)
    await setTotalMilkCoolingCost(totalMilkCoolingCost)
    await setEnergySavingsForHeating(
      consumptionOfHotWater *
        (tempDifference - tapWaterTemperature) *
        kiloWattOfEnergy *
        numberOfDays
    )
    await setMaxAmountOfWaterProduced(MaxAmountOfWaterCalc)
    if (isXsDown) {
      scrollToElement(anchorLink)
    }
  }

  useEffect(
    () => {
      setConsumptionHeating([
        {
          rowName: labels.heatingTableHead1,
          rowId: numberFormat({
            style: "unit",
            value: tempDifference - tapWaterTemperature,
            selectedLanguage,
            currency: defaultCurrency,
            unit: "celsius"
          })
        },
        { rowName: labels.heatingTableHead2, rowId: kiloWattOfEnergy },
        { rowName: labels.heatingTableHead3, rowId: numberOfDays }
      ])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tapWaterTemperature]
  )

  return (
    <Box
      className={classes.calcFormContainer}
      p={{ xs: 2, sm: 5, md: 6 }}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box>
        <Typography className={classes.title} variant="body1">
          {labels.select}
        </Typography>
        <Box pt={2}>
          <FormControl fullWidth>
            <RadioGroup
              className={classes.radioGroup}
              row
              value={calculationType}
              onChange={handleChange}
            >
              <FormControlLabel
                className={classes.firstLable}
                value={COOLING}
                control={<Radio />}
                label={labels.coolingRadioButton}
              />
              <FormControlLabel
                value={HEATING}
                control={<Radio />}
                label={labels.heatingRadioButton}
              />
              <FormControlLabel
                value={COOLING_AND_HEATING}
                control={<Radio />}
                label={labels.coolingAndHeatingRadioButton}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <SliderInput
          title={labels.energyCostKilowatt}
          min={minEnergyCost}
          max={maxEnergyCost}
          step={stepperValueEnergyCost}
          setState={setSliderEnergyCost}
          stateValue={sliderEnergyCost}
        />
        <SliderInput
          title={labels.milkProductionPerDay}
          min={minMilkProduction}
          max={maxMilkProduction}
          step={stepperValueMilk}
          setState={setSliderMilkProduction}
          stateValue={sliderMilkProduction}
        />

        {(calculationType === HEATING ||
          calculationType === COOLING_AND_HEATING) && (
          <Box>
            <SliderInput
              title={labels.consumptionHotWater}
              min={minHotWaterConsumption}
              max={maxHotWaterConsumption}
              step={stepperHotWater}
              setState={setSliderConsumptionOfHotWater}
              stateValue={consumptionOfHotWater}
            />
            <SliderInput
              title={labels.tapWaterTemperature}
              min={minTapWaterTemp}
              max={maxTapWaterTemp}
              step={stepperTapWater}
              setState={setSliderTapWaterTemperature}
              stateValue={tapWaterTemperature}
            />
          </Box>
        )}
      </Box>
      <Box
        pt={5}
        display="flex"
        justifyContent="space-between"
        gridGap={theme.spacing(2)}
      >
        <Button onClick={handleReset} variant="text">
          {labels.resetButton}
        </Button>

        <Button onClick={handleCalculate} variant="secondary">
          {labels.calculateButton}
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  calcFormContainer: {
    border: `1px solid ${themeColors.lightGray}`,
    borderRadius: spacing(1)
  },
  title: {
    fontSize: 18,
    lineHeight: "28px",
    fontWeight: 400
  },
  radioGroup: {
    "& .MuiSvgIcon-root": {
      width: 20,
      height: 20
    },
    "& .MuiRadio-root": {
      color: themeColors.colorBaseGray700
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: themeColors.infoDark
    }
  },
  firstLable: {
    width: "50%"
  }
}))

export default CalculatorForm
