import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import Typography from "common/Typography"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"
import AlertStepper from "./AlertStepper"

type SelectCategoryProps = {
  onCategorySelected: () => void
  onResetStepper: () => void
  confirmChangeOpen: boolean
  onSetConfirmChangeOpen: (isConfirmChangeOpen: boolean) => void
}

const SelectCategory = ({
  onCategorySelected,
  onResetStepper,
  confirmChangeOpen,
  onSetConfirmChangeOpen
}: SelectCategoryProps) => {
  const classes = useStyles()

  const {
    categories,
    selectedCategory,
    setSelectedCategory,
    selectedWire,
    fenceCalculatorLabels
  } = useFenceCalculatorStore()

  const selectCategory = (category: string) => {
    if (Object.keys(selectedWire).length !== 0) {
      onSetConfirmChangeOpen(true)
      return
    }
    setSelectedCategory(category)
    onCategorySelected()
  }

  const reset = () => {
    onSetConfirmChangeOpen(false)
    onResetStepper()
  }

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <Typography className={classes.stepHeading}>
        {fenceCalculatorLabels.fenceTypeDescription}
      </Typography>
      <Box p={2}>
        <Grid container spacing={2}>
          {categories.map(({ category }, idx) => (
            <Grid item xs={6} key={`${category}-${idx}`}>
              <Button
                fullWidth
                className={classNames(classes.button, {
                  [classes.selected]: selectedCategory === category
                })}
                disabled={confirmChangeOpen}
                onClick={() => selectCategory(category)}
              >
                {category === "Permanent" &&
                  fenceCalculatorLabels.fenceTypePermanent}
                {category === "Temporary" &&
                  fenceCalculatorLabels.fenceTypeTemporary}
              </Button>
            </Grid>
          ))}
        </Grid>
        <AlertStepper
          alertMessage={fenceCalculatorLabels.categoryChangeWarning}
          confirmButtonText={fenceCalculatorLabels.categoryChangeConfirm}
          cancelButtonText={fenceCalculatorLabels.cancel}
          onResetStepper={onResetStepper}
          confirmChangeOpen={confirmChangeOpen}
          onSetConfirmChangeOpen={onSetConfirmChangeOpen}
        />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    root: {
      padding: spacing(5, 0),
      [breakpoints.up("sm")]: {
        maxWidth: 654,
        margin: "0 auto"
      }
    },
    stepHeading: {
      margin: spacing(0, 2, 1),
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      color: themeColors.black
    },
    button: {
      height: 124
    },
    selected: {
      background: themeColors.primaryBlue
    }
  })
)

export default SelectCategory
