import { Link } from "react-router-dom"
import { MenuItem } from "@starrepublic/epi/cms"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

import { countObjectDepth } from "utils/hierarchyDepthCounter"

import Button from "common/Button"
import { ConditionalWrapper } from "common/ConditionalWrapper"
import { eventExploreMenuTracker } from "services/StarGA"

type Props = {
  childNodes: Pick<MenuItem, "title" | "url">[]
}

const SubHeaderExploreChildNodes: React.FC<Props> = ({ childNodes }: Props) => {
  const classes = useStyles()

  if (childNodes === undefined || childNodes.length === 0) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {childNodes.map((item, i) => {
          const isActive = item.url === window.location.pathname
          return (
            <ConditionalWrapper
              key={i}
              condition={!!item.url}
              wrapper={(children) => <Link to={item.url}>{children}</Link>}
            >
              <Button
                className={classNames(classes.buttonText, {
                  [classes.activeButton]: isActive
                })}
                variant={isActive ? "primary" : "text"}
                onClick={() =>
                  eventExploreMenuTracker(
                    item.url,
                    countObjectDepth(item),
                    item.title
                  )
                }
              >
                {item.title}
              </Button>
            </ConditionalWrapper>
          )
        })}
      </div>
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  root: {
    width: "100%",
    background: themeColors.white,
    borderBottom: `1px solid ${themeColors.lightGray}`
  },
  wrapper: {
    position: "relative",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  buttonText: {
    fontWeight: 400,
    fontSize: 16,
    height: 56,
    color: themeColors.colorBaseBlue600
  },
  activeButton: {
    backgroundColor: themeColors.colorBaseCyan900,
    color: themeColors.white
  }
}))

export default SubHeaderExploreChildNodes
