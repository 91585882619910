import { Children, useRef } from "react"
import Slider from "react-slick"
import classNames from "classnames"

import Fade from "@material-ui/core/Fade"
import { makeStyles, Theme } from "@material-ui/core/styles"

import ArrowRightShort from "icons/ArrowRightShort"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

type Props = {
  slides: number
  infiniteSlider: boolean
  contentPositionLeftOrRight?: boolean
  arrowsShow?: boolean
  arrowsOnHover?: boolean
  display?: boolean
  fullWithOnMobile?: boolean
  blockSlider?: boolean
  className?: string
  autoScroll?: boolean
  autoScrollSpeed?: number
}

type Count = {
  count: number
}

const Previous: React.FC<{
  className?: string
  navClassName?: string
  onClick?: () => void
  show?: boolean
}> = ({ onClick, className, navClassName, show }) => (
  <Fade in={show}>
    <button onClick={onClick} className={className}>
      <ArrowRightShort className={navClassName} />
    </button>
  </Fade>
)

const Next: React.FC<{
  className?: string
  navClassName?: string
  show?: boolean
  onClick?: () => void
}> = ({ onClick, className, navClassName, show }) => (
  <Fade in={show}>
    <button onClick={onClick} className={className}>
      <ArrowRightShort className={navClassName} />
    </button>
  </Fade>
)

const ContentCarousel: React.FC<Props> = ({
  slides,
  infiniteSlider,
  children,
  contentPositionLeftOrRight,
  arrowsShow = true,
  arrowsOnHover = true,
  fullWithOnMobile = true,
  blockSlider = false,
  autoScroll,
  autoScrollSpeed,
  className
}) => {
  const countElements = Children.count(children)
  const props = {
    count: countElements
  }

  const classes = useStyles(props)
  const containerRef = useRef<HTMLDivElement>(null)

  const checkWidthOnCarousel =
    containerRef.current && containerRef.current.clientWidth

  const settings = (
    prevArrowClassName: string,
    nextArrowClassName?: string
  ) => ({
    swipe: false,
    arrows: autoScroll ? false : arrowsShow ? true : false,
    speed: autoScrollSpeed ? autoScrollSpeed : 500,
    dots: true,
    infinite: true,
    slidesToShow: slides,
    slidesToScroll: slides,
    autoplay: autoScroll,
    prevArrow: (
      <Previous navClassName={prevArrowClassName} show={arrowsOnHover} />
    ),
    nextArrow: <Next navClassName={nextArrowClassName} show={arrowsOnHover} />,
    responsive: [
      {
        breakpoint: contentPositionLeftOrRight ? 1200 : 1080,
        settings: {
          slidesToShow:
            slides === 1 || slides === 2
              ? slides
              : contentPositionLeftOrRight
                ? 2
                : 3,
          slidesToScroll:
            slides === 1 || slides === 2
              ? slides
              : contentPositionLeftOrRight
                ? 2
                : 3,
          dots: true,
          swipe: false
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: slides === 1 || slides === 2 ? slides : 3,
          slidesToScroll: slides === 1 || slides === 2 ? slides : 3,
          swipe: true
        }
      },
      {
        breakpoint: 834,
        settings: {
          arrows: false,
          slidesToShow: slides === 1 ? 1 : 2,
          slidesToScroll: slides === 1 ? 1 : 2,
          swipe: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToScroll: blockSlider ? 1 : 2,
          slidesToShow: blockSlider ? 1 : 2,
          swipe: true
        }
      },
      {
        breakpoint: 425,
        settings: {
          arrows: false,
          centerMode: fullWithOnMobile && !blockSlider ? true : false,
          slidesToScroll: 1,
          slidesToShow: 1,
          swipe: true
        }
      }
    ]
  })

  return (
    <div
      ref={containerRef}
      className={classNames(classes.sliderContainer, className, {
        [classes.slickList]: fullWithOnMobile && !blockSlider,
        [classes.isImageSlider]: !fullWithOnMobile,
        [classes.slickNextInside]: !fullWithOnMobile,
        [classes.slickPrevInside]: !fullWithOnMobile
      })}
    >
      {children && checkWidthOnCarousel && checkWidthOnCarousel > 0 ? (
        <Slider {...settings(classes.prevNavButton)} infinite={infiniteSlider}>
          {children}
        </Slider>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles<Theme & Count>(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    sliderContainer: {
      background: "transparent",
      "& .slick-active > button": {
        backgroundColor: themeColors.primaryCyan
      },
      "& .slick-dots li button:before": {
        content: "none"
      },
      "& .slick-dots li button": {
        borderRadius: "50%",
        border: `2px solid ${themeColors.offWhite}`,
        width: 12,
        height: 12,
        padding: 0
      },
      "& .slick-dots": {
        zIndex: 1,
        position: "initial",
        marginTop: spacing(2),
        display: "flex !important",
        alignItems: "center",
        justifyContent: "center",
        [breakpoints.down("sm")]: {
          marginTop: spacing(2),
          marginBottom: spacing(2)
        }
      },
      "& .slick-dots li": {
        width: 12,
        height: 12
      },
      "& .slick-dots li:hover": {
        // backgroundColor: themeColors.mediumGray
      },
      "& .slick-next:before": {
        content: "none"
      },
      "& .slick-prev:before": {
        content: "none"
      },
      "& .slick-next": {
        right: -28,
        width: 48,
        height: 48,
        zIndex: 2,
        background: themeColors.white,
        borderRadius: "50%",
        color: themeColors.primaryCyan,
        filter:
          "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12))"
      },
      "& .slick-prev": {
        left: -28,
        width: 48,
        height: 48,
        zIndex: 2,
        background: themeColors.white,
        borderRadius: "50%",
        color: themeColors.primaryCyan,
        filter:
          "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12))"
      },
      [breakpoints.down("sm")]: {
        margin: 0
      },
      "& .slick-disabled": {
        opacity: "0.7 !important"
      }
    },
    slickList: {
      "& .slick-list": {
        lineHeight: 0,
        [breakpoints.down("xs")]: {
          padding: `0px ${spacing(4)}px 0px  ${spacing(3)}px !important`,
          position: "relative",
          width: "100vw",
          marginLeft: "calc(50% - 50vw)"
        }
      }
    },
    slickNextInside: {
      "& .slick-next": {
        right: 16
      }
    },
    slickPrevInside: {
      "& .slick-prev": {
        left: 16
      }
    },
    prevNavButton: {
      transform: "rotateY(180deg)"
    },
    nextNavButton: { right: 16 },
    isImageSlider: {
      "& .slick-dots": {
        position: "absolute",
        margin: 0,
        bottom: 16
      }
    }
  })
)

export default ContentCarousel
