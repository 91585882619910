import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { blockDisplay } from "utils/blockDisplay"

import ImagePosition from "./ImagePosition"
import ItemCarousel from "./ItemCarousel"
import TitleAndTextPosition from "./TitleAndTextPosition"
import { ImageProductCarouselContent } from "./types"

type Props = BlockProps<ImageProductCarouselContent>

const BLOCK_MAX_WIDTH = 1680
const titleType = "titleType"

const positions = {
  top: "Top",
  bottom: "Bottom",
  right: "Right",
  left: "Left",
  center: "Center"
}

const ImageProductCarouselBlock: React.FC<Props> = ({
  content,
  propsInfo,
  blockInfo
}) => {
  const { backgroundColor, maxNumberOfProducts, contentPosition } = content
  const _display = blockDisplay(blockInfo)
  const classes = useStyles()
  const isSmDown = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"))

  const positionTop =
    contentPosition && contentPosition.text === positions.top ? true : false
  const positionLeft =
    contentPosition && contentPosition.text === positions.left ? true : false

  const productGrid = (numberOfProducts: number, type?: string) => {
    if (type === "titleType") {
      switch (numberOfProducts) {
        case 1:
          return 7
        case 2:
          return 5
        default:
          return 4
      }
    }
    switch (numberOfProducts) {
      case 1:
        return 5
      case 2:
        return 7
      default:
        return 8
    }
  }

  return (
    <div
      className={classNames({
        [classes.fullWidthContainer]: _display.full
      })}
    >
      <div
        style={{
          backgroundColor: `${backgroundColor}`
        }}
        className={classNames({
          [classes.containerPosition]: positionTop,
          [classes.containerPosition]: isSmDown
        })}
      >
        <Box
          display="flex"
          flexDirection={positionLeft ? "row" : "row-reverse"}
          className={classNames(classes.root, {
            [classes.contentPositionTop]: positionTop,
            [classes.contentOnProduct]: maxNumberOfProducts === 1
          })}
        >
          <ImagePosition content={content} propsInfo={propsInfo} />
          <Grid
            direction={
              isSmDown ? "column-reverse" : positionLeft ? "row" : "row-reverse"
            }
            alignItems="flex-start"
            justifyContent="center"
            container
          >
            {!positionTop && (
              <Grid
                item
                className={classes.index}
                container
                justifyContent={
                  isSmDown
                    ? "center"
                    : positionLeft || maxNumberOfProducts === 1
                      ? "flex-start"
                      : "flex-end"
                }
                xs={12}
                sm={12}
                md={productGrid(maxNumberOfProducts, titleType)}
              >
                <TitleAndTextPosition content={content} propsInfo={propsInfo} />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={positionTop ? 12 : productGrid(maxNumberOfProducts)}
              className={classes.carouselContainer}
            >
              <ItemCarousel content={content} propsInfo={propsInfo} />
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  )
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    alignItems: "center",
    position: "relative",
    height: 576,
    maxWidth: BLOCK_MAX_WIDTH,
    paddingBottom: 0,
    overflow: "hidden",
    [breakpoints.down("sm")]: {
      marginRight: 0,
      marginLeft: 0,
      alignItems: "flex-start",
      height: "100%",
      padding: spacing(4, 0, 3)
    },

    WebkitTapHighlightColor: "transparent",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat"
  },
  fullWidthContainer: {
    margin: spacing(1.5, 0)
  },
  containerPosition: {
    position: "relative",
    width: "100vw",
    marginLeft: "calc(50% - 50vw)"
  },
  contentPositionTop: {
    marginTop: 0,
    maxWidth: "none",
    height: "100%"
  },
  contentOnProduct: {
    maxWidth: `calc(0.84 * ${BLOCK_MAX_WIDTH}px)`
  },
  index: {
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    [breakpoints.down("sm")]: {
      padding: spacing(0, 3)
    }
  },
  carouselContainer: {
    zIndex: 2,
    [breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}))

export default ImageProductCarouselBlock
