import Box from "@material-ui/core/Box"
import Input from "@material-ui/core/Input"
import Slider from "@material-ui/core/Slider"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"

type SliderInptutProps = {
  min: number
  max: number
  title: string
  step: number
  setState: (value: number | string) => void
  stateValue: number
}

const SliderInput: React.FC<SliderInptutProps> = (props) => {
  const { min, max, title, step, stateValue, setState } = props
  const classes = useStyles()

  const handleSliderChange = (event: any, newValue: number) => {
    setState(newValue)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value === "" ? "" : Number(event.target.value))
  }

  const handleBlur = () => {
    if (stateValue < 0) {
      setState(0)
    } else if (stateValue > max) {
      setState(max)
    }
  }

  return (
    <Box pt={3}>
      <Typography className={classes.title} variant="body1">
        {title}
      </Typography>
      <Box
        pt={2}
        display="flex"
        alignItems="center"
        className={classes.sliderContainer}
      >
        <Slider
          value={typeof stateValue === "number" ? stateValue : min}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
          step={step}
          max={max}
          min={min}
        />

        <Input
          className={classes.input}
          value={stateValue}
          margin="dense"
          onChange={handleInputChange}
          onBlur={handleBlur}
          disableUnderline
          inputProps={{
            step,
            min,
            max,
            type: "number",
            "aria-labelledby": "input-slider"
          }}
        />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  input: {
    width: 100,
    height: 40,
    border: `1px solid ${themeColors.primaryCyan}`,
    backgroundColor: themeColors.primaryLightCyan,
    "& > input": {
      textAlign: "center"
    }
  },
  title: {
    fontSize: 18,
    lineHeight: "28px",
    fontWeight: 400,
    color: themeColors.black
  },
  sliderContainer: {
    "& .MuiSlider-root": {
      marginRight: spacing(1)
    }
  }
}))

export default SliderInput
