import { Fragment, useEffect, useRef } from "react"

import Box, { BoxProps } from "@material-ui/core/Box"

import ProductBadge, { ProductBadgeProps } from "common/Product/ProductBadge"
import { ImageBlock, VideoType } from "types/content/media"
import CarouselItem from "./CarouselItem"
import CarouselProvider from "./CarouselProvider"
import CarouselSlider from "./CarouselSlider"
import ThumbnailItem from "./CarouselThumbnailItem"
import Thumbnails from "./CarouselThumbnails"

export type CarouselProps = BoxProps & {
  /**
   * Component can be replaced with a component of your choice.
   */
  CarouselProviderComponent?: typeof CarouselProvider
  /**
   * Component can be replaced with a component of your choice.
   */
  CarouselProviderProps?: typeof CarouselProvider.defaultProps

  CarouselSliderComponent?: typeof CarouselSlider
  /**
   * Component can be replaced with a component of your choice.
   */
  CarouselSliderProps?: typeof CarouselSlider.defaultProps

  CarouselItemComponent?: typeof CarouselItem
  /**
   * Component can be replaced with a component of your choice.
   */
  CarouselItemProps?: typeof CarouselItem.defaultProps

  ThumbnailsComponent?: typeof Thumbnails
  /**
   * Component can be replaced with a component of your choice.
   */
  ThumbnailProps?: typeof Thumbnails.defaultProps

  ThumbnailComponent?: typeof ThumbnailItem
  /**
   * Position of Thumbnails, defaults to left
   */
  ThumbnailItemProps?: typeof ThumbnailItem.defaultProps

  thumbnailPosition?: "left" | "bottom"
  media: (ImageBlock | VideoType)[]
  video: {
    key: string
    value: string
  }
  badgeMessage?: string | null
  badgeDiscount?: ProductBadgeProps
  /**
   * Image aspect ratio width
   */
  naturalSlideWidth?: number
  /**
   * Image aspect ratio height
   */
  naturalSlideHeight?: number

  onItemClick?: (index: number) => void
}

export const Carousel: React.FC<CarouselProps> = ({
  media,
  video,
  badgeMessage,
  badgeDiscount,
  thumbnailPosition = "left",
  CarouselProviderComponent = CarouselProvider,
  CarouselSliderComponent = CarouselSlider,
  CarouselItemComponent = CarouselItem,
  ThumbnailsComponent = Thumbnails,
  ThumbnailComponent = ThumbnailItem,
  naturalSlideWidth = 4,
  naturalSlideHeight = 3,
  onItemClick,
  children,
  ...boxProps
}) => {
  const thumbnailsRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const mediaList = () => {
    if (video.value && video.key) {
      const videoItem = { ...video, _type: "VideoFile", _url: "", id: "" }
      const mediaList = [...media]
      mediaList.splice(1, 0, videoItem)
      return mediaList
    }
    return media
  }

  useEffect(
    () => {
      mediaList()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [media]
  )

  return (
    <Box
      display="inline-block"
      width="100%"
      height="100%"
      flexGrow="0"
      {...boxProps}
      {...({ ref: containerRef } as any)}
    >
      <CarouselProviderComponent
        key={mediaList().reduce((acc, m) => `${acc}_${m.id}`, "slider_")}
        naturalSlideWidth={naturalSlideWidth}
        naturalSlideHeight={naturalSlideHeight}
        thumbnailPosition={thumbnailPosition}
        totalSlides={mediaList().length}
      >
        <Box position="absolute" width="100%">
          {badgeMessage && (
            <Box
              position="absolute"
              top="10px"
              left={{ xs: 0, sm: 36, md: mediaList().length > 1 ? 136 : 36 }}
              zIndex={1}
            >
              <ProductBadge label={badgeMessage} />
            </Box>
          )}
          {badgeDiscount && (
            <Box
              position="absolute"
              top="10px"
              right={{ xs: 0, sm: 36, md: mediaList().length > 1 ? 0 : 36 }}
              zIndex={1}
            >
              <ProductBadge {...badgeDiscount} isLarge />
            </Box>
          )}
        </Box>
        <CarouselSliderComponent thumbnailPosition={thumbnailPosition}>
          {children ||
            mediaList().map((mediaItem, index: number) => (
              <Fragment key={`slider_item_${index}`}>
                <CarouselItemComponent
                  onClick={onItemClick}
                  index={index}
                  mediaItem={mediaItem}
                  naturalSlideHeight={naturalSlideHeight}
                />
              </Fragment>
            ))}
        </CarouselSliderComponent>
        {mediaList().length > 1 && (
          <ThumbnailsComponent
            ref={thumbnailsRef}
            thumbnailPosition={thumbnailPosition}
          >
            {mediaList().map((mediaItem, index: number) => (
              <ThumbnailComponent
                key={`slider_thumb_${index}`}
                mediaItem={mediaItem}
                index={index}
                naturalSlideHeight={naturalSlideHeight}
              />
            ))}
          </ThumbnailsComponent>
        )}
      </CarouselProviderComponent>
    </Box>
  )
}

export default Carousel
