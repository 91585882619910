import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { MenuItem as CmsMenuItem } from "@starrepublic/epi/cms"
import useStore from "global-hook-store"

import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { makeStyles } from "@material-ui/core/styles"

import { calculateArrayDepth } from "utils/hierarchyDepthCounter"

import LinkText from "common/LinkText"
import Typography from "common/Typography"
import { eventHamburgerMenuTracker } from "services/StarGA"
import originalPartsStore from "store/OriginalPartsStore"
import siteSettingStore from "store/SiteSettingStore"
import MenuListItem from "./MenuListItem"

type Props = {
  items?: CmsMenuItem[]
  goToSubMenu: (item: CmsMenuItem) => void
  closeDrawer: () => void
  title?: string
  url?: string
  menuType?: string
  id?: string
}

const MenuList: React.FC<Props> = ({
  items,
  goToSubMenu,
  closeDrawer,
  title,
  url,
  menuType,
  id
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    actions: { open: openOriginalParts }
  } = useStore(originalPartsStore)

  const {
    state: { enableOriginalParts }
  } = useStore(siteSettingStore)

  return items && items.length ? (
    <>
      {title && url && (
        <div>
          <ListItem component="div" className={classes.subheader}>
            <Link
              to={url}
              onClick={() => {
                closeDrawer()
                eventHamburgerMenuTracker(
                  url,
                  calculateArrayDepth(items),
                  title
                )
              }}
              className={classes.subheaderLink}
            >
              <Typography
                component="span"
                variant="link2"
                className={classes.header}
              >
                {title}
              </Typography>
              <Typography className={classes.viewAll} variant="link3">
                {t("product.view_all")}
              </Typography>
            </Link>
          </ListItem>
          <Divider className={classes.divider} />
        </div>
      )}
      <List component="nav" className={classes.navList}>
        {items.map((item) => (
          <MenuListItem
            key={item._id}
            item={item}
            goToSubMenu={goToSubMenu}
            closeDrawer={closeDrawer}
            noBoldText={!!title}
          />
        ))}
        {enableOriginalParts && menuType === "ProductMenu" && (
          <div>
            <div className={classes.extender}>
              <LinkText
                onClick={(e) => {
                  e.preventDefault()
                  closeDrawer()
                  openOriginalParts()
                }}
                link={"/"}
                linkText={t("common.looking_for_original_parts")}
                variant="link1"
                className={classes.sparePartsLink}
              />
            </div>
            <Divider className={classes.divider} />
          </div>
        )}
      </List>
    </>
  ) : null
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  subheader: {
    fontWeight: 700,
    fontSize: 16,
    padding: 0,
    marginBottom: 0,
    marginTop: 0
  },
  subheaderLink: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    alignItems: "center",
    padding: `${spacing(2)}px ${spacing(3)}px ${spacing(1)}px`
  },
  viewAll: {
    color: themeColors.white,
    paddingBottom: spacing(1)
  },
  header: {
    fontSize: 20,
    color: themeColors.white,
    flexGrow: 1,
    paddingBottom: spacing(1)
  },
  navList: {
    flexGrow: 1,
    padding: 0
  },
  divider: {
    margin: 0,
    backgroundColor: themeColors.transparent
  },
  extender: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    height: 56,
    paddingLeft: spacing(4)
  },
  sparePartsLink: {
    "& span": {
      color: themeColors.white,
      fontSize: "1rem",
      "&:hover": {
        color: themeColors.white
      }
    }
  }
}))

export default MenuList
