import { memo, useMemo } from "react"
import useStore from "global-hook-store"

import TreeViewMenu from "common/TreeViewMenu"
import SiteSettingStore from "store/SiteSettingStore"

const ProductMenuComponent = () => {
  const {
    state: { productMenu }
  } = useStore(SiteSettingStore)

  const productMenuMemo = useMemo(() => {
    return productMenu
  }, [productMenu])

  return <TreeViewMenu items={productMenuMemo} />
}

export const ProductMenu = memo(ProductMenuComponent)
