import { useTranslation } from "react-i18next"
import { Formik, FormikActions } from "formik"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import vatRegistrationUtil from "utils/vatRegistrationUtil"

import Typography from "common/Typography"
import MyAccountIcon from "icons/MyAccountIcon"
import accountStore from "store/AccountStore"
import authStore from "store/AuthStore"
import AccountInformationForm from "./AccountInformationForm"
import validationSchema from "./validationSchema"
type MissingAccountInformationModel = {
  vatNumber: string
}

const initialValues: MissingAccountInformationModel = {
  vatNumber: ""
}

const MissingAccountInformation: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { user },
    actions: { getUser }
  } = useStore(authStore)

  const {
    state: { accountDetailsMissing, updatedCustomer },
    actions: { updateCustomer }
  } = useStore(accountStore)

  const handleSubmit = (
    values: MissingAccountInformationModel,
    { setSubmitting }: FormikActions<MissingAccountInformationModel>
  ) => {
    const countryCode =
      (user && user.data && user.data.billingAddress.countryCode) || "EN"
    const vatAdornment = vatRegistrationUtil(countryCode)
    updateCustomer(
      `${vatAdornment.start}${values.vatNumber}${vatAdornment.end}`
    ).then(() => {
      if (updatedCustomer.error) {
        setSubmitting(false)
      } else {
        getUser()
      }
    })
  }

  if (!accountDetailsMissing) {
    return null
  }

  const loggedInUserName = user.data
    ? `${user.data.firstName} ${user.data.lastName}`
    : ""

  const headerText = t(
    "account.additional_information_required_header"
  ).replace("{name}", loggedInUserName)

  return (
    <Grid item xs={12}>
      <div className={classes.wrapper}>
        <div>
          <Typography variant="headline4" className={classes.title}>
            <MyAccountIcon className={classes.accountIcon} />
            {headerText}
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            {t("account.additional_information_required_message")}
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur
            component={AccountInformationForm}
            validationSchema={validationSchema(
              t,
              (user.data && user.data.billingAddress.countryCode) || ""
            )}
          />
        </div>
      </div>
    </Grid>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  wrapper: {
    border: `2px solid ${themeColors.primaryBlue}`,
    padding: spacing(3),
    marginBottom: spacing(3)
  },
  title: {
    display: "flex",
    marginBottom: spacing(1)
  },
  paragraph: {
    marginBottom: spacing(3)
  },
  accountIcon: {
    marginRight: spacing(1)
  }
}))
export default MissingAccountInformation
