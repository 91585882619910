import request from "@starrepublic/epi/api/request"

import { ShoppingItemMail, ShoppingItemPdf } from "store/ShoppingListStore"
const shoppingList = {
  savePdf: (lineItems: ShoppingItemPdf[]) =>
    request
      .post(
        `/shoppinglist/pdf`,
        {
          lineItems
        },
        {
          responseType: "arraybuffer"
        }
      )
      .then((response) => {
        const blob = new Blob([response], {
          type: "application/pdf",
          encoding: "UTF-8"
        } as any)

        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = "DelavalShoppingList.pdf"
        link.click()
      }),
  sendShoppingList: (mailItems: ShoppingItemMail) =>
    request.post(`/shoppinglist/send`, {
      email: mailItems.email,
      lineItems: mailItems.lineItems
    })
}

export default shoppingList
