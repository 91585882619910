import { memo, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PageInfo } from "@starrepublic/epi/cms"
import useStore from "global-hook-store"
import debounce from "lodash/debounce"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import NativeSelect from "@material-ui/core/NativeSelect"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import Product from "models/Products"
import { WebNodeContent } from "pages/WebNode/types"
import ProductListingStore from "store/ProductListingStore"
import OnLoadSpinner from "./OnLoadSpinner"
import ProductRow from "./ProductRow"
import ProductRowMobile from "./ProductRowMobile"
import QuickFilter from "./QuickFilter"
import SubpageDropDown from "./SubpageDropDown"

type Props = {
  products: Product[]
  pageInfo: PageInfo
  content: WebNodeContent
}

const ProductTableDesktop = ({ products, pageInfo, content }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [sorting, setSorting] = useState("latest")

  const {
    state: { productList, searchedProductList, sort },
    actions: {
      setSortProperty,
      loadProductList,
      resetProductList,
      addProducts,
      resetFilteredProducts,
      quickFilterSearch,
      addFilteredProducts
    }
  } = useStore(ProductListingStore)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeSorting = useCallback(
    debounce((sortProperty: string) => {
      setSortProperty(sortProperty).then((state) => {
        if (state.searchedProductList.data !== null) {
          resetFilteredProducts().then(() => {
            quickFilterSearch().then(() => {
              addFilteredProducts()
            })
          })
        } else {
          resetProductList().then(() => {
            loadProductList().then(() => {
              addProducts()
            })
          })
        }
      })
    }, 750),
    []
  )

  useEffect(
    () => {
      if (sorting === sort) {
        changeSorting.cancel()
      } else {
        changeSorting(sorting)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sorting]
  )

  useEffect(
    () => {
      if (sorting !== sort) {
        setSorting(sort)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort]
  )

  const handleSortTranslation = (sortId: string) => {
    switch (sortId) {
      case "nameAsc":
        //Name A-Z
        return t("sort.name_ascending")

      case "nameDesc":
        //Name Z-A
        return t("sort.name_descending")

      case "articleAsc":
        //Articlenumber ascending
        return t("sort.article_number_ascending")

      case "articleDesc":
        //Articlenumber descending
        return t("sort.article_number_descending")

      case "priceAsc":
        //Price ascending
        return t("sort.price_ascending")

      case "priceDesc":
        //Price descending
        return t("sort.price_descending")

      case "latest":
        return "Latest first"
      default:
        "lastest"
    }
  }

  return (
    <>
      <Wrapper>
        <Grid container>
          <Box maxWidth={800} flexDirection="column" mb={3} display="flex">
            <Box pb={2}>
              <Typography variant="headline3">{pageInfo.title}</Typography>
            </Box>
            <Typography variant="preamble">{content.text}</Typography>
          </Box>
          <Hidden mdUp>
            <Grid item xs={12}>
              <SubpageDropDown subMenuNodes={content.children} />
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <Grid container className={classes.header}>
              <Grid item className={classes.quickFilterWrapper}>
                <QuickFilter />
              </Grid>
              <Grid item className={classes.productsTotalWrapper}>
                <Typography>
                  {searchedProductList.loading || productList.loading ? (
                    <ButtonLoaderIcon />
                  ) : searchedProductList.data ? (
                    searchedProductList.data[0].totalItems
                  ) : productList.data ? (
                    productList.data.totalItems
                  ) : (
                    "0"
                  )}
                  &nbsp;
                  {t("search.product_title")}
                </Typography>
              </Grid>

              <Grid container alignItems="center" item xs={12} sm={12} md={3}>
                <NativeSelect
                  className={classes.sortSelector}
                  value={sorting}
                  onChange={(e) => setSorting(e.target.value)}
                  input={<OutlinedInput labelWidth={0} />}
                  inputProps={{
                    name: "Sort by",
                    id: "sort-by-placeholder"
                  }}
                >
                  {productList.data &&
                    productList.data.sortSettings
                      .filter(
                        (sort) =>
                          sort.id !== "priceAsc" && sort.id !== "priceDesc"
                      )
                      .map((sort) => {
                        return (
                          <option key={sort.id} value={sort.id}>
                            {handleSortTranslation(sort.id)}
                          </option>
                        )
                      })}
                </NativeSelect>
              </Grid>
            </Grid>

            <Table size="small">
              <TableHead className={classes.tableHeadDetails}>
                <TableRow>
                  <TableCell className={classes.minWidth}>
                    <Grid wrap="nowrap" container>
                      <Typography variant="body2">
                        {t("cart.product_table_header")}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell />
                  <Hidden smDown>
                    <TableCell>
                      <Typography variant="body2">
                        {t("product.availability")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Grid container>
                        <Typography variant="body2">
                          {t("product.price")}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell />
                  </Hidden>
                </TableRow>
              </TableHead>
              <TableBody>
                <Hidden smDown>
                  {products &&
                    products.map((product) => {
                      return <ProductRow product={product} key={product._id} />
                    })}
                </Hidden>
                <Hidden mdUp>
                  {products &&
                    products.map((product) => {
                      return (
                        <ProductRowMobile product={product} key={product._id} />
                      )
                    })}
                </Hidden>
              </TableBody>
            </Table>
            {(productList.loading || searchedProductList.loading) && (
              <OnLoadSpinner />
            )}
          </Grid>
        </Grid>
      </Wrapper>
    </>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, spacing, breakpoints }) => ({
    header: {
      paddingRight: spacing(1),
      backgroundColor: themeColors.offWhite,
      [breakpoints.down("sm")]: {
        backgroundColor: themeColors.white,
        paddingRight: 0
      }
    },
    tableHeadDetails: {
      "& p": {
        fontWeight: "bold"
      }
    },
    minWidth: {
      width: "1px",
      paddingLeft: 0
    },
    productsTotalWrapper: {
      backgroundColor: themeColors.offWhite,
      display: "flex",
      alignItems: "center",
      alignContent: "center",
      padding: spacing(2),
      [breakpoints.down("xs")]: {
        backgroundColor: themeColors.white
      }
    },
    quickFilterWrapper: {
      backgroundColor: themeColors.offWhite,
      flexGrow: 1,
      [breakpoints.down("xs")]: {
        borderTop: `2px solid ${themeColors.white}`
      }
    },
    sortSelector: {
      background: themeColors.white,
      width: "100%",
      [breakpoints.down("sm")]: {
        marginTop: spacing(2)
      }
    }
  })
)

export default memo(ProductTableDesktop)
