import classNames from "classnames"

import Box from "@material-ui/core/Box"
import { makeStyles, Theme } from "@material-ui/core/styles"

import { useMediaQueries } from "utils/CustomeHooks/useMediaQueries"

import { ImageProductCarouselContent } from "blocks/ImageProductCarouselBlock/types"
import ContentCarousel from "common/ContentCarousel"
import ProductCard from "common/Product/ProductCard"

type Props = {
  carouselWidth: number
  productsShowingInCarousel: number
  productWidthSmall: number
  productWidthBig: number
} & ImageProductCarouselContent

type StyleProps = {
  slideMaxWidth: number
}

const BundleCarousel: React.FC<Props> = (props) => {
  const {
    items,
    header,
    carouselWidth,
    productsShowingInCarousel,
    productWidthSmall,
    productWidthBig
  } = props

  const { isSmUp } = useMediaQueries()

  const classes = useStyles({
    slideMaxWidth: items.length > 2 ? productWidthSmall : productWidthBig
  })

  return (
    <Box
      style={{
        maxWidth: carouselWidth
      }}
      className={classNames(classes.sliderWrapper, {
        [classes.sliderWrapperOne]: productsShowingInCarousel === 1
      })}
    >
      <ContentCarousel
        arrowsShow={items.length > 2}
        infiniteSlider={isSmUp}
        slides={productsShowingInCarousel}
      >
        {items.map((product, index) => {
          return (
            <div key={index} className={classes.content}>
              <ProductCard
                bundleCardSize
                product={product}
                gaData={{ index, belongToList: header }}
              />
            </div>
          )
        })}
      </ContentCarousel>
    </Box>
  )
}

const useStyles = makeStyles<Theme, StyleProps>(({ breakpoints, common }) => ({
  sliderWrapper: {
    width: "100%",
    maxWidth: ({ slideMaxWidth }) => slideMaxWidth,
    "& .slick-list": {
      borderRight: `1px solid ${common.themeColors.whiteGray}`,
      borderLeft: `1px solid ${common.themeColors.whiteGray}`,
      [breakpoints.down("sm")]: {
        borderRight: 0,
        borderLeft: 0
      }
    },
    [breakpoints.down("sm")]: {
      margin: "0 auto"
    },
    [breakpoints.down("xs")]: {
      padding: 0
    }
  },
  sliderWrapperOne: {
    width: "100%",
    margin: "0 auto"
  },
  content: {
    borderRight: `1px solid ${common.themeColors.whiteGray}`,
    borderBottom: `1px solid ${common.themeColors.whiteGray}`,
    borderTop: `1px solid ${common.themeColors.whiteGray}`
  }
}))

export default BundleCarousel
