import { DynamicBlock, SimpleLink } from "../cms"
import { CatalogMeta } from "../commerce/models/catalog"

import { ProductListItem } from "./products"
import request from "./request"

export type Option = {
  displayName: string
  value: string
}

export type FilterGroup = {
  displayName: string
  value: string
  options: Option[]
}

export type ProductContentItem<T> = ProductListItem<T> | DynamicBlock

export type CatalogContent<T extends any> = T & {
  _meta: CatalogMeta
  _parents: SimpleLink[]
  _route: string
  children: any[]
  title: string
  filterGroups?: FilterGroup[]
  sortOptions?: Option[]
}

const catalogs = {
  getAll: <T extends any>() => request.get<CatalogContent<T>[]>("/catalogs"),
  getByRoute: <T extends any>(route: string) =>
    request.get<CatalogContent<T>>(`/catalogs/${route}`)
}

export default catalogs
