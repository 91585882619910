import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const InfoOutlinedIcon: React.FC<Props> = (props) => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20ZM12 16v-4M12 8h0"
    />
  </SvgIcon>
)

export default InfoOutlinedIcon
