import request from "./request"

type Order = {}

const orders = {
  getAll: () => request.get<Order[]>("/orders"),
  getByOrderGroupId: (orderGroupId: string) =>
    request.get<Order>(`/orders/${orderGroupId}`)
}

export default orders
