import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"

import Carousel from "common/Carousel"
import { ProductBadgeProps } from "common/Product/ProductBadge"
import ResponsiveImage from "common/ResponsiveImage"
import Spinner from "common/Spinner"
import Wrapper from "common/Wrapper"
import ProductStore from "store/ProductStore"
import SiteSettingsStore from "store/SiteSettingStore"
import ProductInfo from "./ProductInfo"
import RelatedProducts from "./RelatedProducts"
import { WebProductContent } from "./types"

type Props = {
  content: WebProductContent
  pageId: number
  pageRoute: string
  compactLayout?: boolean
}

type StyleProps = {
  _extraSpacing: boolean
}

const ProductDetails: React.FC<Props> = ({
  content,
  pageId,
  pageRoute,
  compactLayout
}) => {
  const {
    state: { selectedVariant, isFetching },
    actions: { fetchVariations, resetProduct }
  } = useStore(ProductStore)

  const {
    state: { enableDiscountBadges, enableEcommerce, enableWishlist }
  } = useStore(SiteSettingsStore)

  const { t } = useTranslation()
  const classes = useStyles({
    _extraSpacing: enableEcommerce || enableWishlist
  })()

  useEffect(() => {
    fetchVariations(pageId)
    return () => {
      resetProduct()
    }
  }, [fetchVariations, pageId, resetProduct])

  const variantImages =
    selectedVariant.imageAssets && selectedVariant.imageAssets.length > 0
      ? selectedVariant.imageAssets
      : content.imageAssets

  const variantDiscount =
    selectedVariant &&
    selectedVariant._displayPrice &&
    selectedVariant._discountedPrice &&
    selectedVariant._displayPrice.amount !==
      selectedVariant._discountedPrice.amount

  const badgeDiscount: ProductBadgeProps | undefined = useMemo(() => {
    if (
      enableDiscountBadges &&
      content.displayLowestPrice &&
      content.displayLowestDiscountedPrice &&
      content.displayLowestDiscountedPrice.displayPrice !==
        content.displayLowestPrice.displayPrice
    ) {
      const originalPrice = content.displayLowestPrice.amount
      const discountedPrice = content.displayLowestDiscountedPrice.amount
      return {
        label: t("product.discount_label"),
        discount:
          Math.round(
            (100 * (originalPrice - discountedPrice)) / originalPrice
          ) + "%",
        color: "alt"
      }
    }

    return undefined
  }, [
    enableDiscountBadges,
    content.displayLowestPrice,
    content.displayLowestDiscountedPrice,
    t
  ])

  return isFetching ? (
    <div className={classes.root}>
      <div className={classes.spinnerCard}>
        <Spinner />
      </div>
    </div>
  ) : (
    <>
      {content.heroImage && (
        <Hidden smDown>
          <ResponsiveImage
            image={content.heroImage}
            lazy
            color={content.heroImage.dominantColor}
            className={classes.heroImage}
          />
        </Hidden>
      )}
      <Wrapper
        className={classNames(classes.root, {
          [classes.withHeroImage]: !!content.heroImage,
          [classes.compactRoot]: compactLayout
        })}
      >
        <Grid item xs={12} className={classes.productGrid}>
          <Card className={classes.card}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              py={1.5}
              px={{ xs: 1.5 }}
            >
              <Grid item xs={12} md={compactLayout ? 6 : 7}>
                <Carousel
                  pr={{ xs: 0, md: 6 }}
                  pb={{ xs: 0, md: 1.5 }}
                  media={variantImages || []}
                  video={content.productVideo}
                  badgeMessage={content.badgeMessage}
                  badgeDiscount={variantDiscount ? badgeDiscount : undefined}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={compactLayout ? 6 : 5}
                className={classes.productInfoGrid}
              >
                <ProductInfo content={content} route={pageRoute} />
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Wrapper>
      {content.recommendations && content.recommendations.length > 0 && (
        <Wrapper className={classes.relatedProductsWrapper}>
          <RelatedProducts products={content.recommendations} />
        </Wrapper>
      )}
    </>
  )
}

const useStyles = ({ _extraSpacing }: StyleProps) =>
  makeStyles(
    ({ breakpoints, spacing, common: { gridSpacing, themeColors } }) => ({
      root: {
        display: "flex",
        marginTop: gridSpacing,
        marginBottom: _extraSpacing ? gridSpacing * 3 : gridSpacing,
        [breakpoints.down("sm")]: {
          marginBottom: gridSpacing,
          padding: 0
        }
      },
      compactRoot: {
        marginBottom: gridSpacing,
        marginTop: 0
      },
      withHeroImage: {
        [breakpoints.up("md")]: {
          marginTop: spacing(1) * 27
        }
      },
      wrapper: {
        paddingTop: gridSpacing,
        paddingBottom: gridSpacing
      },
      spinnerCard: {
        padding: 12,
        textAlign: "center",
        width: "100%",
        display: "flex",
        justifyContent: "center"
      },
      card: {
        borderRadius: 2,
        overflow: "inherit",
        [breakpoints.down("sm")]: {
          marginRight: 0
        }
      },
      productGrid: {
        [breakpoints.up("md")]: {
          position: "relative",
          zIndex: 999
        }
      },
      productInfoGrid: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      },
      heroImage: {
        width: "100%",
        height: spacing(1) * 39,
        objectFit: "cover",
        position: "absolute"
      },
      relatedProductsWrapper: {
        backgroundColor: themeColors.lightGray
      }
    })
  )

export default ProductDetails
