import React from "react"
import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { contrastSwitch } from "utils/contrastSwitch"
import { useMediaQueries } from "utils/CustomeHooks/useMediaQueries"

import ResponsiveImage from "common/ResponsiveImage"
import Typography from "common/Typography"

type Props = BlockProps

const QuotationBlock: React.FC<Props> = ({ content }) => {
  const { author, backgroundColor, quotation, image } = content
  const theme = backgroundColor

  const classes = useStyles()
  const { isSmDown } = useMediaQueries()
  return (
    <Box maxWidth={700} m="auto">
      <Grid container>
        {image && (
          <Grid item xs={6}>
            <ResponsiveImage
              image={image}
              containerProps={{ className: classes.imgContainer }}
            />
          </Grid>
        )}
        <Grid item xs={image ? 6 : 12}>
          <Box p={{ xs: 2, sm: 4, md: 4 }} bgcolor={theme}>
            {quotation && (
              <Box mb={2}>
                <Typography
                  textWrap="wrap"
                  variant="body"
                  className={classNames(
                    classes.textColor,
                    classes[
                      contrastSwitch({
                        variant: "title",
                        contrastColor: theme
                      })
                    ]
                  )}
                >
                  {quotation}
                </Typography>
              </Box>
            )}
            {author && (
              <Box maxWidth={300}>
                <Typography
                  textWrap="wrap"
                  variant={isSmDown ? "body3" : "body1"}
                  className={classNames(
                    classes.textColor,
                    classes[
                      contrastSwitch({
                        variant: "title",
                        contrastColor: theme
                      })
                    ]
                  )}
                >
                  {author}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  imgContainer: {
    height: "100%"
  },
  textColor: {
    color: themeColors.colorBaseCyan900
  },
  ["text-base-white"]: {
    color: themeColors.white
  },
  ["text-base-cyan-500"]: {
    color: themeColors.colorBaseCyan500
  },
  ["text-base-blue-700"]: {
    color: themeColors.colorBaseBlue700
  },
  ["text-base-blue-800"]: {
    color: themeColors.colorBaseBlue800
  },
  ["text-base-blue-900"]: {
    color: themeColors.colorBaseBlue900
  }
}))

export default QuotationBlock
