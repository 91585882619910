import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import classNames from "classnames"
import useStore from "global-hook-store"

import ButtonBase from "@material-ui/core/ButtonBase"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Grow from "@material-ui/core/Grow"
import { makeStyles } from "@material-ui/core/styles"
import ArrowBack from "@material-ui/icons/ArrowBack"
import Clear from "@material-ui/icons/Clear"

import Spinner from "common/Spinner"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import LogOutIcon from "icons/LogOutIcon"
import MyAccountIcon from "icons/MyAccountIcon"
import Login from "pages/Login/Login"
import Register from "pages/Login/Register"
import ResetPassword from "pages/Login/ResetPassword"
import accountStore, { FormType } from "store/AccountStore"
import authStore from "store/AuthStore"
import DrawerStateStore from "store/DrawerStateStore"
import ImpersonationStore from "store/ImpersonationStore"
import OrderStore from "store/OrderStore"
import registerStore from "store/RegisterStore"

const AccountLogin: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { formComponent },
    actions: { setFormComponent }
  } = useStore(accountStore)

  const {
    actions: { setAccountDrawer }
  } = useStore(DrawerStateStore)

  const {
    state: { user },
    actions: { logout }
  } = useStore(authStore)

  const {
    actions: { resetCompactOrder }
  } = useStore(OrderStore)

  const {
    actions: { resetNewCustomer }
  } = useStore(registerStore)

  const {
    actions: { open }
  } = useStore(ImpersonationStore)

  const isRegisterComponent = formComponent !== FormType.login
  const isLoggedIn = !user.loading && user.data != null
  const accountMenu = user.data ? user.data.userAccountMenu : []
  const canImpersonate = user.data && user.data.canImpersonate

  const handleClose = () => {
    if (isRegisterComponent) {
      setAccountDrawer(false).then(() => {
        setFormComponent(FormType.login)
      })
    } else {
      setAccountDrawer(false)
    }
  }

  const handleSwitchUser = () => {
    setAccountDrawer(false)
    open()
  }

  return (
    <div className={classes.root}>
      {user.loading ? (
        <Spinner />
      ) : isLoggedIn && user.data ? (
        <>
          <div className={classes.accountSignedInHeader}>
            <div className={classes.user}>
              <Typography variant="headline3">
                {user.data.firstName}&nbsp;
                {user.data.lastName}
              </Typography>
              <Typography variant="body1">{user.data.companyName}</Typography>
            </div>
            <ButtonBase
              className={classes.button}
              onClick={(e) => setAccountDrawer(false)}
              id="close-account-drawer-button"
            >
              <Clear />
            </ButtonBase>
          </div>
          <Divider className={classes.divider} />
          <Wrapper className={classes.wrapper}>
            {accountMenu.map((menu) => {
              return (
                <div key={menu.type}>
                  <div className={classes.link}>
                    <Link to={menu.url}>
                      <ButtonBase
                        onClick={() => setAccountDrawer(false)}
                        className={classes.linkButton}
                      >
                        <Typography variant={"link2"}>{menu.title}</Typography>
                      </ButtonBase>
                    </Link>
                  </div>
                </div>
              )
            })}
            {canImpersonate && (
              <div>
                <div className={classes.link}>
                  <ButtonBase
                    onClick={handleSwitchUser}
                    className={classes.linkButton}
                  >
                    <Typography variant={"link2"}>
                      {t("impersonation.switch_user")}
                    </Typography>
                  </ButtonBase>
                </div>
              </div>
            )}
          </Wrapper>
          <Divider className={classes.divider} />
          <Wrapper className={classes.wrapper}>
            <Typography
              variant="link3"
              className={classes.linkButtonText}
              onClick={async () => {
                await logout()
                resetCompactOrder()
              }}
              id="sign-out-button"
            >
              <Typography className={classes.signOutText} variant={"link2"}>
                <LogOutIcon className={classes.logOutIcon} />
                {t("account.sign_out")}
              </Typography>
            </Typography>
          </Wrapper>
        </>
      ) : (
        <>
          <div className={classes.accountLoginHeader}>
            <div className={classes.accountIcon}>
              <Grow mountOnEnter unmountOnExit in={isRegisterComponent}>
                <ButtonBase
                  className={classes.backButton}
                  onClick={() =>
                    resetNewCustomer().then(() => {
                      setFormComponent(FormType.login)
                    })
                  }
                >
                  <ArrowBack />
                </ButtonBase>
              </Grow>
              <Grid
                container
                alignItems="center"
                className={classNames(classes.textTransition, {
                  [classes.textTransitionOn]: isRegisterComponent
                })}
              >
                <MyAccountIcon />
                <Typography
                  variant={"headline4"}
                  className={classes.accountText}
                >
                  {t("error.account")}
                </Typography>
              </Grid>
            </div>
            <ButtonBase
              className={classes.button}
              onClick={(e) => handleClose()}
              id="close-account-drawer-button"
            >
              <Clear />
            </ButtonBase>
          </div>
          <Divider className={classes.divider} />
          {formComponent === FormType.login && <Login reloadCartOnLogin />}
          {formComponent === FormType.forgotPassword && <ResetPassword />}
          {formComponent === FormType.register && <Register />}
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { gridSpacing, themeColors } }) => ({
    root: {},
    wrapper: {
      padding: `${spacing(3)}px ${spacing(4)}px`,
      [breakpoints.down("sm")]: {
        paddingRight: gridSpacing,
        paddingLeft: gridSpacing
      }
    },
    accountLoginHeader: {
      position: "relative",
      height: 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: gridSpacing
    },
    accountIcon: {
      display: "flex",
      alignItems: "center",
      color: themeColors.primaryBlue
    },
    accountText: {
      height: "100%",
      alignItems: "center",
      marginLeft: gridSpacing * 0.5
    },
    button: {
      width: 24,
      color: themeColors.primaryBlue,
      display: "flex",
      justifyContent: "flex-end"
    },
    linkButtonText: {
      padding: `${spacing(1)}px 0 0 0`,
      position: "relative",
      left: -2,
      "& > span > span": {
        // TODO: look over link styles, sync with figma
        fontWeight: "100"
      },
      "&:hover": {
        cursor: "pointer"
      }
    },
    accountSignedInHeader: {
      position: "relative",
      height: 80,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: gridSpacing
    },
    divider: {
      color: themeColors.lightGray
    },
    signOutText: {
      display: "flex",
      alignItems: "center"
    },
    logOutIcon: {
      marginRight: gridSpacing * 0.5
    },
    user: {
      paddingTop: gridSpacing,
      paddingBottom: gridSpacing
    },
    link: {
      paddingTop: 12,
      paddingBottom: 12
    },
    linkButton: {
      paddingLeft: 0,
      color: themeColors.primaryCyan,
      fontWeight: 700,
      lineHeight: 1.25,
      "& > span": {
        // TODO: look over link styles, sync with figma
        fontSize: "1.25rem"
      }
    },
    backButton: {
      color: themeColors.primaryBlue,
      paddingRight: spacing(3),
      position: "absolute"
    },
    textTransition: {
      transition: ".1s linear"
    },
    textTransitionOn: {
      marginLeft: 48
    }
  })
)

export default AccountLogin
