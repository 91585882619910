import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import Paper from "@material-ui/core/Paper"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"

import Typography from "common/Typography"
import { AdditionalInformationSheetType } from "models/Products"

type Props = {
  additionalInformationSheet: AdditionalInformationSheetType
}
const AdditionalInformationSheet = ({ additionalInformationSheet }: Props) => {
  const classes = useStyles()

  return (
    <Box width={1}>
      <Box gridGap={16} display="grid" mb={2}>
        {additionalInformationSheet.tagline && (
          <Typography textWrap="wrap" variant="body2">
            {additionalInformationSheet.tagline}
          </Typography>
        )}
        {additionalInformationSheet.technicalDataHeading && (
          <Typography textWrap="wrap" variant="body1">
            {additionalInformationSheet.technicalDataHeading}
          </Typography>
        )}
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {additionalInformationSheet?.technicalData.map((row) => (
              <TableRow key={row.item1} className={classes.tableRow}>
                <Hidden smDown>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableCellTopAlign}
                  >
                    <Typography variant="body2" bold>
                      {row.item1}
                    </Typography>
                  </TableCell>
                </Hidden>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableCellTopAlign}
                >
                  <Hidden mdUp>
                    <Typography textWrap="wrap" variant="body2" bold>
                      {row.item1}
                    </Typography>
                  </Hidden>
                  <Typography textWrap="wrap" variant="body2">
                    {row.item2}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
const useStyles = makeStyles(({ common: { themeColors } }) =>
  createStyles({
    tableRow: {
      backgroundColor: themeColors.colorBaseCyan400
    },
    tableCellTopAlign: {
      verticalAlign: "top",
      borderColor: themeColors.white,
      "& p": {
        color: themeColors.colorBaseGray700
      }
    }
  })
)

export default AdditionalInformationSheet
