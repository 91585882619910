//In summary, this function is used to decode binary data (presumably an error message) into a human-readable format.
//It assumes that the binary data is encoded as UTF-8 and is a valid JSON string.
export const Uint8ArrayDecoder = (arrayBufferError: any) => {
  if (arrayBufferError) {
    const decoder = new TextDecoder("utf-8")
    const errorMessage = decoder.decode(new Uint8Array(arrayBufferError))
    const errorData = JSON.parse(errorMessage)
    return errorData
  }
  return
}
