import useBreakpoint from "../hooks/useBreakpoint"

import { ImageType } from "types/content/media"
import Image, { ImageProps } from "./Image"
import ImagePlaceholder from "./ImagePlaceholder"

type ResponsiveImageProps = Omit<ImageProps, "src"> & {
  image?: ImageType
  enableColor?: boolean
  overlay?: number
  imagePlaceholder?: boolean
}

/** Wrapper for Images with lazy loading and sets correct source sets */
const ResponsiveImage = ({
  image,
  overlay,
  imagePlaceholder,
  enableColor = true,
  ...rest
}: ResponsiveImageProps) => {
  const isSmall = useBreakpoint("sm")

  if (!image) {
    if (imagePlaceholder) {
      return <ImagePlaceholder />
    } else {
      return null
    }
  }
  const imageProps = (image.sm && isSmall && image.sm) || image
  return (
    <Image
      src={imageProps._url}
      color={enableColor ? imageProps.color : "transparent"}
      lazy
      alt={image.alt}
      overlay={overlay}
      {...rest}
    />
  )
}

export default ResponsiveImage
