import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const CenterDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M16 2.667v6.705M22.637 16h6.696M16 29.333v-6.64M2.667 16h6.729m17.27 0c0 5.891-4.775 10.667-10.666 10.667S5.333 21.89 5.333 16 10.11 5.333 16 5.333 26.667 10.11 26.667 16Zm-8 0a2.667 2.667 0 1 1-5.333 0 2.667 2.667 0 0 1 5.334 0Z"
      fill="none"
    />
  </SvgIcon>
)

export default CenterDeLaval
