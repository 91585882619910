import i18next from "i18next"
import * as yup from "yup"

export interface NewsletterModalFormType {
  firstName: string
  lastName: string
  email: string
  newsletterAccepted: boolean
}

const validationSchema = (t: i18next.TranslationFunction) => {
  return yup.object<NewsletterModalFormType>().shape({
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup
      .string()
      .required(t("checkout.email_required_message"))
      .email(t("checkout.email_invalid_message"))
  })
}

export default validationSchema
