import { useTranslation } from "react-i18next"
import { Form as FormikForm } from "formik"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import vatRegistrationUtil from "utils/vatRegistrationUtil"

import ContinueButton from "common/ContinueButton"
import CustomTextField from "common/Formik/CustomTextField"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import accountStore from "store/AccountStore"
import authStore from "store/AuthStore"
import SiteSettingStore from "store/SiteSettingStore"

const AccountInformationForm: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    state: { vatNumberInformationText }
  } = useStore(SiteSettingStore)

  const {
    state: { updatedCustomer }
  } = useStore(accountStore)

  const {
    state: { user }
  } = useStore(authStore)

  const marketId =
    (user && user.data && user.data.billingAddress.countryCode) || "EN"

  return (
    <div className={classes.root}>
      <FormikForm noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              required
              name={"vatNumber"}
              label={t("checkout.vat_number_input_label")}
              type="text"
              adornment={vatRegistrationUtil(marketId)}
              vatUtilOn
              autoComplete="off"
            />
            {vatNumberInformationText && (
              <Typography variant={"body2"} className={classes.vatHint}>
                {vatNumberInformationText}
              </Typography>
            )}
          </Grid>

          {updatedCustomer.error && (
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.errorMessage}>
                {`${t("account.additional_information_error_message")}`}
                <br />
                {`(${updatedCustomer.error})`}
              </Typography>
            </Grid>
          )}
          <Grid container justifyContent="center">
            <ContinueButton type="submit" className={classes.saveButton}>
              {updatedCustomer.loading ? (
                <>
                  <ButtonLoaderIcon className={classes.loaderSpinner} />
                  {t("common.saving")}
                </>
              ) : (
                t("common.save")
              )}
            </ContinueButton>
          </Grid>
        </Grid>
      </FormikForm>
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    root: {
      background: themeColors.white,
      width: "100%"
    },
    saveButton: {
      margin: `${spacing(2)}px 0`,
      [breakpoints.down("sm")]: {
        marginLeft: spacing(1),
        marginRight: spacing(1)
      }
    },
    errorMessage: {
      textAlign: "center",
      color: themeColors.primaryRed
    },
    loaderSpinner: {
      marginRight: spacing(1)
    },
    vatHint: {
      padding: spacing(1) * 1.5,
      paddingBottom: 0
    }
  })
)

export default AccountInformationForm
