import { useTranslation } from "react-i18next"
import { Form } from "formik"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import CustomTextField from "common/Formik/CustomTextField"
import marketStore from "store/MarketStore"

const CustomerShippingAddress: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { countries, defaultMarket }
  } = useStore(marketStore)

  const availableCountries = countries.filter((c) => {
    return c.alpha2 === defaultMarket!.marketId.value
  })

  return (
    <Form noValidate>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              required
              name={"streetAddress"}
              label={t("checkout.street_address_input_label")}
              type="text"
            />
          </Grid>
          <Grid item container spacing={2} justifyContent="center">
            <Grid item xs={6}>
              <CustomTextField
                required
                name={"zipCode"}
                label={t("checkout.zip_code_input_label")}
                type={"text"}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                required
                name={"city"}
                label={t("checkout.city_input_label")}
                type="text"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              required
              name={"countryCode"}
              label={t("checkout.shipping_country_input_label")}
              defaultValueOption={defaultMarket && defaultMarket.marketId.value}
              select
            >
              <option
                disabled
                label={t("checkout.shipping_country_input_label")}
              />
              {Object.values(availableCountries).map((country, i) => (
                <option key={i} value={country.alpha2}>
                  {country.name}
                </option>
              ))}
            </CustomTextField>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                name={"companyName"}
                label={t("account.company_name_label")}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                required
                name={"firstName"}
                label={t("checkout.recipient_first_name_input_label")}
                type="text"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                required
                name={"lastName"}
                label={t("checkout.recipient_last_name_input_label")}
                type="text"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              required
              name={"phone"}
              label={t("checkout.recipient_phone_input_label")}
              type="tel"
            />
          </Grid>
          <Grid item xs={12} className={classes.continueButtonWrapper}>
            <Button variant="secondary" type="submit">
              {t("checkout.add_delivery_address_form_button")}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Form>
  )
}

const useStyles = makeStyles(({ breakpoints, common: { gridSpacing } }) => ({
  root: {
    [breakpoints.down("sm")]: {
      padding: `0 ${gridSpacing}px`
    }
  },
  continueButtonWrapper: {
    textAlign: "center"
  }
}))

export default CustomerShippingAddress
