import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const ErrorInfo: React.FC<Props> = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M12.7141 3.28465C13.9644 4.53455 14.6668 6.23161 14.6668 7.9995C14.6668 9.76732 13.9646 11.4643 12.7145 12.7144C11.4645 13.9644 9.76749 14.6667 7.99967 14.6667C6.23177 14.6667 4.53472 13.9642 3.28481 12.714C2.03539 11.4642 1.3335 9.76672 1.3335 7.9995C1.3335 6.23221 2.03555 4.53472 3.28522 3.28505C4.53489 2.03538 6.23237 1.33333 7.99967 1.33333C9.76689 1.33333 11.4643 2.03522 12.7141 3.28465Z"
      fill="#FF3300"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66683 4.66667H7.3335V8.66667H8.66683V4.66667ZM8.66683 10H7.3335V11.3333H8.66683V10Z"
      fill="white"
    />
  </SvgIcon>
)

export default ErrorInfo
