import { FC } from "react"

import { PropertyInfo } from "../types/content"
import editor from "../utils/editorAttributes"

import { GenericRenderer } from "./utils"

type XhtmlStringProperty = string

type Props = {
  content: XhtmlStringProperty
  propInfo?: PropertyInfo
  component?: React.ComponentType
  [key: string]: any
}

const XhtmlString: FC<Props> = ({
  content,
  propInfo,
  component: Component,
  ...rest
}) => (
  <GenericRenderer
    {...editor(propInfo)}
    component={Component}
    {...rest}
    dangerouslySetInnerHTML={{
      __html: content ? content : propInfo ? "" : null
    }}
  />
)

export type { XhtmlStringProperty }
export default XhtmlString
