import classNames from "classnames"
import { CarouselProvider as Carousel } from "pure-react-carousel"

import { createStyles, makeStyles } from "@material-ui/core/styles/"

import "pure-react-carousel/dist/react-carousel.es.css"

type CarouselProviderProps = {
  naturalSlideWidth: number
  naturalSlideHeight: number
  totalSlides: number
  thumbnailPosition: "left" | "bottom"
}

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    carousel: {
      position: "relative",
      justifyContent: "space-between",
      flexDirection: "row",
      "& .focusRing___1airF.carousel__slide-focus-ring": {
        border: "none!important"
      },
      [breakpoints.down("sm")]: {
        flexWrap: "wrap"
      }
    },
    left: {
      display: "flex"
    },
    bottom: {
      display: "block"
    }
  })
)

export const CarouselProvider: React.FC<CarouselProviderProps> = ({
  children,
  thumbnailPosition = "left",
  totalSlides,
  naturalSlideWidth,
  naturalSlideHeight
}) => {
  const classes = useStyles({ thumbnailPosition })

  return (
    <Carousel
      naturalSlideWidth={naturalSlideWidth}
      naturalSlideHeight={naturalSlideHeight}
      totalSlides={totalSlides}
      dragEnabled={totalSlides > 1}
      touchEnabled={totalSlides > 1}
      className={classNames(classes.carousel, classes[thumbnailPosition])}
      isIntrinsicHeight={false}
    >
      {children}
    </Carousel>
  )
}

export default CarouselProvider
