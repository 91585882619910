import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Grow from "@material-ui/core/Grow"
import { makeStyles } from "@material-ui/core/styles"

import ContainerWrapper from "../common/ContainerWrapper"

import EditPen from "common/EditPen"
import Typography from "common/Typography"
import ArrowDown from "icons/ArrowDown"
import PromoCodeIcon from "icons/PromoCodeIcon"
import Panel from "pages/AccountPage/common/Panel"
import AuthStore from "store/AuthStore"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import PromoForm from "./PromoForm"

const PromoCode: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { user }
  } = useStore(AuthStore)

  const { resetCheckoutStates, fetchingCheckout, promoCode, preparedCheckout } =
    useCheckoutPaymentStore()

  const handleResetCheckout = async () => {
    await resetCheckoutStates()
    await fetchingCheckout()
  }

  const isValid =
    promoCode.data && promoCode.data.isValid === true ? true : false

  const isPrepared = !preparedCheckout.loading && preparedCheckout.data != null

  const summaryText = (
    <Grid container alignItems="center">
      <PromoCodeIcon className={classes.promoIcon} />
      <Box ml={3}>
        <Typography variant="headline4">
          {t("checkout.promo_code_heading")}
        </Typography>
      </Box>
    </Grid>
  )

  const isLoggedIn = !user.loading && user.data != null

  return (
    <ContainerWrapper>
      <>
        {isPrepared && !isValid ? (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.reset}
          >
            <Grid>{summaryText}</Grid>
            <EditPen onClick={() => handleResetCheckout()} />
          </Grid>
        ) : (
          <Panel
            disabled={!isLoggedIn}
            summaryClassName={classes.summary}
            summary={summaryText}
            expansionIcon={<ArrowDown className={classes.icon} />}
          >
            <Grid container direction="column">
              <Divider className={classes.divider} />
              <Box px={1} pt={3}>
                <Grow in={isValid ? false : true} unmountOnExit={true}>
                  <Typography variant="preamble" className={classes.preamble}>
                    {t("checkout.promo_code_sub_heading")}
                  </Typography>
                </Grow>

                <PromoForm />
              </Box>
            </Grid>
          </Panel>
        )}
      </>
    </ContainerWrapper>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  summary: { padding: 0 },
  reset: {
    padding: `${spacing(3)}px ${spacing(3)}px`,
    background: themeColors.white,
    marginBottom: spacing(1)
  },
  icon: {
    fontSize: 14
  },
  promoIcon: {
    color: themeColors.white
  },
  divider: {
    position: "absolute",
    left: 0,
    width: "100%"
  },
  preamble: {
    textAlign: "left",
    color: themeColors.darkGray,
    fontWeight: "bold",
    marginBottom: spacing(2)
  }
}))

export default PromoCode
