import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { BlockResolver, ContentProps } from "@starrepublic/epi/cms"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import BreadcrumbsBar from "common/BreadcrumbsBar"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import PageListMenu from "pages/InformationPage/PageListMenu"
import marketStore from "store/MarketStore"

type CookiesPageProps = ContentProps

const CookiesPage: FC<CookiesPageProps> = ({ content, pageInfo }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { subNavigation, preamble } = content

  const {
    state: { markets }
  } = useStore(marketStore)

  useEffect(() => {
    const getFirstTwoLettersFromLang = () => {
      const selectedMarket =
        markets.data && markets.data.find((x) => x.isSelected)
      const selectedLanguage = selectedMarket
        ? selectedMarket.selectedLanguage
        : "en"
      const firstTwoLetters = selectedLanguage.substring(0, 2)
      return firstTwoLetters
    }
    const script = document.createElement("script")
    script.src = `https://consent.cookiebot.com/${process.env.REACT_APP_COOKIEBOT_API_KEY}/cd.js`
    script.async = true
    script.setAttribute("data-culture", getFirstTwoLettersFromLang())
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [markets.data])

  return (
    <>
      <BreadcrumbsBar />
      {content.hero &&
      (content.hero.bigTitle || content.hero.backgroundImage) ? (
        <BlockResolver content={content.hero} pageInfo={pageInfo} />
      ) : null}
      <Divider />
      <Wrapper>
        <Grid justifyContent="center" container>
          <Grid className={classes.stickyContainer} item xs={12} md={3} sm={12}>
            {subNavigation && <PageListMenu subNavigation={subNavigation} />}
            <Box mt={4} mr={2}></Box>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-start"
            alignItems="center"
            direction="column"
            className={classes.root}
          >
            {preamble && (
              <Grid className={classes.header}>
                <Typography
                  variant="preamble"
                  className={classes.stringContainer}
                >
                  {preamble}
                </Typography>
              </Grid>
            )}
            <Grid item className={classes.body}>
              {/* CookieBot Cookies List start  */}
              <div id="CookieDeclaration">&nbsp;</div>
            </Grid>
          </Grid>
          {<Grid item xs={12} md={3} sm={12} />}
        </Grid>
      </Wrapper>
    </>
  )
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    maxWidth: 628,
    [breakpoints.down("sm")]: {
      padding: spacing(2)
    }
  },
  stringContainer: {
    marginTop: spacing(6),
    marginBottom: spacing(4),
    [breakpoints.down("sm")]: {
      marginTop: spacing(4)
    }
  },
  stickyContainer: {
    position: "relative",
    float: "right",
    width: "100%",
    zIndex: 1
  },
  header: {
    width: "100%"
  },
  body: {
    marginTop: spacing(4),
    marginBottom: spacing(4),
    width: "100%"
  }
}))

export default CookiesPage
