import i18next from "i18next"
import * as yup from "yup"

import { ChangePasswordModel } from "models/User"

const validationSchema = (t: i18next.TranslationFunction) => {
  return yup.object<ChangePasswordModel>().shape({
    password: yup
      .string()
      .required(t("checkout.password_required_message"))
      .matches(
        new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        ),
        t("checkout.password_format_message")
      )
  })
}

export default validationSchema
