import { FenceProduct } from "../types"

import AccessoryVariant from "./AccessoryVariant"

type Props = {
  product: FenceProduct
  currencySymbol: string
}

const Accessory = ({ product, currencySymbol }: Props) => {
  return (
    <>
      {product.variants.map((variant) => {
        return (
          <AccessoryVariant
            key={variant.articleNumber}
            product={product}
            variant={variant}
            currencySymbol={currencySymbol}
          />
        )
      })}
    </>
  )
}

export default Accessory
