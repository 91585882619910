import { useTranslation } from "react-i18next"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import WeChatQR from "icons/WeChatQR"
import Weibo from "icons/Weibo"
import Youku from "icons/Youku"
import { FooterLink } from "types/content/footer"
import IconCircle from "./IconCircle"

type SocialLinksProps = { socialLinks: FooterLink[] }

const WeChatSocialLink: React.FC<SocialLinksProps> = (Props) => {
  const { socialLinks } = Props
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="headline3" className={classes.socialLinkHeader}>
        {t("footer.social_links")}
      </Typography>

      <Box>
        <Box display="flex">
          {socialLinks.map((link, i) => {
            return (
              <Box key={i}>
                {link.text.toLowerCase().indexOf("youku") > -1 && (
                  <IconCircle {...link}>
                    <Youku />
                  </IconCircle>
                )}
                {link.text.toLowerCase().indexOf("weibo") > -1 && (
                  <IconCircle {...link}>
                    <Weibo />
                  </IconCircle>
                )}
              </Box>
            )
          })}
        </Box>
        <WeChatQR className={classes.weChatQr} />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ common: { themeColors, gridSpacing } }) => ({
  socialLinkHeader: {
    color: themeColors.white,
    fontSize: 24,
    paddingBottom: gridSpacing
  },
  weChatQr: {
    marginTop: 28,
    width: 128,
    height: 128
  }
}))

export default WeChatSocialLink
