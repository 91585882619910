import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const ServicesDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M13.333 5.333h-8v8h8v-8ZM13.333 18.667h-8v8h8v-8ZM26.667 18.667h-8v8h8v-8ZM22.667 4l-5.334 5.333 5.334 5.334L28 9.333 22.667 4Z"
      fill="none"
    />
  </SvgIcon>
)

export default ServicesDeLaval
