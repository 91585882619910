import { ReactNode } from "react"
import Slider from "react-slick"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import ArrowRightShort from "icons/ArrowRightShort"

type RelatedProductsSliderProps = {
  children: ReactNode
  slides: number
}

type NavArrowProps = {
  onClick?: () => void
  className?: string
  navClassName?: string
}

const NavArrowButton = ({
  onClick,
  className,
  navClassName
}: NavArrowProps) => (
  <button onClick={onClick} className={className}>
    <ArrowRightShort className={navClassName} />
  </button>
)

const RelatedProductsSlider = ({
  children,
  slides
}: RelatedProductsSliderProps) => {
  const classes = useStyles()

  const settings = (prevArrowClassName: string) => ({
    swipe: false,
    arrows: true,
    dots: true,
    infinite: slides > 1 ? true : false,
    slidesToShow: slides > 4 ? 4 : slides,
    slidesToScroll: 1,
    prevArrow: <NavArrowButton navClassName={prevArrowClassName} />,
    nextArrow: <NavArrowButton />,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: slides > 4 ? 4 : slides,
          slidesToScroll: 1,
          swipe: false,
          arrows: true
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slides > 3 ? 3 : slides,
          slidesToScroll: 1,
          swipe: false,
          arrows: true
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          swipe: true,
          arrows: false
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          swipe: true
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          swipe: true
        }
      }
    ]
  })

  return (
    <Grid className={classes.sliderContainer}>
      {children && (
        <Slider {...settings(classes.prevNavButton)}>{children}</Slider>
      )}
    </Grid>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    sliderContainer: {
      background: "transparent",
      "& .slick-active > button": {
        backgroundColor: themeColors.primaryCyan
      },
      "& .slick-dots li button:before": {
        content: "none"
      },
      "& .slick-dots li button": {
        borderRadius: "50%",
        border: `2px solid ${themeColors.offWhite}`,
        width: 12,
        height: 12,
        padding: 0
      },
      "& .slick-dots": {
        zIndex: 1,
        position: "initial",
        marginTop: spacing(2),
        display: "flex !important",
        alignItems: "center",
        justifyContent: "center"
      },
      "& .slick-dots li": {
        width: 12,
        height: 12
      },
      "& .slick-next:before": {
        content: "none"
      },
      "& .slick-prev:before": {
        content: "none"
      },
      "& .slick-next": {
        top: "calc(50% - 14px)",
        right: -28,
        width: 48,
        height: 48,
        zIndex: 2,
        background: themeColors.white,
        borderRadius: "50%",
        color: themeColors.primaryCyan,
        filter:
          "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12))"
      },
      "& .slick-prev": {
        top: "calc(50% - 14px)",
        left: -28,
        width: 48,
        height: 48,
        zIndex: 2,
        background: themeColors.white,
        borderRadius: "50%",
        color: themeColors.primaryCyan,
        filter:
          "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12))"
      },
      [breakpoints.down("sm")]: {
        margin: 0
      }
    },
    prevNavButton: {
      transform: "rotateY(180deg)"
    }
  })
)

export default RelatedProductsSlider
