import request from "@starrepublic/epi/api/request"

export type EnergyCalculatorContactPayload = {
  name: string
  phone: string
  email?: string
  postalCode: string
  acceptPrivacyPolicy: boolean
}

const EnergyContactApi = {
  send: (payload: EnergyCalculatorContactPayload) =>
    request.post(`/energycalculator`, payload)
}

export default EnergyContactApi
