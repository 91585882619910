import { useTranslation } from "react-i18next"
import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { blockDisplay } from "utils/blockDisplay"

import ExternalVideoAndImage from "blocks/TextAndMediaBlock/ExternalVideoAndImage"
import ImageCollage from "blocks/TextAndMediaBlock/ImageCollage"
import Button from "common/Button"
import LinkButton from "common/LinkButton"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import ArrowRight from "icons/ArrowRight"
import { TestimonialContent } from "./types"

type Props = BlockProps<TestimonialContent>
type DisplayStylesProps = {
  half: boolean
  twoThirds: boolean
  oneThird: boolean
}

const TestimonialBlock: React.FC<Props> = ({ content, blockInfo }) => {
  const {
    title,
    body,
    quote,
    contentPosition,
    theme,
    link,
    linkText,
    images,
    video,
    farmDataBlock: { herdSize, location }
  } = content

  const _display = blockDisplay(blockInfo)

  const isSmDown = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"))
  const isMdDown = useMediaQuery(({ breakpoints }) => breakpoints.down("md"))
  const isXsDown = useMediaQuery(({ breakpoints }) => breakpoints.down("xs"))

  const positionLeft = contentPosition === "left" ? true : false

  const bgColor = theme ? theme : "#FFFFFF"

  const isDark = bgColor === "#103D82" ? true : false

  const isVideo = video && video.value

  const displayStyles = {
    half: _display.half,
    twoThirds: _display.twoThird,
    oneThird: _display.oneThird
  } as DisplayStylesProps

  const classes = useStyles(displayStyles)()
  const { t } = useTranslation()

  return (
    <Box
      py={{
        xs: 1.5,
        md:
          theme === "#FFFFFF"
            ? 0
            : theme
              ? _display.twoThird
                ? 4
                : _display.oneThird || _display.half
                  ? 3
                  : 9
              : 0
      }}
      px={{
        xs: 0,
        md: _display.twoThird ? 4 : _display.oneThird || _display.half ? 3 : 0
      }}
      position="relative"
      style={{ backgroundColor: bgColor }}
      height="100%"
    >
      {(_display.auto || _display.full || isSmDown) && (
        <div
          className={classes.backgroundColorBlock}
          style={{ backgroundColor: bgColor }}
        />
      )}
      <Grid direction={positionLeft ? "row" : "row-reverse"} container>
        <Grid
          className={classes.imageWrapper}
          item
          xs={12}
          sm={12}
          md={_display.half || _display.oneThird ? 12 : 6}
        >
          {images && images.length > 0 && !isVideo ? (
            <ImageCollage
              {...content}
              display={_display.half || _display.twoThird}
              isOneThird={_display.oneThird}
              showOneImage={false}
            />
          ) : (
            isVideo && (
              <ExternalVideoAndImage
                {...content}
                isHalf={_display.half || _display.twoThird}
                isOneThird={_display.oneThird}
              />
            )
          )}
          {quote && (
            <Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.quoteBox}
              >
                <Typography variant="headline5" align="center">
                  &quot;{quote}&quot;
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          sm={12}
          md={_display.half || _display.oneThird ? 12 : 6}
        >
          <Box
            className={classNames(classes.contentWrapper, {
              [classes.contentLeftWrapper]: !positionLeft
            })}
            px={{
              xs: _display.full ? 1.5 : 0,
              sm: _display.full ? 1.5 : 0,
              md: 0
            }}
          >
            <Grid>
              <Typography
                className={classNames({
                  [classes.lightText]: isDark
                })}
                variant="headline4"
              >
                {title}
              </Typography>
            </Grid>

            <Grid
              className={classes.locationBoxContainer}
              container
              direction={
                (_display.oneThird || _display.twoThird || _display.half) &&
                isMdDown &&
                !isXsDown
                  ? "column"
                  : "row"
              }
              wrap="nowrap"
            >
              <Grid>
                <Grid container alignItems="center">
                  <Grid>
                    <Typography>{`${t(
                      "testimonial.farm_location"
                    )}`}</Typography>
                    <Typography variant="link2">{location}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid>
                <Grid container alignItems="center">
                  <Grid>
                    <Typography>{`${t("testimonial.herd_size")}`}</Typography>
                    <Typography className={classes.herdSize} variant="link2">
                      {herdSize}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              className={classNames({
                [classes.lightText]: isDark
              })}
            >
              <ThemeXhtmlString content={body} />
            </Grid>
            {linkText && link && (
              <LinkButton link={link}>
                <Button endIcon={<ArrowRight />}>{linkText}</Button>
              </LinkButton>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = (displaySize: DisplayStylesProps) =>
  makeStyles(({ breakpoints, spacing, common: { themeColors } }) => ({
    backgroundColorBlock: {
      width: "100vw",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      marginLeft: "calc(50% - 50vw)"
    },
    locationBoxContainer: {
      marginTop: spacing(3),
      marginBottom: spacing(2),
      "& > div": {
        display: "flex",
        flexDirection: "column",
        padding: spacing(2, 3),
        gap: spacing(0.25),
        backgroundColor: themeColors.offWhite,
        border: `1px solid ${themeColors.colorBaseGray400}`
      },
      "& > div:first-child": {
        borderRadius: spacing(0.5, 0, 0, 0.5),
        borderRight: "none",
        [breakpoints.between("sm", "md")]: {
          borderRight:
            displaySize.half || displaySize.oneThird || displaySize.twoThirds
              ? `1px solid ${themeColors.colorBaseGray400}`
              : "none",
          borderBottom:
            displaySize.half || displaySize.oneThird || displaySize.twoThirds
              ? "none"
              : `1px solid ${themeColors.colorBaseGray400}`,
          borderRadius:
            (displaySize.half ||
              displaySize.oneThird ||
              displaySize.twoThirds) &&
            spacing(0.5, 0.5, 0, 0)
        }
      },
      "& > div:last-child": {
        borderRadius: spacing(0, 0.5, 0.5, 0),
        [breakpoints.between("sm", "md")]: {
          borderRadius:
            (displaySize.half ||
              displaySize.oneThird ||
              displaySize.twoThirds) &&
            spacing(0, 0, 0.5, 0.5)
        }
      }
    },
    quoteBox: {
      height: 96,
      backgroundColor: themeColors.primaryBlue,
      "& > h5": {
        color: themeColors.white,
        padding: `0px ${spacing(2)}px`,
        fontSize: 16
      }
    },
    lightText: {
      color: themeColors.white
    },
    contentWrapper: {
      maxWidth: 640,
      zIndex: 2,
      margin: `${spacing(displaySize.twoThirds ? 0 : 4)}px 0 0 ${spacing(
        displaySize.twoThirds
          ? 3
          : displaySize.oneThird || displaySize.half
            ? 0
            : 7
      )}px`,
      [breakpoints.down("sm")]: {
        margin: `${spacing(4)}px 0 0 0`
      }
    },
    contentLeftWrapper: {
      margin: `${spacing(4)}px ${spacing(7)}px 0 0`,
      [breakpoints.down("sm")]: {
        margin: `${spacing(4)}px 0 0 0`
      }
    },
    imageWrapper: {
      zIndex: 1,
      margin: displaySize.twoThirds ? "0 auto auto" : "auto",
      position: "relative"
    },
    linkText: {
      marginRight: spacing(1),
      color: themeColors.primaryCyan,
      fontWeight: 500
    },
    herdSize: {
      color: themeColors.primaryBlue
    }
  }))

export default TestimonialBlock
