import { Suspense } from "react"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import FenceIconSkeleton from "common/Skeletons/FenceIconSkeleton"
import TextField from "common/TextField"
import Typography from "common/Typography"
import FenceCornerIcon from "icons/FenceCalculator/FenceCornerIcon"
import WoodenPost from "icons/FenceCalculator/WoodenPost"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"
import themeColors from "theme/colors"

const FenceCorners = () => {
  const classes = useStyles()
  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))

  const { numberOfCornerPosts, setNumberOfCornerPosts, fenceCalculatorLabels } =
    useFenceCalculatorStore()

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" py={5} px={6}>
        <Box className={classes.iconContainer}>
          <Suspense fallback={<FenceIconSkeleton />}>
            <FenceCornerIcon className={classes.fenceIcon} />
          </Suspense>
        </Box>
        <Box mt={2}>
          <Typography variant="subheading1" className={classes.formLabel}>
            {fenceCalculatorLabels.cornersNumber}
          </Typography>
        </Box>
        <Box width={isSmUp ? "50%" : "100%"} pr={isSmUp ? 4 : 0}>
          <TextField
            select
            value={numberOfCornerPosts}
            onChange={(event) =>
              setNumberOfCornerPosts(Number(event.target.value))
            }
            fullWidth
            variant="outlined"
            inputProps={{
              "aria-label": fenceCalculatorLabels.cornersNumber
            }}
          >
            <MenuItem value={0} disabled>
              {fenceCalculatorLabels.cornersNumber}
            </MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
          </TextField>
        </Box>
      </Box>

      <Box style={{ background: themeColors.primaryLightCyan }}>
        <Box width={isSmUp ? "50%" : "100%"} px={2} pt={3}>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                width="100%"
                height={107}
                border={`3px solid ${themeColors.colorBaseBlue700}`}
                maxWidth={147}
                bgcolor={themeColors.white}
              >
                <WoodenPost style={{ fontSize: 43 }} />
                <Typography className={classes.infoLabel}>
                  {fenceCalculatorLabels.cornersPostHeading}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.description}>
                {fenceCalculatorLabels.cornersPostDescription}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    formLabel: {
      fontWeight: 700,
      color: themeColors.black
    },
    infoLabel: {
      fontSize: ".875rem",
      color: themeColors.primaryBaseBlue,
      fontWeight: 700,
      "& span": {
        color: themeColors.primaryBlue
      }
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      [breakpoints.up("sm")]: {
        width: "50%",
        paddingRight: spacing(4)
      }
    },
    fenceIcon: {
      width: 150,
      height: 92
    },
    description: {
      fontSize: "0.875rem",
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: 1.4
    }
  })
)

export default FenceCorners
