const ensureConfig = () => {
  // config.apiRoot.configure(
  //   "http://192.168.10.234",
  //   // "http://192.168.10.170:3000",
  //   Product,
  //   Variant,
  //   Category
  // )
}

export default ensureConfig
