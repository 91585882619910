import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const Website: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="24 25">
    <path
      fill="#000"
      d="M13 1H4a1 1 0 0 0-1 1v1H2a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-1h1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Zm-2 3v1H2V4h9Zm2 5h-1V4a1 1 0 0 0-1-1H4V2h9v7Z"
    />
  </SvgIcon>
)

export default Website
