import { asyncState, createStore } from "global-hook-store"

import api, { Country } from "api/country"

const countryStore = createStore(
  {
    selectableRegistrationCountries: asyncState<Country[]>()
  },
  {
    fetchCountries: async (_, __: null, { asyncAction }) =>
      asyncAction("selectableRegistrationCountries", api.getCountries())
  }
)

export default countryStore
