import { XhtmlString } from "@starrepublic/epi/cms"
import classNames from "classnames"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import LinkText from "common/LinkText"
import Typography from "common/Typography"
import LocationIcon from "icons/LocationIcon"
import Mail from "icons/Mail"
import Phone from "icons/Phone"
import SiteSettingStore from "store/SiteSettingStore"
import { themeColors } from "theme"

type ContactProps = {
  name: string
  heading: string
  phone: string
  address: string
  zipcode: string
  city: string
  email: string
  title?: string
  isDealerPage?: boolean
}

const ContactHeadquarter: React.FC<ContactProps> = ({
  heading,
  phone,
  address,
  zipcode,
  city,
  email,
  title,
  isDealerPage = true
}) => {
  const classes = useStyles()

  const {
    state: { isOnePager }
  } = useStore(SiteSettingStore)

  return (
    <Box
      mx={{ xs: 2, sm: 4 }}
      my={0}
      bgcolor={themeColors.white}
      p={{ xs: 6, sm: 6, md: 6 }}
      width={"100%"}
      height={"100%"}
    >
      <Box>
        {(title || heading) && (
          <Box mb={2}>
            {isOnePager && (
              <Typography variant="headline2">{heading}</Typography>
            )}
            {isOnePager && title === heading ? null : (
              <Typography
                variant={isOnePager ? "body1" : "headline2"}
                className={classNames({
                  [classes.alternativeName]: isOnePager
                })}
              >
                {title}
              </Typography>
            )}
            {isOnePager === false &&
              (title === heading ? null : (
                <Typography variant="headline2">{heading}</Typography>
              ))}
          </Box>
        )}

        {phone && (
          <Box display="flex" pb={2}>
            <Phone className={classes.icon} />
            <LinkText phone link={phone}>
              {phone}
            </LinkText>
          </Box>
        )}

        {isDealerPage
          ? (address || zipcode || city) && (
              <Box display="flex" pb={2}>
                <LocationIcon className={classes.icon} />
                <Box>
                  {address && <Typography noWrap>{address}</Typography>}
                  {(zipcode || city) && (
                    <Typography noWrap>{`${zipcode} ${city}`}</Typography>
                  )}
                </Box>
              </Box>
            )
          : address && (
              <Box display="flex" pb={2}>
                <LocationIcon className={classes.icon} />
                <Box>
                  <XhtmlString content={address} component={Typography} />
                </Box>
              </Box>
            )}

        {email && (
          <Box display="flex">
            <Mail className={classes.icon} />
            <LinkText mailto link={email}>
              {email}
            </LinkText>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  icon: {
    marginRight: spacing(3.25),
    color: themeColors.colorBaseGray600
  },
  alternativeName: {
    marginBlock: spacing(2),
    color: themeColors.black
  }
}))

export default ContactHeadquarter
