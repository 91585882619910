import { ProductVariationsResponse } from "@starrepublic/epi/api/products"
import { asyncState, createStore } from "global-hook-store"

import productsApi from "api/products"
import Variant from "models/Variant"

const VariationStore = createStore(
  {
    variations: asyncState<ProductVariationsResponse<Variant>>()
  },
  {
    getVariations: async (_, productId: number, { asyncAction }) =>
      asyncAction("variations", productsApi.getVariations(productId))
  }
)

export default VariationStore
