import { useTranslation } from "react-i18next"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import MobileStepper from "@material-ui/core/MobileStepper"
import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import Typography from "common/Typography"

type Props = {
  route: string
  handleLoadMore: () => void
  showingNumberOfProducts: number
  totalNumberOfProducts: number
}

const LoadMore = ({
  route,
  handleLoadMore,
  showingNumberOfProducts,
  totalNumberOfProducts
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box
      key={route}
      m={2}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Box width={335}>
        <Box mb={1}>
          <Typography align="center">
            {t("product.showing_x_of_y_products")
              .replace("{X}", showingNumberOfProducts.toString())
              .replace("{Y}", totalNumberOfProducts.toString())}
          </Typography>
        </Box>
        <Box m={2}>
          <MobileStepper
            variant="progress"
            steps={totalNumberOfProducts}
            position="static"
            activeStep={showingNumberOfProducts}
            className={classNames(classes.stepper, {
              [classes.transform]:
                showingNumberOfProducts >= totalNumberOfProducts
            })}
            nextButton={<></>}
            backButton={<></>}
          />
        </Box>
      </Box>
      {showingNumberOfProducts < totalNumberOfProducts && (
        <Button variant="secondary" onClick={() => handleLoadMore()}>
          {t("product.load_more")}
        </Button>
      )}
    </Box>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  loaderSpinner: {
    marginLeft: spacing(1)
  },
  stepper: {
    "& div": {
      width: "100%"
    }
  },
  transform: {
    "& div": {
      transform: "translateX(0) !important"
    }
  },
  menuTitle: {
    paddingLeft: spacing(2)
  },
  menuRoot: {
    flexWrap: "nowrap"
  }
}))

export default LoadMore
