import request from "@starrepublic/epi/api/request"

import { Cart, LineItemProperty } from "models/Cart"
import Checkout from "models/Checkout"

type PaymentMethodDetail = {
  key: string
  type: string
}

type PaymentMethod = {
  details: PaymentMethodDetail[]
  name: string
  type: string
}

type PaymentGroup = {
  name: string
  type: string[]
}

type AdyenPayAction = {
  method: "GET" | "POST"
  url: string
  data: Record<string, string>
}

export type AdyenPayResponse = {
  resultCode: string
  action: AdyenPayAction
  orderNumber: string
}

export type PaymentMethodFlagsResponse = string[]

export type PaymentMethodResponse = { paymentMethods: PaymentMethod[] }

export type RedirectPaymentResponse = {
  url: string
}

export type TokenPaymentResponse = {
  token: string
  extra: Record<string, any>
}

export type PaymentsReponse = {
  cart: Cart<LineItemProperty>
  paymentData?: RedirectPaymentResponse | TokenPaymentResponse
}

export type Amount = {
  currency: string
  value: number
}

export type AdyenPayRequest = {
  orderGroupId: string
  reference: string
  encryptedCardNumber?: string
  encryptedExpiryMonth?: string
  encryptedExpiryYear?: string
  encryptedSecurityCode?: string
  holderName?: string
}

export type FinalizeRequest = {
  paymentMethod: string
  queryParams?: Record<string, string>
  data?: any
}

export type PaymentCompleteResponse = {
  id: string
  email: string
  payment: string
  total: string
}

export type PaymentRedirectReponse = {
  redirect: {
    data: Record<string, string>
    url: string
  }
}

export type PaymentMethodsRequest = {
  toCountryCode: string
  locale: string
  cartName: string
}

const adyenPayment = {
  setPaymentOptions: (checkout: Checkout) =>
    request.post<PaymentsReponse>(`/checkout/payments`, checkout),

  getPaymentMethods: (payload: PaymentMethodsRequest) =>
    request.get<PaymentMethodResponse>("/adyen/paymentmethods", {
      params: { ...payload }
    }),
  finalize: (paymentType: string, requestBody: AdyenPayRequest) =>
    request.post<AdyenPayResponse>(`/adyen/pay/${paymentType}`, requestBody)
}

export default adyenPayment
