import { useEffect, useMemo, useState } from "react"

type State = {
  loaded: boolean
  error: boolean
}

export enum ResourceType {
  Script = "script",
  Style = "link",
  Image = "img"
}

const cachedResources = new Set<string>()
const useResource: (
  src: string | null,
  type: ResourceType,
  props?: Record<string, string>
) => [boolean, boolean] = (src, type, props = undefined) => {
  const [state, setState] = useState<State>({
    loaded: false,
    error: false
  })

  const propHash = (props && Object.entries(props).join(",")) || ""

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const propValues = useMemo(() => props, [propHash])

  useEffect(() => {
    if (!src) {
      return
    }
    if (cachedResources.has(src)) {
      setState({
        loaded: true,
        error: false
      })
    } else {
      cachedResources.add(src)
      const resourceTag = document.createElement(type)

      const onLoaded = () => {
        setState({
          loaded: true,
          error: false
        })
      }

      const onError = () => {
        setState({
          loaded: true,
          error: true
        })
      }
      resourceTag.addEventListener("load", onLoaded)
      resourceTag.addEventListener("error", onError)

      if (propValues) {
        Object.entries(propValues).forEach(([k, v]) => {
          resourceTag[k] = v
        })
      }

      switch (type) {
        case ResourceType.Script:
          resourceTag["async"] = true
          resourceTag["type"] = "text/javascript"
        //falls through
        case ResourceType.Image:
          resourceTag["src"] = src
          break
        case ResourceType.Style:
          resourceTag["href"] = src
          resourceTag["rel"] = "stylesheet"
          break
      }

      switch (type) {
        case ResourceType.Style:
        case ResourceType.Script:
          document.head.appendChild(resourceTag)
          break
      }

      return () => {
        resourceTag.removeEventListener("load", onLoaded)
        resourceTag.removeEventListener("error", onError)
      }
    }
    return
  }, [src, type, propValues])

  return [state.loaded, state.error]
}

export default useResource
export { cachedResources }
