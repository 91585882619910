import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const PlayButton: React.FC<Props> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M72 48L36 69V27l36 21z" fill="#fff" />
    <circle cx="48" cy="48" r="46" stroke="#fff" fill="none" strokeWidth="4" />
  </SvgIcon>
)

export default PlayButton
