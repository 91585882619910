import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import Typography from "common/Typography"
import CowBodyIcon from "icons/FenceCalculator/CowBodyIcon"
import HorseBodyIcon from "icons/FenceCalculator/HorseBodyIcon"
import SheepBodyIcon from "icons/FenceCalculator/SheepBodyIcon"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"
import AlertStepper from "./AlertStepper"

type SelectAnimalProps = {
  onAnimalSelected: () => void
  onResetStepper: () => void
  confirmChangeOpen: boolean
  onSetConfirmChangeOpen: (isConfirmChangeOpen: boolean) => void
}

const SelectAnimal = ({
  onAnimalSelected,
  onResetStepper,
  confirmChangeOpen,
  onSetConfirmChangeOpen
}: SelectAnimalProps) => {
  const classes = useStyles()

  const {
    animals,
    selectedAnimal,
    setSelectedAnimal,
    selectedWire,
    fenceCalculatorLabels
  } = useFenceCalculatorStore()

  const selectAnimal = (animal: string) => {
    if (Object.keys(selectedWire).length !== 0) {
      onSetConfirmChangeOpen(true)
      return
    }
    setSelectedAnimal(animal)
    onAnimalSelected()
  }

  const getAnimalIcon = (animalType: string) => {
    switch (animalType) {
      case "Cow":
        return (
          <Box display="flex" flexDirection="column">
            <CowBodyIcon style={{ fontSize: 50 }} />
            <Typography isDarkTheme bold variant="label">
              {fenceCalculatorLabels.animalCow}
            </Typography>
          </Box>
        )

      case "Horse":
        return (
          <Box display="flex" flexDirection="column">
            <HorseBodyIcon style={{ fontSize: 50 }} />
            <Typography isDarkTheme bold variant="label">
              {fenceCalculatorLabels.animalHorse}
            </Typography>
          </Box>
        )

      // Sheep selection is unavailable for MVP version
      case "Sheep":
        return (
          <Box display="flex" flexDirection="column">
            <SheepBodyIcon style={{ fontSize: 50 }} />
            <Typography isDarkTheme bold variant="label">
              {fenceCalculatorLabels.animalSheep}
            </Typography>
          </Box>
        )
      default:
        return
    }
  }

  return (
    <Box display="flex" flexDirection="column" className={classes.root}>
      <Typography className={classes.stepHeading}>
        {fenceCalculatorLabels.animalDescription}
      </Typography>
      <Box p={2}>
        <Grid container spacing={2}>
          {animals.map(({ animal }, idx) => (
            <Grid item xs={6} key={`${animal} + ${idx}`}>
              <Button
                fullWidth
                className={classNames(classes.button, {
                  [classes.selected]: selectedAnimal === animal
                })}
                disabled={confirmChangeOpen}
                onClick={() => selectAnimal(animal)}
              >
                {getAnimalIcon(animal)}
              </Button>
            </Grid>
          ))}
        </Grid>
        <AlertStepper
          alertMessage={fenceCalculatorLabels.animalChangeWarning}
          confirmButtonText={fenceCalculatorLabels.animalChangeConfirm}
          cancelButtonText={fenceCalculatorLabels.cancel}
          onResetStepper={onResetStepper}
          confirmChangeOpen={confirmChangeOpen}
          onSetConfirmChangeOpen={onSetConfirmChangeOpen}
        />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    root: {
      padding: spacing(5, 0),
      [breakpoints.up("sm")]: {
        maxWidth: 654,
        margin: "0 auto"
      }
    },
    stepHeading: {
      margin: spacing(0, 2, 1),
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
      color: themeColors.black
    },
    button: {
      height: 124
    },
    selected: {
      background: themeColors.primaryBlue
    }
  })
)

export default SelectAnimal
