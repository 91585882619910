import request from "@starrepublic/epi/api/request"

import { AnonymousShoppingList } from "store/AnonymousShoppingListStore"

export type UpdateListArticle = {
  articleNumber: string
  quantity: number
}

export type SendModel = {
  fromName: "string"
  fromEmail: "string"
  fromPhone: "string"
  subject: "string"
  message: "string"
  toEmail: "string"
}

export type SendToSelfModel = {
  toEmail: string
}

const anonymousShoppingList = {
  getShoppingList: () =>
    request.get<AnonymousShoppingList>(`/shoppinglists/anonymous`),
  updateShoppingList: (_: UpdateListArticle) => {
    return request.put(`/shoppinglists/anonymous/`, _)
  },
  addToShoppingList: (_: UpdateListArticle) => {
    return request.post(`/shoppinglists/anonymous/`, _)
  },
  deleteShoppingList: () => request.delete(`/shoppinglists/anonymous`),
  savePDF: () =>
    request
      .get(`/shoppinglists/anonymous/pdf`, {
        responseType: "arraybuffer"
      })
      .then((response) => {
        const blob = new Blob([response], {
          type: "application/pdf",
          encoding: "UTF-8"
        } as any)

        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(blob)
        link.download = "DelavalShoppingList.pdf"
        link.click()
      }),
  send: (sendModel: SendModel) => {
    return request.post(`/shoppinglists/anonymous/send`, sendModel)
  },
  sendToSelf: (toEmail: SendToSelfModel) => {
    return request.post(`/shoppinglists/anonymous/sendtoself`, toEmail)
  }
}

export default anonymousShoppingList
