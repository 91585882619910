import { useState } from "react"
import { useTranslation } from "react-i18next"
import XhtmlString from "@starrepublic/epi/cms/components/XhtmlString"
import useStore from "global-hook-store"

import Card from "@material-ui/core/Card"
import Modal from "@material-ui/core/Modal"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import siteSettingStore from "store/SiteSettingStore"

const EmailTermsModal: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    state: { terms }
  } = useStore(siteSettingStore)
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Typography variant="body2">
        {t("checkout.newsletter_text")}
        <span onClick={handleClick} className={classes.link}>
          {t("checkout.newsletter_modal_entry")}
        </span>
      </Typography>

      <Modal
        aria-labelledby="email-terms"
        aria-describedby="read-terms-of-use-here"
        open={isOpen}
        onClose={handleClose}
      >
        <Card className={classes.modal}>
          <Typography variant="body2" component="div">
            <XhtmlString
              content={terms.newsletterTerms}
              className={classes.htmlString}
            />
          </Typography>
        </Card>
      </Modal>
    </>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, common: { gridSpacing, themeColors } }) => ({
    root: {},
    modal: {
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-50%)",
      maxWidth: 1000,
      outline: 0,
      width: "100%",
      borderRadius: 2,
      padding: gridSpacing,
      [breakpoints.down("sm")]: {
        top: "10%",
        maxWidth: 650
      }
    },
    link: {
      color: themeColors.primaryCyan,
      cursor: "pointer",
      paddingLeft: 4,
      paddingRight: 4,
      "&:hover": {
        textDecoration: "underline"
      }
    },
    htmlString: {
      "& a": {
        color: themeColors.primaryCyan
      }
    }
  })
)

export default EmailTermsModal
