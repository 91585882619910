import { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { handlePdfTracker } from "services/StarGA"

//The useOptimizelyLinkResolver hook adds event listeners to relative links within a given element, handling navigation and tracking PDF link clicks.

const linkClass = "relativeLink" // Set by Optimizely on all relative links
const alreadyHandledClass = `js-${linkClass}-handled` // Avoids setting listener multiple times

const useOptimizelyLinkResolver = (ref: React.RefObject<HTMLElement>) => {
  const navigate = useNavigate()

  const handleLinkClick = useCallback(
    (e: Event) => {
      e.preventDefault()

      const href = (e.target as HTMLAnchorElement).getAttribute("href")

      if (href) {
        navigate(href)
      }
    },
    [navigate]
  )

  const addLinkListeners = useCallback(() => {
    if (!ref.current) {
      return
    }

    const allRelativeLinks = ref.current.querySelectorAll(
      `.${linkClass}:not(.${alreadyHandledClass})`
    )

    allRelativeLinks.forEach((link) => {
      const href = link.getAttribute("href")
      if (!href) {
        return
      }

      // Check if the link's href is of a type that should not be handled
      const isUnsupportedType = /^mailto:|^tel:|^ftp:|^javascript:/i.test(href)

      const linkStartsWithHyperText = href.startsWith("https://")
      const linkEndsWithPdf = href.endsWith(".pdf")

      // Handle PDF tracking link clicks.
      if (linkEndsWithPdf) {
        link.addEventListener("click", handlePdfTracker)
      }

      // Add event listener if it's not a hyperlink or a PDF, and not an unsupported type
      if (!linkStartsWithHyperText && !linkEndsWithPdf && !isUnsupportedType) {
        link.classList.add(alreadyHandledClass)
        link.addEventListener("click", handleLinkClick)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleLinkClick, handlePdfTracker, ref, linkClass, alreadyHandledClass])

  useEffect(
    () => {
      const node = ref.current
      const mutationObserver = new MutationObserver(addLinkListeners)

      if (!node) {
        return
      }

      mutationObserver.observe(node, {
        childList: true
      })

      addLinkListeners()

      return () => {
        mutationObserver.disconnect()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current, addLinkListeners]
  )
}

export default useOptimizelyLinkResolver
