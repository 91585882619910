import { CSSProperties, memo } from "react"
import XhtmlString from "@starrepublic/epi/cms/components/XhtmlString"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

import { addClassNameToImages } from "utils/imageUtils"

import themeColors from "theme/colors"
import { customTypography, fonts } from "theme/typography"

type Props = {
  content: string
  style?: CSSProperties | undefined
  sectionTheme?: string
}
type StyleProps = {
  makeHeadingLightColor: boolean
  validateLightColorWithDarkTheme: boolean
}

const ThemeXhtmlString = ({ content, style, sectionTheme = "" }: Props) => {
  const validateDarkColor =
    sectionTheme === themeColors.colorBaseBlue700 ||
    sectionTheme === themeColors.colorBaseBlue500

  const validateLightColorWithDarkTheme =
    sectionTheme === themeColors.colorBaseCyan700

  const classes = useStyles({
    makeHeadingLightColor: validateDarkColor,
    validateLightColorWithDarkTheme
  })()

  // Modify the content to add className to all <img> elements
  const modifiedContent = addClassNameToImages(content, classes.imageContain)

  return (
    <div
      className={classNames(classes.root, {
        [classes.setLightFontColor]: validateDarkColor
      })}
      style={style}
    >
      <XhtmlString content={modifiedContent} />
    </div>
  )
}

const useStyles = ({
  makeHeadingLightColor,
  validateLightColorWithDarkTheme
}: StyleProps) =>
  makeStyles(({ breakpoints, spacing, common: { themeColors } }) => ({
    root: {
      "& h1": {
        ...customTypography.headline1,
        margin: spacing(0, 0, 2, 0),
        color: makeHeadingLightColor
          ? "inherit"
          : validateLightColorWithDarkTheme
            ? themeColors.colorBaseBlue800
            : themeColors.colorBaseBlue700
      },
      "& h2": {
        ...customTypography.headline2,
        margin: spacing(0, 0, 2, 0),
        color: makeHeadingLightColor
          ? "inherit"
          : validateLightColorWithDarkTheme
            ? themeColors.colorBaseBlue800
            : themeColors.colorBaseBlue700
      },
      "& h3": {
        ...customTypography.headline3,
        margin: spacing(0, 0, 2, 0),
        color: makeHeadingLightColor
          ? "inherit"
          : validateLightColorWithDarkTheme
            ? themeColors.colorBaseBlue800
            : themeColors.colorBaseBlue700
      },
      "& h4": {
        ...customTypography.headline4,
        margin: spacing(0, 0, 2, 0),
        color: makeHeadingLightColor
          ? "inherit"
          : validateLightColorWithDarkTheme
            ? themeColors.colorBaseBlue800
            : themeColors.colorBaseBlue700
      },
      "& h5": {
        ...customTypography.headline5,
        margin: spacing(0, 0, 2, 0),
        color: "inherit",
        [breakpoints.up("md")]: {
          fontSize: 24
        }
      },
      "& h6": {
        ...customTypography.headline6,
        margin: spacing(0, 0, 2, 0),
        color: "inherit"
      },
      "& p": {
        ...customTypography.body1,
        color: "inherit",
        marginTop: spacing(2)
      },
      "& p:nth-child(1)": {
        marginTop: spacing(0)
      },
      "& strong": {
        fontWeight: 700
      },
      "& .paragraph-large": {
        ...customTypography.body,
        color: "inherit"
      },
      "& ul": {
        display: "inline-block",
        paddingLeft: spacing(4),
        listStyleImage:
          "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogIDxwYXRoIGQ9Ik0yIDZsNCA0IDgtOCIgc3Ryb2tlPSIjRjMwIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPg0KPC9zdmc+)",
        "& li": {
          ...customTypography.body1,
          color: "inherit",
          paddingLeft: spacing(2),
          paddingBottom: spacing(2)
        }
      },
      "& ol": {
        paddingLeft: spacing(4),
        "& li": {
          ...customTypography.body1,
          color: "inherit",
          paddingLeft: spacing(2),
          paddingBottom: spacing(2)
        }
      },
      "& .align-center": {
        textAlign: "center"
      },
      "& .align-left": {
        textAlign: "left"
      },
      "& .align-right": {
        textAlign: "right"
      },
      "& .align-justify": {
        textAlign: "justify"
      },
      "& tr": {
        [breakpoints.down("xs")]: {
          display: "flex",
          flexDirection: "column"
        }
      },
      "& td": {
        [breakpoints.down("xs")]: {
          paddingBottom: spacing(3)
        }
      },
      "& a": {
        color: themeColors.primaryCyan,
        fontFamily: fonts.helveticaNeue.fontFamily
      },
      "& pre": {
        whiteSpace: "break-spaces"
      }
    },
    setLightFontColor: {
      color: themeColors.white
    },
    imageContain: {
      width: "100%",
      height: "100%"
    }
  }))

export default memo(ThemeXhtmlString)
