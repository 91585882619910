import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"

const Printer: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M18.5 10a.5.5 0 11-1 0 .5.5 0 011 0z" stroke="#00A6FF" />
    <path
      d="M6 3V1h12v2M6 18H1v-8a4 4 0 014-4h14a4 4 0 014 4v8h-5"
      stroke="#00A6FF"
      strokeWidth="2"
      fill="none"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M18 14H6v9h12v-9z"
      stroke="#00A6FF"
      fill="none"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </SvgIcon>
)

export default Printer
