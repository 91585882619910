import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const CowBodyIcon: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 63 50">
    <path
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M57.95 25.92c.34-1.1.97-2.33.96-2.92 0-.24-.42-.19-.46-.19-1.08-.04-2.62 1.88-3.24 1.17-1.03-1.17-8.35-9.87-11.35-12.86-.44-.44-2.07-1.07-4.14-1.07-10.02 0-16.62-.05-26.64-.05h-1.26c-7.53.18-7.14 5.2-7.75 12.3m0 0c-2.55.53-2.6 4.36-1.72 6.57 2.5-1.38 2.67-2.6 1.72-6.57Zm47.72 6.13a8.9 8.9 0 0 0 2.4 6.75c.55.59 1.18 1.09 1.82 1.58.85.66 1.74 1.3 2.77 1.6 1.04.3 2.24.22 3.05-.48l-.48-8.27c-.02-.27-.05-.56-.23-.75-.17-.17-.43-.2-.6-.37-.4-.37-.09-1.04.28-1.44.37-.4.4-.94.64-1.42.4-.76-.14-.83-1.54-.47-.68.17-1.34.41-1.96.75M10.41 10.15a29.7 29.7 0 0 0-1.5 7.56c-.38 4 1.84 6.9 1.63 8.55-.17 1.3-1.88 2.52-3.5 6.12-.36 3.98.02 10.61 0 13.07 0 .47.38.85.85.85h2.13c.52 0 .94-.43.93-.95l-.1-8.31c.01-.32.16-.62.4-.82l6.95-5.83c.26-.21.59-.33.93-.33H34.3c.43 0 .82.2 1.08.52.5.63 1.44 1.97 1.86 2.58.15.2.23.43.26.68l1.28 11.74c.05.42.42.74.84.72l1.82-.07a.8.8 0 0 0 .78-.78l.25-9.5c.02-.38.12-.75.31-1.09l1.87-3.28c.2-.37.6-.6 1.02-.6h6.09m-39.75 4.74c.86-.03 1.26-.06 2.12-.1v1.62c-.02.45.23.76.68.77.55 0 1.11.03 1.13-.47.02-.5.04-1.47 0-1.97h2.18c.05.58 0 1.21.05 1.79.01.16.07.23.16.36.22.32 1.34.29 1.57-.02.23-.31.24-.73.23-1.11-.02-.4-.05-.8.13-1.14.25-.46.68-.64 1.15-.86 1.63-.76 2.53-2.28 3.02-4.02m-7.38 10.41 1.4 4.86c.1.3.39.5.7.5h1.89c.48 0 .83-.45.7-.93l-1.12-3.94m14.2-3.8-2.04 7.63c-.1.3-.38.5-.7.5h-1.88a.73.73 0 0 1-.71-.92l2.85-11.93"
    />
  </SvgIcon>
)

export default CowBodyIcon
