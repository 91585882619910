import classNames from "classnames"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Spinner from "common/Spinner"

const OnLoadSpinner = () => {
  const classes = useStyles()

  return (
    <>
      <Grid className={classNames(classes.spinnerContainer, {})}>
        <Spinner className={classes.spinner} fade={true} />
      </Grid>
    </>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  spinnerContainer: {
    position: "fixed",
    zIndex: 1,
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
  },
  spinner: {
    position: "fixed",
    left: "calc(50% - 27px)",
    top: "25%",
    boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
    backgroundColor: themeColors.white,
    boxSizing: "content-box",
    padding: spacing(1),
    borderRadius: spacing(4)
  }
}))

export default OnLoadSpinner
