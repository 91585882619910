import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import XhtmlString from "@starrepublic/epi/cms/components/XhtmlString"
import useStore from "global-hook-store"

import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { scrollToElement } from "utils/ui/scroll"

import CheckoutSectionHeader from "../common/CheckoutSectionHeader"
import ContainerWrapper from "../common/ContainerWrapper"

import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import SummaryCow from "icons/SummaryCow"
import { DeliveryOption } from "models/Checkout"
import { ScrollContainers } from "pages/Checkout/Checkout"
import { checkoutStepAction } from "services/StarGA"
import CartStore from "store/CartStore"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import siteSettingStore from "store/SiteSettingStore"
import { themeColors } from "theme"

const OrderSummary: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { itemsCount }
  } = useStore(CartStore)

  const {
    preparedCheckout,
    confirmedPayment,
    finalizePayment,
    finalizeCheckout,
    selectedSubPaymentOption,
    selectedPaymentOption,
    confirmedOrder,
    computed: { availableDeliveryOptions }
  } = useCheckoutPaymentStore()

  const {
    state: { terms }
  } = useStore(siteSettingStore)

  const [orderSummaryReached, setOrderSummaryReached] = useState(false)

  const handlePlaceOrder = async () => {
    if (selectedPaymentOption === "Adyen") {
      const paymentType = selectedSubPaymentOption
        .replace("_", "")
        .toLowerCase()

      await finalizePayment(paymentType)
    } else {
      await finalizeCheckout()
    }
  }

  useEffect(
    () => {
      if (!preparedCheckout.loading && preparedCheckout.data) {
        scrollToElement(ScrollContainers.orderSummary)
        checkoutStepAction(3)
        setOrderSummaryReached(true)
      }
      if (
        !preparedCheckout.loading &&
        preparedCheckout.data === null &&
        orderSummaryReached
      ) {
        checkoutStepAction(2)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preparedCheckout.data]
  )

  const selectedDeliveryOption =
    availableDeliveryOptions &&
    availableDeliveryOptions.find((o: DeliveryOption) => {
      return (
        o.methodId ===
        (preparedCheckout.data && preparedCheckout.data.cart.deliveryOptionId)
      )
    })

  return (
    <div id={ScrollContainers.orderSummary} className={classes.root}>
      <ContainerWrapper isActive={preparedCheckout.data ? true : false}>
        <CheckoutSectionHeader
          title={t("checkout.summary_title")}
          Icon={SummaryCow}
        />
        {preparedCheckout.data ? (
          <>
            <Grid container className={classes.summaryContainer}>
              <Grid item xs={12} className={classes.row}>
                <div className={classes.textContainer}>
                  <div className={classes.leftText}>
                    <Typography variant={"body1"} className={classes.bold}>
                      {t("common.subtotal")}
                    </Typography>
                  </div>
                  <div className={classes.leftText2}>
                    <Typography variant={"body1"}>
                      {itemsCount} {t("common.items_total")}
                    </Typography>
                  </div>
                </div>
                <div className={classes.rightText}>
                  <Typography variant="body1">
                    {preparedCheckout.data.cart.subTotalString}
                  </Typography>
                </div>
              </Grid>
              {preparedCheckout.data.cart.discountTotal > 0 ? (
                <Grid item xs={12} className={classes.row}>
                  <div className={classes.textContainer}>
                    <div className={classes.leftText}>
                      <Typography variant="body1" className={classes.bold}>
                        {t("checkout.discount")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.rightText}>
                    <Typography variant="priceDiscount">
                      {`-${preparedCheckout.data.cart.discountTotalString}`}
                    </Typography>
                  </div>
                </Grid>
              ) : null}
              <Grid item xs={12} className={classes.row}>
                <div className={classes.textContainer}>
                  <div className={classes.leftText}>
                    <Typography variant={"body1"} className={classes.bold}>
                      {t("checkout.delivery_form_title")}
                    </Typography>
                  </div>
                  <div className={classes.leftText2}>
                    <Typography variant={"body1"}>
                      {selectedDeliveryOption &&
                        selectedDeliveryOption.displayName}
                    </Typography>
                  </div>
                </div>
                <div className={classes.rightText}>
                  <Typography variant={"body1"}>
                    {preparedCheckout.data.cart.shippingTotalString}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} className={classes.row}>
                <div className={classes.textContainer}>
                  <div className={classes.leftText}>
                    <Typography variant={"body1"} className={classes.bold}>
                      {t("checkout.total_excluding_vat")}
                    </Typography>
                  </div>
                </div>
                <div className={classes.rightText}>
                  <Typography variant={"body1"}>
                    {preparedCheckout.data.cart.totalString}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} className={classes.row}>
                <div className={classes.textContainer}>
                  <div className={classes.leftText}>
                    <Typography variant={"body1"} className={classes.bold}>
                      {t("checkout.vat")}
                    </Typography>
                  </div>
                </div>
                <div className={classes.rightText}>
                  <Typography variant={"body1"}>
                    {preparedCheckout.data.cart.properties.sapVatAmountString}
                  </Typography>
                </div>
              </Grid>

              <Divider className={classes.border} />

              <Grid item xs={12} className={classes.row}>
                <div className={classes.textContainer}>
                  <div className={classes.leftText}>
                    <Typography variant={"body1"} className={classes.bold}>
                      {t("checkout.total_including_vat")}
                    </Typography>
                  </div>
                </div>
                <div className={classes.rightText}>
                  <Typography variant={"body1"}>
                    {
                      preparedCheckout.data.cart.properties
                        .totalCostIncVatString
                    }
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <div className={classes.buttonContainer}>
              <Button
                id="complete-checkout-button"
                disabled={confirmedOrder.loading || confirmedPayment.loading}
                className={classes.button}
                type="button"
                onClick={handlePlaceOrder}
              >
                {(confirmedOrder.loading || confirmedPayment.loading) && (
                  <ButtonLoaderIcon className={classes.buttonSpinner} />
                )}
                {t("checkout.place_order")}
              </Button>
              <Typography
                component="div"
                variant={"body2"}
                className={classes.htmlString}
              >
                <XhtmlString content={terms.termsAndConditions} />
              </Typography>
            </div>
          </>
        ) : null}
      </ContainerWrapper>
    </div>
  )
}

const useStyles = makeStyles(({ spacing, common, breakpoints }) => ({
  root: {
    width: "100%"
  },
  summaryContainer: {
    width: "100%",
    position: "relative",
    padding: common.gridSpacing,
    borderTop: `1px solid ${common.themeColors.lightGray}`
  },
  row: {
    display: "flex",
    width: "100%"
  },
  textContainer: {
    flexWrap: "wrap",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    [breakpoints.up("sm")]: {
      flexWrap: "nowrap"
    }
  },
  leftText: {
    width: "100%",
    [breakpoints.up("sm")]: {
      width: "auto"
    }
  },
  leftText2: {
    width: "100%",
    marginLeft: spacing(1)
  },
  rightText: {
    width: "100%",
    display: "flex",
    textAlign: "right",
    justifyContent: "flex-end"
  },
  border: {
    width: "100%",
    marginTop: common.gridSpacing,
    marginBottom: common.gridSpacing,
    backgroundColor: "transparent",
    borderBottom: `1px dashed ${common.themeColors.lightGray}`
  },
  bold: {
    fontWeight: "bold"
  },
  totalItems: {
    fontWeight: "bold",
    display: "flex"
  },
  color: {
    color: common.themeColors.darkGray
  },
  legalText: {
    backgroundColor: common.themeColors.secondaryBeige,
    padding: common.gridSpacing
  },
  formControlLabel: {
    marginRight: 0
  },
  buttonContainer: {
    width: "100%",
    padding: common.gridSpacing,
    paddingTop: 0,
    [breakpoints.down("sm")]: {
      marginTop: spacing(1)
    }
  },
  buttonDisabled: {
    background: common.themeColors.lightGray
  },
  button: {
    background: common.themeColors.primaryCyan,
    color: themeColors.white,
    width: "100%",
    padding: 16,
    borderRadius: 2,
    fontSize: 16,
    textTransform: "none",
    "&:disabled": {
      backgroundColor: themeColors.gray,
      color: themeColors.white
    }
  },
  buttonCheck: {
    marginLeft: spacing(1)
  },
  link: {
    color: common.themeColors.primaryCyan,
    cursor: "pointer",
    paddingLeft: 4,
    paddingRight: 4,
    "&:hover": {
      textDecoration: "underline"
    }
  },
  termsText: {
    paddingTop: spacing(2)
  },
  htmlString: {
    "& a": {
      color: themeColors.primaryCyan
    }
  },
  buttonSpinner: {
    marginRight: spacing(1)
  }
}))

export default OrderSummary
