import { FC } from "react"
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from "react-google-maps"
import useStore from "global-hook-store"

import DealerSearchStore from "store/DealerSearchStore"
import mapStyles from "./mapStyles"

const GoogleMapsDealerConfig: FC = () => {
  const markedIcon = {
    url: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDE3MiAxNzIiPg0KICA8ZyBmaWxsPSJub25lIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOm5vcm1hbCI+DQogICAgPHBhdGggZD0iTTAgMTcyVjBoMTcydjE3MnoiLz4NCiAgICA8cGF0aCBkPSJNODYgM0M1NiAzIDMxIDI4IDMxIDU4YzAgNDkgNTAgMTA3IDUyIDEwOWwzIDIgMy0yYzItMiA1Mi02MSA1Mi0xMDkgMC0zMC0yNS01NS01NS01NXptMCAzOGEyMSAyMSAwIDExMCA0MiAyMSAyMSAwIDAxMC00MnoiIGZpbGw9IiNmMzAiLz4NCiAgPC9nPg0KPC9zdmc+",
    scaledSize: new google.maps.Size(32, 32)
  }

  const {
    state: { selectedDealer }
  } = useStore(DealerSearchStore)

  if (!selectedDealer) return null

  return (
    <GoogleMap
      zoom={15}
      center={{
        lat: selectedDealer.latitude,
        lng: selectedDealer.longitude
      }}
      defaultOptions={{
        styles: mapStyles as any,
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        panControl: false,
        zoomControl: true,
        rotateControl: false,
        fullscreenControl: false,
        draggable: false
      }}
    >
      <Marker
        options={{ optimized: false }}
        visible={true}
        icon={markedIcon}
        position={{
          lat: selectedDealer.latitude,
          lng: selectedDealer.longitude
        }}
      />
    </GoogleMap>
  )
}

export default withScriptjs(withGoogleMap(GoogleMapsDealerConfig))
