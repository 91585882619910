export type ConditionalWrapperProps = {
  condition: boolean
  wrapper: (children: React.ReactElement) => JSX.Element
  children: React.ReactElement
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = (Props) => {
  const { condition, wrapper, children } = Props
  return condition ? wrapper(children) : children
}

export default ConditionalWrapper
