import { FastField } from "formik"

import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox"

import CheckboxCheckedForm from "icons/CheckboxCheckedForm"
import CheckboxForm from "icons/CheckboxForm"
import {
  FormikFieldProps,
  FormikTransformer,
  FormikTransformerProps
} from "./types"

const transformer: FormikTransformer<CheckboxProps> = ({
  field,
  form,
  transformValue,
  ...props
}) => {
  const { name, onChange, value } = field
  const { touched, errors, isSubmitting, setFieldValue } = form
  return {
    ...props,
    ...field,
    onChange: (e) => {
      if (transformValue) {
        setFieldValue(value, transformValue(field.name, e.target.value))
      } else if (onChange) {
        onChange(e)
      }
    },
    value: field.name,
    checked: field.value
  }
}

const CheckBoxComponent: React.FC<FormikTransformerProps<CheckboxProps>> = (
  props
) => {
  return (
    <Checkbox
      icon={<CheckboxForm />}
      checkedIcon={<CheckboxCheckedForm />}
      {...transformer(props)}
    />
  )
}

const FormikCheckbox: React.FC<FormikFieldProps<CheckboxProps>> = (props) => {
  return <FastField component={CheckBoxComponent} {...props} />
}

export default FormikCheckbox
