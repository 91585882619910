import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

import ResponsiveImage from "common/ResponsiveImage"
import { ImageProductCarouselContent } from "./types"

type Props = BlockProps<ImageProductCarouselContent>

const BLOCK_MAX_WIDTH = 1680

const positions = {
  top: "Top",
  bottom: "Bottom",
  right: "Right",
  left: "Left",
  center: "Center"
}

const ImagePosition: React.FC<Props> = ({ content }) => {
  const {
    maxNumberOfProducts,
    contentPosition,
    image,
    horizontalImageAlignment,
    overlay
  } = content

  const classes = useStyles()

  return image && contentPosition && horizontalImageAlignment ? (
    <div
      className={classNames({
        [classes.mediaContainer]:
          contentPosition.text === positions.left ||
          contentPosition.text === positions.right,
        [classes.mediaContainerTop]: contentPosition.text === positions.top,
        [classes.mediaContainerOneProduct]: maxNumberOfProducts === 1
      })}
    >
      <ResponsiveImage
        containerProps={{
          className: classes.mediaContainer
        }}
        className={classNames(classes.media, {
          [classes.mediaTop]: contentPosition.text === positions.top,
          [classes.alignmentTop]:
            horizontalImageAlignment.text === positions.top,
          [classes.alignmentCenter]:
            horizontalImageAlignment.text === positions.center,
          [classes.alignmentBottom]:
            horizontalImageAlignment.text === positions.bottom
        })}
        image={image}
        overlay={overlay}
      />
    </div>
  ) : null
}

const useStyles = makeStyles(({ breakpoints }) => ({
  alignmentTop: {
    objectPosition: "top"
  },
  alignmentCenter: {
    objectPosition: "center"
  },
  alignmentBottom: {
    objectPosition: "bottom"
  },
  mediaContainer: {
    height: "100%",
    width: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "flex-start",
    [breakpoints.down("sm")]: {
      top: 0
    }
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  mediaTop: {
    height: `calc(0.34 * ${BLOCK_MAX_WIDTH}px)`,

    width: "100%",
    maxWidth: "none",
    display: "block",
    [breakpoints.down("sm")]: {
      height: "100%"
    }
  },
  mediaContainerOneProduct: {
    width: "85%",
    [breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  mediaContainerTop: {
    width: "100%",
    height: `calc(0.34 * ${BLOCK_MAX_WIDTH}px)`,
    position: "absolute",
    top: "0",
    [breakpoints.down("sm")]: {
      height: "100%"
    }
  },
  imageContainer: {
    position: "relative",
    width: "100%"
  }
}))

export default ImagePosition
