import { MenuItem } from "@starrepublic/epi/cms/types/menu"
import get from "lodash/get"
import { create } from "zustand"
import { combine } from "zustand/middleware"

import { getZustandHelpers } from "utils/zustand-helpers"

import { CurrentMenuItemType } from "common/Header/DrawerMenu"

const _get = get
const initialStates = {
  headerHeight: 80,
  currentMenuItem: {} as CurrentMenuItemType,
  menuCurrentId: "",
  menuAncestorsId: [] as string[],
  menu: [] as MenuItem[]
}
export const useHeaderStore = create(
  combine(initialStates, (set, get) => {
    const { setAsyncState, reset, setters, resetters } = getZustandHelpers(
      set,
      get,
      initialStates
    )

    const setMenuCurrentAncestors = () => {
      const { menu } = get()
      set({
        ...getCurrentAncestors(menu)
      })
    }

    return {
      set: {
        ...setters(["headerHeight", "currentMenuItem", "menu"])
      },
      reset: {
        ...resetters([])
      },
      computed: {},
      setMenuCurrentAncestors,
      init(menu: MenuItem[]) {
        set({ menu })
        setMenuCurrentAncestors()
        const parent = findCurrentMenuItem(menu)
        set({ currentMenuItem: parent })
      }
    }
  })
)

export const useDemoStore = create(
  combine(
    {
      documents: []
    },
    (set, get) => {
      const fetchDocument = async () => {}

      return {
        fetchDocument,
        async loadDocument() {
          await fetchDocument()
        }
      }
    }
  )
)

export const findCurrentMenuPath = (
  menu: MenuItem[],
  current: string,
  parent: string = ""
): string | false => {
  for (let i = 0; i < menu.length; i++) {
    const item = menu[i]
    if (item.url === current) {
      return `${parent}[${i}]`
    } else if (item.children && item.children.length) {
      const inChildren = findCurrentMenuPath(
        item.children,
        current,
        `${parent}[${i}].children`
      )
      if (inChildren) {
        return inChildren
      }
    }
  }
  return false
}

const getCurrentAncestors = (menu: MenuItem[]) => {
  const currentPath = findCurrentMenuPath(menu, window.location.pathname)
  let menuCurrentId = ""
  const menuAncestorsId: string[] = []
  if (currentPath) {
    const pathParts = currentPath.split(".")
    const allParts = pathParts.reduce((acc: string[], current) => {
      if (acc.length) {
        acc.push(acc[acc.length - 1] + "." + current)
      } else {
        acc.push(current)
      }
      return acc
    }, [])

    allParts.forEach((accessor, index) => {
      const item = _get(menu, accessor)
      if (index === allParts.length - 1) {
        menuCurrentId = item._id
      } else {
        menuAncestorsId.push(item._id)
      }
    })
  }
  return {
    menuCurrentId,
    menuAncestorsId
  }
}

const getParent = (
  menu: MenuItem[],
  partsPath: string[],
  root: MenuItem
): CurrentMenuItemType => {
  partsPath.pop()
  const parent = partsPath.length ? _get(menu, partsPath.join(".")) : menu
  return {
    parentItem:
      partsPath.length === 1
        ? { subMenuStack: menu }
        : getParent(menu, partsPath, root),
    subMenuStack: parent.children,
    title: parent.title,
    url: parent.url,
    rootTitle: root.title,
    menuType: parent.menuType,
    id: parent._id
  }
}
export const findCurrentMenuItem = (menu: MenuItem[]): CurrentMenuItemType => {
  const path = findCurrentMenuPath(menu, window.location.pathname) || ""
  const parts = path.split(".")
  if (parts.length > 1) {
    const root = _get(menu, parts[0])
    return getParent(menu, parts, root)
  } else {
    return {
      subMenuStack: menu
    }
  }
}
