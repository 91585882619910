import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Form as FormikForm } from "formik"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import ContinueButton from "common/ContinueButton"
import CustomTextField from "common/Formik/CustomTextField"
import FormikCheckbox from "common/Formik/FormikCheckbox"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import AddToCartCheckIcon from "icons/AddToCartCheckIcon"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import NewsletterModalStore, {
  NewsletterStatusResponse
} from "store/NewsletterModalStore"
import SiteSettingsStore from "store/SiteSettingStore"

const NewsletterModalForm: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [acceptedNewsLetterTerms, setAcceptedNewsLetterTerms] = useState(false)
  const isXsDown = useMediaQuery(({ breakpoints }) => breakpoints.down("xs"))

  const {
    actions: { reset },
    state: { result }
  } = useStore(NewsletterModalStore)

  const {
    state: { displayNewsletterNameFields, newsletterConfirmText }
  } = useStore(SiteSettingsStore)

  const errorStatus = result.error as NewsletterStatusResponse

  const handleAcceptNewsLetter = (e: React.MouseEvent) => {
    setAcceptedNewsLetterTerms(!acceptedNewsLetterTerms)
  }

  useEffect(() => {
    if (!newsletterConfirmText) {
      setAcceptedNewsLetterTerms(true)
    }
  }, [newsletterConfirmText])

  return (
    <div className={classes.root}>
      {result.data && result.data.statusCode === 200 ? (
        <Grid>
          {result.data && (
            <Typography variant="body2" className={classes.message}>
              {result.data && result.data.message}
            </Typography>
          )}
        </Grid>
      ) : (
        <FormikForm data-form-id="newsletter-form" noValidate>
          <Grid
            container
            spacing={displayNewsletterNameFields || isXsDown ? 2 : 0}
            className={classNames({
              [classes.centerContent]: !displayNewsletterNameFields
            })}
          >
            {displayNewsletterNameFields && (
              <>
                <Grid item xs={6} sm={6} md={3}>
                  <CustomTextField
                    name="firstName"
                    label={t("checkout.first_name_input_label")}
                    type="text"
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={3}>
                  <CustomTextField
                    name="lastName"
                    label={t("checkout.last_name_input_label")}
                    type="text"
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={displayNewsletterNameFields ? 9 : 5} md={4}>
              <CustomTextField
                required
                name="email"
                label={t("account.email_label")}
                type="text"
              />
            </Grid>

            <Grid item xs={12} sm={3} md={displayNewsletterNameFields ? 2 : 1}>
              <ContinueButton
                onClick={() => reset()}
                className={classes.button}
                type="submit"
                disabled={result.loading || !acceptedNewsLetterTerms}
              >
                {result.loading ? (
                  <ButtonLoaderIcon />
                ) : result.data && result.data.statusCode === 200 ? (
                  <>
                    {t("newsletter.newsletter_sent")}
                    <AddToCartCheckIcon className={classes.icon} />
                  </>
                ) : (
                  t("common.contact_me_modal_button_text")
                )}
              </ContinueButton>
            </Grid>
          </Grid>
          <Grid>
            {(result.data || errorStatus) && (
              <Typography
                variant="body2"
                className={classNames(classes.message, {
                  [classes.error]: result.error
                })}
              >
                {result.data && result.data.message}
                {errorStatus && errorStatus.message}
              </Typography>
            )}
          </Grid>
          {newsletterConfirmText && (
            <Grid
              className={classes.newsLetterContainer}
              container
              justifyContent={
                displayNewsletterNameFields ? "flex-start" : "center"
              }
            >
              <Grid
                xs={12}
                sm={displayNewsletterNameFields ? 12 : 8}
                md={displayNewsletterNameFields ? 12 : 5}
                item
              >
                <Grid container alignItems="flex-start" wrap="nowrap">
                  <FormikCheckbox
                    name="newsletterAccepted"
                    color="default"
                    checked={acceptedNewsLetterTerms}
                    onClick={handleAcceptNewsLetter}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    className={classes.checkBox}
                  />
                  {newsletterConfirmText && (
                    <div className={classes.checkboxText}>
                      <ThemeXhtmlString content={newsletterConfirmText} />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
        </FormikForm>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    button: {
      margin: 0,
      height: "100%",
      width: "100%",
      padding: 0,
      maxHeight: 55,
      color: themeColors.primaryCyan,
      [breakpoints.down("sm")]: {
        padding: spacing(3)
      },
      "& span": {
        display: "flex",
        alignItems: "center"
      }
    },
    root: {
      padding: `${spacing(3)}px 0`,
      width: "100%",
      "& .MuiOutlinedInput-root": {
        backgroundColor: themeColors.white
      }
    },
    icon: {
      marginLeft: spacing(1)
    },
    centerContent: {
      justifyContent: "center"
    },
    error: {
      color: themeColors.primaryRed
    },
    message: {
      paddingTop: spacing(2),
      textAlign: "center",
      width: "100%"
    },
    checkBox: {
      padding: spacing(0, 1, 0, 0)
    },
    checkboxText: {
      color: themeColors.darkGray
    },
    newsLetterContainer: {
      marginTop: spacing(2)
    }
  })
)

export default NewsletterModalForm
