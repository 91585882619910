import Box from "@material-ui/core/Box"
import { useTheme } from "@material-ui/core/styles"

type Props = {
  display: string
  className?: string
}

const ContentWrapper: React.FC<Props> = (props) => {
  const { display, children } = props
  const theme = useTheme()

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      paddingTop={display.startsWith("full") ? 3 : 0}
    >
      <Box
        width="100%"
        maxWidth={{
          xs: "auto",
          sm: theme.breakpoints.values.md,
          lg: theme.common.pageMaxWidth
        }}
        px={{
          xs: display.startsWith("full" || "auto") ? 3 : 0,
          md: display.startsWith("full" || "auto") ? 1.5 : 0
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default ContentWrapper
