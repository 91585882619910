type Props = {
  width?: number | string
  height?: number | string
  className?: string
}

const ArrowCyan: React.FC<Props> = (props) => (
  <svg
    {...props}
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.3 0.299988L7.9 1.69999L12.2 5.99999H0V7.99999H12.2L7.9 12.3L9.3 13.7L16 6.99999L9.3 0.299988Z"
      fill="#00A6FF"
    />
  </svg>
)

export default ArrowCyan
