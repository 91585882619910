import useStore from "global-hook-store"

import Badge from "@material-ui/core/Badge"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import BulletList from "icons/BulletList"
import AnonymousShoppingListStore from "store/AnonymousShoppingListStore"

const ShowShoppingListButton: React.FC = () => {
  const classes = useStyles()

  const {
    state: { shoppingList }
  } = useStore(AnonymousShoppingListStore)

  const items = shoppingList.data

  const totalItems =
    items &&
    items.articles &&
    items.articles.reduce((acc, article) => acc + article.quantity, 0)

  return (
    <div className={classes.root}>
      <Badge
        badgeContent={
          totalItems && (
            <Typography variant="smallText" className={classes.badgeContent}>
              {totalItems}
            </Typography>
          )
        }
        classes={{
          badge: classes.badge
        }}
      >
        <BulletList />
      </Badge>
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    fontSize: 16
  },
  badge: {
    backgroundColor: themeColors.primaryRed
  },
  badgeContent: {
    color: themeColors.white,
    fontWeight: "bold"
  }
}))

export default ShowShoppingListButton
