import { ContentInfo, PropertyInfo } from "@starrepublic/epi/cms/types/content"

export enum Display {
  auto = "auto",
  full = "full",
  full_alt = "full_alt",
  half = "half",
  two_third = "two_third",
  one_third = "one_third"
}

type DisplayProps = {
  auto: boolean
  full: boolean
  full_alt: boolean
  half: boolean
  twoThird: boolean
  oneThird: boolean
}

export const blockDisplay = (
  blockInfo: (PropertyInfo & ContentInfo) | undefined
): DisplayProps => {
  const blockInfoDisplay = blockInfo!["_display"]

  return {
    auto: blockInfoDisplay.startsWith(Display.auto),
    full: blockInfoDisplay.startsWith(Display.full),
    full_alt: blockInfoDisplay.startsWith(Display.full_alt),
    half: blockInfoDisplay.startsWith(Display.half),
    twoThird: blockInfoDisplay.startsWith(Display.two_third),
    oneThird: blockInfoDisplay.startsWith(Display.one_third)
  }
}
