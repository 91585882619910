import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import ButtonBase from "@material-ui/core/ButtonBase"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grid from "@material-ui/core/Grid"
import Grow from "@material-ui/core/Grow"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"

import LinkButton from "common/LinkButton"
import Typography from "common/Typography"

type subMenuNode = {
  displayName: string
  listItemsViewType: string
  title: string
  _route: string
  _id: number
}

type Props = {
  subMenuNodes: subMenuNode[]
}

const SubpageDropDown = ({ subMenuNodes }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const anchorElement = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prev) => !prev)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  return subMenuNodes.length > 0 ? (
    <Table className={classes.tableMenuDropdown}>
      <TableBody>
        <TableRow>
          <TableCell>
            <Grid>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Grid>
                  <ButtonBase
                    id="children-popper"
                    className={classes.buttonChildren}
                    onClick={handleToggle}
                    ref={anchorElement}
                  >
                    <>
                      <div className={classes.currentChildWrapper}>
                        <Typography
                          variant="body1"
                          className={classes.currentChildRoute}
                        >
                          {t("product.show_subcategories")}
                        </Typography>
                      </div>
                      {open ? (
                        <KeyboardArrowUp color="inherit" />
                      ) : (
                        <KeyboardArrowDown color="inherit" />
                      )}
                    </>
                  </ButtonBase>
                  <Popper
                    style={{ position: "relative" }}
                    open={open}
                    anchorEl={anchorElement.current}
                    transition
                    disablePortal={true}
                    className={classes.popper}
                    placement="bottom"
                  >
                    {({ TransitionProps }) => (
                      <Grow {...TransitionProps} timeout={100}>
                        <Paper className={classes.childMenu}>
                          <div>
                            <MenuList>
                              {subMenuNodes.map((menuNode, i) => (
                                <MenuItem key={i}>
                                  <LinkButton
                                    onClick={handleClickAway}
                                    link={menuNode._route}
                                  >
                                    <Typography variant="body1">
                                      {menuNode.title}
                                    </Typography>
                                  </LinkButton>
                                </MenuItem>
                              ))}
                            </MenuList>
                          </div>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              </ClickAwayListener>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  ) : null
}

const useStyles = makeStyles(
  ({ common: { themeColors }, spacing, breakpoints }) => ({
    buttonChildren: {
      position: "relative",
      border: `1px solid ${themeColors.lightGray}`,
      borderRadius: 2,
      height: 48,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      marginBottom: spacing(1)
    },
    tableMenuDropdown: {
      "& .MuiTableCell-body": {
        border: 0,
        padding: 0
      }
    },
    childMenu: {
      zIndex: 2,
      width: "100%",
      top: -8,
      position: "relative",
      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
      maxHeight: 300,
      overflow: "auto",
      [breakpoints.down("sm")]: {
        top: 0
      },
      "& ul": {
        paddingTop: 0,
        paddingBottom: 0,
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        "& li": {
          width: "100%",
          boxSizing: "inherit",
          paddingTop: spacing(2),
          paddingBottom: spacing(2)
        }
      }
    },
    popper: {
      zIndex: 2,
      width: "100%",
      padding: `0px ${spacing(2)}px`
    },
    currentChildRoute: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: spacing(2)
    },
    currentChildWrapper: {
      display: "flex",
      width: "100%"
    }
  })
)

export default SubpageDropDown
