import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const CompanyDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M17.333 26.667h-6.666m6.666 0H28V5.333H12v4m5.333 17.334v-8l-6.666-6-6.667 6v8h6.667m0 0v-5.334M28 10.667h-6.667M28 16h-6.667M16 10.667h2.667"
      fill="none"
    />
  </SvgIcon>
)

export default CompanyDeLaval
