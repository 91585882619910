import { asyncState, createStore } from "global-hook-store"

import NewsletterApi, { NewsletterPayload } from "api/newsletter"

export type NewsletterStatusResponse = {
  message: string
  statusCode: number
}

const NewsletterModalStore = createStore(
  {
    result: asyncState<NewsletterStatusResponse>()
  },
  {
    send: async (_, payload: NewsletterPayload, { asyncAction }) =>
      asyncAction("result", NewsletterApi.send(payload)),
    reset: (_, __: null, { reset }) => {
      return reset()
    }
  }
)
export default NewsletterModalStore
