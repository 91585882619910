import classNames from "classnames"

import Accordion, { AccordionProps } from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import { makeStyles } from "@material-ui/core/styles"

type Props = {
  summary: React.ReactNode
  expansionIcon: React.ReactNode
  className?: string
  summaryClassName?: string
  summaryContentClassName?: string
  detailsClassName?: string
} & AccordionProps

const Panel: React.FC<Props> = ({
  summary,
  expansionIcon,
  className,
  summaryClassName,
  summaryContentClassName,
  detailsClassName,
  expanded,
  children,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Accordion
      expanded={expanded}
      className={classNames(classes.root, className)}
      {...rest}
    >
      <AccordionSummary
        expandIcon={expansionIcon}
        classes={{
          root: summaryClassName,
          content: summaryContentClassName
        }}
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails className={detailsClassName}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    marginBottom: spacing(1),
    padding: `${spacing(1.5)}px ${spacing(3)}px`,
    background: themeColors.white,
    "&:before": {
      opacity: 0
    }
  }
}))

export default Panel
