import { BlockProps } from "@starrepublic/epi/cms"

import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"

import CustomTextField from "common/Formik/CustomTextField"

type Props = BlockProps<{
  theme: string
  label: string
  formElementId: string
  placeHolder: string
  description: string
}>

const NumberElementBlock: React.FC<Props> = ({
  content: { label, formElementId, placeHolder, description, theme }
}) => {
  const classes = useStyles()
  return (
    <Tooltip
      placement="top"
      arrow
      title={description ? description : ""}
      interactive
    >
      <div className={classes.removeArrows}>
        <CustomTextField
          {...{ label, placeHolder, theme }}
          name={formElementId}
          type="number"
          numberToString
        />
      </div>
    </Tooltip>
  )
}

const useStyles = makeStyles(() => ({
  removeArrows: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none"
    }
  }
}))

export default NumberElementBlock
