// Function to add className to all <img> elements
export const addClassNameToImages = (htmlString: string, className: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, "text/html")
  const images = doc.querySelectorAll("img")
  images.forEach((img) => {
    // Add the class name
    img.classList.add(className)
    // Attempt to set the max-width directly
    img.style.maxWidth = `${img.width}px`
    // Add lazy loading attribute
    img.setAttribute("loading", "lazy")
  })
  return doc.body.innerHTML
}
