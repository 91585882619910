import * as React from "react"

import { DynamicBlock } from "../types/content"
import { BlockResolver, ContentProps } from ".."

type PageContent = {
  title: string
  _route: string
  previewContent: DynamicBlock | any
}

type Props = ContentProps<PageContent>

const BlockPreviewPage: React.FC<Props> = ({
  content,
  pageInfo,
  propsInfo
}) => (
  <BlockResolver
    content={content.previewContent}
    pageInfo={pageInfo}
    blockInfo={propsInfo.previewContent}
  />
)

export default BlockPreviewPage
