import request from "@starrepublic/epi/api/request"

import { SearchModel } from "store/DealerSearchStore"

export type DealerPayload = {
  pageId: number
  userId?: string
  name: string
  phone?: string
  email: string
  message?: string
}

const getSearchedDealers = {
  send: (searchModel: SearchModel) =>
    request.post(`/dealers/search`, searchModel)
}

export default getSearchedDealers
