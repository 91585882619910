import { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const ReOrder: React.FC<Props> = (props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M11.1255 8C10.7584 8.94196 10.1388 9.76452 9.33473 10.3773C8.53065 10.9901 7.57323 11.3694 6.56764 11.4736C5.56205 11.5777 4.54721 11.4027 3.6346 10.9678C2.72199 10.5328 1.94692 9.85472 1.39453 9.008"
      stroke="#00A6FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.58917 4.11794L11.5 1.20711V4.44137L8.58917 4.11794Z"
      fill="#00A6FF"
      stroke="#00A6FF"
    />
    <path
      d="M0.875 3.99992C1.2421 3.05796 1.86173 2.2354 2.66581 1.62261C3.46988 1.00982 4.42731 0.630511 5.43289 0.526346C6.43848 0.42218 7.45332 0.597193 8.36593 1.03216C9.27855 1.46712 10.0536 2.1452 10.606 2.99192"
      stroke="#00A6FF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.41083 7.88206L0.5 10.7929V7.55863L3.41083 7.88206Z"
      fill="#00A6FF"
      stroke="#00A6FF"
    />
  </svg>
)

export default ReOrder
