import { ComponentType, FC } from "react"

import CmsContext, { CmsContextProps } from "./context"

const withCmsContext =
  <TOwnProps extends object = {}>(
    Component: ComponentType<TOwnProps & CmsContextProps>
  ): FC<TOwnProps> =>
  (props: TOwnProps) => (
    <CmsContext.Consumer>
      {(context) => <Component {...props} {...context} />}
    </CmsContext.Consumer>
  )

export default withCmsContext
