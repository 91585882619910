import { Fragment } from "react"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"

type StepLabelContentProps = {
  label: string
  stepCompleted: boolean
  activeStep: boolean
  stepIndex: number
  step: number
}

const StepLabelContent = ({
  stepCompleted,
  activeStep,
  stepIndex,
  step
}: StepLabelContentProps) => {
  const classes = useStyles()

  const {
    selectedAnimal,
    selectedCategory,
    totalLengthOfFence,
    numberOfWireRows,
    selectedWire,
    selectedGateInsulator,
    selectedPost,
    numberOfPosts,
    selectedGateHandle,
    numberOfCornerPosts,
    selectedInsulator,
    selectedEnergizer,
    selectedAccessories,
    categories,
    fenceCalculatorLabels,
    gateInsulatorPackagesNeeded,
    numberOfFreeStandingGateOpenings,
    numberOfGateOpeningsInCorners
  } = useFenceCalculatorStore()

  const isPermanentSelected = selectedCategory === categories[0].category

  const getLabel = (step: number) => {
    switch (step) {
      case 0:
        return fenceCalculatorLabels.animalHeading
      case 1:
        return fenceCalculatorLabels.fenceTypeHeading
      case 2:
        return fenceCalculatorLabels.fencingMaterialHeading
      case 3:
        return fenceCalculatorLabels.fencePostsHeading
      case 4:
        return fenceCalculatorLabels.cornersHeading
      case 5:
        return fenceCalculatorLabels.gatesHeading
      case 6:
        return fenceCalculatorLabels.energizerHeading
      case 7:
        return fenceCalculatorLabels.accessoriesHeading
      default:
        return ""
    }
  }

  const getSelectionSummary = (step: number) => {
    const isMainsAndBatteryEnergizer =
      selectedEnergizer.name &&
      selectedEnergizer.flags.mains &&
      selectedEnergizer.flags.battery

    switch (step) {
      case 0:
        {
          if (stepCompleted) {
            return (
              <Typography
                className={classNames(classes.label, classes.labelSelected)}
              >
                {selectedAnimal === "Cow" && fenceCalculatorLabels.animalCow}
                {selectedAnimal === "Horse" &&
                  fenceCalculatorLabels.animalHorse}
                {selectedAnimal === "Sheep" &&
                  fenceCalculatorLabels.animalSheep}
              </Typography>
            )
          }
        }
        return
      case 1:
        {
          if (stepCompleted) {
            return (
              <Typography
                className={classNames(classes.label, classes.labelSelected)}
              >
                {selectedCategory === "Permanent" &&
                  fenceCalculatorLabels.fenceTypePermanent}
                {selectedCategory === "Temporary" &&
                  fenceCalculatorLabels.fenceTypeTemporary}
              </Typography>
            )
          }
        }
        return
      case 2:
        {
          if (stepCompleted) {
            return (
              <>
                <Typography
                  className={classNames(classes.label, classes.labelSelected)}
                >
                  {selectedWire.name}
                </Typography>
                <Typography className={classes.labelSelectedInfo}>
                  {totalLengthOfFence} {fenceCalculatorLabels.metresSuffix}{" "}
                  {fenceCalculatorLabels.fence}
                </Typography>
              </>
            )
          }
        }
        return
      case 3:
        {
          if (stepCompleted) {
            return (
              <>
                {isPermanentSelected ? (
                  <Typography
                    className={classNames(classes.label, classes.labelSelected)}
                  >
                    {fenceCalculatorLabels.fencePostsWooden}
                  </Typography>
                ) : (
                  <Typography
                    className={classNames(classes.label, classes.labelSelected)}
                  >
                    {selectedPost.name}
                  </Typography>
                )}
                {selectedInsulator.name && (
                  <Typography className={classes.labelSelectedInfo}>
                    {numberOfPosts * numberOfWireRows} {selectedInsulator.name}
                  </Typography>
                )}
              </>
            )
          }
        }
        return
      case 4:
        {
          if (stepCompleted && numberOfCornerPosts > 0) {
            return (
              <Typography className={classes.labelSelectedInfo}>
                {numberOfCornerPosts} {fenceCalculatorLabels.cornersSuffix}
              </Typography>
            )
          }
        }
        return
      case 5:
        {
          const totalGateOpenings =
            numberOfFreeStandingGateOpenings + numberOfGateOpeningsInCorners
          if (stepCompleted) {
            return (
              <>
                {totalGateOpenings > 0 && (
                  <Typography
                    className={classNames(
                      classes.label,
                      classes.labelSelectedInfo
                    )}
                  >
                    {totalGateOpenings}{" "}
                    {totalGateOpenings > 1
                      ? fenceCalculatorLabels.gateOpeningsSuffix
                      : fenceCalculatorLabels.gateOpeningSuffix}
                  </Typography>
                )}

                {selectedGateInsulator.name && (
                  <Typography
                    className={classNames(
                      classes.label,
                      classes.labelSelectedInfo
                    )}
                  >
                    {selectedGateInsulator.name}{" "}
                    {gateInsulatorPackagesNeeded > 1 &&
                      ` x ${gateInsulatorPackagesNeeded}`}
                  </Typography>
                )}
                {selectedGateHandle.name && (
                  <Typography
                    className={classNames(
                      classes.label,
                      classes.labelSelectedInfo
                    )}
                  >
                    {selectedGateHandle.name}{" "}
                    {totalGateOpenings > 0 &&
                      ` x ${totalGateOpenings * numberOfWireRows}`}
                  </Typography>
                )}
              </>
            )
          }
        }
        return
      case 6:
        {
          if (stepCompleted) {
            return (
              <>
                {selectedEnergizer.name &&
                  (selectedEnergizer.variant.capacity === 0 ||
                    selectedEnergizer.variant.capacity >
                      totalLengthOfFence) && (
                    <>
                      <Typography
                        className={classNames(
                          classes.label,
                          classes.labelSelected
                        )}
                      >
                        {selectedEnergizer.name}
                      </Typography>
                      <Typography className={classes.labelSelectedInfo}>
                        {selectedEnergizer.flags.mains &&
                          fenceCalculatorLabels.energizerMains}{" "}
                        {isMainsAndBatteryEnergizer && "/ "}
                        {selectedEnergizer.flags.battery &&
                          fenceCalculatorLabels.energizerBattery}{" "}
                        {fenceCalculatorLabels.powerSuffix}
                      </Typography>
                    </>
                  )}
              </>
            )
          }
        }
        return
      case 7: {
        if (stepCompleted) {
          return (
            <>
              {selectedAccessories.length ? (
                <>
                  <Typography
                    className={classNames(classes.label, classes.labelSelected)}
                  >
                    {selectedAccessories.length}{" "}
                    {selectedAccessories.length > 1
                      ? fenceCalculatorLabels.itemsSuffix
                      : fenceCalculatorLabels.itemSuffix}
                  </Typography>
                  {selectedAccessories.map((accessory, key) => (
                    <Fragment key={`${accessory.itemCode}-${key}`}>
                      <Typography className={classes.labelSelectedInfo}>
                        {accessory.displayName} x {accessory.quantity}
                      </Typography>
                    </Fragment>
                  ))}
                </>
              ) : null}
            </>
          )
        }
        return
      }
      default:
        return
    }
  }

  return (
    <Box display="flex" flexDirection="row">
      <div
        className={classNames(classes.stepLabelDiv, {
          [classes.completedStepLabelDiv]: stepCompleted || activeStep
        })}
      />
      {stepCompleted && !activeStep ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="baseline"
          justifyContent="center"
          my={1}
        >
          <Typography
            className={classNames(classes.label, {
              [classes.activeLabel]: activeStep,
              [classes.completedLabel]: stepCompleted
            })}
          >
            {getLabel(stepIndex)}
          </Typography>
          {getSelectionSummary(stepIndex)}
        </Box>
      ) : !stepCompleted && stepIndex > step ? (
        <Box display="flex" alignItems="center">
          <Typography className={classes.label}>
            {getLabel(stepIndex)}
          </Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          <Typography
            className={classNames(classes.label, {
              [classes.activeLabel]: activeStep
            })}
          >
            {getLabel(stepIndex)}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  stepLabelDiv: {
    width: "8px",
    background: themeColors.mediumGray,
    marginRight: spacing(1.5),
    minHeight: "44px"
  },
  completedStepLabelDiv: {
    background: themeColors.primaryBlue
  },
  label: {
    color: themeColors.mediumGray,
    fontSize: "1.25rem",
    fontWeight: 700,
    lineHeight: 1.2
  },
  // labelSelected color overrides label color when step label has a value to display
  labelSelected: {
    color: themeColors.primaryBlue,
    lineHeight: 1.4,
    textAlign: "start"
  },
  labelSelectedInfo: {
    textAlign: "left",
    color: themeColors.gray,
    fontSize: ".75rem",
    fontWeight: 700,
    lineHeight: 1.4
  },
  activeLabel: {
    color: themeColors.white,
    fontSize: "1.25rem"
  },
  completedLabel: {
    fontSize: "0.875rem",
    color: themeColors.primaryCyan,
    lineHeight: 1.4
  }
}))

export default StepLabelContent
