import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { ProductContent } from "@starrepublic/epi/api/products"
import classNames from "classnames"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"

import KeyBenefits from "../KeyBenefits"
import ProductDescriptionReadMore from "../ProductDescriptionReadMore"
import ProductTitle from "../ProductTitle"
import VariantCode from "../VariantCode"

import AddToCartOrShoppingListContainer from "common/AddToCartOrShoppingListContainer"
import BadgeContainer, { Badge } from "common/BadgeContainer"
import Button from "common/Button"
import PricePerUnit from "common/Product/PricePerUnit"
import ProductPrice from "common/Product/ProductPrice"
import StockStatus from "common/Product/StockStatus"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import Product from "models/Products"
import * as gtm from "services/StarGA"
import ProductStore from "store/ProductStore"
import SiteSettingsStore from "store/SiteSettingStore"
import AccordionProductDetails from "./AccordionProductDetails"
import VariantDropDownPicker from "./VariantDropDownPicker"

type ProductInfoProps = {
  content: ProductContent<Product>
  route: string
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  content: product,
  route
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { selectedVariant }
  } = useStore(ProductStore)

  const {
    state: { enableEcommerce, enableNoWebShopWithContactDealer, links }
  } = useStore(SiteSettingsStore)

  const contactPage = links.contact && links.contact.href

  useEffect(
    () => {
      if (product && product.displayName && selectedVariant) {
        gtm.productVariantDetail(product, selectedVariant.code)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedVariant]
  )

  const image =
    product && product.imageAssets && product.imageAssets.length
      ? product.imageAssets[0]
      : product.displayImage

  const inventoryStatus =
    selectedVariant && selectedVariant.inventoryStatus === "NOT_IN_STOCK"

  const showProductDocuments =
    (product.pdfAssets && product.pdfAssets.length > 0) ||
    selectedVariant.isDangerousGoods

  return product ? (
    <>
      <Box>
        <ProductTitle
          header={product.productName}
          subHeader={product.productName2}
          selectedVariation={selectedVariant && selectedVariant.articleSize}
        />
        <KeyBenefits listItems={product.benefits} />
        <ProductDescriptionReadMore text={product.text} />
        {(product.substanceWarningText || !selectedVariant.isReturnable) && (
          <BadgeContainer>
            {product.substanceWarningText && (
              <Badge variant="warning" text={product.substanceWarningText} />
            )}
            {enableEcommerce && !selectedVariant.isReturnable && (
              <Badge variant="default" text={t("product.not_returnable")} />
            )}
          </BadgeContainer>
        )}

        {product.additionalInformation && (
          <AccordionProductDetails
            additionalInformationSheet={product.additionalInformation}
            isAdditionalInformation
            showProductDocuments={showProductDocuments}
            accordionSummaryTitle={t("product.documents_sheets")}
          />
        )}
        {showProductDocuments && (
          <AccordionProductDetails
            accordionSummaryTitle={t("product.documents")}
            pdfAssets={product.pdfAssets}
            isDangerousGoods={
              selectedVariant && selectedVariant.isDangerousGoods
            }
          />
        )}
      </Box>
      <Hidden smDown>
        <Wrapper grid className={classes.addToCartWrapper}>
          {product.variations && product.variations.length > 1 && (
            <Grid item xs={12}>
              <VariantDropDownPicker
                defaultVariation={product.defaultVariationCode}
              />
            </Grid>
          )}
          <Grid item xs={12} className={classes.grid}>
            <VariantCode
              code={selectedVariant && selectedVariant.articleNumber}
            />
            {enableEcommerce && (
              <StockStatus
                inventoryStatus={
                  selectedVariant && selectedVariant.inventoryStatus
                }
                isDigitalService={
                  selectedVariant && selectedVariant.isDigitalService
                }
              />
            )}
          </Grid>
          <Grid item xs={12} className={classes.grid2}>
            {enableEcommerce && (
              <Grid item xs={12} md={4} className={classes.priceContainer}>
                <Grid>
                  {selectedVariant.isDigitalService ? (
                    <PricePerUnit
                      price={selectedVariant.variationPricePerX || ""}
                      unit={selectedVariant.variationPricePerXUnitType || ""}
                      size={"large"}
                    />
                  ) : (
                    <ProductPrice
                      price={selectedVariant && selectedVariant._displayPrice}
                      discountedPrice={
                        selectedVariant && selectedVariant._discountedPrice
                      }
                      size={"large"}
                    />
                  )}
                  <div className={classes.vatContainer}>
                    <Typography variant="smallText">
                      {t("product.ex_vat")}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} md={8} className={classes.addToCartContainer}>
              <AddToCartOrShoppingListContainer
                code={selectedVariant && selectedVariant.code}
                large={true}
                amount={
                  selectedVariant._displayPrice &&
                  selectedVariant._displayPrice.amount
                    ? selectedVariant._displayPrice.amount
                    : 0
                }
                disabled={
                  selectedVariant === null ||
                  inventoryStatus ||
                  (selectedVariant && selectedVariant._displayPrice === null)
                }
                name={product.displayName}
                category={product.categoryStructure}
                image={image && image._url}
                variationValue={selectedVariant && selectedVariant.articleSize}
                link={route}
                digitalService={product["isDigitalService"]}
                displayPrice={selectedVariant._displayPrice}
                discountedPrice={selectedVariant._discountedPrice}
              />
            </Grid>
            {enableNoWebShopWithContactDealer && contactPage && (
              <Link to={contactPage}>
                <Button>{t("product.contact_our_dealer")}</Button>
              </Link>
            )}
          </Grid>
        </Wrapper>
      </Hidden>
      <Hidden mdUp>
        <Grid className={classes.addToCartWrapper}>
          <Grid>
            <div className={classes.productNameMob}>
              <Typography className={classes.headlineMob} variant={"headline6"}>
                {product.productName}
              </Typography>
            </div>
            {product.variations && product.variations.length > 1 && (
              <VariantDropDownPicker
                defaultVariation={product.defaultVariationCode}
              />
            )}
          </Grid>
          <Grid item className={classes.grid}>
            <div className={classes.wrapperMob}>
              <div className={classes.artNrMob}>
                <VariantCode
                  code={selectedVariant && selectedVariant.articleNumber}
                />
              </div>
              {enableEcommerce && (
                <StockStatus
                  inventoryStatus={
                    selectedVariant && selectedVariant.inventoryStatus
                  }
                  isDigitalService={
                    selectedVariant && selectedVariant.isDigitalService
                  }
                />
              )}
            </div>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item>
              {enableEcommerce && (
                <ProductPrice
                  size={"small"}
                  className={classes.productPriceMob}
                  isInline={false}
                  price={selectedVariant._displayPrice}
                  discountedPrice={selectedVariant._discountedPrice}
                />
              )}
            </Grid>
            <Grid
              item
              className={classNames(classes.grid2, {
                [classes.contactDealerButton]: enableNoWebShopWithContactDealer
              })}
            >
              <AddToCartOrShoppingListContainer
                code={selectedVariant && selectedVariant.code}
                amount={
                  selectedVariant &&
                  selectedVariant._displayPrice &&
                  selectedVariant._displayPrice.amount
                    ? selectedVariant._displayPrice.amount
                    : 0
                }
                disabled={
                  selectedVariant.inventoryStatus === "NOT_IN_STOCK" ||
                  selectedVariant._displayPrice === null
                }
                name={product.displayName}
                category={product.categoryStructure}
                image={image && image._url}
                variationValue={selectedVariant && selectedVariant.articleSize}
                link={route}
                digitalService={product["isDigitalService"]}
                displayPrice={selectedVariant._displayPrice}
                discountedPrice={selectedVariant._discountedPrice}
              />

              {enableNoWebShopWithContactDealer && contactPage && (
                <Link to={contactPage}>
                  <Button>{t("product.contact_our_dealer")}</Button>
                </Link>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  ) : null
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors }, breakpoints }) => ({
    textWrapper: {
      padding: 0,
      width: "100%"
    },
    addToCartWrapper: {
      padding: 0,
      width: "100%",
      position: "relative",
      display: "flex",
      bottom: 0,
      [breakpoints.down("sm")]: {
        position: "fixed",
        background: themeColors.offWhite,
        left: 0,
        padding: spacing(3, 3),
        zIndex: 100,
        flexDirection: "column"
      }
    },
    grid: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& > div": {
        width: "auto"
      },
      "& > p": {
        width: "auto"
      },
      [breakpoints.down("sm")]: {
        borderBottom: "none",
        flexWrap: "wrap",
        alignItems: "flex-end",
        justifyContent: "flex-start",
        "& > div": {
          width: "100%"
        }
      }
    },
    grid2: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-end",
      justifyContent: "flex-end"
    },
    priceContainer: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      [breakpoints.down("sm")]: {
        marginBottom: 12
      }
    },
    vatContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%"
    },
    addToCartContainer: {
      display: "flex",
      justifyContent: "flex-end",
      [breakpoints.down("sm")]: {
        justifyContent: "flex-start"
      }
    },
    productNameMob: {
      width: "100%",
      paddingBottom: 4
    },
    headlineMob: {
      fontSize: 16
    },
    wrapperMob: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      padding: spacing(1, 0),
      "& > div": {
        width: "auto"
      }
    },
    artNrMob: {
      marginRight: 6
    },
    productPriceMob: {
      height: "100%"
    },
    contactDealerButton: {
      justifyContent: "flex-end"
    }
  })
)

export default ProductInfo
