import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import Spinner from "common/Spinner"
import Typography from "common/Typography"
import CartStore from "store/CartStore"
import OrderStore from "store/OrderStore"
import snackbarStore from "store/SnackbarStore"
import OrderPanel from "./OrderPanel"

type Props = {
  maxNumberOfOrders?: number
}

const OrderTable: React.FC<Props> = ({ maxNumberOfOrders = 100 }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { compactOrdersInfo, order, reorderCart },
    actions: { resetReOrder }
  } = useStore(OrderStore)

  const {
    actions: { getByCartTypeName }
  } = useStore(CartStore)

  const {
    actions: { openSnackbar, resetSnackBar }
  } = useStore(snackbarStore)

  useEffect(
    () => {
      if (reorderCart.data) {
        if (reorderCart.data.validationIssues.length > 0) {
          openSnackbar({
            options: {
              message: t(
                `error.${reorderCart.data!.validationIssues[0].value.toLowerCase()}`
              )
            },
            type: "warning"
          })
        } else {
          openSnackbar({
            options: { message: t("account.reorder_items_added_to_cart") },
            type: "success"
          })
        }
        getByCartTypeName("basket")
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reorderCart.data]
  )

  useEffect(
    () => {
      return () => {
        resetSnackBar()
        resetReOrder()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Box className={classes.boxContainer}>
      <Dialog className={classes.spinnerContainer} open={order.loading}>
        <Spinner className={classes.spinner} />
      </Dialog>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell component="th">
              <Typography className={classes.bold} variant="body1">
                {t("account.order_date_label")}
              </Typography>
            </TableCell>
            <TableCell component="th">
              <Typography className={classes.bold} variant="body1">
                {t("account.order_label")}
              </Typography>
            </TableCell>
            <Hidden smDown>
              <TableCell component="th" align="left">
                <Typography className={classes.bold} variant="body1">
                  {t("account.order_status_label")}
                </Typography>
              </TableCell>
              <TableCell component="th" align="right">
                <Typography className={classes.bold} variant="body1">
                  {t("account.line_item_quantity")}
                </Typography>
              </TableCell>
              <TableCell component="th" align="right">
                <Typography className={classes.bold} variant="body1">
                  {t("cart.total_table_header")}
                </Typography>
              </TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {compactOrdersInfo.data &&
            compactOrdersInfo.data
              .slice(0, maxNumberOfOrders)
              .map((order) => (
                <OrderPanel key={order.orderGroupId} condenseOrder={order} />
              ))}
        </TableBody>
      </Table>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  boxContainer: {
    width: "100%"
  },
  tableBody: {
    width: "100%",
    boxShadow: themeColors.boxShadow3
  },
  bold: {
    fontWeight: 500,
    fontSize: 14
  },
  spinnerContainer: {
    "& .MuiPaper-root": {
      backgroundColor: "transparent"
    }
  },
  spinner: {
    boxSizing: "content-box",
    backgroundColor: themeColors.white,
    padding: spacing(1),
    borderRadius: spacing(4)
  }
}))

export default OrderTable
