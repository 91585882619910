import classNames from "classnames"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import { contrastSwitch } from "utils/contrastSwitch"

import Typography from "common/Typography"
import CheckedOrange from "icons/CheckedOrange"
import CheckedOrangeSmall from "icons/CheckedOrangeSmall"

type Props = { listItems?: string[]; compact?: boolean; sectionTheme?: string }

const KeyBenefits: React.FC<Props> = ({ listItems, compact, sectionTheme }) => {
  const classes = useStyles()
  const benefits =
    listItems &&
    listItems.filter((kb) => {
      return kb !== null
    })

  return benefits && benefits.length > 0 ? (
    <Box py={1} className={classes.root}>
      {benefits.map((benefit, index) => {
        return (
          benefit &&
          (compact ? (
            <div key={index} className={classes.compactItemWrapper}>
              <CheckedOrangeSmall />
              <Typography
                textWrap="wrap"
                className={classNames(
                  classes.compactBenefitText,
                  classes[
                    contrastSwitch({
                      variant: "body",
                      contrastColor: sectionTheme
                    })
                  ]
                )}
                variant="listElement2"
                component="span"
              >
                {benefit}
              </Typography>
            </div>
          ) : (
            <div key={index} className={classes.itemWrapper}>
              <CheckedOrange />
              <Typography
                textWrap="wrap"
                className={classNames(
                  classes.benefitText,
                  classes[
                    contrastSwitch({
                      variant: "body",
                      contrastColor: sectionTheme
                    })
                  ]
                )}
                variant="listElement1"
                component="span"
              >
                {benefit}
              </Typography>
            </div>
          ))
        )
      })}
    </Box>
  ) : null
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  itemWrapper: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: spacing(1)
  },
  root: {
    "& > div:last-child": {
      marginBottom: 0
    }
  },
  compactItemWrapper: {
    display: "flex",
    alignItems: "baseline"
  },
  benefitText: {
    paddingLeft: spacing(1),
    width: "100%",
    color: themeColors.colorBaseGray700
  },
  compactBenefitText: {
    paddingLeft: spacing(1),
    width: "100%",
    color: themeColors.colorBaseGray700
  },
  ["text-base-white"]: {
    color: themeColors.white
  },
  ["text-base-blue-900"]: {
    color: themeColors.colorBaseBlue900
  }
}))

export default KeyBenefits
