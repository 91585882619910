import { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const Belgium: React.FC<Props> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48" {...props}>
    <g>
      <rect x="16" y="6" fill="#FFDE00" width="16" height="36" />
      <path
        fill="#EE0000"
        d="M48,40c0,1.105-0.895,2-2,2H32V6h14c1.105,0,2,0.895,2,2V40z"
      />
      <path d="M16,42H2c-1.105,0-2-0.895-2-2V8c0-1.105,0.895-2,2-2h14V42z" />
    </g>
  </svg>
)

export default Belgium
