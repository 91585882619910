import { ReactNode, useRef } from "react"
import Slider from "react-slick"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

type FenceContentCarouselProps = {
  children: ReactNode
  slides: number
}

const FenceContentCarousel = ({
  children,
  slides
}: FenceContentCarouselProps) => {
  const classes = useStyles()
  const containerRef = useRef<HTMLDivElement>(null)

  // TODO: Look over these settings + fix responsive property
  const settings = {
    swipe: true,
    arrows: false,
    centerMode: false,
    focusOnSelect: true,
    dots: false,
    infinite: slides > 1 ? true : false,
    slidesToShow: slides,
    slidesToScroll: slides,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          focusOnSelect: true,
          slidesToScroll: 1,
          slidesToShow: 5
        }
      },
      {
        breakpoint: 600,
        settings: {
          focusOnSelect: true,
          slidesToScroll: 1,
          slidesToShow: 4
        }
      },
      {
        breakpoint: 500,
        settings: {
          focusOnSelect: true,
          slidesToScroll: 1,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 375,
        settings: {
          focusOnSelect: true,
          slidesToScroll: 1,
          slidesToShow: 2
        }
      }
    ]
  }

  return (
    <Grid className={classes.slickList} style={{ overflowX: "hidden" }}>
      {children ? <Slider {...settings}>{children}</Slider> : null}
    </Grid>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  slickList: {
    "& .slick-list": {
      // Width overflow screen and makes it obvious it's scrollable
      width: "100vw",
      marginLeft: spacing(2)
    },
    "& .slick-slide": {
      padding: spacing(0, 1, 0, 0)
    },
    "& .slick-track": {
      margin: 0
    }
  }
}))

export default FenceContentCarousel
