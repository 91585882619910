import { ComponentType, lazy, LazyExoticComponent, Suspense } from "react"

import MapLoadingSpinner from "pages/DealerPage/MapLoadingSpinner"

const isDevelopment = process.env.NODE_ENV === "development"

// This function is used to conditionally import and
// render components based on the environment and the path provided.
// It's designed to use lazy loading in production for better performance.

export const conditionalImport = (paths: string) => {
  let Component: ComponentType<any> | LazyExoticComponent<any>
  switch (paths) {
    case "GoogleMaps":
      Component = isDevelopment
        ? require("pages/DealerPage/GoogleMaps").default
        : lazy(() => import("pages/DealerPage/GoogleMaps"))
      break
    case "GoogleMapsDealer":
      Component = isDevelopment
        ? require("pages/DealerPage/GoogleMapsDealer").default
        : lazy(() => import("pages/DealerPage/GoogleMapsDealer"))
      break
    case "PublicSecurityChinaIcon":
      Component = isDevelopment
        ? require("icons/PublicSecurityChinaIcon").default
        : lazy(() => import("icons/PublicSecurityChinaIcon"))
      break
    // Add more cases as needed
    default:
      throw new Error(`Unsupported path: ${paths}`)
  }

  return function WrappedComponent(props: any) {
    return isDevelopment ? (
      <Component {...props} />
    ) : (
      <Suspense fallback={<MapLoadingSpinner />}>
        <Component {...props} />
      </Suspense>
    )
  }
}
