const vatRegistrationUtil = (countryAlpha2: string) => {
  if (!countryAlpha2) {
    return { start: "", end: "", length: 99 }
  }

  switch (countryAlpha2) {
    case "SE":
      return { start: "SE", end: "01", length: 10 }
    case "FI":
      return { start: "FI", end: "", length: 8 }
    default:
      return { start: "", end: "", length: 99 }
  }
}

export default vatRegistrationUtil
