import { SvgIconProps } from "@material-ui/core/SvgIcon"

const Argentina: React.FC<SvgIconProps> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48" {...props}>
    <g>
      <path
        fill="#7FA9CF"
        d="M48,18H0V8c0-1.105,0.895-2,2-2h44c1.105,0,2,0.895,2,2V18z"
      />
      <rect y="18" fill="#E6E6E6" width="48" height="12" />
      <path
        fill="#7FA9CF"
        d="M48,40c0,1.105-0.895,2-2,2H2c-1.105,0-2-0.895-2-2V30h48V40z"
      />
      <path
        fill="#EFD358"
        d="M24,20c-2.209,0-4,1.791-4,4s1.791,4,4,4c2.209,0,4-1.791,4-4S26.209,20,24,20z"
      />
    </g>
  </svg>
)

export default Argentina
