import request from "./request"

const getIdentifier = (identifier: string) =>
  (window.EPI_EDIT_MODE && `${identifier}?ineditmode=true`) || identifier

const getCached = <T>(
  url: string,
  cacheLocation: string,
  cacheKey: (lang: string) => string,
  clearCache?: boolean
): Promise<T> => {
  const cachedValue = window[cacheLocation]
  if (cachedValue) {
    const key = cacheKey(cachedValue["_lang"] || "").toLowerCase()
    const type = cachedValue["_type"] || ""
    if (
      key === cachedValue["_apiRoute"] ||
      window.EPI_EDIT_MODE ||
      type.toLowerCase().includes("errorpage")
    ) {
      if (clearCache) {
        window[cacheLocation] = undefined
      }
      return Promise.resolve<T>(cachedValue as any)
    }
  }

  return request.get(url)
}

export { getCached, getIdentifier }
