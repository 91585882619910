import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const Weibo: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 33 26" {...props}>
    <path
      d="M24.108 12.5156C23.6564 12.3783 23.3464 12.2897 23.5811 11.6963C24.0948 10.4077 24.1479 9.29617 23.5899 8.49906C22.5493 7.01113 19.6974 7.09084 16.4293 8.45921C16.4293 8.45921 15.4019 8.90647 15.6676 8.09608C16.168 6.47973 16.0927 5.12908 15.3133 4.34525C13.542 2.56948 8.82574 4.41168 4.78264 8.45478C1.75806 11.4794 0 14.6899 0 17.4665C0 22.7717 6.8064 26 13.4622 26C22.1906 26 27.9962 20.9295 27.9962 16.9041C28.0006 14.4685 25.9502 13.0869 24.108 12.5156ZM13.4844 24.0958C8.17034 24.6228 3.58255 22.2182 3.23714 18.733C2.89173 15.2435 6.92154 11.993 12.2312 11.4661C17.5452 10.9391 22.133 13.3437 22.474 16.8288C22.8238 20.3184 18.794 23.5732 13.4844 24.0958Z"
      fill="#103D82"
    />
    <path
      d="M29.9048 2.8042C27.7969 0.466017 24.6882 -0.424085 21.8186 0.18703C21.1543 0.328738 20.7336 0.984136 20.8754 1.64396C21.0171 2.30822 21.668 2.72891 22.3323 2.58721C24.3738 2.15323 26.5835 2.78648 28.0803 4.44712C29.5771 6.10776 29.9845 8.37066 29.3424 10.3546C29.1343 11.0011 29.4885 11.6919 30.1351 11.9001C30.7816 12.1082 31.4724 11.7539 31.6806 11.1118V11.1074C32.584 8.32195 32.0127 5.13795 29.9048 2.8042Z"
      fill="#103D82"
    />
    <path
      d="M26.6677 5.72701C25.6403 4.58892 24.1258 4.15494 22.7309 4.45606C22.1596 4.57563 21.7965 5.14246 21.9161 5.71372C22.0401 6.28498 22.6025 6.64811 23.1693 6.52411C23.8513 6.37798 24.5952 6.59054 25.0956 7.14409C25.5961 7.70206 25.7333 8.45931 25.5163 9.12357C25.3392 9.67711 25.6403 10.2749 26.1983 10.4565C26.7563 10.6336 27.3497 10.3325 27.5312 9.77454C27.9697 8.41503 27.6951 6.8651 26.6677 5.72701Z"
      fill="#103D82"
    />
    <path
      d="M13.7767 17.7809C13.5907 18.0998 13.1788 18.2504 12.86 18.1175C12.5412 17.9891 12.4437 17.6304 12.6253 17.3204C12.8113 17.0104 13.2054 16.8598 13.5198 16.9838C13.8387 17.099 13.9538 17.4577 13.7767 17.7809ZM12.0806 19.9508C11.5669 20.7701 10.4642 21.1288 9.63614 20.7524C8.82132 20.3804 8.57776 19.4283 9.09145 18.6268C9.60072 17.8297 10.6635 17.4754 11.4872 17.8208C12.3197 18.1795 12.5854 19.1227 12.0806 19.9508ZM14.0114 14.1497C11.4828 13.4899 8.62647 14.7519 7.52824 16.9794C6.40786 19.2512 7.49281 21.7753 10.0436 22.599C12.6917 23.4537 15.8093 22.1429 16.8942 19.694C17.9659 17.2938 16.6285 14.8272 14.0114 14.1497Z"
      fill="#103D82"
    />
  </SvgIcon>
)

export default Weibo
