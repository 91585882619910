import { SvgIconProps } from "@material-ui/core/SvgIcon"

const Colombia: React.FC<SvgIconProps> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48" {...props}>
    <g>
      <path
        fill="#FDD116"
        d="M48,26H0V8c0-1.105,0.895-2,2-2h44c1.105,0,2,0.895,2,2V26z"
      />
      <rect y="26" fill="#003D8C" width="48" height="8" />
      <path
        fill="#CB1224"
        d="M48,40c0,1.105-0.895,2-2,2H2c-1.105,0-2-0.895-2-2v-6h48V40z"
      />
    </g>
  </svg>
)

export default Colombia
