import { makeStyles } from "@material-ui/core/styles"

type EpiNavButtonProps = {
  link: string
}

const EpiQuickNavigatorButton: React.FC<EpiNavButtonProps> = ({ link }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <a href={link} target="_self">
        <span className={classes.text} />
      </a>
    </div>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    background: "#f7542b;",
    margin: 0,
    padding: 0,
    position: "fixed",
    right: 0,
    top: 0,
    height: 32,
    zIndex: 1300,
    "& a": {
      textDecoration: "none"
    }
  },
  text: {
    background:
      "url(https://inte.delaval.com/Util/images/episerver-white.svg) center center no-repeat",
    display: "block",
    height: 16,
    lineHeight: 12,
    margin: spacing(1, 1, 0),
    textIndent: "-9999em",
    width: 50
  }
}))

export default EpiQuickNavigatorButton
