import { Fragment, useState } from "react"
import { Link } from "react-router-dom"

import Box from "@material-ui/core/Box"
import Collapse from "@material-ui/core/Collapse"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"

import Typography from "common/Typography"
import InformationPageMenuListItem from "./InformationPageMenuListItem"

type MenuItem = {
  title: string
  url: string
  isSelected: boolean
  subItems?: MenuItem[]
}

type Props = {
  subNavigation: MenuItem
}

const InformationPageMenu: React.FC<Props> = ({ subNavigation }) => {
  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)

  const handleExpansion = () => {
    setExpanded(!expanded)
  }

  const isMdUp = useMediaQuery(({ breakpoints }) => breakpoints.up("md"))

  return (
    <Box>
      <Box className={classes.menuContainer} onClick={handleExpansion}>
        <Grid justifyContent="center" container={true} className={classes.menu}>
          {subNavigation.url && (
            <Link
              onClick={(e) => {
                e.stopPropagation()
              }}
              to={subNavigation.url}
            >
              <Typography variant="headline5" className={classes.title}>
                {subNavigation.title}
              </Typography>
            </Link>
          )}
          {subNavigation.subItems &&
            subNavigation.subItems.length > 0 &&
            (expanded ? (
              <KeyboardArrowUp className={classes.arrow} />
            ) : (
              <KeyboardArrowDown className={classes.arrow} />
            ))}
        </Grid>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="nav" className={classes.root}>
          {subNavigation.subItems &&
            subNavigation.subItems.length > 0 &&
            subNavigation.subItems.map((item, i) => (
              <Fragment key={i}>
                <InformationPageMenuListItem
                  {...item}
                  handleMenuExpansion={handleExpansion}
                />
              </Fragment>
            ))}
        </List>
      </Collapse>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors, pageMaxWidth }, breakpoints }) => ({
    root: {
      padding: 0,
      maxWidth: pageMaxWidth,
      margin: "0 auto",
      background: themeColors.white
    },
    menuContainer: {
      WebkitTapHighlightColor: "transparent",
      backgroundColor: themeColors.offWhite,
      margin: `0 -${spacing(3)}px`,
      [breakpoints.up("md")]: {
        marginLeft: "calc(50% - 50vw)",
        marginRight: "calc(50% - 50vw)"
      }
    },
    menu: {
      cursor: "pointer",
      alignItems: "center",
      padding: spacing(1.5, 3),
      maxWidth: 960,
      margin: "0 auto",
      width: "100%",
      display: "flex",
      [breakpoints.up("md")]: {
        padding: spacing(1.5, 1.5)
      },
      [breakpoints.up("lg")]: {
        maxWidth: pageMaxWidth,
        padding: spacing(1.5, 1.5)
      }
    },
    title: {
      alignSelf: "center"
    },
    listBorders: {
      paddingLeft: 0
    },
    listItem: {
      margin: `${spacing(0.75)}px 0`,
      paddingTop: spacing(0.5),
      paddingBottom: spacing(0.5),
      paddingLeft: 0,
      cursor: "pointer",
      "& p": {
        color: themeColors.primaryCyan,
        fontSize: 16
      },
      "& > div": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        color: themeColors.primaryCyan,
        [breakpoints.down("sm")]: {
          padding: `0px ${spacing(3)}px`
        }
      }
    },
    arrow: {
      color: themeColors.primaryRed,
      height: spacing(6),
      width: spacing(6),
      padding: `0 ${spacing(1.5)}px`,
      marginRight: `-${spacing(1.5)}px`,
      marginLeft: "auto",
      cursor: "pointer"
      // backgroundColor: themeColors.whiteGray
    }
  })
)

export default InformationPageMenu
