import { asyncStateSetter } from "./zustand-async-state"

export const getZustandHelpers = <Get extends () => any, State extends object>(
  set: any,
  get: Get,
  initialStates: State
) => {
  type StateKeys = keyof State
  const reset = (stateKey?: StateKeys) => {
    if (stateKey) {
      set({ [stateKey]: initialStates[stateKey] })
    } else {
      set(initialStates)
    }
  }

  const setters = <Keys extends StateKeys>(keys: Keys[]) => {
    type Setters = { [Key in Keys]: (val: State[Key]) => void }
    const setObject = keys.reduce((acc, key) => {
      acc[key] = (value: State[typeof key]) => {
        return set({ [key]: value })
      }
      return acc
    }, {} as Setters)
    return setObject
  }
  const resetters = <Keys extends StateKeys>(keys: Keys[]) => {
    type Resetters = { [Key in Keys]: () => void }
    const resetObject = keys.reduce((acc, key) => {
      acc[key] = () => reset(key)
      return acc
    }, {} as Resetters)
    return resetObject
  }

  const setAsyncState = asyncStateSetter(set, get)
  return { setAsyncState, reset, setters, resetters }
}
