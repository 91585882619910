import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Hidden from "@material-ui/core/Hidden"
import { makeStyles, Theme } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { CondenseOrder, Order } from "api/orders"
import Typography from "common/Typography"
import Delivered from "icons/Delivered"
import Pending from "icons/Pending"
import { OrderStatus } from "models/Order"
import AccountModal from "pages/AccountPage/common/AccountModal"
import OrderStore from "store/OrderStore"
import OrderContent from "./OrderContent"

type Props = { condenseOrder: CondenseOrder; expanded?: boolean }

const OrderPanel: React.FC<Props> = ({ condenseOrder, expanded = false }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const isMdDown = useMediaQuery(({ breakpoints }) => breakpoints.down("md"))

  const [isExpanded, toggleExpanded] = useState(expanded)
  const [localOrderState, setLocalOrderState] = useState<Order | null>(null)
  const [orderId, setOrderId] = useState("")
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const {
    state: { order },
    actions: { fetchOrder }
  } = useStore(OrderStore)

  const handleOrderIdOnChange = () => {
    setOrderId(condenseOrder.id)
    toggleExpanded(!isExpanded)
  }

  useEffect(
    () => {
      if (isExpanded) {
        fetchOrder(orderId)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderId]
  )

  useEffect(
    () => {
      if (orderId && !localOrderState) {
        setLocalOrderState(order.data)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [order.data && order.data.orderGroupId]
  )

  const handleClose = () => {
    setIsDialogOpen(false)
  }

  const handleEditDialog = () => {
    handleOrderIdOnChange()
    setIsDialogOpen(true)
  }

  const table = (
    <TableRow onClick={handleEditDialog} className={classes.shoppingRow}>
      <TableCell>
        <Typography variant="smallText">
          {t("account.order_date_label")}
        </Typography>
        <Typography variant="body2">
          {new Date(condenseOrder.created).toLocaleDateString()}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="smallText">
          {t("account.order_order_number_label")} <br />
          {condenseOrder.id}
        </Typography>
      </TableCell>
      <Hidden smDown>
        <TableCell align="left">
          <Typography variant="body2" className={classes.orderStatus}>
            {condenseOrder.status === OrderStatus.completed ? (
              <>
                <Delivered className={classes.orderStatusIcon} />
                {t("account.delivered")}
              </>
            ) : (
              <>
                <Pending className={classes.orderStatusIcon} />
                {t("account.pending")}
              </>
            )}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="body2">{condenseOrder.numberOfItems}</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography variant="body2">{condenseOrder.totalString}</Typography>
        </TableCell>
      </Hidden>
    </TableRow>
  )

  return (
    <AccountModal
      modalTrigger={table}
      onClose={handleClose}
      open={isDialogOpen && !order.loading}
      title={`${t("account.order_order_number_label")}: ${condenseOrder.id}`}
      maxWidth="md"
      fullScreen={isMdDown}
    >
      <OrderContent order={localOrderState} />
    </AccountModal>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors, gridSpacing } }: Theme) => ({
    root: {
      width: "100%",
      backgroundColor: themeColors.offWhite,
      borderLeft: `1px solid ${themeColors.lightGray}`,
      borderRight: `1px solid ${themeColors.lightGray}`,
      borderTop: `1px solid ${themeColors.lightGray}`,
      [breakpoints.down("sm")]: {
        borderLeft: "none",
        borderRight: "none"
      },
      [breakpoints.up("md")]: {
        "&:last-child": {
          borderBottom: `1px solid ${themeColors.lightGray}`
        }
      },
      marginBottom: 0
    },
    container: {
      margin: 0,
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row"
    },
    expansionIcon: {
      width: spacing(1),
      height: spacing(1),
      margin: spacing(0.5)
    },
    expandedRow: {
      backgroundColor: themeColors.white,
      margin: 0,
      width: "100%",
      transition: "0.5s"
    },
    orderStatus: {
      display: "flex",
      alignItems: "center"
    },
    orderStatusIcon: {
      marginRight: spacing(1)
    },
    expandedSummary: {
      borderBottom: `1px solid ${themeColors.lightGray}`,
      width: "100%"
    },
    summaryContent: {
      marginTop: gridSpacing,
      marginBottom: gridSpacing,
      width: "100%"
    },
    orderDetails: {
      padding: 0
    },
    sideBars: {
      paddingLeft: gridSpacing,
      paddingRight: gridSpacing,
      width: "100%"
    },
    "@keyframes rotate": {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(360deg)" }
    },
    shoppingRow: {
      cursor: "pointer",
      "&:nth-of-type(even)": {
        background: themeColors.whiteGray
      }
    }
  })
)

export default OrderPanel
