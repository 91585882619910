import { lazy, Suspense, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import MenuItem from "@material-ui/core/MenuItem"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Paper from "@material-ui/core/Paper"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import { ShippingAddress } from "models/User"
import ShippingAdressList from "pages/Checkout/Sections/CustomerDetails/ShippingAdressList"
import authStore from "store/AuthStore"

const PostalAddressFormat = lazy(() => import("common/PostalAddressFormat"))

const AccountShippingDetails: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { user },
    actions: { getUser }
  } = useStore(authStore)

  const [selectedShippingAddress, setSelectedShippingAddress] = useState(0)

  const userData =
    user.data && user.data.shippingAddresses[selectedShippingAddress]

  const handleSelectShipping = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as number
    setSelectedShippingAddress(value)
  }

  useEffect(
    () => {
      if (!user.data) {
        getUser()
      } else {
        user.data.shippingAddresses.forEach((sA: ShippingAddress, index) => {
          if (sA.shippingAddressId === user.data!.defaultShippingAddressId) {
            setSelectedShippingAddress(index)
          }
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getUser, user.data]
  )

  return (
    <Box p={3}>
      <div className={classes.headline}>
        <Typography variant="headline6" className={classes.sectionName}>
          {t("account.shipping_address_title")}
        </Typography>
      </div>

      {user.data && (
        <>
          <Select
            id="shippingSelector-field"
            className={classes.shippingSelector}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
                }
              }
            }}
            value={selectedShippingAddress}
            onChange={handleSelectShipping}
            input={<OutlinedInput labelWidth={8} style={{ marginRight: 7 }} />}
          >
            {user.data.shippingAddresses.map((address, index) => {
              return (
                <MenuItem key={index} value={index}>
                  <ShippingAdressList
                    shippingAddressId={address.shippingAddressId}
                    streetAddress={address.streetAddress}
                    city={address.city}
                    zipCode={address.zipCode}
                  />
                </MenuItem>
              )
            })}
          </Select>
          <Paper className={classes.shippingAddress}>
            <Table>
              <TableBody>
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="smallText" className={classes.label}>
                      {t("account.shipping_address_label")}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {user.data && (
                      <Suspense
                        fallback={
                          <Box display="flex" alignItems="center">
                            <ButtonLoaderIcon />
                          </Box>
                        }
                      >
                        {userData && (
                          <PostalAddressFormat
                            companyName={user.data.companyName}
                            addressData={userData}
                            variant="body1"
                            addressType="personal"
                          />
                        )}
                      </Suspense>
                    )}
                  </TableCell>
                </TableRow>
                {userData?.companyName &&
                  userData.companyName !== user.data.companyName && (
                    <TableRow className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>
                        <Typography
                          variant="smallText"
                          className={classes.label}
                        >
                          {t("account.company_name_label")}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Typography
                          variant="body1"
                          className={classes.shippingAdressText}
                        >
                          {userData?.companyName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="smallText" className={classes.label}>
                      {t("account.recipient_label")}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      variant="body1"
                      className={classes.shippingAdressText}
                    >
                      {`${userData?.firstName} ${userData?.lastName}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                {userData?.phone && (
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="smallText" className={classes.label}>
                        {t("account.shipping_address_phone_label")}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography
                        variant="body1"
                        className={classes.shippingAdressText}
                      >
                        {userData.phone}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </>
      )}
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors, gridSpacing } }) => ({
    sectionName: {
      color: themeColors.darkGray,
      marginRight: spacing(2)
    },
    tableRow: {
      verticalAlign: "text-top",
      [breakpoints.down("sm")]: {
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: spacing(1)
      }
    },
    tableCell: {
      border: "none",
      paddingLeft: 0,
      [breakpoints.down("sm")]: {
        padding: 0,
        width: "100%"
      }
    },
    label: {
      color: themeColors.gray
    },
    headline: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      marginBottom: spacing(4)
    },
    shippingAdressText: {
      display: "flex",
      textAlign: "left",
      paddingLeft: 0
    },
    shippingAddress: {
      backgroundColor: themeColors.offWhite,
      padding: gridSpacing,
      border: `1px solid ${themeColors.lightGray}`,
      [breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    shippingSelector: {
      width: "100%",
      padding: spacing(1),
      marginBottom: spacing(3),
      "& svg": {
        right: 8
      },
      "& > div > div > div:nth-child(2)": {
        display: "none"
      },
      [breakpoints.down("sm")]: {
        marginBottom: gridSpacing
      }
    }
  })
)

export default AccountShippingDetails
