import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const SheepBodyIcon: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 61 52">
    <path
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M48.56 18.86c.65.98 1.33 1.68 1.87 2.72.25.5.49 1.08.24 1.57-.3.6-1.11.66-1.78.66l-2.3-.03c-.57-.01-1.17-.03-1.64-.34a2.05 2.05 0 0 1-.8-1.28c-.14-.59-.7-.98-1.3-1.09-.2-.03-.38-.1-.55-.2-.5-.3-.78-1-.47-1.49.31-.49.99-.53 1.57-.53l5.15.01h0Zm0 0c-.23-.52-1.18-3.86-3.4-3.86-2.22 0-2.16.12-4.51.06-1.85-.05-3.54 3.23-4.18 4.63-.17.38-.54.62-.95.62-3.13 0-11.73.06-14.89 0C13.37 20.31 9 24.5 9 30v1c0 5 1.2 9.98 7.8 10m0 0h21.26c2.44 0 3.36-1.29 3.67-3.37.07-.5.48-.9.99-.97 2.86-.4 3.44-2.21 3.55-4.37.74-3.56.93-7.91.93-7.91M16.8 41l-1.37 2.28c-.26.44-.35.97-.24 1.48l1.14 4.74a.45.45 0 0 1-.43.55h-2.12a.45.45 0 0 1-.42-.3l-1.76-5.4c-.1-.31-.11-.65-.02-.96.21-.71.97-2.55 1.23-3.01m9.69-3.88c0 2.9-1.63 4.12-3.98 4.44m12.1-4.31c0 2.89.86 4.24 3.63 4.37m-15.73 3.04 2.12 5.66c.08.2.3.35.52.35h1.62c.37 0 .65-.37.54-.73l-1.56-5.14m12.13-3.2.12 2.94.03.31 1.22 5.53c.07.29.32.49.61.49h1.52c.38 0 .67-.32.63-.68l-.46-4.8v-.25l.07-3.52m-10.25 3.34 1.17 5.23c.07.29.32.49.6.49h1.53c.37 0 .66-.32.63-.69l-.46-5.03"
    />
  </SvgIcon>
)

export default SheepBodyIcon
