import { BlockProps } from "@starrepublic/epi/cms"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"

import { contrastSwitch } from "utils/contrastSwitch"

import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"

type Props = BlockProps<{
  label?: string
  description?: string
  text?: string
  theme: string
  textAlignment: "left" | "right" | "center"
}>

const FormTextElementBlock: React.FunctionComponent<Props> = ({
  content: { description, label, text, theme, textAlignment }
}) => {
  const classes = useStyles()

  return (
    <Tooltip
      placement="top"
      arrow
      title={description ? description : ""}
      interactive
    >
      <Grid container direction="column">
        {label && (
          <Box pt={2}>
            <Typography
              variant="headline4"
              align={textAlignment}
              className={
                classes[
                  contrastSwitch({
                    variant: "title",
                    contrastColor: theme
                  })
                ]
              }
            >
              {label}
            </Typography>
          </Box>
        )}
        {text && (
          <ThemeXhtmlString
            content={text}
            sectionTheme={theme}
            style={{ textAlign: textAlignment }}
          />
        )}
      </Grid>
    </Tooltip>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  ["text-base-white"]: {
    color: themeColors.white
  },
  ["text-base-blue-700"]: {
    color: themeColors.colorBaseBlue700
  },
  ["text-base-blue-800"]: {
    color: themeColors.colorBaseBlue800
  },
  ["text-base-blue-900"]: {
    color: themeColors.colorBaseBlue900
  }
}))

export default FormTextElementBlock
