import request from "./request"

export type MenuItem = {
  id: string
  title: string
  url: string
  children: MenuItem[]
}

export type Menu = {
  identifier: string
  items: MenuItem[]
}

const menus = {
  getByIdAndLevels: (id: string, levels: number) =>
    request.get<Menu>(`/menus/${id}/${levels}`),
  getByLevels: (levels: number) => request.get<Menu>(`/menus/${levels}`)
}

export default menus
