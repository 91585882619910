import { useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { MenuItem } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Fade from "@material-ui/core/Fade"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { isSearchBot } from "utils/device"
import { countObjectDepth } from "utils/hierarchyDepthCounter"

import SearchBarButton from "common/Header/SearchBar/Button"
import HoverIntent from "common/HoverIntent"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import { eventMegaMenuTracking } from "services/StarGA"
import { useHeaderStore } from "store/HeaderStore"
import { themeColors } from "theme"
import MenuContent from "./MenuContent"

type Props = {
  fixedTop: number
}

const CLOSE_MENU_TIMEOUT = 250

const HeaderContent: React.FC<Props> = ({ fixedTop }) => {
  const classes = useStyles()
  const isCrawler = useMemo(() => isSearchBot(), [])

  const [isMenuOpen, setIsMenuOpen] = useState<MenuItem | null>(null)

  const { menu, menuAncestorsId, menuCurrentId } = useHeaderStore()

  const openMegaMenu = (menuNode: MenuItem) => {
    if (
      (menuNode.children && menuNode.children.length > 0) ||
      menuNode["menuType"] === "StoreMenu"
    ) {
      setIsMenuOpen(menuNode)
    } else {
      setIsMenuOpen(null)
    }
  }

  let timeoutId: NodeJS.Timeout
  return (
    <>
      <Grid container className={classes.menuContainer}>
        {!isCrawler && (
          <Grid
            id="mega-menu-navigation"
            container
            onMouseLeave={() => {
              timeoutId = setTimeout(
                () => setIsMenuOpen(null),
                CLOSE_MENU_TIMEOUT
              )
            }}
            onMouseEnter={() => clearTimeout(timeoutId)}
            className={classes.menuItemsWrapper}
            component="nav"
          >
            {menu &&
              menu.map((item, key) => {
                return (
                  <Grid item key={`${item._id}-${key}`}>
                    <HoverIntent
                      clone
                      enterCallback={() => {
                        return openMegaMenu(item)
                      }}
                      leaveCallback={() => isMenuOpen && setIsMenuOpen(null)}
                    >
                      <Link
                        to={item.url}
                        onClick={() => {
                          setIsMenuOpen(null),
                            eventMegaMenuTracking(
                              item.url,
                              countObjectDepth(item),
                              item.title
                            )
                        }}
                        className={classNames(classes.linkText, {
                          [classes.active]:
                            menuAncestorsId.includes(item._id) ||
                            menuCurrentId === item._id
                        })}
                      >
                        <Typography textWrap="wrap" variant="headline6">
                          {item.title}
                        </Typography>
                      </Link>
                    </HoverIntent>
                  </Grid>
                )
              })}
          </Grid>
        )}
        <SearchBarButton />
        {isCrawler && (
          <Box
            component="nav"
            position="absolute"
            id="mega-menu-navigation"
            top={100}
            className={classes.megaMenuNavigation}
          >
            {menu &&
              menu.map((item, key) => {
                return (
                  <Box key={`${item._id}-${key}`}>
                    <Box bgcolor={themeColors.colorBaseGray400}>
                      <Link
                        to={item.url}
                        onClick={() => {
                          setIsMenuOpen(null)
                        }}
                        className={classNames(classes.linkText, classes.active)}
                      >
                        <Typography variant="headline6">
                          {item.title}
                        </Typography>
                      </Link>
                      <Box bgcolor={themeColors.colorBaseGray400}>
                        <MenuContent
                          menuItem={item}
                          onLinkClick={() => {
                            setIsMenuOpen(null)
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )
              })}
          </Box>
        )}
      </Grid>
      <Fade in={isMenuOpen !== null}>
        <div
          className={classes.storeMenuContent}
          style={{ top: fixedTop }}
          onMouseLeave={() => {
            timeoutId = setTimeout(
              () => setIsMenuOpen(null),
              CLOSE_MENU_TIMEOUT
            )
          }}
          onMouseEnter={() => clearTimeout(timeoutId)}
        >
          <Wrapper>
            <MenuContent
              menuItem={isMenuOpen}
              onLinkClick={() => {
                setIsMenuOpen(null)
              }}
            />
          </Wrapper>
        </div>
      </Fade>
    </>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, breakpoints, spacing }) => ({
    menuContainer: {
      flexGrow: 1,
      alignItems: "center",
      flexWrap: "nowrap",
      [breakpoints.down("sm")]: {
        display: "none"
      }
    },
    menuItemsWrapper: {
      alignItems: "center",
      flexWrap: "nowrap",
      width: "auto"
    },
    linkText: {
      backgroundColor: themeColors.primaryBlue,
      lineHeight: 0.9,
      padding: spacing(0, 4),
      transition: "0.7s",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 80,
      overflow: "hidden",
      "&:hover": {
        backgroundColor: themeColors.colorBaseBlue650
      },
      "& h6": {
        fontSize: 16,
        lineHeight: "20px",
        color: themeColors.white
      },
      [breakpoints.down("md")]: {
        padding: spacing(0, 2)
      }
    },
    active: {
      backgroundColor: themeColors.transparent
    },
    container: {
      color: themeColors.white,
      [breakpoints.down("sm")]: {
        paddingTop: 0
      }
    },
    storeMenuContent: {
      minHeight: 250,
      background: themeColors.primaryBlue,
      position: "absolute",
      width: "100vw",
      marginLeft: "calc(50% - 50vw)",
      borderTop: `1px solid ${themeColors.transparent}`,
      left: 0,
      zIndex: 999
    },
    megaMenuNavigation: {
      backgroundColor: themeColors.neutralGray,
      position: "absolute",
      top: 100,
      visibility: "hidden"
    }
  })
)

export default HeaderContent
