import { Link } from "react-router-dom"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import ArrowRight from "icons/ArrowRight"
import themeColors from "theme/colors"
import { ChildLinksContent } from "./types"

type Props = { sectionTheme?: string } & ChildLinksContent

const GridViewCard: React.FC<Props> = ({
  sectionTheme = "",
  image,
  url,
  text,
  heading
}) => {
  const classes = useStyles()

  return (
    <Grid item className={classes.root}>
      <Divider
        className={
          sectionTheme === themeColors.primaryBlue ? classes.dividerLight : ""
        }
      />
      <Link to={url}>
        <Grid
          wrap="nowrap"
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.container}
        >
          <Grid item>
            {heading && (
              <Box mt={2} mb={2}>
                <Typography
                  className={classNames(classes.headline, {
                    [classes.themedColor]:
                      sectionTheme === themeColors.primaryBlue
                  })}
                  variant="headline5"
                >
                  {heading}
                </Typography>
              </Box>
            )}
            {text && (
              <Grid>
                <Typography
                  className={classNames(classes.preamble, {
                    [classes.themedColor]:
                      sectionTheme === themeColors.primaryBlue
                  })}
                  variant="preamble"
                >
                  {text}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Grid
              className={classNames(classes.arrowContainer, {
                [classes.themedColor]: sectionTheme === themeColors.primaryBlue
              })}
              container
            >
              <ArrowRight />
            </Grid>
          </Grid>
        </Grid>
      </Link>
    </Grid>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, spacing, breakpoints }) => ({
    root: {
      width: "100%"
    },
    container: {
      paddingInline: spacing(2)
    },
    headline: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineHeight: 1.5,
      [breakpoints.down("sm")]: {
        fontSize: spacing(2)
      }
    },
    preamble: {
      marginBottom: spacing(2),
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      [breakpoints.down("xs")]: {
        fontSize: spacing(1.5)
      }
    },
    arrowContainer: {
      paddingLeft: spacing(2),
      color: themeColors.primaryCyan
    },
    themedColor: {
      color: themeColors.white
    },
    dividerLight: {
      backgroundColor: themeColors.transparent
    }
  })
)

export default GridViewCard
