import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { BlockResolver, ContentProps, PageInfo } from "@starrepublic/epi/cms"
import DisplayOptionsContentArea from "@starrepublic/epi/cms/components/DisplayOptionsContentArea"
import classNames from "classnames"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { conditionalImport } from "utils/conditionalImport "
import DisplayOptions from "utils/ui/DisplayOptions"

import {
  ChildContainer,
  ContainerWrapper,
  SectionComponent
} from "common/contentAreaContainers"
import Typography from "common/Typography"
import ListIcon from "icons/ListIcon"
import MapIcon from "icons/MapIcon"
import DealerSearchStore, { Dealer } from "store/DealerSearchStore"
import ContactHeadquarter from "./ContactHeadquarter"
import DealerDialog from "./DealerDialog"
import DealerPanel from "./DealerPanel"
import { DisplayNumberOfDealersShowing } from "./DisplayNumberOfDealersShowing"
import MapLoadingSpinner from "./MapLoadingSpinner"
import SearchField from "./SearchField"

const GoogleMaps = conditionalImport("GoogleMaps")

type Props = { pageInfo: PageInfo } & ContentProps

const DealerPage: React.FC<Props> = ({ content, pageInfo, propsInfo }) => {
  const classes = useStyles()
  const isMdUp = useMediaQuery(({ breakpoints }) => breakpoints.up("md"))
  const { t } = useTranslation()

  const { dealers, dealerCountryPage } = content
  const {
    state: { allHeadquarters, mapOpen, mapSearch },
    actions: {
      setAllDealers,
      setGeoLocation,
      setMapZoom,
      setMapOpen,
      setDefaultMapZoom
    }
  } = useStore(DealerSearchStore)

  const [dealersInList, setDealersInList] = useState<Dealer[]>([])

  const searchedDealersRef = useRef<Dealer[]>()

  useEffect(
    () => {
      setAllDealers(dealers).then(() => {
        if (dealerCountryPage) {
          setGeoLocation({
            lat: Number(dealerCountryPage.defaultLatitude),
            lng: Number(dealerCountryPage.defaultLongitude)
          })
            .then(() => {
              setMapZoom(Number(dealerCountryPage.defaultZoomLevel))
            })
            .then(() => {
              setDefaultMapZoom(Number(dealerCountryPage.defaultZoomLevel))
            })
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      {content.hero &&
      (content.hero.bigTitle || content.hero.backgroundImage) ? (
        <BlockResolver content={content.hero} pageInfo={pageInfo} />
      ) : null}

      <ContainerWrapper>
        <Box width="100%">
          <Box
            mt={isMdUp ? 6 : 5}
            mb={isMdUp ? 4 : 0}
            {...(!isMdUp && { marginX: "16px" })}
          >
            <Typography variant="headline3" align="center">
              {t("contact.google_maps_title")}
            </Typography>
          </Box>

          {isMdUp && (
            <Box position="relative" height="75vh" mt={2} mb={3}>
              <Box height="100%" className={classes.map}>
                {!mapSearch.data && <MapLoadingSpinner />}
                <GoogleMaps />
              </Box>
              {mapSearch.data && (
                <Box className={classes.dealerPanel}>
                  <div className={classes.searchFieldWrapper}>
                    <SearchField />
                    {searchedDealersRef.current && (
                      <DisplayNumberOfDealersShowing
                        dealersInList={dealersInList.length}
                        totalNumberOfDealers={searchedDealersRef.current.length}
                      />
                    )}
                  </div>
                  <DealerPanel
                    searchedDealersRef={searchedDealersRef}
                    dealersInList={dealersInList}
                    onSetDealersInList={(dealers) => setDealersInList(dealers)}
                  />
                </Box>
              )}
            </Box>
          )}

          {!isMdUp && (
            <Box position="relative">
              <Box p={2}>
                {mapOpen ? (
                  <>
                    <SearchField />
                    <Box position="relative" width="100%" height={549} mt={2}>
                      {!mapSearch.data && <MapLoadingSpinner />}

                      <GoogleMaps />
                    </Box>
                  </>
                ) : (
                  <Box width="100%">
                    <div className={classes.searchFieldWrapper}>
                      <SearchField />
                      {searchedDealersRef.current && (
                        <DisplayNumberOfDealersShowing
                          dealersInList={dealersInList.length}
                          totalNumberOfDealers={
                            searchedDealersRef.current.length
                          }
                        />
                      )}
                    </div>
                    <DealerPanel
                      searchedDealersRef={searchedDealersRef}
                      dealersInList={dealersInList}
                      onSetDealersInList={(dealers) =>
                        setDealersInList(dealers)
                      }
                    />
                  </Box>
                )}
              </Box>

              <DealerDialog />

              {mapSearch.data && (
                <IconButton
                  className={classNames(classes.toggleMapIconButton, {
                    [classes.mapIcon]: !mapOpen
                  })}
                  onClick={() => setMapOpen(!mapOpen)}
                  aria-label={mapOpen ? "list-icon" : "map-icon"}
                >
                  {mapOpen ? <ListIcon /> : <MapIcon />}
                </IconButton>
              )}
            </Box>
          )}

          {allHeadquarters && (
            <Box display="flex" justifyContent="center" flexWrap="wrap">
              {allHeadquarters.map((headQuarter) => {
                return (
                  <Box
                    clone
                    display="flex"
                    alignItems="center"
                    key={headQuarter.id}
                  >
                    <ContactHeadquarter {...headQuarter} />
                  </Box>
                )
              })}
            </Box>
          )}

          <DisplayOptionsContentArea
            content={content.mainContent}
            propInfo={propsInfo.mainContent}
            component={SectionComponent}
            pageInfo={pageInfo}
            containWhen={({ _display: display, _type: type }) =>
              (type &&
                type !== "SectionBlock" &&
                display &&
                (display === "auto" || DisplayOptions[display] !== 1)) ||
              false
            }
            childContainer={ChildContainer}
            container={ContainerWrapper}
          />
        </Box>
      </ContainerWrapper>
    </>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    dealerPanel: {
      position: "absolute",
      top: "5%",
      left: 20,
      height: "90%",
      overflowY: "auto",
      width: 400,
      backgroundColor: themeColors.white,
      [breakpoints.up("lg")]: {
        width: 476
      }
    },
    searchFieldWrapper: {
      backgroundColor: themeColors.white,
      position: "sticky",
      top: 0,
      zIndex: 1,
      [breakpoints.up("md")]: {
        padding: spacing(2, 2, 0)
      }
    },
    map: {
      "& .gm-ui-hover-effect": {
        visibility: "hidden"
      },
      "& .gm-style .gm-style-iw": {
        background: themeColors.white,
        borderRadius: 0,
        left: 125,
        padding: 0,
        [breakpoints.between(960, 1248)]: {
          left: 150
        },
        "& .gm-style-iw-c": {
          overflow: "auto !important"
        },
        "& .gm-style-iw-d": {
          overflow: "auto !important"
        }
      },
      "& .gm-style-iw-t::after": {
        background: themeColors.white
      }
    },
    toggleMapIconButton: {
      position: "absolute",
      top: 90,
      right: 24,
      backgroundColor: "#0091FF !important",
      borderRadius: 0,
      padding: spacing(1.25, 2),
      zIndex: 2
    },
    mapIcon: {
      "& .MuiSvgIcon-root": {
        fill: "none"
      }
    }
  })
)

export default DealerPage
