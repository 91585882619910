import { useTranslation } from "react-i18next"

import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
// Material component
import { makeStyles, Theme } from "@material-ui/core/styles"

import { Order } from "api/orders"
// Inner component
import Typography from "common/Typography"

type Props = {
  order: Order
}

const OrderSummary: React.FC<Props> = ({ order }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.row}>
        <div className={classes.textContainer}>
          <div className={classes.leftText}>
            <Typography variant={"body2"} className={classes.bold}>
              {t("common.subtotal")}&nbsp;
            </Typography>
            <Typography variant={"body2"}>
              {order.numberOfItems} {t("common.items_total")}
            </Typography>
          </div>
        </div>
        <div className={classes.rightText}>
          <Typography variant={"body2"}>{order.subTotalString}</Typography>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <div className={classes.textContainer}>
          <div className={classes.leftText}>
            <Typography variant={"body2"} className={classes.bold}>
              {t("checkout.discount")}
            </Typography>
          </div>
        </div>
        <div className={classes.rightText}>
          <Typography variant={"body2"} className={classes.red}>
            {order.discountTotalString}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <div className={classes.textContainer}>
          <div className={classes.leftText}>
            <Typography variant={"body2"} className={classes.bold}>
              {t("common.shipping")}
            </Typography>
          </div>
        </div>
        <div className={classes.rightText}>
          <Typography variant={"body2"}>{order.shippingTotalString}</Typography>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <div className={classes.textContainer}>
          <div className={classes.leftText}>
            <Typography variant={"priceSmall"}>
              {t("checkout.total_excluding_vat")}
            </Typography>
          </div>
        </div>
        <div className={classes.rightText}>
          <Typography variant={"priceSmall"}>{order.totalString}</Typography>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.row}>
        <div className={classes.textContainer}>
          <div className={classes.leftText}>
            <Typography variant={"priceSmall"}>
              {t("checkout.total_vat")}
            </Typography>
          </div>
        </div>
        <div className={classes.rightText}>
          <Typography variant={"priceSmall"}>
            {order.properties.sapVatAmountString}
          </Typography>
        </div>
      </Grid>
      <Divider className={classes.border} />
      <Grid item xs={12} className={classes.row}>
        <div className={classes.textContainer}>
          <div className={classes.leftText}>
            <Typography variant={"priceSmall"}>
              {t("checkout.total_including_vat")}
            </Typography>
          </div>
        </div>
        <div className={classes.rightText}>
          <Typography variant={"priceSmall"}>
            {order.properties.totalCostIncVatString}
          </Typography>
        </div>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors, gridSpacing }, breakpoints }: Theme) => ({
    root: {
      width: "100%",
      position: "relative"
    },
    row: {
      display: "flex",
      width: "100%"
    },
    textContainer: {
      flexWrap: "wrap",
      width: "100%",
      display: "flex",
      alignItems: "flex-start",
      [breakpoints.up("sm")]: {
        flexWrap: "nowrap",
        width: "auto",
        maxWidth: "100%",
        whiteSpace: "nowrap"
      }
    },
    leftText: {
      display: "flex",
      [breakpoints.up("sm")]: {
        whiteSpace: "nowrap"
      }
    },
    rightText: {
      width: "100%",
      display: "flex",
      textAlign: "right",
      justifyContent: "flex-end"
    },
    border: {
      width: "100%",
      marginTop: gridSpacing,
      marginBottom: gridSpacing,
      backgroundColor: "transparent",
      borderBottom: `1px solid ${themeColors.lightGray}`
    },
    red: {
      color: themeColors.primaryRed
    },
    bold: {
      fontWeight: "bold"
    },
    totalItems: {
      fontWeight: "bold",
      display: "flex"
    }
  })
)

export default OrderSummary
