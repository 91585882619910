import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

type Props = {
  className?: string
}

const ButtonLoaderIcon: React.FC<Props> = ({ className }) => {
  const classes = useStyles()

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(className, classes.root)}
    >
      <g>
        <path
          opacity="0.4"
          d="M3.05248 14.2868C-0.414042 11.559 -1.01499 6.51924 1.71289 3.05272C4.44077 -0.413795 9.48049 -1.01475 12.947 1.71314C16.4135 4.44102 17.0145 9.48074 14.2866 12.9473C11.5587 16.4138 6.519 17.0147 3.05248 14.2868ZM11.7102 3.28485C9.11011 1.23879 5.33067 1.68946 3.2846 4.28954C1.23854 6.88962 1.68921 10.6691 4.28929 12.7151C6.88938 14.7612 10.6688 14.3105 12.7149 11.7104C14.7609 9.11036 14.3103 5.33091 11.7102 3.28485Z"
          fill="currentColor"
        />
        <path
          d="M14.2869 12.9473L12.7151 11.7104C14.7612 9.11036 14.3105 5.33091 11.7104 3.28485L12.9473 1.71313C16.4138 4.44102 17.0147 9.48074 14.2869 12.9473Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

const useStyles = makeStyles(() => ({
  "@keyframes rotate": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" }
  },
  root: {
    animationName: "$rotate",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
    animationDuration: "1s"
  }
}))

export default ButtonLoaderIcon
