import { createStore } from "global-hook-store"

const AddToCartStore = createStore(
  {
    quantity: 1,
    isLoading: false
  },
  {
    setQuantity: (currentState, quantity) => ({
      ...currentState,
      quantity
    }),
    setIsLoading: (state, isLoading: boolean) => ({
      ...state,
      isLoading
    })
  }
)

export default AddToCartStore
