import { ProductVariationsResponse } from "@starrepublic/epi/api/products"
import request from "@starrepublic/epi/api/request"

import Variant from "models/Variant"

const products = {
  getVariations: (productId: number) => {
    return request.get<ProductVariationsResponse<Variant>>(
      `/products/${productId}/variations?limit=50`
    )
  }
}

export default products
