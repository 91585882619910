import { asyncState, createStore } from "global-hook-store"

import api, {
  SendModel,
  SendToSelfModel,
  UpdateListArticle
} from "api/anonymousShoppingList"

export type Article = {
  name: string
  variationValue: string
  articleNumber: string
  price: string
  quantity: number
  inventoryStatus: string
  imageUrl: string
  url: string
}

export type AnonymousShoppingList = {
  id: string
  name: string
  created: Date
  modified: Date
  creatorId: string
  creatorName: string
  ownerId: string
  ownerName: string
  totalQuantity: number
  totalPrice: string
  articles: Article[]
}

const AnonymousShoppingListStore = createStore(
  {
    isLoading: false,
    isOpen: false,
    email: "",
    updatedShoppingList: asyncState<AnonymousShoppingList>(),
    addToShoppingList: asyncState<AnonymousShoppingList>(),
    shoppingList: asyncState<AnonymousShoppingList>(),
    deleteShoppingList: asyncState<AnonymousShoppingList>(),
    savedPDF: asyncState<AnonymousShoppingList>(),
    sent: asyncState<AnonymousShoppingList>(),
    sentToSelf: asyncState<AnonymousShoppingList>()
  },
  {
    loading: (state, isLoading) => ({
      ...state,
      isLoading
    }),
    setEmail: (state, email) => ({
      ...state,
      email
    }),
    open: (state, _: null) => {
      return { ...state, isOpen: true }
    },
    close: (state, __: null) => {
      return { ...state, isOpen: false }
    },
    fetchShoppingList: async (_, __: null, { asyncAction }) =>
      asyncAction("shoppingList", api.getShoppingList()),
    updateShoppingList: async (
      _,
      payload: UpdateListArticle,
      { asyncAction }
    ) => asyncAction("updatedShoppingList", api.updateShoppingList(payload)),
    addToShoppingList: async (_, payload: UpdateListArticle, { asyncAction }) =>
      asyncAction("addToShoppingList", api.addToShoppingList(payload)),
    deleteShoppingList: (_, __: null, { asyncAction }) =>
      asyncAction("deleteShoppingList", api.deleteShoppingList()),
    savePDF: async (_, __: null, { asyncAction }) =>
      asyncAction("savedPDF", api.savePDF()),
    send: (_, payload: SendModel, { asyncAction }) =>
      asyncAction("sent", api.send(payload)),
    sendToSelf: (_, toEmail: SendToSelfModel, { asyncAction }) =>
      asyncAction("sentToSelf", api.sendToSelf(toEmail))
  }
)

export default AnonymousShoppingListStore
