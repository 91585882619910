import { BlockResolver, ContentProps, PageInfo } from "@starrepublic/epi/cms"
import DisplayOptionsContentArea from "@starrepublic/epi/cms/components/DisplayOptionsContentArea"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import DisplayOptions from "utils/ui/DisplayOptions"

import ArticleCard from "blocks/ContentCarouselBlock/ArticleCard"
import BreadcrumbsBar from "common/BreadcrumbsBar"
import {
  ChildContainer,
  ContainerWrapper,
  SectionComponent
} from "common/contentAreaContainers"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import Wrapper from "common/Wrapper"
import { ImageBlock } from "types"
import InformationPageMenu from "./InformationPageMenu"

type Props = { pageInfo: PageInfo } & ContentProps

type Article = {
  image: ImageBlock
  category: string
  url: string
  heading: string
  text: string
}

const InformationPage: React.FC<Props> = ({ content, pageInfo, propsInfo }) => {
  const classes = useStyles()
  const { mainContent, mainBody, subNavigation, preamble, _subType, archive } =
    content

  const isArchive = _subType === "Archive" ? true : false

  return (
    <>
      <BreadcrumbsBar />
      {content.hero &&
      (content.hero.bigTitle || content.hero.backgroundImage) ? (
        <BlockResolver content={content.hero} pageInfo={pageInfo} />
      ) : null}
      {!preamble && !mainBody && !mainContent && !subNavigation ? null : (
        <div>
          <ContainerWrapper>
            <Grid
              justifyContent="center"
              container={true}
              className={classes.contentContainer}
            >
              {subNavigation && (
                <Grid className={classes.stickyContainer} item>
                  <InformationPageMenu subNavigation={subNavigation} />
                </Grid>
              )}
              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
                direction="column"
                className={classes.root}
              >
                {mainBody && (
                  <Grid item className={classes.body}>
                    <ThemeXhtmlString content={mainBody} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </ContainerWrapper>
          {mainContent && (
            <DisplayOptionsContentArea
              content={mainContent}
              propInfo={propsInfo.mainContent}
              component={SectionComponent}
              pageInfo={pageInfo}
              containWhen={({ _display: display, _type: type }) =>
                (type &&
                  type !== "SectionBlock" &&
                  display &&
                  (display === "auto" || DisplayOptions[display] !== 1)) ||
                false
              }
              childContainer={ChildContainer}
              container={ContainerWrapper}
            />
          )}
        </div>
      )}
      {isArchive && (
        <Wrapper className={classes.informationPageWrapper}>
          <Grid container>
            {archive.map((item: Article, key: number) => {
              return (
                <Grid
                  className={classes.articleContainer}
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  key={key}
                >
                  <ArticleCard {...item} />
                </Grid>
              )
            })}
          </Grid>
        </Wrapper>
      )}
    </>
  )
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    marginBottom: spacing(3),
    [breakpoints.up("md")]: {
      flex: "1 1 auto"
    }
  },
  contentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  informationPageWrapper: {
    width: "100%",
    maxWidth: 1414,
    marginInline: "auto",
    marginBottom: "91px",
    padding: `0 ${spacing(3)}px`,
    [breakpoints.up("sm")]: {
      padding: `0 24px`
    },
    [breakpoints.up(768)]: {
      padding: `0 91px`
    },
    [breakpoints.up("md")]: {
      marginTop: "91px"
    }
  },
  stickyContainer: {
    padding: spacing(0, 1.5),
    position: "relative",
    top: "-12px",
    zIndex: 1
  },
  body: {
    padding: spacing(3, 1.5),
    marginTop: spacing(4),
    marginBottom: spacing(4),
    width: "100%"
    // REFACTOR: This could be used to make images behave more consistently. Needs to be synced with editors, example: sv/radgivning/elstangselskolan/monteringsanvisningarplaneringsguider/ || sv/radgivning/inspirerad-av-delpro/lar-dig-mer-om-utfodring/kontroll-over-din-utfodringsstrategi/
    // "& img": {
    //   width: 100%;
    //   max-width: 628px;
    //   display: block;
    //   margin: 1.5rem auto;
    // }
  },
  articleContainer: {
    padding: `${spacing(1.5)}px ${spacing(1.5)}px ${spacing(3)}px`,
    [breakpoints.down("sm")]: {
      padding: spacing(1)
    }
  }
}))

export default InformationPage
