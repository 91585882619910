import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"

import Button from "common/Button"
import LinkButton from "common/LinkButton"
import ArrowRight from "icons/ArrowRight"
import ProductDetails from "pages/WebProduct/ProductDetails"
import ProductListingStore from "store/ProductListingStore"

const WebNodeListing = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    state: { quickViewProduct, quickViewOpen },
    actions: { setQuickViewOpen }
  } = useStore(ProductListingStore)

  const handleClose = () => {
    setQuickViewOpen(false)
  }

  return (
    <Dialog
      aria-labelledby="product-quick-view-modal"
      aria-describedby=""
      open={quickViewOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={classes.title}>
        <IconButton id="close-product-quick-view-modal" onClick={handleClose}>
          <div>
            <CloseIcon />
          </div>
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {quickViewProduct.data && (
          <ProductDetails
            content={quickViewProduct.data}
            pageRoute={
              (quickViewProduct.data && quickViewProduct.data._route) || ""
            }
            pageId={(quickViewProduct.data && quickViewProduct.data._id) || 0}
            compactLayout={true}
          />
        )}
      </DialogContent>
      {quickViewProduct.data && quickViewProduct.data._route && (
        <DialogActions className={classes.footer}>
          <LinkButton onClick={handleClose} link={quickViewProduct.data._route}>
            <Button endIcon={<ArrowRight />} variant="text">
              {t("product.navigate_to_product_page")}
            </Button>
          </LinkButton>
        </DialogActions>
      )}
    </Dialog>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  content: { padding: 0, margin: 0 },
  title: {
    textAlign: "right",
    padding: 0
  },
  footer: {
    backgroundColor: themeColors.offWhite
  },
  arrowRightWrapper: { marginLeft: spacing(1) }
}))

export default WebNodeListing
