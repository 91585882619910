import { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"
import { SnackbarKey, useSnackbar } from "notistack"

import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"

import Button from "common/Button"
import Image from "common/Image"
import ProductPrice from "common/Product/ProductPrice"
import Typography from "common/Typography"
import DrawerStateStore from "store/DrawerStateStore"
import SiteSettingsStore from "store/SiteSettingStore"
import { SnackBarOptions } from "store/SnackbarStore"

type Props = {
  id: SnackbarKey
  options: SnackBarOptions
}

const ProductSnackbar = forwardRef<HTMLDivElement, Props>(
  ({ id, options }, ref) => {
    const {
      state: { _siteType }
    } = useStore(SiteSettingsStore)

    const {
      actions: { setCartDrawer, setShoppingListDrawer }
    } = useStore(DrawerStateStore)

    const { closeSnackbar } = useSnackbar()
    const classes = useStyles()
    const { t } = useTranslation()
    const isWebShop = _siteType === "webShop"
    const noWebShop =
      _siteType === "noWebShop" || _siteType === "webShopMigration"

    const handleCartDrawer = () => {
      if (noWebShop) {
        setShoppingListDrawer(true)
        closeSnackbar(id)
      } else {
        setCartDrawer(true)
        closeSnackbar(id)
      }
    }
    return (
      <SnackbarContent
        ref={ref}
        className={classes.product}
        aria-describedby="client-snackbar"
        message={
          <Grid container direction="column">
            <Grid
              wrap="nowrap"
              className={classes.spacing}
              item
              container
              justifyContent="space-between"
            >
              <Typography
                variant="headline6"
                id="client-snackbar"
                className={classes.message}
              >
                {options.message}
              </Typography>

              <IconButton
                className={classes.icon}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => {
                  closeSnackbar(id)
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider />
            {options && (
              <Grid
                className={classes.productContainer}
                container
                alignItems="center"
              >
                {options.image && (
                  <Grid>
                    <Image
                      className={classes.productImage}
                      containerProps={{
                        className: classes.imageContainer
                      }}
                      src={options.image}
                      lazy
                    />
                  </Grid>
                )}
                <Grid>
                  <Typography variant="smallText">{options.code}</Typography>
                  <Typography variant="subheading1">
                    {options.displayName}
                  </Typography>
                  <Typography variant="smallText">
                    {options.variationValue}
                  </Typography>
                  {options.displayPrice && isWebShop && (
                    <ProductPrice
                      price={options.displayPrice}
                      discountedPrice={options.discountedPrice}
                      size={"small"}
                      className={classes.variantPrice}
                    />
                  )}
                </Grid>
              </Grid>
            )}
            <Grid className={classes.spacing}>
              <Button
                variant="secondary"
                className={classes.viewCartButton}
                onClick={() => handleCartDrawer()}
              >
                {noWebShop
                  ? t("cart.open_shopping_list_snackbar_button")
                  : t("cart.open_cart_snackbar_button")}
              </Button>
            </Grid>
          </Grid>
        }
      />
    )
  }
)

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    product: {
      minWidth: 466,
      backgroundColor: themeColors.white,
      boxShadow:
        "0px 0px 1px rgba(0, 0, 0, 0.12), 0px 12px 32px rgba(0, 0, 0, 0.14);",
      padding: 0,
      "& .MuiSnackbarContent-message": {
        width: "100%",
        padding: 0
      },
      [breakpoints.down("xs")]: {
        minWidth: "auto"
      }
    },
    icon: {
      color: themeColors.darkGray,
      fontSize: 20,
      padding: `0px 0px 0px ${spacing(1)}px`
    },
    message: {
      display: "flex",
      alignItems: "center"
    },
    viewCartButton: {
      width: "100%",
      margin: 0
    },
    spacing: {
      padding: spacing(3)
    },
    productContainer: {
      padding: `${spacing(3)}px ${spacing(3)}px 0px `
    },
    variantPrice: {
      fontSize: 16
    },
    productImage: {
      width: 96,
      height: 72,
      objectFit: "cover",
      position: "initial"
    },
    imageContainer: {
      display: "flex",
      marginRight: spacing(2)
    }
  })
)

export default ProductSnackbar
