import { asyncState, createStore } from "global-hook-store"

import ContactApi, { ContactPayload } from "api/contact"

const ContactModalStore = createStore(
  {
    isOpen: false,
    result: asyncState<string>()
  },
  {
    open: (state, _: null) => {
      return { ...state, isOpen: true }
    },
    close: (state, __: null) => {
      return { ...state, isOpen: false }
    },
    send: async (_, payload: ContactPayload, { asyncAction }) =>
      asyncAction("result", ContactApi.send(payload)),
    reset: (_, __: null, { reset }) => {
      return reset()
    }
  }
)
export default ContactModalStore
