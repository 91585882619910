import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"

type Props = {
  header: string
  subHeader: string
  selectedVariation?: string
}

const ProductTitle: React.FC<Props> = ({
  header,
  subHeader,
  selectedVariation
}) => {
  const classes = useStyles()

  const subHeading = selectedVariation
    ? subHeader
      ? `${subHeader}, ${selectedVariation}`
      : selectedVariation
    : subHeader

  return (
    <div className={classes.root}>
      <Typography
        textWrap="wrap"
        className={classes.headline}
        variant="headline1"
      >
        {header}
      </Typography>
      <Typography
        textWrap="wrap"
        className={classes.headline2}
        variant="subheading1"
      >
        {subHeading}
      </Typography>
    </div>
  )
}
const useStyles = makeStyles(({ common: { gridSpacing }, breakpoints }) => ({
  root: {
    width: "100%",
    marginBottom: gridSpacing
  },
  headline: {
    [breakpoints.down("sm")]: {
      fontSize: 24
    }
  },
  headline2: {
    [breakpoints.down("sm")]: {
      fontSize: 16
    }
  }
}))

export default ProductTitle
