import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import RemoveIcon from "icons/CloseDeLaval"
import AuthStore from "store/AuthStore"
import snackbarStore from "store/SnackbarStore"

type DeleteShippingAddressType = {
  shippingAddressId: string
  streetAddress: string
  zipCode: string
  city: string
}

const DeleteShippingAdressDialog: React.FC<DeleteShippingAddressType> = ({
  shippingAddressId,
  streetAddress,
  zipCode,
  city
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    state: { user, deletedShippingAddress },
    actions: { deleteShippingAddress, getUser }
  } = useStore(AuthStore)

  const {
    actions: { openSnackbar }
  } = useStore(snackbarStore)

  const [open, setOpen] = useState(false)
  const [defaultUserId, setUserDefaultId] = useState(false)

  const handleDelete = async (
    event: React.MouseEvent<HTMLElement>,
    deliveryId: string
  ) => {
    event.stopPropagation()
    try {
      await deleteShippingAddress(deliveryId)
      await getUser()
      setOpen(false) // Close the dialog only if the deletion was successful
    } catch (error) {
      if (deletedShippingAddress.error) {
        openSnackbar({
          options: {
            message: deletedShippingAddress.error.toString()
          },
          type: "error"
        })
      }
    }
  }

  useEffect(() => {
    if (shippingAddressId && user.data && user.data.sapCustomerNumber) {
      if (shippingAddressId === user.data.sapCustomerNumber) {
        setUserDefaultId(true)
      }
    }
  }, [shippingAddressId, user.data])

  const handleClose = (event: any) => {
    event.stopPropagation()
    setOpen(false)
  }

  const handleOpen = (event: any) => {
    event.stopPropagation()
    setOpen(true)
  }

  return (
    <>
      <Box
        display="flex"
        width={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography>{`${streetAddress}, ${zipCode} ${city}`}</Typography>
        </Box>
        {!defaultUserId && (
          <Box
            id="removeIcon"
            onClick={(event) => handleOpen(event)}
            display="flex"
            className={classes.removeIcon}
          >
            <RemoveIcon />
          </Box>
        )}
      </Box>
      <Dialog open={open} onClose={(event) => handleClose(event)}>
        <DialogTitle id="alert-dialog-title">
          <Typography bold>
            {t("account.delete_shipping_address_title")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {`${t("account.delete_shipping_address_preamble")} ${t(
              "checkout.add_new_shipping_address_label"
            )}`}
          </Typography>
          {deletedShippingAddress.error && (
            <Typography>{deletedShippingAddress.error}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={handleClose}
            variant="secondary"
          >
            {t("account.delete_shipping_address_button_cancel")}
          </Button>
          <Button
            className={classes.button}
            variant="secondary"
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              handleDelete(event, shippingAddressId)
            }
          >
            {deletedShippingAddress.loading ? (
              <ButtonLoaderIcon />
            ) : (
              t("account.delete_shipping_address_button_yes")
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors, gridSpacing } }) => ({
    removeIcon: {
      position: "absolute",
      right: 8,
      zIndex: 1,
      padding: spacing(0.5),
      "&:hover": {
        backgroundColor: themeColors.lightGray,
        borderRadius: "50%"
      }
    },
    button: {
      height: 45,
      width: 120
    }
  })
)

export default DeleteShippingAdressDialog
