import Box, { BoxProps } from "@material-ui/core/Box"
import { useTheme } from "@material-ui/core/styles"

type Props = {
  display?: string
  className?: string
  paddingY?: boolean
} & BoxProps

const BlockWrapper: React.FC<Props> = (props) => {
  const { display = "auto", children, paddingY, ...boxProps } = props
  const theme = useTheme()

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      py={{ xs: paddingY ? 2 : 0, sm: paddingY ? 3 : 0 }}
      {...boxProps}
    >
      <Box
        width="100%"
        maxWidth={{
          xs: "auto",
          sm: theme.breakpoints.values.md,
          lg: theme.common.pageMaxWidth
        }}
        px={{
          xs: display.startsWith("full" || "auto") ? 3 : 0, // TODO: Does this "full" || "auto" work?
          md: display.startsWith("full" || "auto") ? 1.5 : 0
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default BlockWrapper
