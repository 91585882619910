import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const WeChatQR: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 128 128" {...props}>
    <rect width="128" height="128" rx="4" fill="#fff" />
    <path
      d="M62.4 21.8h3.2V31H78v-3h3.2V25h3v3h3.2v12.6h6.2v3h3.2V50h-6.3v-6.2h-3.2v3h-3v-3H78v3h6.2V50h-6.2v6.2h6.2V50h3V53h6.3v3.2h-6.2v3h-3.1v3.1H78v-3h-6.3v-3h-3v9.2h-3.1V50h3.1v3h3v-3H75V37.4h-3.2v9.3h-3v-6.1h-3.1v-6.3h-3.2V31h-3v-3h3v-6.3Zm18.8 12.5h3V31h-3v3.2h-3.1v3h3.1v-3ZM53 106.1v3h-6.2v3.2h-3v-6.2h-9.4v3h-3.2V103h15.5v-3h-6v-6.3h3v-3.1h-6.3v-3.2h-3v-3h12.4v3H50v3.2h-3.2v3H50v3.2h6.2v-6.2H53v-3.2h9.4v-3h3.2V81h3v12.5h3.2v3.2H78v3h-9.3v-3h-3v-3.2h-3.3v3.2h-3v3h3v-3h3.2v3h3v9.4h3.1v3.2h-3v6.1h-6.3v-3h3.2v-12.6h-9.4v-3H50v6.2h3ZM31.2 59.3h3.2v3h3v-6h-3v-6.4h3v-3.1h-6.2v6.1H25v-3h3v-3.2h-3v-3h3v-3.1h-3v-3.2h9.4v3.2h-3.2v3h3.2v-3h3v-6.3h3.2v6.3h3.1v3h-3v3.1h3V50h3v6.3h-3V53h-3v6.3h3v6.3H50v3H28v-3h3v-6.3Z"
      fill="#103D82"
    />
    <path
      d="M9.4 62.3v-3h3v-3h9.4v12.3h6.3v3.1h-3V75h6v6.3h-3V78h-6.3v3.2h-3v3h3v3.1h6.3v3.2h-9.3v3h3v3.2h-6.2v-3.1H9.4v-3.1h3v-3.2h3.1v-6.2h-3V78h3v-3h6.3v-3.3h-9.3v-3h-3v-3.2h-.1 3v-3.2h-3Zm9.3-3h-3.1v6.2h3.1v-6.2ZM78 28h-3v-3h-3.3v-6.3h-3v-3.2H56.3V6.2h3v3.1h3v3H75v9.4h3v-3h9.4v-3.2h3.2V9.3h-6.2V6.3h9.3v3.1h3.2v9.4h-3.1v3h3.1v9.4h-3.2V25h-3v-3.2h-3.2V25h-3.1v-3.2h-3.1V25H78v3ZM84.3 81.1v3.1h3v3h-3v3.3h6.2v3h-3.2v3.3h-3v-3.2h-3.1v-3.1H75v-3.2h-3.2V81h-3v-9.4h-3.1v-3.1h3v-3H75v-3.3h3v3.2h6.3v-3.2h3v6.3h-6.1v3h-3.1V78h3.1v3.2h3Zm-9.4-12.4h-3.1v6.1h3.1v-6.1Zm3 12.5h-3v3h3v-3ZM96.8 90.5h9.3v3h6.3v3.2h-3.1v9.4h3.2v3h-3.3v6.4h-3v-6.3h-6.3v3.1h-3v-3.1h-3.3V96.8h-3v-3.2h6.2v-3Zm0 6.3v9.3h9.3v-9.3H97ZM6.3 6.2H28V28H6.3V6.2Zm3.2 3.2v15.5H25V9.4H9.5ZM100 6.2h21.8V28H99.9V6.2Zm3 18.7h15.5V9.4H103v15.5ZM28 99.9v21.8H6.4V99.9H28Zm-3 3H9.4v15.6H25V103ZM15.6 50H9.4v6.2H6.3V37.4h3.1v3.1h3v-3.1h3.2v-3.1h3.1v-3.2h9.4v3.2h-6.3v3h-3v6.3h-6.3v3h3V50ZM109.3 50v-6.4h6.2v3h3v-3h-3v-3h3v-3.2h-3v-3h3V31h3.3V53h-3.2v3.2h-3V53h-3.1v-3h-3.2ZM103 53h3.2v6.3H103v3h-3v-3h-3.1v6.2h3v3.1h6.3v3H90.6v-3h3V56.2h9.4V53ZM34.4 115.5H50v-3h3v6h3.3v3.2h-22v-6.3ZM115.5 74.9h6.2v18.7h-3v-3h-3.2v-3.3h3v-3h-3v-9.4ZM109.3 65.6v-9.4h3.1v3h9.3v9.4h-3v-3h-9.4ZM81.2 112.4h-3.1V106h6.2V103h6.2v6.3h-3.2v3.2h-3v-3.2h-3v3.2ZM103 118.5v3.2h-9.4v-3.2h-9.3v-3h15.6v3h3ZM115.5 87.3h-6.2V78h-3v-3.1h6.2v9.3h3v3.1Z"
      fill="#103D82"
    />
    <path
      d="M37.4 90.5v6.3h-3v-3.2h-3.2v3.2H25v-3.2h3v-3.1h9.3ZM96.8 87.3v-6.2h-3.2V78h6.3V81h3v6.2h-6ZM40.6 34.3V28h3.1v3h9.4v3.3H40.6ZM99.9 34.3h6.2v3h3.2v3.3h-9.4v-6.3ZM43.7 15.5V9.3h-3V6.3h9.2v3.1h-3.1v6.2h-3.1ZM118.6 115.5v-3.1h-6.1v-3.3h9.2v6.3h-3.1ZM40.6 9.3v3h-6.2v-3h-3.2V6.3h6.2v3.1h3.2ZM71.7 112.4H75v3h3v6.3h-3v-3.2h-3.2v-6.1ZM118.6 96.8h3.1v6h-9.2v-3h6.1v-3ZM99.9 43.7h6.2v3H103V53h-3v-9.4h-.1ZM81.2 71.7h3v3.2h3.1V81h-3V78h-3.1v-6.2ZM12.5 81.1v3H6.2V78h3.2v3.2h3Z"
      fill="#103D82"
    />
    <path
      d="M46.8 15.5H53v6.3h-3v-3.1h-3.2v-3.2ZM118.6 115.4v3.1h3.2v3.2h-6.2v-6.2h3ZM40.6 25v-9.5h3.1V25h-3ZM84.3 9.3v3.1h-3v3H78v-6h6.2ZM109.3 37.4v-6.2h3.1v6.2h-3.2ZM65.6 9.3V6.2h6v3.1h-6ZM34.4 18.7h3V25h-3v-6.3ZM31.2 59.3H25v-3h6v3ZM84.3 102.9h-3v-6.1h-.1 3v6.1h.1ZM9.4 90.5H6.3v-3.2h3.1v3.2ZM25 50h-3.2v-3.3H25V50ZM96.8 87.3v3.2h-3.1v-3.2h3.1ZM9.4 93.6v3.1H6.2v-3.1h3.2ZM53 21.8h3.2V25H53v-3.2ZM81.2 96.8H78v-3.2h3.2v3.2ZM56.2 102.9v3.2H53V103h3.3ZM74.9 106.1h-3.1V103h3.1v3.1ZM9.4 65.5H6.3v-3.2h3.1v3.2ZM40.6 112.4h-3.1v-3.2h3v3.2ZM31.2 31.1h3.1v3.2h-3V31ZM34.4 18.7h-3.2v-3.2h3.2v3.2ZM112.5 74.9v-3.2h3V75h-3ZM109.2 118.5v3.2h-3v-3.2h3ZM84.3 118.5v3.2h-3v-3.2h3ZM28.1 65.5h-3v-3.1h3v3.1ZM71.7 118.5v3.2h-3v-3.2h3ZM59.3 118.6h3.1v3h-3v-3h-.1ZM109.2 115.5h3.2v3h-3.2v-3ZM106.2 118.5H103v-3h3.2v3ZM15.5 50h3.2v3h-3.1v-3ZM34.4 115.4h-3.2v-3h3.2v3ZM12.5 34.3v-3.2h3v3.2h-3ZM59.3 109.2h3v3.1h-3v-3.1ZM96.8 31.1h3v3.2h.1-3v-3.2ZM37.4 25h3.2v3h-3.2v-3ZM21.8 43.7v-3.1H25v3h-3.2ZM118.6 96.8h-3v-3.2h3v3.2ZM34.4 96.8v3h-3.2v-3h3.2ZM34.4 25v3h-3.2v-3h3.2ZM9.4 37.4v-3h3v-.1 3h-3ZM99.9 40.6v3h-3v-3h3ZM21.8 46.7h-3v-3h3v3Z"
      fill="#103D82"
    />
    <path
      d="M56.2 106.1h3.1v3.1h-3v-3.1ZM93.6 81.1v3h-3v-3h3ZM84.3 115.4h-3v-3h-.1 3v3h.1ZM56.3 118.5v-3h3v3h-3ZM71.7 25v3h-3v-3h3ZM106.2 53v-3h3v3h-3ZM56.2 25h3.1v3h-3v-3ZM62.4 21.8h-3v-3h3v3ZM100 78v-3h3v3h-3.1ZM106.2 68.6v-3h3v3h-3ZM62.4 50v-3.3h-3v-9.3h3v6.2h3.2V50h-3.2ZM53 34.3h3.2v-3.2h3.1v6.3H53v-3.1ZM50 68.6h3v3h3.3V78h3v-3h3.1v-3.2h3.2v9.5h-3.2v3h-3v-3h-3.1v3H53v-3h-3V78h3v-3.1h-6.2v-3.2H50v-3.1Z"
      fill="#103D82"
    />
    <path
      d="M46.8 56.2H50v-9.5h3v-3h3.2v6.2h6.2v3h-9.3v6.4H50v3h-3.2v-6.1Z"
      fill="#103D82"
    />
    <path
      d="M53 68.6v-3h6.3v-3.2H53v-3.1h6.3v-3h3v15.4h.1-6.2v-3.1H53ZM87.3 25h3.3v3h-3.3v-3ZM93.6 31.1v3.2h-3v-3.2h3ZM93.6 34.3h3.2v3h-3.2v-3ZM54.7 31.8c1.2 0 2.2-1 2.2-2.2 0-1.2-1-2.2-2.2-2.2-1.3 0-2.3 1-2.3 2.2 0 1.2 1 2.2 2.3 2.2ZM37.4 74.9h6.3V81h-3V78h-3.3v-3.1ZM37.4 74.9h-3v-3.2h3V75ZM34.4 84.2h-3.2v-3h3.2v3ZM50 84.2h-3.2v-3H50v3ZM28 81.1v3h-3v-3h3.1ZM53 87.3h-3v-3h3v3ZM50 93.6v-3.1h3v3h-3ZM43.7 40.6v-3.2H50v3.2h-6.2ZM43.7 46.7v-3H50v3h-6.3ZM103 102.9h-3v-3h3v3ZM12.5 12.4h9.3v9.3h-9.3v-9.3ZM106.2 12.4h9.3v9.3h-9.3v-9.3ZM21.8 106.2v9.2h-9.3v-9.2h9.3ZM50 21.8V25h-3.2v-3.2H50ZM43.7 25h3v3h-3v-3Z"
      fill="#103D82"
    />
    <path d="M53 25v3h-3v-3h3Z" fill="#103D82" />
    <path
      d="M77.4 77.5H50.6c-2.4 0-4.4-2-4.4-4.4V53.8c0-2.4 2-4.4 4.4-4.4h26.8c2.5 0 4.4 2 4.4 4.4V73c0 2.4-2 4.4-4.4 4.4Z"
      fill="#fff"
      stroke="#103D82"
      strokeWidth="1.7"
      strokeMiterlimit="10"
    />
    <path
      d="m66.4 60.7 2-.4a6.1 6.1 0 0 0-1.7-3.7 8.1 8.1 0 0 0-10.6-.7A6.2 6.2 0 0 0 56 66c.7.5.7.5.5 1.2l-.3 1.5a11 11 0 0 0 1.8-1c.5-.3 1-.4 1.6-.3l1.7.2v-.4c0-.6 0-1.2.2-1.8.7-2.5 2.4-4 4.8-4.7Zm-8-.4c-.6 0-.9-.3-.9-.8s.4-.8.8-.8c.5 0 1 .4.9.8 0 .5-.4.9-.9.8Zm5.7-1.6c.4 0 .8.3.8.8s-.3.8-.8.9c-.5 0-1-.4-1-.8 0-.5.5-1 1-1Z"
      fill="#53B433"
    />
    <path
      d="M73.1 62.8a7 7 0 0 0-9-.1 5.2 5.2 0 0 0-1.4 6.4c1.3 2.6 4.5 4 7.3 3.1.7-.2 1.4-.2 2 .2l.9.4v-.4c-.4-.9-.4-1.2.3-1.8a5.2 5.2 0 0 0 0-7.8Zm-7.4 3c-.4 0-.7-.4-.7-.7 0-.4.3-.7.7-.7.4 0 .7.3.7.7 0 .4-.3.7-.7.7Zm4.6 0c-.4 0-.7-.3-.6-.7 0-.4.2-.7.7-.7.3 0 .6.3.6.7 0 .4-.3.7-.7.7Z"
      fill="#53B433"
    />
  </SvgIcon>
)

export default WeChatQR
