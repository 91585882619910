import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import authStore from "store/AuthStore"
import marketStore from "store/MarketStore"
import snackbarStore from "store/SnackbarStore"
import ResetPassword from "./ResetPassword"

const ChangePasswordWrapper: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const navigate = useNavigate()
  const {
    state: { changePasswordResponse },
    actions: { resetChangePasswordResponse }
  } = useStore(authStore)

  const {
    actions: { openSnackbar }
  } = useStore(snackbarStore)

  const {
    state: { markets }
  } = useStore(marketStore)

  useEffect(
    () => {
      if (
        !changePasswordResponse.loading &&
        changePasswordResponse.data === ""
      ) {
        openSnackbar({
          options: { message: t("account.password_changed") },
          type: "success"
        })
        resetChangePasswordResponse()

        if (markets.data) {
          const selectedMarkets = markets.data.filter((m) => m.isSelected)

          const selectedLanguage = selectedMarkets[0].selectedLanguage
          navigate(`/${selectedLanguage}`)
          return
        }
        navigate(`/`)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      changePasswordResponse.loading,
      changePasswordResponse.data,
      history,
      markets.data,
      openSnackbar,
      resetChangePasswordResponse,
      t
    ]
  )

  return (
    <Wrapper
      containerClassName={classes.wrapper}
      className={classes.wrapperRoot}
    >
      <Typography
        variant={"headline3"}
        className={classes.changePasswordHeader}
      >
        {t("account.change_password_header")}
      </Typography>
      <ResetPassword />
    </Wrapper>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, breakpoints, spacing }) => ({
    wrapperRoot: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      background: themeColors.offWhite,
      padding: spacing(3),
      [breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    wrapper: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      alignItems: "center"
    },
    changePasswordHeader: {
      padding: spacing(3),
      paddingBottom: 0,
      display: "flex",
      justifyContent: "center"
    }
  })
)

export default ChangePasswordWrapper
