import { Dot } from "pure-react-carousel"

import Box from "@material-ui/core/Box"
import { createStyles, makeStyles } from "@material-ui/core/styles/"

import ResponsiveImage from "../ResponsiveImage"

import ExternalVideoAndImage from "blocks/TextAndMediaBlock/ExternalVideoAndImage"
import { ImageType, VideoType } from "types/content/media"

type CarouselThumbnailItemProps = {
  mediaItem: ImageType | VideoType
  index: number
  naturalSlideHeight: number
  onClick?: () => void
}

const ThumbnailItem: React.FC<CarouselThumbnailItemProps> = ({
  index,
  mediaItem,
  naturalSlideHeight,
  onClick
}) => {
  const classes = useStyles()
  return (
    <Dot slide={index} onClick={onClick} className={classes.dot}>
      <>
        {naturalSlideHeight !== 0 && mediaItem._type === "ImageFile" && (
          <ResponsiveImage
            className={classes.thumbnail}
            image={mediaItem as ImageType}
          />
        )}
        {mediaItem._type === "VideoFile" && (
          <Box className={classes.thumbnailVideo}>
            <ExternalVideoAndImage
              video={{ key: mediaItem["key"], value: mediaItem["value"] }}
              isHalf={false}
              isOneThird={false}
              isCarouselVideoThumbnail={true}
            />
          </Box>
        )}
      </>
    </Dot>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints, palette }) =>
  createStyles({
    thumbnail: {
      [breakpoints.up("md")]: {
        width: 72,
        height: 72,
        display: "block",
        position: "relative",
        objectFit: "contain",
        "& img": {
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: "100%"
        }
      },
      [breakpoints.down("sm")]: {
        display: "none"
      }
    },
    thumbnailVideo: {
      [breakpoints.up("md")]: {
        display: "block",
        position: "relative",
        zoom: 0.1
      },
      [breakpoints.down("sm")]: {
        display: "none"
      }
    },
    dot: {
      backgroundColor: "transparent",
      padding: 0,
      transition: "0.2s ease",
      "&:focus": {
        outline: "none"
      },
      [breakpoints.up("md")]: {
        margin: 0,
        marginBottom: spacing(4),
        border: `1px solid ${palette.divider}`,
        opacity: 0.5,
        "&.carousel__dot--selected": {
          border: `1px solid ${palette.primary.main}`,
          opacity: 1
        }
      },
      [breakpoints.down("sm")]: {
        width: "8px",
        height: "8px",
        margin: "8px",
        borderRadius: "100%",
        border: `1px solid ${palette.divider}`,
        "&.carousel__dot--selected": {
          backgroundColor: palette.primary.main
        }
      }
    }
  })
)

export default ThumbnailItem
