import { createStore } from "global-hook-store"

import SnackbarUtils from "utils/SnackbarUtils"

import { Price } from "models/Products"

export type SnackBarOptions = {
  image?: string
  code?: string
  displayName?: string
  variationValue?: string
  displayPrice?: Price
  discountedPrice?: Price
  message: string
}

export type SnackbarModel = {
  type: "success" | "warning" | "error" | "info" | "product"
  options?: SnackBarOptions
}

const snackbarStore = createStore(
  {
    type: "success",
    options: {
      image: "",
      code: "",
      displayName: "",
      variationValue: "",
      displayPrice: {
        displayPrice: "",
        amount: 0
      },
      discountedPrice: {
        displayPrice: "",
        amount: 0
      },
      message: ""
    } as SnackBarOptions
  } as SnackbarModel,
  {
    openSnackbar: async (state, payload: SnackbarModel) => {
      SnackbarUtils.toast(JSON.stringify(payload.options))
      return { ...state, ...payload }
    },
    closeSnackbar: (state, __: null) => {
      return { ...state, open: false }
    },
    resetSnackBar: (_, __: null, { reset }) => {
      return reset()
    }
  }
)
export default snackbarStore
