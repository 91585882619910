import { Market as ApiMarket } from "@starrepublic/epi/api/markets"
import { asyncState, createStore } from "global-hook-store"
import i18nCountries from "i18n-iso-countries"
import i18n from "i18next"
import Cookies from "js-cookie"

import marketsApi, { ChangeMarketsModel } from "api/markets"
import SnackbarStore from "store/SnackbarStore"

// Importing English manually is required to use getName() from i18n-iso-countries
const data = require(`i18n-iso-countries/langs/en.json`)
i18nCountries.registerLocale(data)

type Market = ApiMarket & {
  countries: string[]
  selectedCountry: string
  selectedNativeName: string
  nativeNames: string[]
  baseUrls: string[]
  selectedBaseUrl: string
}

type Country = {
  name: string
  alpha2: string
  alpha3: string
}

type ChangeMarketPayload = {
  market: Market
  language: string
  redirectUrl: string
}

type ChangeMarketOnLoginPayload = {
  marketId: string
  redirectUrl: string
}

type MarketsByCountryCode = Record<string, Market[]>

const marketStore = createStore(
  {
    markets: asyncState<Market[]>(),
    languages: [] as string[],
    countries: [] as Country[],
    defaultMarket: undefined as Market | undefined,
    loadingChangeMarket: false,
    marketsByCountryCode: {} as MarketsByCountryCode,
    availableMarkets: [] as Market[]
  },
  {
    fetchMarkets: async (state, _payload: null, { asyncAction }) => {
      const mrkid = Cookies.get("mrkid") as string

      const { markets } = await asyncAction(
        "markets",
        marketsApi.setMarket(mrkid)
      )

      // const { markets } = await asyncAction(
      //   "markets",
      //   api.markets.getAll(true, false)
      // )

      if (markets.error || !markets.data) {
        return { ...state, markets }
      }

      const allActiveMarkets = markets.data.filter(
        (m) => m.marketId.value.toLowerCase() !== "default" && m.isEnabled
      )

      const languages = allActiveMarkets.reduce((acc, current) => {
        return acc.concat(current.languages)
      }, [] as string[])

      let defaultMarket: Market | null = markets.data!.find(
        (m) => m.isSelected
      )!
      const marketsByCountryCode = {}

      const allCountries: Record<string, Country> = {}
      markets.data!.forEach((market) => {
        if (market.isSelected) {
          defaultMarket = market
        }

        market.countries.forEach((alpha3) => {
          const name = i18nCountries.getName(alpha3, "en")
          const alpha2 = i18nCountries.alpha3ToAlpha2(alpha3)
          if (name) {
            allCountries[alpha3] = {
              name,
              alpha2,
              alpha3
            }
          }
          if (marketsByCountryCode[alpha2]) {
            marketsByCountryCode[alpha2].push(market)
          } else {
            marketsByCountryCode[alpha2] = [market]
          }
        })
      })

      return {
        ...state,
        languages,
        markets,
        marketsByCountryCode,
        availableMarkets: allActiveMarkets,
        countries: Object.values(allCountries).sort((a, b) =>
          a.name.localeCompare(b.name)
        ),
        defaultMarket: defaultMarket!
      }
    },
    setChangeMarketLoading: (state, loadingChangeMarket: boolean) => {
      return { ...state, loadingChangeMarket }
    },
    changeMarket: async (
      state,
      { market, language, redirectUrl }: ChangeMarketPayload
    ) => {
      const apiPayload = {
        language,
        currency: market.selectedCurrency.toLowerCase(),
        country: market.selectedCountry
      } as ChangeMarketsModel

      marketsApi
        .changeMarket(market.marketId.value.toLowerCase(), apiPayload)
        .then(() => {
          window.location.href = redirectUrl
        })
        .then(() => {
          marketStore.actions.setChangeMarketLoading(false)
        })
        .catch((error) => {
          SnackbarStore.actions.openSnackbar({
            options: { message: i18n.t(`error.${error.toLowerCase()}`) },
            type: "error"
          })
          marketStore.actions.setChangeMarketLoading(false)
        })

      return { ...state, loadingChangeMarket: true }
    },
    changeMarketOnLogin: async (
      state,
      { marketId, redirectUrl }: ChangeMarketOnLoginPayload
    ) => {
      const apiPayload = {
        language: "",
        currency: "",
        country: ""
      }

      marketsApi
        .changeMarket(marketId.toLowerCase(), apiPayload)
        .then(() => {
          window.location.href = redirectUrl
        })
        .catch((error) => {
          SnackbarStore.actions.openSnackbar({
            options: { message: i18n.t(`error.${error.toLowerCase()}`) },
            type: "error"
          })
          marketStore.actions.setChangeMarketLoading(false)
        })

      return { ...state, loadingChangeMarket: true }
    }
  }
)

marketStore.actions.fetchMarkets()

export default marketStore
