import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import { useMediaQueries } from "utils/CustomeHooks/useMediaQueries"

import Button from "common/Button"
import ContentCarousel from "common/ContentCarousel"
import LinkButton from "common/LinkButton"
import ProductCard from "common/Product/ProductCard"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import ArrowRight from "icons/ArrowRight"
import { themeColors } from "theme"
import { ImageProductCarouselContent } from "./types"

type Props = BlockProps<ImageProductCarouselContent>

const SLIDER_MAX_WIDTH = 765
const TOP_SLIDER_MAX_WIDTH = 1048

const positions = {
  top: "Top",
  bottom: "Bottom",
  right: "Right",
  left: "Left",
  center: "Center"
}

const ItemCarousel: React.FC<Props> = ({ content }) => {
  const {
    maxNumberOfProducts,
    items,
    imageTitle,
    contentPosition,
    body,
    link,
    linkText
  } = content
  const classes = useStyles()

  const { isSmUp } = useMediaQueries()

  const productsShowingInCarousel =
    maxNumberOfProducts === 1
      ? 1
      : maxNumberOfProducts > items.length && items.length < 4
        ? items.length
        : maxNumberOfProducts > 4
          ? 4
          : maxNumberOfProducts

  const contentPositionLeftOrRight =
    contentPosition &&
    (contentPosition.text === positions.right ||
      contentPosition.text === positions.left)
      ? true
      : false

  const contentPositionTop =
    contentPosition && contentPosition.text === positions.top ? true : false

  return (
    <Box
      maxWidth={contentPositionTop ? TOP_SLIDER_MAX_WIDTH : SLIDER_MAX_WIDTH}
      mx={{ sm: "auto", md: 6 }}
      className={classNames(classes.sliderWrapper, {
        [classes.sliderWrapperTop]: contentPositionTop,
        [classes.sliderWrapperOne]: productsShowingInCarousel === 1
      })}
    >
      {imageTitle && contentPositionTop ? (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          mb={6}
          mx="auto"
          className={classes.title}
        >
          <Typography variant="headline4">{imageTitle}</Typography>
          <div>
            <ThemeXhtmlString content={body || ""} />
          </div>
          {linkText && link && (
            <LinkButton link={link}>
              <Button variant="primary" endIcon={<ArrowRight />}>
                {linkText}
              </Button>
            </LinkButton>
          )}
        </Box>
      ) : null}
      <ContentCarousel
        contentPositionLeftOrRight={contentPositionLeftOrRight}
        infiniteSlider={isSmUp}
        slides={productsShowingInCarousel}
      >
        {items.map((product, index) => {
          return (
            <div key={index} className={classes.content}>
              <ProductCard
                product={product}
                gaData={{ index, belongToList: imageTitle }}
              />
            </div>
          )
        })}
      </ContentCarousel>
    </Box>
  )
}
const useStyles = makeStyles(({ breakpoints, spacing, common }) => ({
  title: {
    position: "relative",
    maxWidth: 604,
    "& h4": {
      color: themeColors.white
    },
    "& > div p": {
      color: common.themeColors.white,
      margin: spacing(3, 0, 5)
    },
    [breakpoints.down("sm")]: {
      marginBottom: spacing(0),
      marginTop: spacing(2),
      textAlign: "left",
      alignItems: "start",
      maxWidth: "none"
    }
  },
  sliderWrapper: {
    "& .slick-list": {
      borderRight: `1px solid ${common.themeColors.whiteGray}`,
      paddingLeft: "1px", // This is needed to set left border to show
      [breakpoints.down("xs")]: {
        borderRight: 0
      }
    },
    [breakpoints.down("sm")]: {
      margin: "0 auto"
    },
    [breakpoints.down("sm")]: {
      padding: 0,
      display: "flex",
      flexDirection: "column-reverse"
    }
  },
  sliderWrapperOne: {
    width: "100%",
    margin: "0 auto"
  },
  sliderWrapperTop: {
    margin: `${spacing(7)}px auto ${spacing(4)}px`,
    padding: spacing(0, 5),
    [breakpoints.down("sm")]: {
      margin: "0 auto"
    }
  },
  content: {
    textAlign: "left",
    position: "relative",
    borderRight: `1px solid ${common.themeColors.whiteGray}`,
    borderBottom: `1px solid ${common.themeColors.whiteGray}`,
    borderTop: `1px solid ${common.themeColors.whiteGray}`,
    background: common.themeColors.white,
    overflow: "auto",
    outline: "none"
  }
}))

export default ItemCarousel
