import { useState } from "react"
import { Link } from "react-router-dom"
import useStore from "global-hook-store"

import Collapse from "@material-ui/core/Collapse"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem, {
  ListItemProps as MuiListItemProps
} from "@material-ui/core/ListItem"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"

import Typography from "common/Typography"
import PageStore from "store/PageStore"

type MenuItem = {
  title: string
  url: string
  isSelected: boolean
  subItems?: MenuItem[]
}

type Props = MuiListItemProps & {
  title: string
  url: string
  isSelected: boolean
  subItems?: MenuItem[]
  handleMenuExpansion: () => void
}

const InformationPageMenuListItem: React.FC<Props> = ({
  title,
  url,
  isSelected,
  subItems,
  handleMenuExpansion
}) => {
  const classes = useStyles()
  const hasChildren = subItems && subItems.length > 0

  const {
    state: { page }
  } = useStore(PageStore)

  const isInformationPage = page.data && page.data._type === "InformationPage"

  const initiallyExpanded = () => {
    if (!hasChildren) return false
    for (const child of subItems!) {
      if (child.isSelected) {
        return true
      }
    }
    return false
  }

  const [expanded, setExpanded] = useState(initiallyExpanded)

  const handleExpansion = () => {
    setExpanded(!expanded)
  }

  return hasChildren ? (
    <>
      <div className={classes.expansionList}>
        <div className={classes.expansionHeader}>
          <ListItem
            onClick={handleExpansion}
            className={`${classes.listItem} ${
              isSelected && !initiallyExpanded()
                ? "active"
                : initiallyExpanded()
                  ? "inPath"
                  : null
            }`}
          >
            <Link
              onClick={(e) => {
                e.stopPropagation()
                handleMenuExpansion()
              }}
              to={url}
            >
              <Typography>{title}</Typography>
            </Link>
          </ListItem>
          <Divider className={classes.menuDivider} />
          {expanded ? (
            <KeyboardArrowUp
              onClick={handleExpansion}
              className={classes.arrow}
            />
          ) : (
            <KeyboardArrowDown
              onClick={handleExpansion}
              className={classes.arrow}
            />
          )}
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.nestedList}>
            <div className={classes.expansionChild}>
              {subItems &&
                subItems.map((child, i) => {
                  return (
                    <InformationPageMenuListItem
                      disableGutters
                      {...child}
                      button
                      key={i}
                      handleMenuExpansion={handleMenuExpansion}
                    >
                      <Typography>{child.title}</Typography>
                    </InformationPageMenuListItem>
                  )
                })}
            </div>
          </List>
        </Collapse>
      </div>
      <Divider className={classes.menuDivider} />
    </>
  ) : (
    <>
      <ListItem
        className={`${classes.listItem} ${isSelected ? "active" : "inactive"}`}
        onClick={handleMenuExpansion}
      >
        <Link to={url}>
          <Typography>{title}</Typography>
        </Link>
      </ListItem>
      {isInformationPage && <Divider className={classes.menuDivider} />}
    </>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, breakpoints, spacing }) => ({
    menuDivider: {
      backgroundColor: themeColors.whiteGray
    },
    expansionList: {
      cursor: "pointer",
      "& hr": {
        display: "none"
      }
    },
    expansionHeader: {
      display: "flex",
      alignItems: "center",
      "&.active": {
        borderLeft: `4px solid ${themeColors.primaryCyan}`,
        paddingLeft: spacing(3) - 4
      }
    },
    listItem: {
      margin: `${spacing(1)}px 0`,
      paddingTop: spacing(0.5),
      paddingBottom: spacing(0.5),
      paddingLeft: 0,
      "&.active": {
        borderLeft: `${spacing(0.5)}px solid ${themeColors.darkBlue} `,
        paddingLeft: spacing(1.25)
      },
      "&.active p": {
        color: themeColors.primaryBlue,
        fontWeight: 700
      },
      "&.inPath p": {
        color: themeColors.primaryBlue,
        fontWeight: 700
      },
      "& p": {
        color: themeColors.primaryCyan,
        fontSize: 16
      },
      "& > div": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        color: themeColors.primaryCyan,
        [breakpoints.down("sm")]: {
          padding: `0px ${spacing(3)}px`
        }
      }
    },
    arrow: {
      color: themeColors.primaryRed,
      height: spacing(4),
      width: spacing(6),
      padding: `0 ${spacing(1.5)}px`,
      marginRight: `-${spacing(1.5)}px`,
      cursor: "pointer"
    },
    nestedList: {
      paddingBottom: spacing(2),
      paddingLeft: spacing(2)
    },
    expansionChild: {
      "& li": {
        padding: `${spacing(0.5)}px ${spacing(4)}px ${spacing(0.5)}px 0`,
        "&.active": {
          borderLeft: `${spacing(0.25)}px solid ${themeColors.darkBlue} `,
          paddingLeft: spacing(1)
        },
        "& p": {
          fontSize: "14px",
          maxWidth: 250,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }
      }
    },
    activeListItem: {
      fontWeight: "bold"
    }
  })
)

export default InformationPageMenuListItem
