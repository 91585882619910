import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const SecurityDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M16 13.344v5.701m0-5.7a1.333 1.333 0 0 0 0-2.667m0 2.666a1.333 1.333 0 0 1 0-2.666m0 2.666v-2.666m9.333-6.647V19.96a3 3 0 0 1-1.303 2.474L16 27.942l-8.03-5.51a3 3 0 0 1-1.303-2.473V4.03h18.666Z"
      fill="none"
    />
  </SvgIcon>
)

export default SecurityDeLaval
