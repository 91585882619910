type Props = {
  className?: string
}

const BrochureIcon: React.FC<Props> = (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M15 15V5L10 0H2C1.4 0 1 0.4 1 1V15C1 15.6 1.4 16 2 16H14C14.6 16 15 15.6 15 15ZM3 2H9V6H13V14H3V2Z"
      fill="currentColor"
    />
  </svg>
)

export default BrochureIcon
