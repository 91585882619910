import { makeStyles } from "@material-ui/core/styles"

import FormikCheckbox from "common/Formik/FormikCheckbox"
import EmailTermsModal from "./EmailTermsModal"

const EmailCheck: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.termsOfUseContainer}>
      <FormikCheckbox
        color="default"
        className={classes.newsletterCheckbox}
        name={"receiveNewsletter"}
      />
      <EmailTermsModal />
    </div>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  termsOfUseContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  },
  termsOfUse: {
    color: themeColors.primaryCyan,
    cursor: "pointer"
  },
  newsletterCheckbox: {
    alignSelf: "flex-start",
    marginLeft: -14
  }
}))

export default EmailCheck
