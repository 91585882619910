import { asyncState, createStore } from "global-hook-store"

import SearchApi, { SearchApiParams, SearchResult } from "api/search"

const OriginalPartsStore = createStore(
  {
    isOpen: false,
    rootCategory: "",
    result: asyncState<SearchResult>()
  },
  {
    open: (state, _: null) => {
      return { ...state, isOpen: true }
    },
    close: (state, __: null) => {
      return { ...state, isOpen: false }
    },
    forceLoading: (state, __: null) => {
      const newState = { ...state }
      newState.result.loading = true
      return newState
    },
    setRootCategory: (state, rootCategory: string) => ({
      ...state,
      rootCategory
    }),
    asyncSearch: async (state, payload: SearchApiParams, { asyncAction }) =>
      asyncAction(
        "result",
        SearchApi.getOriginalPartsSearchResults(payload, state.rootCategory)
      ),
    reset: (_, __: null, { reset }) => {
      return reset()
    }
  }
)
export default OriginalPartsStore
