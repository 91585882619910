import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Formik, FormikActions } from "formik"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"

import { scrollToElement } from "utils/ui/scroll"

import Spinner from "common/Spinner"
import {
  SapCustomerRegistrationModel,
  UserRegistrationModel
} from "models/User"
import { ScrollContainers } from "pages/Checkout/Checkout"
import { createAccountSuccess } from "services/StarGA"
import authStore from "store/AuthStore"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import countryStore from "store/CountryStore"
import marketStore from "store/MarketStore"
import pageStore from "store/PageStore"
import registerStore from "store/RegisterStore"
import SiteSettingStore from "store/SiteSettingStore"
import newUserSchema from "./validationSchemas/newUserSchema"
import RegistrationForm from "./Form"
import VatAutoFill from "./VatAutofill"

const initialValues: UserRegistrationModel | SapCustomerRegistrationModel = {
  companyName: "",
  vatNumber: "",
  email: "",
  receiveNewsletter: false,
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  sapCustomerNumber: "",
  billingAddress: {
    streetAddress: "",
    zipCode: "",
    city: "",
    firstName: "",
    lastName: "",
    phone: "",
    countryCode: ""
  },
  shippingAddresses: [
    {
      shippingAddressId: "",
      streetAddress: "",
      zipCode: "",
      city: "",
      firstName: "",
      lastName: "",
      phone: "",
      countryCode: ""
    }
  ]
}

const Registration: React.FC = () => {
  const { t } = useTranslation()

  const {
    state: { newCustomer, customerInformation, vatNumberTitle, continueVat },
    actions: { registerNewCustomer }
  } = useStore(registerStore)

  const {
    state: { user },
    actions: { login }
  } = useStore(authStore)

  const {
    state: { defaultMarket }
  } = useStore(marketStore)

  const {
    state: { selectableRegistrationCountries },
    actions: { fetchCountries }
  } = useStore(countryStore)

  const { fetchingCheckout } = useCheckoutPaymentStore()

  const {
    state: { page }
  } = useStore(pageStore)

  const {
    state: { postalCodeValidationPattern, disableVatNumberLookup }
  } = useStore(SiteSettingStore)

  const isCheckoutPage = page.data && page.data._type === "CheckoutPage"
  const defaulMarketValue =
    defaultMarket && defaultMarket.marketId ? defaultMarket.marketId.value : ""

  useEffect(
    () => {
      fetchCountries()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const AutoFillForm = () => {
    //VAT Autofill information
    initialValues["companyName"] = customerInformation.data
      ? customerInformation.data.Name
      : ""

    initialValues["shippingAddresses"][0]["streetAddress"] =
      customerInformation.data ? customerInformation.data.Address : ""

    initialValues["billingAddress"]["streetAddress"] = customerInformation.data
      ? customerInformation.data.Address
      : ""

    initialValues["shippingAddresses"][0]["zipCode"] = customerInformation.data
      ? customerInformation.data.ZipCode
      : ""

    initialValues["billingAddress"]["zipCode"] = customerInformation.data
      ? customerInformation.data.ZipCode
      : ""

    initialValues["shippingAddresses"][0]["city"] = customerInformation.data
      ? customerInformation.data.City
      : ""

    initialValues["billingAddress"]["city"] = customerInformation.data
      ? customerInformation.data.City
      : ""

    return
  }

  const handleNewUserRegistrationSubmit = async (
    values: UserRegistrationModel,
    { setSubmitting }: FormikActions<UserRegistrationModel>
  ) => {
    setSubmitting(true)

    const registrationModel = { ...values }

    registrationModel.billingAddress.firstName = registrationModel.firstName
    registrationModel.billingAddress.lastName = registrationModel.lastName

    registrationModel.vatNumber = vatNumberTitle

    await registerNewCustomer(registrationModel)
      .then(async () => {
        if (newCustomer.error) {
          setSubmitting(false)
          scrollToElement(ScrollContainers.registerFormErrorMessage)

          return
        }

        createAccountSuccess()

        return await login({
          userName: values.email,
          password: values.confirmPassword
        })
      })
      .then(() => {
        if (user.error) {
          setSubmitting(false)
          scrollToElement(ScrollContainers.registerFormErrorMessage)
          return
        }

        if (isCheckoutPage) {
          fetchingCheckout()
        }

        scrollToElement(ScrollContainers.customerDetails)
      })
  }

  if (selectableRegistrationCountries.data && defaultMarket) {
    const initialCountry = selectableRegistrationCountries.data.find((c) => {
      return c.alpha3 === defaultMarket.selectedCountry
    })
    if (initialCountry) {
      AutoFillForm()
      initialValues["shippingAddresses"][0]["countryCode"] =
        initialCountry.alpha2
      initialValues["billingAddress"]["countryCode"] = initialCountry.alpha2
    }
  } else {
    AutoFillForm()
    initialValues["shippingAddresses"][0]["countryCode"] = defaulMarketValue
    initialValues["billingAddress"]["countryCode"] = defaulMarketValue
  }

  return (
    <div id={ScrollContainers.customerDetails}>
      <VatAutoFill />
      {customerInformation.loading ? (
        <Box display="flex" justifyContent="center">
          <Spinner />
        </Box>
      ) : (
        (customerInformation.data ||
          continueVat ||
          (disableVatNumberLookup && vatNumberTitle)) && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleNewUserRegistrationSubmit}
            validateOnChange={false}
            validateOnBlur
            component={(formikProps) => <RegistrationForm {...formikProps} />}
            validationSchema={newUserSchema(
              t,
              postalCodeValidationPattern,
              defaulMarketValue
            )}
          />
        )
      )}
    </div>
  )
}

export default Registration
