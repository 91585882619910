import { useEffect } from "react"
import { ProductListItem } from "@starrepublic/epi/api/products"
import { BlockProps } from "@starrepublic/epi/cms"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import ContentCarousel from "common/ContentCarousel"
import ProductCard from "common/Product/ProductCard"
import Product from "models/Products"
import CarouselHeader from "./CarouselHeader"

type Props = BlockProps<{
  items: ProductListItem<Product>[]
  link?: string
  header?: string
  maxNumberOfProducts: number
}>

const CAROUSEL_HEADER_ID = "CAROUSEL_HEADER_ID"

const ProductsCarousel: React.FC<Props> = ({ content }) => {
  const classes = useStyles()
  const SLIDES_TO_SHOW = content.maxNumberOfProducts

  let resizeTimer: NodeJS.Timeout

  const resizeHeader = () => {
    const carouselHeader = document.getElementById(CAROUSEL_HEADER_ID)
    const carouselContent = document.getElementsByClassName(
      "slick-list"
    )[0] as HTMLElement
    if (carouselHeader) {
      carouselHeader.style.width = carouselContent.style.width
    }
  }

  useEffect(() => {
    if (content.items && content.items.length < SLIDES_TO_SHOW) {
      resizeHeader()
      window.addEventListener(
        "resize",
        () => {
          clearTimeout(resizeTimer)
          // eslint-disable-next-line react-hooks/exhaustive-deps
          resizeTimer = setTimeout(resizeHeader, 50)
        },
        true
      )
    }
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.removeEventListener("resize", () => {})
    }
  }, [])

  return content.items && content.items.length ? (
    <div className={classes.wrapper}>
      <div>
        {content && content.header && (
          <CarouselHeader
            title={content.header}
            linkUrl={content.link || null}
            id={CAROUSEL_HEADER_ID}
          />
        )}
        <Grid>
          <ContentCarousel infiniteSlider={true} slides={SLIDES_TO_SHOW}>
            {content.items.map((product, key) => {
              return (
                <div key={key} className={classes.content}>
                  <ProductCard
                    slides={SLIDES_TO_SHOW}
                    product={product}
                    gaData={{ belongToList: content.header, index: key }}
                  />
                </div>
              )
            })}
          </ContentCarousel>
        </Grid>
      </div>
    </div>
  ) : null
}

const useStyles = makeStyles(({ breakpoints, common: { themeColors } }) => ({
  wrapper: {
    "& .slick-list": {
      borderRight: `1px solid ${themeColors.whiteGray}`
    }
  },
  content: {
    borderLeft: `1px solid ${themeColors.whiteGray}`,
    borderTop: `1px solid ${themeColors.whiteGray}`,
    borderBottom: `1px solid ${themeColors.whiteGray}`,
    outline: 0,
    [breakpoints.down("xs")]: {
      borderRight: "none"
    }
  }
}))

export default ProductsCarousel
