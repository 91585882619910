import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import accountStore, { FormType } from "store/AccountStore"
import pageStore from "store/PageStore"

const LoginFormSelector: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { formComponent },
    actions: { setFormComponent }
  } = useStore(accountStore)

  const {
    state: { page }
  } = useStore(pageStore)

  if (page.data && page.data._type === "CheckoutPage") {
    return null
  }

  const isLoginPage = page.data && page.data._type === "LoginPage"

  return (
    <Grid
      item
      container
      justifyContent="space-between"
      className={classes.root}
    >
      {formComponent === FormType.login && (
        <Grid item>
          <Typography
            className={classes.sliderLink}
            variant="link3"
            onClick={() => {
              setFormComponent(FormType.forgotPassword)
            }}
            id="forgot-password-button"
          >
            {t("account.forgot_your_password")}
          </Typography>
        </Grid>
      )}
      {formComponent !== FormType.register && !isLoginPage && (
        <Grid item>
          <Typography
            className={classes.sliderLink}
            variant="link3"
            onClick={() => {
              setFormComponent(FormType.register)
            }}
            id="create-account-button"
          >
            {t("account.create_account")}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: `0 ${spacing(1)}px`
  },
  sliderLink: {
    cursor: "pointer"
  }
}))

export default LoginFormSelector
