import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Badge from "@material-ui/core/Badge"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import OrderStore from "store/OrderStore"

const OrderHeading: React.FC<{}> = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    actions: { fetchCompactOrdersInfo },
    state: { compactOrdersInfo }
  } = useStore(OrderStore)

  useEffect(() => {
    fetchCompactOrdersInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box mb={5} py={1}>
      <Typography variant="headline3">
        {t("account.my_orders_tab")}
        {compactOrdersInfo.loading ? (
          <ButtonLoaderIcon className={classes.loader} />
        ) : (
          <Badge
            badgeContent={
              <Typography variant="smallText" className={classes.badgeContent}>
                {compactOrdersInfo.data && compactOrdersInfo.data.length}
              </Typography>
            }
            classes={{ badge: classes.badge, root: classes.badgeContainer }}
          >
            <div />
          </Badge>
        )}
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  badgeContainer: {
    marginLeft: spacing(2)
  },
  loader: {
    marginLeft: spacing(1)
  },
  badgeContent: {
    color: themeColors.darkGray,
    fontWeight: "bold"
  },
  badge: {
    backgroundColor: themeColors.lightGray,
    height: spacing(3),
    width: spacing(3)
  }
}))

export default OrderHeading
