export const getTextAlignment = (alignment: string) => {
  switch (alignment) {
    case "left":
      return "start"
    case "center":
      return "center"
    case "right":
      return "end"
    default:
      return "center"
  }
}
