import { useTranslation } from "react-i18next"
import { Form as FormikForm } from "formik"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import CustomTextField from "common/Formik/CustomTextField"
import marketStore from "store/MarketStore"

const FarmForm: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { countries, defaultMarket }
  } = useStore(marketStore)

  const availableCountries = countries.filter((c) => {
    return c.alpha2 === defaultMarket!.marketId.value
  })

  return (
    <div className={classes.root}>
      <FormikForm id="farm-form" noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              required
              name="name"
              label={t("subscription.farm_name")}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              required
              name="streetAddress"
              label={t("checkout.street_address_input_label")}
              type="text"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              required
              name="zipCode"
              label={t("checkout.zip_code_input_label")}
              type="text"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              required
              name="city"
              label={t("checkout.city_input_label")}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              required
              name="country"
              label={t("checkout.shipping_country_input_label")}
              type="text"
            />
          </Grid>
        </Grid>
      </FormikForm>
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    root: {
      paddingBottom: spacing(3),
      background: themeColors.white,
      width: "100%"
    }
  })
)

export default FarmForm
