import { useEffect } from "react"
import useStore, { useLocalStore } from "global-hook-store"

import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import withWidth, { isWidthUp, WithWidth } from "@material-ui/core/withWidth"

import ProductCard from "common/Product/ProductCard"
import Spinner from "common/Spinner"
import Wrapper from "common/Wrapper"
import * as gtm from "services/StarGA"
import marketStore from "store/MarketStore"
import SubCategoryCardHeader from "./SubCategoryCardHeader"
import SubCategoryCardStore from "./SubCategoryCardStore"

type Props = {
  route: string
  title: string
} & WithWidth

const SubCategoryCard: React.FC<Props> = ({ route, title, width }) => {
  const classes = useStyles()
  const {
    state: {
      subCategoryProducts: { loading, data: products, error }
    },
    actions
  } = useLocalStore(SubCategoryCardStore)

  const {
    state: { defaultMarket }
  } = useStore(marketStore)

  const currencyCode = defaultMarket && defaultMarket.defaultCurrency

  useEffect(
    () => {
      actions.getProducts({ route, limit: isWidthUp("sm", width) ? 3 : 2 })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [route, isWidthUp("sm", width)]
  )

  useEffect(
    () => {
      if (!loading && products && products.value.length > 0) {
        gtm.categoryImpression(products, title, currencyCode)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, products]
  )

  return !error ? (
    <>
      <div className={classes.SubCategoryHeader}>
        <SubCategoryCardHeader title={title} linkUrl={route} />
      </div>
      <Wrapper className={classes.wrapper}>
        <Card>
          {!loading ? (
            <Grid container>
              {products &&
                products.value.map((product, index) => (
                  <Grid key={index} item xs={6} sm={4} className={classes.grid}>
                    <ProductCard
                      product={product}
                      gaData={{ index, belongToList: title }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
        </Card>
      </Wrapper>
    </>
  ) : null
}

const useStyles = makeStyles(({ common: { themeColors, gridSpacing } }) => ({
  SubCategoryHeader: {
    borderBottom: `1px solid ${themeColors.whiteGray}`
  },
  wrapper: {
    padding: 0,
    marginBottom: gridSpacing,
    borderLeft: `1px solid ${themeColors.whiteGray}`,
    "& > div": {
      padding: 0
    }
  },

  grid: {
    position: "relative",
    width: "100%",
    height: "100%",
    borderBottom: `1px solid ${themeColors.whiteGray}`,
    borderRight: `1px solid ${themeColors.whiteGray}`,
    "&:nth-of-type(3n+3)": {
      borderRight: `1px solid ${themeColors.whiteGray}`
    }
  },
  spinnerWrapper: {
    textAlign: "center",
    padding: gridSpacing
  }
}))

export default withWidth()(SubCategoryCard)
