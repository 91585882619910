import { useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import MarketFlag from "common/MarketFlag"
import ArrowDown from "icons/ArrowDown"
import marketStore from "store/MarketStore"
import SiteSettingsStore from "store/SiteSettingStore"
import themeColors from "theme/colors"
import MarketsMenu from "./MarketsMenu"

type Props = {
  isMobile?: boolean
}

const MarketSelector: React.FC<Props> = ({ isMobile }) => {
  const [isMarketMenuOpen, setIsMarketMenuOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { markets, defaultMarket, loadingChangeMarket }
  } = useStore(marketStore)

  const {
    state: { enableEcommerce }
  } = useStore(SiteSettingsStore)

  // Only show market selector if there are more then one Market configured in environment
  if (
    markets.data &&
    markets.data.filter((m) => m.marketId.value !== "DEFAULT" && m.isEnabled)
      .length < 2
  ) {
    return null
  }

  const handleMarketsMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={classes.languageSelector}>
      {defaultMarket && (
        <IconButton
          color="primary"
          aria-label="Menu"
          className={classNames(
            classes.marketButton,
            isMarketMenuOpen ? classes.marketButtonActive : ""
          )}
          onClick={handleMarketsMenuButtonClick}
          id="market-selector-button"
        >
          <Box clone mr={1}>
            <MarketFlag countryAlpha2={defaultMarket.marketId.value} />
          </Box>
          <Typography
            className={classNames(classes.countryText, {
              [classes.countryTextActive]: isMarketMenuOpen,
              [classes.countryTextMobile]: isMobile
            })}
            variant={"body1"}
          >
            {loadingChangeMarket
              ? t("common.loading").toUpperCase()
              : enableEcommerce
                ? `${defaultMarket.selectedLanguage.toUpperCase()} / ${
                    defaultMarket.selectedCurrency
                  }`
                : defaultMarket.selectedLanguage.toUpperCase()}
          </Typography>

          <ArrowDown
            fill={themeColors.white}
            className={classNames(classes.arrowDown, {
              [classes.rotate]: anchorEl
            })}
          />
        </IconButton>
      )}
      <MarketsMenu anchorEl={anchorEl} onClose={handleClose} />
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  languageSelector: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flex: "0 0 auto",
    padding: spacing(0, 1.5)
  },
  marketButton: {
    padding: spacing(0.75, 1.5),
    border: `1px solid ${themeColors.white}`,
    borderRadius: "4px",
    "&:hover": {
      color: themeColors.primaryBlue
    }
  },
  marketButtonActive: {
    color: themeColors.white
  },
  countryText: {
    fontSize: 12,
    fontWeight: 700,
    color: themeColors.white,
    whiteSpace: "nowrap",
    "&:hover": {
      color: themeColors.white
    }
  },
  countryTextActive: {
    color: themeColors.white
  },
  countryTextMobile: {
    color: themeColors.white,
    "&:hover": {
      color: themeColors.primaryCyan
    }
  },
  arrowDown: {
    width: 12,
    height: 12,
    marginLeft: spacing(3),
    transition: "all 0.1s linear"
  },
  rotate: {
    transform: "rotate(180deg)"
  }
}))

export default MarketSelector
