import { AsyncState } from "global-hook-store"

import { Dealer, GeoLocationType } from "store/DealerSearchStore"

export const sortAllDealers = (
  mapSearch: AsyncState<GeoLocationType>,
  a: Dealer,
  b: Dealer
) => {
  const distanceInMetersA =
    google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(mapSearch.data),
      new google.maps.LatLng({
        lat: a.latitude,
        lng: a.longitude
      })
    )
  const distanceInMetersB =
    google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(mapSearch.data),
      new google.maps.LatLng({
        lat: b.latitude,
        lng: b.longitude
      })
    )

  return distanceInMetersA > distanceInMetersB ? 1 : -1
}

export const sortSearchedDealers = (a: Dealer, b: Dealer) => {
  const parsedDistanceA = parseFloat(a.distance)
  const parsedDistanceB = parseFloat(b.distance)

  return parsedDistanceA > parsedDistanceB ? 1 : -1
}
