import { useTranslation } from "react-i18next"
import { Formik, FormikActions } from "formik"
import useStore from "global-hook-store"

import { NewsletterPayload } from "api/newsletter"
import NewsletterModalStore from "store/NewsletterModalStore"
import SiteSettingsStore from "store/SiteSettingStore"
import validationSchema, {
  NewsletterModalFormType
} from "./NewsletterModalForm/validationSchema"
import NewsletterModalForm from "./NewsletterModalForm"

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  newsletterAccepted: false
}

const NewsletterForm: React.FC = () => {
  const { t } = useTranslation()

  const {
    state: {
      displayNewsletterNameFields,
      newsletterTitle,
      newsletterConfirmText
    }
  } = useStore(SiteSettingsStore)

  const {
    actions: { send: sendNewsletterInformation }
  } = useStore(NewsletterModalStore)

  const newsletterFormSubmitData = () => {
    const fields = displayNewsletterNameFields ? 3 : 1
    const checkboxField = newsletterConfirmText ? 1 : 0
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "form_submit",
        form_name: newsletterTitle,
        form_type: "newsletter",
        form_fields: fields + checkboxField
      })
    }
  }

  const handleSubmit = async (
    values: NewsletterModalFormType,
    { setSubmitting }: FormikActions<NewsletterModalFormType>
  ) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      newsletterAccepted: values.newsletterAccepted
    } as NewsletterPayload

    sendNewsletterInformation(payload).then(() => {
      newsletterFormSubmitData()
      setSubmitting(false)
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur
      component={NewsletterModalForm}
      validationSchema={validationSchema(t)}
    />
  )
}

export default NewsletterForm
