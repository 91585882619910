import { KeyOfType } from "./types"

export const asyncState = <T extends any>(defaultValue: T): AsyncState<T> => {
  return {
    loading: false,
    data: defaultValue
  }
}

export type AsyncState<T = any> = {
  error?: any
  loading: boolean
  data: T | null
}

export const asyncStateSetter = <Get extends () => any>(set: any, get: Get) => {
  type T = ReturnType<Get>
  return async <Key extends KeyOfType<T, AsyncState>>(
    stateKey: Key,
    promise: T[Key] extends AsyncState ? Promise<T[Key]["data"]> : never,
    throwError: boolean = false
  ) => {
    const currentState: AsyncState = get()[stateKey]

    set({ [stateKey]: { ...currentState, loading: true } })

    try {
      const result = await promise
      const newState = { [stateKey]: { loading: false, data: result } }
      set(newState)
      return newState
    } catch (e) {
      set({ [stateKey]: { ...currentState, loading: false, error: e } })
      if (throwError) {
        throw e
      }
    }
  }
}
