import { BlockProps, editorAttributes as editor } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { contrastSwitch } from "utils/contrastSwitch"
import { getTextAlignment } from "utils/ui"

import Button from "common/Button"
import LinkButton from "common/LinkButton"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import ArrowRight from "icons/ArrowRight"
import themeColors from "theme/colors"
import { BodyContent as BodyContentType } from "types/content/layout"
import { ImageBlock } from "types/content/media"

type Props = {
  display: string
  backgroundColor?: string
  content: BodyContentType
  propsInfo: BlockProps<BodyContentType>["propsInfo"]
  border?: boolean
  buttonAlignment?: "center" | "start" | "end"
  image?: ImageBlock | undefined
}

const BodyContent: React.FunctionComponent<Props> = ({
  display,
  backgroundColor = "",
  border = false,
  content,
  propsInfo,
  buttonAlignment = "center",
  image
}) => {
  const {
    title,
    body,
    link,
    linkText,
    horizontalAlignment,
    isSectionChild
  }: BodyContentType = content

  const classes = useStyles()
  const textAlign = getTextAlignment(horizontalAlignment)

  return (
    <Grid
      item
      xs={12}
      className={classNames(classes.root, classes.bodyContent, {
        [classes.leftTextAlign]: textAlign === "left",
        [classes.border]: border
      })}
      style={{
        backgroundColor
      }}
    >
      {title && (
        <Typography
          textWrap="wrap"
          variant={"headline2"}
          {...editor(propsInfo.title)}
          component={isSectionChild ? "h3" : "h2"}
          className={classNames(
            classes.title,
            classes[
              contrastSwitch({
                variant: "title",
                contrastColor:
                  !image || backgroundColor
                    ? backgroundColor
                    : themeColors.colorBaseBlue700
              })
            ],
            {
              [classes.titleStyles]: display === "one_third"
            }
          )}
        >
          {title}
        </Typography>
      )}

      {body && (
        <Typography
          {...editor(propsInfo.body)}
          component="div"
          className={classNames(
            classes.content,
            classes[
              contrastSwitch({
                variant: "body",
                contrastColor:
                  !image || backgroundColor
                    ? backgroundColor
                    : themeColors.colorBaseBlue700
              })
            ]
          )}
        >
          <ThemeXhtmlString sectionTheme={backgroundColor} content={body} />
        </Typography>
      )}

      {linkText && link && (
        <Box p={1} display="flex" justifyContent={buttonAlignment}>
          <LinkButton link={link}>
            <Button
              variant="primary"
              contrastColor={backgroundColor}
              endIcon={<ArrowRight />}
            >
              {linkText}
            </Button>
          </LinkButton>
        </Box>
      )}
    </Grid>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    root: {
      height: "100%",
      padding: spacing(3),
      textAlign: "center",
      zIndex: 2,
      [breakpoints.up("md")]: {
        padding: spacing(3, 4)
      }
    },
    leftTextAlign: {
      textAlign: "left"
    },
    title: {
      marginBottom: spacing(2)
    },
    titleStyles: {
      fontSize: 30
    },
    bodyContent: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      maxWidth: 604,
      flexBasis: "auto"
    },
    content: {
      marginBottom: spacing(3)
    },

    linkText: {
      marginRight: spacing(1),
      color: themeColors.white,
      fontWeight: 500
    },
    icon: {
      display: "flex"
    },
    border: {
      border: `1px solid ${themeColors.whiteGray}`
    },
    ["text-base-white"]: {
      color: themeColors.white
    },
    ["text-base-blue-700"]: {
      color: themeColors.colorBaseBlue700
    },
    ["text-base-blue-800"]: {
      color: themeColors.colorBaseBlue800
    },
    ["text-base-blue-900"]: {
      color: themeColors.colorBaseBlue900
    }
  })
)

export default BodyContent
