import { memo } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import AddToCartOrShoppingListContainer from "common/AddToCartOrShoppingListContainer"
import PricePerUnit from "common/Product/PricePerUnit"
import ProductPrice from "common/Product/ProductPrice"
import StockStatus from "common/Product/StockStatus"
import VariantPickerModal from "common/ProductListing/VariantPickerModal"
import ResponsiveImage from "common/ResponsiveImage"
import Typography from "common/Typography"
import Product from "models/Products"
import ProductListingStore from "store/ProductListingStore"

type Props = {
  product: Product
}

const ProductRow = ({ product }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    actions: { getQuickViewProduct, setQuickViewOpen }
  } = useStore(ProductListingStore)

  const handleOpenQuickView = () => {
    getQuickViewProduct(product._id.toString()).then(() => {
      setQuickViewOpen(true)
    })
  }

  const inventoryStatus = product.inventoryStatus === "NOT_IN_STOCK"

  return (
    <TableRow key={product._route}>
      <TableCell className={classes.imageCell}>
        {product && product.displayImage && (
          <Grid>
            <ResponsiveImage
              lazy
              image={product && product.displayImage}
              className={classes.productImage}
            />
          </Grid>
        )}
      </TableCell>
      <TableCell
        className={classNames({
          [classes.cursor]: product._route
        })}
        onClick={() => {
          handleOpenQuickView()
        }}
      >
        <Grid item container direction="column">
          <Typography variant="body2">
            {product.displayLowestPriceArticleNumber}
          </Typography>
          <Typography variant="body2">{product.productName}</Typography>
        </Grid>
      </TableCell>

      <TableCell>
        <StockStatus
          inventoryStatus={product.inventoryStatus}
          isDigitalService={product.isDigitalService}
        />
      </TableCell>

      <>
        <TableCell>
          <div className={classes.priceWrapper}>
            {product.isDigitalService ? (
              <PricePerUnit
                price={product.displayLowestPricePerX || ""}
                unit={product.displayLowestPricePerXUnitType || ""}
                size={"medium"}
              />
            ) : (
              <ProductPrice
                price={product.displayLowestPrice}
                discountedPrice={product.displayLowestDiscountedPrice}
                size={"medium"}
              />
            )}

            <Typography variant="smallText">{t("product.ex_vat")}</Typography>
          </div>
        </TableCell>
      </>
      <TableCell className={classes.noWrap} align="right">
        {product.variations.length > 1 ? (
          <VariantPickerModal
            link="/"
            productId={product._id}
            productName={product.displayName}
            disabled={inventoryStatus}
          />
        ) : product.variations.length === 1 ? (
          <AddToCartOrShoppingListContainer
            disabled={inventoryStatus || product.displayLowestPrice === null}
            code={product.variations[0].code}
            large={false}
            amount={
              product.variations[0].displayPrice &&
              product.variations[0].displayPrice.amount
                ? product.variations[0].displayPrice.amount
                : 0
            }
            name={product.displayName}
            category={product.categoryStructure}
            image={product && product.displayImage && product.displayImage._url}
            variationValue={
              product.variations &&
              product.variations.length &&
              product.variations[0] &&
              product.variations[0].variationValue
            }
            link="/"
            digitalService={product["isDigitalService"]}
            displayPrice={product.displayLowestPrice}
            discountedPrice={product.displayLowestDiscountedPrice}
          />
        ) : null}
      </TableCell>
    </TableRow>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, spacing, breakpoints }) => ({
    tableHead: {
      backgroundColor: themeColors.offWhite
    },
    noWrap: {
      minWidth: 112,
      "& > div > div": {
        borderBottom: `1px solid ${themeColors.whiteGray}`
      }
    },
    productImage: {
      width: "100%",
      height: 64,
      objectFit: "cover",
      position: "initial"
    },
    imageCell: {
      width: 100,
      paddingLeft: 0,
      paddingRight: 0
    },
    cursor: {
      cursor: "pointer",
      "& div > p:nth-child(2):hover": {
        textDecoration: "underline",
        color: themeColors.primaryCyan
      }
    },
    priceWrapper: {
      bottom: spacing(3),
      left: spacing(3),
      [breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline"
      },
      [breakpoints.down("xs")]: {
        left: spacing(1.5),
        bottom: spacing(1.5)
      }
    },
    spinner: {
      minWidth: 150
    }
  })
)

export default memo(ProductRow)
