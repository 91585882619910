import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore, { useLocalStore } from "global-hook-store"

import Backdrop from "@material-ui/core/Backdrop"
import Box from "@material-ui/core/Box"
import ButtonBase from "@material-ui/core/ButtonBase"
import Card from "@material-ui/core/Card"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import DialogContent from "@material-ui/core/DialogContent"
import Fade from "@material-ui/core/Fade"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import Modal from "@material-ui/core/Modal"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import CloseIcon from "@material-ui/icons/Close"

import variationComparator from "utils/variationComparator"

import AddToCartOrShoppingListContainer from "common/AddToCartOrShoppingListContainer"
import PricePerUnit from "common/Product/PricePerUnit"
import ProductPrice from "common/Product/ProductPrice"
import StockStatus from "common/Product/StockStatus"
import Spinner from "common/Spinner"
import AddToCartIcon from "icons/AddToCartIcon"
import AddToWishlistIcon from "icons/AddToWishlist"
import DrawerStateStore from "store/DrawerStateStore"
import SiteSettingsStore from "store/SiteSettingStore"
import VariationStore from "./VariationStore"

type Props = {
  productId: number
  productName: string
  link: string
  disabled?: boolean
  mobile?: boolean
}

const VariantPickerModal: React.FC<Props> = ({
  productId,
  productName,
  disabled,
  link,
  mobile
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const {
    state: { variations },
    actions: { getVariations }
  } = useLocalStore(VariationStore)

  const {
    state: { isCartOpen }
  } = useStore(DrawerStateStore)

  const {
    state: { enableEcommerce, enableWishlist, enableNoWebShopWithContactDealer }
  } = useStore(SiteSettingsStore)

  useEffect(
    () => {
      if (isOpen) getVariations(productId)
      if (isCartOpen) {
        setIsOpen(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen, isCartOpen]
  )

  const handleClick = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const varitionsWithPricePerUnit =
    variations.data &&
    variations.data.value.filter((v) => {
      return v.variationPricePerXUnitType !== null
    })

  return enableNoWebShopWithContactDealer ? null : (
    <Box display="flex" width="100%" justifyContent="right">
      <ButtonBase
        className={classNames("open-variant-picker-button", classes.button, {
          [classes.mobile]: mobile,
          [classes.disabledButton]: disabled
        })}
        onClick={handleClick}
        disabled={disabled}
      >
        {enableEcommerce ? <AddToCartIcon /> : <AddToWishlistIcon />}
      </ButtonBase>

      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClose}
      >
        <Modal
          aria-labelledby="variant-picker-modal"
          aria-describedby="pick-your-variant-here"
          open={isOpen}
          onClose={handleClose}
          className={classes.root}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={isOpen}>
            <DialogContent className={classes.dialogue}>
              {variations.loading ? (
                <div className={classes.spinner}>
                  <Spinner />
                </div>
              ) : (
                <Grid
                  item
                  xs={12}
                  className={classNames(classes.modal, {
                    [classes.modalCompact]: enableEcommerce !== true
                  })}
                >
                  <Card className={classes.card}>
                    <Table className={classes.table} padding="normal">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {variations.data &&
                              variations.data.value &&
                              variations.data.value.length > 0 &&
                              variations.data.value[0].variationType &&
                              t(
                                `product.${variations.data.value[0].variationType.toLowerCase()}_header`
                              )}
                          </TableCell>
                          <Hidden smDown>
                            <TableCell>{t("product.article_no")}</TableCell>
                            {enableEcommerce && (
                              <TableCell>{t("product.availability")}</TableCell>
                            )}
                          </Hidden>
                          {enableEcommerce && (
                            <>
                              <TableCell>{t("product.price")}</TableCell>
                              <Hidden smDown>
                                {varitionsWithPricePerUnit &&
                                varitionsWithPricePerUnit.length > 0 ? (
                                  <TableCell>
                                    <div className={classes.noWrap}>
                                      {t("product.price")}
                                      <span className={classes.volumeUnit}>
                                        /
                                      </span>
                                      {` ${varitionsWithPricePerUnit[0].variationPricePerXUnitType}`}
                                    </div>
                                  </TableCell>
                                ) : null}
                              </Hidden>
                            </>
                          )}
                          <TableCell className={classes.tableCose}>
                            <IconButton
                              id="close-variant-picker-button"
                              onClick={() => handleClose()}
                            >
                              <div className={classes.icon}>
                                <CloseIcon />
                              </div>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {variations.data &&
                          variations.data.value
                            .sort(variationComparator)
                            .map((variant, index) => {
                              const inventoryStatus = enableWishlist
                                ? false
                                : variant.inventoryStatus === "NOT_IN_STOCK"

                              return inventoryStatus ? null : (
                                <TableRow
                                  key={index}
                                  className={classes.tableRow}
                                >
                                  <TableCell>
                                    {variant.variationValue}
                                  </TableCell>
                                  <Hidden smDown>
                                    <TableCell>
                                      {variant.articleNumber}
                                    </TableCell>
                                    {enableEcommerce && (
                                      <TableCell>
                                        <StockStatus
                                          inventoryStatus={
                                            variant.inventoryStatus
                                          }
                                          isDigitalService={
                                            variant.isDigitalService
                                          }
                                        />
                                      </TableCell>
                                    )}
                                  </Hidden>
                                  {enableEcommerce && (
                                    <>
                                      <TableCell className={classes.bold}>
                                        <ProductPrice
                                          price={variant._displayPrice}
                                          discountedPrice={
                                            variant._discountedPrice
                                          }
                                          isInline={false}
                                          size={"small"}
                                          className={classes.variantPrice}
                                        />
                                      </TableCell>
                                      <Hidden smDown>
                                        {variant.variationPricePerX ||
                                        variant.variationDiscountedPricePerX ? (
                                          <TableCell className={classes.bold}>
                                            <PricePerUnit
                                              unit={
                                                variant.variationPricePerXUnitType
                                              }
                                              price={
                                                variant.variationDiscountedPricePerX !==
                                                  null &&
                                                variant.variationPricePerX !==
                                                  variant.variationDiscountedPricePerX
                                                  ? variant.variationDiscountedPricePerX
                                                  : variant.variationPricePerX
                                              }
                                            />
                                          </TableCell>
                                        ) : null}
                                      </Hidden>
                                    </>
                                  )}
                                  <TableCell
                                    align="right"
                                    className={classes.noWrap}
                                  >
                                    <AddToCartOrShoppingListContainer
                                      code={variant.code}
                                      amount={
                                        variant._displayPrice &&
                                        variant._displayPrice.amount
                                          ? variant._displayPrice.amount
                                          : 0
                                      }
                                      disabled={
                                        inventoryStatus ||
                                        variant._displayPrice === null
                                      }
                                      name={productName}
                                      category={variant.categoryStructure}
                                      image={
                                        variant.imageAssets &&
                                        variant.imageAssets.length
                                          ? variant.imageAssets[0]._url
                                          : variant.displayImage &&
                                            variant.displayImage._url
                                      }
                                      variationValue={variant.variationValue}
                                      link={link}
                                      digitalService={
                                        variant["isDigitalService"]
                                      }
                                      displayPrice={variant._displayPrice}
                                      discountedPrice={variant._discountedPrice}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
              )}
            </DialogContent>
          </Fade>
        </Modal>
      </ClickAwayListener>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    overflow: "scroll"
  },
  button: {
    cursor: "pointer",
    color: themeColors.white,
    background: themeColors.primaryCyan,
    borderRadius: 0,
    height: 56,
    width: 56
  },
  modal: {
    position: "absolute",
    top: "25%",
    left: "50%",
    transform: "translate3d(-50%, 0, 0)",
    maxWidth: 1000,
    width: "100%",
    paddingBottom: 80,
    borderRadius: 0
  },
  modalCompact: {
    maxWidth: 600
  },
  card: {
    overflowX: "auto",
    borderRadius: 0
  },
  table: {
    borderCollapse: "separate",
    "& th": {
      color: themeColors.darkGray,
      whiteSpace: "nowrap"
    }
  },
  tableRow: {
    height: 72
  },
  bold: {
    fontWeight: "bold"
  },
  noWrap: {
    whiteSpace: "nowrap",
    display: "flex",
    "& > div > div": {
      borderBottom: `1px solid ${themeColors.whiteGray}`
    }
  },
  dialogue: {
    outline: 0
  },
  disabledButton: {
    background: themeColors.lightGray,
    cursor: "default !important",
    color: themeColors.mediumGray
  },
  variantPrice: {
    fontSize: 16
  },
  volumeUnit: {
    display: "flex",
    fontSize: 16,
    padding: "0 4px"
  },
  icon: {
    position: "absolute",
    height: 24,
    width: 24
  },
  tableCose: {
    display: "flex",
    justifyContent: "flex-end"
  },
  mobile: {
    width: 48,
    height: 48
  },
  spinner: {
    top: "25%",
    position: "fixed",
    left: "50%",
    background: themeColors.white,
    padding: spacing(1),
    borderRadius: "50%",
    height: 54,
    width: 54
  }
}))

export default VariantPickerModal
