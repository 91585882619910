import { useEffect, useState } from "react"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { validVideoId, videoId } from "utils/videoImageUtils"

import Image from "common/Image"
import PlayButton from "icons/PlayButton"
import { themeColors } from "theme"
import { ImageBlock } from "types"

type Props = {
  video: {
    key: string
    value: string
  }
  isHalf: boolean
  images?: ImageBlock[]
  body?: string
  title?: string
  fullSize?: boolean
  isOneThird?: boolean
  isBottom?: boolean
  clickStateFromSlider?: boolean
  closeVideoFromModal?: (stateFromSlider: boolean) => void
  isCarouselVideoThumbnail?: boolean
  autoPlayVideo?: boolean
}

const MEDIA_BG_RATIO_SINGLE_VIDEO = 16 / 9
const isYouTubeId = "YouTube"
const isScreen9Id = "Screen9Id"
const thumbnailSizes = ["sddefault", "mqdefault", "hqdefault", "maxresdefault"]

const ExternalVideoAndImage: React.FC<Props> = ({
  video: { key, value },
  isHalf,
  isBottom,
  images,
  title,
  body,
  fullSize,
  isOneThird,
  closeVideoFromModal,
  clickStateFromSlider,
  isCarouselVideoThumbnail,
  autoPlayVideo
}) => {
  const classes = useStyles()
  const isSmDown = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"))
  const isXsDown = useMediaQuery(({ breakpoints }) => breakpoints.down("xs"))

  const [play, setPlay] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [isYouTubeThumbNail, setYouTubeThumbnail] = useState<ImageBlock>()

  const handlePlay = () => {
    if (!isCarouselVideoThumbnail) {
      setPlay(true)
    }
  }

  const handleClose = () => {
    setPlay(false)
    if (closeVideoFromModal) {
      closeVideoFromModal(false)
    }
  }

  const validateImage = () => {
    thumbnailSizes.map(async (size) => {
      const img = (await validVideoId(value, size)) as HTMLImageElement

      if (img.width > 320) {
        const imageSet = {
          _url: `https://img.youtube.com/vi/${value}/${size}.jpg`
        } as ImageBlock
        setYouTubeThumbnail(imageSet)
        setImageLoaded(true)
      }
    })
  }

  useEffect(
    () => {
      if (!imageLoaded) {
        validateImage()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageLoaded, isYouTubeThumbNail]
  )

  if (!images && !value) {
    return null
  }

  return (
    <Grid className={classes.wrapper}>
      <Grid>
        {!autoPlayVideo && value && (
          <Box width="100%" height="100%" position="absolute">
            <PlayButton
              className={classNames(classes.playButton, {
                [classes.playButtonThumbnail]: isCarouselVideoThumbnail
              })}
              onClick={handlePlay}
            />
          </Box>
        )}
        {!autoPlayVideo &&
        (key === isYouTubeId ||
          (key === isScreen9Id && images && images.length > 0)) ? (
          <Image
            objectFit="cover"
            lazy
            className={classNames(classes.mediaImage)}
            containerProps={{
              className: classNames(classes.mediaContainer, {
                [classes.displaySmall]:
                  (images && images.length <= 1) || isXsDown,
                [classes.displayWide]:
                  isOneThird ||
                  isHalf ||
                  isSmDown ||
                  isSmDown ||
                  (!title && !body),
                [classes.bottomWide]: isBottom,
                [classes.displaySmallMobile]: isXsDown || isOneThird
              })
            }}
            src={
              images && images.length > 0
                ? images[0]._url
                : isYouTubeThumbNail && isYouTubeThumbNail._url
            }
            alt={
              images && images.length > 0
                ? images[0].alt
                : isYouTubeThumbNail && isYouTubeThumbNail.alt
            }
          />
        ) : autoPlayVideo ? (
          <Box position="relative" className={classes.mediaSingle}>
            <iframe
              className={classNames(classes.media)}
              src={videoId(key, value, 0, true, 1, 1)}
              allow="autoplay"
              style={{ border: 0 }}
              frameBorder="0"
              allowFullScreen
            />
          </Box>
        ) : (
          <Grid
            className={classNames(classes.displayWide, {
              [classes.displaySmallMobile]: isXsDown || isOneThird
            })}
          >
            <iframe
              className={classNames(classes.videoThumbnail, {
                [classes.videoSingle]: !title && !body,
                [classes.media]: !isCarouselVideoThumbnail,
                [classes.fullSize]: fullSize
              })}
              src={videoId(key, value, 0, false, 0, 0)}
              style={{ border: 0 }}
              frameBorder="0"
              allowFullScreen
            />
          </Grid>
        )}
      </Grid>

      <Dialog
        className={classes.paper}
        onClose={handleClose}
        open={play || clickStateFromSlider ? true : false}
        maxWidth="md"
        fullWidth
      >
        <DialogContent dividers>
          <Box position="relative" className={classes.mediaSingle}>
            <iframe
              className={classNames(classes.media)}
              src={videoId(key, value, 1, true, 0, 0)}
              allow="autoplay"
              style={{ border: 0 }}
              frameBorder="0"
              allowFullScreen
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

const useStyles = makeStyles(
  ({
    breakpoints,
    spacing,
    common: { pageMaxWidth, aspectRatio16x9, aspectRatio4x3, aspectRatio1x1 }
  }) => ({
    wrapper: {
      position: "relative",
      display: "block",
      width: "100%",
      height: "100%"
    },
    mediaContainer: {
      display: "block",
      aspectRatio: aspectRatio1x1
    },
    displaySmall: {
      aspectRatio: aspectRatio4x3
    },
    displayWide: {
      aspectRatio: aspectRatio16x9
    },
    displaySmallMobile: {
      // Same CSS as displaySmall but this is needed because of how it prioritize the CSS classes.
      aspectRatio: aspectRatio4x3
    },
    bottomWide: {
      aspectRatio: `${pageMaxWidth}/470`,
      [breakpoints.down("xs")]: {
        aspectRatio: aspectRatio4x3
      }
    },
    mediaSingle: {
      aspectRatio: MEDIA_BG_RATIO_SINGLE_VIDEO
    },
    videoThumbnail: {
      width: 720,
      height: 720
    },
    media: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "50%",
      transform: "translateY(-50%)",
      [breakpoints.down("sm")]: {
        padding: 0,
        height: "100%"
      }
    },
    playButton: {
      fontSize: spacing(8),
      position: "absolute",
      left: "50%",
      marginLeft: `-${spacing(4)}px`,
      top: "50%",
      marginTop: `-${spacing(4)}px`,
      zIndex: 1,
      "&:hover": {
        cursor: "pointer",
        background: themeColors.darkBorder,
        borderRadius: "50%"
      }
    },
    playButtonThumbnail: {
      fontSize: spacing(48),
      marginLeft: `-${spacing(24)}px`,
      marginTop: `-${spacing(24)}px`,
      "&:hover": {
        cursor: "pointer",
        background: "none",
        borderRadius: "50%"
      }
    },
    isHalf: {
      padding: spacing(4)
    },
    mediaImage: {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover"
    },
    videoSingle: {
      padding: 0
    },
    fullSize: {
      padding: 0
    },
    paper: {
      "& .MuiDialog-paperFullWidth": {
        margin: spacing(2),
        width: "100%"
      },
      "& .MuiDialogContent-root:first-child": {
        padding: spacing(1)
      }
    }
  })
)

export default ExternalVideoAndImage
