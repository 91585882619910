import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles } from "@material-ui/core/styles"
import Close from "@material-ui/icons/Close"
import Search from "@material-ui/icons/Search"

import TextField from "common/TextField"
import DealerSearchStore from "store/DealerSearchStore"

const SearchField: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: {
      mapSearch,
      deniedGeolocation,
      searchedDealer,
      defaultMapZoom,
      searchTerm
    },
    actions: {
      setGeoLocation,
      setSearchGeoLocation,
      setMapZoom,
      setSearchedDealer,
      setSearchTerm,
      resetSearchedDealer,
      setSelectedDealer
    }
  } = useStore(DealerSearchStore)

  const searchInputRef = useRef<HTMLInputElement>(null)

  useEffect(
    () => {
      if (
        !searchedDealer.loading &&
        searchedDealer.data &&
        searchedDealer.data.center
      ) {
        setGeoLocation({
          lat: searchedDealer.data.center.latitude,
          lng: searchedDealer.data.center.longitude
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchedDealer.loading]
  )

  useEffect(
    () => {
      if (!mapSearch.loading && mapSearch.data) {
        const location = mapSearch.data
        if (location) {
          setSearchGeoLocation(location).then(() => setGeoLocation(location))
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapSearch.loading]
  )

  const handleSubmit = () => {
    if (searchTerm === "") {
      resetSearchedDealer()
        .then(() => setSelectedDealer(null))
        .then(() => setMapZoom(defaultMapZoom))
    } else {
      if (mapSearch.data) {
        const model = {
          searchTerm,
          userLocation: deniedGeolocation
            ? null
            : {
                latitude: mapSearch.data.lat,
                longitude: mapSearch.data.lng
              }
        }
        setSearchedDealer(model)
          .then(() => setSelectedDealer(null))
          .then(() => setMapZoom(Math.ceil(defaultMapZoom * 1.25)))
      }
    }
  }

  const handleClearSearchField = () => {
    if (!searchInputRef.current) return

    searchInputRef.current.value = ""
    setSearchTerm("")
      .then(() => setSelectedDealer(null))
      .then(() => setMapZoom(defaultMapZoom))
      .then(() =>
        resetSearchedDealer().then(() => setGeoLocation(mapSearch.data))
      )
  }

  return (
    <TextField
      fullWidth
      variant="outlined"
      name="searchTerm"
      className={classes.textField}
      placeholder={t("contact.dealer_search_placeholder")}
      inputRef={searchInputRef}
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.currentTarget.value)}
      onKeyDown={(e) => {
        if (e.keyCode === 13 && !mapSearch.loading) {
          handleSubmit()
        }
      }}
      inputProps={{
        "aria-label": t("contact.dealer_search_placeholder")
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton className={classes.iconButton} onClick={handleSubmit}>
              <Search className={classes.searchIcon} />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {!!searchTerm && (
              <InputAdornment position="end">
                <IconButton
                  className={classes.iconButton}
                  onClick={handleClearSearchField}
                >
                  <Close className={classes.clearIcon} />
                </IconButton>
              </InputAdornment>
            )}
          </>
        )
      }}
      disabled={mapSearch.loading}
    />
  )
}

const useStyles = makeStyles(
  ({ breakpoints, common: { themeColors }, spacing }) => ({
    textField: {
      padding: 0,
      "& input": {
        padding: spacing(2, 0),
        [breakpoints.down("md")]: {
          fontSize: 15
        },
        [breakpoints.down("xs")]: {
          fontSize: 14
        }
      },
      "& .MuiOutlinedInput-inputAdornedEnd": {
        maxWidth: "100%"
      },
      "& .MuiInputBase-root": {
        padding: 0
      }
    },
    iconButton: {
      color: themeColors.darkGray,
      margin: 0,
      padding: 0,
      cursor: "pointer"
    },
    searchIcon: {
      margin: spacing(0, 1, 0, 2)
    },
    clearIcon: {
      margin: spacing(0, 2, 0, 1)
    }
  })
)

export default SearchField
