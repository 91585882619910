import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const PinDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
      d="M23.29 6.91c3.817 3.816 3.817 10.002 0 13.818-1.96 1.962-6.909 6.91-6.909 6.91l-6.909-6.91c-3.816-3.816-3.816-10.002 0-13.818 3.816-3.816 10.003-3.816 13.819 0Z"
      fill="none"
    />
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
      d="M18.278 11.9a2.714 2.714 0 1 1-3.838 3.838 2.714 2.714 0 0 1 3.838-3.838Z"
      fill="none"
    />
  </SvgIcon>
)

export default PinDeLaval
