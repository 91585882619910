import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { MenuItem } from "@starrepublic/epi/cms"
import classNames from "classnames"
import useStore from "global-hook-store"
import debounce from "lodash/debounce"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"

import { countObjectDepth } from "utils/hierarchyDepthCounter"

import LinkText from "common/LinkText"
import Wrapper from "common/Wrapper"
import { eventMegaMenuTracking } from "services/StarGA"
import originalPartsStore from "store/OriginalPartsStore"
import SiteSettingStore from "store/SiteSettingStore"

type Props = {
  onLinkClick: () => void
  menuItem: MenuItem | null
}

const MenuContent: React.FC<Props> = ({ onLinkClick, menuItem }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    actions: { open: openOriginalParts }
  } = useStore(originalPartsStore)

  const {
    state: { enableOriginalParts }
  } = useStore(SiteSettingStore)

  const [activeMenu, setActiveMenu] = useState("")

  const handleMouseOver = debounce((id: string) => {
    if (activeMenu !== id) {
      setActiveMenu(id)
    }
  }, 100)

  const useRootCategory =
    (menuItem && menuItem["menuType"] === "ProductMenu") ||
    (menuItem && menuItem["menuType"] === "StoreMenu")

  const menuItemArray = useMemo(() => {
    return useRootCategory
      ? menuItem && menuItem.children
      : [
          {
            _id: "",
            title: "",
            url: "",
            children:
              menuItem &&
              menuItem.children &&
              menuItem.children.reduce((a: MenuItem[], b) => a.concat(b), [])
          } as MenuItem
        ]
  }, [menuItem, useRootCategory])

  useEffect(() => {
    if (menuItemArray && menuItemArray.length > 0) {
      setActiveMenu(menuItemArray[0]._id)
    }
  }, [menuItemArray])

  return (
    <div className={classes.root}>
      <Grid container className={classes.itemContainer} component="nav">
        <Grid item md={3} hidden={!useRootCategory}>
          <Wrapper
            className={classes.menuWrapper}
            containerId="mega-menu-navigation-root-subcategories"
          >
            {menuItemArray &&
              menuItemArray.map((item: MenuItem) => {
                return (
                  <div
                    key={item._id}
                    onMouseEnter={() => {
                      handleMouseOver(item._id)
                    }}
                  >
                    <LinkText
                      onClick={() => {
                        onLinkClick()
                        eventMegaMenuTracking(
                          item.url,
                          countObjectDepth(item),
                          item.title
                        )
                      }}
                      link={item.url}
                      linkText={item.title}
                      variant="link1"
                      className={classNames(classes.itemGroupTitle, {
                        [classes.forcedHover]: item._id === activeMenu
                      })}
                    />
                  </div>
                )
              })}
            {enableOriginalParts && (
              <>
                <div className={classes.menuDividerWrapper}>
                  <span className={classes.menuItemDivider} />
                </div>
                <div>
                  <LinkText
                    onClick={(e) => {
                      e.preventDefault()
                      onLinkClick()
                      openOriginalParts()
                    }}
                    link={"/"}
                    linkText={t("common.looking_for_original_parts")}
                    variant="link1"
                    className={classNames(classes.itemGroupTitle)}
                  />
                </div>
              </>
            )}
          </Wrapper>
        </Grid>
        <Grid item hidden={!useRootCategory} className={classes.divider} />
        <Grid
          item
          md={useRootCategory ? 9 : 12}
          className={classes.itemsWrapper}
          id="mega-menu-navigation-subcategories"
        >
          {menuItemArray &&
            menuItemArray.map((item: MenuItem) => {
              return (
                <div
                  className={classNames(classes.subCategoryWrapper, {
                    [classes.hidden]: activeMenu !== item._id
                  })}
                  key={item._id}
                >
                  {item.children &&
                    item.children.map((childItem: MenuItem) => {
                      return (
                        <Grid
                          data-type="mega-menu-sub-navigation"
                          item
                          md={useRootCategory ? 4 : 3}
                          lg={useRootCategory ? 3 : 2}
                          key={childItem._id}
                          className={`mega-menu-sub-navigation ${classes.wrapper}`}
                        >
                          <div className={classes.categoryWrapper}>
                            <LinkText
                              onClick={() => {
                                onLinkClick()
                                eventMegaMenuTracking(
                                  childItem.url,
                                  countObjectDepth(childItem),
                                  childItem.title
                                )
                              }}
                              link={childItem.url}
                              variant="link2"
                              className={classes.subCategoryHeader}
                            >
                              {childItem.title}
                              <KeyboardArrowRight
                                className={classes.menuArrow}
                              />
                            </LinkText>
                            {item["hideThirdLevelMenuChildren"]
                              ? null
                              : childItem.children &&
                                childItem.children!.map((child: MenuItem) => {
                                  return (
                                    <LinkText
                                      key={child._id}
                                      onClick={() => {
                                        onLinkClick()
                                        eventMegaMenuTracking(
                                          child.url,
                                          countObjectDepth(child),
                                          child.title
                                        )
                                      }}
                                      link={child.url}
                                      linkText={child.title}
                                      variant="link4"
                                      className={classes.subCategory}
                                    />
                                  )
                                })}
                          </div>
                        </Grid>
                      )
                    })}
                </div>
              )
            })}
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors, gridSpacing } }) => ({
    root: {},
    itemContainer: {
      letterSpacing: 0,
      flexWrap: "nowrap"
    },
    menuWrapper: {
      padding: 0,
      paddingTop: gridSpacing,
      marginTop: spacing(1),
      marginBottom: spacing(3)
    },
    itemsWrapper: {
      marginTop: spacing(1),
      marginBottom: spacing(3)
    },
    itemGroupTitle: {
      padding: 0,
      margin: 0,
      width: "100%",
      textAlign: "right",
      "& span": {
        color: themeColors.textTransparent,
        paddingRight: 26,
        borderRight: "1px solid transparent",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        marginBottom: gridSpacing
      },

      "&:hover span": {
        color: themeColors.white
      }
    },
    menuDividerWrapper: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "column",
      marginBottom: 24,
      paddingRight: 26
    },
    menuItemDivider: {
      display: "inline-block",
      height: 0,
      width: 56,
      borderBottom: `1px solid ${themeColors.transparent}`
    },
    forcedHover: {
      "& span": {
        color: themeColors.white,
        paddingRight: gridSpacing,
        borderRight: `3px solid ${themeColors.white}`
      }
    },
    subCategoryWrapper: {
      marginBottom: gridSpacing,
      marginTop: gridSpacing,
      paddingLeft: gridSpacing,
      display: "flex",
      flexWrap: "wrap"
    },
    wrapper: {
      display: "flex",
      paddingBottom: gridSpacing
    },
    categoryWrapper: {
      width: 273,
      paddingRight: gridSpacing
    },
    subCategoryHeader: {
      display: "flex",
      flexDirection: "row",
      paddingBottom: spacing(1) * 0.5,
      "& span": {
        color: themeColors.white,
        "&:hover": {
          color: themeColors.white
        }
      }
    },
    subCategory: {
      display: "flex",
      padding: "4px 0",
      "& span": {
        color: themeColors.textTransparent,
        "&:hover": {
          color: themeColors.offWhite
        }
      }
    },
    hidden: { display: "none" },
    divider: {
      width: "1px",
      marginLeft: "-2px",
      backgroundColor: themeColors.transparent
    },
    flexGrow: {
      flexGrow: 1
    },
    menuArrow: {
      height: "20px",
      width: "24px",
      marginRight: "-24px",
      verticalAlign: "bottom"
    }
  })
)

export default MenuContent
