import { useTranslation } from "react-i18next"
import { Formik, FormikActions } from "formik"
import useStore from "global-hook-store"

import { ContactPayload } from "api/contact"
import authStore from "store/AuthStore"
import contactModalStore from "store/ContactModalStore"
import pageStore from "store/PageStore"
import validationSchema, { ContactModalFormType } from "./Form/validationSchema"
import Form from "./Form"

const initialValues = {
  name: "",
  email: "",
  phone: "",
  message: ""
}

const ContactForm: React.FC = () => {
  const { t } = useTranslation()

  const {
    actions: { send: sendContactInformation }
  } = useStore(contactModalStore)

  const {
    state: { user }
  } = useStore(authStore)

  const {
    state: { page }
  } = useStore(pageStore)

  const handleSubmit = async (
    values: ContactModalFormType,
    { setSubmitting, setStatus }: FormikActions<ContactModalFormType>
  ) => {
    const payload = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      message: values.message,
      pageId: page.data && page.data._id
    } as ContactPayload
    const keyCount = Object.keys(values).length

    const formDataLayerPush = () => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "form_submit",
          form_id: page?.data?._id,
          form_name: t("contact.contact_me_header"),
          form_type: "contact form",
          form_fields: keyCount
        })
      }
    }

    sendContactInformation(payload).then(() => {
      formDataLayerPush()
      setSubmitting(false)
    })
  }

  if (user.data) {
    initialValues.name = `${user.data.firstName} ${user.data.lastName}`
    initialValues.email = user.data.email
    initialValues.phone = user.data.phone
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur
      component={Form}
      validationSchema={validationSchema(t)}
    />
  )
}

export default ContactForm
