import { useMemo, useState } from "react"
import debounce from "lodash/debounce"
import useResizeObserver from "use-resize-observer"

export type ObservedSize = {
  width: number | undefined
  height: number | undefined
}

export default (wait?: number | undefined) => {
  const [debouncedSize, setDebouncedSize] = useState<ObservedSize>({
    width: undefined,
    height: undefined
  })

  const { ref, width, height } = useResizeObserver({
    onResize: debounce((size) => {
      setDebouncedSize(size)
    }, wait || 500)
  })

  const debouncedSizeMemo = useMemo(() => {
    return {
      width: debouncedSize.width,
      height: debouncedSize.height
    }
  }, [debouncedSize])

  return {
    ref,
    ...debouncedSizeMemo
  }
}
