import { XhtmlString } from "@starrepublic/epi/cms"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import siteSettingStore from "store/SiteSettingStore"

const UserRegistrationTerms: React.FC = () => {
  const classes = useStyles()

  const {
    state: { terms }
  } = useStore(siteSettingStore)

  return (
    <Typography
      component="div"
      variant={"body2"}
      className={classes.htmlString}
    >
      <XhtmlString content={terms.userRegistrationTerms} />
    </Typography>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  htmlString: {
    textAlign: "left",
    "& a": {
      color: themeColors.primaryCyan
    }
  }
}))

export default UserRegistrationTerms
