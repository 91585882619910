import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import CheckoutSectionHeader from "../common/CheckoutSectionHeader"
import ContainerWrapper from "../common/ContainerWrapper"

import EditPen from "common/EditPen"
import Typography from "common/Typography"
import PaymentIcon from "icons/Payment"
import Shipping from "icons/Shipping"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import paymentStore from "store/PaymentStore"
import OrderReference from "./Payment/OrderReference"

const DeliveryPaymentSummary: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    checkout: { data: checkout },
    selectedPaymentOption,
    selectedSubPaymentOption,
    selectedDeliveryOption,
    orderReference,
    computed: { availableDeliveryOptions },
    reset: checkoutReset
  } = useCheckoutPaymentStore()

  const {
    state: { paymentMethods }
  } = useStore(paymentStore)

  const payment =
    selectedPaymentOption === "Adyen"
      ? paymentMethods.data &&
        paymentMethods.data.paymentMethods &&
        paymentMethods.data.paymentMethods.find((p) => {
          return p.type === selectedSubPaymentOption
        })
      : checkout &&
        checkout.paymentOptions.find((p) => {
          return p.id === selectedPaymentOption
        })
  const delivery = availableDeliveryOptions.find((d: any) => {
    return d.methodId === selectedDeliveryOption
  })

  const hideDeliverySummary =
    (checkout &&
      checkout.cart &&
      checkout.cart.properties &&
      checkout.cart.properties.numberOfDigitalServices > 0 &&
      checkout.cart.properties.numberOfProducts === 0) ||
    false

  return (
    <>
      {!hideDeliverySummary && (
        <ContainerWrapper isActive={true}>
          <CheckoutSectionHeader
            title={t("checkout.shipping_title")}
            Icon={Shipping}
          />
          {delivery && (
            <>
              <div className={classes.labelContainer}>
                <div className={classes.flex}>
                  <Typography variant="headline6">
                    {delivery.displayName}
                  </Typography>
                </div>
                <Typography variant="body1" color="textSecondary">
                  {delivery.displayName}
                </Typography>
              </div>
              <span className={classes.editWrapper}>
                <EditPen onClick={checkoutReset.preparedCheckout} />
              </span>
            </>
          )}
        </ContainerWrapper>
      )}

      <ContainerWrapper isActive={true}>
        <CheckoutSectionHeader
          title={t("checkout.payment_title")}
          Icon={PaymentIcon}
        />
        {payment && (
          <>
            <div className={classes.labelContainer}>
              <Typography variant="headline6">
                {payment.type === "ebanking_FI"
                  ? t("checkout.finnish_ebanking_payment_name")
                  : payment.name}
              </Typography>
            </div>
            {orderReference && (
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={6}>
                  <OrderReference />
                </Grid>
              </Grid>
            )}
            <span className={classes.editWrapper}>
              <EditPen onClick={checkoutReset.preparedCheckout} />
            </span>
          </>
        )}
      </ContainerWrapper>
    </>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  form: {
    width: "100%"
  },
  labelContainer: {
    borderTop: `1px solid ${themeColors.lightGray}`,
    borderBottom: `1px solid ${themeColors.lightGray}`,
    borderLeft: `3px solid ${themeColors.primaryCyan}`,
    padding: spacing(4)
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  editWrapper: {
    float: "right",
    margin: spacing(2),
    marginRight: spacing(4)
  }
}))

export default DeliveryPaymentSummary
