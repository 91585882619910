import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Clear from "@material-ui/icons/Clear"

import { Uint8ArrayDecoder } from "utils/uint8ArrayDecoder"

import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import DownloadIcon from "icons/DownloadIcon"
import MailIcon from "icons/Mail"
import PrinterIcon from "icons/Printer"
import { eventWishListPdfTracker } from "services/StarGA"
import AnonymousShoppingListStore, {
  Article
} from "store/AnonymousShoppingListStore"
import DrawerStateStore from "store/DrawerStateStore"
import SiteSettingStore from "store/SiteSettingStore"
import snackbarStore from "store/SnackbarStore"
import { printTemplate } from "./PrintTemplate"
import SendShoppingListModal from "./SendShoppingListModal"
import ShoppingItem from "./ShoppingItem"

const ShoppingList: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const url = window.location.href
  const pdfButtonEventText = "Save pdf button clicked"
  const printButtonEventText = "Print button clicked"

  const buttonContainerRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<Number>()

  useEffect(() => {
    if (buttonContainerRef.current) {
      setHeight(buttonContainerRef.current.clientHeight)
    }
  }, [])

  const {
    state: {
      enableSendShoppingList,
      enablePrintShoppingList,
      enableSaveShoppingList
    }
  } = useStore(SiteSettingStore)

  const {
    actions: { setShoppingListDrawer }
  } = useStore(DrawerStateStore)

  const {
    state: { shoppingList, isLoading, savedPDF },
    actions: { deleteShoppingList, fetchShoppingList, savePDF, loading, open }
  } = useStore(AnonymousShoppingListStore)

  const {
    actions: { openSnackbar }
  } = useStore(snackbarStore)

  const handleClearList = () => {
    deleteShoppingList().then(() => {
      fetchShoppingList()
    })
  }

  const handleSavePdfButton = () => {
    loading(true)

    savePDF().then(() => {
      const errorData = Uint8ArrayDecoder(savedPDF.error)
      if (shoppingList.data && shoppingList.data.articles) {
        if (errorData && errorData.status === 500) {
          openSnackbar({
            options: {
              message: t("error.internal_server_error")
            },
            type: "error"
          })
        } else {
          eventWishListPdfTracker(
            pdfButtonEventText,
            url,
            shoppingList.data.totalQuantity
          )
        }
      }
      loading(false).catch((res) => {
        console.log(res)
      })
    })
  }

  const handlePrintButton = () => {
    if (shoppingList.data) {
      eventWishListPdfTracker(
        printButtonEventText,
        url,
        shoppingList.data.totalQuantity
      )
      printTemplate(
        shoppingList.data.articles,
        t("shopping.title"),
        t("product.article_no"),
        t("cart.product_table_header"),
        t("cart.quantity_table_header"),
        t(`product.free_text_header`)
      )
    }
  }

  return (
    <>
      <Grid className={classes.header}>
        <Grid className={classes.mainHeader}>
          <Typography
            variant="headline3"
            color="textPrimary"
            className={classes.headerTitle}
          >
            {t("shopping.title")}
          </Typography>
          <Grid>
            <IconButton onClick={() => setShoppingListDrawer(false)}>
              <Clear className={classes.closeIcon} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid className={classes.clear}>
          <Typography
            onClick={() => handleClearList()}
            className={classes.clearList}
            variant="link2"
          >
            {t("shopping.clear_list")}
          </Typography>
        </Grid>
      </Grid>
      {shoppingList.data && shoppingList.data.articles && (
        <Box mt={12} pb={`${height}px`}>
          {shoppingList.data &&
            shoppingList.data.articles.map((list: Article, i: number) => {
              return (
                <Grid key={i}>
                  <ShoppingItem list={list} />
                </Grid>
              )
            })}
          <SendShoppingListModal />
        </Box>
      )}
      <Grid
        ref={buttonContainerRef}
        container
        justifyContent="center"
        className={classes.buttonContainer}
      >
        <Box px={1} mb={2}>
          <Typography variant="smallText" align="left">
            {t("shopping.drawer_buttons_description_text")}
          </Typography>
        </Box>
        {enablePrintShoppingList && (
          <Button
            id="print-shopping-list-button"
            onClick={() => handlePrintButton()}
            className={classes.button}
          >
            <Hidden xsDown>
              <Typography className={classes.buttonText} variant="link2">
                {t("common.print")}
              </Typography>
            </Hidden>
            <PrinterIcon />
          </Button>
        )}
        {enableSaveShoppingList && (
          <Button
            id="save-as-pdf-button"
            className={classes.button}
            onClick={() => handleSavePdfButton()}
          >
            <Hidden xsDown>
              <Typography className={classes.buttonText} variant="link2">
                {t("common.save_as_pdf")}
              </Typography>
            </Hidden>
            {isLoading ? (
              <ButtonLoaderIcon className={classes.loaderSpinner} />
            ) : (
              <DownloadIcon />
            )}
          </Button>
        )}
        {enableSendShoppingList && (
          <Button
            id="send-shopping-list-button"
            className={classes.mailButton}
            onClick={() => open()}
          >
            <Hidden xsDown>
              <Typography className={classes.buttonText} variant="link2">
                {t("common.send_shopping_list")}
              </Typography>
            </Hidden>
            <MailIcon />
          </Button>
        )}
      </Grid>
    </>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors }, breakpoints }) => ({
    header: {
      display: "flex",
      flexDirection: "column",
      height: spacing(12),
      borderBottom: `1px solid ${themeColors.lightGray}`,
      position: "fixed",
      width: 480,
      zIndex: 1,
      backgroundColor: themeColors.white,
      paddingTop: spacing(1.5),
      paddingRight: spacing(3),
      paddingLeft: spacing(3),
      [breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    mainHeader: {
      display: "flex",
      alignItems: "center"
    },
    headerTitle: {
      flexGrow: 2
    },
    closeIcon: {
      color: "#000",
      width: spacing(3.5),
      height: spacing(3.5)
    },
    clear: {
      paddingBottom: spacing(1.5),
      position: "relative",
      top: -2
    },
    clearList: {
      fontWeight: "normal",
      cursor: "pointer"
    },
    button: {
      margin: spacing(1),
      minWidth: 200,
      width: "100%",
      padding: spacing(2),
      backgroundColor: themeColors.white,
      border: `2px solid ${themeColors.primaryCyan}`,
      "&:hover": {
        backgroundColor: themeColors.white
      },
      [breakpoints.down("xs")]: {
        minWidth: 140
      }
    },
    mailButton: {
      width: "100%",
      margin: spacing(1),
      minWidth: 200,
      padding: spacing(2),
      backgroundColor: themeColors.primaryCyan,
      "&:hover": {
        backgroundColor: themeColors.primaryCyan
      },
      "& span": {
        color: themeColors.white
      },
      [breakpoints.down("xs")]: {
        minWidth: 140
      }
    },
    buttonText: {
      paddingRight: spacing(1)
    },
    loaderSpinner: {
      width: 24,
      color: themeColors.primaryCyan
    },
    buttonContainer: {
      boxShadow: "0px 0px 0px 0px #fff, 0px -5px 10px -5px rgba(0,0,0,0.12)",
      backgroundColor: themeColors.white,
      position: "fixed",
      maxWidth: 480,
      bottom: 0,
      padding: `${spacing(3)}px ${spacing(3)}px ${spacing(2)}px`
    }
  })
)

export default ShoppingList
