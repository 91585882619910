import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import MailIcon from "icons/Mail"
import PageStore from "store/PageStore"
import SiteSettingsStore from "store/SiteSettingStore"
import NewsletterForm from "./NewsletterForm"

type Props = {
  light?: boolean
}

const NewsletterContent: React.FC<Props> = ({ light }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    state: { page }
  } = useStore(PageStore)

  const {
    state: {
      displayNewsletterForm,
      displayNewsletterNameFields,
      newsletterPreamble,
      newsletterTitle
    }
  } = useStore(SiteSettingsStore)

  if (!displayNewsletterForm) {
    return null
  }

  const isCheckoutPage = page.data && page.data._type === "CheckoutPage"

  return (
    <div
      className={classNames(classes.border, {
        [classes.isCheckout]: isCheckoutPage
      })}
    >
      <Wrapper>
        <Grid container className={classes.root}>
          <Grid
            container
            justifyContent={
              displayNewsletterNameFields ? "flex-start" : "center"
            }
          >
            <Grid
              xs={12}
              sm={displayNewsletterNameFields ? 12 : 8}
              md={displayNewsletterNameFields ? 12 : 5}
              item
            >
              <Grid container direction="column">
                <Grid container>
                  <Grid
                    className={classNames(classes.icon, {
                      [classes.light]: light
                    })}
                  >
                    <MailIcon />
                  </Grid>

                  <Typography
                    className={classNames({ [classes.light]: light })}
                    variant="headline5"
                  >
                    {newsletterTitle
                      ? newsletterTitle
                      : t("newsletter.heading")}
                  </Typography>
                </Grid>
                {newsletterPreamble && (
                  <div className={classes.preamble}>
                    <ThemeXhtmlString content={newsletterPreamble} />
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <NewsletterForm />
        </Grid>
      </Wrapper>
    </div>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors, pageMaxWidth }, spacing }) => ({
    root: {
      width: "100%",
      maxWidth: pageMaxWidth,
      margin: "0 auto",
      paddingInline: spacing(1.5),
      paddingBlock: spacing(3, 0)
    },
    border: {
      borderTop: `1px solid ${themeColors.mediumGray}`
    },
    icon: {
      color: themeColors.primaryBlue,
      paddingRight: spacing(2)
    },
    light: {
      color: themeColors.white
    },
    preamble: {
      color: themeColors.darkGray
    },
    isCheckout: {
      display: "none"
    }
  })
)

export default NewsletterContent
