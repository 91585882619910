import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Image from "common/Image"
import LinkButton from "common/LinkButton"
import Typography from "common/Typography"
import { Article } from "store/AnonymousShoppingListStore"
import QuantityField from "./QuantityField"

type Props = {
  list: Article
}

const ShoppingItem: React.FC<Props> = ({ list }) => {
  const classes = useStyles()

  return (
    <>
      <Grid className={classes.root}>
        <LinkButton
          link={list.url ? list.url : "/"}
          className={classes.itemLink}
        >
          <Image
            className={classes.media}
            containerProps={{
              className: classes.mediaContainer
            }}
            src={list.imageUrl}
            lazy
          />
        </LinkButton>
        <Grid className={classes.container}>
          <Grid container>
            <Grid item>
              <Typography>{list.articleNumber}</Typography>
              <Typography className={classes.productName} variant="headline6">
                {list.name}
              </Typography>
              <Typography>{list.variationValue}</Typography>
            </Grid>
          </Grid>
          <QuantityField
            itemCode={list.articleNumber}
            quantity={list.quantity}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors }, breakpoints }) => ({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flex: "0 0 auto",
      padding: `${spacing(2.25)}px ${spacing(2)}px`
    },
    divider: {
      width: "100%",
      backgroundColor: themeColors.grayBorder
    },
    container: {
      marginLeft: spacing(2),
      display: "flex",
      alignItems: "center",
      width: "100%"
    },
    productName: {
      fontSize: 16,
      paddingRight: spacing(1)
    },
    media: {
      width: spacing(6),
      height: spacing(4.5),
      objectFit: "cover",
      objectPosition: "center",
      position: "unset"
    },
    itemLink: {
      padding: 0,
      textAlign: "left"
    },
    mediaContainer: {
      display: "flex"
    }
  })
)

export default ShoppingItem
