import { LinkProps } from "react-router-dom"
import classNames from "classnames"

import MuiButton, {
  ButtonProps as MuiButtonProps
} from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

import { contrastSwitch } from "utils/contrastSwitch"

import { themeColors } from "theme"

type ExcludeComponentButtonProps = Pick<
  MuiButtonProps,
  Exclude<keyof MuiButtonProps, "component">
>

type Props = {
  theme?: string
  isLoading?: boolean
  centerIcon?: Pick<ButtonProps, "startIcon">
  variant?: "primary" | "secondary" | "text" | "destructive" | "deprecated"
  contrastColor?: string
} & Pick<ButtonProps, Exclude<keyof MuiButtonProps, "variant">>

type ButtonProps = MuiButtonProps | (ExcludeComponentButtonProps & LinkProps)

const getMuiVariant = (variant: string) => {
  const variants = {
    primary: "contained",
    secondary: "outlined",
    text: "text",
    destructive: "contained",
    destructiveOutline: "outlined",
    compatibility: "contained"
  }
  return variants[variant] || variants["deprecated"]
}
const getMuiColorByVariant = (variant: string) => {
  const variants = {
    primary: "primary",
    secondary: "primary",
    text: "primary",
    destructive: "secondary",
    destructiveOutline: "secondary",
    compatibility: "primary"
  }
  return variants[variant] || variants["deprecated"]
}

const Button: React.FC<Props> = ({
  theme = "#FFFFFF",
  children,
  isLoading,
  disabled,
  variant = "compatibility",
  color,
  centerIcon,
  contrastColor,
  className,
  ...rest
}) => {
  // Link is not compatible with the component property from ButtonProps
  const classes = useStyles()
  const _color = getMuiColorByVariant(variant)
  const isThemeColorBaseGray400 = theme === themeColors.colorBaseGray400
  return (
    <MuiButton
      variant={getMuiVariant(variant)}
      disabled={disabled || isLoading}
      color={_color}
      {...rest}
      className={classNames(
        className,
        contrastColor && classes[contrastSwitch({ variant, contrastColor })],
        {
          [classes.centeredIcon]: centerIcon,
          [classes.darkDisabledButton]:
            isThemeColorBaseGray400 && (disabled || isLoading)
        }
      )}
    >
      {centerIcon || children}
    </MuiButton>
  )
}
const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  [`text-base-white`]: {
    color: themeColors.white,
    borderColor: themeColors.white
  },
  [`text-base-blue-650`]: {
    color: themeColors.colorBaseBlue650,
    borderColor: themeColors.colorBaseBlue650
  },
  ["text-base-blue-700"]: {
    color: themeColors.colorBaseBlue700
  },
  ["text-base-blue-800"]: {
    color: themeColors.colorBaseBlue800
  },
  ["primary-base-blue-650"]: {
    backgroundColor: themeColors.colorBaseBlue650
  },
  centeredIcon: {
    padding: spacing(1.5),
    minWidth: "44px",
    maxHeight: "44px"
  },
  spinner: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: spacing(1)
  },
  darkDisabledButton: {
    backgroundColor: `${themeColors.colorBaseGray500} !important`
  }
}))

export default Button
