import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { blockDisplay } from "utils/blockDisplay"

import BlockWrapper from "common/BlockWrapper"
import Typography from "common/Typography"
import {
  AnimalCow,
  buildingDeLaval,
  bulbDeLaval,
  centerDeLaval,
  checkDeLaval,
  Clock,
  closeDeLaval,
  companyDeLaval,
  cowDeLaval,
  customersDeLaval,
  deviceOnDeLaval,
  farmDeLaval,
  FoodSafety,
  hideDeLaval,
  homeDeLaval,
  mailDeLaval,
  Money,
  moneyDeLaval,
  phoneDeLaval,
  pinDeLaval,
  searchDeLaval,
  securityDeLaval,
  servicesDeLaval,
  showDeLaval,
  tagDeLaval,
  timeDeLaval,
  usersDeLaval,
  utensilsDeLaval
} from "icons/index"
import themeColors from "theme/colors"

export type svgItems = {
  svgIconUrl: string
  svgIcon: string
  heading: string
  text: string
}

type HighlightBlockContent = {
  heading: string
  description: string
  items: svgItems[]
  theme: string
  isSectionChild?: boolean
}

const svgIconMapping = {
  AnimalCow,
  FoodSafety,
  Money,
  Clock,
  usersDeLaval,
  phoneDeLaval,
  mailDeLaval,
  buildingDeLaval,
  bulbDeLaval,
  centerDeLaval,
  checkDeLaval,
  closeDeLaval,
  companyDeLaval,
  cowDeLaval,
  customersDeLaval,
  deviceOnDeLaval,
  farmDeLaval,
  hideDeLaval,
  homeDeLaval,
  moneyDeLaval,
  pinDeLaval,
  searchDeLaval,
  securityDeLaval,
  servicesDeLaval,
  showDeLaval,
  tagDeLaval,
  timeDeLaval,
  utensilsDeLaval
}

type Props = BlockProps<HighlightBlockContent>

const HighlightBlock: React.FC<Props> = ({ content, blockInfo }) => {
  const classes = useStyles()
  const isMdUp = useMediaQuery(({ breakpoints }) => breakpoints.up("md"))
  const isSMDown = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"))
  const { items, heading, theme, isSectionChild, description } = content
  const display = blockInfo!["_display"]
  const dark = themeColors.primaryBlue
  const isSmallGrid =
    blockDisplay(blockInfo).oneThird || blockDisplay(blockInfo).half
  const isFullWidth = blockDisplay(blockInfo).full

  return (
    <BlockWrapper
      bgcolor={theme}
      display={display}
      className={classNames(classes.root, {
        [classes.smallGridWrapper]: isSmallGrid,
        [classes.fullWidth]: isFullWidth
      })}
    >
      {(heading || description) && (
        <Box py={2} px={3} maxWidth={700} mx="auto">
          {heading && (
            <Box>
              <Typography
                className={classNames({
                  [classes.lightText]: content.theme === dark
                })}
                align="center"
                variant="headline3"
                component={isSectionChild ? "h3" : "h2"}
              >
                {heading}
              </Typography>
            </Box>
          )}
          {description && (
            <Box mt={heading ? 2 : 0}>
              <Typography
                className={classNames({
                  [classes.lightText]: content.theme === dark
                })}
                align="center"
                variant="body1"
              >
                {description}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      <Grid container>
        {items.map((item, i) => {
          const Component = svgIconMapping[item.svgIcon]
          return (
            <Grid
              key={i}
              item
              md={isSmallGrid ? 12 : items.length > 2 ? 6 : 12}
              sm={12}
              className={classes.gridItem}
            >
              <Box
                justifyContent="center"
                display="flex"
                flexDirection={isSMDown && isSmallGrid ? "column" : "row"}
                alignItems={isSMDown && isSmallGrid ? "center" : "flex-start"}
                px={
                  isSmallGrid
                    ? 0
                    : display === "full"
                      ? { xs: 0, md: 2, lg: 3 }
                      : { xs: 3, sm: 4, lg: 6 }
                }
                py={2.5}
              >
                <Box
                  display="flex"
                  alignItems="flex-start"
                  className={classNames(classes.icon, {
                    [classes.lightIcon]: content.theme === dark
                  })}
                  mr={isSMDown && isSmallGrid ? 0 : isMdUp ? 4 : 3}
                >
                  <Component
                    className={classNames(classes.iconSize, {
                      [classes.iconSizeSmall]: isSmallGrid
                    })}
                  />
                </Box>
                <Box maxWidth={500} width={"100%"}>
                  <Box mb={1}>
                    <Typography
                      className={classNames({
                        [classes.lightText]: content.theme === dark,
                        [classes.smallHeadline]: isSmallGrid
                      })}
                      variant="headline5"
                    >
                      {item.heading}
                    </Typography>
                  </Box>
                  {!isSmallGrid && (
                    <Typography
                      className={classNames({
                        [classes.lightText]: content.theme === dark
                      })}
                      variant="body1"
                    >
                      {item.text}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </BlockWrapper>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    root: {
      paddingTop: spacing(1.5),
      paddingBottom: spacing(2)
    },
    icon: {
      color: themeColors.primaryBlue
    },
    lightIcon: {
      color: themeColors.primaryCyan
    },
    iconSize: {
      height: spacing(5),
      width: spacing(5),
      [breakpoints.up("sm")]: {
        height: spacing(7),
        width: spacing(7)
      },
      [breakpoints.up("md")]: {
        height: spacing(9),
        width: spacing(9)
      }
    },
    iconSizeSmall: {
      height: spacing(5),
      width: spacing(5)
    },
    smallHeadline: {
      fontSize: 24,
      fontWeight: 300,
      [breakpoints.down("sm")]: {
        paddingTop: spacing(1),
        textAlign: "center",
        fontSize: 20
      }
    },
    lightText: {
      color: themeColors.white
    },
    smallGridWrapper: {
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      [breakpoints.down("sm")]: {
        padding: spacing(0, 2)
      }
    },
    gridItem: {
      [breakpoints.down("sm")]: {
        flexGrow: 1
      }
    },
    fullWidth: {
      margin: spacing(1.5, 0)
    }
  })
)

export default HighlightBlock
