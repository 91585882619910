import MuiGrid, { GridProps } from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { Omit } from "utils/types"

type Props = {
  container?: boolean
} & Omit<GridProps, "container" | "spacing">

const BlockGrid: React.FC<Props> = ({ container, ...gridProps }) => {
  const classes = useStyles()
  return container ? (
    <MuiGrid container className={classes.container} {...gridProps} />
  ) : (
    <MuiGrid {...gridProps} />
  )
}

const useStyles = makeStyles(
  ({ breakpoints, common: { gridSpacing, gridSpacingMobile } }) => ({
    container: {
      "&> div[class*='MuiGrid-item']": {
        [breakpoints.up("md")]: {
          padding: gridSpacing / 2
        },
        padding: gridSpacingMobile / 2
      }
    }
  })
)

export default BlockGrid
