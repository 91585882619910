import { BlockProps } from "@starrepublic/epi/cms"

import Tooltip from "@material-ui/core/Tooltip"

import CustomTextField from "common/Formik/CustomTextField"

type Props = BlockProps<{
  theme: string
  label: string
  formElementId: string
  placeholder: string
  description: string
}>

const TextboxElementBlock: React.FC<Props> = ({
  content: { label, formElementId, placeholder, description, theme }
}) => {
  return (
    <Tooltip
      placement="top"
      arrow
      title={description ? description : ""}
      interactive
    >
      <div>
        <CustomTextField
          {...{ label, placeholder, theme }}
          name={formElementId}
        />
      </div>
    </Tooltip>
  )
}

export default TextboxElementBlock
