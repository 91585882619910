import { BlockProps } from "@starrepublic/epi/cms"

import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import LinkButton from "common/LinkButton"
import Typography from "common/Typography"
import SearchComponent from "./SearchComponent"
import { SearchBlockContent } from "./types"

type Props = BlockProps<SearchBlockContent>

const SearchBlock: React.FC<Props> = (props) => {
  const { content, propsInfo, blockInfo } = props
  const classes = useStyles()

  return (
    <Box py={{ xs: 4, sm: 7 }} className={classes.root}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={11} sm={10} md={8}>
          <Typography
            className={classes.heading}
            align="center"
            variant="headline2"
          >
            {content.heading}
          </Typography>
          <SearchComponent />
          <Box pt={{ xs: 4, sm: 7 }}>
            <Divider />
          </Box>
          <Box pb={{ xs: 3, sm: 4 }} pt={{ xs: 3, sm: 6 }}>
            <Typography align="center" variant="headline4">
              {content.linksHeading}
            </Typography>
          </Box>
          <Grid container justifyContent="flex-start">
            {content.linkItems.map((item) => {
              return (
                <Grid
                  className={classes.button}
                  container
                  justifyContent="center"
                  item
                  xs={6}
                  sm={4}
                  lg={3}
                  key={item.url}
                >
                  <LinkButton link={item.url}>
                    <Typography variant="link3">{item.text}</Typography>
                  </LinkButton>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    root: {
      margin: `${spacing(8)}px 0px`,
      [breakpoints.down("sm")]: {
        margin: `${spacing(4)}px 0px`
      },
      [breakpoints.down("xs")]: {
        position: "relative",
        width: "100vw",
        marginLeft: "calc(50% - 50vw)"
      },
      background: themeColors.offWhite,
      "& > div": {
        height: "100%"
      }
    },
    heading: {
      marginBottom: spacing(5)
    },
    button: {
      marginBottom: spacing(2.5),
      color: themeColors.primaryCyan,
      "& a > button": {
        padding: 0
      },
      "& span": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 200,
        color: themeColors.primaryCyan,
        [breakpoints.down("md")]: {
          maxWidth: 160
        }
      }
    }
  })
)

export default SearchBlock
