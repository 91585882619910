import { useCallback, useEffect, useMemo, useRef } from "react"
import classNames from "classnames"
import useStore from "global-hook-store"
import useResizeObserver from "use-resize-observer"

import Box from "@material-ui/core/Box"
import Drawer from "@material-ui/core/Drawer"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Close from "@material-ui/icons/Close"
import MenuIcon from "@material-ui/icons/Menu"

import Logo from "common/Logo"
import ShoppingCart from "common/ShoppingCart"
import ShoppingList from "common/ShoppingList"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import MyAccountIcon from "icons/MyAccountIcon"
import AccountLogin from "pages/AccountPage/common/AccountLogin"
import accountStore, { FormType } from "store/AccountStore"
import AnonymousShoppingListStore from "store/AnonymousShoppingListStore"
import AuthStore from "store/AuthStore"
import DrawerStateStore from "store/DrawerStateStore"
import SiteSettingsStore from "store/SiteSettingStore"
import SearchBarButton from "./SearchBar/Button"
import DrawerMenu from "./DrawerMenu"
import MarketSelector from "./MarketSelector"
import MegaMenu from "./MegaMenu"
import SearchBar from "./SearchBar"
import ShowCartButton from "./ShowCartButton"
import ShowShoppingListButton from "./ShowShoppingListButton"

const HeaderContent: React.FC = () => {
  const classes = useStyles()
  const isMdUp = useMediaQuery(({ breakpoints }) => breakpoints.up("md"))
  const isLgUp = useMediaQuery(({ breakpoints }) => breakpoints.up("lg"))

  const wrapperRef = useRef<HTMLDivElement>(null)

  const { height: wrapperHeight } = useResizeObserver({
    ref: wrapperRef
  })

  const {
    state: {
      isMobileMainMenuOpen,
      isMyAccountOpen,
      isCartOpen,
      isShoppingListOpen
    },
    actions: {
      setMobileMainMenuDrawer,
      setCartDrawer,
      setAccountDrawer,
      setShoppingListDrawer
    }
  } = useStore(DrawerStateStore)

  const {
    state: { user }
  } = useStore(AuthStore)

  const {
    state: {
      enableEcommerce,
      enableWishlist,
      enableDigitalServicesOnly,
      enableNoWebShopWithContactDealer,
      enableWebShopMigration,
      isOnePager
    }
  } = useStore(SiteSettingsStore)

  const {
    actions: { fetchShoppingList }
  } = useStore(AnonymousShoppingListStore)

  const {
    actions: { setFormComponent }
  } = useStore(accountStore)

  const isLoggedIn = useMemo(
    () => !user.loading && user.data != null,
    [user.loading, user.data]
  )

  const handleMobileMainMenuDrawer = useCallback(() => {
    setMobileMainMenuDrawer(!isMobileMainMenuOpen)
  }, [isMobileMainMenuOpen, setMobileMainMenuDrawer])

  useEffect(() => {
    if (enableWebShopMigration || enableWishlist || enableDigitalServicesOnly) {
      fetchShoppingList()
    }
  }, [
    enableWebShopMigration,
    enableWishlist,
    enableDigitalServicesOnly,
    fetchShoppingList
  ])

  const handleCloseDrawer = useCallback(() => {
    setAccountDrawer(false).then(() => {
      setFormComponent(FormType.login)
    })
  }, [setAccountDrawer, setFormComponent])

  return (
    <Box className={classes.rootContainer}>
      <Grid
        container
        justifyContent="space-between"
        direction={"row"}
        wrap={"nowrap"}
        className={classes.root}
        ref={wrapperRef}
      >
        {isOnePager || (!isOnePager && isMdUp) ? null : (
          <>
            <IconButton
              className={classes.mobileMainMenuButton}
              aria-label="Menu"
              onClick={handleMobileMainMenuDrawer}
            >
              <Close
                className={
                  isMobileMainMenuOpen ? undefined : classes.closeToggle
                }
              />
              <MenuIcon
                className={
                  isMobileMainMenuOpen ? classes.menuToggle : undefined
                }
              />
            </IconButton>
            <SearchBarButton />
          </>
        )}
        <Grid
          className={classNames(classes.logo, {
            [classes.onePageLogo]: isOnePager,
            [classes.commonLogo]: !isOnePager
          })}
        >
          <Logo />
        </Grid>
        {!isOnePager && <MegaMenu fixedTop={wrapperHeight || 0} />}

        <Grid
          className={classNames(classes.container, {
            [classes.rightButtonWrapper]:
              enableNoWebShopWithContactDealer ||
              enableWishlist ||
              !isMdUp ||
              isLgUp
          })}
          container
          direction={"row"}
          wrap={"nowrap"}
          justifyContent={"flex-end"}
        >
          {isOnePager ? <MarketSelector /> : isMdUp && <MarketSelector />}
          {enableNoWebShopWithContactDealer || isOnePager
            ? null
            : !enableWishlist && (
                <IconButton
                  className={classes.accountButton}
                  color="primary"
                  aria-label="Menu"
                  onClick={() => {
                    setAccountDrawer(true)
                  }}
                  id="account-drawer-button"
                >
                  {user.loading ? (
                    <ButtonLoaderIcon />
                  ) : isLoggedIn && user.data ? (
                    <div className={classes.userIconLoggedIn}>
                      <span>
                        {user.data.firstName.substring(0, 1) +
                          user.data.lastName.substring(0, 1)}
                      </span>
                    </div>
                  ) : (
                    <MyAccountIcon />
                  )}
                </IconButton>
              )}
          {enableNoWebShopWithContactDealer
            ? null
            : (enableDigitalServicesOnly || enableEcommerce) && (
                <IconButton
                  color="primary"
                  aria-label="Menu"
                  onClick={() => {
                    setCartDrawer(true)
                  }}
                  id="cart-drawer-button"
                >
                  <Grid container alignItems="center">
                    <Grid className={classes.cartButton}>
                      <ShowCartButton />
                    </Grid>
                  </Grid>
                </IconButton>
              )}
          {(enableDigitalServicesOnly ||
            enableWishlist ||
            enableWebShopMigration) && (
            <IconButton
              id="shopping-list-button"
              color="primary"
              aria-label="Menu"
              onClick={() => {
                setShoppingListDrawer(!isShoppingListOpen)
              }}
            >
              <ShowShoppingListButton />
            </IconButton>
          )}
        </Grid>
        <Drawer
          classes={{ paper: classes.drawer }}
          BackdropProps={{
            classes: { root: classes.pointerEvent }
          }}
          anchor="right"
          open={isMyAccountOpen}
          onClose={handleCloseDrawer}
        >
          <AccountLogin />
        </Drawer>
        {isMdUp ? null : (
          <Drawer
            anchor="left"
            open={isMobileMainMenuOpen}
            className={classes.menuDrawer}
            onClose={() => {
              setMobileMainMenuDrawer(false)
            }}
            classes={{ paper: classes.menuPaper }}
          >
            <DrawerMenu
              onClose={() => {
                setMobileMainMenuDrawer(false)
              }}
            />
          </Drawer>
        )}
        <Drawer
          classes={{ paper: classes.drawer }}
          anchor="right"
          open={isCartOpen}
          onClose={() => {
            setCartDrawer(false)
          }}
          BackdropProps={{
            classes: { root: classes.overLay }
          }}
        >
          <ShoppingCart />
        </Drawer>
        <Drawer
          classes={{ paper: classes.drawer }}
          anchor="right"
          open={isShoppingListOpen}
          onClose={() => {
            setShoppingListDrawer(!isShoppingListOpen)
          }}
          BackdropProps={{
            classes: { root: classes.overLay }
          }}
        >
          <ShoppingList />
        </Drawer>
        <SearchBar fixedTop={wrapperHeight || 0} />
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(
  ({
    common: { pageMaxWidth, pageMaxWidthNarrow, themeColors },
    breakpoints,
    spacing
  }) => ({
    rootContainer: {
      margin: "0 auto",
      width: "100%",
      [breakpoints.up("md")]: {
        maxWidth: breakpoints.values.md,
        marginInline: "auto"
      },
      [breakpoints.up(pageMaxWidth + spacing(2))]: {
        maxWidth: pageMaxWidth,
        "&.narrow": {
          maxWidth: pageMaxWidthNarrow
        }
      }
    },
    root: {
      height: 80,
      backgroundColor: themeColors.primaryBlue,
      zIndex: 999,
      width: "100%",
      position: "relative",
      padding: 0
    },
    menuDrawer: {
      zIndex: 1301,
      width: "100%"
    },
    menuPaper: {
      width: "95vw",
      backgroundColor: themeColors.primaryBlue
    },
    drawer: {
      width: spacing(1) * 60,
      [breakpoints.down("sm")]: {
        width: "100vw"
      }
    },
    mobileMainMenuButton: {
      color: themeColors.white,
      [breakpoints.down("xs")]: {
        margin: 0
      },
      [breakpoints.down("sm")]: {
        paddingTop: spacing(1.5),
        paddingBottom: spacing(1.5)
      }
    },
    closeToggle: {
      display: "none"
    },
    menuToggle: {
      display: "none"
    },
    userIconLoggedIn: {
      width: 24,
      height: 24,
      backgroundColor: themeColors.white,
      borderRadius: 24,
      fontSize: 11,
      color: themeColors.primaryBlue,
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: 0
    },
    logo: {
      padding: `${spacing(1)}px`,
      display: "flex",
      alignItems: "center",
      [breakpoints.down("sm")]: {
        padding: `${spacing(1)}px 0`,
        flexGrow: 1
      }
    },
    commonLogo: {
      justifyContent: "center"
    },
    onePageLogo: {
      justifyContent: "flex-start"
    },
    container: {
      color: themeColors.white,
      [breakpoints.down("sm")]: {
        paddingTop: 0
      }
    },
    rightButtonWrapper: {
      width: "auto"
    },
    accountButton: {
      color: themeColors.white,
      "&:hover": {
        background: "none"
      }
    },
    pointerEvent: {
      // TODO: This is not very fresh, why do we have this?
      // pointerEvents: "none", // Will not make the drawer close when clicking outside of it
      backgroundColor: "rgba(0, 0, 0, 0.2)"
    },
    overLay: {
      backgroundColor: "rgba(0, 0, 0, 0.2)"
    },
    price: {
      paddingLeft: spacing(2),
      color: themeColors.white
    },
    shoppingListButton: {
      color: themeColors.white,
      display: "flex",
      alignItems: "center",
      padding: `${spacing(2)}px ${spacing(2)}px`,
      marginRight: spacing(0),
      borderLeft: `1px solid ${themeColors.transparent}`,
      borderRight: `1px solid ${themeColors.transparent}`,
      "& svg": {
        width: 32,
        height: 32
      }
    },
    cartButton: {
      padding: `${spacing(1.5)}px ${spacing(0)}px`
    }
  })
)

export default HeaderContent
