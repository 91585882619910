import api from "@starrepublic/epi/api"
import { TokenResponse } from "@starrepublic/epi/api/users"
import jwt from "@starrepublic/epi/services/jwt"
import { asyncState, createStore } from "global-hook-store"

import contactsApi, { CustomerContactModel } from "api/customerContact"
import usersApi from "api/users"
import users from "api/users"
import {
  ChangePasswordModel,
  ImpersonationUserModel,
  UserRegistrationModel
} from "models/User"
import * as gtm from "services/StarGA"
import CartStore from "store/CartStore"
import DrawerStateStore from "store/DrawerStateStore"
import MarketStore from "store/MarketStore"
import SiteSettingStore from "store/SiteSettingStore"
import PageStore from "./PageStore"

const AuthStore = createStore(
  {
    tokenResponse: asyncState<TokenResponse>(),
    user: asyncState<UserRegistrationModel>(),
    ImpersonationUser: asyncState<ImpersonationUserModel[]>(),
    updateUserDetailsResponse: asyncState<CustomerContactModel>(),
    resetPasswordResponse: asyncState<any>(),
    changePasswordResponse: asyncState<any>(),
    resetPasswordSuccess: false,
    deletedShippingAddress: asyncState<any>()
  },
  {
    login: async (state, { userName, password }, { asyncAction }) => {
      const { tokenResponse } = await asyncAction(
        "tokenResponse",
        api.users.login(userName, password)
      )
      if (tokenResponse.error) {
        return { ...state, tokenResponse }
      }

      const changeMarketLoginMethod = (
        marketId: string,
        redirectUrl: string
      ) => {
        const payload = {
          marketId,
          redirectUrl
        }
        MarketStore.actions.changeMarketOnLogin(payload)
      }

      AuthStore.actions.getUser().then(() => {
        MarketStore.actions.fetchMarkets()
        if (
          AuthStore.state.user.data &&
          AuthStore.state.user.data.sapCustomerNumber
        ) {
          gtm.logSapCustomerNumber(
            AuthStore.state.user.data.sapCustomerNumber.substr(0, 3)
          )
        }

        const getMarketId =
          MarketStore.state &&
          MarketStore.state.availableMarkets.find((x) => {
            return (
              x.marketId.value ===
              (AuthStore.state.user.data && AuthStore.state.user.data.marketId)
            )
          })
        // We check here if the user is a partner portal user
        if (
          AuthStore.state.user.data &&
          AuthStore.state.user.data.isPartnerUser
        ) {
          SiteSettingStore.actions.fetchSiteSettings(false).then(() => {
            if (
              SiteSettingStore.state &&
              SiteSettingStore.state.loggedInLandingUrl
            ) {
              const loggedInLandingUrl =
                SiteSettingStore.state.loggedInLandingUrl
                  .split("/")
                  .filter((a) => {
                    return a.length
                  })
                  .slice(1)

              const partnerMarketLandingUrl = `/${[
                getMarketId && getMarketId.selectedLanguage
              ]
                .concat(loggedInLandingUrl)
                .join("/")}/`

              changeMarketLoginMethod(
                AuthStore.state.user.data
                  ? AuthStore.state.user.data.marketId
                  : "",
                partnerMarketLandingUrl.toLowerCase()
              )
            }
          })
        } else {
          const isLoginPage =
            PageStore.state.page.data &&
            PageStore.state.page.data._type === "LoginPage"

          if (getMarketId && (!getMarketId.isSelected || isLoginPage)) {
            changeMarketLoginMethod(
              AuthStore.state.user.data
                ? AuthStore.state.user.data.marketId
                : "",
              getMarketId
                ? `${window.location.origin}/${getMarketId.selectedLanguage}`
                : "/"
            )
          } else {
            CartStore.actions.getByCartTypeName("basket")
          }
        }
      })
      DrawerStateStore.actions.setAccountDrawer(false)
      return { ...state, tokenResponse, isLoggedIn: true }
    },
    switchUser: async (state, impersonateUserId, { asyncAction }) => {
      const { tokenResponse } = await asyncAction(
        "tokenResponse",
        api.users.switchUser(impersonateUserId)
      )
      if (tokenResponse.error) {
        return { ...state, tokenResponse }
      }

      const changeMarketLoginMethod = (
        marketId: string,
        redirectUrl: string
      ) => {
        const payload = {
          marketId,
          redirectUrl
        }
        MarketStore.actions.changeMarketOnLogin(payload)
      }

      AuthStore.actions.getUser().then(() => {
        MarketStore.actions.fetchMarkets()
        if (
          AuthStore.state.user.data &&
          AuthStore.state.user.data.sapCustomerNumber
        ) {
          gtm.logSapCustomerNumber(
            AuthStore.state.user.data.sapCustomerNumber.substr(0, 3)
          )
        }

        const getMarketId =
          MarketStore.state &&
          MarketStore.state.availableMarkets.find((x) => {
            return (
              x.marketId.value ===
              (AuthStore.state.user.data && AuthStore.state.user.data.marketId)
            )
          })
        // We check here if the user is a partner portal user
        if (
          AuthStore.state.user.data &&
          AuthStore.state.user.data.isPartnerUser
        ) {
          SiteSettingStore.actions.fetchSiteSettings(false).then(() => {
            if (
              SiteSettingStore.state &&
              SiteSettingStore.state.loggedInLandingUrl
            ) {
              const loggedInLandingUrl =
                SiteSettingStore.state.loggedInLandingUrl
                  .split("/")
                  .filter((a) => {
                    return a.length
                  })
                  .slice(1)

              const partnerMarketLandingUrl = `/${[
                getMarketId && getMarketId.selectedLanguage
              ]
                .concat(loggedInLandingUrl)
                .join("/")}/`

              changeMarketLoginMethod(
                AuthStore.state.user.data
                  ? AuthStore.state.user.data.marketId
                  : "",
                partnerMarketLandingUrl.toLowerCase()
              )
            }
          })
        } else {
          if (getMarketId && !getMarketId.isSelected) {
            changeMarketLoginMethod(
              AuthStore.state.user.data
                ? AuthStore.state.user.data.marketId
                : "",
              getMarketId
                ? `${window.location.origin}/${getMarketId.selectedLanguage}`
                : "/"
            )
          } else {
            CartStore.actions.getByCartTypeName("basket")
          }
        }
      })
      DrawerStateStore.actions.setAccountDrawer(false)
      return { ...state, tokenResponse, isLoggedIn: true }
    },
    resetPassword: async (_state, userName: string, { asyncAction }) =>
      asyncAction("resetPasswordResponse", usersApi.resetPassword(userName)),
    changePassword: async (
      _state,
      model: ChangePasswordModel,
      { asyncAction }
    ) => asyncAction("changePasswordResponse", usersApi.changePassword(model)),
    getUser: async (_state, _payload: null, { asyncAction }) =>
      asyncAction("user", usersApi.getUser()),
    getImpersonationUsers: async (_state, _payload: null, { asyncAction }) =>
      asyncAction("ImpersonationUser", usersApi.getImpersonationUsers()),
    logout: async (_, __: null, { reset }) => {
      jwt.clear()
      DrawerStateStore.actions.setAccountDrawer(false)
      CartStore.actions.getByCartTypeName("basket")

      const marketSelected =
        MarketStore.state &&
        MarketStore.state.availableMarkets.find((x) => {
          return x.isSelected === true
        })
      if (marketSelected) {
        window.location.href = `/${marketSelected.selectedLanguage.toLowerCase()}`
      }

      return { ...(await reset()) }
    },
    updateUserDetails: async (
      _state,
      _payload: CustomerContactModel,
      { asyncAction }
    ) =>
      asyncAction(
        "updateUserDetailsResponse",
        contactsApi.updateUserDetails(_payload)
      ),
    resetChangePasswordResponse: (_, __: null, { reset }) => {
      return reset("changePasswordResponse")
    },
    resetResetPasswordResponse: (_, __: null, { reset }) => {
      return reset("resetPasswordResponse")
    },
    deleteShippingAddress: async (_, deliveryId: string, { asyncAction }) =>
      asyncAction(
        "deletedShippingAddress",
        users.deleteShippingAddress(deliveryId)
      )
  }
)

if (jwt.token) {
  AuthStore.actions.getUser()
}

export default AuthStore
