import { useCallback, useEffect, useRef, useState } from "react"
import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Step from "@material-ui/core/Step"
import StepButton from "@material-ui/core/StepButton"
import StepContent from "@material-ui/core/StepContent"
import StepLabel from "@material-ui/core/StepLabel"
import Stepper from "@material-ui/core/Stepper"
import { makeStyles } from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import RemoveIcon from "@material-ui/icons/Remove"

import { getLabelNameUtil } from "utils/getLabelNameUtil"

import Button from "common/Button"
import Typography from "common/Typography"
import { fenceCalculatorStepperClick } from "services/StarGA"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"
import { themeColors } from "theme"
import FenceCorners from "./FenceCorners"
import FenceGateOpenings from "./FenceGateOpenings"
import FenceMaterial from "./FenceMaterial"
import FencePosts from "./FencePosts"
import SelectAccessories from "./SelectAccessories"
import SelectAnimal from "./SelectAnimal"
import SelectCategory from "./SelectCategory"
import SelectEnergizer from "./SelectEnergizer"
import StepLabelContent from "./StepLabelContent"
import Summary from "./Summary"
import { FenceCalculatorBlockProps } from "./types"

type Props = BlockProps<FenceCalculatorBlockProps>

const FenceCalculatorBlock = ({ content }: Props) => {
  const classes = useStyles()
  const stepRef = useRef<null | HTMLDivElement>(null)
  const [stepValid, setStepValid] = useState(false)
  const [completed, setCompleted] = useState(new Set<number>())
  const [showSummary, setShowSummary] = useState(false)
  // Represents whether an animal has been selected, if true -> go to the next step without the need of click "Next" btn.
  const [animalSelected, setAnimalSelected] = useState(false)
  // Represents whether an category has been selected, if true -> go to the next step without the need of click "Next" btn.
  const [categorySelected, setCategorySelected] = useState(false)
  const [confirmChangeOpen, setConfirmChangeOpen] = useState(false)

  const {
    step,
    setStep,
    resetStepper,
    clearValues,
    selectedAnimal,
    selectedCategory,
    lengthOfFence,
    totalLengthOfFence,
    numberOfWireRows,
    selectedWire,
    selectedGateInsulator,
    selectedPost,
    numberOfPosts,
    selectedGateHandle,
    distanceBetweenPosts,
    numberOfCornerPosts,
    numberOfWirePacks,
    numberOfFreeStandingGateOpenings,
    numberOfGateOpeningsInCorners,
    selectedInsulator,
    selectedEnergizer,
    selectedEnergizerPowerSource,
    selectedAccessories,
    setTotalItems,
    fenceCalculatorLabels,
    setFenceCalculatorLabels
  } = useFenceCalculatorStore()

  const fenceCalculatorSteps = () => {
    return [
      fenceCalculatorLabels.animalHeading,
      fenceCalculatorLabels.fenceTypeHeading,
      fenceCalculatorLabels.fencingMaterialHeading,
      fenceCalculatorLabels.fencePostsHeading,
      fenceCalculatorLabels.cornersHeading,
      fenceCalculatorLabels.gatesHeading,
      fenceCalculatorLabels.energizerHeading,
      fenceCalculatorLabels.accessoriesHeading
    ]
  }

  const steps = fenceCalculatorSteps()

  const handleComplete = (step: number) => {
    const newCompleted = completed
    newCompleted.add(step)
    setCompleted(newCompleted)
  }

  const isStepCompleted = (step: number) => {
    return completed.has(step)
  }

  const isStepOptional = (step: number) => {
    return step === 4 || step === 5 || step === 6
  }

  const isSkipStepEnabled = (step: number) => {
    return (
      (step === 4 && !numberOfCornerPosts) ||
      (step === 5 &&
        !numberOfFreeStandingGateOpenings &&
        !numberOfGateOpeningsInCorners &&
        !selectedGateInsulator.name &&
        !selectedGateHandle.name) ||
      (step === 6 && !selectedEnergizer.name)
    )
  }

  const handleResetStepper = () => {
    setShowSummary(false)
    resetStepper(0)
    setCompleted(new Set<number>())
    clearValues()
    window.scrollTo(0, 0)
  }

  const handleScrollIntoView = () => {
    if (stepRef.current) {
      stepRef.current.scrollIntoView()
    }
  }

  const handleGtmFenceClick = (
    eventCategory: string,
    eventLabel: string,
    eventValue: number
  ) => {
    const category = getLabelNameUtil(fenceCalculatorLabels, eventCategory)
    fenceCalculatorStepperClick(category, eventLabel, eventValue)
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <SelectAnimal
            onAnimalSelected={() => setAnimalSelected(true)}
            onResetStepper={handleResetStepper}
            confirmChangeOpen={confirmChangeOpen}
            onSetConfirmChangeOpen={(isConfirmChangeOpen) =>
              setConfirmChangeOpen(isConfirmChangeOpen)
            }
          />
        )
      case 1:
        return (
          <SelectCategory
            onCategorySelected={() => setCategorySelected(true)}
            onResetStepper={handleResetStepper}
            confirmChangeOpen={confirmChangeOpen}
            onSetConfirmChangeOpen={(isConfirmChangeOpen) =>
              setConfirmChangeOpen(isConfirmChangeOpen)
            }
          />
        )
      case 2:
        return <FenceMaterial content={content} />
      case 3:
        return <FencePosts content={content} />
      case 4:
        return <FenceCorners />
      case 5:
        return <FenceGateOpenings content={content} />
      case 6:
        return <SelectEnergizer content={content} />
      case 7:
        return <SelectAccessories content={content} />
      default:
        return "Completed"
    }
  }

  const stepValidation = useCallback(
    (step: number) => {
      switch (step) {
        case 0:
          {
            if (!selectedAnimal) {
              return setStepValid(false)
            }

            setStepValid(true)
            handleGtmFenceClick(
              fenceCalculatorLabels.animalHeading,
              selectedAnimal,
              step
            )
            handleComplete(step)
          }
          return
        case 1:
          {
            if (!selectedCategory) {
              return setStepValid(false)
            }
            setStepValid(true)
            handleGtmFenceClick(
              fenceCalculatorLabels.fenceTypeHeading,
              selectedCategory,
              step
            )
            handleComplete(step)
          }
          return
        case 2:
          {
            if (!lengthOfFence || !numberOfWireRows) {
              return setStepValid(false)
            }
            setStepValid(true)
            if (selectedWire.name) {
              handleGtmFenceClick(
                fenceCalculatorLabels.fencingMaterialHeading,
                selectedWire.name,
                step
              )
            }
            handleComplete(step)
          }
          return
        case 3:
          {
            if (!distanceBetweenPosts) {
              return setStepValid(false)
            }
            setStepValid(true)

            handleGtmFenceClick(
              fenceCalculatorLabels.fencePostsHeading,
              selectedPost.name
                ? selectedPost.name
                : `Wooden post, ${selectedInsulator.name ? selectedInsulator.name : ""}`,
              step
            )
            handleComplete(step)
          }
          return
        case 4:
          {
            if (!numberOfCornerPosts) {
              handleGtmFenceClick(
                fenceCalculatorLabels.cornersHeading,
                "Skipped to Next step",
                step
              )
              return
            }
            setStepValid(true)
            handleGtmFenceClick(
              fenceCalculatorLabels.cornersHeading,
              `Next step`,
              step
            )
            handleComplete(step)
          }
          return
        case 5:
          {
            if (
              !numberOfFreeStandingGateOpenings &&
              !numberOfGateOpeningsInCorners
            ) {
              handleGtmFenceClick(
                fenceCalculatorLabels.gatesHeading,
                "Skipped to Next step",
                step
              )
              return
            }

            handleGtmFenceClick(
              fenceCalculatorLabels.gatesHeading,
              `${selectedGateInsulator.name}, ${selectedGateHandle.name}`,
              step
            )
            handleComplete(step)
            setStepValid(true)
          }
          return
        case 6:
          {
            if (!selectedEnergizer.name) {
              return
            }
            handleGtmFenceClick(
              fenceCalculatorLabels.energizerHeading,
              `${selectedEnergizerPowerSource}, ${selectedEnergizer.name}`,
              step
            )
            handleComplete(step)
            setStepValid(true)
          }
          return
        case 7:
          {
            handleGtmFenceClick(
              fenceCalculatorLabels.accessoriesHeading,
              `${selectedAccessories.length.toString()} items`,
              step
            )
            setStepValid(true)
            handleComplete(step)
          }
          return
        default:
          return "Completed"
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedAnimal,
      selectedCategory,
      lengthOfFence,
      numberOfWireRows,
      selectedWire,
      distanceBetweenPosts,
      selectedPost,
      numberOfCornerPosts,
      numberOfFreeStandingGateOpenings,
      numberOfGateOpeningsInCorners,
      selectedEnergizer,
      selectedAccessories,
      selectedInsulator
    ]
  )

  useEffect(() => {
    if (content.labels) {
      setFenceCalculatorLabels(content.labels)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      setTotalItems()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedAnimal,
      selectedCategory,
      selectedEnergizer,
      selectedWire,
      selectedPost,
      selectedGateInsulator,
      selectedInsulator,
      selectedGateHandle,
      selectedAccessories,
      lengthOfFence,
      totalLengthOfFence,
      numberOfWirePacks,
      numberOfPosts,
      numberOfCornerPosts,
      numberOfFreeStandingGateOpenings,
      numberOfGateOpeningsInCorners,
      numberOfWireRows,
      selectedEnergizerPowerSource
    ]
  )

  const validateStepClicked = (idx: number) => {
    if (idx === step) {
      setStep(-1)
      return
    }
    if (
      (isStepCompleted(step) && idx <= step + 1) ||
      (idx > step && isStepCompleted(idx)) ||
      (!isStepCompleted(step) && idx < step) ||
      idx === completed.size ||
      idx === completed.size - 1 ||
      (step === -1 && isStepCompleted(idx))
    ) {
      setStep(idx)
      setShowSummary(false)
      handleScrollIntoView()
    }
  }

  const summaryDisabled = () => {
    if (!selectedAnimal || !selectedCategory || !lengthOfFence) {
      return true
    }
    return false
  }

  useEffect(
    () => {
      if (animalSelected) {
        setStep(1)
      }
      if (categorySelected) {
        setStep(2)
      }
      setAnimalSelected(false)
      setCategorySelected(false)
      stepValidation(step)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stepValidation, step, animalSelected, categorySelected]
  )

  return (
    <Box maxWidth={808} mx="auto">
      <Box m={8}>
        <Typography
          align="center"
          className={classes.heading}
          variant="headline2"
        >
          {fenceCalculatorLabels.title}
        </Typography>
      </Box>
      <div
        className={classNames(classes.root, {
          [classes.accessoryBorder]: step === 7
        })}
      >
        <Box mx={0.5} my={4} className={classes.stepContentText}>
          <Typography variant="preamble">
            {fenceCalculatorLabels.preamble}
          </Typography>
        </Box>
        <Stepper
          ref={stepRef}
          activeStep={step}
          orientation="vertical"
          nonLinear
          className={classes.stepper}
        >
          {steps.map((label, idx) => {
            const stepProps: { completed?: boolean } = {}
            const activeStep = step === idx
            const stepIsOptional = isStepOptional(step)
            const stepIsCompleted = isStepCompleted(idx)
            const skipStepEnabled = stepIsOptional && isSkipStepEnabled(step)
            return (
              <Step
                key={`${label}-${idx}`}
                disabled={confirmChangeOpen}
                {...stepProps}
              >
                <StepButton
                  onClick={() => {
                    validateStepClicked(idx)
                  }}
                  completed={stepIsCompleted}
                  className={classNames(classes.stepButton, {
                    [classes.activeStep]: activeStep
                  })}
                >
                  <StepLabel
                    className={classNames(classes.stepLabelContainer, {
                      [classes.activeStep]: activeStep
                    })}
                  >
                    <StepLabelContent
                      label={label}
                      stepCompleted={stepIsCompleted}
                      activeStep={activeStep}
                      stepIndex={idx}
                      step={step}
                    />
                    <Box display="flex" alignItems="center" mr={1}>
                      {activeStep ? (
                        <RemoveIcon color="secondary" fontSize="small" />
                      ) : (
                        completed.size >= idx && (
                          <AddIcon color="primary" fontSize="small" />
                        )
                      )}
                    </Box>
                  </StepLabel>
                </StepButton>
                <StepContent>
                  <Box>{getStepContent(idx)}</Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    bgcolor={themeColors.primaryLightCyan}
                  >
                    {step !== steps.length - 1 && step > 1 && (
                      <Button
                        classes={{
                          root: classes.button,
                          label: classes.buttonLabel
                        }}
                        variant="text"
                        endIcon={<KeyboardArrowDown />}
                        onClick={() => {
                          if (stepIsOptional) {
                            handleComplete(step)
                          }
                          setStep(step + 1)
                          handleScrollIntoView()
                        }}
                        disabled={!stepValid}
                      >
                        {skipStepEnabled
                          ? fenceCalculatorLabels.skipToNextStep
                          : fenceCalculatorLabels.nextStep}
                      </Button>
                    )}
                  </Box>
                </StepContent>
              </Step>
            )
          })}
        </Stepper>
        <Button
          variant="primary"
          fullWidth
          onClick={() => {
            setStep(-1) // Collapse steps when "Summary of fencing" is clicked
            setShowSummary((prev) => !prev)
            handleScrollIntoView()
          }}
          style={{ marginTop: "1rem" }}
          color="primary"
          endIcon={showSummary ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          disabled={summaryDisabled()}
        >
          {fenceCalculatorLabels.summaryHeading}
        </Button>
        {showSummary && (
          <Summary
            currencySymbol={content.currencySymbol}
            resetStepper={handleResetStepper}
          />
        )}
      </div>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    heading: {
      fontSize: 24,
      [breakpoints.up("sm")]: {
        fontSize: 40
      }
    },
    root: {
      "& .MuiCollapse-wrapper": {
        boxSizing: "border-box",
        mozbBoxSizing: "border-box",
        webkitBoxSizing: "border-box",
        border: `1px solid ${themeColors.mediumGray}`,
        borderTopStyle: "none"
      },
      "& .MuiPaper-root": {
        margin: 0,
        padding: 0
      },
      "& .MuiStepContent-root, .MuiStepConnector-lineVertical": {
        border: "none",
        margin: 0,
        padding: 0,
        minHeight: 0
      }
    },
    accessoryBorder: {
      "& .MuiCollapse-wrapper": {
        border: "none"
      }
    },
    stepContentText: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.4,
      color: themeColors.colorBaseGray700,
      textAlign: "center"
    },
    stepButton: {
      boxSizing: "border-box",
      border: `1px solid ${themeColors.mediumGray}`,
      borderLeftStyle: "none"
    },
    activeStep: {
      border: "none",
      background: themeColors.infoMain
    },
    stepLabelContainer: {
      boxSizing: "border-box"
    },
    button: {
      margin: spacing(4, 0),
      padding: 0,
      fontSize: "1.125rem",
      "& .MuiButton-endIcon": {
        margin: 0
      }
    },
    buttonLabel: {
      display: "flex",
      flexDirection: "column"
    },
    stepper: {
      "& .MuiStepButton-vertical": {
        margin: 0,
        padding: 0
      },
      "& .MuiStepLabel-root": {
        width: "100%"
      },
      "& .MuiStepLabel-iconContainer": {
        display: "none"
      },
      "& .MuiStepIcon-root": {
        display: "none"
      },
      "& .MuiStepLabel-label": {
        display: "flex",
        justifyContent: "space-between"
      },
      "& .MuiSvgIcon-colorPrimary": {
        color: themeColors.infoDark
      }
    }
  })
)

export default FenceCalculatorBlock
