import { useTranslation } from "react-i18next"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import ProductCard from "common/Product/ProductCard"
import Typography from "common/Typography"
import RelatedProductsSlider from "./RelatedProductsSlider"
import { WebProductContent } from "./types"

type RelatedProductsProps = {
  products: WebProductContent[]
}

const RelatedProducts = ({ products }: RelatedProductsProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const SLIDES_TO_SHOW = products.length

  return (
    <Box pb={{ xs: 3, sm: 6, md: 6 }}>
      <Typography variant="headline4" style={{ margin: "1rem 0" }}>
        {t("common.similar_products")}
      </Typography>
      <Box className={classes.sliderWrapper}>
        <RelatedProductsSlider slides={SLIDES_TO_SHOW}>
          {products.map((product, key) => {
            return (
              <div key={`${product.code}-${key}`} className={classes.content}>
                <ProductCard product={product} />
              </div>
            )
          })}
        </RelatedProductsSlider>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  sliderWrapper: {
    width: "100%",
    "& .slick-list": {
      borderRight: `1px solid ${themeColors.whiteGray}`,
      borderLeft: `1px solid ${themeColors.whiteGray}`
    }
  },
  content: {
    borderTop: `1px solid ${themeColors.whiteGray}`,
    borderRight: `1px solid ${themeColors.whiteGray}`,
    borderBottom: `1px solid ${themeColors.whiteGray}`
  }
}))

export default RelatedProducts
