enum DisplayOptions {
  two_thirds = 2 / 3,
  full = 1,
  half = 0.5,
  one_third = 1 / 3,
  auto = 1,
  full_alt = 1
}

export default DisplayOptions
