import request from "@starrepublic/epi/api/request"

const market = {
  changeMarket: (marketId: string, model: ChangeMarketsModel) =>
    request.post(`/markets/${marketId}`, model),
  setMarket: (marketId: string) => request.get(`/markets/true/false`)
}

export type ChangeMarketsModel = {
  language: string
  currency: string
  country: string
}

export default market
