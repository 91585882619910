import React from "react"
import { ContentInfo, PropertyInfo } from "@starrepublic/epi/cms"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { blockDisplay } from "utils/blockDisplay"

import Overlay from "common/Overlay"
import ResponsiveImage from "common/ResponsiveImage"
import Video from "common/Video"
import Wrapper from "common/Wrapper"
import { BaseLayoutProperties } from "types"

type BlockBackgroundProps = {
  _display?: "string"
} & PropertyInfo &
  ContentInfo

type Props = {
  blockInfo?: BlockBackgroundProps
  content: BaseLayoutProperties
  className?: string
  noSidePadding?: boolean
  isOnePager?: boolean
}

const BlockBackground: React.FC<Props> = ({
  blockInfo,
  content: { backgroundImage, backgroundVideo, theme, overlay },
  isOnePager = false,
  children,
  className,
  noSidePadding = false
}) => {
  const classes = useStyles()
  const backgroundColorStyle = { backgroundColor: theme || "transparent" }
  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))
  isOnePager && !isSmUp ? (backgroundImage = undefined) : null

  const _display = blockInfo && blockDisplay(blockInfo)

  const isFull = _display && _display.full
  return (
    <div className={className} style={backgroundColorStyle}>
      {backgroundImage && backgroundImage._url && (
        <ResponsiveImage
          image={backgroundImage}
          className={classNames(classes.imageBg, {
            [classes.displayFull]: isFull
          })}
          containerProps={{
            className: classes.HeroContainer
          }}
        />
      )}
      {backgroundVideo && (
        <Video
          className={classes.videoBg}
          url={backgroundVideo._url}
          background
        />
      )}
      {backgroundImage && backgroundImage._url && (
        <Overlay opacity={overlay || 0} fullWidth={isFull} />
      )}
      <Wrapper
        className={classNames({ [classes.wrapperPadding]: noSidePadding })}
      >
        {children}
      </Wrapper>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  HeroContainer: {
    position: "initial"
  },
  wrapperPadding: {
    padding: 0
  },
  imageBg: {
    objectFit: "cover",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    margin: "auto"
  },
  displayFull: {
    width: "100vw",
    marginLeft: "calc(50% - 50vw)"
  },
  rootTextureBg: {
    backgroundRepeat: "repeat"
  },
  videoBg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
  }
}))

export default BlockBackground
