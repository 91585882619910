import Slide from "@material-ui/core/Slide"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"

type HideOnScrollProps = {
  target?: Window | Node
  children: React.ReactElement
  enabled?: boolean
}

const HideOnScroll: React.FC<HideOnScrollProps> = ({
  target = window,
  children,
  enabled = true
}) => {
  const trigger = useScrollTrigger({ target })

  return (
    <>
      {enabled && (
        <Slide appear={false} direction="down" in={!trigger}>
          {children}
        </Slide>
      )}
      {!enabled && <>{children}</>}
    </>
  )
}

export default HideOnScroll
