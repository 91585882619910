import { useTranslation } from "react-i18next"

import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Spinner from "common/Spinner"
import Typography from "common/Typography"
import ContainerWrapper from "./Sections/common/ContainerWrapper"

const Loading: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ContainerWrapper>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12}>
          <Card className={classes.cardContent}>
            <Typography variant={"headline2"} className={classes.loadingText}>
              {t(`checkout.loading_checkout`)}
            </Typography>
            <Spinner />
          </Card>
        </Grid>
      </Grid>
    </ContainerWrapper>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  cardContent: {
    padding: spacing(4),
    textAlign: "center"
  },
  loadingText: {
    textAlign: "center",
    marginBottom: spacing(4)
  }
}))

export default Loading
