import { FenceProduct, FenceProductVariant } from "./types"

export const getSelectedProduct = (
  products: FenceProductVariant[],
  articleNumber: string
) => {
  return products.find(
    (product) => product.variant.articleNumber === articleNumber
  )
}

const filterProducts = (
  products: FenceProductVariant[],
  filters: string[],
  totalLengthOfFence?: number
) => {
  const filteredProducts = products.filter((product) => {
    return filters.every((filter) => {
      if (product.flags[filter.toLowerCase()] === null) {
        return product
      }
      return product.flags[filter.toLowerCase()]
    })
  })

  // Filter out products that don't meet max capacity requirements
  if (totalLengthOfFence) {
    return filteredProducts.filter(
      (product) =>
        product.variant.capacity === 0 ||
        product.variant.capacity >= totalLengthOfFence
    )
  }
  return filteredProducts
}

export const getAllProducts = (
  products: FenceProduct[],
  filters?: string[],
  totalLengthOfFence?: number
) => {
  const allProducts: FenceProductVariant[] = []
  products.forEach((product) => {
    product.variants.forEach((variant) => {
      const { variants, ...restOfProduct } = product
      allProducts.push({ ...restOfProduct, variant })
    })
  })

  if (!filters) return allProducts

  const filteredProducts = filterProducts(
    allProducts,
    filters,
    totalLengthOfFence
  )
  return filteredProducts
}

export const deselectProduct = (
  selectedProduct: FenceProductVariant,
  product: FenceProductVariant
) => {
  if (
    Object.keys(selectedProduct).length !== 0 &&
    product.variant.articleNumber === selectedProduct.variant.articleNumber
  ) {
    return true
  }
  return false
}
