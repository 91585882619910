import {
  BlockResolver,
  ContentProps,
  DynamicBlock,
  PageInfo
} from "@starrepublic/epi/cms/"
// import { ContentAreaProperty } from "@starrepublic/epi/cms/components/ContentArea"
import ContentReference, {
  ContentReferenceProperty
} from "@starrepublic/epi/cms/components/ContentReference"
import DisplayOptionsContentArea from "@starrepublic/epi/cms/components/DisplayOptionsContentArea"

import DisplayOptions from "utils/ui/DisplayOptions"

import { HeroBlockContent } from "blocks/HeroBlock/types"
import BreadcrumbsBar from "common/BreadcrumbsBar"
import {
  ArticleComponent,
  ChildContainer,
  ContainerWrapper,
  SectionComponent
} from "common/contentAreaContainers"

type StandardPageContent = {
  mainContent: DynamicBlock[]
  contentReference: ContentReferenceProperty
  urlProp: string
  title: string
  hero: HeroBlockContent
}

type Props = { pageInfo: PageInfo } & ContentProps<StandardPageContent>

const StandardPage: React.FC<Props> = ({ content, propsInfo, pageInfo }) => (
  <>
    <BreadcrumbsBar />
    {content.hero && (content.hero.bigTitle || content.hero.backgroundImage) ? (
      <BlockResolver content={content.hero} pageInfo={pageInfo} />
    ) : null}
    <DisplayOptionsContentArea
      content={content.mainContent}
      propInfo={propsInfo.mainContent}
      component={SectionComponent}
      pageInfo={pageInfo}
      containWhen={({ _display: display, _type: type }) =>
        (type &&
          type !== "SectionBlock" &&
          display &&
          (display === "auto" || DisplayOptions[display] !== 1)) ||
        false
      }
      childContainer={ChildContainer}
      container={ContainerWrapper}
    />
    {content.contentReference && (
      <ContentReference
        content={content.contentReference}
        propInfo={propsInfo.contentReference}
        pageInfo={pageInfo}
        component={ArticleComponent}
      />
    )}
  </>
)

export default StandardPage
