import { Children, isValidElement, useEffect } from "react"
import { FastField, getIn } from "formik"

import Box from "@material-ui/core/Box"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"

import Typography from "common/Typography"
import ErrorInfo from "icons/ErrorInfo"
import { themeColors } from "theme"
import {
  FormikFieldProps,
  FormikTransformer,
  FormikTransformerProps
} from "./types"

type Props = {
  numberToString?: boolean
  defaultValueOption?: string
} & TextFieldProps

const transformer: FormikTransformer<Props> = ({
  field,
  form,
  transformValue,
  onBlur,
  inputRef,
  numberToString,
  defaultValueOption,
  ...props
}) => {
  const { name, onChange, value, onBlur: fieldBlur } = field

  const { touched, errors, isSubmitting, setFieldValue } = form

  const fieldError = getIn(errors, name)
  const showError = getIn(touched, name) && !!fieldError

  return {
    ...props,
    ...field,
    inputRef,
    value: value || "",
    onChange: (e) => {
      if (transformValue) {
        setFieldValue(name, transformValue(field.value, e.target.value))
      } else if (numberToString) {
        setFieldValue(name, e.target.value.toString())
      } else if (onChange) {
        onChange(e)
      }
    },
    onBlur: (e) => {
      fieldBlur(e)
      if (onBlur) {
        onBlur(e)
      }
    },
    error: showError,
    helperText: showError ? (
      <Box display="flex" alignItems="center">
        <ErrorInfo
          style={{ height: "16px", width: "16px", marginRight: "6px" }}
        />
        <Typography variant="smallText" style={{ color: themeColors.black }}>
          {fieldError}
        </Typography>
      </Box>
    ) : (
      props.helperText
    ),
    disabled: isSubmitting || props.disabled,
    variant: "outlined"
  }
}

const TextFieldComponent: React.FC<FormikTransformerProps<Props>> = (props) => {
  const { field, form, children, defaultValueOption } = props
  const { name } = field
  const { setFieldValue } = form

  useEffect(() => {
    const initialValues = [] as string[]

    Children.forEach(children, (element) => {
      // This is used to set default values on a Select-Text-Field-Component with MULTIPLE default values set in Optimizley Forms.
      if (!isValidElement(element)) return
      const { defaultValue } = element.props
      if (defaultValue) {
        initialValues.push(defaultValue)
        setFieldValue(name, initialValues)
      } else {
        // This is used to set the first value as default value on a Select-Text-Field-Component .
        if (defaultValueOption) {
          setFieldValue(name, defaultValueOption)
        } else if (children && children[0].props) {
          setFieldValue(name, children[0].props.value)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <TextField fullWidth {...transformer(props)} />
}

const FormikTextField: React.FC<FormikFieldProps<Props>> = (props) => {
  return <FastField component={TextFieldComponent} {...props} />
}

export default FormikTextField
