import { useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Button from "@material-ui/core/Button"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import CloseIcon from "@material-ui/icons/Close"

import ReturnItems from "common/Returns/ReturnItems"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import CheckedIcon from "icons/LargeChecked"
import ReturnStore from "store/ReturnStore"

const ReturnModal: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { order, itemsSelected, returnOrder },
    actions: { resetOrder, sendReturnForm }
  } = useStore(ReturnStore)

  const [reason, setReason] = useState("")

  const handleReturnClose = () => {
    if (!returnOrder.loading) {
      resetOrder()
    }
  }

  const requestReturn = () => {
    sendReturnForm(reason)
  }

  if (!order) {
    return null
  }

  return (
    <ClickAwayListener onClickAway={handleReturnClose}>
      <Dialog
        maxWidth={"lg"}
        className={classes.root}
        aria-labelledby="return-modal"
        aria-describedby="return-order"
        open={order !== null}
        onClose={handleReturnClose}
      >
        <DialogContent
          className={classes.dialog}
          classes={{ root: classes.dialogContentRoot }}
        >
          {returnOrder.data ? (
            <DialogContent className={classes.content}>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <CheckedIcon className={classes.completeIcon} />
                <Typography
                  variant={"headline3"}
                  className={classes.completeHeader}
                >
                  {t(`account.return_success_header`)}
                </Typography>
                <Typography variant={"body1"}>
                  {t(`account.return_success_message`)}
                </Typography>
                <Button
                  className={classes.closeButton}
                  onClick={handleReturnClose}
                >
                  {t("common.close")}
                </Button>
              </Grid>
            </DialogContent>
          ) : (
            <>
              <DialogTitle className={classes.dialogTitle} disableTypography>
                <Typography className={classes.title} variant="headline4">
                  {t("account.select_items_to_return_header")}
                </Typography>
                <IconButton
                  id="close-return-modal"
                  onClick={handleReturnClose}
                  className={classes.headerCloseButton}
                >
                  <div>
                    <CloseIcon className={classes.closeIcon} />
                  </div>
                </IconButton>
              </DialogTitle>
              <DialogContent className={classes.content}>
                <Grid item xs={12}>
                  <ReturnItems />
                </Grid>
                <Grid
                  item
                  xs={12}
                  hidden={itemsSelected < 1}
                  className={classes.dialogContent}
                >
                  <Typography
                    variant="headline6"
                    className={classes.returnReasonHeader}
                  >
                    {t("account.return_reason_header")}
                  </Typography>
                  <TextField
                    disabled={itemsSelected < 1 || returnOrder.loading}
                    multiline
                    rows="4"
                    variant="outlined"
                    onChange={(e) => setReason(e.target.value)}
                    className={classes.returnReason}
                    placeholder={t(`account.return_reason_placeholder`)}
                  />
                </Grid>
                {returnOrder.error && (
                  <Grid item xs={12}>
                    <Typography
                      variant={"body1"}
                      className={classes.errorMessage}
                    >
                      {t(`return_error_message`)}
                    </Typography>
                  </Grid>
                )}
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  disabled={itemsSelected < 1 || returnOrder.loading}
                  className={classes.ctaButton}
                  onClick={() => requestReturn()}
                >
                  {returnOrder.loading && (
                    <ButtonLoaderIcon className={classes.loadingIcon} />
                  )}
                  {t("account.return_send_request_button")}
                </Button>
              </DialogActions>
            </>
          )}
        </DialogContent>
      </Dialog>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    root: {
      overflow: "scroll"
    },
    dialog: {
      outline: 0,
      backgroundColor: themeColors.white,
      height: "100%",
      padding: 0,
      [breakpoints.up("md")]: {
        height: "auto",
        maxHeight: "100%"
      }
    },
    dialogContentRoot: {
      minWidth: 450,
      "&:first-child": {
        paddingTop: 0
      },
      [breakpoints.down("xs")]: {
        minWidth: "100%"
      }
    },
    dialogTitle: {
      display: "flex",
      padding: `0 0 0 ${spacing(3)}px`,
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${themeColors.lightGray}`,
      [breakpoints.down("xs")]: {
        padding: `${spacing(1.5)}px 0 ${spacing(1.5)}px ${spacing(3)}px`
      }
    },
    title: {
      marginRight: spacing(2)
    },
    closeIcon: {
      "& g": {
        stroke: themeColors.darkGray
      }
    },
    dialogContent: {
      padding: 0
    },
    content: {
      padding: `0 ${spacing(3)}px`,
      margin: 0
    },
    dialogActions: {
      backgroundColor: themeColors.white,
      padding: `${spacing(3)}px ${spacing(3)}px`,
      [breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    returnReasonHeader: {
      margin: `${spacing(2)}px 0 ${spacing(1)}px`
    },
    returnReason: { width: "100%" },
    ctaButton: {
      background: themeColors.primaryCyan,
      borderRadius: 2,
      color: themeColors.white,
      padding: "14px 24px",
      width: "100%",
      "&[disabled]": {
        background: themeColors.lightGray,
        cursor: "default !important",
        color: themeColors.mediumGray
      }
    },
    loadingIcon: {
      marginRight: spacing(1)
    },
    errorMessage: {
      paddingTop: spacing(2),
      textAlign: "center",
      color: themeColors.primaryRed
    },
    completeIcon: {
      marginBottom: spacing(2),
      marginTop: 24
    },
    completeHeader: {
      marginBottom: spacing(1)
    },
    headerCloseButton: {
      padding: spacing(2.5),
      lineHeight: 0
    },
    closeButton: {
      padding: spacing(2.5),
      lineHeight: 0,
      width: "100%",
      margin: `${spacing(3)}px 0`
    }
  })
)

export default ReturnModal
