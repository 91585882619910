/* tslint:disable:no-console*/
export const throwDevError = (errorMessage: string) => {
  if (process.env.NODE_ENV !== "production") {
    throw new Error(errorMessage)
  }
}

export enum Severity {
  Info,
  Warn,
  Error,
  Default
}

export const logDevMessage = (message: string, severity: Severity) => {
  if (process.env.NODE_ENV !== "production") {
    switch (severity) {
      case Severity.Warn:
        console.warn(message)
        break
      case Severity.Error:
        console.error(message)
        break
      case Severity.Info:
        console.info(message)
        break
      default:
        console.log(message)
    }
  }
}
