import { Theme, useTheme } from "@material-ui/core/styles"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { isSearchBot } from "utils/device"

const useBreakpoint = (breakpoint: Breakpoint | number) => {
  const theme = useTheme<Theme>()

  const mediaQueryResult = useMediaQuery(theme.breakpoints.down(breakpoint))

  if (isSearchBot()) {
    switch (breakpoint) {
      case "xs":
        return false
      case "sm":
        return false
      case "md":
        return true
      case "lg":
        return false
      case "xl":
        return false
    }
  }

  return mediaQueryResult
}

export default useBreakpoint
