import { useTranslation } from "react-i18next"

import Button from "common/Button"

const CheckoutButton: React.FunctionComponent = () => {
  const { t } = useTranslation()

  return <Button variant="primary">{t("cart.checkout_button_title")}</Button>
}

export default CheckoutButton
