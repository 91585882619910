import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import useStore from "global-hook-store"

import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Clear from "@material-ui/icons/Clear"

import Typography from "common/Typography"
import CartStore from "store/CartStore"
import DrawerStateStore from "store/DrawerStateStore"
import SiteSettingStore from "store/SiteSettingStore"
import CheckoutButton from "./CheckoutButton"
import OrderDetails from "./OrderDetails"

const ShoppingCart: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { selectedCart, itemsCount },
    actions: { deleteByCartTypeName }
  } = useStore(CartStore)

  const {
    state: { links }
  } = useStore(SiteSettingStore)

  const {
    actions: { setCartDrawer }
  } = useStore(DrawerStateStore)

  const handleClearCart = () => {
    deleteByCartTypeName("basket")
  }

  return (
    <>
      <div className={classes.header}>
        <Typography
          variant="headline3"
          color="textPrimary"
          className={classes.headerTitle}
        >
          {t("cart.title")}
        </Typography>
        <IconButton onClick={() => setCartDrawer(false)}>
          <Clear className={classes.closeIcon} />
        </IconButton>
        {itemsCount > 0 && (
          <div className={classes.clearCartContainer}>
            <Typography
              className={classes.clearCartButton}
              onClick={handleClearCart}
              variant="link2"
            >
              {t("cart.clear_cart")}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.container}>
        <div className={classes.orderDetailsContainer}>
          <OrderDetails onItemClick={() => setCartDrawer(false)} />
        </div>
        {selectedCart &&
        selectedCart.lineItems &&
        selectedCart.lineItems.length ? (
          <Link
            id="checkout-link"
            to={(links.checkout && links.checkout.href) || "/"}
            onClick={() => setCartDrawer(false)}
            className={classes.checkoutButton}
          >
            <CheckoutButton />
          </Link>
        ) : null}
      </div>
    </>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors }, breakpoints }) => ({
    header: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      alignContent: "flex-start",
      height: spacing(10),
      borderBottom: `1px solid ${themeColors.lightGray}`,
      position: "fixed",
      width: 480,
      zIndex: 1,
      backgroundColor: themeColors.white,
      paddingRight: spacing(3),
      paddingLeft: spacing(3),
      [breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    headerTitle: {
      flexGrow: 2
    },
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column"
    },
    closeIcon: {
      color: "#000",
      width: spacing(3.5),
      height: spacing(3.5)
    },
    checkoutButton: {
      position: "fixed",
      width: 480,
      zIndex: 1,
      bottom: 0,
      backgroundColor: themeColors.white,
      paddingTop: spacing(3),
      paddingBottom: spacing(3),
      paddingRight: spacing(3),
      paddingLeft: spacing(3),
      display: "flex",
      "& button": {
        flexGrow: 1
      },
      [breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    orderDetailsContainer: {
      flex: 1,
      display: "flex",
      marginTop: spacing(10),
      marginBottom: spacing(24)
    },
    clearCartContainer: {
      paddingBottom: spacing(1),
      flex: "1 1 100%"
    },
    clearCartButton: {
      fontWeight: "normal",
      cursor: "pointer"
    }
  })
)

export default ShoppingCart
