import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const YoutubeIcon: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M23.8 7.2001C23.8 7.2001 23.6 5.5001 22.8 4.8001C21.9 3.8001 20.9 3.8001 20.4 3.8001C17 3.6001 12 3.6001 12 3.6001C12 3.6001 7 3.6001 3.6 3.8001C3.1 3.9001 2.1 3.9001 1.2 4.8001C0.5 5.5001 0.2 7.2001 0.2 7.2001C0.2 7.2001 0 9.1001 0 11.1001V12.9001C0 14.8001 0.2 16.8001 0.2 16.8001C0.2 16.8001 0.4 18.5001 1.2 19.2001C2.1 20.2001 3.3 20.1001 3.8 20.2001C5.7 20.4001 12 20.4001 12 20.4001C12 20.4001 17 20.4001 20.4 20.1001C20.9 20.0001 21.9 20.0001 22.8 19.1001C23.5 18.4001 23.8 16.7001 23.8 16.7001C23.8 16.7001 24 14.8001 24 12.8001V11.0001C24 9.1001 23.8 7.2001 23.8 7.2001ZM9.5 15.1001V8.4001L16 11.8001L9.5 15.1001Z"
      fill="#103D82"
    />
  </SvgIcon>
)

export default YoutubeIcon
