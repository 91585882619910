type Props = {
  url: string
  background?: boolean
  className?: string
}

const Video = ({ url, className, background }: Props) => {
  const videoProps: React.HTMLProps<HTMLVideoElement> = background
    ? {
        autoPlay: true,
        muted: true,
        loop: true,
        playsInline: true,
        preload: "auto"
      }
    : { controls: true }
  return (
    <video className={className} {...videoProps}>
      <source src={url} type="video/mp4" />
    </video>
  )
}

export default Video
