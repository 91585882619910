import { FC } from "react"

import GoogleMapsDealerConfig from "./GoogleMapsDealerConfig"

const GoogleMapsDealer: FC = () => {
  const API_ROOT_URL = process.env.REACT_APP_GOOGLE_MAPS_API_ROOT
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

  return (
    <GoogleMapsDealerConfig
      googleMapURL={`${API_ROOT_URL}/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100%`, width: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  )
}

export default GoogleMapsDealer
