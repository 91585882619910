import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import Typography from "common/Typography"
import BatteryIcon from "icons/FenceCalculator/BatteryIcon"
import MainsIcon from "icons/FenceCalculator/MainsIcon"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"
import { themeColors } from "theme"
import ProductsContainer from "./ProductsContainer"
import { FenceCalculatorBlockProps, FenceProductVariant } from "./types"
import { deselectProduct, getAllProducts, getSelectedProduct } from "./utils"

type Props = {
  content: FenceCalculatorBlockProps
}

const SelectEnergizer = ({ content }: Props) => {
  const { energizerProducts, currencySymbol } = content
  const classes = useStyles()

  const {
    selectedEnergizer,
    setSelectedEnergizer,
    powerSources,
    selectedEnergizerPowerSource,
    setSelectedEnergizerPowerSource,
    selectedAnimal,
    selectedCategory,
    fenceCalculatorLabels,
    totalLengthOfFence
  } = useFenceCalculatorStore()

  const allEnergizerProducts: FenceProductVariant[] = getAllProducts(
    energizerProducts,
    [selectedAnimal, selectedCategory, selectedEnergizerPowerSource].concat(),
    totalLengthOfFence
  )

  const selectProductEnergizer = (articleNumber: string) => {
    const product = getSelectedProduct(allEnergizerProducts, articleNumber)
    if (!product || deselectProduct(selectedEnergizer, product)) {
      return setSelectedEnergizer({} as FenceProductVariant)
    }
    setSelectedEnergizer(product)
  }

  const getEnergizerIcon = (energizerType: string) => {
    switch (energizerType) {
      case "Battery":
        return (
          <Box display="flex" flexDirection="column">
            <Typography isDarkTheme bold variant="label">
              {fenceCalculatorLabels.energizerBattery}
            </Typography>
            <BatteryIcon style={{ fontSize: 40 }} />
          </Box>
        )
      case "Mains":
        return (
          <Box display="flex" flexDirection="column">
            <Typography isDarkTheme bold variant="label">
              {fenceCalculatorLabels.energizerMains}
            </Typography>
            <MainsIcon style={{ fontSize: 40 }} />
          </Box>
        )
      default:
        return <BatteryIcon style={{ fontSize: 40 }} />
    }
  }

  return (
    <Box>
      <Box display="flex" flexDirection="column" className={classes.container}>
        <Typography className={classes.stepHeading}>
          {fenceCalculatorLabels.energizerDescription}
        </Typography>
        <Box p={2}>
          <Grid container spacing={2}>
            {powerSources.map(({ powerSource }, idx) => (
              <Grid item xs={6} key={`${powerSource}-${idx}`}>
                <Button
                  fullWidth
                  className={classNames(classes.button, {
                    [classes.selected]:
                      selectedEnergizerPowerSource === powerSource
                  })}
                  onClick={() => {
                    setSelectedEnergizerPowerSource(powerSource)
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {getEnergizerIcon(powerSource)}
                  </Box>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Box style={{ background: themeColors.primaryLightCyan }}>
        <Typography className={classes.chooseProductLabel}>
          {fenceCalculatorLabels.energizerChoose}
        </Typography>
        <ProductsContainer
          products={allEnergizerProducts}
          selectedProduct={selectedEnergizer}
          onSelectProduct={selectProductEnergizer}
          currencySymbol={currencySymbol}
        />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  container: {
    padding: spacing(5, 0),
    [breakpoints.up("sm")]: {
      maxWidth: 654,
      margin: "0 auto"
    }
  },
  stepHeading: {
    margin: spacing(0, 2, 1),
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    color: themeColors.black
  },
  button: {
    height: 124
  },
  selected: {
    background: themeColors.primaryBlue
  },
  chooseProductLabel: {
    color: themeColors.black,
    padding: spacing(3, 2),
    fontSize: "1.25rem",
    lineHeight: 1.2,
    fontWeight: 700
  }
}))

export default SelectEnergizer
