import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const ShowDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.214 11.863c2.294-1.696 5.506-3.53 8.786-3.53 3.277 0 6.534 1.83 8.872 3.524a32.278 32.278 0 0 1 3.706 3.149 18.462 18.462 0 0 1 .28.285l.015.017.005.005.002.002-.728.685c.729.684.729.685.728.685l-.002.002-.004.005-.017.017-.022.023-.037.039-.22.223a32.273 32.273 0 0 1-3.706 3.15c-2.338 1.692-5.595 3.523-8.872 3.523-3.28 0-6.492-1.834-8.786-3.53a31.032 31.032 0 0 1-3.623-3.153 17.21 17.21 0 0 1-.273-.286l-.015-.017-.005-.005-.002-.002.739-.674-.74-.674.003-.002.005-.005.015-.017.058-.062a27.29 27.29 0 0 1 1.013-1.008 31.029 31.029 0 0 1 2.825-2.37ZM4.034 16l-.738-.674-.614.674.614.674.739-.674Zm1.4 0 .329.315c.64.605 1.552 1.41 2.64 2.214 2.216 1.638 4.987 3.138 7.597 3.138 2.614 0 5.433-1.503 7.699-3.144A30.289 30.289 0 0 0 26.73 16a30.294 30.294 0 0 0-3.033-2.524c-2.265-1.64-5.084-3.143-7.698-3.143-2.61 0-5.381 1.5-7.597 3.138A29.03 29.03 0 0 0 5.435 16Zm22.718 0 .728.685.643-.685-.642-.684-.73.684ZM13.96 18.04a2.886 2.886 0 1 1 4.08-4.08 2.886 2.886 0 0 1-4.08 4.08Zm1.414-2.666a.886.886 0 1 0 1.252 1.252.886.886 0 0 0-1.252-1.252Z"
      clipRule="evenodd"
    />
  </SvgIcon>
)

export default ShowDeLaval
