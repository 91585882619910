import { FC } from "react"

import Box from "@material-ui/core/Box"
import Skeleton from "@material-ui/lab/Skeleton"

import themeColors from "theme/colors"

type Props = {
  className?: string
}

const SkeletonWalnut: FC<Props> = ({ className }) => {
  return (
    <Box
      className={className}
      borderTop={`1px solid ${themeColors.grayBorder}`}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mx={4}
        my={0.5}
      >
        <Skeleton variant="text" width={150} height={28} />
        <Skeleton variant="circle" width={35} height={35} />
      </Box>
      <Box
        height={1}
        width={1}
        display={"flex"}
        borderTop={`1px solid ${themeColors.grayBorder}`}
      >
        <Skeleton
          variant="rect"
          height={"100%"}
          width={"8%"}
          style={{ backgroundColor: themeColors.grayBorder }}
        />
        <Box height={1} width={1} display="flex" flexDirection="column">
          <Skeleton variant="rect" height="20%" width="100%" />
          <Skeleton variant="rect" height="100%" width="100%" />
        </Box>
      </Box>
    </Box>
  )
}

export default SkeletonWalnut
