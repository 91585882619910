import classNames from "classnames"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import MyLocationIcon from "@material-ui/icons/MyLocation"

import LinkText from "common/LinkText"
import Typography from "common/Typography"
import CloseDeLaval from "icons/CloseDeLaval"
import LocationIcon from "icons/LocationIcon"
import Mail from "icons/Mail"
import Phone from "icons/Phone"
import Website from "icons/Website"
import DealerSearchStore from "store/DealerSearchStore"

type DealerWindowProps = {
  onClose: () => void
}

const DealerWindow = ({ onClose }: DealerWindowProps) => {
  const classes = useStyles()

  const theme = useTheme()

  const isMdUp = useMediaQuery(theme.breakpoints.up("md"))
  const isSmLaptopScreen = useMediaQuery(theme.breakpoints.between(960, 1248))

  const {
    state: { selectedDealer }
  } = useStore(DealerSearchStore)

  return (
    <Box maxWidth={isSmLaptopScreen ? 400 : 415}>
      {selectedDealer && (
        <Box
          display="flex"
          justifyContent="space-between"
          {...(isMdUp && {
            minWidth: 400,
            padding: isSmLaptopScreen ? "8px 16px" : "16px",
            position: "relative",
            alignItems: "center"
          })}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.closeIcon}
          >
            <CloseDeLaval />
          </IconButton>
          <div>
            <Box pb={isSmLaptopScreen ? 0.5 : 1}>
              <Typography variant="headline6">{selectedDealer.name}</Typography>
              <Typography variant="link2">
                {selectedDealer.dealerType}
              </Typography>
            </Box>
            <Box display="flex">
              <LocationIcon
                className={classNames(classes.icon, classes.locationIcon)}
              />
              <Box display="flex" flexDirection="column">
                <Typography variant="label">
                  {selectedDealer.address}
                </Typography>
                <Typography variant="label">
                  {selectedDealer.zipcode} {selectedDealer.city}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column">
              {selectedDealer.phone && (
                <Box display="flex" alignItems="center">
                  <Phone className={classes.icon} />
                  <LinkText variant="label" phone link={selectedDealer.phone}>
                    {selectedDealer.phone}
                  </LinkText>
                </Box>
              )}
              {selectedDealer.email && (
                <Box display="flex" alignItems="center">
                  <Mail className={classes.icon} />
                  <LinkText variant="label" mailto link={selectedDealer.email}>
                    {selectedDealer.email}
                  </LinkText>
                </Box>
              )}
              {selectedDealer.distance && (
                <Box display="flex" alignItems="center">
                  <MyLocationIcon className={classes.icon} />
                  <Typography variant="label">
                    {selectedDealer.distance}
                  </Typography>
                </Box>
              )}
              {selectedDealer.website && (
                <Box display="flex" alignItems="center">
                  <Website className={classes.icon} />
                  <LinkText
                    variant="label"
                    link={selectedDealer.website}
                    external
                  >
                    {selectedDealer.website}
                  </LinkText>
                </Box>
              )}
            </Box>
          </div>
          {selectedDealer.image && (
            <Box>
              <img
                alt="selected dealer image"
                className={classes.image}
                src={selectedDealer.image._url}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

const useStyles = makeStyles(({ spacing, common }) => ({
  icon: {
    fontSize: spacing(2),
    marginRight: spacing(1),
    color: common && common.themeColors.black
  },
  locationIcon: {
    marginTop: spacing(0.5)
  },
  closeIcon: {
    position: "absolute",
    top: 0,
    right: 0
  },
  image: {
    width: spacing(9),
    height: spacing(9),
    borderRadius: "50%",
    marginRight: spacing(2)
  }
}))

export default DealerWindow
