import { createRef } from "react"
import useStore from "global-hook-store"
import { SnackbarProvider } from "notistack"

import snackbarStore from "store/SnackbarStore"
import ProductSnackbar from "./ProductSnackbar"
import SnackbarContent from "./SnackbarContent"

const Snackbar: React.FC<{ autoHideDuration?: number }> = ({
  autoHideDuration = 4500,
  children
}) => {
  const {
    state: { type }
  } = useStore(snackbarStore)

  const formOptions = (optionsString: string) => {
    if (!optionsString) return {}
    const options = JSON.parse(optionsString)
    return options
  }

  return (
    <SnackbarProvider
      ref={createRef()}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      autoHideDuration={autoHideDuration}
      content={(key, message) =>
        type === "product" ? (
          <ProductSnackbar
            key={key}
            id={key}
            options={formOptions(message as string)}
          />
        ) : (
          <SnackbarContent
            key={key}
            id={key}
            options={formOptions(message as string)}
            variant={type}
          />
        )
      }
    >
      {children}
    </SnackbarProvider>
  )
}
export default Snackbar
