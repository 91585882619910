import { FC, Suspense, useEffect, useMemo, useState } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import classNames from "classnames"
import useStore from "global-hook-store"
import SmoothScroll from "smooth-scroll/dist/smooth-scroll"

import { makeStyles } from "@material-ui/core/styles"

import { isEpiPreview } from "utils/epiPreviewUtil"

import EpiQuickNavigatorButton from "common/EpiQuickNavigatorButton"
import Footer from "common/Footer"
import Header from "common/Header"
import NewsletterContent from "common/Newsletter/NewsletterContent"
import OriginalParts from "common/OriginalParts"
import SiteLoader from "common/SiteLoader"
import PageContainer from "pages/PageContainer"
import { useHeaderStore } from "store/HeaderStore"
import PageStore from "store/PageStore"

const App: FC = () => {
  const classes = useStyles()
  const location = useLocation()
  const [previousPathname, setPreviousPathname] = useState<string>()
  const { headerHeight } = useHeaderStore()

  const {
    state: { page }
  } = useStore(PageStore)

  const [epiNavLink, setEpiNavLink] = useState<string>("")

  const memoizedPageData = useMemo(() => page.data, [page.data])

  useEffect(() => {
    if (memoizedPageData) {
      const getEpiNavLink = memoizedPageData._links.find(
        (link) => link.rel === "edit"
      )
      if (getEpiNavLink) {
        setEpiNavLink(getEpiNavLink.href)
      }
    }
  }, [memoizedPageData])

  useEffect(
    () => {
      if (location.pathname) {
        setPreviousPathname(location.pathname)
        if (previousPathname !== location.pathname) {
          if (isEpiPreview() && previousPathname) {
            window.location.reload()
            return
          }

          try {
            new SmoothScroll().animateScroll(0, { speed: 200 })
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  )

  const isCheckoutPage =
    memoizedPageData && memoizedPageData._type === "CheckoutPage"

  return (
    <Suspense fallback={<SiteLoader />}>
      <div className={classes.root}>
        {epiNavLink && <EpiQuickNavigatorButton link={epiNavLink} />}
        <Header />
        <main
          style={{ paddingTop: headerHeight }}
          className={classNames(classes.main, {
            [classes.gray]: isCheckoutPage
          })}
        >
          <Routes>
            <Route path="*" element={<PageContainer location={location} />} />
          </Routes>
        </main>
        <NewsletterContent />
        <Footer />
        <OriginalParts />
      </div>
    </Suspense>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  "@global": {
    a: {
      textDecoration: "none"
    },
    "*, *::after, *::before": {
      "-webkit-user-select": "text",
      "-webkit-user-drag": "none",
      "-webkit-app-region": "no-drag"
    }
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflow: "hidden"
  },
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "flex-start",
    position: "relative",
    background: themeColors.white,
    paddingBottom: 0
  },
  gray: {
    background: themeColors.offWhite
  }
}))

export default App
