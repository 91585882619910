import adyenPayment from "./adyenPayment"
import checkout from "./checkout"
import content from "./content"
import country from "./country"
import markets from "./markets"
import orders from "./orders"
import quickOrder from "./quickOrder"
import search from "./search"
import shoppingList from "./shoppingList"
import siteSettings from "./siteSettings"
import users from "./users"
export default {
  adyenPayment,
  checkout,
  content,
  country,
  markets,
  orders,
  quickOrder,
  siteSettings,
  search,
  shoppingList,
  users
}
