import { useTranslation } from "react-i18next"
import { Form as FormikForm } from "formik"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import CustomTextField from "common/Formik/CustomTextField"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import contactModalStore from "store/ContactModalStore"

const ContactModalForm: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { result }
  } = useStore(contactModalStore)

  return (
    <div className={classes.root}>
      <FormikForm noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              required
              name="name"
              label={t("account.full_name_label")}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              required
              name="email"
              label={t("account.email_label")}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              name="phone"
              label={t("account.company_phone_label")}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              name="message"
              label={t("account.message_label")}
              type="text"
              multiline={true}
              minRows={6}
            />
          </Grid>
          {result.error && (
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.errorMessage}>
                {t("common.contact_me_modal_error_message ")}
              </Typography>
            </Grid>
          )}
          <Grid container justifyContent="center">
            <Grid item xs={12} className={classes.SignInButton}>
              <Button variant="primary" type="submit" disabled={result.loading}>
                {result.loading && (
                  <ButtonLoaderIcon className={classes.loaderSpinner} />
                )}
                {t("common.contact_me_modal_button_text")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FormikForm>
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    root: {
      background: themeColors.white,
      width: "100%",
      [breakpoints.down("sm")]: {
        padding: spacing(3)
      }
    },
    SignInButton: {
      paddingTop: spacing(2),
      paddingBottom: spacing(3),
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
      display: "flex",
      justifyContent: "center",
      [breakpoints.down("sm")]: {
        paddingLeft: spacing(1),
        paddingRight: spacing(1)
      }
    },
    errorMessage: {
      paddingTop: spacing(1),
      textAlign: "center",
      color: themeColors.primaryRed,
      paddingBottom: spacing(2)
    },
    loaderSpinner: {
      marginRight: spacing(1)
    }
  })
)

export default ContactModalForm
