import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const Youku: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 37 9" {...props}>
    <g clipPath="url(#a)" fill="#103D82">
      <path d="M16.2.4c-.8 0-.8.7-.8.7v3.6s0 3.4 3.5 3.4c3.4 0 3.4-3.4 3.4-3.4V1.1s0-.7-.8-.7c-.9 0-.9.7-.9.7v3.6s0 1.7-1.7 1.7C17 6.4 17 4.7 17 4.7V1.1s0-.7-.9-.7Zm-4.8 0a3.6 3.6 0 0 0-2.4 1c-.8.7-1.1 1.7-1 2.7-.1.8 0 1.6.4 2.3a3.6 3.6 0 0 0 3 1.7h.3c1-.1 2.2-.7 2.7-1.7a4 4 0 0 0 .6-2.3A3.5 3.5 0 0 0 12.4.5l-1-.1Zm-10 0a1 1 0 0 0-.9.5c-.1.4 0 .8.3 1l2.5 2.5v2.8s0 .9 1 .9c.8 0 .8-.9.8-.9V4.3L7.7 2c.2-.3.2-.7.1-1-.1-.5-1-.7-1.3-.3L4.2 2.8 1.8.5a.8.8 0 0 0-.4-.1Zm10 1.7h.5c.5.2.9.5 1.1 1 .3.5.2 1.6.2 1.6 0 .7-.5 1.3-1.1 1.5l-.7.2c-.2 0-.4 0-.6-.2-.6-.2-1-.8-1-1.5 0 0-.2-1.1 0-1.6a1.8 1.8 0 0 1 1.6-1ZM28.5.4c-.2 0-.4 0-.5.2l-3.1 3-.3.4V1.2s0-.8-1-.8c-.8 0-.8.8-.8.8v6.1s0 .8.9.8.9-.8.9-.8V4.5c.1.3.3.4.5.6l2.3 2.3.7.6c.6.3 1.4-.2 1.3-.9 0-.3-.4-.6-.6-.8l-2-2v-.2L29 2c.2-.1.4-.4.4-.7 0-.5-.5-.8-1-.8Zm2 0c-.8 0-.8.8-.8.8v3.5s0 3.4 3.5 3.4c3.4 0 3.4-3.3 3.4-3.3V1.2s0-.8-.8-.8c-.9 0-.9.8-.9.8v3.6s0 1.6-1.7 1.6c-1.8 0-1.8-1.7-1.8-1.7V1.2s0-.8-.8-.8Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h37v8.4H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default Youku
