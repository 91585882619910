import { useTranslation } from "react-i18next"
import { Form as FormikForm } from "formik"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import CustomTextField from "common/Formik/CustomTextField"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import LoginFormSelector from "pages/Login/LoginFormSelector"
import authStore from "store/AuthStore"
import siteSettingStore from "store/SiteSettingStore"

const LoginForm: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { tokenResponse }
  } = useStore(authStore)

  const {
    state: { additionalLoginInformation }
  } = useStore(siteSettingStore)
  return (
    <div className={classes.root}>
      <FormikForm id="login-form" noValidate>
        <Grid className={classes.welcome}>
          <Typography variant="headline6">
            {t("checkout.welcome_message")}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              required
              name="userName"
              label={t("checkout.email_input_label")}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              required
              name="password"
              label={t("checkout.password_input_label")}
              type="password"
              autoComplete="curent-password"
            />
          </Grid>
          {tokenResponse.error && (
            <Grid item xs={12}>
              <Typography
                id="login-form-error-message"
                variant="body2"
                className={classes.errorMessage}
              >
                {tokenResponse.error["error"] === "invalid_username"
                  ? t("account.user_not_registered_error_message")
                  : t("account.login_error_message")}
              </Typography>
            </Grid>
          )}
          {additionalLoginInformation && (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                className={classes.extraLoginInformationText}
              >
                {additionalLoginInformation}
              </Typography>
            </Grid>
          )}
          <Grid container justifyContent="center">
            <Grid item xs={12} className={classes.signInButtonContainer}>
              <Button fullWidth type="submit">
                {tokenResponse.loading && (
                  <ButtonLoaderIcon className={classes.loaderSpinner} />
                )}
                {t("account.login_button")}
              </Button>
            </Grid>
          </Grid>
          <LoginFormSelector />
        </Grid>
      </FormikForm>
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    root: {
      padding: spacing(3),
      paddingTop: spacing(3),
      background: themeColors.white,
      width: "100%",
      [breakpoints.down("sm")]: {
        padding: spacing(3)
      }
    },
    welcome: {
      marginBottom: spacing(3)
    },
    signInButtonContainer: {
      padding: spacing(2, 1, 2)
    },
    errorMessage: {
      paddingTop: spacing(1),
      textAlign: "center",
      color: themeColors.primaryRed,
      paddingBottom: spacing(2)
    },
    loaderSpinner: {
      marginRight: spacing(1)
    },
    extraLoginInformationText: {
      textAlign: "center"
    }
  })
)

export default LoginForm
