import { Link } from "react-router-dom"
import useStore from "global-hook-store"

import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import pageStore from "store/PageStore"

const BreadCrumbsBlock: React.FC = () => {
  const classes = useStyles()
  const {
    state: { page }
  } = useStore(pageStore)

  const parents = (page.data && page.data._parents) || []

  return (
    <div className={classes.root}>
      <Hidden smUp>
        <div className={classes.mobileCover} />
      </Hidden>
      {parents.map((link, i) => {
        // Skip the first parent that links to start page
        if (i === 0) {
          return null
        }

        return (
          <span key={i}>
            <Link to={link.url}>
              <Typography
                className={classes.linkText}
                variant="body1"
                component={"span"}
              >
                {link.title}
              </Typography>
            </Link>
            <span className={classes.line}>/</span>
          </span>
        )
      })}

      <Typography
        className={classes.currentText}
        variant="body1"
        component={"span"}
      >
        {page.data && page.data.title}
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, common: { themeColors }, spacing }) => ({
    root: {
      background: themeColors.white,
      width: "100%",
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: spacing(8),
      [breakpoints.down("xs")]: {
        overflowX: "scroll",
        justifyContent: "start",
        "& span:first-child": {
          paddingLeft: spacing(4)
        }
      }
    },
    linkText: {
      color: themeColors.mediumGray,
      cursor: "pointer",
      padding: `0 ${spacing(1)}px`,
      fontSize: 12,
      whiteSpace: "nowrap",
      "&:hover": {
        color: themeColors.mediumGray
      },
      "&:active": {
        color: themeColors.mediumGray
      }
    },
    currentText: {
      fontSize: 12,
      color: themeColors.darkerGray,
      padding: `0 ${spacing(1)}px`,
      whiteSpace: "nowrap"
    },
    line: {
      fontSize: 12,
      marginTop: 4,
      color: themeColors.mediumGray,
      whiteSpace: "nowrap"
    },
    mobileCover: {
      zIndex: 1,
      position: "absolute",
      width: 130,
      height: 40,
      background:
        "linear-gradient(90deg, rgba(255,255,255,1) 9%, rgba(255,255,255,0) 43%, rgba(255,255,255,0) 100%)"
    }
  })
)

export default BreadCrumbsBlock
