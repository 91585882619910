import { useCallback, useEffect, useRef } from "react"

import Box, { BoxProps } from "@material-ui/core/Box"

type Props = {
  enterCallback: () => unknown
  leaveCallback: () => unknown
  enterWait?: number
  leaveWait?: number
} & BoxProps

const HoverIntent: React.FC<Props> = ({
  enterCallback,
  leaveCallback,
  enterWait,
  leaveWait,
  children,
  ...props
}) => {
  const onEnterWait = enterWait === undefined ? 500 : enterWait
  const onLeaveWait = leaveWait === undefined ? 500 : leaveWait
  const isEnterEnvoked = useRef<boolean>(false)
  const enterTimer = useRef<number | null>(null)
  const leaveTimer = useRef<number | null>(null)

  const cancelEnterTimer = useCallback(() => {
    if (enterTimer.current) {
      clearTimeout(enterTimer.current)
    }
  }, [enterTimer])

  const cancelLeaveTimer = useCallback(() => {
    if (leaveTimer.current) {
      clearTimeout(leaveTimer.current)
    }
  }, [leaveTimer])

  const handleMouseEnter = useCallback(
    () => {
      cancelLeaveTimer()
      enterTimer.current = window.setTimeout(() => {
        enterCallback()
        isEnterEnvoked.current = true
      }, onEnterWait)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cancelLeaveTimer, onEnterWait]
  )

  const handelMouseLeave = useCallback(
    () => {
      cancelEnterTimer()
      leaveTimer.current = window.setTimeout(() => {
        if (isEnterEnvoked.current) {
          leaveCallback()
          isEnterEnvoked.current = false
        }
      }, onLeaveWait)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cancelEnterTimer, onLeaveWait]
  )

  useEffect(() => {
    return () => {
      cancelEnterTimer()
      cancelLeaveTimer()
    }
  }, [cancelEnterTimer, cancelLeaveTimer])

  return (
    <Box
      {...props}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handelMouseLeave}
    >
      {children}
    </Box>
  )
}

export default HoverIntent
