import { useState } from "react"
import { Link } from "react-router-dom"

import Collapse from "@material-ui/core/Collapse"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem, {
  ListItemProps as MuiListItemProps
} from "@material-ui/core/ListItem"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"

import Typography from "common/Typography"

type MenuItem = {
  title: string
  url: string
  isSelected: boolean
  subItems?: MenuItem[]
}

type Props = MuiListItemProps & {
  title: string
  url: string
  isSelected: boolean
  subItems?: MenuItem[]
}

const MenuListItem: React.FC<Props> = ({
  title,
  url,
  isSelected,
  subItems
}) => {
  const classes = useStyles()
  const hasChildren = subItems && subItems.length > 0

  const initiallyExpanded = () => {
    if (!hasChildren) return false
    for (const child of subItems!) {
      if (child.isSelected) {
        return true
      }
    }
    return false
  }

  const [expanded, setExpanded] = useState(initiallyExpanded)

  const handleExpansion = () => {
    setExpanded(!expanded)
  }

  return hasChildren ? (
    <>
      <div className={classes.expansionList}>
        <div className={classes.expansionHeader}>
          <ListItem
            className={`${classes.listItem} ${isSelected ? "active" : null}`}
          >
            <Link to={url}>
              <Typography>{title}</Typography>
            </Link>
          </ListItem>
          <Divider className={classes.menuDivider} />
          {expanded ? (
            <KeyboardArrowUp
              onClick={handleExpansion}
              className={classes.arrow}
            />
          ) : (
            <KeyboardArrowDown
              onClick={handleExpansion}
              className={classes.arrow}
            />
          )}
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.nestedList}>
            <div className={classes.expansionChild}>
              {subItems &&
                subItems.map((child, i) => {
                  return (
                    <MenuListItem disableGutters {...child} button key={i}>
                      <Typography>{child.title}</Typography>
                    </MenuListItem>
                  )
                })}
            </div>
          </List>
        </Collapse>
      </div>
      <Divider className={classes.menuDivider} />
    </>
  ) : (
    <>
      <ListItem
        className={`${classes.listItem} ${isSelected ? "active" : null}`}
      >
        <Link to={url}>
          <Typography>{title}</Typography>
        </Link>
      </ListItem>
    </>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, breakpoints, spacing }) => ({
    menuDivider: {
      backgroundColor: themeColors.whiteGray,
      marginRight: spacing(3)
    },
    expansionList: {
      "& hr": {
        display: "none"
      }
    },
    expansionHeader: {
      display: "flex",
      alignItems: "center",
      "&.active": {
        borderLeft: `4px solid ${themeColors.primaryCyan}`,
        paddingLeft: spacing(3) - 4
      }
    },
    listItem: {
      margin: `${spacing(1)}px 0`,
      paddingTop: spacing(0.5),
      paddingBottom: spacing(0.5),
      paddingLeft: 0,
      "&.active": {
        borderLeft: `${spacing(0.5)}px solid ${themeColors.darkBlue} `,
        paddingLeft: spacing(1.5)
      },
      "&.active p": {
        color: themeColors.primaryBlue,
        fontWeight: 700
      },
      "& p": {
        color: themeColors.primaryCyan,
        fontSize: 16
      },
      "& > div": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        color: themeColors.primaryCyan,
        [breakpoints.down("sm")]: {
          padding: `0px ${spacing(3)}px`
        }
      }
    },
    arrow: {
      color: themeColors.primaryRed,
      height: spacing(4),
      width: spacing(6),
      padding: `0 ${spacing(1.5)}px`,
      marginRight: spacing(2)
    },
    nestedList: {
      paddingBottom: spacing(2),
      paddingLeft: spacing(2)
    },
    expansionChild: {
      "& li": {
        padding: `${spacing(1)}px ${spacing(4)}px 0 0`,
        paddingRight: spacing(4),
        "& p": {
          fontSize: "14px"
        }
      }
    },
    activeListItem: {
      fontWeight: "bold"
    }
  })
)

export default MenuListItem
