import { useTranslation } from "react-i18next"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import LinkText from "common/LinkText"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import ArrowCyan from "icons/ArrowCyan"

type Props = {
  title: string
  linkUrl?: string
  id?: string
}

const SubCategoryCardHeader: React.FC<Props> = ({ title, linkUrl, id }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <>
      <Wrapper className={classes.root} id={id}>
        <Grid container={true} spacing={0} className={classes.container}>
          <Grid item xs={12} className={classes.headerWrapper}>
            <Typography
              variant="headline4"
              className={classes.leftMenuHeaderText}
            >
              {title}
            </Typography>
            {linkUrl && (
              <LinkText link={linkUrl} className={classes.viewAllLink}>
                {t("product.view_all")}
                <ArrowCyan className={classes.arrow} />
              </LinkText>
            )}
          </Grid>
        </Grid>
      </Wrapper>
    </>
  )
}

const useStyles = makeStyles(({ common, breakpoints }) => ({
  root: {
    margin: "0 auto",
    backgroundColor: common.themeColors.white,
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingTop: common.gridSpacing,
    paddingBottom: common.gridSpacing
  },
  container: {
    width: "100%"
  },
  headerWrapper: {
    display: "flex",
    [breakpoints.down("xs")]: {
      flexDirection: "column",
      textAlign: "center"
    }
  },
  leftMenuHeaderText: {
    width: "100%",
    color: common.themeColors.primaryBlue
  },
  viewAllLink: {
    padding: 0,
    "& span": {
      fontSize: 16,
      whiteSpace: "nowrap",
      color: common.themeColors.primaryCyan
    }
  },
  arrow: {
    marginLeft: common.gridSpacing * 0.5,
    display: "inline-block",
    height: "16px",
    width: "14px",
    boxSizing: "border-box",
    verticalAlign: "middle"
  }
}))

export default SubCategoryCardHeader
