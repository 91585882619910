import { Children, isValidElement, useEffect } from "react"
import classNames from "classnames"
import { FastField, getIn } from "formik"

import Chip from "@material-ui/core/Chip"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import Select, { SelectProps } from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"

import {
  FormikFieldProps,
  FormikTransformer,
  FormikTransformerProps
} from "./types"

type Props = {
  id?: string
  helperText?: string
  label?: string
  select?: boolean
  placeholder?: string
  theme: string
} & SelectProps

const transformer: FormikTransformer<Props> = ({
  field,
  form,
  transformValue,
  ...props
}) => {
  const { name, onChange, value } = field
  const { touched, errors, isSubmitting, setFieldValue } = form
  const fieldError = getIn(errors, name)
  const showError = getIn(touched, name) && !!fieldError

  return {
    ...props,
    ...field,
    value: value || [],
    onChange: (e) => {
      if (transformValue) {
        setFieldValue(name, transformValue(field.value, e.target.value))
      } else if (onChange) {
        onChange(e)
      }
    },
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    disabled: isSubmitting || props.disabled,
    variant: "outlined"
  }
}

const SelectFieldComponent: React.FC<FormikTransformerProps<Props>> = (
  props
) => {
  const classes = useStyles()
  const { children, form, field, theme } = props
  const primaryBlueTheme = theme === "#103D82"
  const { setFieldValue } = form
  const { name } = field
  const {
    label,
    error,
    helperText,
    disabled,
    id,
    multiple,
    variant,
    placeholder,
    ...rest
  } = transformer(props)

  useEffect(() => {
    const initialValues = [] as string[]

    Children.forEach(children, (element) => {
      if (!isValidElement(element)) return
      const { defaultValue } = element.props
      if (defaultValue) {
        initialValues.push(defaultValue)
      }
      setFieldValue(name, initialValues)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={classNames(classes.input, {
        [classes.border]: primaryBlueTheme
      })}
    >
      <InputLabel disabled={disabled} className={classes.label}>
        {label}
      </InputLabel>
      <Select
        {...rest}
        placeholder={placeholder}
        disabled={disabled}
        multiple={true}
        label={label}
        MenuProps={{
          classes: { paper: classes.paper },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}
        renderValue={(selected) => {
          return (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )
        }}
      />
      <FormHelperText error={error} disabled={disabled}>
        {helperText}
      </FormHelperText>
    </FormControl>
  )
}

const FormikSelectField: React.FC<FormikFieldProps<Props>> = (props) => {
  return <FastField component={SelectFieldComponent} {...props} />
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  input: {
    "& .MuiOutlinedInput-root": {
      padding: spacing(1),
      minHeight: 55,
      backgroundColor: themeColors.white
    },
    "& .MuiFormLabel-root": {
      backgroundColor: themeColors.white,
      padding: spacing(0.5),
      color: themeColors.colorBaseGray600
    },
    "& .MuiFormLabel-root.Mui-focused": {
      backgroundColor: themeColors.white,
      padding: spacing(0.5),
      color: themeColors.colorBaseGray600
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      padding: spacing(0.5)
    },
    "& .MuiOutlinedInput-notchedOutline legend": {
      maxWidth: 0
    }
  },
  border: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: themeColors.colorBaseCyan700,
      borderWidth: "2px"
    }
  },
  paper: {
    boxShadow: themeColors.boxShadow3,
    marginTop: spacing(1)
  },
  label: {
    color: themeColors.gray
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  }
}))

export default FormikSelectField
