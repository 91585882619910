import { makeStyles } from "@material-ui/core/styles"

import Spinner from "common/Spinner"
import Logo from "icons/Logo"

const SiteLoader: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.loadingContainer}>
        <span className={classes.logo}>
          <Logo />
        </span>
        <br />
        <Spinner />
      </div>
    </div>
  )
}

const useStyles = makeStyles(({ common, spacing }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  },
  loadingContainer: {
    display: "inline-block",
    textAlign: "center",
    backgroundColor: common.themeColors.white
  },
  logo: {
    display: "inline-block",
    marginBottom: spacing(2),
    backgroundColor: common.themeColors.primaryBlue,
    padding: `${spacing(2)}px ${spacing(3)}px`
  }
}))

export default SiteLoader
