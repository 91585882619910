import { asyncState, createStore } from "global-hook-store"

import EnergyContactApi, {
  EnergyCalculatorContactPayload
} from "api/energyCalculatorContact"
import { EnergyCalculatorLabels } from "blocks/EnergyCalculatorBlock/types"

type Respons = {
  status: number
}

type XhtmlStrings = {
  checkboxLabel: string
  contactLabel1: string
}

const EnergyCalculatorStore = createStore(
  {
    sliderEnergyCost: 0,
    sliderMilkProduction: 0,
    totalEnergyNeededCalc: 0,
    totalMilkCoolingCost: 0,
    energySavingsPerYear: 0,
    energyCostSavingsCooling: 0,
    consumptionOfHotWater: 0,
    tapWaterTemperature: 0,
    energySavingsForHeating: 0,
    energyCostSavingsHeating: 0,
    calculationType: "cooling",
    maxAmountOfWaterCalc: 0,
    enableEmailInContactForm: false,
    labels: {} as EnergyCalculatorLabels,
    xhtmlStrings: {
      checkboxLabel: "",
      contactLabel1: ""
    } as XhtmlStrings,
    result: asyncState<Respons>()
  },
  {
    send: async (_, payload: EnergyCalculatorContactPayload, { asyncAction }) =>
      asyncAction("result", EnergyContactApi.send(payload)),

    enableEmail: (currentState, enableEmailInContactForm) => {
      return {
        ...currentState,
        enableEmailInContactForm
      }
    },
    setLabels: (currentState, labels: EnergyCalculatorLabels) => {
      return {
        ...currentState,
        labels
      }
    },
    setXhtmlStrings: (currentState, xhtmlStrings: XhtmlStrings) => {
      return {
        ...currentState,
        xhtmlStrings
      }
    },
    setSliderEnergyCost: (currentState, sliderEnergyCost: number) => {
      return {
        ...currentState,
        sliderEnergyCost
      }
    },
    setSliderMilkProduction: (currentState, sliderMilkProduction: number) => ({
      ...currentState,
      sliderMilkProduction
    }),
    setTotalEnergyNeededCooling: (
      currentState,
      totalEnergyNeededCalc: number
    ) => ({
      ...currentState,
      totalEnergyNeededCalc,
      energySavingsPerYear: totalEnergyNeededCalc / 2
    }),
    setTotalMilkCoolingCost: (currentState, totalMilkCoolingCost: number) => ({
      ...currentState,
      totalMilkCoolingCost,
      energyCostSavingsCooling: totalMilkCoolingCost / 2
    }),
    setSliderConsumptionOfHotWater: (
      currentState,
      consumptionOfHotWater: number
    ) => ({
      ...currentState,
      consumptionOfHotWater
    }),
    setSliderTapWaterTemperature: (
      currentState,
      tapWaterTemperature: number
    ) => ({
      ...currentState,
      tapWaterTemperature
    }),
    setMaxAmountOfWaterProduced: (
      currentState,
      maxAmountOfWaterCalc: number
    ) => ({
      ...currentState,
      maxAmountOfWaterCalc
    }),
    setEnergySavingsForHeating: (
      currentState,
      energySavingsForHeating: number
    ) => {
      const calcCost = (energySavingsForHeating *
        EnergyCalculatorStore.state.sliderEnergyCost) as number

      return {
        ...currentState,
        energySavingsForHeating,
        energyCostSavingsHeating: calcCost
      }
    },

    setCalculatorType: (currentState, calculationType: string) => ({
      ...currentState,
      calculationType
    }),
    resetEnergyCalc: (_, __: null, { reset }) => {
      return reset(
        "sliderEnergyCost",
        "sliderMilkProduction",
        "consumptionOfHotWater",
        "tapWaterTemperature",
        "totalEnergyNeededCalc"
      )
    }
  }
)

export default EnergyCalculatorStore
