import { Maybe } from "utils/types"

import { ApiEnumProperty, apiEnumToValue } from "types"

export enum VerticalAlignment {
  top = "top",
  center = "center",
  bottom = "bottom"
}

export enum HorizontalAlignment {
  left = "left",
  center = "center",
  right = "right"
}

export const alignmentToFlex = (alignment: Maybe<ApiEnumProperty>) => {
  if (!alignment) {
    return "center"
  }
  switch (apiEnumToValue(alignment)) {
    case VerticalAlignment.top:
    case HorizontalAlignment.left:
      return "flex-start"

    case VerticalAlignment.bottom:
    case HorizontalAlignment.right:
      return "flex-end"

    default:
      return "center"
  }
}

export const centralizedPadding = (maxWidth: number) =>
  `calc((100vw - ${maxWidth}px)/2)`

// right alignment on text is almost never used, even if the content is
// right-aligned the text will be left aligned
export const getTextAlignment = (alignment: Maybe<ApiEnumProperty>) =>
  !alignment || apiEnumToValue(alignment) === HorizontalAlignment.center
    ? "center"
    : "left"
