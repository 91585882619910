import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const FoodSafety: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 76 66">
    <g clipPath="url(#FoodSafety)" fill="currentColor">
      <path d="M42.82 70.99v4.96c-.31 0-.61.01-.9 0-2.85-.08-5.62-.54-8.25-1.71-2.83-1.26-5.04-3.26-6.97-5.66-1.63-2.04-2.45-4.39-2.87-6.91-.47-2.78-.44-5.6-.4-8.41.03-2.41.02-4.83.02-7.24 0-.24-.03-.47-.04-.75h.32c2.13-.02 4.25-.17 6.33-.67 1.86-.45 3.61-1.12 5.12-2.32 1.09-.87 1.91-1.98 2.74-3.08 1.33-1.78 2.22-3.75 2.53-5.97.1-.7.15-1.42.15-2.12.01-8.42.01-16.85.01-25.27v-.33H5.05c0 .1-.01.19-.01.29 0 7.68-.01 15.35.01 23.03.01 1.72.1 3.44.25 5.15.16 1.74.59 3.42 1.57 4.91.64.99 1.53 1.74 2.46 2.43 2.51 1.88 5.34 3.05 8.41 3.62 1.05.2 2.14.24 3.2.35.08.01.15.01.25.02.01.09.02.16.02.24-.01 2.89.01 5.78-.05 8.67-.07 3.15-.38 6.27-1.25 9.32-.66 2.27-1.65 4.38-3.17 6.21-1.4 1.68-3.16 2.9-5.06 3.91a18.991 18.991 0 01-7.09 2.13c-1.04.1-2.1.13-3.14.19-.08.01-.17 0-.29 0v-4.64c.08-.01.19-.02.3-.02 2.79-.04 5.46-.58 7.95-1.86 1.61-.83 3.03-1.91 4.19-3.29.35-.42.59-.93.84-1.42.92-1.81 1.45-3.74 1.75-5.74.44-2.99.42-6 .42-9.02 0-.02-.01-.03-.02-.07-.23-.01-.48-.02-.72-.04-1.88-.13-3.67-.61-5.4-1.34-2.86-1.2-5.32-2.96-7.23-5.43C1.49 40.84.48 38.27.16 35.42c-.09-.82-.13-1.65-.13-2.47C0 22.1 0 11.25 0 .39V0h45.7c.01.18.02.37.02.56.01 2.47.02 4.94.02 7.4 0 4.18-.01 8.36-.02 12.54-.01 4.27.02 8.54-.03 12.8-.08 5.8-2.56 10.29-7.44 13.42-2.48 1.59-5.2 2.56-8.11 3.01-.31.05-.62.08-.94.11-.3.02-.61.03-.94.05-.04.61-.11 1.22-.11 1.82-.02 1.81-.05 3.62.01 5.42.06 1.85.35 3.68.86 5.47.54 1.9 1.56 3.45 3.07 4.73 2.05 1.74 4.34 2.95 7.01 3.35 1.1.16 2.21.2 3.32.29.13.03.26.02.4.02zM60.64.02h4.76c0 .1.01.2.01.29 0 8.34.01 16.67 0 25.01-.01 2.7-.85 5.11-2.7 7.11-.9.98-1.98 1.7-3.31 1.97-.23.05-.47.06-.74.1v41.48h-4.87V30.74c.35-.02.7-.03 1.05-.06.85-.06 1.68-.21 2.48-.54 1.86-.77 2.86-2.22 3.18-4.16.1-.59.14-1.19.14-1.78.01-7.93.01-15.86.01-23.79V.02h-.01z" />
      <path d="M59.54.02h-4.77v20.49h4.77V.02zM53.2.01h-4.76v20.5h4.76V.01z" />
    </g>
    <defs>
      <clipPath id="FoodSafety">
        <path fill="#fff" d="M0 0h65.42v75.99H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default FoodSafety
