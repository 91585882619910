const themeColors = {
  boxShadow3: "0px 0px 1px rgb(0 0 0 / 12%), 0px 2px 4px rgb(0 0 0 / 10%)",
  textDefault: "rgba(0, 0, 0, 0.84)",
  darkBorder: "rgba(0, 0, 0, 0.1)",
  grayBorder: "rgba(0, 0, 0, 0.12)",
  lightBorder: "rgb(255,255,255, .5)",
  transparent: "rgba(255, 255, 255, 0.1)",
  textTransparent: "rgba(255, 255, 255, 0.6)",
  darkTransparent: "rgba(0, 0, 0, 0.5)",
  errorBorder: "#CB5E4F",
  baseBlue: "#0B2856",
  primaryBlue: "#103D82",
  primaryBaseBlue: "#223046",
  primaryCyan: "#00A6FF",
  primaryLightCyan: "#E4F3FB",

  primaryRed: "#FF3300",
  secondaryBlue: "#7EA2D1",
  secondaryAqua: "#44B6AC",
  secondaryGreen: "#77AD1C",
  secondaryYellow: "#FFD300",
  secondaryOrange: "#F8B323",
  secondaryBeige: "#FAF6ED",
  secondaryPurple: "#736FA9",
  secondaryCyan: "#E5F6FE",
  secondaryRed: "#CC2900",
  white: "#FFFFFF",
  offWhite: "#F7F7F7",
  whiteGray: "#EEEEEE",
  lightGray: "#E6E6E6",
  mediumGray: "#CCCCCC",
  mediumDarkGray: "#9E9E9E",
  neutralGray: "#545A63",
  gray: "#999999",
  darkBlue: "#0D3168",
  darkGray: "#575757",
  darkerGray: "#2B2B2B",
  black: "#000000",
  successLight: "#E4EFD2",
  successMain: "#4F7212",
  successDark: "#77AD1C",
  infoLight: "#D9F2FF",
  infoDark: "#0091FF",
  infoMain: "#2E66BE",
  warningLight: "#FFF2B3",
  warningMain: "#936506",
  warningDark: "#F7AA08",
  errorLight: "#FFD6CC",
  errorDark: "#CC2900",
  errorMain: "#991F00",
  // color to change
  dumpColor: "#39ff14",
  // COLOR BASE BLUE
  colorBaseBlue300: "#CDDEF9",
  colorBaseBlue500: "#3472D5",
  colorBaseBlue600: "#2E66BE",
  colorBaseBlue650: "#2659A8",
  colorBaseBlue700: "#103D82",
  colorBaseBlue800: "#0B2856",
  colorBaseBlue900: "#223046",
  // COLOR BASE CYAN
  colorBaseCyan400: "#E4F3FB",
  colorBaseCyan450: "#D9F2FF",
  colorBaseCyan500: "#B2E4FF",
  colorBaseCyan600: "#75CFFF",
  colorBaseCyan700: "#00A6FF",
  colorBaseCyan900: "#0073CC",
  // COLOR BASE GRAY
  colorBaseGray200: "#F0F0F0",
  colorBaseGray400: "#CCCCCC",
  colorBaseGray500: "#999999",
  colorBaseGray600: "#757575",
  colorBaseGray700: "#5C5C5C",
  colorBaseGray800: "#333333",
  // COLOR BASE RED
  colorBaseRed600: "#FF6952"
}

export default themeColors
