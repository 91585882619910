export const getLabelNameUtil = (objectList: any, searchValue: string) => {
  let keyName = ""

  for (const [key, value] of Object.entries(objectList)) {
    if (value === searchValue) {
      keyName = key
      break
    }
  }

  return keyName
}
