import { asyncState, createStore } from "global-hook-store"

import SearchApi, { SearchApiParams, SearchResult } from "api/search"

const SearchStore = createStore(
  {
    isSearchBarOpen: false,
    isQuickAddSearchBarOpen: false,
    result: asyncState<SearchResult[]>(),
    quickAddResult: asyncState<SearchResult[]>()
  },
  {
    forceLoading: (state, __: null) => {
      const newState = { ...state }
      newState.result.loading = true
      return newState
    },
    asyncSearch: async (_, payload: SearchApiParams, { asyncAction }) =>
      asyncAction("result", SearchApi.getSearchResults(payload)),
    asyncQuickAddSearch: async (_, payload: SearchApiParams, { asyncAction }) =>
      asyncAction("quickAddResult", SearchApi.getSearchResults(payload)),
    setSearchBarOpen: async (state, isSearchBarOpen: boolean) => ({
      ...state,
      isSearchBarOpen
    }),
    setQuickAddSearchBarOpen: async (
      state,
      isQuickAddSearchBarOpen: boolean
    ) => ({
      ...state,
      isQuickAddSearchBarOpen
    }),
    resetResult: (_, __: null, { reset }) => reset("result", "quickAddResult")
  }
)

export default SearchStore
