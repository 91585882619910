import { ElementType, FC, HTMLAttributes, SyntheticEvent } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import { CustomVariant } from "theme/typography"

type Props = {
  link: string
  id?: string
  offset?: boolean
  variant?: CustomVariant
  component?: ElementType<HTMLAttributes<HTMLElement>>
  className?: string
  linkText?: string
  dense?: boolean
  external?: boolean
  mailto?: boolean
  phone?: boolean
  onClick?: (e: SyntheticEvent<HTMLElement>) => void
  linkContentClassName?: string
}

const LinkText: FC<Props> = ({
  id,
  link,
  linkText,
  children,
  dense,
  offset,
  className,
  variant,
  onClick,
  external = false,
  mailto = false,
  phone = false,
  component,
  linkContentClassName
}) => {
  const classes = useStyles()

  if (external) {
    return (
      <a id={id} href={link} target="_blank" rel="noopener noreferrer">
        <Typography
          component={component || "span"}
          variant={variant}
          className={linkContentClassName}
        >
          {linkText || children}
        </Typography>
      </a>
    )
  }

  if (mailto) {
    return (
      <a id={id} href={`mailto:${link}`}>
        <Typography
          component="span"
          variant={variant}
          className={linkContentClassName}
        >
          {linkText || children}
        </Typography>
      </a>
    )
  }

  if (phone) {
    return (
      <a id={id} href={`tel:${link}`}>
        <Typography
          component="span"
          variant={variant}
          className={linkContentClassName}
        >
          {linkText || children}
        </Typography>
      </a>
    )
  }

  return (
    <Link
      id={id}
      to={link}
      onClick={onClick}
      className={classNames(classes.root, className, {
        [classes.dense]: dense,
        [classes.offset]: offset && !dense
      })}
    >
      <Typography
        component={component || "span"}
        variant={variant}
        className={linkContentClassName}
      >
        {linkText || children}
      </Typography>
    </Link>
  )
}

const useStyles = makeStyles(({ spacing, common }) => ({
  root: {
    cursor: "pointer",
    index: 0,
    paddingTop: 0,
    display: "inline-block",
    "&> *:hover": {
      color: common && common.themeColors.primaryBlue
    }
  },
  dense: {
    padding: `${spacing(1)}px 0`
  },
  offset: {
    marginLeft: -spacing(1)
  }
}))

export default LinkText
