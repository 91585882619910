import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import CustomTextField from "common/Formik/CustomTextField"
import CustomTextFieldPopper from "common/Formik/CustomTextFieldPopper"

type AccountInformationProps = {
  selectedCountry: string
}

const AccountInformation: React.FC<AccountInformationProps> = (props) => {
  const { selectedCountry } = props
  const classes = useStyles()

  const { t } = useTranslation()

  const passwordRef = useRef<HTMLInputElement>(null)

  const [passwordStrengthPopupOpen, setPasswordStrengthPopupOpen] =
    useState(false)

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomTextField
              required
              name={"firstName"}
              label={t("checkout.first_name_input_label")}
              type="text"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              required
              name={"lastName"}
              label={t("checkout.last_name_input_label")}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              required
              name={"email"}
              label={t("checkout.email_input_label")}
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              inputRef={passwordRef}
              required
              name={"password"}
              label={t("checkout.password_input_label")}
              type="password"
              onFocus={() => {
                setPasswordStrengthPopupOpen(true)
              }}
              onBlur={() => {
                setPasswordStrengthPopupOpen(false)
              }}
            />
            <CustomTextFieldPopper
              open={passwordStrengthPopupOpen}
              inputRef={passwordRef}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextField
              required
              name={"confirmPassword"}
              label={t("checkout.confirm_password_input_label")}
              type="password"
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    marginBottom: spacing(1)
  },
  passwordHintWrapper: {
    marginBottom: spacing(2),
    paddingTop: 0
  },
  passwordHint: {
    padding: spacing(1.5),
    paddingBottom: 0
  }
}))

export default AccountInformation
