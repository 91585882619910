import { lazy, Suspense, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import { Box, makeStyles } from "@material-ui/core/"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import { CustomerContactModel } from "api/customerContact"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import { Address } from "models/User"
import authStore from "store/AuthStore"

const PostalAddressFormat = lazy(() => import("common/PostalAddressFormat"))

export type CustomerPartialModel = {
  companyName: string
  billingAddress: Address
} & Partial<CustomerContactModel>

const AccountCompanyDetails: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { user, updateUserDetailsResponse },
    actions: { getUser }
  } = useStore(authStore)

  const initialValues = {
    companyName: "",
    vatNumber: "",
    billingAddress: {} as Address,
    phone: ""
  }

  const [companyValues, setCompanyValues] = useState(initialValues)

  const populateCompanyValues = () => {
    if (user.data) {
      companyValues.companyName = `${user.data.companyName}`
      companyValues.vatNumber = `${user.data.vatNumber}`
      companyValues.billingAddress = user.data.billingAddress
      companyValues.phone = `${user.data.billingAddress.phone}`
      setCompanyValues(companyValues)
    }
  }

  useEffect(
    () => {
      if (!user.data) {
        getUser()
      } else {
        populateCompanyValues()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getUser, user.data]
  )

  return (
    <Box p={3}>
      <div className={classes.headline}>
        <Typography variant="headline6" className={classes.sectionName}>
          {t("account.company_details_title")}
        </Typography>
      </div>
      {user.data && (
        <Table>
          <TableBody>
            <TableRow className={classes.tableRow}>
              <TableCell align="left" className={classes.tableCell}>
                <Typography variant="smallText" className={classes.label}>
                  {t("account.company_name_label")}
                </Typography>
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                <Typography variant="body1">{user.data.companyName}</Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="left" className={classes.tableCell}>
                <Typography variant="smallText" className={classes.label}>
                  {t("account.vat_number_label")}
                </Typography>
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                <Typography variant="body1">{user.data.vatNumber}</Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="left" className={classes.tableCell}>
                <Typography variant="smallText" className={classes.label}>
                  {t("account.billing_address_label")}
                </Typography>
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                {user.data && (
                  <Suspense
                    fallback={
                      <Box display="flex" alignItems="center">
                        <ButtonLoaderIcon />
                      </Box>
                    }
                  >
                    <PostalAddressFormat
                      companyName={user.data.companyName}
                      addressData={user.data.billingAddress}
                      variant="body1"
                    />
                  </Suspense>
                )}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <TableCell align="left" className={classes.tableCell}>
                <Typography variant="smallText" className={classes.label}>
                  {t("account.company_phone_label")}
                </Typography>
              </TableCell>
              <TableCell align="left" className={classes.tableCell}>
                <Typography variant="body1">
                  {user.data.billingAddress.phone}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors, gridSpacing } }) => ({
    sectionName: {
      color: themeColors.darkGray,
      marginRight: spacing(2)
    },
    tableRow: {
      verticalAlign: "text-top",
      [breakpoints.down("sm")]: {
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        paddingBottom: spacing(1)
      }
    },
    tableCell: {
      paddingLeft: 0,
      border: "none",
      paddingBottom: spacing(1.5),
      paddingTop: spacing(1.5),
      "&:last-child": {
        padding: 0
      },
      [breakpoints.down("sm")]: {
        display: "flex",
        width: "100%",
        paddingBottom: spacing(0.5),
        paddingTop: spacing(0.5)
      }
    },
    label: {
      color: themeColors.gray
    },
    headline: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      marginBottom: spacing(2)
    },
    button: {
      width: "100%",
      padding: 0,
      border: 0,
      height: 55,
      [breakpoints.down("sm")]: {
        padding: spacing(3)
      },
      "& span": {
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        padding: spacing(2)
      }
    },
    loaderSpinner: {
      marginRight: spacing(1)
    },
    outline: {
      border: `2px solid ${themeColors.primaryCyan}`
    }
  })
)

export default AccountCompanyDetails
