import { useCallback, useEffect, useState } from "react"
import { useResizeDetector } from "react-resize-detector"
import classNames from "classnames"
import { MinHeightProperty } from "csstype"

import { makeStyles } from "@material-ui/core/styles"

export type ScrollBoxProps = {
  minHeight?: MinHeightProperty<0>
  enable: boolean
}
export const ScrollBox: React.FC<ScrollBoxProps> = ({
  children,
  minHeight,
  enable = true
}) => {
  const classes = useStyles()
  const [scrollEnd, setScrollEnd] = useState(false)
  const { height, ref: scrollContainer } = useResizeDetector()

  const checkScroll = useCallback(
    (target: HTMLDivElement) => {
      const bottom =
        target.scrollHeight - target.scrollTop === target.clientHeight
      if (scrollEnd !== bottom) {
        setScrollEnd(bottom)
      }
    },
    [scrollEnd]
  )
  const handleScroll = useCallback(
    (e) => {
      checkScroll(e.target)
    },
    [checkScroll]
  )
  useEffect(
    () => {
      if (scrollContainer.current !== null) {
        checkScroll(scrollContainer.current)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrollContainer, height]
  )
  if (!enable) return <>{children}</>
  return (
    <div
      style={{ minHeight }}
      className={classNames(classes.scrollbox, {
        [classes.scrollend]: scrollEnd
      })}
    >
      <div
        className={classes.scrollboxInner}
        onScroll={handleScroll}
        ref={scrollContainer}
      >
        {children}
      </div>
    </div>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, breakpoints, spacing }) => ({
    scrollbox: {
      position: "relative",
      flexGrow: 1,
      "&::after": {
        content: '"\\0020"',
        position: "absolute",
        height: spacing(8),
        right: spacing(1),
        bottom: 0,
        left: 0,
        pointerEvents: "none",
        transition: "opacity 0.3s ease-in-out",
        background:
          "linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 100%);"
      }
    },
    scrollend: {
      "&::after": {
        opacity: 0
      }
    },
    scrollboxInner: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: spacing(1)
      },
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        opacity: 0
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,0)",
        borderRadius: "10px"
      },
      "&:hover::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,0.1)",
        borderRadius: "10px"
      },
      "& .MuiTableHead-root": {
        position: "sticky",
        top: 0,
        backgroundColor: themeColors.white,
        zIndex: 2
      }
    }
  })
)
