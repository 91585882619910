import Argentina from "icons/flags/Argentina"
import Australia from "icons/flags/Australia"
import Austria from "icons/flags/Austria"
import Belarus from "icons/flags/Belarus"
import Belgium from "icons/flags/Belgium"
import Bolivia from "icons/flags/Bolivia"
import Brazil from "icons/flags/Brazil"
import Canada from "icons/flags/Canada"
import Chile from "icons/flags/Chile"
import China from "icons/flags/China"
import Colombia from "icons/flags/Colombia"
import Czechia from "icons/flags/Czechia"
import Denmark from "icons/flags/Denmark"
import Ecuador from "icons/flags/Ecuador"
import Estonia from "icons/flags/Estonia"
import Finland from "icons/flags/Finland"
import France from "icons/flags/France"
import Germany from "icons/flags/Germany"
import Hungary from "icons/flags/Hungary"
import India from "icons/flags/India"
import Ireland from "icons/flags/Ireland"
import Italy from "icons/flags/Italy"
import Japan from "icons/flags/Japan"
import Kazakhstan from "icons/flags/Kazakhstan"
import Latvia from "icons/flags/Latvia"
import Lithuania from "icons/flags/Lithuania"
import Mexico from "icons/flags/Mexico"
import Netherlands from "icons/flags/Netherlands"
import NewZealand from "icons/flags/NewZealand"
import Norway from "icons/flags/Norway"
import Peru from "icons/flags/Peru"
import Poland from "icons/flags/Poland"
import Romania from "icons/flags/Romania"
import Russia from "icons/flags/Russia"
import SouthAfrica from "icons/flags/SouthAfrica"
import SouthKorea from "icons/flags/SouthKorea"
import Spain from "icons/flags/Spain"
import Sweden from "icons/flags/Sweden"
import Switzerland from "icons/flags/Switzerland"
import Turkey from "icons/flags/Turkey"
import Ukraine from "icons/flags/Ukraine"
import UnitedKingdom from "icons/flags/UnitedKingdom"
import UnitedStates from "icons/flags/UnitedStates"
import Uruguay from "icons/flags/Uruguay"

const flagMapper = {
  // America
  ar: Argentina,
  bo: Bolivia,
  br: Brazil,
  ca: Canada,
  cl: Chile,
  co: Colombia,
  ec: Ecuador,
  mx: Mexico,
  pe: Peru,
  us: UnitedStates,
  uy: Uruguay,
  // Asia
  au: Australia,
  cn: China,
  in: India,
  jp: Japan,
  nz: NewZealand,
  kr: SouthKorea,
  kz: Kazakhstan,
  // Europe
  be: Belgium,
  cz: Czechia,
  se: Sweden,
  fi: Finland,
  no: Norway,
  gb: UnitedKingdom,
  nl: Netherlands,
  it: Italy,
  de: Germany,
  dk: Denmark,
  fr: France,
  ie: Ireland,
  hu: Hungary,
  es: Spain,
  ee: Estonia,
  pl: Poland,
  lv: Latvia,
  lt: Lithuania,
  at: Austria,
  ch: Switzerland,
  tr: Turkey,
  by: Belarus,
  ru: Russia,
  ua: Ukraine,
  ro: Romania,
  // Africa
  za: SouthAfrica
}

type Props = {
  countryAlpha2: string | undefined
  className?: string | undefined
}

const MarketFlag = ({ countryAlpha2, className }: Props) => {
  const Component = countryAlpha2
    ? flagMapper[countryAlpha2.toLowerCase()]
    : null

  if (Component) {
    return <Component width={24} height={24} className={className} />
  }
  return null
}

export default MarketFlag
