import { useTranslation } from "react-i18next"

import CheckoutSectionHeader from "../common/CheckoutSectionHeader"
import ContainerWrapper from "../common/ContainerWrapper"

import OrderOverviewIcon from "icons/OrderOverviewIcon"
import OrderOverview from "./OrderOverview"

const OrderDetails: React.FC = () => {
  const { t } = useTranslation()
  return (
    <ContainerWrapper>
      <CheckoutSectionHeader
        title={t("checkout.your_order_title")}
        Icon={OrderOverviewIcon}
      />
      <OrderOverview />
    </ContainerWrapper>
  )
}

export default OrderDetails
