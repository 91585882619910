import { useState } from "react"
import classNames from "classnames"

import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"

import Typography from "common/Typography"
import { AdditionalInformationSheetType, PdfAssets } from "models/Products"
import AdditionalInformation from "pages/WebProduct/AdditionalInformation"
import AdditionalInformationSheet from "./AdditionalInformationSheet"

type Props = {
  pdfAssets?: PdfAssets[]
  isDangerousGoods?: boolean
  isAdditionalInformation?: boolean
  showProductDocuments?: boolean
  additionalInformationSheet?: AdditionalInformationSheetType
  accordionSummaryTitle: string
}
const AccordionProductDetails = ({
  pdfAssets,
  isDangerousGoods,
  isAdditionalInformation,
  showProductDocuments,
  accordionSummaryTitle,
  additionalInformationSheet
}: Props) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(!open)
  }

  return (
    <div
      className={classNames(classes.root, {
        [classes.adjustBottom]: isAdditionalInformation && showProductDocuments
      })}
    >
      <Accordion onClick={handleToggle}>
        <AccordionSummary
          expandIcon={
            open ? (
              <RemoveIcon className={classes.icon} />
            ) : (
              <AddIcon className={classes.icon} />
            )
          }
        >
          <Typography>{accordionSummaryTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isAdditionalInformation && additionalInformationSheet ? (
            <AdditionalInformationSheet
              additionalInformationSheet={additionalInformationSheet}
            />
          ) : (
            <AdditionalInformation
              showHazardousWarning={isDangerousGoods}
              pdfAssets={pdfAssets}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) =>
    createStyles({
      root: {
        width: "100%",
        margin: 0,
        border: `1px solid ${themeColors.grayBorder}`,
        borderStyle: "double none",
        [breakpoints.up("md")]: {
          margin: spacing(0, 0, 4)
        },
        "& .MuiAccordionDetails-root": {
          padding: spacing(0, 2, 2)
        },
        "& .MuiIconButton-edgeEnd": {
          marginRight: -16
        },
        "& .MuiAccordionSummary-content": {
          margin: spacing(2.25, 0)
        }
      },
      icon: {
        color: themeColors.colorBaseGray600
      },
      adjustBottom: {
        marginBottom: 0,
        borderBottom: 0
      }
    })
)

export default AccordionProductDetails
