import { makeStyles } from "@material-ui/core/styles"

import MarketSelector from "common/Header/MarketSelector"

const MenuFooter: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <MarketSelector isMobile />
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  root: {
    background: themeColors.primaryBlue,
    zIndex: 1001,
    display: "flex",
    flexDirection: "row",
    borderTop: `1px solid ${themeColors.transparent}`,
    padding: spacing(1),
    position: "sticky",
    bottom: 0
  }
}))

export default MenuFooter
