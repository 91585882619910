import { Location } from "history"

import config from "../config"
export const QUERY_DELIMITER: string = "?"

export const extractPath = (location: Location<any>) =>
  location.pathname.split(QUERY_DELIMITER)[0]

export const extractQuery = (location: Location<any>) =>
  location.pathname.split(QUERY_DELIMITER)[1]

export const isLocalHost = () =>
  window.location.hostname === "localhost" ||
  window.location.host === "127.0.0.1"

export const isDetached = () => config.runAsDetached === "true"
