import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Spinner from "common/Spinner"
import Typography from "common/Typography"
import originalPartsStore from "store/OriginalPartsStore"

const LoadingBar: React.FC = () => {
  const { t } = useTranslation()

  const {
    state: { result }
  } = useStore(originalPartsStore)

  if (!result.loading) {
    return null
  }

  return (
    <div>
      <Spinner />
      <Typography variant="body1" color="textPrimary">
        {t("search.searching_for_original_parts")}
      </Typography>
    </div>
  )
}

export default LoadingBar
