type NumberFormatProps = {
  style: string
  value: number
  selectedLanguage: string
  currency?: string
  unit?: string
  unitDisplayLong?: boolean
}

export const numberFormat = ({
  style,
  value,
  selectedLanguage,
  currency,
  unit,
  unitDisplayLong = false
}: NumberFormatProps) => {
  return new Intl.NumberFormat(selectedLanguage, {
    style: style ? style : "decimal",
    unit: unit ? unit : undefined,
    unitDisplay: unitDisplayLong ? "long" : undefined,
    currency: currency ? currency : "EUR"
  }).format(value)
}
