import { AsyncState, asyncState, createStore } from "global-hook-store"

import getSearchedDealers from "api/dealerList"
import { ImageAsset } from "models/Variant"
import * as gtm from "services/StarGA"
import MarketStore from "store/MarketStore"

const axios = require("axios")

export type GeoLocationType = {
  lat: number
  lng: number
}

export type SearchResponse = {
  center: {
    latitude: number
    longitude: number
  }
  dealers: Dealer[]
}

export type DealerContact = {
  description: string
  name: string
  email: string
  phone: string
}

export type Dealer = {
  id: string
  language: string
  name: string
  city: string
  country: string
  dealerType: string
  email: string
  heading: string
  headquarter: boolean
  latitude: number
  longitude: number
  phone: string
  zipcode: string
  address: string
  image: ImageAsset
  distance: string
  website: string
  additionalContacts: DealerContact[]
}

type DealerSearchStoreType = {
  selectedDealer: null | Dealer
  searchedDealer: AsyncState<SearchResponse>
  allDealers: Dealer[]
  mapOpen: boolean
  geoLocation: GeoLocationType
  defaultMapZoom: number
  mapZoom: number
  searchGeoLocation: GeoLocationType | null
  searchValue: string
  mapSearch: AsyncState<GeoLocationType>
  allHeadquarters: Dealer[]
  searchTerm: string
  deniedGeolocation: boolean
}

export type SearchModel = {
  searchTerm: string
  userLocation: null | {
    latitude: number
    longitude: number
  }
}

const DealerSearchStore = createStore(
  // Start settings for radius and center of search location
  {
    selectedDealer: null,
    searchedDealer: asyncState<SearchResponse>(),
    allDealers: [] as Dealer[],
    mapOpen: true,
    geoLocation: {
      lat: 62.5228738,
      lng: 15.6589419
    },
    searchGeoLocation: null, // This can be removed, use mapSearch.data instead
    defaultMapZoom: 4,
    mapZoom: 5,
    searchValue: "",
    searchTerm: "",
    mapSearch: asyncState<GeoLocationType>(),

    get allHeadquarters() {
      return this.allDealers.filter((h: Dealer) => h.headquarter)
    }
  } as DealerSearchStoreType,
  {
    setSearchedDealer: async (_, searchModel: SearchModel, { asyncAction }) => {
      let searchedDealerResponse
      try {
        searchedDealerResponse = await getSearchedDealers.send(searchModel)
        gtm.foundDealer({ searchModel, results: searchedDealerResponse.length })
      } catch (err) {
        gtm.foundDealer({ searchModel, results: 0 })
      }
      return asyncAction("searchedDealer", searchedDealerResponse)
    },
    setSelectedDealer: (state, selectedDealer: Dealer | null) => {
      return {
        ...state,
        mapZoom:
          state.mapZoom === state.defaultMapZoom && selectedDealer === null
            ? Math.ceil(state.defaultMapZoom * 1.25)
            : selectedDealer !== null
              ? state.defaultMapZoom * 2
              : Math.ceil(state.defaultMapZoom * 1.25),
        geoLocation:
          selectedDealer !== null
            ? { lat: selectedDealer.latitude, lng: selectedDealer.longitude }
            : state.geoLocation,
        selectedDealer
      }
    },
    setAllDealers: (state, allDealers: Dealer[]) => ({
      ...state,
      allDealers
    }),
    setMapOpen: (state, mapOpen: boolean) => ({
      ...state,
      mapOpen
    }),
    setDeniedGeolocation: (state, deniedGeolocation: boolean) => ({
      ...state,
      deniedGeolocation
    }),
    setGeoLocation: (state, geoLocation: GeoLocationType) => ({
      ...state,
      geoLocation
    }),
    setDefaultMapZoom: (state, defaultMapZoom: number) => ({
      ...state,
      defaultMapZoom
    }),
    setMapZoom: (state, mapZoom: number) => ({
      ...state,
      mapZoom
    }),
    setSearchTerm: (state, searchTerm: string) => ({
      ...state,
      searchTerm
    }),
    setSearchGeoLocation: (state, searchGeoLocation: GeoLocationType) => ({
      ...state,
      searchGeoLocation
    }),
    googleMapsSearch: (_, searchValue: string, { asyncAction }) => {
      const API_ROOT_URL = process.env.REACT_APP_GOOGLE_MAPS_API_ROOT
      const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
      const selectedMarket = MarketStore.state.defaultMarket
      return asyncAction(
        "mapSearch",
        axios
          .get(
            `${API_ROOT_URL}/maps/api/geocode/json?address=${searchValue}%20${
              selectedMarket && selectedMarket.marketName
            }&region=${
              selectedMarket && selectedMarket.marketId.value
            }&key=${API_KEY}`
          )
          .then((resp: any) => {
            if (
              resp.data &&
              resp.data.results &&
              resp.data.results.length &&
              resp.data.results[0] &&
              resp.data.results[0].geometry &&
              resp.data.results[0].geometry.location
            ) {
              return resp.data.results[0].geometry.location as GeoLocationType
            }
            return null
          })
      )
    },
    resetSearchedDealer: (_, __: null, { reset }) => {
      return reset("searchedDealer")
    }
  }
)

export default DealerSearchStore
