import { useTranslation } from "react-i18next"
import classNames from "classnames"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import InStock from "icons/InStock"

type Props = {
  activationStatus: string
}

const IconStatus: React.FC<Props> = ({ activationStatus }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  let statusText = ""
  let statusIconClass = ""

  switch (activationStatus) {
    case "ACTIVE":
      statusText = t("subscription.status_text_active")
      statusIconClass = classes.statusActive
      break
    case "ACTIVATION_REQUESTED":
      statusText = t("subscription.status_text_activation_request")
      statusIconClass = classes.statusActivationRequest
      break
    case "DEACTIVATION_REQUESTED":
      statusText = t("subscription.status_text_deactivation")
      statusIconClass = classes.statusDeactivation
      break
    case "PENDING":
      statusText = t("subscription.status_text_pending")
      statusIconClass = classes.statusPending
      break
    default:
      statusText = t("subscription.status_text_inactive")
      statusIconClass = classes.statusInactive
      break
  }

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <InStock
        className={classNames(classes.stockStatusIcon, statusIconClass)}
      />
      <Typography variant="body1">{statusText}</Typography>
    </Grid>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  statusActive: {
    color: themeColors.secondaryGreen
  },
  statusActivationRequest: {
    color: themeColors.secondaryOrange
  },
  statusPending: {
    color: themeColors.darkGray
  },
  statusInactive: {
    color: themeColors.darkGray
  },
  statusDeactivation: {
    color: themeColors.secondaryOrange
  },
  stockStatusIcon: {
    fontSize: 12,
    marginRight: spacing(1) * 0.5
  }
}))

export default IconStatus
