import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const MoneyDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="M24.883 7c-6.717 4.091-14.082-.308-19.499 4.601l4.117 8.388c5.743-4.602 13.541-.206 19.499-4.602L24.883 7Zm-.974 2.046 2.924 5.829c-4.66 2.353-11.049-.308-16.575 2.555l-2.816-5.624c4.226-2.76 10.292-.102 16.467-2.76Zm-6.175 5.319.108.205h-.217l-.108-.205h-.758l-.109-.41c.218 0 .435.102.65 0h.108c.217-.103.217-.41-.217-.513-.218-.103-.975 0-1.192-.613-.109-.308 0-.613.434-.818l-.108-.205h.217l.217.308h.649l.109.407h-.652c-.326.103-.217.41.108.41.544.103 1.084.103 1.301.613.109.41-.108.615-.54.82Zm1.624-1.433c-.544-1.126-1.95-1.84-3.142-1.636-1.192.205-1.841 1.228-1.3 2.353.542 1.228 2.057 1.944 3.358 1.636 1.084-.308 1.518-1.33 1.084-2.353Zm9.533 6.034C21.416 22.954 15.567 17.43 7.874 25L3 14.875l1.518-1.126 4.116 8.59c8.016-5.726 12.133-1.228 19.608-4.704.323.716.54 1.126.65 1.33Z"
    />
  </SvgIcon>
)

export default MoneyDeLaval
