export const videoId = (
  playerType: string,
  id: string,
  controlsOn: 1 | 0,
  autoplay: boolean,
  mute: 1 | 0,
  loop: 1 | 0
) => {
  const autoplayIfImage = autoplay ? "autoplay=1" : ""
  const idAndAutoPlay = `${id}?${autoplayIfImage}`

  switch (playerType) {
    case "Screen9":
    case "Screen9Id":
      return `https://api.screen9.com/embed/${idAndAutoPlay}&controls=${controlsOn}&loop=true`
    case "Vimeo":
      return `//player.vimeo.com/video/${idAndAutoPlay}&controls=${controlsOn}`
    case "YouTube":
      return `https://www.youtube.com/embed/${idAndAutoPlay}&mute=${mute}&controls=${controlsOn}&modestbranding=0&loop=${loop}&playlist=${id}`
  }
}

export const validVideoId = (id: string, size: string) => {
  return new Promise((resolve, reject) => {
    const imageSrouce = `http://img.youtube.com/vi/${id}/${size}.jpg`
    const img = new Image()
    img.src = imageSrouce
    img.onload = () => resolve(img)
  })
}
