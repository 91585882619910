type Props = {
  className?: string
}

const ArrowRight: React.FC<Props> = (props) => (
  <svg {...props} width="24" height="24" fill="none">
    <path
      d="M2 12h20M15 5l7 7-7 7"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowRight
