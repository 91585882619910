import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const WoodenPost: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 43 54" fill="#ffffff">
    <path
      fill="none"
      stroke="#233146"
      d="M42.5 4c0 .4-.3.8-1.4 1.3-1 .5-2.5 1-4.5 1.2a98 98 0 0 1-15.1 1 98 98 0 0 1-15.1-1c-2-.3-3.5-.7-4.5-1.2S.5 4.3.5 4c0-.4.3-.8 1.4-1.3 1-.5 2.5-1 4.5-1.2a98 98 0 0 1 15.1-1c6 0 11.3.3 15.1 1 2 .3 3.5.7 4.5 1.2s1.4 1 1.4 1.3Z"
    />
    <path
      fill="none"
      stroke="#233146"
      strokeLinecap="round"
      strokeWidth=".5"
      d="M30 2.5c3.5 0 7.5.5 7.5 1.5s-8.5 2-16 2c-4 0-5.4-.3-7.5-.5"
    />
    <path
      stroke="#233146"
      d="M.5 4.2v49M42.5 4.2v39M0 53.2h6.4c4.1 0 6 .2 9.6-.6 5-1.1 10.9-6.5 15-8.1 2.9-1.2 8.3-1.3 12-1.3"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeWidth=".5"
      d="M9.3 11v37M22.3 22v12M31.3 11v30"
    />
  </SvgIcon>
)

export default WoodenPost
