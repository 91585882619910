import { useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Divider from "@material-ui/core/Divider"
import Popover from "@material-ui/core/Popover"
import { makeStyles } from "@material-ui/core/styles"

import Spinner from "common/Spinner"
import Typography from "common/Typography"
import CartStore from "store/CartStore"
import CardItemList from "./CardItemList"

type Props = {
  stockStatus?: boolean
  onItemClick?: () => void
}

const OrderDetails: React.FC<Props> = ({
  stockStatus = false,
  onItemClick
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const popoverOpen = Boolean(anchorEl)

  const {
    state: { selectedCart, isFetching }
  } = useStore(CartStore)

  return (
    <div className={classes.root}>
      {!selectedCart || selectedCart.lineItems.length === 0 ? (
        <>
          <Typography
            variant="body1"
            align="center"
            className={classes.cartMessage}
          >
            {t("cart.empty_cart")}
          </Typography>
        </>
      ) : (
        <>
          <CardItemList
            lineItems={selectedCart!.lineItems}
            currency={selectedCart!.currency}
            isFetching={isFetching}
            stockStatus={stockStatus}
            onItemClick={onItemClick}
          />
          {selectedCart.lineItems && selectedCart.lineItems.length ? (
            <div className={classes.totalDetailsContainer}>
              <div className={classes.totalDetails}>
                {selectedCart!.shipping === 0 && (
                  <Typography
                    variant="priceTiny"
                    color="textPrimary"
                    className={classes.totalDetail}
                  >
                    <div>{t("cart.shipping_total")}</div>
                    {isFetching ? (
                      <Spinner width={24} height={24} />
                    ) : (
                      <div>{selectedCart!.shippingTotalString}</div>
                    )}
                  </Typography>
                )}
                {selectedCart &&
                  selectedCart.properties &&
                  selectedCart.properties.numberOfProducts > 0 && (
                    <Typography variant="body1">
                      {`${selectedCart.properties.numberOfProducts} ${t(
                        "common.items_total"
                      )}`}
                    </Typography>
                  )}
                {selectedCart &&
                  selectedCart.properties &&
                  selectedCart.properties.numberOfDigitalServices > 0 && (
                    <div className={classes.subscriptionsWrapper}>
                      <Typography variant="body1">{`${
                        selectedCart.properties.numberOfDigitalServices
                      } ${t("common.subscriptions_total")}`}</Typography>
                      <Typography
                        variant="body1"
                        className={classes.actionRequiredText}
                        onClick={handlePopoverOpen}
                      >
                        {t("common.action_required")}
                      </Typography>
                      <Popover
                        open={popoverOpen}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center"
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center"
                        }}
                      >
                        <Typography
                          variant="body1"
                          className={classes.popoverText}
                        >
                          {t("common.subscriptions_action_required_helper")}
                        </Typography>
                      </Popover>
                    </div>
                  )}
                <Divider className={classes.divider} />
                <Typography
                  variant="priceMedium"
                  color="textPrimary"
                  className={classes.total}
                >
                  <div>{t("cart.total")}</div>
                  {isFetching ? (
                    <Spinner width={24} height={24} />
                  ) : (
                    <div>{selectedCart!.totalString}</div>
                  )}
                </Typography>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles(({ spacing, common, breakpoints }) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  totalDetail: {
    display: "flex",
    justifyContent: "space-between",
    margin: `${spacing(1)}px 0`,
    "&:first-child": {
      marginTop: 0
    }
  },
  cartMessage: {
    marginTop: spacing(2) + 4,
    marginBottom: spacing(2) + 4
  },
  totalDetailsContainer: {
    position: "fixed",
    width: 480,
    bottom: 92, // TODO: Magic number
    zIndex: 1,
    backgroundColor: common.themeColors.white,
    padding: spacing(3),
    paddingBottom: 0,
    boxShadow: "0px 0px 0px 0px #fff, 0px -5px 10px -5px rgba(0,0,0,0.12)",
    [breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  totalDetails: {
    backgroundColor: common.themeColors.offWhite,
    padding: `${spacing(2) + 4}px ${spacing(3)}px`
  },
  total: {
    display: "flex",
    justifyContent: "space-between"
  },
  divider: {
    marginTop: spacing(1),
    marginBottom: spacing(1)
  },
  subscriptionsWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  actionRequiredText: {
    color: common.themeColors.primaryCyan,
    fontWeight: "bold",
    cursor: "pointer",
    marginLeft: spacing(1)
  },
  popoverText: {
    padding: spacing(2),
    maxWidth: 470
  }
}))

export default OrderDetails
