import { useTranslation } from "react-i18next"
import { Formik, FormikActions } from "formik"
import useStore from "global-hook-store"
import qs from "qs"

import { ChangePasswordModel } from "models/User"
import authStore from "store/AuthStore"
import validationSchema from "./Form/validationSchema"
import ChangePasswordForm from "./Form"

const ResetPassword: React.FunctionComponent = () => {
  const { t } = useTranslation()

  const {
    actions: { changePassword }
  } = useStore(authStore)

  const handleSubmit = async (
    values: ChangePasswordModel,
    { setSubmitting, setStatus }: FormikActions<ChangePasswordModel>
  ) => {
    setSubmitting(true)

    await changePassword(values)

    setSubmitting(false)
  }

  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  })

  return (
    <Formik
      initialValues={{
        email: query.username,
        password: "",
        token: query.resetToken
      }}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur
      component={ChangePasswordForm}
      validationSchema={validationSchema(t)}
    />
  )
}

export default ResetPassword
