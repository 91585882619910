import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"

import OrderHeading from "./OrderHeading"
import OrderTable from "./OrderTable"

type AccountPageProps = {
  title?: string
}

const AccountOrders: React.FC<AccountPageProps> = ({ title = "" }) => {
  const classes = useStyles()

  return (
    <Box mb={6}>
      <OrderHeading />
      <Grid container direction="column">
        <Paper elevation={3}>
          <OrderTable />
        </Paper>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  badgeContainer: {
    marginLeft: spacing(2)
  },
  loader: {
    marginLeft: spacing(1)
  },
  badgeContent: {
    color: themeColors.darkGray,
    fontWeight: "bold"
  },
  badge: {
    backgroundColor: themeColors.lightGray,
    height: spacing(3),
    width: spacing(3)
  }
}))

export default AccountOrders
