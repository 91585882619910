import useStore from "global-hook-store"

import AddToCartContainer from "common/AddToCartContainer"
import AddToShoppingList from "common/AddToShoppingList"
import { Price } from "models/Products"
import SiteSettingsStore from "store/SiteSettingStore"

type Props = {
  disabled?: boolean
  large?: boolean
  mobile?: boolean
  link?: string
  image: string
  variationValue: string
  digitalService: boolean
  code: string
  amount: number
  name: string
  category: string
  discountedPrice?: Price
  displayPrice: Price
}

const AddToCartOrShoppingListContainer: React.FC<Props> = ({
  disabled,
  image,
  link,
  variationValue,
  digitalService,
  code,
  large,
  mobile,
  amount,
  name,
  category,
  discountedPrice,
  displayPrice
}) => {
  const {
    state: {
      enableEcommerce,
      enableDigitalServicesOnly,
      enableNoWebShopWithContactDealer
    }
  } = useStore(SiteSettingsStore)

  return enableNoWebShopWithContactDealer ? null : (
    <>
      {(enableDigitalServicesOnly && digitalService) || enableEcommerce ? (
        <AddToCartContainer
          mobile={mobile}
          disabled={disabled}
          code={code}
          large={large}
          amount={amount}
          name={name}
          category={category}
          image={image}
          variationValue={variationValue}
          discountedPrice={discountedPrice}
          displayPrice={displayPrice}
        />
      ) : (
        <AddToShoppingList
          mobile={mobile}
          code={code}
          large={large}
          name={name}
          image={image}
          variationValue={variationValue}
          link={link}
        />
      )}
    </>
  )
}

export default AddToCartOrShoppingListContainer
