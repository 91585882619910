import classNames from "classnames"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import ContentCarousel from "common/ContentCarousel"
import ResponsiveImage from "common/ResponsiveImage"
import SiteSettingStore from "store/SiteSettingStore"
import { ImageBlock } from "types"

type Props = {
  images: ImageBlock[]
  display?: boolean
  isOneThird?: boolean
  isBottom?: boolean
  autoScroll?: boolean
  autoScrollSpeed?: number
  showOneImage: boolean
  isCarousel?: boolean
}

const ImageCollage: React.FC<Props> = ({
  images,
  display,
  isOneThird,
  isBottom,
  autoScrollSpeed,
  autoScroll,
  showOneImage,
  isCarousel
}) => {
  const isSmDown = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"))
  const isXsDown = useMediaQuery(({ breakpoints }) => breakpoints.down("xs"))
  const classes = useStyles()
  const oneImage = images.length < 2 || showOneImage
  const multipleImages = showOneImage ? false : images.length > 1

  const {
    state: { isOnePager }
  } = useStore(SiteSettingStore)

  const ResponsiveImageCard = (image: ImageBlock, key: number) => (
    <ResponsiveImage
      key={image._id + key}
      className={classes.mediaImage}
      containerProps={{
        className: classNames(classes.mediaContainer, {
          [classes.displaySmall]:
            (!isOnePager && oneImage) ||
            images.length === 1 ||
            isCarousel ||
            isXsDown,
          [classes.display]:
            isOneThird || display || isSmDown || (isSmDown && multipleImages),
          [classes.bottomWide]: isBottom,
          [classes.displaySmallMobile]: isXsDown || isOneThird
        })
      }}
      image={image}
    />
  )

  return (
    <Grid
      container
      spacing={
        oneImage || isOneThird || display || isCarousel || isSmDown ? 0 : 3
      }
      className={classNames(classes.ImageContainer, {
        [classes.topImage]: images.length > 2 && !showOneImage,
        [classes.oneImage]: oneImage || display || isOneThird || isCarousel
      })}
    >
      {(multipleImages && (isSmDown || isOneThird || isCarousel)) ||
      (multipleImages && display) ? (
        <ContentCarousel
          slides={1}
          infiniteSlider
          display={oneImage || display}
          fullWithOnMobile={false}
          autoScroll={autoScroll}
          autoScrollSpeed={autoScrollSpeed}
        >
          {images.map((image: ImageBlock, key) =>
            ResponsiveImageCard(image, key)
          )}
        </ContentCarousel>
      ) : (
        <>
          {multipleImages
            ? images.map((image: ImageBlock, key) => (
                <Grid item key={image._id + key}>
                  {ResponsiveImageCard(image, key)}
                </Grid>
              ))
            : images && ResponsiveImageCard(images[0], 1)}
        </>
      )}
    </Grid>
  )
}

const useStyles = makeStyles(
  ({
    breakpoints,
    common: {
      pageMaxWidth,
      aspectRatio21x9,
      aspectRatio16x9,
      aspectRatio4x3,
      aspectRatio1x1
    }
  }) => ({
    mediaContainer: {
      display: "block",
      aspectRatio: aspectRatio1x1
    },
    displaySmall: {
      aspectRatio: aspectRatio4x3
    },
    display: {
      aspectRatio: aspectRatio16x9
    },
    displaySmallMobile: {
      // Same CSS as displaySmall but this is needed because of how it prioritize the CSS classes.
      aspectRatio: aspectRatio4x3
    },
    bottomWide: {
      aspectRatio: `${pageMaxWidth}/470`,
      [breakpoints.down("xs")]: {
        aspectRatio: aspectRatio4x3
      }
    },
    mediaImage: {
      display: "block",
      height: "100%",
      width: "100%",
      objectFit: "cover"
    },
    ImageContainer: {
      "& > div": {
        flexGrow: 0,
        maxWidth: "50%",
        flexBasis: "50%",
        [breakpoints.down("sm")]: {
          maxWidth: "100%",
          flexBasis: "100%"
        }
      }
    },
    oneImage: {
      "& > div": {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%"
      }
    },
    topImage: {
      "& > div:first-child": {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
        "& > div": {
          aspectRatio: aspectRatio21x9
        }
      }
    }
  })
)

export default ImageCollage
