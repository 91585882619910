import { useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"

import LinkButton from "common/LinkButton"
import Typography from "common/Typography"
import { SelectableSimpleLink } from "store/PageStore"

type Props = { menuItems?: SelectableSimpleLink[] }

const SecondarySubMenuMobile = ({ menuItems }: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [expanded, setExpanded] = useState(false)

  const handleExpansion = () => {
    setExpanded(!expanded)
  }

  return menuItems && menuItems.length > 0 ? (
    <>
      <div className={classes.root}>
        <div className={classes.expansionHeader}>
          <Typography className={classes.menuHeader} onClick={handleExpansion}>
            {t("common.secondary_menu_header")}
          </Typography>
          {expanded ? (
            <KeyboardArrowUp
              className={classes.arrow}
              onClick={handleExpansion}
            />
          ) : (
            <KeyboardArrowDown
              className={classes.arrow}
              onClick={handleExpansion}
            />
          )}
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding className={classes.menuList}>
            {menuItems.map((item, i) => (
              <LinkButton
                key={i}
                link={item.url}
                className={classNames(classes.button, {
                  [classes.selected]: item.isSelected
                })}
                color="primary"
                onClick={handleExpansion}
              >
                <Typography className={classes.buttonText}>
                  {item.title}
                </Typography>
              </LinkButton>
            ))}
          </List>
        </Collapse>
      </div>
    </>
  ) : null
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    backgroundColor: themeColors.offWhite,
    marginBottom: spacing(4)
  },
  expansionHeader: {
    display: "flex",
    alignItems: "center"
  },
  menuHeader: {
    paddingRight: spacing(3),
    paddingLeft: spacing(3),
    width: "100%",
    height: 56,
    fontWeight: 300,
    fontSize: 16,
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    color: themeColors.darkGray,
    "&:hover": {
      color: themeColors.darkGray
    }
  },
  arrow: {
    color: themeColors.primaryRed,
    height: spacing(6),
    width: spacing(6),
    padding: spacing(1.5)
  },
  menuList: {
    display: "flex",
    flexDirection: "column"
  },
  button: {
    backgroundColor: themeColors.offWhite,
    padding: `0 ${spacing(4)}px`,
    height: 56,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    "&:active": {
      backgroundColor: themeColors.lightGray,
      color: themeColors.primaryBlue
    }
  },
  selected: {
    backgroundColor: themeColors.whiteGray
  },
  buttonText: {
    fontWeight: 300,
    fontSize: 16,
    whiteSpace: "nowrap",
    color: themeColors.darkGray,
    "&:hover": {
      color: themeColors.darkGray
    }
  }
}))

export default SecondarySubMenuMobile
