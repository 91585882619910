import Box from "@material-ui/core/Box"
import Tooltip from "@material-ui/core/Tooltip"
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined"
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined"
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined"

import Typography from "../Typography"

import { themeColors } from "theme"

export type Variant = "info" | "warning" | "success" | "error" | "default"

export type BadgeProps = {
  text: string
  variant?: Variant
  displayIcon?: boolean
  iconComponent?: JSX.Element
  iconSize?: number
  tooltipText?: string
}

const getBgColor = (color: string) => {
  const colors = {
    info: themeColors.infoLight,
    warning: themeColors.warningLight,
    success: themeColors.successLight,
    error: themeColors.errorLight,
    default: themeColors.lightGray
  }
  return colors[color]
}

const getForeColor = (color: string) => {
  const colors = {
    info: themeColors.infoMain,
    warning: themeColors.warningMain,
    success: themeColors.successMain,
    error: themeColors.errorMain,
    default: themeColors.neutralGray
  }
  return colors[color]
}

const getIconComponent = (icon: string) => {
  const icons = {
    info: <InfoOutlinedIcon fontSize="inherit" />,
    warning: <ReportProblemOutlinedIcon fontSize="inherit" />,
    success: <CheckOutlinedIcon fontSize="inherit" />,
    error: <ErrorOutlineOutlinedIcon fontSize="inherit" />,
    default: <FiberManualRecordIcon fontSize="inherit" />
  }
  return icons[icon]
}

export const Badge: React.FC<BadgeProps> = ({
  text,
  variant = "default",
  displayIcon = true,
  iconComponent = getIconComponent(variant || "default"),
  iconSize = variant === "default" ? 10 : 16,
  tooltipText = "",
  ...rest
}) => {
  const bgColor = getBgColor(variant || "default")
  const foreColor = getForeColor(variant || "default")

  return (
    <Tooltip placement="top" arrow title={tooltipText}>
      <Box
        bgcolor={bgColor}
        justifyContent="flex-start"
        boxSizing="border-box"
        color={foreColor}
        display="flex"
        m={0.5}
        pr={1.25}
        pl={displayIcon ? 0 : 1.25}
        {...rest}
      >
        {displayIcon && (
          <Box
            height={28}
            width={32}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flex="0 0 auto"
            fontSize={iconSize}
          >
            <Box clone color={foreColor} fontSize={iconSize}>
              {iconComponent}
            </Box>
          </Box>
        )}
        <Box clone display="inline" color={foreColor} py={0.5}>
          <Typography textWrap="wrap" variant="badge">
            {text}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  )
}

const BadgeContainer: React.FC = ({ children, ...rest }) => {
  return (
    <Box
      maxHeight={{ xs: 72, sm: 108 }}
      alignContent="flex-start"
      alignItems="flex-start"
      flexDirection={"column"}
      display="flex"
      mb={1.5}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default BadgeContainer
