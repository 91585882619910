import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Fade from "@material-ui/core/Fade"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import CustomTextField from "common/Formik/CustomTextField"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import { ScrollContainers } from "pages/Checkout/Checkout"
import accountStore, { FormType } from "store/AccountStore"
import countryStore from "store/CountryStore"
import marketStore from "store/MarketStore"
import registerStore from "store/RegisterStore"
import SiteSettingStore from "store/SiteSettingStore"
import EmailCheck from "./EmailCheck"
import UserRegistrationTerms from "./UserRegistrationTerms"

type Props = {
  selectedCountry: string
  isSubmitting: boolean
  hasErrors: boolean
}

const NewCustomer: React.FC<Props> = ({
  selectedCountry,
  isSubmitting,
  hasErrors
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { defaultMarket }
  } = useStore(marketStore)

  const {
    state: { selectableRegistrationCountries }
  } = useStore(countryStore)

  const {
    state: { newCustomer },
    actions: { resetNewCustomer }
  } = useStore(registerStore)

  const {
    state: { vatNumberInformationText, terms }
  } = useStore(SiteSettingStore)

  const {
    state: { formComponent },
    actions: { setFormComponent }
  } = useStore(accountStore)

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomTextField
            required
            name={"billingAddress.phone"}
            label={t("checkout.phone_input_label")}
            type="tel"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            required
            name={"companyName"}
            label={t("checkout.company_input_label")}
            type="text"
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            required
            name={"billingAddress.streetAddress"}
            label={t("checkout.street_address_input_label")}
            type="text"
          />
        </Grid>
        <Grid item container spacing={2} justifyContent="center">
          <Grid item xs={6}>
            <CustomTextField
              required
              name={"billingAddress.zipCode"}
              label={t("checkout.zip_code_input_label")}
              type={"text"}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              required
              name={"billingAddress.city"}
              label={t("checkout.city_input_label")}
              type="text"
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <CustomTextField
              required
              defaultValueOption={defaultMarket && defaultMarket.marketId.value}
              name={"billingAddress.countryCode"}
              label={t("checkout.shipping_country_input_label")}
              select
            >
              {selectableRegistrationCountries.data ? (
                selectableRegistrationCountries.data.map((country, i) => (
                  <option key={i} value={country.alpha2}>
                    {country.nativeName}
                  </option>
                ))
              ) : (
                <option value={defaultMarket && defaultMarket.marketId.value}>
                  {defaultMarket && defaultMarket.selectedNativeName}
                </option>
              )}
            </CustomTextField>
          </Grid>
          {vatNumberInformationText && (
            <Grid item xs={12}>
              <Typography variant={"body2"}>
                {vatNumberInformationText}
              </Typography>
            </Grid>
          )}
          {selectedCountry !==
            ((defaultMarket && defaultMarket.marketId.value) || "") && (
            <Grid item xs={12}>
              <Typography variant={"body2"}>
                {t("account.selected_country_differ_from_market_message")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        alignContent={"center"}
        className={classes.continuePadding}
        spacing={2}
      >
        {newCustomer.error && (
          <Grid
            item
            xs={12}
            className={classes.continueButtonContainer}
            id={ScrollContainers.registerFormErrorMessage}
          >
            <Typography
              id="register-new-customer-error-message"
              variant={"body2"}
              className={classes.errorMessage}
            >
              {t(`error.${(newCustomer.error as string).toLowerCase()}`)}
            </Typography>
          </Grid>
        )}
        {terms.userRegistrationTerms && (
          <Grid item xs={12}>
            <UserRegistrationTerms />
          </Grid>
        )}
        <Grid item xs={12}>
          <EmailCheck />
        </Grid>
        {hasErrors && (
          <Fade in={hasErrors}>
            <Grid item xs={12}>
              <Typography
                id="register-new-customer-form-error-message"
                variant={"body2"}
                className={classes.formErrorMessage}
              >
                {t("error.register_form_error")}
              </Typography>
            </Grid>
          </Fade>
        )}
        <Grid
          container
          spacing={2}
          justifyContent="center"
          className={classes.continueButtonContainer}
        >
          <Grid item xs={12} sm={6}>
            <Button fullWidth type="submit" disabled={isSubmitting}>
              {newCustomer.loading && (
                <ButtonLoaderIcon className={classes.loaderSpinner} />
              )}
              {t("account.register_user_button")}
            </Button>
          </Grid>
          {formComponent !== FormType.login && (
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="secondary"
                id="back-to-login-button"
                onClick={() => {
                  resetNewCustomer().then(() => {
                    setFormComponent(FormType.login)
                  })
                }}
              >
                {t("account.back_to_login")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  continuePadding: {
    paddingBottom: spacing(6)
  },
  continueButtonContainer: {
    margin: `${spacing(2)}px auto`
  },
  errorMessage: {
    paddingBottom: spacing(4),
    textAlign: "center",
    color: themeColors.primaryRed
  },
  formErrorMessage: {
    textAlign: "center",
    color: themeColors.primaryRed
  },
  loaderSpinner: {
    marginRight: spacing(1)
  }
}))

export default NewCustomer
