import { useCallback } from "react"
import {
  BlockProps,
  editorAttributes as editor,
  PageInfo
} from "@starrepublic/epi/cms"

import Box from "@material-ui/core/Box"

import { blockDisplay } from "utils/blockDisplay"

import BlockWrapper from "common/BlockWrapper"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import { textMaxWidth } from "theme"

type ContentType = {
  body: string
  sectionTheme: string
}

type Props = {
  content: ContentType
  pageInfo: PageInfo
  propsInfo: BlockProps<ContentType>["propsInfo"]
} & BlockProps

const headlineRegExTags = /(<h1>)|(<h2>)|(<h3>)|(<h4>)/

const TextBlock: React.FC<Props> = ({ content, propsInfo, blockInfo }) => {
  const { body, sectionTheme }: ContentType = content

  const headlineRegExCheck = useCallback(
    (reg: RegExp) => {
      const bodyMatch = body.match(reg)
      if (bodyMatch && bodyMatch.length > 0) {
        return true
      }
      return false
    },
    [body]
  )

  const _display = blockDisplay(blockInfo)
  if (!body) {
    return null
  }

  return (
    <BlockWrapper paddingY={headlineRegExCheck(headlineRegExTags)}>
      <Box
        maxWidth={_display.full || _display.full_alt ? "auto" : textMaxWidth}
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="0 auto"
        px={{
          xs: _display.full || _display.full_alt ? 1.5 : 0,
          sm: _display.full || _display.full_alt ? 1.5 : 0,
          md: 0
        }}
      >
        {body && (
          <Box width="100%" {...editor(propsInfo.body)}>
            <ThemeXhtmlString content={body} sectionTheme={sectionTheme} />
          </Box>
        )}
      </Box>
    </BlockWrapper>
  )
}

export default TextBlock
