import { useState } from "react"
import { BlockProps } from "@starrepublic/epi/cms"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import DisplayOptions from "utils/ui/DisplayOptions"

import BlockWrapper from "common/BlockWrapper"
import ContentCarousel from "common/ContentCarousel"
import LinkButton from "common/LinkButton"
import Typography from "common/Typography"
import { ImageBlock } from "types/content/media"
import ArticleCard from "./ArticleCard"

type Article = {
  image: ImageBlock
  category: string
  url: string
  heading: string
  text: string
}

type Props = BlockProps<{
  items: Article[]
  heading: string
  maxNumberOfItems: number
  viewAllLink?: {
    url: string
    text: string
  }
}>

const ContentCarouselBlock: React.FC<Props> = ({ content, blockInfo }) => {
  const classes = useStyles()
  const _display = blockInfo!["_display"]
  const displayOption = DisplayOptions[_display]
  const [showArrows, setShotArrows] = useState<boolean>(false)

  const { heading, items, viewAllLink, maxNumberOfItems } = content
  const SLIDES_TO_SHOW =
    Number(displayOption) < 1
      ? Number(displayOption) === 0.5
        ? 2
        : 1
      : maxNumberOfItems

  const handleOnMouseOver = (show: boolean) => {
    setShotArrows(show)
  }

  return items && items.length ? (
    <BlockWrapper className={classes.wrapper} display={_display}>
      <Box mb={6}>
        <Typography align="center" variant="headline2">
          {heading}
        </Typography>
        {viewAllLink && (
          <Box display="flex" justifyContent="center">
            <LinkButton link={viewAllLink.url ? viewAllLink.url : "/"}>
              <Typography variant="link3">{viewAllLink.text}</Typography>
            </LinkButton>
          </Box>
        )}
      </Box>
      <Box>
        <ContentCarousel
          arrowsOnHover={Number(displayOption) < 1 ? showArrows : true}
          infiniteSlider={true}
          slides={SLIDES_TO_SHOW}
        >
          {items.map((article, i) => {
            return (
              <Box p={1.5} key={i} display={"block !important"}>
                <ArticleCard {...article} />
              </Box>
            )
          })}
        </ContentCarousel>
      </Box>
    </BlockWrapper>
  ) : null
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    wrapper: {
      [breakpoints.down("xs")]: {
        margin: `0px 0px ${spacing(1.5)}px`,
        "& .slick-list": {
          borderRight: `1px solid ${themeColors.whiteGray}`
        }
      }
    },
    rootFull: {
      margin: spacing(1.5, 0),
      [breakpoints.up("sm")]: {
        marginBlock: spacing(3)
      },
      [breakpoints.up("md")]: {
        marginBlock: spacing(4)
      }
    }
  })
)

export default ContentCarouselBlock
