import { useTranslation } from "react-i18next"

import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import Typography from "common/Typography"
import { EnhancedTableProps, FileDataList, HeadCell } from "./types"

type Props = EnhancedTableProps

const EnhancedTableHead: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { order, orderBy, onRequestSort } = props

  const isXsDown = useMediaQuery(({ breakpoints }) => breakpoints.down("xs"))

  const createSortHandler =
    (property: keyof FileDataList) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  const headCells: HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: t("browser.file_name")
    },
    {
      id: "type",
      numeric: true,
      disablePadding: false,
      label: t("browser.file_format")
    }
  ]

  const headCellsMobile: HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: t("browser.file_name")
    }
  ]

  const isMobile = isXsDown ? headCellsMobile : headCells
  return (
    <TableHead>
      <TableRow className={classes.tableCell}>
        <Hidden xsDown>
          <TableCell />
        </Hidden>
        {isMobile.map((headCell) => (
          <TableCell
            className={classes.tableCellHead}
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Typography className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Typography>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  tableCell: {
    "& .MuiTableSortLabel-root.MuiTableSortLabel-active": {
      color: themeColors.mediumDarkGray
    },
    "& th > span": {
      color: themeColors.mediumDarkGray
    },
    "& .MuiTableCell-root": {
      borderBottom: 0
    }
  },
  tableCellHead: {
    paddingBottom: spacing(2)
  }
}))

export default EnhancedTableHead
