import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"

import { conditionalImport } from "utils/conditionalImport "

import DealerSearchStore from "store/DealerSearchStore"
import DealerWindow from "./DealerWindow"

const GoogleMapsDealer = conditionalImport("GoogleMapsDealer")

const DealerDialog = () => {
  const {
    state: { selectedDealer },
    actions: { setSelectedDealer }
  } = useStore(DealerSearchStore)

  return (
    <Dialog open={!!selectedDealer} fullScreen>
      <Box m={2}>
        <Box mt={4} mb={2} width="100%" height={343}>
          <GoogleMapsDealer />
        </Box>
        <DealerWindow onClose={() => setSelectedDealer(null)} />
      </Box>
    </Dialog>
  )
}

export default DealerDialog
