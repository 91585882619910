import api from "@starrepublic/epi/api"
import { asyncState, createStore } from "global-hook-store"

import { Cart as CartType, LineItem, LineItemProperties } from "models/Cart"
import * as gtm from "services/StarGA"
import MarketStore from "./MarketStore"

export type CartTypeName = "basket" | "wishlist"

type AddByItemCodePayload = {
  cartTypeName: string
  itemCode: string
  quantity: number
  amount: number
  name: string
  category: string
}

type UpdateItemQuantityPayload = {
  cartTypeName: string
  code: string
  quantity: number
  amount: number
  name: string
  category: string
}
type DeleteItemPayload = {
  cartTypeName: string
  code: string
}

const CartStore = createStore(
  {
    error: "",
    isFetching: false,
    isAddingItem: false,
    cart: asyncState<CartType<LineItemProperties>>(),
    get selectedCart() {
      return this.cart && this.cart.data
    },
    get itemsCount() {
      return ((this.selectedCart && this.selectedCart!.lineItems) || []).reduce(
        (acc: number, item: LineItem) => acc + item.quantity,
        0
      )
    }
  },
  {
    getByCartTypeName: async (_, cartTypeName: string, { asyncAction }) =>
      asyncAction(
        "cart",
        api.carts.getByCartTypeName<LineItemProperties>(cartTypeName)
      ),
    deleteByCartTypeName: async (_, cartTypeName: string, { asyncAction }) =>
      asyncAction("cart", api.carts.deleteByCartTypeName(cartTypeName)),
    deleteByItemCode: async (_, payload: DeleteItemPayload, { asyncAction }) =>
      asyncAction(
        "cart",
        api.carts.items.deleteByItemCode(payload.cartTypeName, payload.code)
      ),
    addByItemCode: async (
      _,
      payload: AddByItemCodePayload,
      { asyncAction }
    ) => {
      const currentCurrency = MarketStore.state.defaultMarket
        ? MarketStore.state.defaultMarket.defaultCurrency
        : ""

      gtm.addToCart(
        {
          code: payload.itemCode,
          amount: payload.amount,
          quantity: payload.quantity,
          name: payload.name,
          category: payload.category
        },
        currentCurrency
      )

      return asyncAction(
        "cart",
        api.carts.items.addByItemCode(payload.cartTypeName, payload.itemCode, {
          quantity: payload.quantity
        }) as Promise<CartType<LineItemProperties>>
      )
    },
    updateItemQuantity: async (
      state,
      payload: UpdateItemQuantityPayload,
      { asyncAction }
    ) => {
      const currentCurrency = MarketStore.state.defaultMarket
        ? MarketStore.state.defaultMarket.defaultCurrency
        : ""

      if (state.cart.data) {
        const itemInCart = state.cart.data.lineItems.find(
          (i) => i.code === payload.code
        )
        if (itemInCart) {
          if (itemInCart.quantity < payload.quantity) {
            gtm.addToCart(
              {
                code: payload.code,
                amount: payload.amount,
                quantity: payload.quantity - itemInCart.quantity,
                name: payload.name,
                category: payload.category
              },
              currentCurrency
            )
          } else {
            gtm.removeFromCart(
              {
                code: payload.code,
                amount: payload.amount,
                quantity: itemInCart.quantity - payload.quantity,
                name: payload.name,
                category: payload.category
              },
              currentCurrency
            )
          }
        }
      }

      return asyncAction(
        "cart",
        api.carts.items.updateByItemCode(payload.cartTypeName, payload.code, {
          quantity: payload.quantity
        }) as Promise<CartType<LineItemProperties>>
      )
    }
  }
)

CartStore.actions.getByCartTypeName("basket")

export default CartStore
