import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { Form as FormikForm } from "formik"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import CustomTextField from "common/Formik/CustomTextField"
import CustomTextFieldPopper from "common/Formik/CustomTextFieldPopper"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import authStore from "store/AuthStore"

const ChangePasswordForm: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const passwordRef = useRef<HTMLInputElement>(null)

  const {
    state: { changePasswordResponse }
  } = useStore(authStore)

  return (
    <div className={classes.root}>
      <FormikForm noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              required
              name="password"
              label={t("checkout.password_input_label")}
              type="password"
              autoComplete="new-password"
              inputRef={passwordRef}
            />
            <CustomTextFieldPopper
              open={true}
              inputRef={passwordRef}
              renderAsBlock
            />
          </Grid>
          {changePasswordResponse.error && (
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.errorMessage}>
                {t(
                  `error.${(
                    changePasswordResponse.error as string
                  ).toLowerCase()}`
                )}
              </Typography>
            </Grid>
          )}
          <Grid container justifyContent="center">
            <Grid item xs={12} className={classes.signInButtonContainer}>
              <Button type="submit">
                {changePasswordResponse.loading && (
                  <ButtonLoaderIcon className={classes.loaderSpinner} />
                )}
                {t("account.change_password_button")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </FormikForm>
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { gridSpacing, themeColors } }) => ({
    root: {
      width: "100%",
      padding: gridSpacing,
      maxWidth: 450,
      [breakpoints.down("sm")]: {
        padding: gridSpacing
      }
    },
    signInButtonContainer: {
      paddingTop: gridSpacing,
      paddingLeft: 8,
      paddingRight: 8,
      display: "flex",
      justifyContent: "center",
      paddingBottom: 16,
      [breakpoints.down("sm")]: {
        paddingLeft: 8,
        paddingRight: 8
      }
    },
    errorMessage: {
      paddingTop: spacing(1),
      textAlign: "center",
      color: themeColors.primaryRed
    },
    loaderSpinner: {
      marginRight: spacing(1)
    }
  })
)

export default ChangePasswordForm
