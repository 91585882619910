import { Fragment } from "react"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import FenceContentCarousel from "../FenceContentCarousel"
import FenceProductCard from "../FenceProductCard"
import { FenceProductVariant } from "../types"

type ProductsContainerProps = {
  products: FenceProductVariant[]
  selectedProduct: FenceProductVariant
  onSelectProduct: (articleNumber: string) => void
  currencySymbol: string
}

const ProductsContainer = ({
  products,
  selectedProduct,
  onSelectProduct,
  currencySymbol
}: ProductsContainerProps) => {
  // Custom mediaQuery to check if products should be displayed in a grid or carousel
  const isWideScreen = useMediaQuery("(min-width:769px)")
  const SLIDES_TO_SHOW = products.length

  return (
    <>
      {isWideScreen ? (
        <Box mx={2}>
          <Grid container spacing={1}>
            {products.map((product, idx) => {
              return (
                <Grid
                  item
                  sm={3}
                  key={`${product.variant.articleNumber} - ${idx}`}
                >
                  <FenceProductCard
                    product={product}
                    selectedProduct={selectedProduct}
                    onSelectProduct={onSelectProduct}
                    currencySymbol={currencySymbol}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      ) : (
        <FenceContentCarousel slides={SLIDES_TO_SHOW}>
          {products.map((product, idx) => {
            return (
              <Fragment key={`${product.variant.articleNumber} - ${idx}`}>
                <FenceProductCard
                  product={product}
                  selectedProduct={selectedProduct}
                  onSelectProduct={onSelectProduct}
                  currencySymbol={currencySymbol}
                />
              </Fragment>
            )
          })}
        </FenceContentCarousel>
      )}
    </>
  )
}

export default ProductsContainer
