import request from "@starrepublic/epi/api/request"

export type FormModel = {
  hostedPage: number
  submissionId: string
  stepIndex: number
  values: Record<string, string>
}

export type FormResponse = {
  isSuccess: boolean
  isProgressiveSubmit: boolean
  redirectUrl: string
  message: string
}

const forms = {
  submitForm: (id: string, model: FormModel) =>
    request.post<FormResponse>(`/forms/${id}`, model)
}

export default forms
