import { useRef, useState } from "react"
import {
  GoogleMap,
  Polygon,
  withGoogleMap,
  withScriptjs
} from "react-google-maps"
import DrawingManager from "react-google-maps/lib/components/drawing/DrawingManager"
import debounce from "lodash/debounce"

import Box from "@material-ui/core/Box"

import Button from "common/Button"
import mapStyles from "pages/DealerPage/mapStyles"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"

export type GeoLocationType = {
  lat: number
  lng: number
}

const FenceMapConfig: React.FC = () => {
  const mapRef = useRef<GoogleMap>(null)

  const { setLengthOfFence, geoLocation, setGeoLocation } =
    useFenceCalculatorStore()

  const [mapZoom, setMapZoom] = useState(12)

  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null)

  const [showControls, setShowControls] = useState(true)
  const [drawingMode, setDrawingMode] =
    useState<google.maps.drawing.OverlayType.POLYGON | null>(null)

  const handleCenterChanged = debounce(() => {
    if (mapRef.current) {
      setGeoLocation({
        lat: mapRef.current.getCenter().lat(),
        lng: mapRef.current.getCenter().lng()
      })
    }
  }, 500)

  const handleZoomChanged = debounce(() => {
    if (mapRef.current) {
      setMapZoom(mapRef.current.getZoom())
    }
  }, 500)

  const handlePolygonComplete = (polygon: google.maps.Polygon) => {
    setPolygon(polygon)
    const vertices: { x: number; y: number }[] = []
    const paths = polygon.getPath().getArray()
    paths.forEach((path: { lat: () => number; lng: () => number }) =>
      vertices.push({ x: path.lat(), y: path.lng() })
    )

    const distances = []
    for (let i = 0; i < vertices.length; i++) {
      const j = (i + 1) % vertices.length
      distances.push(
        distance(vertices[i].x, vertices[i].y, vertices[j].x, vertices[j].y)
      )
    }

    let totalDistance = 0
    for (let i = 0; i < distances.length; i++) {
      totalDistance += parseInt(distances[i])
    }

    setLengthOfFence(totalDistance)
    setShowControls(false)
    setDrawingMode(null)
  }

  const distance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371 // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1) // deg2rad below
    const dLon = deg2rad(lon2 - lon1)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const d = R * c // Distance in km
    const meter = d * 1000
    return meter.toFixed()
  }

  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180)
  }

  const handleClearMap = () => {
    if (polygon) {
      // TODO: Investigate if this could be done in one line
      polygon.setVisible(false)
      setPolygon(null)
    }
    setLengthOfFence(0)
    setShowControls(true)
  }

  return (
    <Box style={{ position: "relative" }}>
      <Button
        style={{ position: "absolute", top: -281 }}
        variant="secondary"
        onClick={handleClearMap}
      >
        Clear
      </Button>
      <GoogleMap
        ref={mapRef}
        zoom={mapZoom}
        center={{ lat: geoLocation.lat, lng: geoLocation.lng }}
        defaultOptions={{
          styles: mapStyles as any,
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: true,
          rotateControl: false,
          fullscreenControl: false
        }}
        onCenterChanged={handleCenterChanged}
        onZoomChanged={handleZoomChanged}
      >
        {polygon ? (
          <Polygon
            path={polygon.getPath()}
            editable
            draggable
            onMouseUp={() => handlePolygonComplete(polygon)}
          />
        ) : (
          <DrawingManager
            onPolygonComplete={handlePolygonComplete}
            options={{
              drawingControl: showControls,
              drawingMode,
              drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT,
                drawingModes: [google.maps.drawing.OverlayType.POLYGON]
              }
            }}
          />
        )}
      </GoogleMap>
    </Box>
  )
}

export default withScriptjs(withGoogleMap(FenceMapConfig))
