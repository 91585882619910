import { useEffect } from "react"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"

import Wrapper from "common/Wrapper"
import authStore from "store/AuthStore"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import CustomerDetails from "./Sections/CustomerDetails"
import DeliveryAndPayment from "./Sections/DeliveryAndPayment"
import OrderDetails from "./Sections/OrderDetails"
import OrderSummary from "./Sections/OrderSummary"
import PromoCode from "./Sections/PromoCode"
import Error from "./Error"

export enum ScrollContainers {
  customerDetails = "RegisterForm",
  deliveryOptions = "DeliveryOptions",
  paymentOptions = "PaymentOptions",
  registerFormErrorMessage = "RegisterFormErrorMessage",
  deliveryOptionsErrorMessage = "DeliveryOptionsErrorMessage",
  paymentOptionsErrorMessage = "PaymentOptionsErrorMessage",
  orderSummary = "OrderSummary",
  orderConfirmation = "OrderConfirmation"
}

const CheckoutPage: React.FC = () => {
  const classes = useStyles()
  const {
    selectedShippingAddress,
    checkoutError,
    shippingCheck,
    set: checkoutSet
  } = useCheckoutPaymentStore()

  const {
    state: { user }
  } = useStore(authStore)

  useEffect(
    () => {
      if (!user.loading && user.data && selectedShippingAddress) {
        window.setTimeout(() => {
          checkoutSet.loadingShippingCheck(true)
          shippingCheck(user.data)
        }, 1)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.loading, user.data, selectedShippingAddress]
  )
  return (
    <>
      {checkoutError ? <Error error={checkoutError} fullWidth={true} /> : null}
      <Wrapper
        containerClassName={classes.wrapper}
        className={classes.wrapperRoot}
      >
        <OrderDetails />
        <PromoCode />
        <CustomerDetails />
        <DeliveryAndPayment />
        <OrderSummary />
      </Wrapper>
    </>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  wrapperRoot: {
    background: themeColors.offWhite
  },
  wrapper: {
    background: themeColors.offWhite
  }
}))

export default CheckoutPage
