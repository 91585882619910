import i18next from "i18next"
import * as yup from "yup"

export interface VatValues {
  VatNumber: string
  CountryCode: string
}

const validationSchemaVat = (t: i18next.TranslationFunction) => {
  return yup.object<VatValues>().shape({
    VatNumber: yup
      .string()
      .required(t("checkout.vat_number_required_message"))
      .when("CountryCode", {
        is: (value) => {
          return value === "FI"
        },
        then: yup
          .string()
          .matches(
            /^[0-9]{8}$/,
            t("checkout.vat_number_format_message").replace(
              "{value}",
              "FI99999999"
            )
          )
      })
      .when("CountryCode", {
        is: (value) => {
          return value === "SE"
        },
        then: yup
          .string()
          .matches(
            /^[0-9]{10}$/,
            t("checkout.vat_number_format_message").replace(
              "{value}",
              "SE999999999901"
            )
          )
      })
  })
}

export default validationSchemaVat
