import Fade from "@material-ui/core/Fade"

type Props = {
  fade?: boolean
  className?: string
  width?: number
  height?: number
}

const SpinnerComponent = ({ className }: { className: string | undefined }) => (
  <svg className={className} width={38} height={38}>
    <defs>
      <linearGradient id="_slg_" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#00A6FF" />
        <stop offset="100%" stopColor="#103D82" />
      </linearGradient>
    </defs>
    <g
      transform="translate(1 1)"
      strokeWidth={2}
      stroke="url(#_slg_)"
      fill="none"
      fillRule="evenodd"
    >
      <circle strokeOpacity={0.2} cx={18} cy={18} r={18} />
      <path d="M35.84 20.393C37.162 10.54 30.244 1.48 20.393.16">
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 18 18"
          to="360 18 18"
          dur="1000ms"
          repeatCount="indefinite"
        />
      </path>
    </g>
  </svg>
)

const Spinner: React.FC<Props> = ({ className, fade = true }) =>
  (fade && (
    <Fade
      in
      unmountOnExit
      style={{
        transitionDelay: "250ms"
      }}
    >
      <div>
        <SpinnerComponent className={className} />
      </div>
    </Fade>
  )) || <SpinnerComponent className={className} />

export default Spinner
