import { SvgIconProps } from "@material-ui/core/SvgIcon"

const Estonia: React.FC<SvgIconProps> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48" {...props}>
    <g>
      <path
        fill="#E6E6E6"
        d="M48,40c0,1.105-0.895,2-2,2H2c-1.105,0-2-0.895-2-2V30h48V40z"
      />
      <rect y="18" width="48" height="12" />
      <path
        fill="#3A75C3"
        d="M48,18H0V8c0-1.105,0.895-2,2-2h44c1.105,0,2,0.895,2,2V18z"
      />
    </g>
  </svg>
)

export default Estonia
