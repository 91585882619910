import classNames from "classnames"
import { Slider } from "pure-react-carousel"

import { createStyles, makeStyles } from "@material-ui/core/styles/"

type CarouselSliderProps = {
  thumbnailPosition: "left" | "bottom"
}

const useStyles = makeStyles(({ breakpoints, common: { themeColors } }) =>
  createStyles({
    slider: {
      maxWidth: "600px",
      margin: "0 auto",
      width: "100%",
      display: "block",
      position: "relative",
      cursor: "pointer",
      "& img": {
        position: "absolute",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        objectFit: "contain",
        objectPosition: "center",
        width: "100%",
        height: "100%"
      },
      "& li > div:focus": {
        outline: "none"
      },
      "& .focusRing___1airF.carousel__slide-focus-ring": {
        outline: "none!important"
      },
      [breakpoints.down("sm")]: {
        margin: "0 auto",
        width: "100%"
      },
      "&:hover": {
        "& svg": {
          backgroundColor: themeColors.darkBorder,
          borderRadius: "50%"
        }
      }
    },
    left: {
      [breakpoints.up("md")]: {
        width: "calc(100% - 72px)"
      }
    }
  })
)

export const CarouselSlider: React.FC<CarouselSliderProps> = ({
  children,
  thumbnailPosition
}) => {
  const classes = useStyles({ thumbnailPosition })
  return (
    <Slider className={classNames(classes.slider, classes[thumbnailPosition])}>
      {children}
    </Slider>
  )
}

export default CarouselSlider
