import i18next from "i18next"
import * as yup from "yup"

export interface ContactModalFormType {
  name: string
  email: string
  phone: string
  message: string
}

const validationSchema = (t: i18next.TranslationFunction) => {
  return yup.object<ContactModalFormType>().shape({
    name: yup.string().required(t("account.full_name_required_message")),
    email: yup
      .string()
      .required(t("checkout.email_required_message"))
      .email(t("checkout.email_invalid_message")),
    phone: yup.string(),
    message: yup.string()
  })
}

export default validationSchema
