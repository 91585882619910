import classNames from "classnames"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { numberFormat } from "utils/numberFormat"

import Typography from "common/Typography"
import AnimalCow from "icons/AnimalCow"
import EnergyCalculatorStore from "store/EnergyCalculatorStore"
import marketStore from "store/MarketStore"
import { themeColors } from "theme"
import CalculactionForm from "./CalculatorForm"
type CalculatorResultProps = {
  isTwoThirds: boolean
}

const CalculatorResult: React.FC<CalculatorResultProps> = (props) => {
  const { isTwoThirds } = props
  const {
    state: {
      totalEnergyNeededCalc,
      totalMilkCoolingCost,
      energyCostSavingsCooling,
      energySavingsPerYear,
      calculationType,
      energySavingsForHeating,
      energyCostSavingsHeating,
      labels,
      maxAmountOfWaterCalc
    }
  } = useStore(EnergyCalculatorStore)

  const isSmDown = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"))
  const isMdDown = useMediaQuery(({ breakpoints }) => breakpoints.down("md"))
  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))
  const isMdUp = useMediaQuery(({ breakpoints }) => breakpoints.up("md"))

  const isTwoThirdsDesktop = isTwoThirds && isMdUp
  const isTwoThirdsDesktopSmall = isTwoThirds && isMdDown

  const classes = useStyles(isTwoThirdsDesktop)()

  const KILOWATT = "kWh"
  const COOLING_AND_HEATING = "coolingAndHeating"
  const HEATING = "heating"
  const COOLING = "cooling"

  const {
    state: { markets }
  } = useStore(marketStore)
  const selectedMarket = markets.data && markets.data.find((x) => x.isSelected)
  const selectedLanguage = selectedMarket
    ? selectedMarket.selectedLanguage
    : "en"

  const defaultCurrency = selectedMarket
    ? selectedMarket.defaultCurrency
    : undefined

  return (
    <Box
      id={
        labels.yearlyCostCoolingResultTitle &&
        labels.yearlyCostCoolingResultTitle.replace(/\s/g, "_")
      }
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      {totalEnergyNeededCalc ? (
        <Box
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            className={classNames(classes.result, {
              [classes.resultBorder]: isSmDown,
              [classes.resultPaddingTwoThirds]: isTwoThirdsDesktop
            })}
          >
            <Box>
              <Typography
                variant={isTwoThirdsDesktop ? "headline5" : "headline4"}
              >
                {labels.yearlyCostCoolingResultTitle}
              </Typography>
              <Box pt={2}>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={isTwoThirdsDesktopSmall ? 12 : 6}>
                    <Typography
                      className={classes.totalCost}
                      variant="headline4"
                    >
                      {numberFormat({
                        style: "currency",
                        value: totalMilkCoolingCost,
                        selectedLanguage,
                        currency: defaultCurrency
                      })}
                    </Typography>
                    <Typography className={classes.smallText} variant="body2">
                      {labels.totalMilkCoolingCostPerYear}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={isTwoThirdsDesktopSmall ? 12 : 6}>
                    <Typography
                      className={classes.totalCost}
                      variant="headline4"
                      noWrap
                    >
                      {`${KILOWATT} ${numberFormat({
                        style: "decimal",
                        value: totalEnergyNeededCalc,
                        selectedLanguage,
                        currency: defaultCurrency
                      })}`}
                    </Typography>
                    <Typography className={classes.smallText} variant="body2">
                      {labels.totalEnergyForCoolingMilkPerYear}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {(calculationType === COOLING ||
              calculationType === COOLING_AND_HEATING) && (
              <Box pt={3}>
                <Typography
                  variant={isTwoThirdsDesktop ? "headline5" : "headline4"}
                >
                  {labels.savingPrecoolingTitle}
                </Typography>
                <Box pt={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={isTwoThirdsDesktopSmall ? 12 : 6}>
                      <Typography
                        className={classes.energyCost}
                        variant="headline4"
                        noWrap
                      >
                        {numberFormat({
                          style: "currency",
                          value: energyCostSavingsCooling,
                          selectedLanguage,
                          currency: defaultCurrency
                        })}
                      </Typography>
                      <Typography className={classes.smallText} variant="body2">
                        {labels.energyCostSavingsYear}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={isTwoThirdsDesktopSmall ? 12 : 6}>
                      <Typography
                        className={classes.energyCost}
                        variant="headline4"
                        noWrap
                      >
                        {`${KILOWATT} ${numberFormat({
                          style: "decimal",
                          value: energySavingsPerYear,
                          selectedLanguage,
                          currency: defaultCurrency
                        })}`}
                      </Typography>
                      <Typography className={classes.smallText} variant="body2">
                        {labels.energySavingsUsingPrecooling}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
            {(calculationType === HEATING ||
              calculationType === COOLING_AND_HEATING) && (
              <Box pt={3}>
                <Typography
                  variant={isTwoThirdsDesktop ? "headline5" : "headline4"}
                >
                  {labels.savingsUsingHeatRecoveryTitle}
                </Typography>
                <Box pt={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={isTwoThirdsDesktopSmall ? 12 : 6}>
                      <Typography
                        className={classes.energyCost}
                        variant="headline4"
                        noWrap
                      >
                        {numberFormat({
                          style: "currency",
                          value: energyCostSavingsHeating,
                          selectedLanguage,
                          currency: defaultCurrency
                        })}
                      </Typography>
                      <Typography className={classes.smallText} variant="body2">
                        {labels.energyCostSavingsYear}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={isTwoThirdsDesktopSmall ? 12 : 6}>
                      <Typography
                        className={classes.energyCost}
                        variant="headline4"
                        noWrap
                      >
                        {`${KILOWATT} ${numberFormat({
                          style: "decimal",
                          value: energySavingsForHeating,
                          selectedLanguage,
                          currency: defaultCurrency
                        })}`}
                      </Typography>
                      <Typography className={classes.smallText} variant="body2">
                        {labels.energySavingsUsingHeatRecovery}
                      </Typography>
                    </Grid>
                    {maxAmountOfWaterCalc > 0 && (
                      <Grid item xs={6} md={isTwoThirdsDesktopSmall ? 12 : 6}>
                        <Typography
                          className={classes.energyCost}
                          variant="headline4"
                          noWrap
                        >
                          {numberFormat({
                            style: "unit",
                            value: maxAmountOfWaterCalc,
                            selectedLanguage,
                            currency: defaultCurrency,
                            unit: "liter",
                            unitDisplayLong: true
                          })}
                        </Typography>
                        <Typography
                          className={classes.smallText}
                          variant="body2"
                        >
                          {labels.maxAmountOfWater}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            )}
            {calculationType === COOLING_AND_HEATING && (
              <Box pt={3}>
                <Typography
                  variant={isTwoThirdsDesktop ? "headline5" : "headline4"}
                >
                  {labels.totalSavingsTitle}
                </Typography>
                <Box pt={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={isTwoThirdsDesktopSmall ? 12 : 6}>
                      <Typography
                        className={classes.energyCost}
                        variant="headline4"
                        noWrap
                      >
                        {numberFormat({
                          style: "currency",
                          value:
                            energyCostSavingsCooling + energyCostSavingsHeating,
                          selectedLanguage,
                          currency: defaultCurrency
                        })}
                      </Typography>
                      <Typography className={classes.smallText} variant="body2">
                        {labels.energyCostSavingsYear}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={isTwoThirdsDesktopSmall ? 12 : 6}>
                      <Typography
                        className={classes.energyCost}
                        variant="headline4"
                        noWrap
                      >
                        {`${KILOWATT} ${numberFormat({
                          style: "decimal",
                          value: energySavingsPerYear + energySavingsForHeating,
                          selectedLanguage,
                          currency: defaultCurrency
                        })}`}
                      </Typography>
                      <Typography className={classes.smallText} variant="body2">
                        {labels.energySavingsUsingPrecoolingAndHeating}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            mt={3}
            bgcolor={themeColors.colorBaseGray200}
            className={classes.resultBorder}
          >
            <CalculactionForm />
          </Box>
        </Box>
      ) : (
        isSmUp && (
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box
              mb={3}
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.iconBorder}
            >
              <AnimalCow className={classes.icon} />
            </Box>
            <Box textAlign="center">
              <Typography
                style={{ color: themeColors.colorBaseGray500 }}
                variant="headline6"
              >
                {labels.resultPlaceholderTitle}
              </Typography>

              <Typography
                style={{ color: themeColors.colorBaseGray500 }}
                variant="body2"
              >
                {labels.resultPreambleText}
              </Typography>
            </Box>
          </Box>
        )
      )}
    </Box>
  )
}

const useStyles = (isTwoThirdsDesktop: boolean) =>
  makeStyles(({ breakpoints, spacing, common: { themeColors } }) => ({
    icon: {
      width: spacing(7),
      height: spacing(7),
      color: themeColors.mediumGray
    },
    iconBorder: {
      width: spacing(9),
      height: spacing(9),
      backgroundColor: themeColors.offWhite,
      borderRadius: "50%"
    },
    totalCost: {
      color: themeColors.primaryBlue,
      fontSize: isTwoThirdsDesktop ? 20 : 28,
      lineHeight: "32px",
      [breakpoints.down("md")]: {
        fontSize: 20,
        lineHeight: "24px"
      }
    },
    energyCost: {
      color: themeColors.primaryCyan,
      fontSize: isTwoThirdsDesktop ? 20 : 28,
      lineHeight: "32px",
      [breakpoints.down("md")]: {
        fontSize: 20,
        lineHeight: "24px"
      }
    },
    smallText: {
      color: themeColors.colorBaseGray500
    },
    resultBorder: {
      border: `1px solid ${themeColors.lightGray}`,
      borderRadius: spacing(1)
    },

    result: {
      padding: spacing(6),
      [breakpoints.down("md")]: {
        padding: spacing(5)
      },
      [breakpoints.down("xs")]: {
        padding: spacing(2)
      }
    },
    resultPaddingTwoThirds: {
      padding: spacing(6, 6, 6, 0)
    }
  }))

export default CalculatorResult
