import { aspectRatio16x9, themeColors } from "theme"

const BLANK_PIXEL =
  "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="

/** Wrapper for Images with lazy loading and sets correct source sets */
const ImagePlaceholder = () => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        paddingTop: `${100 / aspectRatio16x9}%`, // This calculates the padding based on the aspect ratio
        backgroundColor: themeColors.colorBaseGray200
      }}
    >
      <img
        alt="Missing image src"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover" // This ensures the image covers the area without stretching
        }}
        src={BLANK_PIXEL}
      />
    </div>
  )
}

export default ImagePlaceholder
