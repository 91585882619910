import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const PersonIcon: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M9 8a2 2 0 10.09-4A2 2 0 009 8zm0 1.2c-1.67 0-5 .83-5 2.5V13h10v-1.3c0-1.67-3.33-2.5-5-2.5z"
      fill={props.fill || "#575757"}
    />
  </SvgIcon>
)

export default PersonIcon
