import { SvgIconProps } from "@material-ui/core/SvgIcon"

const Australia: React.FC<SvgIconProps> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48" {...props}>
    <g>
      <path
        d="M46,6H2A2,2,0,0,0,0,8V40a2,2,0,0,0,2,2H46a2,2,0,0,0,2-2V8A2,2,0,0,0,46,6Z"
        fill="#0f2e72"
      />
      <polygon
        points="19 19 16 19 16 19.25 22.333 24 24 24 24 22.75 19 19"
        fill="red"
      />
      <path
        d="M.12,7.34,5,11H8v-.25L1.706,6.03A1.991,1.991,0,0,0,.12,7.34Z"
        fill="red"
      />
      <polygon
        points="24 6 22.334 6 16 10.75 16 11 19.001 11 24 7.25 24 6"
        fill="red"
      />
      <polygon
        points="0 22.75 0 24 1.667 24 8 19.25 8 19 5 19 0 22.75"
        fill="red"
      />
      <polygon
        points="24 13 14 13 14 6 10 6 10 13 0 13 0 17 10 17 10 24 14 24 14 17 24 17 24 13"
        fill="red"
      />
      <polygon
        points="12.172 30 12.905 32.059 14.879 31.121 13.941 33.095 16 33.828 13.941 34.561 14.879 36.535 12.905 35.596 12.172 37.656 11.439 35.596 9.465 36.535 10.404 34.561 8.344 33.828 10.404 33.095 9.465 31.121 11.439 32.059 12.172 30"
        fill="#fff"
      />
      <polygon
        points="31.155 22.522 31.505 23.505 32.448 23.057 32 24 32.983 24.35 32 24.7 32.448 25.642 31.505 25.194 31.155 26.178 30.805 25.194 29.863 25.642 30.311 24.7 29.327 24.35 30.311 24 29.863 23.057 30.805 23.505 31.155 22.522"
        fill="#fff"
      />
      <polygon
        points="36.172 13.344 36.522 14.327 37.465 13.879 37.017 14.822 38 15.172 37.017 15.522 37.465 16.465 36.522 16.016 36.172 17 35.822 16.016 34.879 16.465 35.328 15.522 34.344 15.172 35.328 14.822 34.879 13.879 35.822 14.327 36.172 13.344"
        fill="#fff"
      />
      <polygon
        points="42.828 20.344 43.178 21.327 44.121 20.879 43.673 21.822 44.656 22.172 43.673 22.522 44.121 23.465 43.178 23.016 42.828 24 42.478 23.016 41.535 23.465 41.984 22.522 41 22.172 41.984 21.822 41.535 20.879 42.478 21.327 42.828 20.344"
        fill="#fff"
      />
      <polygon
        points="36.172 31 36.522 31.983 37.465 31.535 37.017 32.478 38 32.828 37.017 33.178 37.465 34.121 36.522 33.672 36.172 34.656 35.822 33.672 34.879 34.121 35.328 33.178 34.344 32.828 35.328 32.478 34.879 31.535 35.822 31.983 36.172 31"
        fill="#fff"
      />
    </g>
  </svg>
)

export default Australia
