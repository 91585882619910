import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import Button from "common/Button"
import Typography from "common/Typography"
import Logo from "icons/Logo"
import { themeColors } from "theme"
import SkeletonWalnut from "./SkeletonWalnut"

type EmbeddedDemoBlockTypes = {
  demoUrl: string
}

const DEMO_IFRAME_RATIO = 5 / 3

type Props = BlockProps & EmbeddedDemoBlockTypes

const EmbeddedDemoBlock = ({ content }: Props) => {
  const classes = useStyles()
  const { demoUrl } = content

  const isMdDown = useMediaQuery(({ breakpoints }) => breakpoints.down("745"))
  const { t } = useTranslation()

  const ref = useRef<HTMLIFrameElement>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box>
      <Box
        position="relative"
        className={classNames(classes.root, {
          [classes.isMobile]: isMdDown
        })}
      >
        {isLoading && <SkeletonWalnut className={classes.media} />}
        <iframe
          ref={ref}
          style={{
            visibility: isLoading ? "hidden" : "visible"
          }} // Hide the iframe while it's loading
          className={classes.media}
          src={demoUrl}
          allowFullScreen
          frameBorder="none"
          aria-label='"Iframe Embedded DeLaval Plus Content."'
          tabIndex={0}
          role="document"
          onLoad={() => {
            setIsLoading(false)
          }}
        />

        {isMdDown && open && (
          <Box
            position="absolute"
            display="flex"
            justifyContent="center"
            flexDirection="column"
            bgcolor={themeColors.white}
            height={1}
            width={1}
          >
            <Logo dark />
            <Box my={3} mx={4.5} textAlign="left">
              <Typography className={classes.headline} variant="headline5">
                {t("demo.head_requires_larger_screen")}
              </Typography>
              <Typography variant="body1" textWrap="pretty">
                {t("demo.body_requires_larger_screen")}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Button onClick={handleClose} variant="primary">
                {t("demo.button_requires_larger_screen")}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    display: "block",
    width: "100%",
    position: "relative",
    paddingBottom: `${100 / DEMO_IFRAME_RATIO}%`
  },
  media: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "50%",
    transform: "translateY(-50%)"
  },
  spinner: {
    boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
    backgroundColor: themeColors.white,
    boxSizing: "content-box",
    padding: spacing(1),
    borderRadius: spacing(4)
  },
  headline: {
    fontSize: 28,
    paddingBottom: spacing(2)
  },
  isMobile: {
    paddingBottom: 600
  }
}))

export default EmbeddedDemoBlock
