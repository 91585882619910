import useStore from "global-hook-store"

import authStore from "store/AuthStore"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import Delivery from "./Delivery"
import DeliveryPaymentSummary from "./DeliveryPaymentSummary"
import Payment from "./Payment"

const DeliveryAndPayment: React.FC = () => {
  const { preparedCheckout } = useCheckoutPaymentStore()
  const {
    state: { user }
  } = useStore(authStore)

  const isLoggedIn = !user.loading && user.data != null

  if (!isLoggedIn) return null

  return !preparedCheckout.data ? (
    <>
      <Delivery />
      <Payment />
    </>
  ) : (
    <DeliveryPaymentSummary />
  )
}

export default DeliveryAndPayment
