import request from "@starrepublic/epi/api/request"

export type NewsletterPayload = {
  firstName: string
  lastName: string
  email: string
  newsletterAccepted: boolean
}

const newsletter = {
  send: (payload: NewsletterPayload) => request.post(`/newsletter`, payload)
}

export default newsletter
