import CmsContext from "./context"

export const CmsContextConsumer = CmsContext.Consumer
export { default as CmsProvider } from "./CmsProvider"
export type {
  BlockComponentMap,
  CmsContextProps,
  PageComponentMap
} from "./context"
export { default as withCmsContext } from "./withCmsContext"
