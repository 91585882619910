import { ChangeEvent, Suspense, useCallback, useEffect, useState } from "react"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import Button from "common/Button"
import FenceIconSkeleton from "common/Skeletons/FenceIconSkeleton"
import TextField from "common/TextField"
import Typography from "common/Typography"
import FenceLengthIcon from "icons/FenceCalculator/FenceLengthIcon"
import FenceWiresIcon from "icons/FenceCalculator/FenceWiresIcon"
import MapLoadingSpinner from "pages/DealerPage/MapLoadingSpinner"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"
import { themeColors } from "theme"
import AlertStepper from "./AlertStepper"
import FenceMap from "./FenceMap"
import ProductsContainer from "./ProductsContainer"
import { FenceCalculatorBlockProps, FenceProductVariant } from "./types"
import { deselectProduct, getAllProducts, getSelectedProduct } from "./utils"

type FenceMaterialProps = {
  content: FenceCalculatorBlockProps
}

const FenceMaterial = ({ content }: FenceMaterialProps) => {
  // Should not display map feature for MVP
  const displayMapFeature = false
  const classes = useStyles()
  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))
  const { wireProducts, currencySymbol } = content
  const [showMap, setShowMap] = useState(false)

  const {
    lengthOfFence,
    setLengthOfFence,
    totalLengthOfFence,
    setTotalLengthOfFence,
    selectedWire,
    setSelectedWire,
    numberOfWireRows,
    setNumberOfWireRows,
    numberOfWirePacks,
    setNumberOfWirePacks,
    selectedAnimal,
    selectedCategory,
    fenceCalculatorLabels,
    maxCapacityExceeded,
    selectedEnergizer,
    setSelectedEnergizer,
    setMaxCapacityExceeded
  } = useFenceCalculatorStore()

  // State used to keep track of entered wire values (length and rows), when max capacity for selected product/products are exceeded.
  const [exceededWireValues, setExceededWireValues] = useState({
    length: lengthOfFence,
    rows: numberOfWireRows
  })

  const allWireProducts: FenceProductVariant[] = getAllProducts(
    wireProducts,
    [selectedAnimal, selectedCategory].concat(),
    totalLengthOfFence
  )

  const isEnergizerMaxCapacityExceeded = (name: string, value: number) => {
    if (!selectedEnergizer.name) {
      return false
    }
    return (
      selectedEnergizer.variant.capacity > 0 &&
      selectedEnergizer.variant.capacity <
        (name === "lengthOfFence"
          ? value * numberOfWireRows
          : value * lengthOfFence)
    )
  }

  const isMaxCapacityExceeded = (
    capacity: number,
    name: string,
    inputValue: number
  ): boolean => {
    return (
      (capacity > 0 &&
        capacity <
          (name === "lengthOfFence"
            ? inputValue * numberOfWireRows
            : inputValue * lengthOfFence)) ||
      isEnergizerMaxCapacityExceeded(name, inputValue)
    )
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    const { capacity } = selectedWire.variant || 0

    switch (name) {
      case "lengthOfFence": {
        // Need to set exceeded values here to catch event value before condition
        setExceededWireValues({
          ...exceededWireValues,
          length: Number(value)
        })
        if (
          selectedWire.name &&
          isMaxCapacityExceeded(capacity, name, Number(value))
        ) {
          setMaxCapacityExceeded(true)
          return
        }
        setLengthOfFence(Number(value))
        setMaxCapacityExceeded(false)
        break
      }
      case "numberOfWireRows": {
        // Need to set exceeded values here to catch event value before condition
        setExceededWireValues({ ...exceededWireValues, rows: Number(value) })
        if (
          selectedWire.name &&
          isMaxCapacityExceeded(capacity, name, Number(value))
        ) {
          setMaxCapacityExceeded(true)
          return
        }
        setNumberOfWireRows(Number(value))
        setMaxCapacityExceeded(false)
        break
      }
      default:
        return
    }
  }

  const handleDeselectProduct = () => {
    if (
      selectedWire.name &&
      selectedWire.variant.capacity > 0 &&
      selectedWire.variant.capacity <
        exceededWireValues.length * exceededWireValues.rows
    ) {
      setSelectedWire({} as FenceProductVariant)
      setLengthOfFence(exceededWireValues.length)
      setNumberOfWireRows(exceededWireValues.rows)
    }
    if (
      selectedEnergizer.name &&
      selectedEnergizer.variant.capacity > 0 &&
      selectedEnergizer.variant.capacity <
        exceededWireValues.length * exceededWireValues.rows
    ) {
      setSelectedEnergizer({} as FenceProductVariant)
      setLengthOfFence(exceededWireValues.length)
      setNumberOfWireRows(exceededWireValues.rows)
    }
  }

  const selectProductWire = (articleNumber: string) => {
    const product = getSelectedProduct(allWireProducts, articleNumber)
    if (!product || deselectProduct(selectedWire, product)) {
      return setSelectedWire({} as FenceProductVariant)
    }
    setSelectedWire(product)
  }

  const calculateNumberOfPacksRequired = useCallback(
    (totalLengthOfFence: number): number => {
      if (
        Object.keys(selectedWire).length === 0 ||
        selectedWire.variant.length === 0
      )
        return 0
      const numberOfPacks = totalLengthOfFence / selectedWire.variant.length

      if (totalLengthOfFence % selectedWire.variant.length > 0) {
        return Math.ceil(numberOfPacks)
      }
      return numberOfPacks
    },
    [selectedWire]
  )

  useEffect(() => {
    const totalLengthOfFence = lengthOfFence * numberOfWireRows
    setTotalLengthOfFence(totalLengthOfFence)
    const numberOfPacks = calculateNumberOfPacksRequired(totalLengthOfFence)
    setNumberOfWirePacks(numberOfPacks)
  }, [
    calculateNumberOfPacksRequired,
    lengthOfFence,
    numberOfWireRows,
    selectedWire.variant,
    setNumberOfWirePacks,
    setTotalLengthOfFence
  ])

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" py={5} px={2}>
        <Box display="flex" flexDirection={isSmUp ? "row" : "column"}>
          <Box
            px={4}
            minWidth="50%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box className={classes.iconContainer}>
              <Suspense fallback={<FenceIconSkeleton />}>
                <FenceLengthIcon className={classes.fenceIcon} />
              </Suspense>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="end">
              <Box display="flex" flexDirection="column" mt={2}>
                <Typography variant="subheading1" className={classes.formLabel}>
                  {fenceCalculatorLabels.fencingMaterialLength}
                  <span>{fenceCalculatorLabels.fencingMaterialLengthUnit}</span>
                </Typography>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                value={lengthOfFence.toString().replace(/^[0]+/g, "0")}
                onChange={handleChange}
                name="lengthOfFence"
                type="number"
                disabled={showMap}
                inputProps={{
                  "aria-label": fenceCalculatorLabels.fencingMaterialLength
                }}
              />
            </Box>
          </Box>

          {/* Map feature should not be a part of the MVP version */}
          {displayMapFeature && (
            <>
              <Grid container item xs={12} justifyContent="center">
                <Button
                  variant="secondary"
                  onClick={() => setShowMap((prev) => !prev)}
                >
                  {showMap ? "Close map" : "Measure on a map"}
                </Button>
              </Grid>
              {showMap && (
                <Grid item xs={12}>
                  <Grid className={classes.mapContainer} container>
                    <Grid className={classes.infoWindow} item xs={12}>
                      <Suspense fallback={<MapLoadingSpinner />}>
                        <FenceMap />
                      </Suspense>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          <Box
            px={4}
            minWidth="50%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box className={classes.iconContainer}>
              <Suspense fallback={<FenceIconSkeleton />}>
                <FenceWiresIcon className={classes.fenceIcon} />
              </Suspense>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="end">
              <Box mt={2}>
                <Typography variant="subheading1" className={classes.formLabel}>
                  {fenceCalculatorLabels.fencingMaterialNumberOfWires}
                </Typography>
              </Box>
              <TextField
                select
                value={numberOfWireRows}
                onChange={handleChange}
                name="numberOfWireRows"
                fullWidth
                variant="outlined"
                inputProps={{
                  "aria-label":
                    fenceCalculatorLabels.fencingMaterialNumberOfWires
                }}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
              </TextField>
            </Box>
          </Box>
        </Box>
        <AlertStepper
          alertMessage="Some of your selected products are exceeding max capacity with this change. Would you like to deselect these products?"
          confirmButtonText={fenceCalculatorLabels.confirm}
          cancelButtonText={fenceCalculatorLabels.cancel}
          onResetStepper={handleDeselectProduct}
          confirmChangeOpen={maxCapacityExceeded}
          onSetConfirmChangeOpen={() => setMaxCapacityExceeded(false)}
        />

        {totalLengthOfFence !== 0 && (
          <Box mt={2} display="flex" flexDirection={isSmUp ? "row" : "column"}>
            <Typography className={classes.infoLabel}>
              {fenceCalculatorLabels.fencingMaterialTotalRequired}
              <span>
                {totalLengthOfFence} {fenceCalculatorLabels.metresSuffix}
              </span>
            </Typography>
            <Typography className={classes.lightItalicFont}>
              ({lengthOfFence} {fenceCalculatorLabels.metresSuffix} x{" "}
              {numberOfWireRows} {fenceCalculatorLabels.wiresSuffix})
            </Typography>
          </Box>
        )}
      </Box>
      <Box style={{ background: themeColors.primaryLightCyan }}>
        <Typography className={classes.chooseProductLabel}>
          {fenceCalculatorLabels.fencingMaterialChoose}
        </Typography>
        <ProductsContainer
          products={allWireProducts}
          selectedProduct={selectedWire}
          onSelectProduct={selectProductWire}
          currencySymbol={currencySymbol}
        />
        {Object.keys(selectedWire).length !== 0 &&
          totalLengthOfFence !== 0 &&
          numberOfWireRows !== 0 && (
            <Box pt={3} px={2}>
              <Typography className={classes.infoLabel}>
                {fenceCalculatorLabels.fencingMaterialNumberOfUnits}
                <span>{numberOfWirePacks}</span>
              </Typography>
            </Box>
          )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    mapContainer: {
      height: "70vh",
      marginBottom: spacing(2),
      width: "100%",
      [breakpoints.down("sm")]: {
        height: "25vh"
      }
    },
    infoWindow: {
      "& .gm-style .gm-style-iw": {
        background: themeColors.offWhite,
        borderRadius: 0,
        "& .gm-style-iw-d": {
          overflow: "auto !important",
          padding: `0 ${spacing(1.5)}px ${spacing(1.5)}px 0`
        }
      },
      "& .gm-style-iw-t::after": {
        background: themeColors.offWhite
      }
    },
    formLabel: {
      fontWeight: 700,
      color: themeColors.black,
      "& span": {
        fontSize: ".875rem",
        marginLeft: ".25rem"
      }
    },
    chooseProductLabel: {
      color: themeColors.black,
      padding: spacing(3, 2),
      fontSize: "1.25rem",
      lineHeight: 1.2,
      fontWeight: 700
    },
    infoLabel: {
      fontSize: ".875rem",
      color: themeColors.primaryBaseBlue,
      fontWeight: 700,
      "& span": {
        color: themeColors.primaryBlue,
        marginLeft: spacing(0.5),
        [breakpoints.up("sm")]: {
          margin: spacing(0, 0.5)
        }
      }
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center"
    },
    fenceIcon: {
      width: 150,
      height: 92
    },
    lightItalicFont: {
      fontSize: "0.875rem",
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: 1.4
    }
  })
)

export default FenceMaterial
