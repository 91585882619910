import { Link, useNavigate } from "react-router-dom"
import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import { blockDisplay } from "utils/blockDisplay"
import { isIE } from "utils/UserAgent"

import BlockGrid from "common/BlockGrid"
import Button from "common/Button"
import { ConditionalWrapper } from "common/ConditionalWrapper"
import LinkButton from "common/LinkButton"
import ResponsiveImage from "common/ResponsiveImage"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import ArrowRight from "icons/ArrowRight"
import { ExploreBlockContent } from "./types"

type Props = BlockProps<ExploreBlockContent>

const alignment = {
  top: "Top",
  bottom: "Bottom",
  center: "Center"
}
const ExploreBlock: React.FC<Props> = ({ content, blockInfo, pageInfo }) => {
  const classes = useStyles()
  const _display = blockDisplay(blockInfo)
  const navigate = useNavigate()

  const { title, text, image, link, horizontalImageAlignment, linkText } =
    content

  const isIe = isIE()
  const explorePage = "ExplorePage"
  const isExplorePage = pageInfo && pageInfo._type === explorePage
  const xhtmlCheck = text && text.startsWith("<")

  return (
    <Box display="flex" alignItems="center" flexDirection="column" width="100%">
      <Box width="100%">
        {image && !isIe && (
          <ConditionalWrapper
            condition={link ? true : false}
            wrapper={(children) => <Link to={link}>{children}</Link>}
          >
            <ResponsiveImage
              className={classNames(classes.media, {
                [classes.alignTop]:
                  horizontalImageAlignment &&
                  horizontalImageAlignment.text === alignment.top,
                [classes.alignCenter]:
                  horizontalImageAlignment &&
                  horizontalImageAlignment.text === alignment.center,
                [classes.alignBottom]:
                  horizontalImageAlignment &&
                  horizontalImageAlignment.text === alignment.bottom,
                [classes.showPointer]: link
              })}
              containerProps={{
                className: classNames(classes.mediaContainer, {
                  [classes.oneThirdMedia]: _display.oneThird,
                  [classes.exploreMediaCard]: isExplorePage && _display.oneThird
                })
              }}
              image={image}
              onClick={() => {
                if (link) {
                  navigate(link)
                }
              }}
            />
          </ConditionalWrapper>
        )}
        <BlockGrid
          container
          direction="column"
          className={classes.bodyContentContainer}
        >
          <ConditionalWrapper
            condition={link ? true : false}
            wrapper={(children) => <Link to={link}>{children}</Link>}
          >
            <Typography
              className={classNames(classes.headline, {
                [classes.showPointer]: link
              })}
              variant="headline3"
            >
              {title}
            </Typography>
          </ConditionalWrapper>
          {xhtmlCheck ? (
            <Box className={classes.preamble}>
              <ThemeXhtmlString content={text} />
            </Box>
          ) : (
            <Typography className={classes.preamble} variant="body1">
              {text}
            </Typography>
          )}
          {linkText && link && (
            <Box display="flex">
              <LinkButton link={link}>
                <Button endIcon={<ArrowRight />} variant="text">
                  {linkText}
                </Button>
              </LinkButton>
            </Box>
          )}
        </BlockGrid>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(
  ({
    breakpoints,
    spacing,
    common: { aspectRatio21x9, aspectRatio16x9, aspectRatio4x3 }
  }) => ({
    mediaContainer: {
      position: "relative",
      aspectRatio: aspectRatio21x9,
      flex: 0
    },
    oneThirdMedia: {
      aspectRatio: aspectRatio16x9
    },
    exploreMediaCard: {
      aspectRatio: aspectRatio4x3
    },
    media: {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover"
    },
    showPointer: {
      cursor: "pointer"
    },
    bodyContentContainer: {
      height: "100%",
      width: "100%",
      padding: `${spacing(2)}px 0`,
      [breakpoints.up("sm")]: {
        padding: `${spacing(2)}px 0`
      }
    },
    headline: {
      lineHeight: `${spacing(6)}px`
    },
    alignTop: {
      objectPosition: "top"
    },
    alignCenter: {
      objectPosition: "center"
    },
    alignBottom: {
      objectPosition: "bottom"
    },
    preamble: {
      lineHeight: `${spacing(3)}px`,
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 4,
      "-webkit-box-orient": "vertical"
    }
  })
)

export default ExploreBlock
