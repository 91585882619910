import { LineItem } from "@starrepublic/epi/api/carts"
import {
  ProductContent,
  ProductsApiResponse
} from "@starrepublic/epi/api/products"

import { Order } from "api/orders"
import { LineItemProperties } from "models/Cart"
import Product from "models/Products"
import { SearchModel } from "store/DealerSearchStore"
import marketStore from "store/MarketStore"

export const PRODUCT_CONTENT_TYPE = "WebProduct"
export const CATALOG_CONTENT_TYPE = "WebNode"
export const CHECKOUT_COMPLETE_TYPE = "CheckoutCompletePage"

export const PageTypes: availablePages = {
  startPageType: "",
  productPageType: "",
  categoryPageType: "",
  checkoutPageType: "",
  cookiesPageType: "",
  checkoutConfirmationPageType: ""
}

export type gaDataType = {
  belongToList: string | undefined
  index: number
}

type availablePages = {
  startPageType: string
  productPageType: string
  categoryPageType: string
  checkoutPageType: string
  cookiesPageType: string
  checkoutConfirmationPageType?: string
}

const selectedMarket =
  marketStore.state &&
  marketStore.state.markets.data &&
  marketStore.state.markets.data.find((x) => x.isSelected)
const selectedLanguage = selectedMarket ? selectedMarket.selectedLanguage : "en"

const resetGtmProductsData = () => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "resetGtmProductsData",
      products: undefined,
      page_type: undefined
    })
  }
}

const resetGtmOrderData = () => {
  const ecom = {
    event: "reset_gtm_order_data",
    transactionId: undefined,
    transactionAffiliation: undefined,
    transactionTotal: undefined,
    transactionTax: undefined,
    transactionShipping: undefined,
    transactionProducts: undefined
  }

  window.gtm_data_sources.order = null

  return window.dataLayer.push(ecom)
}

const getTransactionHitData = () => {
  let i = 0
  const transactionProducts: any[] = []
  const ecom = {
    transactionProducts,
    event: "transaction",
    lang: window.gtm_data_sources.content._viewLang,
    transactionId: window.gtm_data_sources.order.orderGroupId, // Transaction ID - Type:String - Required
    transactionAffiliation: "DeLaval", // store name - Type:String - Optional to use
    transactionTotal: window.gtm_data_sources.order.total, // total revenue - Type:Numeric - Required
    transactionTax: window.gtm_data_sources.order.taxTotal, // Tax amount for transaction - Type:Numeric - Optional to use
    transactionShipping: window.gtm_data_sources.order.shippingTotal // Shipping cost - Type:Numeric - Optional to use
  }
  for (i; i < window.gtm_data_sources.order.lineItems.length; i++) {
    const lineItem: any = {
      sku: window.gtm_data_sources.order.lineItems[i].code, // Product SKU - Type:String - Required
      name: window.gtm_data_sources.order.lineItems[i].displayName, // Product Name - Type:String - Required
      category: "DeLaval Products", // Product Category - Type:String - Optional to use
      price: window.gtm_data_sources.order.lineItems[i].placedPrice, // Product Price - Type:Numeric - Required
      quantity: window.gtm_data_sources.order.lineItems[i].quantity // Product Quantity - Type:Numeric - Required
    }
    ecom.transactionProducts.push(lineItem)
  }
  return ecom
}

const getProductHitData = () => {
  resetGtmProductsData()

  const data: any = {}
  data.event = "virtual_pageview"
  data.page_type = window.gtm_data_sources.current_page_type
  data.products = {}
  data.products.remarketing_product_ids = []
  data.urlPath = getUrlPath()
  data.title = window.gtm_data_sources.content.siteTitle

  data.products.remarketing_product_ids.push(
    window.gtm_data_sources.content.code
  )

  data.products.product_category =
    window.gtm_data_sources.content.productCategory || "N/A"
  data.products.product_category_id =
    window.gtm_data_sources.content.productCategoryId || "N/A"
  data.products.product_group =
    window.gtm_data_sources.content.productGroup || "N/A"
  data.products.product_group_id =
    window.gtm_data_sources.content.productGroup || "N/A"
  data.products.label = window.gtm_data_sources.content.label || "N/A"
  data.products.product_display_price = window.gtm_data_sources.content
    .productDisplayPrice || { amount: 0, currency: "N/A" }
  data.lang = window.gtm_data_sources.content._viewLang || "N/A"

  data.products.google_tag_parmas = {}
  data.products.google_tag_parmas.ecomm_pagetype = "product"
  data.products.google_tag_parmas.ecomm_prodid =
    window.gtm_data_sources.content.code
  data.products.google_tag_parmas.ecomm_totalvalue =
    window.gtm_data_sources.content.productDisplayPrice &&
    window.gtm_data_sources.content.productDisplayPrice.amount

  return data
}

const getUrlPath = () => document.location.pathname + document.location.search

const getGeneralHitData = () => {
  resetGtmProductsData()
  const data: any = {}
  data.event = "virtual_pageview"
  data.page_type = window.gtm_data_sources.current_page_type
  data.products = undefined
  data.lang = window.gtm_data_sources.content._viewLang
  data.urlPath = getUrlPath()
  data.title = window.gtm_data_sources.content.siteTitle

  return data
}

const getCheckoutPageHitData = () => {
  resetGtmProductsData()

  let i = 0
  const data: any = {}
  data.event = "virtual_pageview"
  data.page_type = window.gtm_data_sources.current_page_type
  data.products = {}
  data.products.remarketing_product_ids = []
  data.products.cart = {
    content_ids: [],
    total_cart_value: window.gtm_data_sources.cart.subTotal,
    currency: window.gtm_data_sources.cart.currency,
    num_items: window.gtm_data_sources.cart.lineItems.length
  }
  data.urlPath = getUrlPath()
  data.title = window.gtm_data_sources.content.siteTitle

  for (i = 0; i < window.gtm_data_sources.cart.lineItems.length; i++) {
    data.products.cart.content_ids.push(
      window.gtm_data_sources.cart.lineItems[i].code
    )
    data.products.remarketing_product_ids.push(
      window.gtm_data_sources.cart.lineItems[i].code
    )
  }

  return data
}

const buildHit = () => {
  let data: any = {}
  let transactionData: any = {}

  switch (window.gtm_data_sources.current_page_type) {
    case PRODUCT_CONTENT_TYPE:
      data = getProductHitData()
      window.dataLayer.push(data)
      window.gtm_data_sources.pageViewSent = true
      break

    case CHECKOUT_COMPLETE_TYPE:
      if (window.gtm_data_sources.order !== null) {
        data = getGeneralHitData()
        transactionData = getTransactionHitData()
        window.dataLayer.push(data, transactionData)
        resetGtmOrderData()
        window.gtm_data_sources.pageViewSent = true
      }
      break

    case "Checkout":
      if (window.gtm_data_sources.cart !== null) {
        data = getCheckoutPageHitData()
        window.dataLayer.push(data)
        window.gtm_data_sources.pageViewSent = true
      }
      break
    case CATALOG_CONTENT_TYPE:
    default:
      data = getGeneralHitData()
      window.dataLayer.push(data)
      window.gtm_data_sources.pageViewSent = true
      break
  }
}

export const init = (GAid: string, Pages: availablePages) => {
  window.dataLayer = window.dataLayer || []

  PageTypes.startPageType = Pages.startPageType
  PageTypes.productPageType = Pages.productPageType
  PageTypes.categoryPageType = Pages.categoryPageType
  PageTypes.checkoutPageType = Pages.checkoutPageType
  PageTypes.cookiesPageType = Pages.cookiesPageType

  if (Pages.checkoutConfirmationPageType) {
    PageTypes.checkoutConfirmationPageType = Pages.checkoutConfirmationPageType
  }

  window.analytics = {
    id: GAid,
    data_sources: {
      current_path: null,
      current_page_type: null,
      content: null,
      product: null,
      order: null,
      cart: null,
      pageViewSent: false,
      currency: window.EPI_CURRENT_CURRENCY || null
    }
  }

  window.gtm_data_sources = {
    current_path: null,
    current_page_type: null,
    content: null,
    product: null,
    order: null,
    cart: null,
    pageViewSent: false,
    currency: window.EPI_CURRENT_CURRENCY || null
  }
}

export const setContentData = (data: any) => {
  if (data && data._route !== window.gtm_data_sources.current_path) {
    window.gtm_data_sources.product = null
    window.gtm_data_sources.current_page_type = data._type
    window.gtm_data_sources.current_path = data._route
    window.gtm_data_sources.content = data
    window.gtm_data_sources.pageViewSent = false
    buildHit()
  }
}

export const setTransactionData = (data: any) => {
  window.gtm_data_sources.order = data
  buildHit()
}

export const setCartData = (data: any) => {
  window.gtm_data_sources.cart = data
  buildHit()
}

export const setFbAddToCart = (item: any) => {
  const event = {
    event: "fb_addToCart",
    fb_addToCart: {
      content_ids: [item.code],
      content_type: "product",
      value: item.amount
    }
  }
  const currency = window.gtm_data_sources.currency
  currency
    ? window.dataLayer.push({
        ...event,
        fb_addToCart: { ...event.fb_addToCart, currency }
      })
    : window.dataLayer.push(event)
}

export const setFbAddToWishlist = (item: any) => {
  const event = {
    event: "fb_addToWishlist",
    fb_addToWishlist: {
      content_ids: [item.code],
      content_type: "product_group",
      value: item.placedPrice
    }
  }
  const currency = window.gtm_data_sources.currency
  currency
    ? window.dataLayer.push({
        ...event,
        fb_addToWishlist: { ...event.fb_addToWishlist, currency }
      })
    : window.dataLayer.push(event)
}

/*

  ENHANCED E-COM EVENTS!

*/

export const addToCart = (item: any, currencyCode?: string) => {
  const event = {
    event: "addToCart",
    ecommerce: {
      currency_code: currencyCode,
      add: {
        products: [
          {
            id: item.code,
            name: item.name,
            price: item.amount,
            quantity: item.quantity,
            category: "" // item.category
          }
        ]
      }
    }
  }

  window.dataLayer.push(event)
  resetEcommerceActions()
}
export const addToShoppinglist = (item: any, currencyCode?: string) => {
  const event = {
    event: "addToShoppinglist",
    ecommerce: {
      currency_code: currencyCode,
      add: {
        products: [
          {
            id: item.code,
            name: item.name,
            category: "" // item.category
          }
        ]
      }
    }
  }

  window.dataLayer.push(event)
  resetEcommerceActions()
}

export const logSapCustomerNumber = (sapCustomerNum: string) => {
  const event = {
    event: "logSapCustomerNumber",
    sapCustomerNumber: sapCustomerNum
  }

  window.dataLayer.push(event)
}

export const resetEcommerceActions = () => {
  const event = {
    event: "resetEcommerceData",
    ecommerce: undefined
  }

  window.dataLayer.push(event)
}

export const checkoutStepAction = (
  step: number,
  products?: LineItem<LineItemProperties>[]
) => {
  const event = {
    event: "checkout",
    ecommerce: {
      checkout: {
        action_field: {
          step
        },
        products: [] as any[]
      }
    }
  }

  if (products) {
    products.forEach((item) => {
      event.ecommerce.checkout.products.push({
        id: item.code,
        name:
          item["properties"] && item["properties"]["productDisplayName"]
            ? item["properties"]["productDisplayName"]
            : "Missing name",
        price: item.placedPrice,
        quantity: item.quantity,
        category: "" // item["properties"] && item["properties"]["categoryStructure"]
        //   ? item["properties"]["categoryStructure"]
        //   : "Missing category"
      })
    })
  }

  window.dataLayer.push(event)
  resetEcommerceActions()
}

export const removeFromCart = (item: any, currencyCode?: string) => {
  const event = {
    event: "removeFromCart",
    ecommerce: {
      currencyCode,
      remove: {
        products: [
          {
            id: item.code,
            name: item.name,
            price: item.amount,
            quantity: item.quantity,
            category: "" // item.category
          }
        ]
      }
    }
  }

  window.dataLayer.push(event)
  resetEcommerceActions()
}

export const enhancedTransaction = (order: Order, currencyCode?: string) => {
  const tax = order["properties"] && order["properties"]["sapVatAmount"]
  const event = {
    event: "eec_transaction",
    ecommerce: {
      currency_code: currencyCode,
      purchase: {
        action_field: {
          tax,
          id: order["orderGroupId"],
          affiliation: "DeLaval",
          revenue:
            order["properties"] && order["properties"]["totalCostIncVat"]
              ? order["properties"]["totalCostIncVat"]
              : 0,
          shipping: order.shippingTotal
        },
        products: [] as any[]
      }
    }
  }
  order.lineItems.forEach((item) => {
    event.ecommerce.purchase.products.push({
      id: item.code,
      name:
        item["properties"] && item["properties"]["productDisplayName"]
          ? item["properties"]["productDisplayName"]
          : "Missing name",
      price: item.placedPrice,
      quantity: item.quantity,
      category: "" // item["properties"] && item["properties"]["categoryStructure"]
      // ? item["properties"]["categoryStructure"]
      // : "Missing category"
    })
  })

  window.dataLayer.push(event)
  resetEcommerceActions()
}

export const categoryImpression = (
  data: ProductsApiResponse<Product>,
  categoryTitle: string,
  currencyCode?: string
) => {
  const event = {
    event: "category_listing",
    ecommerce: {
      currency_code: currencyCode,
      impressions: [] as any[]
    }
  }

  data.value.forEach((product) => {
    const price =
      product.displayLowestPrice && product.displayLowestPrice.amount

    const categoryName =
      categoryTitle && categoryTitle.replace(/(\r\n|\n|\r)/gm, "")

    event.ecommerce.impressions.push({
      price,
      id: product.displayLowestPriceArticleNumber,
      name: product.displayName,
      brand: "DeLaval",
      list: categoryName,
      category: "" // product.categoryStructure
    })
  })

  window.dataLayer.push(event)
  resetEcommerceActions()
}

export const productListImpression = (
  products: Product[],
  currencyCode?: string
) => {
  const event = {
    event: "product_listing",
    ecommerce: {
      currency_code: currencyCode,
      impressions: [] as any[]
    }
  }

  products.forEach((product) => {
    const price =
      product.displayLowestPrice && product.displayLowestPrice.amount

    event.ecommerce.impressions.push({
      price,
      id: product.displayLowestPriceArticleNumber,
      name: product.displayName,
      brand: "DeLaval",
      category: "" // product.categoryStructure
    })
  })

  window.dataLayer.push(event)
  resetEcommerceActions()
}

export const productVariantDetail = (
  product: ProductContent<Product>,
  variantId: string
) => {
  const event = {
    event: "product_detail_view",
    ecommerce: {
      detail: {
        products: [
          {
            id: variantId,
            name: product.displayName,
            price:
              product.displayLowestPrice && product.displayLowestPrice.amount
                ? product.displayLowestPrice.amount
                : 0,
            brand: "DeLaval",
            category: "" // product.categoryStructure
          }
        ]
      }
    }
  }

  window.dataLayer.push(event)
  resetEcommerceActions()
}

export const productDetailClick = (product: Product, gaData?: gaDataType) => {
  let belongsTo = window.gtm_data_sources.current_page_type
  let positionInList = 0

  if (
    gaData &&
    gaData.belongToList !== undefined &&
    gaData.index !== undefined
  ) {
    belongsTo = gaData.belongToList !== null ? gaData.belongToList : belongsTo
    positionInList = gaData.index + 1
  }

  const listName = belongsTo.replace(/(\r\n|\n|\r)/gm, "")
  const event = {
    event: "productClick",
    ecommerce: {
      click: {
        actionField: {
          list: listName
        },
        products: [
          {
            id: product.displayLowestPriceArticleNumber,
            name: product.displayName,
            price:
              product.displayLowestPrice && product.displayLowestPrice.amount
                ? product.displayLowestPrice.amount
                : 0,
            brand: "DeLaval",
            category: "" // product.categoryStructure
          }
        ]
      }
    }
  }

  window.dataLayer.push(event)
  resetEcommerceActions()
}

export const foundDealer = (data: {
  searchModel: SearchModel
  results: number
}) => {
  const event = {
    event: "findYourDealer",
    dealerSearchQuery: data.searchModel.searchTerm, //The query used
    results: data.results // The number of results returned
  }

  window.dataLayer.push(event)
}

export const fenceCalculatorStepperClick = (
  eventCategory: string,
  eventLabel: string,
  eventValue: number
) => {
  const event = {
    event: "custom_event",
    event_category: "Calculators",
    event_subcategory: "Fence_calculator_navigation",
    event_action: eventLabel, //"submit/next step/select item/input value", // Variable
    event_label: eventCategory, // Variable
    event_status: "success",
    event_message:
      "success - a user has successfully interacted with a calculator",
    event_context_1: `step: ${eventValue}` // Variable
  }

  const array = window.dataLayer
  const index = array.findIndex((obj) => obj.eventCategory === eventCategory)

  if (index !== -1) {
    array.splice(index, 1, event)
  } else {
    window.dataLayer.push(event)
  }
}

export type EventTrackingMenuType = {
  event_category:
    | "Mega menu"
    | "Footer"
    | "Explore menu"
    | "Hamburger menu"
    | "Tree view menu"
  event_action: string
  event_message: string
  event_context_1: string
  event_context_2: string
  event_context_3: string
}

// <----------------- Event menu trackers - Start -----------------> //
const eventTrackingMenu = (data: EventTrackingMenuType) => {
  const selectedMarket =
    marketStore.state &&
    marketStore.state.markets.data &&
    marketStore.state.markets.data.find((x) => x.isSelected)
  const selectedLanguage = selectedMarket
    ? selectedMarket.selectedLanguage
    : "en"

  const event = {
    event: "custom_event",
    event_category: data.event_category, // "[Type of navigation Mega menu/Footer/extra menus]"
    event_action: data.event_action, //"[recursive navigation path]",
    event_label: window.location.href, // "[page url]",
    event_status: "success",
    event_message: data.event_message,
    event_context_1: data.event_context_1, // "[menu layout hamburger|mega menu]",
    event_context_2: data.event_context_2, //"[link hierarchy depth 1 - n]",
    event_context_3: data.event_context_3, //"[click text]",
    market: selectedLanguage // "[current market]"
  }
  window.dataLayer.push(event)
}

const recursiveNavigationPathFormat = (path: string) => {
  return path
    .split("/")
    .slice(2)
    .filter((str) => str !== "")
    .join(" > ")
}

export const eventMegaMenuTracking = (
  navigationPath: string,
  depth: number,
  clickText: string
) => {
  const data = {
    event_category: "Mega menu",
    event_action: recursiveNavigationPathFormat(navigationPath),
    event_message: "success - a link in the top navigation was followed",
    event_context_1: "Menu layout: Mega menu desktop",
    event_context_2: `Link hierarchy depth 1 - ${depth}`,
    event_context_3: clickText
  } as EventTrackingMenuType
  eventTrackingMenu(data)
}

export const eventTreeViewTracking = (
  navigationPath: string,
  depth: number,
  clickText: string
) => {
  const data = {
    event_category: "Tree view menu",
    event_action: recursiveNavigationPathFormat(navigationPath),
    event_message: "success - a link in the product tree view was followed",
    event_context_1: "Menu layout: Tree view desktop",
    event_context_2: `Link hierarchy depth 1 - ${depth}`,
    event_context_3: clickText
  } as EventTrackingMenuType
  eventTrackingMenu(data)
}

export const eventHamburgerMenuTracker = (
  navigationPath: string,
  depth: number,
  clickText: string
) => {
  const data = {
    event_category: "Hamburger menu",
    event_action: recursiveNavigationPathFormat(navigationPath),
    event_message: "success - a link in the hamburger menu was followed",
    event_context_1: "Menu layout: Hamburger menu mobile",
    event_context_2: `Link hierarchy depth 1 - ${depth}`,
    event_context_3: clickText
  } as EventTrackingMenuType
  eventTrackingMenu(data)
}

export const eventExploreMenuTracker = (
  navigationPath: string,
  depth: number,
  clickText: string
) => {
  const data = {
    event_category: "Explore menu",
    event_action: recursiveNavigationPathFormat(navigationPath),
    event_message: "success - a link in the explore menu was followed",
    event_context_1: "Menu layout: Explore menu desktop",
    event_context_2: `Link hierarchy depth 1 - ${depth}`,
    event_context_3: clickText
  } as EventTrackingMenuType
  eventTrackingMenu(data)
}

export const eventExploreMenuMobileTracker = (
  navigationPath: string,
  depth: number,
  clickText: string
) => {
  const data = {
    event_category: "Explore menu",
    event_action: recursiveNavigationPathFormat(navigationPath),
    event_message: "success - a link in the explore menu was followed",
    event_context_1: "Menu layout: Explore menu mobile",
    event_context_2: `Link hierarchy depth 1 - ${depth}`,
    event_context_3: clickText
  } as EventTrackingMenuType
  eventTrackingMenu(data)
}

export const eventFooterTracker = (
  navigationPath: string,
  depth: number,
  clickText: string
) => {
  const data = {
    event_category: "Footer",
    event_action: recursiveNavigationPathFormat(navigationPath),
    event_message: "success - a link in the footer was followed",
    event_context_1: "Menu layout: Footer",
    event_context_2: `Link hierarchy depth 1 - ${depth}`,
    event_context_3: clickText
  } as EventTrackingMenuType
  eventTrackingMenu(data)
}
// <----------------- Event menu trackers - End -----------------> //

// <----------------- Event wishlist and PDF download trackers - Start -----------------> //
export const eventWishListPdfTracker = (
  actionClicked: string,
  url: string,
  quantity: number
) => {
  const event = {
    event: "custom_event",
    event_category: "Wishlist",
    event_subcategory: "favorite list print",
    event_action: actionClicked, //"pdf/print button clicked"
    event_label: url, //"[page url]",
    event_status: "success",
    event_message:
      "success - a user has successfuly printed/saved their favorite list",
    event_context_1: quantity //"quantity of products"
  }
  window.dataLayer.push(event)
}
// <----------------- Event wishlist and PDF download trackers - End -----------------> //

// <----------------- Event pdf trackers - Start -----------------> //
export const eventPdfTracker = (url: string) => {
  const event = {
    event: "custom_event",
    event_category: "file download",
    event_subcategory: "brochure download",
    event_action: "type of link (pdf)", // (image/text/pdf etc.)
    event_label: url, // [brochure url]
    event_status: "success",
    event_message: "success - a user has downloaded a brochure"
  }
  window.dataLayer.push(event)
}

// Tracks PDF link clicks, ensuring tracking is associated with the `<a>` tag, not `<strong>` text.
export const handlePdfTracker = (event: MouseEvent) => {
  let target = event.target as HTMLElement
  // If the target is a <strong> tag, find the closest <a> tag
  if (target.tagName === "STRONG") {
    target = target.closest("A") || target
  }
  const getUrl = target.getAttribute("href")
  if (target.tagName === "A" && getUrl) {
    eventPdfTracker(getUrl)
  }
}
// <----------------- Event pdf trackers - End -----------------> //

export const createAccountSuccess = () => {
  const event = {
    event: "createAccountSuccess"
  }

  window.dataLayer.push(event)
}
