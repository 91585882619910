import { Form as FormikForm, FormikProps } from "formik"

import { makeStyles } from "@material-ui/core/styles"

import {
  SapCustomerRegistrationModel,
  UserRegistrationModel
} from "models/User"
import LoginFormSelector from "pages/Login/LoginFormSelector"
import AccountInformation from "./AccountInformation"
import NewCustomer from "./NewCustomer"

type Props = FormikProps<SapCustomerRegistrationModel | UserRegistrationModel>

const RegisterForm: React.FC<Props> = ({ values, isSubmitting, errors }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <FormikForm id="register-form" noValidate className={classes.form}>
        <AccountInformation
          selectedCountry={
            (values as UserRegistrationModel).billingAddress.countryCode
          }
        />
        <NewCustomer
          selectedCountry={
            (values as UserRegistrationModel).billingAddress.countryCode
          }
          isSubmitting={isSubmitting}
          hasErrors={Object.keys(errors).length > 0}
        />
      </FormikForm>
      <LoginFormSelector />
    </div>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    padding: spacing(3),
    [breakpoints.down("sm")]: {
      padding: spacing(3)
    }
  },
  form: {
    width: "100%"
  }
}))

export default RegisterForm
