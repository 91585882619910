import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const Mail: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 25">
    <path
      d="M20 4.857H4c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2Zm0 4-8 5-8-5v-2l8 5 8-5v2Z"
      fill="#currentColor"
    />
  </SvgIcon>
)

export default Mail
