import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"
import ArrowForward from "@material-ui/icons/ArrowForward"

import LinkText from "common/LinkText"
import Logo from "common/Logo"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import PageStore from "store/PageStore"
import SiteSettingStore from "store/SiteSettingStore"

const CheckoutHeader: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { previousRoute }
  } = useStore(PageStore)

  const {
    state: { links }
  } = useStore(SiteSettingStore)

  const backLink = previousRoute || (links.home && links.home.href) || "/"

  return (
    <div className={classes.rootExpander}>
      <Wrapper
        containerClassName={classes.root}
        className={classes.rootContainer}
      >
        <LinkText
          id="back-to-site-link"
          link={backLink}
          className={classes.continueShoppingButton}
        >
          <Hidden smDown>
            <Typography
              variant="link3"
              className={classes.continueShoppingTypo}
            >
              <ArrowForward className={classes.arrowForwardIcon} />
              {t("checkout.continue_shopping")}
            </Typography>
          </Hidden>
          <Hidden mdUp>
            <ArrowForward className={classes.arrowForwardIcon} />
          </Hidden>
        </LinkText>
        <Logo />
      </Wrapper>
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  rootExpander: {
    height: 80
  },
  rootContainer: {
    backgroundColor: themeColors.primaryBlue,
    width: "100%",
    height: "100%"
  },
  root: {
    padding: "14px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative"
  },
  continueShoppingButton: {
    position: "absolute",
    left: 34,
    zIndex: 999,
    padding: 0
  },
  continueShoppingTypo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: themeColors.white
  },
  arrowForwardIcon: {
    color: themeColors.white,
    transform: "rotate(180deg)",
    marginLeft: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: spacing(1),
    height: 18,
    width: 18
  }
}))

export default CheckoutHeader
