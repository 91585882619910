import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Formik } from "formik"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"

import { ActivateSubscriptionPayload } from "api/subscriptions"
import Spinner from "common/Spinner"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import Subscription, { Farm } from "models/DigitalService"
import snackbarStore from "store/SnackbarStore"
import subscriptionsStore from "store/SubscriptionsStore"
import validationSchema from "./Form/validationSchema"
import FarmForm from "./Form"

type Props = {
  subscription: Subscription
}

const NEW_FARM_ID = ""
const ACTIVATION_REQUESTED = "ACTIVATION_REQUESTED"
const PENDING = "PENDING"

const initialValues = {
  name: "",
  streetAddress: "",
  country: "",
  city: "",
  zipCode: ""
}

const ConnectFarmField: React.FC<Props> = ({ subscription }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [selectedFarm, setSelectedFarm] = useState(NEW_FARM_ID)
  const formRef = useRef<Formik<any>>(null)

  const AddNewFarmString = t("subscription.add_new_farm")

  const {
    state: { myFarms, editingSubscription, activatedSubscription },
    actions: {
      activateSubscription,
      setOpen,
      getSubscriptions,
      resetActivatedSubscription
    }
  } = useStore(subscriptionsStore)

  const {
    actions: { openSnackbar }
  } = useStore(snackbarStore)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFarm(event.target.value)
  }

  const handleActivation = async () => {
    if (!editingSubscription) {
      return
    }

    const subscriptionId = editingSubscription.subscriptionId
    const articleId = editingSubscription.digitalService.articleNumber

    let farm = {} as Farm
    if (selectedFarm === NEW_FARM_ID) {
      if (!formRef || !formRef.current) {
        return
      }
      if (
        Object.keys(formRef.current.state.errors).length > 0 ||
        Object.keys(formRef.current.state.touched).length !==
          Object.keys(initialValues).length
      ) {
        // Trigger forced validation
        formRef.current.submitForm()
        return
      }

      const newFarm = formRef.current.state.values

      farm.farmId = NEW_FARM_ID
      farm.name = newFarm.name
      farm.address = {
        streetAddress: newFarm.streetAddress,
        zipCode: newFarm.zipCode,
        city: newFarm.city,
        country: newFarm.country
      }
    } else {
      farm = myFarms!.data.find((f) => f.farmId === selectedFarm) as Farm
    }

    const payload = {
      subscriptionId,
      articleId,
      farm
    } as ActivateSubscriptionPayload

    await activateSubscription(payload)
  }

  useEffect(() => {
    if (myFarms.data && myFarms.data.length > 0) {
      setSelectedFarm(myFarms.data[0].farmId)
    }
  }, [myFarms.data, setSelectedFarm])

  useEffect(
    () => {
      if (
        activatedSubscription.data &&
        activatedSubscription.data.status === ACTIVATION_REQUESTED
      ) {
        setOpen(false).then(() => {
          openSnackbar({
            options: { message: t("subscription.activation_requested") },
            type: "success"
          })
          getSubscriptions()
          resetActivatedSubscription()
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activatedSubscription.data]
  )

  return (
    <>
      <Box my={2} mx={3}>
        <Typography className={classes.detailsText}>
          {t("subscription.head_farm")}
        </Typography>
        {myFarms.loading ? (
          <Grid container justifyContent="center">
            <Spinner />
          </Grid>
        ) : (
          <Box py={2}>
            {!subscription.farm && (
              <TextField
                className={classes.textfield}
                id="outlined-select-farm"
                select
                InputProps={{
                  className: classes.input,

                  fullWidth: true
                }}
                InputLabelProps={{
                  className: classes.label
                }}
                value={selectedFarm}
                onChange={handleChange}
                SelectProps={{
                  native: true
                }}
                variant="outlined"
                disabled={subscription.status !== "PENDING"}
              >
                <option value={NEW_FARM_ID}>{AddNewFarmString}</option>
                {myFarms.data &&
                  myFarms.data.map((farm) => {
                    return (
                      <option
                        key={farm.farmId}
                        value={farm.farmId}
                      >{`${farm.name}, ${farm.address.streetAddress}, ${farm.address.zipCode} ${farm.address.city}, ${farm.address.country}`}</option>
                    )
                  })}
              </TextField>
            )}
          </Box>
        )}

        {!myFarms.loading && selectedFarm === NEW_FARM_ID && (
          <Formik
            ref={formRef}
            initialValues={initialValues}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onSubmit={() => {}}
            validateOnChange={false}
            validateOnBlur
            component={FarmForm}
            validationSchema={validationSchema(t)}
          />
        )}
      </Box>
      <Divider />
      <Box p={3}>
        <Box pb={3}>
          <Typography align="center" variant="body2">
            {t("subscription.text_explain_in_edit")}
          </Typography>
        </Box>
        {myFarms.error ||
          (activatedSubscription.error && (
            <Grid container justifyContent="center" alignItems="center">
              <Box pb={2}>
                <Typography variant="body1" className={classes.error}>
                  {t(`error.${(myFarms.error || activatedSubscription.error)
                    .toString()
                    .toLowerCase()}
                  `)}
                </Typography>
              </Box>
            </Grid>
          ))}
        {subscription.status === PENDING && (
          <Grid container justifyContent="center">
            <Button
              className={classes.requestButton}
              onClick={handleActivation}
              disabled={activatedSubscription.loading || myFarms.loading}
            >
              {activatedSubscription.loading ? (
                <ButtonLoaderIcon className={classes.loader} />
              ) : (
                <Typography
                  align="center"
                  className={classes.buttonText}
                  variant="link2"
                >
                  {t("subscription.request_start")}
                </Typography>
              )}
            </Button>
          </Grid>
        )}
      </Box>
    </>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    root: {},
    detailsText: {
      fontWeight: "bold"
    },
    input: {
      padding: spacing(1),
      boxSizing: "border-box",
      "& input": {
        fontWeight: 300,
        fontSize: 16,
        paddingLeft: 5,
        "&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus, ":
          {
            "-webkit-text-fill-color": themeColors.darkGray,
            "-webkit-box-shadow": " 0 0 0px 1000px transparent inset",
            transition: "background-color 5000s ease-in-out 0s"
          }
      },
      "& select": {
        "&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus, ":
          {
            "-webkit-text-fill-color": themeColors.darkGray,
            "-webkit-box-shadow": " 0 0 0px 1000px transparent inset",
            transition: "background-color 5000s ease-in-out 0s",
            paddingLeft: 5
          }
      }
    },
    label: {
      fontWeight: 300,
      fontSize: 16,
      color: themeColors.gray
    },
    textfield: {
      background: themeColors.offWhite,
      width: "100%"
    },
    requestButton: {
      margin: spacing(1),
      minWidth: 350,
      padding: spacing(2),
      backgroundColor: themeColors.primaryCyan,
      "&:hover": {
        backgroundColor: themeColors.primaryCyan
      },
      "& span": {
        color: themeColors.white
      },
      [breakpoints.down("xs")]: {
        minWidth: 140
      }
    },
    buttonText: {
      paddingRight: spacing(1)
    },
    removeButton: {
      border: `2px solid ${themeColors.primaryCyan}`,
      margin: spacing(1),
      minWidth: 200,
      padding: spacing(2),
      backgroundColor: themeColors.white,
      "&:hover": {
        backgroundColor: themeColors.white
      },
      "& span": {
        color: themeColors.primaryCyan
      },
      [breakpoints.down("xs")]: {
        minWidth: 140
      }
    },
    icon: {
      width: 12,
      marginRight: spacing(1)
    },
    loader: {
      height: 20
    },
    error: {
      color: themeColors.primaryRed
    }
  })
)

export default ConnectFarmField
