import { useEffect } from "react"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import useResource, { ResourceType } from "hooks/useResource"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import PaymentStore from "store/PaymentStore"
import SiteSettingStore from "store/SiteSettingStore"
import { AdyenCardState } from "./types"

import "./adyen.css"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { ENVIRONMENT } = window

const getOriginKey = () => {
  if (window.ADYEN_ORIGIN_KEY) {
    return window.ADYEN_ORIGIN_KEY
  }

  switch (window.location.origin) {
    case "https://inte.delaval.com":
      return process.env.REACT_APP_ADYEN_INTEGRATION_ORIGIN_KEY
    case "https://prep.delaval.com":
      return process.env.REACT_APP_ADYEN_PREPRODUCTION_ORIGIN_KEY
    case "https://www.delaval.com":
      return process.env.REACT_APP_ADYEN_PRODUCTION_ORIGIN_KEY
    case "https://store.delaval.com":
      return process.env.REACT_APP_ADYEN_PRODUCTION_ORIGIN_ALT_KEY
    default:
      return process.env.REACT_APP_ADYEN_DEV_ORIGIN_KEY
  }
}

const loadingContexts = {
  Production: process.env.REACT_APP_ADYEN_PRODUCTION_SDK_CONTEXT,
  Preproduction: process.env.REACT_APP_ADYEN_PREPRODUCTION_SDK_CONTEXT,
  Integration: process.env.REACT_APP_ADYEN_INTEGRATION_SDK_CONTEXT,
  Development: process.env.REACT_APP_ADYEN_DEV_SDK_CONTEXT
}
const sdkUrls = {
  Production: process.env.REACT_APP_ADYEN_PRODUCTION_SDK_URL,
  Preproduction: process.env.REACT_APP_ADYEN_PREPRODUCTION_SDK_URL,
  Integration: process.env.REACT_APP_ADYEN_INTEGRATION_SDK_URL,
  Development: process.env.REACT_APP_ADYEN_DEV_SDK_URL
}

const ORIGIN_KEY = getOriginKey()
const LOADING_CONTEXT = loadingContexts[ENVIRONMENT || "Development"]
const ADYEN_SDK_URL = sdkUrls[ENVIRONMENT || "Development"]

const Adyen: React.FC = () => {
  const classes = useStyles()
  const {
    state: { lang }
  } = useStore(SiteSettingStore)

  const [loaded, error] = useResource(ADYEN_SDK_URL, ResourceType.Script)

  const cardType = "card"
  const channel = "web"
  const { set: checkoutSet } = useCheckoutPaymentStore()
  const {
    state: {
      paymentMethods: { data: paymentMethodResponse }
    }
  } = useStore(PaymentStore)

  useEffect(
    () => {
      if (loaded && !error && "AdyenCheckout" in window) {
        const amount = {}
        const configuration = {
          locale: lang.replace(/-/g, "_"),
          originKey: ORIGIN_KEY,
          loadingContext: LOADING_CONTEXT,
          paymentMethods: paymentMethodResponse
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window["AdyenCheckoutInstance"] = new AdyenCheckout(configuration)
        ;(window["AdyenCheckoutInstance"] as any)
          .create(cardType, {
            amount,
            channel,
            toCountryCode: lang,
            onChange: (state: AdyenCardState) => {
              checkoutSet.selectedCardPayment(state)
            }
          })
          .mount("#card")
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loaded]
  )

  return (
    <Typography component="div" variant="body2" className={classes.root}>
      <div id="card" className={classes.card} />
    </Typography>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    width: "100%",
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    paddingTop: 12,
    paddingLeft: spacing(3),
    paddingRight: spacing(3)
  },
  card: {
    width: "100%"
  }
}))

export default Adyen
