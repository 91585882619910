import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import ProductCard from "common/Product/ProductCard"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import * as gtm from "services/StarGA"
import marketStore from "store/MarketStore"
import ProductListingStore from "store/ProductListingStore"

type Props = {
  route: string
}

const ProductListing: React.FC<Props> = ({ route }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { productList },
    actions: { fetchingProductList, emptyProducts }
  } = useStore(ProductListingStore)

  const {
    state: { defaultMarket }
  } = useStore(marketStore)

  const currencyCode = defaultMarket && defaultMarket.defaultCurrency

  useEffect(
    () => {
      emptyProducts()
      fetchingProductList({
        route,
        limit: 50,
        offset: 0,
        excludeIds: [],
        filters: {}
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [route]
  )

  useEffect(
    () => {
      if (
        productList.data &&
        productList.data.value &&
        productList.data.value.length > 0
      ) {
        gtm.productListImpression(productList.data.value, currencyCode)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productList.data && productList.data.value]
  )

  return (
    <>
      <Wrapper
        className={classes.filterWrapper}
        hidden={productList.data && productList.data.value.length === 0}
      >
        <Card className={classes.filterCard}>
          {productList.data ? (
            <Typography variant="body2">
              {t("product.showing_x_products").replace(
                "{value}",
                productList.data.totalItems
              )}
            </Typography>
          ) : (
            <ButtonLoaderIcon />
          )}
        </Card>
        <Card>
          <Grid container className={classes.container}>
            {productList.data &&
              productList.data.value.map((product, index) => (
                <Grid key={index} item xs={6} sm={4} className={classes.grid}>
                  <ProductCard product={product} />
                </Grid>
              ))}
          </Grid>
        </Card>
      </Wrapper>
    </>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors, gridSpacing }, breakpoints, spacing }) => ({
    filterCard: {
      padding: `${spacing(3) + 2}px ${0}px`,
      borderBottom: `1px solid ${themeColors.lightGray}`,
      [breakpoints.down("sm")]: {
        padding: spacing(1.5)
      }
    },
    container: {
      borderLeft: `1px solid ${themeColors.lightGray}`
    },
    grid: {
      position: "relative",
      width: "100%",
      height: "100%",
      borderRight: `1px solid ${themeColors.lightGray}`,
      borderBottom: `1px solid ${themeColors.lightGray}`
    },
    filterWrapper: {
      marginBottom: gridSpacing,
      padding: 0
    }
  })
)

export default ProductListing
