import {
  ContentAreaProperty,
  ContentProps,
  XhtmlStringProperty
} from "@starrepublic/epi/cms"

import { ImageBlock } from "types/content/media"
import Error from "./Error"

export type ErrorPageContent = {
  title: string
  image: ImageBlock
  errorMessage: XhtmlStringProperty
  notFoundMessage: XhtmlStringProperty
  contentArea: ContentAreaProperty
  body: XhtmlStringProperty
}

type Props = ContentProps<ErrorPageContent>

const ErrorPage: React.FC<Props> = ({ content, propsInfo, pageInfo }) => {
  return <Error content={content} propsInfo={propsInfo} pageInfo={pageInfo} />
}

export default ErrorPage
