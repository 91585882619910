import { BlockProps, CmsContextProps } from "@starrepublic/epi/cms"

import Box from "@material-ui/core/Box"

import ContactHeadquarter from "pages/DealerPage/ContactHeadquarter"
import { themeColors } from "theme"

type Props = BlockProps & CmsContextProps

const ContactBlock: React.FC<Props> = ({ content, blockInfo }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="Center"
      bgcolor={themeColors.white}
      height="100%"
      id={blockInfo && blockInfo["_id"].toString()}
    >
      <ContactHeadquarter {...content} isDealerPage={false} />
    </Box>
  )
}

export default ContactBlock
