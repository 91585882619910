import memoize from "lodash/memoize"

const SEARCH_BOT_AGENTS = (process.env.REACT_APP_SEARCH_BOT_USER_AGENTS || "")
  .toLowerCase()
  .split(",")

const LOWER_CASE_USER_AGENT = navigator.userAgent.toLowerCase()

export const isSearchBot = memoize(() => {
  if (window.BOT_DETECTED) {
    return window.BOT_DETECTED
  } else {
    // Fallback to clientside detection for projects with no backend detection
    return SEARCH_BOT_AGENTS.some((agent) =>
      LOWER_CASE_USER_AGENT.includes(agent)
    )
  }
})

export const isTouchDevice = () => {
  return !!("ontouchstart" in window || navigator.maxTouchPoints)
}
