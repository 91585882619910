import { asyncState, createStore } from "global-hook-store"

import api, { Order, ReturnModel } from "api/orders"
import { PartnerOrganizationOrder } from "models/Organization"

type ReturnLineItem = {
  selected: boolean
  code: string
  quantity: number
  imageUrl: string
  displayName: string
  isReturnable: boolean
  errorText: string
  reason: string
}

type ReturnPartnerLineItem = {
  selected: boolean
  code: string
  quantity: number
  displayName: string
  errorText: string
  reason: string
}

export type EditLineItemPayload = {
  selected: boolean
  code: string
  quantity: number
}

const ReturnStore = createStore(
  {
    error: "",
    isFetching: false,
    partnerOrder: null as null | PartnerOrganizationOrder,
    order: null as null | Order,
    partnerLineItems: [] as ReturnPartnerLineItem[],
    lineItems: [] as ReturnLineItem[],
    reason: "",
    itemsSelected: 0,
    partnerItemsSelected: 0,
    returnOrder: asyncState<Order>()
  },
  {
    setOrder: (state, order: Order) => {
      const lis = [] as ReturnLineItem[]

      for (let i = 0; i < order.lineItems.length; i++) {
        const element = order.lineItems[i]
        lis.push({
          selected: false,
          code: element.code,
          quantity: element.quantity,
          imageUrl: element.properties.imageUrl,
          displayName: element.displayName,
          isReturnable: element.isReturnable,
          errorText: "",
          reason: ""
        })
      }

      return { ...state, order, lineItems: lis }
    },
    resetOrder: (_, __: null, { reset }) => reset(),
    editLineItem: (state, payload: EditLineItemPayload) => {
      let itemsSelected = 0
      for (let i = 0; i < state.lineItems.length; i++) {
        if (state.lineItems[i].code === payload.code) {
          state.lineItems[i].selected = payload.selected

          if (payload.quantity === 0) {
            state.lineItems[i].quantity = 1
          } else {
            state.lineItems[i].quantity = payload.quantity
          }
        }

        if (state.lineItems[i].selected) {
          itemsSelected += 1
        }
      }

      state.itemsSelected = itemsSelected

      return { ...state }
    },
    sendReturnForm: async (state, reason: string, { asyncAction }) => {
      const returnOrders = state.lineItems.filter(
        (lineItem) => lineItem.selected
      )

      const model = {
        shipmentId: 0,
        lineItems: returnOrders,
        comment: reason,
        returnWarehouseId: ""
      } as ReturnModel

      return asyncAction(
        "returnOrder",
        api.submitReturn((state.order && state.order.orderGroupId) || "", model)
      )
    }
  }
)

export default ReturnStore
