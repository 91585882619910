import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const HideDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <g clipPath="url(#a)">
      <path
        stroke="currentColor"
        strokeWidth="2"
        d="M4.81 27.184 27.281 4.711M16 9.333C10.11 9.333 3.996 16 3.996 16S10.11 22.666 16 22.667c5.891 0 12.027-6.667 12.027-6.667S21.89 9.333 16 9.333Z"
        fill="none"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default HideDeLaval
