import { ChangeEvent, useRef, useState } from "react"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"

import { UpdateListArticle } from "api/anonymousShoppingList"
import Remove from "icons/Remove"
import AnonymousShoppingListStore from "store/AnonymousShoppingListStore"

type Props = {
  quantity: number
  itemCode: string
}

const QuantityField: React.FC<Props> = ({ quantity, itemCode }) => {
  const classes = useStyles()
  const textFieldRef = useRef<HTMLInputElement>(null)

  const [empty, setEmpty] = useState(false)

  const {
    actions: { fetchShoppingList, updateShoppingList }
  } = useStore(AnonymousShoppingListStore)

  const handleRemoveItem = (id: string) => {
    const updatedArticle = {
      articleNumber: id,
      quantity: 0
    } as UpdateListArticle
    updateShoppingList(updatedArticle).then((state) => {
      fetchShoppingList()
    })
  }

  const handleQuantityChange = (
    id: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value

    if (value === "") {
      setEmpty(true)
    } else {
      const newQuantity = parseInt(value, 10) || 1

      setEmpty(false)

      fetchShoppingList().then((state) => {
        if (state.shoppingList && state.shoppingList.data) {
          const updatedArticle = {
            articleNumber: id,
            quantity: newQuantity
          } as UpdateListArticle
          updateShoppingList(updatedArticle).then((state) => {
            fetchShoppingList()
          })
        }
      })
    }
  }

  return (
    <Grid className={classes.textFieldWrap}>
      <TextField
        variant="outlined"
        onChange={(e) =>
          handleQuantityChange(itemCode, e as ChangeEvent<HTMLInputElement>)
        }
        InputProps={{
          classes: {
            root: classes.quantityInput,
            notchedOutline: classes.border
          }
        }}
        inputRef={textFieldRef}
        inputProps={{
          style: {
            textAlign: "center",
            border: "none",
            padding: 0,
            fontSize: 14
          }
        }}
        className={classes.quantitySelector}
        value={empty ? "" : quantity}
      />
      <Remove
        onClick={() => handleRemoveItem(itemCode)}
        className={classes.removeIcon}
      />
    </Grid>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  textFieldWrap: {
    display: "flex",
    flexWrap: "nowrap",
    padding: spacing(1),
    alignItems: "center"
  },
  removeIcon: {
    cursor: "pointer",
    width: spacing(4),
    height: spacing(4),
    marginLeft: spacing(1),
    marginTop: spacing(0.5)
  },
  quantityInput: {
    height: "100%"
  },
  border: {
    borderRadius: 0
  },

  quantitySelector: {
    height: spacing(5),
    width: spacing(6)
  }
}))

export default QuantityField
