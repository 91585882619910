import { initReactI18next } from "react-i18next"
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-chained-backend"
import LocalStorageBackend from "i18next-localstorage-backend"
import XHR from "i18next-xhr-backend"

import ensureConfig from "utils/config"

import config from "./@starrepublic/epi/config"

ensureConfig()

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    // TODO: For development ENV
    detection: {
      order: [
        "path",
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "subdomain"
      ]
    },
    debug: false,
    fallbackLng: "en",
    load: "currentOnly",
    ns: [""],
    defaultNS: "",
    nonExplicitWhitelist: true,
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true
    },
    backend: {
      backends: [LocalStorageBackend, XHR],
      backendOptions: [
        {
          prefix: "i18next_res_",
          expirationTime: 7 * 24 * 60 * 60 * 1000,
          // language versions
          versions: {
            en: window.TRANSLATION_VERSION || "v1.0",
            sv: window.TRANSLATION_VERSION || "v1.0",
            fi: window.TRANSLATION_VERSION || "v1.0",
            "en-gb": window.TRANSLATION_VERSION || "v1.0",
            es: window.TRANSLATION_VERSION || "v1.0",
            no: window.TRANSLATION_VERSION || "v1.0"
          }
        },
        {
          loadPath: `${config.apiRoot}/api/translations/{{ns}}{{lng}}`,
          // TODO: Only for development remove later
          crossDomain: true
        }
      ]
    }
  })

export default i18n
