import { useEffect } from "react"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"

import Wrapper from "common/Wrapper"
import Logo from "icons/Logo"
import Register from "pages/Login/Register"
import ResetPassword from "pages/Login/ResetPassword"
import accountStore, { FormType } from "store/AccountStore"
import AuthStore from "store/AuthStore"
import Login from "./Login"

const LoginPage: React.FC = () => {
  const classes = useStyles()

  const {
    state: { formComponent }
  } = useStore(accountStore)

  const {
    state: { user }
  } = useStore(AuthStore)

  useEffect(() => {
    if (user.data) {
      window.location.replace("/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper className={classes.wrapper}>
      <Grid container justifyContent={"center"}>
        <Paper className={classes.containerWrapper}>
          <Grid className={classes.logo}>
            <Logo dark />
          </Grid>
          {formComponent === FormType.login && <Login reloadCartOnLogin />}
          {formComponent === FormType.forgotPassword && <ResetPassword />}
          {formComponent === FormType.register && <Register />}
        </Paper>
      </Grid>
    </Wrapper>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors, boxShadow } }) => ({
    wrapper: {
      flexGrow: 1,
      backgroundColor: themeColors.offWhite
    },
    containerWrapper: {
      boxShadow,
      backgroundColor: themeColors.white,
      maxWidth: 396,
      margin: `${spacing(16)}px 0`,
      padding: `${spacing(6)}px 0`
    },
    logo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 47,
      [breakpoints.down("xs")]: {
        height: 35
      },
      "& > svg": {
        height: "100%"
      }
    }
  })
)

export default LoginPage
