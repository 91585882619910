import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const BuildingDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M10.667 12.667V5.333h16V26m-16-13.333V26m0-13.333-5.334 2.666V26m0 0h5.334m-5.334 0H2.667m8 0h16m0 0h2.666m-8-13.333v2.666m0 2.667v2.667m-5.333-8v2.666M16 18v2.667"
      fill="none"
    />
  </SvgIcon>
)

export default BuildingDeLaval
