import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"
import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"

import CheckoutSectionHeader from "../common/CheckoutSectionHeader"
import ContainerWrapper from "../common/ContainerWrapper"

import Spinner from "common/Spinner"
import Typography from "common/Typography"
import Customer from "icons/Customer"
import { ScrollContainers } from "pages/Checkout/Checkout"
import Login from "pages/Login/Login"
import Register from "pages/Login/Register"
import ResetPassword from "pages/Login/ResetPassword"
import { checkoutStepAction } from "services/StarGA"
import authStore from "store/AuthStore"
import CartStore from "store/CartStore"
import CustomerDetailsSummary from "./CustomerDetailsSummary"

const tabs = { newCustomer: "NEW_CUSTOMER", login: "LOGIN" }

const CustomerDetails: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [showResetPassword, setShowResetPassword] = useState(false)
  const {
    state: { user }
  } = useStore(authStore)

  const {
    state: { selectedCart }
  } = useStore(CartStore)

  useEffect(
    () => {
      if (!user.loading && user.data) {
        checkoutStepAction(2)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, checkoutStepAction]
  )

  const [showTab, setShowTab] = useState(tabs.login)

  const handleTabChange = (_event: any, value: string) => {
    setShowTab(value)
  }

  const hasCart =
    selectedCart && selectedCart.lineItems.length > 0 ? true : false

  const isLoggedIn = user.data

  const content = user.loading ? (
    <div className={classes.spinnerWrapper}>
      <Spinner />
    </div>
  ) : !isLoggedIn ? (
    <>
      <Tabs
        value={showTab}
        onChange={handleTabChange}
        centered
        classes={{
          indicator: classes.indicator
        }}
        textColor="primary"
        className={classes.tabs}
      >
        <Tab
          className={classes.label}
          label={t("checkout.already_member")}
          value={tabs.login}
          style={{ maxWidth: "100%", flexGrow: 1 }}
        />
        <Tab
          className={classes.label}
          label={t("checkout.new_customer")}
          value={tabs.newCustomer}
          style={{ maxWidth: "100%", flexGrow: 1 }}
        />
      </Tabs>
      {showTab === tabs.newCustomer && <Register />}
      {showTab === tabs.login && (
        <>
          {showResetPassword ? <ResetPassword /> : <Login reloadCartOnLogin />}
          <div className={classes.loginLinkWrapper}>
            <Typography
              className={classes.sliderLink}
              variant="link3"
              onClick={() => {
                setShowResetPassword(!showResetPassword)
              }}
            >
              {showResetPassword
                ? t("account.back_to_login")
                : t("account.forgot_your_password")}
              {}
            </Typography>
          </div>
        </>
      )}
    </>
  ) : (
    <CustomerDetailsSummary />
  )

  return (
    <div id={ScrollContainers.customerDetails} className={classes.root}>
      <ContainerWrapper isActive={hasCart}>
        <CheckoutSectionHeader
          title={t("checkout.customer_title")}
          Icon={Customer}
        />
        {hasCart ? content : null}
      </ContainerWrapper>
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  root: {
    width: "100%"
  },
  tabs: {
    background: themeColors.whiteGray,
    width: "100%",
    "& .MuiTab-textColorPrimary.Mui-selected:hover": {
      cursor: "auto"
    }
  },
  label: {
    zIndex: 1
  },
  spinnerWrapper: {
    textAlign: "center",
    padding: spacing(3)
  },
  loginLinkWrapper: {
    padding: `0 ${spacing(3)}px ${spacing(3)}px`
  },
  sliderLink: {
    cursor: "pointer"
  },
  indicator: {
    background: themeColors.white
  }
}))

export default CustomerDetails
