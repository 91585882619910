import { MutableRefObject, useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import Button from "common/Button"
import Typography from "common/Typography"
import DealerSearchStore, { Dealer } from "store/DealerSearchStore"
import DealerListItem from "./DealerListItem"
import { sortAllDealers, sortSearchedDealers } from "./utils"

type DealerPanelProps = {
  searchedDealersRef: MutableRefObject<Dealer[] | undefined>
  dealersInList: Dealer[]
  onSetDealersInList: (dealers: Dealer[]) => void
}

const DealerPanel = ({
  searchedDealersRef,
  dealersInList,
  onSetDealersInList
}: DealerPanelProps) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isMdUp = useMediaQuery(({ breakpoints }) => breakpoints.up("md"))

  const {
    state: { allDealers, mapSearch, searchedDealer }
  } = useStore(DealerSearchStore)

  const dealersPerLoad = 10 // Number of dealers loaded per "load more" click

  const handleLoadMore = () => {
    const loadedDealers =
      searchedDealersRef.current &&
      searchedDealersRef.current.slice(0, dealersInList.length + dealersPerLoad)
    onSetDealersInList(loadedDealers || [])
  }

  const set = useCallback((dealers: Dealer[]) => {
    if (!dealers) return
    onSetDealersInList(dealers.slice(0, dealersPerLoad))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    searchedDealersRef.current =
      searchedDealer.data && searchedDealer.data.dealers
        ? searchedDealer.data.dealers.sort((a, b) => sortSearchedDealers(a, b))
        : mapSearch.data &&
          allDealers.sort((a, b) => sortAllDealers(mapSearch, a, b))

    set(searchedDealersRef.current)
  }, [
    allDealers,
    mapSearch,
    searchedDealer,
    searchedDealer.data,
    searchedDealersRef,
    set
  ])

  return (
    <Box {...(isMdUp && { paddingX: "16px" })}>
      <Box className={classes.dealerList} {...(!isMdUp && { height: 549 })}>
        <>
          {dealersInList.map((dealer: Dealer, key) => {
            return (
              <DealerListItem key={`${dealer.id}-${key}`} dealer={dealer} />
            )
          })}
        </>

        {searchedDealer.data === undefined && (
          <div className={classes.alignCenter}>
            <Typography>{t("contact.no_dealers_found")}</Typography>
          </div>
        )}
        {searchedDealersRef.current &&
          searchedDealersRef.current.length > dealersInList.length && (
            <Button
              fullWidth
              className={classes.button}
              onClick={handleLoadMore}
              variant="primary"
            >
              {t("contact.show_more")}
            </Button>
          )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  dealerList: {
    overflow: "scroll",
    "&::-webkit-scrollbar": { display: "none" } // TODO: Add for other web browsers
  },
  alignCenter: {
    textAlign: "center",
    margin: spacing(2)
  },

  button: {
    backgroundColor: "#0091FF !important",
    margin: spacing(1, 0)
  }
}))

export default DealerPanel
