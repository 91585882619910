type Position = [number, number]

let lastPosition: Position = [0, 0]

const swipeHelper = () => {
  const saveInitialClickPosition = (x: number, y: number) => {
    lastPosition = [x, y]
  }

  const isSwipe = (x: number, y: number) =>
    Math.abs(x - lastPosition[0]) > 10 || Math.abs(y - lastPosition[1]) > 10

  return [isSwipe, saveInitialClickPosition] as const
}

export default swipeHelper
