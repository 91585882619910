import { createContext, useContext } from "react"
import { BlockProps, DynamicBlock } from "@starrepublic/epi/cms"
import DisplayOptionsContentArea from "@starrepublic/epi/cms/components/DisplayOptionsContentArea"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import { GridSpacing } from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { contrastSwitch } from "utils/contrastSwitch"
import DisplayOptions from "utils/ui/DisplayOptions"

import {
  ChildContainer,
  ContainerWrapper,
  SectionComponent
} from "common/contentAreaContainers"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import { textMaxWidth } from "theme"

export type SectionLayout = "default" | "mosaic"
export type SectionBlockProps = {
  heading?: string
  description?: string
  mainContent: DynamicBlock[]
  theme: string
  columnGap: GridSpacing | null
  layout?: SectionLayout
  disableTopAndBottomSpacing: boolean
}

export type SectionBlockChildProps = {
  isSectionChild?: boolean
  sectionTheme?: string
}

type Props = BlockProps<SectionBlockProps>

const defaultContextValue = {
  gap: 3 as GridSpacing,
  layout: "default" as SectionLayout
}
const SectionBlockContext = createContext(defaultContextValue)

export const useSectionBlockContext = () => useContext(SectionBlockContext)

const SectionBlock: React.FC<Props> = ({ content }) => {
  const classes = useStyles()

  const {
    theme,
    mainContent,
    columnGap,
    layout,
    heading,
    description,
    disableTopAndBottomSpacing
  } = content

  mainContent &&
    mainContent.forEach((block) => {
      block.isSectionChild = true
      block.sectionTheme = theme
    })

  const gap: GridSpacing = typeof columnGap === "number" ? columnGap : 3

  return (
    <SectionBlockContext.Provider value={{ gap, layout: layout || "default" }}>
      <Box
        py={{ xs: 2, md: 4 }}
        my={0}
        style={{ backgroundColor: theme }}
        className={classNames({
          [classes.disablePadding]: disableTopAndBottomSpacing
        })}
      >
        {(heading || description) && (
          <Box pb={mainContent && mainContent.length > 0 ? 4 : 0}>
            <Wrapper>
              {heading && (
                <Box maxWidth={textMaxWidth} m="auto">
                  <Typography
                    align="center"
                    variant="headline2"
                    className={
                      classes[
                        contrastSwitch({
                          variant: "title",
                          contrastColor: theme
                        })
                      ]
                    }
                  >
                    {content.heading}
                  </Typography>
                </Box>
              )}
              {description && (
                <Box
                  mt={heading ? 2 : 0}
                  style={{ maxWidth: "90ch", marginInline: "auto" }}
                >
                  <Typography
                    align="center"
                    variant="body1"
                    className={
                      classes[
                        contrastSwitch({
                          variant: "body",
                          contrastColor: theme
                        })
                      ]
                    }
                  >
                    {description}
                  </Typography>
                </Box>
              )}
            </Wrapper>
          </Box>
        )}

        {mainContent && (
          <DisplayOptionsContentArea
            content={mainContent}
            component={SectionComponent}
            containWhen={({ _display: display }) =>
              (display &&
                (display === "auto" || DisplayOptions[display] !== 1)) ||
              false
            }
            childContainer={ChildContainer}
            container={ContainerWrapper}
          />
        )}
      </Box>
    </SectionBlockContext.Provider>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, spacing, breakpoints }) => ({
    ["text-base-blue-700"]: {
      color: themeColors.colorBaseBlue700
    },
    ["text-base-blue-900"]: {
      color: themeColors.colorBaseBlue900
    },
    ["text-base-white"]: {
      color: themeColors.white
    },
    disablePadding: {
      padding: spacing(0),
      [breakpoints.down("xs")]: {
        padding: spacing(0)
      }
    }
  })
)

export default SectionBlock
