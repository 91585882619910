import { memo } from "react"
import { MenuItem } from "@starrepublic/epi/cms"

import Collapse from "@material-ui/core/Collapse"
import { makeStyles } from "@material-ui/core/styles"
import { TransitionProps } from "@material-ui/core/transitions"
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem"

import { countObjectDepth } from "utils/hierarchyDepthCounter"

import LinkText from "common/LinkText"
import { eventTreeViewTracking } from "services/StarGA"

const TransitionComponent = (props: TransitionProps) => <Collapse {...props} />

const TreeViewMenuItem = (props: TreeItemProps & { item: MenuItem }) => {
  const classes = useStyles()

  return (
    <TreeItem
      onClick={() =>
        eventTreeViewTracking(
          props.item.url,
          countObjectDepth(props.item),
          props.item.title
        )
      }
      classes={{
        root: classes.root,
        content: classes.content,
        group: classes.group,
        iconContainer: classes.iconContainer,
        label: classes.label,
        selected: classes.selected,
        expanded: classes.expanded
      }}
      {...props}
      label={
        <LinkText className={classes.labelLink} link={props.nodeId}>
          <span className={classes.labelText}>{props.label}</span>
        </LinkText>
      }
      TransitionComponent={TransitionComponent}
    />
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  root: {
    "&:focus > $content, &:focus > $content $label, &:hover > $content $label, &:hover > $content":
      {
        backgroundColor: themeColors.offWhite
      },
    "&$selected > $content, &$selected > $content $label, &$selected:hover > $content $label, &$selected:hover > $content, &$selected:focus > $content $label, &$selected:focus > $content":
      {
        backgroundColor: themeColors.primaryLightCyan,
        transition: `background-color 0s`
      }
  },
  expanded: {
    "&> $content, & > $content $label": {
      backgroundColor: `${themeColors.lightGray} !important`
    },
    "&$selected > $content, &$selected  > $content $label": {
      backgroundColor: `${themeColors.primaryLightCyan} !important`
    },
    "& .MuiTreeItem-root > div": {
      backgroundColor: themeColors.white
    }
  },
  selected: {
    "& > $content": {
      borderLeft: `4px solid ${themeColors.colorBaseBlue600}`
    }
  },
  content: {
    flexDirection: "row-reverse",
    marginBottom: "2px",
    backgroundColor: themeColors.offWhite,
    transition: `background-color .2s`
  },
  label: {
    overflow: "hidden",
    transition: `background-color .2s`
  },
  labelText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    wordBreak: "inherit",
    display: "block"
  },
  labelLink: {
    display: "block",
    "& > span": {
      display: "block",
      padding: `${spacing(1)}px ${spacing(2)}px`
    }
  },
  group: {
    marginLeft: 0,
    paddingLeft: spacing(2),
    borderLeft: `1px dashed ${themeColors.whiteGray}`,
    marginBottom: spacing(1)
  },
  iconContainer: {
    padding: `${spacing(1.5)}px ${spacing(3)}px`,
    "& .close": {
      opacity: 0.3
    }
  }
}))

export default memo(TreeViewMenuItem)
