type Props = {
  className?: string
  white?: boolean
}

const Checked: React.FC<Props> = ({ className, white }) => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <polyline
      fill="none"
      strokeMiterlimit="10"
      points="6,12 10,16 18,8"
      strokeLinecap="square"
      strokeWidth="2"
      stroke={white ? "#FFFFFF" : "#3DB23D"}
    />
  </svg>
)

export default Checked
