import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const TimeDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      fill="currentColor"
      d="m23.413 7.76 1.509-1.52 1.515 1.526-1.415 1.425a13.071 13.071 0 0 0-1.609-1.432Zm-9.836-2.405V3h4.286v2.355c-1.542-.263-2.777-.257-4.286 0Zm2.143 21.406c-4.73 0-8.577-3.879-8.577-8.642 0-4.762 3.847-8.637 8.577-8.637s8.577 3.875 8.577 8.637c-.003 4.763-3.85 8.642-8.577 8.642Zm0-19.442C9.8 7.319 5 12.155 5 18.119 5 24.084 9.8 28.92 15.72 28.92c5.92 0 10.72-4.836 10.72-10.8 0-5.965-4.803-10.801-10.72-10.801Zm1.072 8.641-.738-4.319h-.653l-.753 4.319a2.155 2.155 0 0 0-1.071 1.86c0 1.193.959 2.16 2.143 2.16a2.151 2.151 0 0 0 2.143-2.16c0-.799-.434-1.487-1.071-1.86Z"
    />
  </SvgIcon>
)

export default TimeDeLaval
