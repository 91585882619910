import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const Clock: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 71 85">
    <g clipPath="url(#Clock)">
      <path
        d="M60.65 15.56l4.97-4.97 4.99 4.99-4.66 4.66c-1.63-1.7-3.4-3.26-5.3-4.68zM28.25 7.7V0h14.12v7.7c-5.08-.86-9.15-.84-14.12 0zm7.06 69.98C19.73 77.68 7.06 65 7.06 49.43s12.67-28.24 28.25-28.24 28.25 12.67 28.25 28.24C63.55 65 50.88 77.68 35.31 77.68zm0-63.56C15.81 14.12 0 29.93 0 49.43s15.81 35.31 35.31 35.31 35.31-15.81 35.31-35.31S54.8 14.12 35.31 14.12zm3.53 28.25l-2.43-14.12h-2.15l-2.48 14.12c-2.1 1.23-3.53 3.47-3.53 6.08 0 3.9 3.16 7.06 7.06 7.06 3.9 0 7.06-3.16 7.06-7.06 0-2.61-1.43-4.86-3.53-6.08z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="Clock">
        <path fill="#fff" d="M0 0h70.62v84.74H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default Clock
