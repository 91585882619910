import request from "@starrepublic/epi/api/request"

export type Country = {
  alpha2: string
  alpha3: string
  nativeName: string
}
type CountriesResponseType = Country[]

const country = {
  getCountries: () => request.get<CountriesResponseType>(`/countries/`)
}

export default country
