import { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const Payment: React.FC<Props> = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 18.5C8.5 19.0523 8.05228 19.5 7.5 19.5C6.94772 19.5 6.5 19.0523 6.5 18.5C6.5 17.9477 6.94772 17.5 7.5 17.5C8.05228 17.5 8.5 17.9477 8.5 18.5Z"
      fill="#103D82"
      stroke="#103D82"
    />
    <path
      d="M14 17V21C14 22.1 13.1 23 12 23H3C1.9 23 1 22.1 1 21V5C1 3.9 1.9 3 3 3H7"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M23 13H18H7V1H23V13Z"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M7 5H23"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M13 9H11"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export default Payment
