import { useTranslation } from "react-i18next"

import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import Wrapper from "common/Wrapper"

type Props = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  error: string | object
  fullWidth?: boolean
}

const Error: React.FC<Props> = ({ error, fullWidth }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Wrapper
      grid
      containerClassName={classes.wrapper}
      className={classes.wrapperRoot}
    >
      <Grid container justifyContent={"center"}>
        <Grid item xs={fullWidth ? 8 : 6}>
          <Card className={classes.cardContent}>
            <Typography className={classes.errorMessage}>
              {t(`error.${error}`)}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  wrapperRoot: {
    padding: 0,
    paddingTop: spacing(5)
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  cardContent: {
    padding: spacing(4)
  },
  errorMessage: {
    color: themeColors.primaryRed,
    textAlign: "center"
  }
}))

export default Error
