import { SvgIconProps } from "@material-ui/core/SvgIcon"

const Japan: React.FC<SvgIconProps> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48" {...props}>
    <g>
      <path
        fill="#E6E6E6"
        d="M48,40c0,1.105-0.895,2-2,2H2c-1.105,0-2-0.895-2-2V8c0-1.105,0.895-2,2-2h44c1.105,0,2,0.895,2,2V40z"
      />
      <circle fill="#BA0831" cx="24" cy="24" r="10" />
    </g>
  </svg>
)

export default Japan
