import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Clear from "@material-ui/icons/Clear"

import Button from "common/Button"
import Image from "common/Image"
import Typography from "common/Typography"
import ProductDescriptionReadMore from "pages/WebProduct/ProductDescriptionReadMore"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"
import SiteSettingStore from "store/SiteSettingStore"
import { FenceProductVariant } from "./types"

type FenceProductCardDetailsProps = {
  product: FenceProductVariant
  isSelected: boolean
  onSelectProduct: (articleNumber: string) => void
  onSetProductDrawerOpen: (value: boolean) => void
  currencySymbol: string
}

const FenceProductCardDetails = ({
  product,
  isSelected,
  onSelectProduct,
  onSetProductDrawerOpen,
  currencySymbol
}: FenceProductCardDetailsProps) => {
  const classes = useStyles()
  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))
  const { t } = useTranslation()

  const { fenceCalculatorLabels } = useFenceCalculatorStore()

  const {
    state: { enableEcommerce }
  } = useStore(SiteSettingStore)

  return (
    <Box m={2}>
      <Box display="flex" justifyContent="end">
        <IconButton
          onClick={() => onSetProductDrawerOpen(false)}
          aria-label={t("common.close")}
        >
          <Clear className={classes.closeIcon} />
        </IconButton>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection={isSmUp ? "row" : "column"}
      >
        <Box width={isSmUp ? "50%" : "100%"}>
          <Image
            containerProps={{
              className: classes.imageContainer
            }}
            className={classes.image}
            lazy
            title={product.name}
            src={product.variant.productImage || product.productImage}
            alt={product.name}
          />
        </Box>
        <Box width={isSmUp ? "50%" : "100%"}>
          <Typography variant="headline6" className={classes.title}>
            {product.name}
          </Typography>

          <Typography variant="headline6" className={classes.articleNumber}>
            {product.variant.articleNumber}
          </Typography>

          <ProductDescriptionReadMore text={product.preamble} />

          {/* TODO: Decide on text style for freetext, color etc. */}
          {product.variant.freeText && (
            <Typography>{product.variant.freeText}</Typography>
          )}
          {product.variant.color && (
            <Typography>{product.variant.color}</Typography>
          )}
          {enableEcommerce && (
            <Box mt={2} display="flex" flexDirection="column">
              <Typography className={classes.price}>
                {product.variant.price.toFixed(2)} {currencySymbol}
              </Typography>
              <Typography variant="smallText">{t("product.ex_vat")}</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        mt={2}
        mb={2}
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
      >
        <Box width={isSmUp ? "50%" : "100%"}>
          <Button
            style={{
              width: "40%",
              padding: isSmUp ? "10px 24px " : "8px 16px"
            }}
            variant="text"
            onClick={() => onSetProductDrawerOpen(false)}
          >
            {fenceCalculatorLabels.cancel}
          </Button>
          <Button
            onClick={() => {
              onSelectProduct(product.variant.articleNumber)
              onSetProductDrawerOpen(false)
            }}
            style={{
              width: "60%",
              padding: isSmUp ? "10px 24px " : "10px 16px"
            }}
            variant={isSelected ? "secondary" : "primary"}
          >
            {!isSelected
              ? fenceCalculatorLabels.select
              : fenceCalculatorLabels.deselect}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    closeIcon: {
      color: themeColors.black,
      width: spacing(3.5),
      height: spacing(3.5)
    },
    imageContainer: {
      height: 218,
      width: "100%",
      margin: spacing(3.75, 0)
    },
    image: {
      width: "100%",
      maxHeight: "100%",
      objectFit: "contain"
    },
    title: {
      fontSize: "1.5rem",
      [breakpoints.up("sm")]: {
        fontSize: "2rem"
      }
    },
    articleNumber: {
      fontSize: ".875rem",
      color: themeColors.darkGray,
      lineHeight: 1.5,
      fontWeight: 400
    },
    preamble: {
      margin: spacing(1.5, 0),
      fontSize: "1rem",
      lineHeight: 1.5,
      fontWeight: 400
    },
    price: {
      fontSize: "1rem",
      color: themeColors.black,
      textAlign: "left",
      fontWeight: 700,
      lineHeight: 1.2,
      [breakpoints.up("sm")]: {
        fontSize: "1.5rem"
      }
    }
  })
)

export default FenceProductCardDetails
