import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"

import Badge from "@material-ui/core/Badge"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import subscriptionsStore from "store/SubscriptionsStore"
import IconStatus from "./IconStatus"
import SubscriptionEdit from "./SubscriptionsEdit"

type AccountPageProps = {
  title?: string
}

const AccountSubscriptions: React.FC<AccountPageProps> = ({ title = "" }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const subscriptionStatus = "PENDING"

  const {
    state: { mySubscriptions },
    actions: { setOpen, setEditingSubscription, getSubscriptions }
  } = useStore(subscriptionsStore)

  useEffect(() => {
    getSubscriptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box mb={6}>
      {title !== "" && (
        <Box mb={2} py={1}>
          <Typography variant="headline3">{title}</Typography>
          {mySubscriptions.loading ? (
            <ButtonLoaderIcon className={classes.loader} />
          ) : (
            <Badge
              badgeContent={
                <Typography
                  variant="smallText"
                  className={classNames(classes.badgeContent, {
                    [classes.badgeContentHighlight]:
                      mySubscriptions.data &&
                      mySubscriptions.data.filter(
                        (s) => s.status === subscriptionStatus
                      ).length > 0
                  })}
                >
                  {(mySubscriptions.data && mySubscriptions.data.length) || "0"}
                </Typography>
              }
              classes={{
                badge: classNames(classes.badge, {
                  [classes.badgeHighlight]:
                    mySubscriptions.data &&
                    mySubscriptions.data.filter(
                      (s) => s.status === subscriptionStatus
                    ).length > 0
                }),
                root: classes.badgeContainer
              }}
            >
              <div />
            </Badge>
          )}
        </Box>
      )}
      <Grid container className={classes.subscriptionDetails}>
        {mySubscriptions.data && (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography className={classes.bold} variant="body1">
                    {t("subscription.head_status")}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.bold} variant="body1">
                    {t("subscription.head_name")}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography className={classes.bold} variant="body1">
                    {t("subscription.head_farm")}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography className={classes.bold} variant="body1">
                    {t("subscription.head_actions")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mySubscriptions.data.map((subscription, i) => (
                <TableRow key={i}>
                  <TableCell align="left">
                    <IconStatus activationStatus={subscription.status} />
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1">
                      {subscription.digitalService.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1">
                      {subscription.farm && subscription.farm.address ? (
                        `${subscription.farm.name}, ${subscription.farm.address.streetAddress}, ${subscription.farm.address.zipCode} ${subscription.farm.address.city}, ${subscription.farm.address.country}`
                      ) : (
                        <i>{t("subscription.no_farm_connected")}</i>
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="body1"
                      className={classes.edit}
                      onClick={() =>
                        setEditingSubscription(subscription).then(() => {
                          setOpen(true)
                        })
                      }
                    >
                      {subscription.status === subscriptionStatus
                        ? t("common.activate")
                        : t("common.open")}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Grid>
      <SubscriptionEdit />
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    badge: {
      backgroundColor: themeColors.lightGray,
      height: spacing(3),
      width: spacing(3)
    },
    badgeHighlight: {
      backgroundColor: themeColors.primaryRed
    },
    badgeContent: {
      color: themeColors.darkGray,
      fontWeight: "bold"
    },
    badgeContentHighlight: {
      color: themeColors.white
    },
    loader: {
      marginLeft: spacing(1)
    },
    badgeContainer: {
      marginLeft: spacing(2)
    },
    subscriptionDetails: {
      paddingTop: spacing(2),
      [breakpoints.down("md")]: {
        paddingTop: 0
      }
    },
    edit: {
      color: themeColors.primaryCyan,
      "&:hover": {
        cursor: "pointer"
      }
    },
    bold: {
      fontWeight: 600
    }
  })
)

export default AccountSubscriptions
