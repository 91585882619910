import { BlockResolver, ContentProps, PageInfo } from "@starrepublic/epi/cms"
import DisplayOptionsContentArea from "@starrepublic/epi/cms/components/DisplayOptionsContentArea"

import { Theme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import DisplayOptions from "utils/ui/DisplayOptions"

import BreadcrumbsBar from "common/BreadcrumbsBar"
import {
  ChildContainer,
  ContainerWrapper,
  SectionComponent
} from "common/contentAreaContainers"
import SecondarySubMenu from "common/SecondarySubMenu"
import SecondarySubMenuMobile from "common/SecondarySubMenuMobile"
import { ExploreContent } from "./types"

type Props = { pageInfo: PageInfo } & ContentProps<ExploreContent>

const Explore: React.FC<Props> = ({ content, pageInfo, propsInfo }) => {
  const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"))
  return (
    <>
      <BreadcrumbsBar />
      {content.hero &&
      (content.hero.bigTitle || content.hero.backgroundImage) ? (
        <BlockResolver content={content.hero} pageInfo={pageInfo} />
      ) : null}

      {content.secondarySubMenuItems &&
      content.secondarySubMenuItems.length > 0 ? (
        isMdUp ? (
          <SecondarySubMenu menuItems={content.secondarySubMenuItems} />
        ) : (
          <SecondarySubMenuMobile menuItems={content.secondarySubMenuItems} />
        )
      ) : null}
      <DisplayOptionsContentArea
        content={content.mainContent}
        propInfo={propsInfo.mainContent}
        component={SectionComponent}
        pageInfo={pageInfo}
        containWhen={({ _display: display, _type: type }) =>
          (type &&
            type !== "SectionBlock" &&
            display &&
            (display === "auto" || DisplayOptions[display] !== 1)) ||
          false
        }
        childContainer={ChildContainer}
        container={ContainerWrapper}
      />
    </>
  )
}

export default Explore
