type Props = {
  className?: string
}

const LogOutIcon: React.FC<Props> = (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M4 4L0 8L4 12V9H10V7H4V4Z" fill="currentColor" />
    <path
      d="M15 16H6C5.4 16 5 15.6 5 15V12H7V14H14V2H7V4H5V1C5 0.4 5.4 0 6 0H15C15.6 0 16 0.4 16 1V15C16 15.6 15.6 16 15 16Z"
      fill="currentColor"
    />
  </svg>
)

export default LogOutIcon
