import { FC } from "react"

import { makeStyles } from "@material-ui/core/styles"
import MuiTextField, { TextFieldProps } from "@material-ui/core/TextField"

type Props = TextFieldProps

const TextField: FC<Props> = ({
  id,
  label,
  value,
  onChange,
  margin,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <MuiTextField
      id={id}
      label={label}
      className={classes.textField}
      value={value}
      onChange={onChange}
      margin={margin}
      fullWidth
      {...rest}
      variant="outlined"
    />
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  textField: {
    marginTop: spacing(2),
    marginBottom: spacing(2)
  }
}))

export default TextField
