import { useTranslation } from "react-i18next"
import { Formik, FormikActions } from "formik"
import useStore from "global-hook-store"

import vatRegistrationUtil from "utils/vatRegistrationUtil"

import validationSchemaVat from "../validationSchemas/vatSchema"

import { CustomerInformation, VatNumberModel } from "models/User"
import { ScrollContainers } from "pages/Checkout/Checkout"
import countryStore from "store/CountryStore"
import marketStore from "store/MarketStore"
import registerStore from "store/RegisterStore"
import SiteSettingStore from "store/SiteSettingStore"
import AutofillForm from "./AutoFillForm"

const initialValues: VatNumberModel = {
  CountryCode: "",
  VatNumber: ""
}

const VatAutoFill: React.FC = () => {
  const { t } = useTranslation()

  const {
    state: { defaultMarket }
  } = useStore(marketStore)

  const selectedCountry =
    (defaultMarket && defaultMarket.marketId.value) || "EN"

  const {
    state: { vatNumberTitle },
    actions: { getCustomerInformation, setVatNumberTitle }
  } = useStore(registerStore)

  const {
    state: { disableVatNumberLookup }
  } = useStore(SiteSettingStore)

  const {
    state: { selectableRegistrationCountries }
  } = useStore(countryStore)

  const handleGetCustomerInformation = async (
    values: CustomerInformation,
    { setSubmitting }: FormikActions<CustomerInformation>
  ) => {
    setSubmitting(true)

    const customerInformationModel = { ...values }

    const vatAdornment = vatRegistrationUtil(selectedCountry)

    customerInformationModel.VatNumber = `${vatAdornment.start}${values.VatNumber}${vatAdornment.end}`

    customerInformationModel.CountryCode = selectedCountry
    if (!disableVatNumberLookup) {
      await getCustomerInformation(customerInformationModel.VatNumber)
    }
    setVatNumberTitle(customerInformationModel.VatNumber)
    setSubmitting(false)
  }

  if (selectableRegistrationCountries.data && defaultMarket) {
    const initialCountry = selectableRegistrationCountries.data.find((c) => {
      return c.alpha3 === defaultMarket.selectedCountry
    })
    if (initialCountry) {
      initialValues["CountryCode"] = initialCountry.alpha2
    }
  } else if (defaultMarket && defaultMarket.marketId) {
    initialValues["CountryCode"] =
      defaultMarket && defaultMarket.marketId && defaultMarket.marketId.value
  }

  return (
    <div id={ScrollContainers.customerDetails}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleGetCustomerInformation}
        validateOnChange={false}
        validateOnBlur
        component={(formikProps) => (
          <AutofillForm
            vatNumberTitle={vatNumberTitle}
            selectedCountry={selectedCountry}
            {...formikProps}
          />
        )}
        validationSchema={validationSchemaVat(t)}
      />
    </div>
  )
}

export default VatAutoFill
