export const isEpiPreview = () => {
  // Check if Epi edit iframe exists
  const previewWindows =
    window.parent &&
    window.parent.document &&
    window.parent.document.getElementsByName("sitePreview")

  return previewWindows.length > 0
}

// Set Episerver preview/Edit iframe height to the height of the actual content
export const setEpiPreviewHeight = () => {
  const previewWindows = window.parent.document.getElementsByName("sitePreview")
  // Only set the height if  iframe exists
  if (previewWindows.length > 0) {
    const currentHeight = getContentHeight()

    // Only set a new height if larger
    const previewWindowHeight = previewWindows[0].style.height
    if (
      previewWindowHeight &&
      currentHeight > parseInt(previewWindowHeight.replace("px", ""), 10)
    ) {
      previewWindows[0].style.height = `${getContentHeight().toString()}px`
    }
  }
}

const getContentHeight = () => {
  return Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  )
}

// Watch root-element for any changes in the dom and run setEpiPreviewHeight to get the correct height
export const handleRerenders = () => {
  if (isEpiPreview()) {
    const targetNode = document.getElementById("root")
    if (!targetNode) {
      return
    }
    // Beware of this as it can possibly create an endless loop locking the main thread
    const observer = new MutationObserver(() => setEpiPreviewHeight())
    observer.observe(targetNode, {
      childList: true,
      attributes: true,
      subtree: true
    })
  }
}
