import { SvgIconProps } from "@material-ui/core/SvgIcon"

const Canada: React.FC<SvgIconProps> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48" {...props}>
    <g>
      <rect x="16" y="6" fill="#E6E6E6" width="16" height="36" />
      <path
        fill="#FE0000"
        d="M48,40c0,1.105-0.895,2-2,2H32V6h14c1.105,0,2,0.895,2,2V40z"
      />
      <path
        fill="#FE0000"
        d="M16,42H2c-1.105,0-2-0.895-2-2V8c0-1.105,0.895-2,2-2h14V42z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FE0000"
        d="M24.875,30.688c0,0-0.436-3.334-0.216-3.299 c1.84,0.285,2.707,0.418,2.707,0.418c-0.954-1.802,1.443-2.674,2.675-3.722c-0.955-0.323-0.825-0.205-0.21-2.498 c-1.994,0.633-1.831,0.448-2.254-0.541c0,0-0.155,0.177-1.403,1.586c-0.178,0.201-0.476,0.067-0.414-0.24 c0.65-3.215,0.683-3.346,0.683-3.346c-1.266,0.764-1.219,0.808-1.419,0.413C24.329,18.104,24,17.438,24,17.438 s-0.329,0.667-1.024,2.02c-0.201,0.394-0.154,0.351-1.42-0.413c0,0,0.032,0.131,0.683,3.346c0.063,0.307-0.236,0.442-0.414,0.24 c-1.248-1.409-1.402-1.586-1.402-1.586c-0.424,0.99-0.259,1.174-2.254,0.541c0.615,2.293,0.745,2.175-0.21,2.498 c1.233,1.048,3.63,1.92,2.675,3.722c0,0,0.867-0.133,2.707-0.418c0.22-0.035-0.153,3.362-0.153,3.362L24.875,30.688z"
      />
    </g>
  </svg>
)

export default Canada
