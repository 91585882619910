import { useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"

import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import ListItem from "@material-ui/core/ListItem"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"

import LinkButton from "common/LinkButton"
import PricePerUnit from "common/Product/PricePerUnit"
import ProductPrice from "common/Product/ProductPrice"
import Typography from "common/Typography"
import Remove from "icons/Remove"
import { LineItem } from "models/Cart"
import CartStore from "store/CartStore"

type Props = {
  stockStatus?: boolean
  item: LineItem
  currency: string
  isFetching: boolean
  onItemClick?: () => void
}

const CardItem: React.FC<Props> = ({ item }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    actions: { updateItemQuantity }
  } = useStore(CartStore)

  const [quantity, setQuantity] = useState(item.quantity)
  const [empty, setEmpty] = useState(false)

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value === "") {
      setEmpty(true)
    } else {
      const newQuantity = parseInt(value, 10) || 1

      setQuantity(newQuantity)
      setEmpty(false)
    }
  }

  const handleQuantityBlur = () => {
    updateItemQuantity({
      quantity,
      cartTypeName: "basket",
      code: item.code,
      amount: item.placedPrice,
      name:
        item["properties"] && item["properties"]["productDisplayName"]
          ? item["properties"]["productDisplayName"]
          : "Missing name",
      category:
        item["properties"] && item["properties"]["categoryStructure"]
          ? item["properties"]["categoryStructure"]
          : "Missing category"
    })
  }

  const handleRemoveItem = () => {
    updateItemQuantity({
      cartTypeName: "basket",
      code: item.code,
      quantity: 0,
      amount: item.placedPrice,
      name:
        item["properties"] && item["properties"]["productDisplayName"]
          ? item["properties"]["productDisplayName"]
          : "Missing name",
      category:
        item["properties"] && item["properties"]["categoryStructure"]
          ? item["properties"]["categoryStructure"]
          : "Missing category"
    })
  }

  return (
    <ListItem disableGutters className={classes.root} component="li">
      <Card className={classes.card} raised>
        <LinkButton
          link={item.properties.url || "/"}
          className={classes.itemLink}
        >
          <CardMedia
            component="img"
            image={item.properties.imageUrl}
            className={classes.media}
          />
        </LinkButton>
        <div className={classes.cardBody}>
          <CardContent className={classes.content}>
            <LinkButton
              className={classNames(classes.itemLink, classes.itemInfo)}
              link={item.properties.url || "/"}
              color="textPrimary"
            >
              <Typography variant="body2" className={classes.itemInfo}>
                {item.properties.articleNumber}
              </Typography>
            </LinkButton>
            <Typography variant="link2" className={classes.itemInfo}>
              {item.properties.productDisplayName}
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
              className={classes.itemInfo}
            >
              {item.properties.variationValue}
            </Typography>
            <Typography
              variant="body2"
              className={classes.itemInfoSecondary}
              color="textSecondary"
              component={"span"}
            >
              {item.properties.isDigitalService ? (
                <PricePerUnit
                  price={item.properties.variationPricePerX || ""}
                  unit={item.properties.variationPricePerXUnitType || ""}
                />
              ) : (
                <ProductPrice
                  price={{
                    displayPrice: item.properties.originalPriceString,
                    amount: item.properties.originalPrice
                  }}
                  discountedPrice={{
                    displayPrice: item.placedPriceString,
                    amount: item.placedPrice
                  }}
                  isInline={false}
                  size={"tiny"}
                />
              )}
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <div className={classes.wrap}>
              <TextField
                variant="outlined"
                onChange={handleQuantityChange}
                onBlur={handleQuantityBlur}
                InputProps={{
                  classes: {
                    root: classes.quantityInput,
                    notchedOutline: classes.border,
                    input: "cart-item-quantity-input"
                  }
                }}
                inputProps={{
                  style: {
                    textAlign: "center",
                    border: "none",
                    padding: 0,
                    fontSize: 14
                  }
                }}
                className={classes.quantitySelector}
                value={empty ? "" : quantity}
              />
              <Remove
                onClick={handleRemoveItem}
                className={classNames(
                  "cart-item-remove-button",
                  classes.removeIcon
                )}
              />
            </div>
            <div className={classes.itemPrice}>
              <Typography className={classes.total} variant="priceSmall">
                {t("cart.total")}
              </Typography>
              <ProductPrice
                price={{
                  displayPrice: item.extendedPlacedPriceString,
                  amount: item.extendedPlacedPrice
                }}
                size={"small"}
              />
            </div>
          </CardActions>
        </div>
      </Card>
    </ListItem>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    root: {
      flex: "0 0 auto",
      padding: 0
    },
    card: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      padding: `${spacing(2) + 2}px ${spacing(2)}px`,
      boxShadow: "inherit",
      alignItems: "center",
      [breakpoints.down("sm")]: {
        paddingBottom: spacing(2),
        paddingTop: spacing(2)
      }
    },
    cardBody: {
      flex: 1,
      display: "flex",
      marginLeft: spacing(2)
    },
    content: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      alignContent: "flex-start",
      padding: 0,
      "&:last-child": {
        paddingBottom: 0
      }
    },
    media: {
      width: spacing(6),
      height: spacing(4.5),
      objectFit: "cover"
    },
    quantitySelector: {
      height: spacing(5),
      width: spacing(6)
    },
    quantityInput: {
      height: "100%"
    },
    itemInfo: {
      lineHeight: `${spacing(3)}px`,
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      textAlign: "left"
    },
    itemInfoSecondary: {
      lineHeight: `${spacing(3)}px`,
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      textAlign: "left",
      marginTop: spacing(1)
    },
    itemLink: {
      padding: 0,
      textAlign: "left",
      "&:hover": {
        color: themeColors.textDefault
      }
    },
    displayName: {
      paddingLeft: 0
    },
    removeIcon: {
      cursor: "pointer",
      width: spacing(4),
      height: spacing(4),
      marginLeft: spacing(1),
      marginTop: spacing(0.5)
    },
    border: {
      borderRadius: 2
    },
    cardActions: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingBottom: 4,
      paddingTop: 4
    },
    wrap: {
      display: "flex",
      flexWrap: "wrap",
      flexGrow: 1
    },
    itemPrice: {
      display: "flex",
      flexDirection: "row",
      whiteSpace: "nowrap",
      marginTop: spacing(1)
    },
    total: {
      marginRight: spacing(0.5)
    }
  })
)

export default CardItem
