import { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const Pen: React.FC<Props> = (props) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M9.5 0.5L11.5 2.5L4.5 9.5L1.5 10.5L2.5 7.5L9.5 0.5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Pen
