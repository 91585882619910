import { useTranslation } from "react-i18next"
import classNames from "classnames"

import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import { LineItem } from "api/orders"
import Image from "common/Image"
import Typography from "common/Typography"

type Props = {
  className?: string
  items: LineItem[]
}

const LineItems: React.FC<Props> = ({ items, className }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Table className={classNames(classes.table, className)}>
      <Hidden smDown>
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classNames(classes.product)}>
              <Typography className={classes.tableHead}>
                {t("account.line_item_product")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableHead}>
                {t("account.line_item_price")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableHead} align="right">
                {t("account.line_item_quantity")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.tableHead} align="right">
                {t("account.line_item_subtotal")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      </Hidden>
      <TableBody>
        {items.map(
          (
            {
              properties,
              image,
              code,
              displayName,
              discountedPrice,
              placedPrice,
              discountedPriceString,
              placedPriceString,
              quantity,
              extendedPlacedPriceString
            }: LineItem,
            index
          ) => {
            const withDiscount = discountedPrice !== placedPrice

            const cellClasses = classNames(classes.tableCell, {
              [classes.noBorder]: index === items.length - 1
            })

            return (
              <TableRow key={`${code}-${index}`} className={classes.tableRow}>
                <TableCell className={cellClasses}>
                  <Grid wrap="nowrap" container>
                    <Grid item>
                      <Image
                        containerProps={{
                          className: classes.imageBackgroundContainer
                        }}
                        className={classes.image}
                        lazy
                        color={image && image.dominantColor}
                        src={properties.imageUrl && properties.imageUrl}
                      />
                    </Grid>
                    <Grid item>
                      <Hidden smDown>
                        <Typography variant="body1">{code}</Typography>
                        <Typography variant="link2">{displayName}</Typography>
                      </Hidden>
                    </Grid>
                    <Hidden mdUp>
                      <Grid>
                        <Typography
                          className={classes.productInfoMobile}
                          variant="body1"
                        >
                          {code}
                        </Typography>
                        <Typography
                          className={classes.productInfoMobile}
                          variant="link2"
                        >
                          {displayName}
                        </Typography>
                      </Grid>
                    </Hidden>
                  </Grid>
                </TableCell>
                <Hidden smDown>
                  <TableCell className={cellClasses}>
                    <Typography variant={"body1"}>
                      {placedPriceString}
                    </Typography>
                  </TableCell>
                  <TableCell className={cellClasses}>
                    <Typography className={classes.rightCell} variant="body1">
                      {quantity}
                    </Typography>
                  </TableCell>
                  <TableCell className={cellClasses}>
                    <Typography className={classes.rightCell} variant="body1">
                      {extendedPlacedPriceString}
                    </Typography>
                  </TableCell>
                </Hidden>

                <Hidden mdUp>
                  <TableCell
                    align="right"
                    style={{ whiteSpace: "nowrap" }}
                    className={cellClasses}
                  >
                    {withDiscount && (
                      <Typography
                        variant={"body2"}
                        className={classes.discountedPrice}
                      >
                        {discountedPriceString}
                      </Typography>
                    )}

                    <Typography
                      variant={"body2"}
                      className={classNames({
                        [classes.originalPrice]: withDiscount
                      })}
                    >
                      {placedPriceString}
                    </Typography>
                    <Typography className={classes.quantity} variant={"body2"}>
                      {`${t("cart.quantity_table_header")} ${quantity}`}
                    </Typography>
                    <Typography variant={"priceTiny"}>
                      {`${t(
                        "cart.total_table_header"
                      )} ${discountedPriceString}`}
                    </Typography>
                  </TableCell>
                </Hidden>
              </TableRow>
            )
          }
        )}
      </TableBody>
    </Table>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors, gridSpacing } }) => ({
    root: {},
    table: {
      borderCollapse: "separate",
      "& th": {
        color: themeColors.darkGray,
        whiteSpace: "nowrap"
      }
    },
    product: {
      paddingLeft: 0
    },
    imageContainer: {
      position: "relative"
    },
    imageBackgroundContainer: {
      display: "flex"
    },
    image: {
      paddingRight: spacing(2),
      height: 38,
      position: "relative",
      [breakpoints.down("sm")]: {
        paddingRight: spacing(1)
      }
    },
    discountedPrice: {
      color: themeColors.primaryRed
    },
    originalPrice: {
      textDecoration: "line-through"
    },
    tableRow: {
      width: "100%"
    },
    tableCell: {
      paddingTop: gridSpacing,
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      [breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    tableHead: {
      color: themeColors.darkGray,
      fontWeight: 500
    },
    noBorder: { borderBottom: "none" },
    quantity: {
      display: "flex",
      justifyContent: "flex-end",
      lineHeight: 1.4
    },
    productInfoMobile: {
      fontSize: 14
    },
    rightCell: {
      textAlign: "right"
    }
  })
)

export default LineItems
