import { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const ShoppingBasket: React.FC<Props> = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 22C6.5 22.2761 6.27614 22.5 6 22.5C5.72386 22.5 5.5 22.2761 5.5 22C5.5 21.7239 5.72386 21.5 6 21.5C6.27614 21.5 6.5 21.7239 6.5 22Z"
      stroke="white"
    />
    <path
      d="M20.5 22C20.5 22.2761 20.2761 22.5 20 22.5C19.7239 22.5 19.5 22.2761 19.5 22C19.5 21.7239 19.7239 21.5 20 21.5C20.2761 21.5 20.5 21.7239 20.5 22Z"
      stroke="white"
    />
    <path
      d="M6 23C6.55228 23 7 22.5523 7 22C7 21.4477 6.55228 21 6 21C5.44772 21 5 21.4477 5 22C5 22.5523 5.44772 23 6 23Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M20 23C20.5523 23 21 22.5523 21 22C21 21.4477 20.5523 21 20 21C19.4477 21 19 21.4477 19 22C19 22.5523 19.4477 23 20 23Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M1 1H4V15C4 15.5304 4.21071 16.0391 4.58579 16.4142C4.96086 16.7893 5.46957 17 6 17H22"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M4 5H21L19 13H4"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default ShoppingBasket
