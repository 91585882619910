import { Link } from "react-router-dom"
import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

import { blockDisplay } from "utils/blockDisplay"
import { alignmentToFlex } from "utils/ui"

import BodyContent from "blocks/BodyContent"
import BlockGrid from "common/BlockGrid"
import Overlay from "common/Overlay"
import ResponsiveImage from "common/ResponsiveImage"
import { BodyContent as BodyContentType } from "types"
import { MediaBlockContent } from "./types"

type Props = BlockProps<MediaBlockContent & BodyContentType>

const MediaBlock: React.FC<Props> = ({ content, blockInfo, propsInfo }) => {
  const {
    backgroundImage,
    backgroundColor,
    overlay,
    verticalAlignment,
    horizontalAlignment,
    height,
    link,
    linkText
  } = content

  const _display = blockDisplay(blockInfo)
  const classes = useStyles()
  const justify = alignmentToFlex(verticalAlignment)
  const align = alignmentToFlex(horizontalAlignment)

  const innerContent = (
    <>
      {backgroundImage && (
        <ResponsiveImage
          className={classes.media}
          containerProps={{
            className: classes.mediaContainer
          }}
          image={backgroundImage}
        />
      )}
      <Overlay opacity={overlay} />
      <BlockGrid
        container
        direction="column"
        justifyContent={justify}
        alignItems={align}
        className={classNames(classes.bodyContentContainer, {
          [classes.bodyContentContainerFull]: _display.full
        })}
      >
        <BodyContent
          content={content}
          propsInfo={propsInfo}
          backgroundColor={backgroundColor}
          display={blockInfo!["_display"]}
          image={backgroundImage}
        />
      </BlockGrid>
    </>
  )

  return (
    <div className={classes.root}>
      {link && !linkText ? <Link to={link}>{innerContent}</Link> : innerContent}
    </div>
  )
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    display: "block",
    position: "relative",
    paddingBottom: 0,
    marginBottom: spacing(0),
    overflow: "hidden",
    WebkitTapHighlightColor: "transparent",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100%"
  },
  mediaContainer: {
    height: "100%",
    width: "100%",
    position: "absolute",
    display: "block"
  },
  media: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  bodyContentContainer: {
    height: "100%",
    width: "100%",
    padding: `${spacing(3)}px ${spacing(2)}px`,
    [breakpoints.up("sm")]: {
      padding: `${spacing(6)}px ${spacing(3)}px`
    },
    [breakpoints.up("md")]: {
      padding: `${spacing(8)}px ${spacing(4)}px`
    }
  },
  bodyContentContainerFull: {
    padding: `${spacing(3)}px ${spacing(2)}px`,
    [breakpoints.up("sm")]: {
      padding: `${spacing(6)}px ${spacing(3)}px`
    },
    [breakpoints.up("md")]: {
      padding: `${spacing(8)}px ${spacing(4)}px`
    }
  }
}))

export default MediaBlock
