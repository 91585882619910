import { FC, forwardRef } from "react"
import classNames from "classnames"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { useSectionBlockContext } from "blocks/SectionBlock"

type OwnProps = {
  containerClassName?: string
  containerId?: string
  grid?: boolean
  narrow?: boolean
  forwardRef?: React.Ref<any>
} & React.HTMLProps<HTMLDivElement>

type Props = OwnProps

const Wrapper: FC<Props> = ({
  children,
  className,
  containerClassName,
  grid,
  forwardRef,
  narrow,
  containerId,
  ...rest
}) => {
  const classes = useStyles()
  const isSmall = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"))

  const { gap, layout } = useSectionBlockContext()
  return (
    <div className={classNames(classes.root, className)} {...rest}>
      {(grid && (
        <div className={classNames(classes.container, containerClassName)}>
          <Grid
            id={containerId}
            innerRef={forwardRef}
            container
            direction={isSmall ? "column" : "row"}
            spacing={gap}
            className={classNames(
              {
                narrow
              },
              {
                [classes.mosaic]: layout === "mosaic"
              }
            )}
          >
            {children}
          </Grid>
        </div>
      )) || (
        <div
          id={containerId}
          ref={forwardRef}
          className={classNames(
            {
              narrow
            },
            classes.container,
            containerClassName,
            {
              [classes.mosaic]: layout === "mosaic"
            }
          )}
        >
          {children}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles(
  ({
    breakpoints,
    spacing,
    common: {
      gridSpacing,
      pageMargin,
      pageMarginMobile,
      pageMaxWidth,
      pageMaxWidthNarrow
    }
  }) => ({
    root: {
      padding: `0 ${pageMargin}px`
    },
    mosaic: {
      [breakpoints.up("sm")]: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridTemplateRows: "repeat(2, auto)"
      },
      [breakpoints.up("md")]: {
        gridTemplateColumns: "repeat(3, 1fr)",
        gridTemplateRows: "repeat(2, auto)"
      },

      "& > :first-child": {
        gridColumn: "span 2",
        gridRow: "span 2"
      },
      "& .MuiGrid-item": {
        maxWidth: "100%"
      }
    },
    container: {
      margin: "0 auto",
      width: "100%",
      paddingBlock: gridSpacing,
      [breakpoints.up("md")]: {
        maxWidth: breakpoints.values.md,
        marginInline: "auto"
      },
      [breakpoints.up(pageMaxWidth + spacing(2))]: {
        maxWidth: pageMaxWidth,
        "&.narrow": {
          maxWidth: pageMaxWidthNarrow
        }
      }
    }
  })
)

const Enhanced = Wrapper

export default forwardRef<HTMLInputElement, OwnProps>((props, ref) => {
  return <Enhanced {...props} forwardRef={ref} />
})
