import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Clear from "@material-ui/icons/Clear"

import Typography from "common/Typography"
import subscriptionsStore from "store/SubscriptionsStore"
import ConnectFarmField from "./ConnectFarmField"
import IconStatus from "./IconStatus"

const SendShoppingListModal: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { open, editingSubscription: subscription },
    actions: { setOpen, getFarms }
  } = useStore(subscriptionsStore)

  useEffect(
    () => {
      if (open && subscription && subscription.farm === null) {
        getFarms()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, subscription]
  )

  if (!subscription) {
    return null
  }

  return (
    <>
      <Dialog
        aria-labelledby="edit-subscriptions-dialog"
        aria-describedby="edit-your-subscription-here"
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        className={classes.root}
      >
        <DialogContent className={classes.dialogue}>
          <Grid
            className={classes.head}
            container
            justifyContent="space-between"
          >
            <Grid>
              <Typography variant="headline4">
                {subscription.digitalService.name}
              </Typography>
            </Grid>
            <Grid>
              <IconButton
                className={classes.closeButton}
                onClick={() => setOpen(false)}
              >
                <Clear className={classes.Icon} />
              </IconButton>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography className={classes.detailsText}>
                      {t("subscription.head_status")}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <IconStatus activationStatus={subscription.status} />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography className={classes.detailsText}>
                      {t("subscription.payment")}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>
                      {`${subscription.digitalService.pricePerXValue} / ${subscription.digitalService.pricePerXUnit}`}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tableCellBorder}>
                  <TableCell>
                    <Typography className={classes.detailsText}>
                      {t("subscription.duration")}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography>
                      {subscription.digitalService.duration}
                    </Typography>
                  </TableCell>
                </TableRow>
                {subscription.subscriptionStartDate && (
                  <TableRow className={classes.tableCellBorder}>
                    <TableCell>
                      <Typography className={classes.detailsText}>
                        {t("subscription.start_date")}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {new Date(
                          subscription.subscriptionStartDate
                        ).toLocaleDateString()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {subscription.subscriptionEndDate && (
                  <TableRow className={classes.tableCellBorder}>
                    <TableCell>
                      <Typography className={classes.detailsText}>
                        {t("subscription.end_date")}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {new Date(
                          subscription.subscriptionEndDate
                        ).toLocaleDateString()}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {subscription.farm && (
                  <TableRow className={classes.farmTableBorder}>
                    <TableCell>
                      <Typography className={classes.detailsText}>
                        {t("subscription.head_farm")}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography>
                        {subscription.farm.name}
                        <br />
                        {subscription.farm.address.streetAddress}
                        <br />
                        {`${subscription.farm.address.zipCode} ${subscription.farm.address.city}`}
                        <br />
                        {subscription.farm.address.country}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {!subscription.farm && (
              <>
                <Divider className={classes.divider} />
                <ConnectFarmField subscription={subscription} />
              </>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  dialogue: {
    padding: 0
  },
  root: {
    "& .MuiTableCell-body": {
      padding: `${spacing(2)}px ${spacing(3)}px`
    }
  },
  head: {
    padding: `${spacing(1)}px ${spacing(3)}px ${spacing(2.5)}px`
  },
  closeButton: {
    padding: 0
  },
  Icon: {
    width: spacing(3),
    height: spacing(3)
  },
  divider: {
    width: "100%",
    backgroundColor: themeColors.grayBorder
  },
  tableCellBorder: {
    "& > td": {
      border: 0
    }
  },
  farmTableBorder: {
    "& > td": {
      borderBottom: 0,
      borderTop: `1px solid ${themeColors.grayBorder}`,
      verticalAlign: "top",
      paddingBottom: `${spacing(6)}px !important`
    }
  },
  detailsText: {
    fontWeight: "bold"
  }
}))

export default SendShoppingListModal
