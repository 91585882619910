import { DynamicBlock, Menu, MenuItem } from "@starrepublic/epi/cms"
import { createStore } from "global-hook-store"

import { Omit } from "utils/types"

import siteSettingsApi from "api/siteSettings"
import {
  ContentReference,
  Header,
  Link,
  OriginalPartsCategory,
  Terms
} from "models/SiteSettings"
import AuthStore from "store/AuthStore"
import { Footer } from "types/content/footer"

export type NavMenuGroup = Omit<Menu, "_id" | "_type">
type Links = Record<
  | "account"
  | "search"
  | "checkout"
  | "orderConfirmation"
  | "home"
  | "partnerPortal"
  | "contact"
  | "orders",
  Link | undefined
>

const SiteSettingStore = createStore(
  {
    _settingsCacheKey: "",
    _siteType: "",
    footer: {} as Footer,
    links: {} as Links,
    isFetching: false,
    customerNumberImage: {} as ContentReference,
    contactForm: {} as DynamicBlock,
    terms: {} as Terms,
    header: {} as Header,
    lang: "",
    countryCode: "",
    newsletterConfirmText: "",
    cookiePolicy: "",
    loggedInLandingUrl: "",
    showCustomerNumberSearch: false,
    vatNumberInformationText: "",
    enableOriginalParts: false,
    enableDigitalServices: false,
    enableDiscountBadges: false,
    partnerCart: "",
    partnerShoppingList: "",
    displayNewsletterForm: false,
    newsletterPreamble: "",
    newsletterTitle: "",
    logoutUrl: "",
    partnerTermsUrl: "",
    disableVatNumberLookup: false,
    postalCodeValidationPattern: "",
    enableSendShoppingList: false,
    enablePrintShoppingList: false,
    enableSaveShoppingList: false,
    displayNewsletterNameFields: false,
    enableAddNewShippingAddressInCheckout: false,
    safetyInformationUrl: "",
    originalPartsCategory: {} as OriginalPartsCategory,
    additionalLoginInformation: "",
    partnerMenu: [] as MenuItem[],
    eComMenu: [] as MenuItem[],
    get enableWishlist(): boolean {
      return ["noWebShop"].indexOf(this._siteType) > -1
    },
    get enableWebShopMigration(): boolean {
      return ["webShopMigration"].indexOf(this._siteType) > -1
    },
    get enableEcommerce(): boolean {
      return ["webShop"].indexOf(this._siteType) > -1
    },
    get enableDigitalServicesOnly(): boolean {
      return ["webShopForDigitalServicesOnly"].indexOf(this._siteType) > -1
    },
    get enableNoWebShopWithContactDealer(): boolean {
      return ["noWebShopWithContactDealer"].indexOf(this._siteType) > -1
    },
    get isOnePager(): boolean {
      return ["cmsOnePager"].indexOf(this._siteType) > -1
    },

    get productMenu(): MenuItem[] {
      const menu = (
        AuthStore &&
        AuthStore.state &&
        AuthStore.state.user &&
        AuthStore.state.user.data &&
        AuthStore.state.user.data.isPartnerUser &&
        this.partnerMenu
          ? this.partnerMenu
          : this.eComMenu
      ).find((m: MenuItem) => {
        return m["menuType"] === "ProductMenu"
      })
      return (menu && menu.children) || ([] as MenuItem[])
    }
  },
  {
    // Used to toggle state in Storybook HeroBlock
    // TODO: Remove this when we have a better way to do this
    setSiteType: (state, _: null) => ({
      ...state,
      _siteType: state._siteType === "cmsOnePager" ? "webShop" : "cmsOnePager"
    }),
    isFetching: (state, isFetching: boolean) => ({
      ...state,
      isFetching
    }),
    fetchSiteSettings: async (state, getCached: boolean) => {
      try {
        const {
          _settingsCacheKey,
          _siteType,
          footer,
          links,
          countryCode,
          customerNumberImage,
          terms,
          contactForm,
          header,
          newsletterConfirmText,
          cookiePolicy,
          loggedInLandingUrl,
          showCustomerNumberSearch,
          vatNumberInformationText,
          enableOriginalParts,
          enableDigitalServices,
          enableDiscountBadges,
          partnerCart,
          partnerShoppingList,
          displayNewsletterForm,
          newsletterTitle,
          logoutUrl,
          partnerTermsUrl,
          disableVatNumberLookup,
          postalCodeValidationPattern,
          enableSendShoppingList,
          enablePrintShoppingList,
          enableSaveShoppingList,
          newsletterPreamble,
          displayNewsletterNameFields,
          safetyInformationUrl,
          originalPartsCategory,
          additionalLoginInformation,
          enableAddNewShippingAddressInCheckout,
          partnerMenu,
          eComMenu
        } = await siteSettingsApi.get(getCached)

        let reducedLinks = {} as Links
        if (links) {
          reducedLinks = links.reduce((acc, curr, i) => {
            acc[curr.text] = curr
            return acc
          }, {}) as Links
        }

        return {
          ...state,
          _settingsCacheKey,
          _siteType,
          footer,
          terms,
          countryCode,
          contactForm,
          header,
          newsletterConfirmText,
          cookiePolicy,
          loggedInLandingUrl,
          customerNumberImage,
          showCustomerNumberSearch,
          vatNumberInformationText,
          enableOriginalParts,
          enableDigitalServices,
          enableDiscountBadges,
          partnerCart,
          partnerShoppingList,
          displayNewsletterForm,
          newsletterTitle,
          logoutUrl,
          partnerTermsUrl,
          disableVatNumberLookup,
          postalCodeValidationPattern,
          enableSendShoppingList,
          enablePrintShoppingList,
          enableSaveShoppingList,
          newsletterPreamble,
          displayNewsletterNameFields,
          safetyInformationUrl,
          originalPartsCategory,
          additionalLoginInformation,
          enableAddNewShippingAddressInCheckout,
          partnerMenu,
          eComMenu,
          links: reducedLinks,
          isFetching: false
        }
      } catch (error) {
        console.error("There's an error", error)
        return {
          ...state,
          isFetching: false
        }
      }
    },
    //This is used to mock data in Storybook
    //TODO: Remove this when we got a better solution
    setSiteSettings: (state, payLoad: any) => ({
      ...state,
      ...payLoad
    })
  }
)

SiteSettingStore.actions.isFetching(true)
SiteSettingStore.actions.fetchSiteSettings(true)

export default SiteSettingStore
