import classNames from "classnames"

import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles, Theme } from "@material-ui/core/styles"

import FormikTextField from "common/Formik/FormikTextField"

type Props = {
  theme?: string
  name: string
  shrink?: boolean
  adornment?: { start: string; end: string; length: number }
  placeholder?: string
  children?: React.ReactNode
  type?: string
  defaultValueOption?: string
  required?: boolean
  multiline?: boolean
  minRows?: number
  select?: boolean
  native?: boolean
  MenuProps?: any
  disabled?: boolean
  label?: string
  length?: number
  autoComplete?: string
  vatUtilOn?: boolean
  autoFocus?: boolean
  numberToString?: boolean
  inputRef?: React.RefObject<HTMLInputElement>
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

type StyleProps = {
  _theme: string
  _darkTheme: boolean
  _primaryBlueTheme: boolean
  _useDarkComponents: boolean
}

const maxLength = (
  event: React.BaseSyntheticEvent,
  max: number,
  vatUtilOn?: boolean
) => {
  if (event.target.value && vatUtilOn) {
    event.target.value = event.target.value.toString().slice(0, max)
  }
}

const keyPressValidation = (
  event: React.KeyboardEvent,
  vatUtilOn?: boolean
) => {
  const reg = new RegExp("^[0-9]+$")
  if (vatUtilOn) {
    if (
      event.keyCode === 8 ||
      event.keyCode === 46 ||
      event.keyCode === 37 ||
      event.keyCode === 39
    ) {
      return true
    }
    if (!event.key.match(reg)) {
      event.preventDefault()
    }
  }
}

const CustomTextField: React.FC<Props> = (props) => {
  const {
    theme = "#FFFFFF",
    name,
    placeholder,
    numberToString,
    select,
    native = true,
    children,
    type,
    defaultValueOption,
    required,
    minRows,
    disabled,
    label,
    multiline = false,
    MenuProps = {},
    adornment,
    shrink,
    autoComplete,
    vatUtilOn,
    autoFocus,
    onFocus,
    onBlur,
    inputRef
  } = props

  const dark = theme === "#103D82" || theme === "#3472D5"
  const primaryBlueTheme = theme === "#103D82"
  const useDarkComponents =
    theme === "#103D82" || theme === "#75CFFF" || theme === "#00A6FF"

  const classes = useStyles({
    _theme: theme,
    _darkTheme: dark,
    _primaryBlueTheme: primaryBlueTheme,
    _useDarkComponents: useDarkComponents
  })

  return (
    <FormikTextField
      className={classNames(classes.root, {
        [classes.border]: primaryBlueTheme
      })}
      inputRef={inputRef}
      numberToString={numberToString}
      disabled={disabled}
      defaultValueOption={defaultValueOption}
      autoComplete={autoComplete}
      InputLabelProps={{
        className: classes.label,
        shrink
      }}
      autoFocus={autoFocus}
      InputProps={{
        className: classes.input,
        startAdornment: adornment && adornment.start && (
          <InputAdornment position="start" hidden={!adornment.start}>
            {adornment.start}
          </InputAdornment>
        ),
        endAdornment: adornment && adornment.end && (
          <InputAdornment position="end" hidden={!adornment.end}>
            {adornment.end}
          </InputAdornment>
        ),
        fullWidth: true,
        classes: { disabled: classes.disabled }
      }}
      onInput={(e) =>
        maxLength(
          e,
          adornment && adornment.length ? adornment.length : 99,
          vatUtilOn
        )
      }
      error
      onKeyDown={(e) => keyPressValidation(e, vatUtilOn)}
      FormHelperTextProps={{
        classes: {
          root: classes.helperTextRoot,
          error: classes.helperTextError
        },
        // @ts-expect-error
        component: "div"
      }}
      select={select}
      SelectProps={
        select
          ? {
              MenuProps,
              native
            }
          : {}
      }
      multiline={multiline}
      minRows={minRows}
      required={required}
      name={name}
      type={type}
      label={label}
      placeholder={placeholder}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {children}
    </FormikTextField>
  )
}

const useStyles = makeStyles<Theme, StyleProps>(
  ({ spacing, common: { themeColors } }) => ({
    root: {
      backgroundColor: themeColors.white,
      "& .MuiFormLabel-root.Mui-focused": {
        backgroundColor: themeColors.white,
        padding: spacing(0.5),
        color: themeColors.colorBaseGray600
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        padding: spacing(0.5)
      },
      "& .MuiOutlinedInput-notchedOutline legend": {
        maxWidth: 0
      },
      "& .MuiFormHelperText-root.Mui-error": {
        color: ({ _darkTheme, _primaryBlueTheme, _useDarkComponents }) =>
          _darkTheme
            ? _useDarkComponents && !_primaryBlueTheme
              ? themeColors.black
              : themeColors.white
            : themeColors.black
      }
    },
    label: {
      fontWeight: 300,
      fontSize: 16,
      color: themeColors.colorBaseGray600,
      backgroundColor: themeColors.white
    },
    input: {
      padding: spacing(1),
      boxSizing: "border-box",
      "& input": {
        fontWeight: 300,
        fontSize: 16,
        paddingLeft: 5,
        "&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus, ":
          {
            "-webkit-text-fill-color": themeColors.darkGray,
            "-webkit-box-shadow": " 0 0 0px 1000px transparent inset",
            transition: "background-color 5000s ease-in-out 0s"
          }
      },
      "& select": {
        "&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus, ":
          {
            "-webkit-text-fill-color": themeColors.darkGray,
            "-webkit-box-shadow": " 0 0 0px 1000px transparent inset",
            transition: "background-color 5000s ease-in-out 0s",
            paddingLeft: 5
          }
      }
    },
    border: {
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: themeColors.colorBaseCyan700,
        borderWidth: "2px"
      }
    },
    disabled: {
      color: themeColors.gray,
      backgroundColor: themeColors.white
    },
    helperTextRoot: {
      backgroundColor: ({ _theme }) => _theme,
      margin: "-1px 0px",
      padding: spacing(0.5, 0, 0)
    },
    helperTextError: {
      fontWeight: 400,
      fontSize: 12
    }
  })
)

export default CustomTextField
