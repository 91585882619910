import { forwardRef } from "react"
import classNames from "classnames"
import { SnackbarKey, useSnackbar } from "notistack"

import CircularProgress from "@material-ui/core/CircularProgress"
import IconButton from "@material-ui/core/IconButton"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import { makeStyles } from "@material-ui/core/styles"
import CheckCircleIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import ErrorIcon from "@material-ui/icons/Error"
import InfoIcon from "@material-ui/icons/Info"
import WarningIcon from "@material-ui/icons/Warning"

import { SnackBarOptions } from "store/SnackbarStore"

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
}

type Props = {
  className?: string
  id: SnackbarKey
  options: SnackBarOptions
  variant: "success" | "warning" | "error" | "info" | "product" | "shopping"
}

const MySnackbarContent = forwardRef<HTMLDivElement, Props>(
  ({ className, variant, options, id }, ref) => {
    const { closeSnackbar } = useSnackbar()
    const classes = useStyles()
    const Icon = variantIcon[variant]
    return (
      <SnackbarContent
        ref={ref}
        className={classNames(classes[variant], className, classes.root)}
        aria-describedby="client-snackbar"
        message={
          <span
            id="client-snackbar"
            data-type={variant}
            className={classes.message}
          >
            <Icon
              className={classNames(
                classes.icon,
                classes.iconVariant,
                `icon--${variant}`
              )}
            />
            {options.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => closeSnackbar(id)}
          >
            <CloseIcon className={classes.icon} />
            <CircularProgress
              className={classNames(classes.progress, `icon--${variant}`)}
              size={24}
              variant="determinate"
              value={100}
            />
          </IconButton>
        ]}
      />
    )
  }
)

const useStyles = makeStyles(
  ({
    spacing,
    palette: {
      common: { white },
      warning,
      success,
      error,
      info
    }
  }) => ({
    root: { borderLeft: "solid 3px currentColor" },
    success: {
      borderLeftColor: success.main,
      backgroundColor: success.light
    },
    error: {
      borderLeftColor: error.main,
      backgroundColor: error.light
    },
    info: {
      borderLeftColor: info.main,
      backgroundColor: info.light
    },
    warning: {
      borderLeftColor: warning.main,
      backgroundColor: warning.light
    },
    icon: {
      fontSize: 16
    },
    iconVariant: {
      fontSize: 24,
      opacity: 0.9,
      marginRight: spacing(1)
    },
    progress: {
      position: "absolute",
      opacity: 0.2
    },
    staticProgress: {
      opacity: 0.2,
      color: white
    },
    message: {
      display: "flex",
      alignItems: "center"
    }
  })
)

export default MySnackbarContent
