import { asyncState, createStore } from "global-hook-store"

import api, { CondenseOrder, Order } from "api/orders"
import quickOrder, { QuickItem } from "api/quickOrder"
import { Cart, LineItemProperties } from "models/Cart"

const CART_TYPE_NAME = "basket"

const orderStore = createStore(
  {
    orders: asyncState<Order[]>(),
    order: asyncState<Order>(),
    compactOrdersInfo: asyncState<CondenseOrder[]>(),
    reorderCart: asyncState<Cart<LineItemProperties>>(),
    bundleToCart: asyncState<Cart<LineItemProperties>>()
  },
  {
    fetchOrders: async (_, __: null, { asyncAction }) =>
      asyncAction("orders", api.getOrders()),
    fetchOrder: async (_, orderId: string, { asyncAction }) =>
      asyncAction("order", api.getOrder(orderId)),
    fetchCompactOrdersInfo: async (_, __: null, { asyncAction }) =>
      asyncAction("compactOrdersInfo", api.getCondenseOrders()),
    reorder: async (_, orderNumber: number, { asyncAction }) =>
      asyncAction(
        "reorderCart",
        quickOrder.reOrder(CART_TYPE_NAME, orderNumber)
      ),
    addItemsToCart: async (_, items: QuickItem[], { asyncAction }) =>
      asyncAction("bundleToCart", quickOrder.items(CART_TYPE_NAME, items)),
    resetReOrder: (_, __: null, { reset }) => {
      return reset("reorderCart")
    },
    resetCompactOrder: (_, __: null, { reset }) => {
      return reset("compactOrdersInfo")
    }
  }
)

export default orderStore
