import { themeColors } from "theme"

type ContrastTypes = {
  variant: string
  contrastColor?: string
}

export const contrastSwitch = ({ variant, contrastColor }: ContrastTypes) => {
  if (variant === "text") {
    switch (contrastColor) {
      case themeColors.colorBaseCyan400:
      case themeColors.colorBaseBlue300:
      case themeColors.colorBaseGray400:
        return "text-base-blue-650"
      case themeColors.colorBaseBlue500:
        return "text-base-white"
      case themeColors.colorBaseCyan600:
        return "text-base-blue-700"
      case themeColors.colorBaseCyan700:
        return "text-base-blue-800"
    }
  } else if (variant === "primary") {
    switch (contrastColor) {
      case themeColors.colorBaseBlue500:
      case themeColors.colorBaseCyan400:
      case themeColors.colorBaseCyan600:
      case themeColors.colorBaseCyan700:
        return "primary-base-blue-650"
    }
  } else if (variant === "body") {
    switch (contrastColor) {
      case themeColors.colorBaseBlue700:
      case themeColors.colorBaseBlue500:
        return "text-base-white"
      case themeColors.colorBaseCyan600:
      case themeColors.colorBaseCyan700:
      case themeColors.colorBaseBlue300:
        return "text-base-blue-900"
    }
  } else if (variant === "title") {
    switch (contrastColor) {
      case themeColors.colorBaseBlue500:
      case themeColors.colorBaseBlue700:
        return "text-base-white"
      case themeColors.colorBaseCyan700:
        return "text-base-blue-700"
      case themeColors.colorBaseCyan600:
        return "text-base-blue-700"
    }
  } else if (variant === "preTitle") {
    switch (contrastColor) {
      case themeColors.colorBaseCyan700:
        return "text-base-blue-700"
      case themeColors.colorBaseCyan600:
        return "text-base-blue-700"
      case themeColors.colorBaseBlue500:
        return "text-base-cyan-500"
      case themeColors.colorBaseBlue700:
        return "text-base-cyan-500"
    }
  } else if (variant === "links") {
    switch (contrastColor) {
      case themeColors.colorBaseCyan700:
        return "text-base-blue-800"
      case themeColors.colorBaseCyan600:
        return "text-base-blue-800"
      default:
        return "text-base-cyan-700"
    }
  }
  return ""
}
