import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

type Props = {
  opacity: number
  fullWidth?: boolean
  overlayIndex?: boolean
}

const Overlay = ({ opacity, fullWidth, overlayIndex }: Props) => {
  const classes = useStyles()

  return (
    <div
      className={classNames(classes.root, {
        [classes.fullWidth]: fullWidth,
        [classes.overlayIndex]: overlayIndex
      })}
      style={{ opacity }}
    />
  )
}

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: "#000",
    height: "100%",
    width: "100%"
  },
  overlayIndex: {
    zIndex: 1
  },
  fullWidth: {
    marginLeft: "calc(50% - 50vw)",
    width: "100vw"
  }
}))

export default Overlay
