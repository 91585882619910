import { useState } from "react"
import { useTranslation } from "react-i18next"
import { BlockProps } from "@starrepublic/epi/cms"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import Tooltip from "@material-ui/core/Tooltip"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import GetAppIcon from "@material-ui/icons/GetApp"
import ImageSharpIcon from "@material-ui/icons/ImageSharp"
import InsertDriveFileSharpIcon from "@material-ui/icons/InsertDriveFileSharp"
import PictureAsPdfSharpIcon from "@material-ui/icons/PictureAsPdfSharp"

import { ScrollBox } from "common/ScrollBox/ScrollBox"
import Typography from "common/Typography"
import { eventPdfTracker } from "services/StarGA"
import EnhancedTableHead from "./EnhancedTableHead"
import { FileDataList, MediaBrowserContent, Order } from "./types"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export type MediaBrowserBlockProps = BlockProps<MediaBrowserContent>

const MediaBrowserBlock: React.FC<MediaBrowserBlockProps> = ({
  content: {
    desktopFitToHeight = true,
    pageSize: defaultRowPerPage,
    ...content
  },
  ...props
}) => {
  const classes = useStyles()
  const [order, setOrder] = useState<Order>("asc")
  const [orderBy, setOrderBy] = useState<keyof FileDataList>("type")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowPerPage || 11)
  const isMdDown = useMediaQuery(({ breakpoints }) => breakpoints.down("md"))

  const { t } = useTranslation()

  const rows = (content.folderData && content.folderData.files) || null

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof FileDataList
  ) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 11))
    setPage(0)
  }

  const handlePdfDownload = (url: string) => {
    if (url.endsWith(".pdf")) {
      eventPdfTracker(url)
    }
  }

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, ((rows && rows.length) || 0) - page * rowsPerPage)

  const iconRenderType = (iconType: string | number) => {
    switch (iconType) {
      case "PdfFile":
        return <PictureAsPdfSharpIcon />
      case "ImageFile":
        return <ImageSharpIcon />
      default:
        return <InsertDriveFileSharpIcon />
    }
  }

  return (
    rows && (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {content.heading && (
            <Box pb={[2, 4]}>
              <Typography variant="headline2" id="tableTitle">
                {content.heading}
              </Typography>
            </Box>
          )}
          <ScrollBox
            minHeight={"200px"}
            enable={desktopFitToHeight && !isMdDown}
          >
            <TableContainer className={classes.tableContainer}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody className={classes.tableCell}>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-${index}`

                      return (
                        <TableRow tabIndex={-1} key={row.name + labelId}>
                          <Hidden xsDown>
                            <TableCell padding="checkbox">
                              <div>{iconRenderType(row.type)}</div>
                            </TableCell>
                          </Hidden>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <Grid container wrap="nowrap">
                              <Hidden smUp>
                                <Box pr={2}>
                                  <div>{iconRenderType(row.type)}</div>
                                </Box>
                              </Hidden>
                              <Typography
                                className={classes.name}
                                variant="body1"
                              >
                                {row.name}
                              </Typography>
                            </Grid>
                          </TableCell>
                          <Hidden xsDown>
                            <TableCell align="right">
                              <Typography variant="body1">
                                {row.type}
                              </Typography>
                            </TableCell>
                          </Hidden>
                          <TableCell
                            className={classes.tableDownload}
                            align="right"
                          >
                            <div>
                              <Tooltip
                                placement="top"
                                arrow
                                title={t("browser.download_file")}
                              >
                                <a
                                  href={row.url.toString()}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download
                                  onClick={() =>
                                    handlePdfDownload(row.url.toString())
                                  }
                                >
                                  <IconButton className={classes.downloadIcon}>
                                    <GetAppIcon fontSize="small" />
                                  </IconButton>
                                </a>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </ScrollBox>
          {rows.length > rowsPerPage && (
            <Box pt={[2, 4]}>
              <TablePagination
                labelRowsPerPage={t("browser.rows_per_page")}
                className={classes.pagination}
                nextIconButtonProps={{ className: classes.nextIcon }}
                backIconButtonProps={{ className: classes.backIcon }}
                rowsPerPageOptions={
                  // Customizes the options of the rows per page select field.
                  // If less than two options are available, no select field will be displayed.
                  // To show it again use [10, 25, 50, 100]
                  [rowsPerPage]
                }
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          )}
        </Paper>
      </div>
    )
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, breakpoints, spacing }) => ({
    root: {
      width: "100%",
      height: "100%",
      display: "flex"
    },
    paper: {
      width: "100%",
      marginTop: spacing(4),
      display: "flex",
      flexDirection: "column",
      [breakpoints.down("xs")]: {
        marginBottom: spacing(2),
        marginTop: spacing(2)
      }
    },
    tableContainer: {
      flexGrow: 1,
      overflowX: "visible",
      "& .MuiTableHead-root": {
        position: "sticky",
        top: 0,
        backgroundColor: themeColors.white,
        zIndex: 2
      }
    },

    table: {
      [breakpoints.down("xs")]: {
        minWidth: "auto"
      }
    },
    downloadIcon: {
      "&:hover": {
        borderRadius: "50%",
        backgroundColor: themeColors.lightGray,
        [breakpoints.down("xs")]: {
          backgroundColor: themeColors.white
        }
      },
      [breakpoints.down("xs")]: {
        padding: `${spacing(2)}px 0`
      }
    },
    pagination: {
      "& > div": {
        width: 300,
        padding: 0,
        justifyContent: "center",
        backgroundColor: themeColors.lightGray,
        border: `1px solid ${themeColors.grayBorder}`,
        [breakpoints.down("xs")]: {
          width: "100%"
        }
      },
      display: "flex",
      justifyContent: "center",
      "& .MuiTablePagination-actions": {
        margin: 0
      },
      "& .MuiTablePagination-spacer": {
        flex: "none"
      }
    },
    tableCell: {
      "& .MuiTableCell-root": {
        [breakpoints.down("xs")]: {
          borderBottom: 0
        }
      }
    },
    nextIcon: {
      position: "absolute",
      top: 0,
      right: 0
    },
    backIcon: {
      position: "absolute",
      top: 0,
      left: 0
    },
    name: {
      wordBreak: "break-all",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-inline-box",
      "-webkit-line-clamp": "1",
      lineClamp: 1,
      "-webkit-box-orient": "vertical",
      [breakpoints.down("xs")]: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 230
      }
    },
    tableDownload: {
      "&:last-child": {
        padding: 0
      }
    }
  })
)

export default MediaBrowserBlock
