import { useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"

import Button from "common/Button"
import LinkButton from "common/LinkButton"
import Typography from "common/Typography"
import ArrowRight from "icons/ArrowRight"

type Props = {
  heading: string
  text: string
  link: string
  readMoreUrl: string
  theme: string
}

const dark = "#103D82"

const AccordionItem: React.FC<Props> = ({
  heading,
  text,
  link,
  readMoreUrl,
  theme
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(!open)
  }

  return (
    <Accordion
      onClick={handleToggle}
      style={{ backgroundColor: theme }}
      classes={{
        expanded: classes.expanded,
        root: classes.root
      }}
    >
      <AccordionSummary
        expandIcon={
          open ? (
            <RemoveIcon
              className={classNames(classes.iconGray, {
                [classes.light]: theme === dark
              })}
            />
          ) : (
            <AddIcon
              className={classNames(classes.red, {
                [classes.blue]: theme === dark
              })}
            />
          )
        }
        classes={{
          root: theme === dark ? classes.lightSummery : classes.summery,
          content: classes.summeryContent
        }}
      >
        <Typography
          className={classNames({
            [classes.lightText]: theme === dark
          })}
          variant="headline6"
        >
          {heading}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Typography
            variant="preamble"
            className={classNames({ [classes.lightText]: theme === dark })}
          >
            {text}
          </Typography>
          {link && readMoreUrl && (
            <Box pt={1}>
              <LinkButton link={readMoreUrl}>
                <Button variant="text" endIcon={<ArrowRight />}>
                  {t("product.description_read_more")}
                </Button>
              </LinkButton>
            </Box>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    "&:before": {
      top: 0,
      height: 0
    }
  },
  expanded: {
    margin: 0
  },
  summery: {
    borderTop: `1px solid ${themeColors.grayBorder}`,
    borderBottom: "none"
  },
  summeryContent: {
    margin: `${spacing(3)}px 0`
  },
  iconGray: {
    color: themeColors.mediumGray
  },
  light: {
    color: themeColors.white,
    opacity: 0.5
  },
  red: {
    color: themeColors.primaryRed
  },
  blue: {
    color: themeColors.primaryCyan
  },
  lightSummery: {
    borderTop: `1px solid rgb(255,255,255, .5)`,
    borderBottom: "none"
  },
  lightText: {
    color: themeColors.white
  },
  linkWrapper: {
    padding: `${spacing(1)}px 0px`,
    "& div": {
      color: themeColors.primaryCyan
    }
  },
  linkStyle: {
    transition: "0.3s",
    color: themeColors.primaryCyan,
    paddingRight: spacing(1)
  }
}))

export default AccordionItem
