import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"

type Props = {
  title: string
  Icon: React.FC
}

const CheckoutSessionHeader: React.FC<Props> = ({ Icon, title }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <div className={classes.root}>
        <Icon />
        <Typography
          className={classes.textMargin}
          variant="headline4"
          align="left"
        >
          {title}
        </Typography>
      </div>
    </Grid>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors, gridSpacing } }) => ({
    root: {
      padding: spacing(3, 3),
      display: "flex",
      borderBottom: `1px solid ${themeColors.lightGray}`,
      alignItems: "center"
    },
    textMargin: {
      marginLeft: gridSpacing
    }
  })
)

export default CheckoutSessionHeader
