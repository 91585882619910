import Helmet from "react-helmet"
import {
  ContentPropertyInfo,
  ContentProps,
  PageInfo
} from "@starrepublic/epi/cms"

import ErrorPageComponent from "./ErrorPageComponent"
import { ErrorPageContent } from "."

type OwnProps = {
  content: ErrorPageContent
  propsInfo: ContentPropertyInfo<ErrorPageContent>
  pageInfo: PageInfo | undefined
}

type Props = OwnProps & ContentProps

const Error: React.FC<Props> = ({ content }) => {
  return (
    <>
      <Helmet
        meta={[
          {
            name: "prerender-status-code",
            content: "404"
          }
        ]}
      />
      <ErrorPageComponent content={content} />
    </>
  )
}

export default Error
