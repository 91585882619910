import { SvgIconProps } from "@material-ui/core/SvgIcon"

const Spain: React.FC<SvgIconProps> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48" {...props}>
    <g>
      <path
        fill="#C60B1E"
        d="M48,16H0V8c0-1.105,0.895-2,2-2h44c1.105,0,2,0.895,2,2V16z"
      />
      <rect y="16" fill="#FFC300" width="48" height="16" />
      <path
        fill="#C60B1E"
        d="M48,40c0,1.105-0.895,2-2,2H2c-1.105,0-2-0.895-2-2v-8h48V40z"
      />
      <polygon
        fill="#91443A"
        points="14,20 14,18 6,18 6,20 8,20 8,22 6,22 6,27.332 10,30 14,27.332 14,22 12,22 12,20 "
      />
    </g>
  </svg>
)

export default Spain
