import request from "@starrepublic/epi/api/request"

if (!window["ENVIRONMENT"]) {
  let lang = "en"
  const path = window.location.pathname.split("/").filter((p) => {
    return p !== ""
  })

  if (
    path.length > 0 &&
    (path[0].toLowerCase() === "en" ||
      path[0].toLowerCase() === "fi" ||
      path[0].toLowerCase() === "sv" ||
      path[0].toLowerCase() === "en-gb" ||
      path[0].toLowerCase() === "es" ||
      path[0].toLowerCase() === "no")
  ) {
    lang = path[0]
  }

  request.defaults.headers.common["accept-language"] = lang
}
