import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import TextField from "common/TextField"
import Typography from "common/Typography"
import FenceGateOpeningIcon from "icons/FenceCalculator/FenceGateOpeningIcon"
import WoodenPost from "icons/FenceCalculator/WoodenPost"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"
import { themeColors } from "theme"
import ProductsContainer from "./ProductsContainer"
import { FenceCalculatorBlockProps, FenceProductVariant } from "./types"
import { deselectProduct, getAllProducts, getSelectedProduct } from "./utils"

type FenceGateOpeningsProps = {
  content: FenceCalculatorBlockProps
}

const FenceGateOpenings = ({ content }: FenceGateOpeningsProps) => {
  const { gateInsulatorProducts, gateHandleProducts, currencySymbol } = content
  const classes = useStyles()
  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))

  const {
    numberOfFreeStandingGateOpenings,
    setNumberOfFreeStandingGateOpenings,
    numberOfGateOpeningsInCorners,
    setNumberOfGateOpeningsInCorners,
    selectedGateInsulator,
    setSelectedGateInsulator,
    selectedGateHandle,
    setSelectedGateHandle,
    numberOfWireRows,
    selectedAnimal,
    selectedCategory,
    fenceCalculatorLabels,
    gateInsulatorPackagesNeeded
  } = useFenceCalculatorStore()

  const allGateInsulatorProducts: FenceProductVariant[] = getAllProducts(
    gateInsulatorProducts,
    [selectedAnimal, selectedCategory].concat()
  )

  const allGateHandleProducts: FenceProductVariant[] = getAllProducts(
    gateHandleProducts,
    [selectedAnimal, selectedCategory].concat()
  )

  const gateHandlePackSize =
    selectedGateHandle.variant && selectedGateHandle.variant.quantity

  const selectProductGateInsulator = (articleNumber: string) => {
    const product = getSelectedProduct(allGateInsulatorProducts, articleNumber)
    if (!product || deselectProduct(selectedGateInsulator, product)) {
      return setSelectedGateInsulator({} as FenceProductVariant)
    }
    setSelectedGateInsulator(product)
  }
  const selectProductGateHandle = (articleNumber: string) => {
    const product = getSelectedProduct(allGateHandleProducts, articleNumber)
    if (!product || deselectProduct(selectedGateHandle, product)) {
      return setSelectedGateHandle({} as FenceProductVariant)
    }
    setSelectedGateHandle(product)
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection={isSmUp ? "row" : "column"}
        px={2}
        py={5}
      >
        <Box
          px={4}
          minWidth="50%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box className={classes.iconContainer}>
            <FenceGateOpeningIcon className={classes.fenceIcon} />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="end">
            <Box mt={2}>
              <Typography variant="subheading1" className={classes.formLabel}>
                {fenceCalculatorLabels.gatesOpenings}
              </Typography>
            </Box>
            <TextField
              select
              value={numberOfFreeStandingGateOpenings}
              onChange={(event) =>
                setNumberOfFreeStandingGateOpenings(Number(event.target.value))
              }
              fullWidth
              variant="outlined"
              inputProps={{
                "aria-label": fenceCalculatorLabels.gatesOpenings
              }}
            >
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
            </TextField>
          </Box>
        </Box>

        <Box
          px={4}
          minWidth="50%"
          display="flex"
          flexDirection="column"
          justifyContent="end"
        >
          <Box mt={2}>
            <Typography variant="subheading1" className={classes.formLabel}>
              {fenceCalculatorLabels.gatesOpeningsCorners}
            </Typography>
          </Box>
          <TextField
            select
            value={numberOfGateOpeningsInCorners}
            onChange={(event) =>
              setNumberOfGateOpeningsInCorners(Number(event.target.value))
            }
            fullWidth
            variant="outlined"
            inputProps={{
              "aria-label": fenceCalculatorLabels.gatesOpeningsCorners
            }}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
          </TextField>
        </Box>
      </Box>

      <Box style={{ background: themeColors.primaryLightCyan }}>
        <Box width={isSmUp ? "50%" : "100%"} px={2} pt={3}>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Grid item xs={6}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                width="100%"
                height={107}
                border={`3px solid ${themeColors.colorBaseBlue700}`}
                maxWidth={147}
                bgcolor={themeColors.white}
              >
                <WoodenPost style={{ fontSize: 43 }} />
                <Typography className={classes.infoLabel}>
                  {fenceCalculatorLabels.gatesPostsHeading}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
              <Typography className={classes.lightItalicFont}>
                {fenceCalculatorLabels.gatesPostsDescription}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box m={2}>
          <Divider />
        </Box>
      </Box>
      <Box style={{ background: themeColors.primaryLightCyan }}>
        <Typography className={classes.chooseProductLabel}>
          {fenceCalculatorLabels.gateInsulatorsChoose}
        </Typography>
        <ProductsContainer
          products={allGateInsulatorProducts}
          selectedProduct={selectedGateInsulator}
          onSelectProduct={selectProductGateInsulator}
          currencySymbol={currencySymbol}
        />
        {numberOfFreeStandingGateOpenings + numberOfGateOpeningsInCorners > 0 &&
          selectedGateInsulator.name && (
            <Box
              pt={3}
              px={2}
              display="flex"
              flexDirection={isSmUp ? "row" : "column"}
            >
              <Typography className={classes.infoLabel}>
                {fenceCalculatorLabels.gateInsulatorsQuantity}
                <span>{gateInsulatorPackagesNeeded}</span>
              </Typography>
              {numberOfFreeStandingGateOpenings +
                numberOfGateOpeningsInCorners >
                0 && (
                <Typography className={classes.lightItalicFont}>
                  ({numberOfWireRows}{" "}
                  {fenceCalculatorLabels.fenceGateInsulatorsPerGateOpening})
                </Typography>
              )}
            </Box>
          )}
        <Box m={2}>
          <Divider />
        </Box>

        <Typography className={classes.chooseProductLabel}>
          {fenceCalculatorLabels.gateHandlesChoose}
        </Typography>
        <ProductsContainer
          products={allGateHandleProducts}
          selectedProduct={selectedGateHandle}
          onSelectProduct={selectProductGateHandle}
          currencySymbol={currencySymbol}
        />
        {numberOfFreeStandingGateOpenings + numberOfGateOpeningsInCorners > 0 &&
          selectedGateHandle.name && (
            <Box
              pt={3}
              px={2}
              display="flex"
              flexDirection={isSmUp ? "row" : "column"}
            >
              <Typography className={classes.infoLabel}>
                {fenceCalculatorLabels.gateHandlesQuantity}
                <span>
                  {Math.ceil(
                    ((numberOfFreeStandingGateOpenings +
                      numberOfGateOpeningsInCorners) *
                      numberOfWireRows) /
                      gateHandlePackSize
                  )}
                </span>
              </Typography>
              {numberOfFreeStandingGateOpenings +
                numberOfGateOpeningsInCorners >
                0 && (
                <Typography className={classes.lightItalicFont}>
                  ({numberOfWireRows}
                  {fenceCalculatorLabels.fenceGateHandlesPerGateOpening})
                </Typography>
              )}
            </Box>
          )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    formLabel: {
      fontWeight: 700,
      color: themeColors.black
    },
    chooseProductLabel: {
      color: themeColors.black,
      padding: spacing(3, 2),
      fontSize: "1.25rem",
      lineHeight: 1.2,
      fontWeight: 700
    },
    infoLabel: {
      fontSize: ".875rem",
      color: themeColors.primaryBaseBlue,
      fontWeight: 700,
      "& span": {
        color: themeColors.primaryBlue,
        marginLeft: spacing(0.5),
        [breakpoints.up("sm")]: {
          margin: spacing(0, 0.5)
        }
      }
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center"
    },
    fenceIcon: {
      width: 150,
      height: 92
    },
    lightItalicFont: {
      fontSize: "0.875rem",
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: 1.4
    }
  })
)

export default FenceGateOpenings
