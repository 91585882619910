import { asyncState, createStore } from "global-hook-store"

import api from "api"
import {
  AdyenPayRequest,
  PaymentMethodResponse,
  PaymentMethodsRequest
} from "api/adyenPayment"

const PaymentStore = createStore(
  {
    paymentValid: false,
    paymentInProgress: false,
    error: null as null | any,
    paymentMethods: asyncState<PaymentMethodResponse>(),
    state: asyncState<AdyenPayRequest>()
  },
  {
    getPaymentMethods: async (
      _,
      payload: PaymentMethodsRequest,
      { asyncAction }
    ) =>
      asyncAction("paymentMethods", api.adyenPayment.getPaymentMethods(payload))
  }
)

export default PaymentStore
