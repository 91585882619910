import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const VkIcon: React.FC<Props> = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M13.0718 19.7097C4.87196 19.7097 0.194878 14.1935 0 5H4.10742C4.24234 11.7223 7.27044 14.576 9.66894 15.1644V5H13.5366V10.7956C15.9051 10.5456 18.3935 7.85368 19.233 5H23.1006C22.7898 6.51642 22.1616 7.95304 21.256 9.21902C20.3503 10.485 19.1867 11.5528 17.8389 12.3548C19.3374 13.0836 20.6614 14.116 21.7234 15.3838C22.7854 16.6516 23.5614 18.126 24 19.7097H19.7427C19.3498 18.3321 18.5514 17.099 17.4474 16.1649C16.3433 15.2307 14.9829 14.6371 13.5366 14.4584V19.7097H13.0718Z"
      fill="#103D82"
    />
  </SvgIcon>
)

export default VkIcon
