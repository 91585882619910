import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Fade from "@material-ui/core/Fade"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Clear from "@material-ui/icons/Clear"
import Search from "@material-ui/icons/Search"

import pageStore from "store/PageStore"
import ProductListingStore from "store/ProductListingStore"

const QuickFilter = () => {
  const { t } = useTranslation()

  const searchInputRef = useRef<HTMLInputElement>(null)
  const classes = useStyles()
  const [query, setQuery] = useState("")
  const {
    state: { searchedProductList, filterQuery },
    actions: {
      quickFilterSearch,
      setFilterQuery,
      addFilteredProducts,
      resetQuickFilter,
      resetFilteredProducts,
      setSortProperty
    }
  } = useStore(ProductListingStore)

  const {
    state: { page }
  } = useStore(pageStore)

  const handleSubmit = () => {
    if (!searchedProductList.loading && query.length > 0) {
      resetFilteredProducts().then(() => {
        setFilterQuery(query).then(() => {
          quickFilterSearch().then(() => {
            addFilteredProducts()
          })
        })
      })
    }
  }

  useEffect(
    () => {
      if (query.length === 0 && searchedProductList.data !== null) {
        resetQuickFilter().then((state) => {
          const selectedSortSetting =
            state.productList.data &&
            state.productList.data.sortSettings.find((sortSetting) => {
              return sortSetting.isSelected
            })

          if (selectedSortSetting && selectedSortSetting.id !== state.sort) {
            setSortProperty(selectedSortSetting.id)
          }
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query, resetQuickFilter]
  )

  useEffect(
    () => {
      if (query !== filterQuery) {
        setQuery(filterQuery)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterQuery]
  )

  return (
    <div className={classes.root}>
      <button
        type="submit"
        className={classes.searchButton}
        onClick={handleSubmit}
        disabled={searchedProductList.loading}
      >
        <Search />
      </button>
      <TextField
        name="searchTerm"
        variant="filled"
        placeholder={t("search.search_in_subcategory").replace(
          "{subCategory}",
          (page.data && page.data.title) || ""
        )}
        className={classes.textField}
        inputRef={searchInputRef}
        autoComplete={"off"}
        disabled={searchedProductList.loading}
        value={query}
        onChange={(e) => {
          setQuery(e.currentTarget.value)
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handleSubmit()
          }
        }}
      />
      <Fade in={filterQuery.length > 0}>
        <button
          className={classes.searchButton}
          onClick={() => {
            setQuery("")
          }}
          disabled={searchedProductList.loading}
        >
          <Clear />
        </button>
      </Fade>
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    margin: "0 auto"
  },
  textField: {
    width: "100%",
    color: themeColors.darkGray,
    overflow: "hidden",
    background: themeColors.offWhite,
    padding: 0,
    "& .MuiFilledInput-input": {
      padding: spacing(3) - 1,
      paddingLeft: spacing(1),
      background: themeColors.offWhite
    },
    "& .MuiFilledInput-underline:before": {
      display: "none"
    },
    "& .MuiFilledInput-underline:after": {
      display: "none"
    }
  },
  searchButton: {
    background: themeColors.offWhite,
    color: themeColors.darkGray,
    border: "none",
    outline: 0,
    width: 64,
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "none"
    }
  }
}))

export default QuickFilter
