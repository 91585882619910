import { createStore } from "global-hook-store"

const drawerStateStore = createStore(
  {
    isCartOpen: false,
    isMobileMainMenuOpen: false,
    isProductMenuOpen: false,
    // isSearchVisible: false,
    isMyAccountOpen: false,
    isShoppingListOpen: false
  },
  {
    setCartDrawer: (currentState, open: boolean) => ({
      ...currentState,
      isCartOpen: open
    }),
    setMobileMainMenuDrawer: (currentState, open: boolean) => ({
      ...currentState,
      isMobileMainMenuOpen: open
    }),
    setProductMenuDrawer: (currentState, open: boolean) => ({
      ...currentState,
      isProductMenuOpen: open
    }),
    setAccountDrawer: (currentState, open: boolean) => ({
      ...currentState,
      isMyAccountOpen: open
    }),
    setShoppingListDrawer: (currentState, open: boolean) => ({
      ...currentState,
      isShoppingListOpen: open
    })
  }
)

export default drawerStateStore
