import { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const Confirmation: React.FC<Props> = (props) => (
  <svg
    {...props}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.3 18.7C41.8 20.4 42 22.1 42 24C42 35 33 44 22 44C11 44 2 35 2 24C2 13 11 4 22 4C25.9 4 29.5 5.1 32.5 7"
      stroke="#77AD1C"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M14 20L22 28L44 6"
      stroke="#77AD1C"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export default Confirmation
