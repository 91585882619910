import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const FarmDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M12 26.667v-9.538l8-6.462 8 6.462v9.538h-8m-8 0h8m-8 0H4V6s2.667-1.333 5.333-1.333C12 4.667 14.667 6 14.667 6v4M20 26.667v-6.212"
      fill="none"
    />
  </SvgIcon>
)

export default FarmDeLaval
