import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"
import debounce from "lodash/debounce"

import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { SearchResultType } from "api/search"
import SearchContent from "common/Header/SearchBar/SearchContent"
import Search from "icons/Search"
import SearchStore from "store/SearchStore"

const SearchComponent: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("")
  const [isResultOpen, setResultOpen] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const DEBOUNCED_SEARCH_TIMER = 1000
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { result },
    actions: { asyncSearch, resetResult, forceLoading }
  } = useStore(SearchStore)

  useEffect(
    () => {
      if (inputRef.current) {
        inputRef.current.value = ""
      }
      setSearchTerm("")
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.href]
  )

  const valueIsValid = (value: string) => {
    return value && value.length > 2
  }

  const handleClose = (e?: React.MouseEvent<Document, MouseEvent>) => {
    setResultOpen(false)
    if (e) {
      e.preventDefault()
    }
    if (result.data) {
      resetResult()
    }
  }

  const handleSearchChange = debounce((value: string) => {
    debouncedSearch(value)
    setSearchTerm(value)
    setResultOpen(true)
  }, DEBOUNCED_SEARCH_TIMER)

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (
      inputRef.current &&
      valueIsValid(inputRef.current.value) &&
      inputRef.current.value !== searchTerm
    ) {
      forceLoading()
    }
  }

  const searchLimit = useMediaQuery("(min-width:960px)") ? 8 : 4

  const debouncedSearch = (value: string) => {
    if (valueIsValid(value)) {
      asyncSearch({
        type: SearchResultType.Products,
        q: value,
        limit: searchLimit,
        offset: 0
      })
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <form id="search-form" onSubmit={handleSubmit} className={classes.form}>
        <div className={classes.textFieldArea}>
          <button type="submit" className={classes.searchButton}>
            <Search />
          </button>
          <TextField
            autoFocus
            name="searchTerm"
            variant="filled"
            inputRef={inputRef}
            placeholder={t("search.placeholder_text")}
            className={classes.textField}
            onChange={(e) => {
              handleSearchChange(e.currentTarget.value)
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSubmit(e)
              }
            }}
          />
        </div>
      </form>

      <ClickAwayListener onClickAway={() => handleClose()}>
        <Grow in={isResultOpen}>
          <Paper className={classes.paper}>
            <SearchContent
              searchTerm={searchTerm}
              onClose={() => {
                handleClose()
              }}
            />
          </Paper>
        </Grow>
      </ClickAwayListener>
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors, pageMaxWidth } }) => ({
    form: {
      width: "100%",
      padding: `0 ${spacing(7)}px`,
      [breakpoints.down("xs")]: {
        padding: 0
      }
    },
    textFieldArea: {
      display: "flex",
      width: "100%",
      margin: "0 auto"
    },
    textField: {
      width: "100%",
      color: themeColors.darkGray,
      overflow: "hidden",
      background: themeColors.white,
      padding: 0,
      "& .MuiFilledInput-input": {
        padding: spacing(3) - 1,
        background: themeColors.white
      },
      "& .MuiFilledInput-underline:before": {
        display: "none"
      },
      "& .MuiFilledInput-underline:after": {
        display: "none"
      }
    },
    searchButton: {
      background: themeColors.white,
      color: themeColors.darkGray,
      border: "none",
      outline: 0,
      width: 64,
      padding: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 0,
      "&:hover": {
        backgroundColor: "none"
      }
    },
    paper: {
      width: "100%",
      position: "absolute",
      zIndex: 10
    }
  })
)

export default SearchComponent
