import { Helmet } from "react-helmet"

const StarGAHelmet: React.FC = () => {
  return (
    <Helmet
      script={[
        {
          type: "text/javascript",
          innerHTML: `
            window.dataLayer = window.dataLayer || [];

            if (window.EPI_CURRENT_CURRENCY) {
                window.dataLayer.push({
                        currency: window.EPI_CURRENT_CURRENCY
                    }
                )};
            (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://gtmss.delaval.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', '${window.analytics.id}');`
        }
      ]}
    />
  )
}

export default StarGAHelmet
/*
window.dataLayer.push({ originalLocation: document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search });
window.EPI_CURRENT_CURRENCY = 'EUR';
*/
