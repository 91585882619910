import request from "@starrepublic/epi/api/request"

export type ContactPayload = {
  pageId: number
  userId?: string
  name: string
  phone?: string
  email: string
  message?: string
}

const contact = {
  send: (data: ContactPayload) => request.post(`/contact/send/`, data)
}

export default contact
