import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore, { useLocalStore } from "global-hook-store"

import ButtonBase from "@material-ui/core/ButtonBase"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"

import { UpdateListArticle } from "api/anonymousShoppingList"
import AddToCartCheckIcon from "icons/AddToCartCheckIcon"
import AddToWishlistIcon from "icons/AddToWishlist"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import AddToCartStore from "store/AddToCartStore"
import AnonymousShoppingListStore from "store/AnonymousShoppingListStore"
import snackbarStore from "store/SnackbarStore"

type Props = {
  code: string
  large?: boolean
  mobile?: boolean
  name: string
  image: string
  variationValue: string
  link?: string
}

const AddToShoppingList: React.FC<Props> = ({
  code,
  large,
  mobile,
  image,
  variationValue,
  name
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const CHECKMARK_TIMEOUT = 2000

  const {
    actions: { openSnackbar }
  } = useStore(snackbarStore)

  const {
    state: { quantity, isLoading },
    actions: { setQuantity }
  } = useLocalStore(AddToCartStore)

  const {
    actions: { fetchShoppingList, addToShoppingList }
  } = useStore(AnonymousShoppingListStore)

  const textFieldRef = useRef<HTMLInputElement>(null)

  const [successfullyAdded, setSuccessfullyAdded] = useState(false)

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSuccessfullyAdded(false)
    setQuantity(parseInt(e.target.value, 10) || 0)
  }

  const handleAddToShoppingListButton = () => {
    if (!isLoading) {
      setSuccessfullyAdded(false)
      fetchShoppingList().then((state) => {
        if (state.shoppingList && state.shoppingList.data) {
          const updateList = {
            articleNumber: code,
            quantity
          } as UpdateListArticle
          addToShoppingList(updateList).then((state) => {
            fetchShoppingList()
            setSuccessfullyAdded(true)
            setTimeout(() => {
              setSuccessfullyAdded(false)
            }, CHECKMARK_TIMEOUT)

            const discountedPrice = {
              displayPrice: "",
              amount: 0
            }

            const displayPrice = {
              displayPrice: "",
              amount: 0
            }

            const options = {
              code,
              image,
              displayName: name,
              variationValue,
              discountedPrice,
              displayPrice,
              message: t("shopping.item_added_to_shopping_list")
            }

            openSnackbar({
              options,
              type: "product"
            })
          })
        }
      })
    }
  }

  return (
    <div className={classes.root}>
      <TextField
        variant="outlined"
        onChange={handleQuantityChange}
        onClick={(e) => {
          e.stopPropagation()
        }}
        inputRef={textFieldRef}
        InputProps={{
          classes: {
            root: classes.quantityInput,
            notchedOutline: classes.border,
            input: "add-to-wishlist-quantity-input"
          }
        }}
        inputProps={{
          style: {
            textAlign: "center",
            padding: 0,
            height: "100%",
            fontSize: 14
          },
          min: 0
        }}
        className={classNames(classes.quantity, {
          [classes.bigQuantity]: large,
          [classes.mobile]: mobile
        })}
        value={quantity}
      />

      <ButtonBase
        onClick={(e) => {
          e.stopPropagation()
          handleAddToShoppingListButton()
        }}
        className={classNames("add-to-wishlist-button", classes.button, {
          [classes.bigButton]: large,
          [classes.mobile]: mobile
        })}
      >
        {
          <div
            className={classNames(classes.buttonText, {
              [classes.buttonTextMobile]: mobile
            })}
          >
            {isLoading ? (
              <ButtonLoaderIcon
                className={classNames({
                  [classes.iconMargin]: large
                })}
              />
            ) : successfullyAdded ? (
              <AddToCartCheckIcon
                className={classNames({
                  [classes.iconMargin]: large
                })}
              />
            ) : (
              <>
                <AddToWishlistIcon
                  className={classNames({
                    [classes.iconMargin]: large
                  })}
                />
              </>
            )}
            {large ? (
              <span className={classes.buttonInnerText}>
                {t("product.add_to_shopping_list")}
              </span>
            ) : null}
          </div>
        }
      </ButtonBase>
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors }, breakpoints }) => ({
    root: {
      display: "flex",
      justifyContent: "flex-end",
      [breakpoints.down("md")]: {
        width: "100%"
      },
      "& div > fieldset": {
        borderBottom: 0,
        borderRight: 0,
        borderColor: themeColors.whiteGray
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          border: "1px solid",
          borderColor: themeColors.darkBlue
        },
        "&.Mui-focused fieldset": {
          borderColor: themeColors.darkBlue,
          border: "2px solid"
        }
      }
    },
    border: {
      borderRadius: 0
    },
    quantity: {
      width: 56,
      height: 56,
      borderRadius: 0,
      background: themeColors.white
    },
    bigQuantity: {
      width: 56,
      height: 56,
      borderBottom: `1px solid ${themeColors.whiteGray}`
    },
    quantityInput: {
      color: themeColors.darkGray,
      height: "100%"
    },
    button: {
      cursor: "pointer",
      color: themeColors.white,
      background: themeColors.primaryCyan,
      borderRadius: 0,
      height: 56,
      minWidth: 56
    },
    bigButton: {
      height: 56,
      cursor: "pointer"
    },
    buttonText: {
      fontSize: 14,
      fontWeight: 700,
      padding: "8px 12px 8px 12px",
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center"
    },
    buttonInnerText: {
      whiteSpace: "nowrap",
      marginRight: spacing(1)
    },
    iconMargin: {
      width: 32,
      marginRight: 12
    },
    disabledButton: {
      background: themeColors.lightGray,
      cursor: "default !important",
      color: themeColors.mediumGray
    },
    mobile: {
      width: 48,
      height: 48,
      minWidth: 48
    },
    buttonTextMobile: {
      padding: spacing(1)
    }
  })
)

export default AddToShoppingList
