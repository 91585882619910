import { useState } from "react"
import { useTranslation } from "react-i18next"
import DisplayOptionsContentArea from "@starrepublic/epi/cms/components/DisplayOptionsContentArea"
import { toRelativeLink } from "@starrepublic/epi/cms/components/RelativeLink"
import { BlockProps } from "@starrepublic/epi/cms/types/props"
import classNames from "classnames"
import { Form as FormikFrom, Formik, FormikActions } from "formik"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import { contrastSwitch } from "utils/contrastSwitch"
import DisplayOptions from "utils/ui/DisplayOptions"

import forms, { FormModel, FormResponse } from "api/forms"
import { ChildContainer } from "common/contentAreaContainers"
import { browserHistory } from "common/Root"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import contactModalStore from "store/ContactModalStore"
import SiteSettingStore from "store/SiteSettingStore"
import snackbarStore from "store/SnackbarStore"
import FormWrapper from "./FormWrapper"
import generateValidationSchema from "./helpers"
import { Form } from "./types"

type Props = BlockProps<Form>

const FormContainerBlock: React.FC<Props> = ({
  content,
  pageInfo,
  propsInfo,
  blockInfo
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [response, setResponse] = useState<FormResponse | null>(null)
  const [error, setError] = useState<any>("")

  const {
    state: { isOpen },
    actions: { reset }
  } = useStore(contactModalStore)

  const {
    actions: { openSnackbar }
  } = useStore(snackbarStore)

  const {
    state: { isOnePager }
  } = useStore(SiteSettingStore)

  const handleSubmit = async (
    values: Record<string, string>,
    { setSubmitting }: FormikActions<Record<string, string>>
  ) => {
    const newValue = () => {
      const updateValue = {}
      for (const [key, value] of Object.entries(values)) {
        updateValue[`${key}`] = `${value}`
      }
      return updateValue
    }

    const model: FormModel = {
      values: newValue(),
      hostedPage: content.__FormHostedPage,
      submissionId: "",
      stepIndex: content.__FormCurrentStepIndex
    }

    const formSubmitData = () => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "form_submit",
          form_id: content.__FormHostedPage,
          form_name: content.title,
          form_type: "dynamic",
          form_fields: content.elementsArea.length - 1
        })
      }
    }

    setSubmitting(true)
    try {
      const formResponse = await forms.submitForm(content.__FormGuid, model)
      setSubmitting(false)
      setResponse(formResponse)
      formSubmitData()
      if (isOpen) {
        openSnackbar({
          options: { message: t("common.contact_me_modal_success_message") },
          type: "success"
        })
      }

      if (formResponse.redirectUrl) {
        if (isOpen) {
          reset()
        }
        browserHistory.push(toRelativeLink(formResponse.redirectUrl))
      }
    } catch (error) {
      setSubmitting(false)
      setError(error)
    }
  }

  return (
    <Box
      className={classNames({ [classes.border]: isOnePager })}
      margin="auto"
      height={1}
      maxWidth={688}
      py={4}
      px={{ xs: 2, sm: 4 }}
      id={blockInfo && blockInfo["_id"].toString()}
      bgcolor={content.theme}
    >
      <Box pb={3}>
        <Box mb={content.description ? 1 : 0}>
          <Typography
            variant="headline4"
            align={content.textAlignment}
            textWrap={content.textAlignment === "center" ? "balance" : "wrap"}
            className={
              classes[
                contrastSwitch({
                  variant: "title",
                  contrastColor: content.theme
                })
              ]
            }
          >
            {content.title}
          </Typography>
        </Box>
        <Typography
          variant="preamble"
          align={content.textAlignment}
          textWrap={content.textAlignment === "center" ? "balance" : "wrap"}
          className={
            classes[
              contrastSwitch({ variant: "body", contrastColor: content.theme })
            ]
          }
        >
          {content.description}
        </Typography>
      </Box>
      {error && (
        <Typography
          variant="body1"
          color="error"
          className={classes.errorMessage}
        >
          {error}
        </Typography>
      )}

      {content.elementsArea && (
        <Formik
          onSubmit={handleSubmit}
          validationSchema={generateValidationSchema(content.elementsArea)}
          initialValues={{}}
          validateOnChange
          validateOnBlur
        >
          {({ setFieldValue, isValid }) => {
            content.elementsArea = content.elementsArea.map((element) => ({
              ...{
                ...element,
                theme: content.theme,
                textAlignment:
                  element._type === "FormTextElementBlock" &&
                  content.textAlignment
              },
              setFieldValue,
              isValid
            }))
            return (
              <FormikFrom
                data-form-id={`dynamic-form-${content.__FormHostedPage}`}
                data-form-title={`dynamic-form-${content.title}`}
                noValidate
              >
                {response && response.isSuccess ? (
                  <Box id="dynamic-form-sent-success">
                    <ThemeXhtmlString
                      sectionTheme={content.theme}
                      style={{ textAlign: "center" }}
                      content={response.message}
                    />
                  </Box>
                ) : (
                  <DisplayOptionsContentArea
                    content={content.elementsArea}
                    propInfo={propsInfo.elementsArea}
                    pageInfo={pageInfo}
                    containWhen={(block) => DisplayOptions[block._type] !== 1}
                    childContainer={ChildContainer}
                    container={FormWrapper}
                  />
                )}
              </FormikFrom>
            )
          }}
        </Formik>
      )}
    </Box>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    errorMessage: {
      padding: spacing(1)
    },
    successMessage: {
      padding: spacing(1),
      marginBottom: spacing(5)
    },
    border: {
      padding: spacing(5),
      [breakpoints.down("xs")]: {
        padding: spacing(3)
      }
    },
    ["text-base-white"]: {
      color: themeColors.white
    },
    ["text-base-blue-700"]: {
      color: themeColors.colorBaseBlue700
    },
    ["text-base-blue-800"]: {
      color: themeColors.colorBaseBlue800
    },
    ["text-base-blue-900"]: {
      color: themeColors.colorBaseBlue900
    }
  })
)

export default FormContainerBlock
