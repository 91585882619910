import { Suspense } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import PersonIcon from "@material-ui/icons/Person"

import { conditionalImport } from "utils/conditionalImport "
import { countObjectDepth } from "utils/hierarchyDepthCounter"

import LinkText from "common/LinkText"
import ResponsiveImage from "common/ResponsiveImage"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import CircleFilled from "icons/CircleFilled"
import FacebookIcon from "icons/FacebookIcon"
import InstagramIcon from "icons/InstagramIcon"
import LaunchApp from "icons/LaunchApp"
import MilkLogo from "icons/MilkLogo"
import MilkLogoChina from "icons/MilkLogoChina"
import TwitterIcon from "icons/TwitterIcon"
import VkIcon from "icons/VkIcon"
import YoutubeIcon from "icons/YoutubeIcon"
import { eventFooterTracker } from "services/StarGA"
import SiteSettingStore from "store/SiteSettingStore"
import themeColors from "theme/colors"
import { FooterLink } from "types/content/footer"
import IconCircle from "./IconCircle"
import WeChatSocialLink from "./WeChatSocialLink"

const PublicSecurityChinaIcon = conditionalImport("PublicSecurityChinaIcon")

type Props = {
  onLinkClick?: () => void
}

const Footer: React.FC<Props> = ({ onLinkClick }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const isXsDown = useMediaQuery(({ breakpoints }) => breakpoints.down("xs"))

  const {
    state: { footer, countryCode, isOnePager }
  } = useStore(SiteSettingStore)

  const { paymentMethods } = footer

  const isChina = countryCode === "CN"

  const footerLink = (link: FooterLink, i: number) => {
    return link.href.startsWith("http") ? (
      <a key={i} href={link.href} target={link.target}>
        <Typography className={classes.footerLink}>
          {link.text}
          <LaunchApp className={classes.launchAppIcon} />
        </Typography>
      </a>
    ) : (
      <Link
        onClick={() => {
          onLinkClick
          eventFooterTracker(link.href, countObjectDepth(link), link.text)
        }}
        key={i}
        to={link.href}
        target={link.target}
      >
        <Typography className={classes.footerLink}>{link.text}</Typography>
      </Link>
    )
  }

  const headerLink = (link: string, header: string) => {
    if (!header) {
      return null
    }
    return link ? (
      <Link to={link}>
        <Typography variant="headline3" className={classes.footerTitle}>
          {header}
        </Typography>
      </Link>
    ) : (
      <Typography variant="headline3" className={classes.footerTitle}>
        {header}
      </Typography>
    )
  }

  return (
    <footer>
      {footer && (
        <Wrapper containerClassName={classes.container}>
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "column", md: "row" }}
            justifyContent="space-between"
            gridGap={32}
          >
            {!isOnePager && (
              <Box>
                {headerLink(
                  footer.productLinksHeaderLink,
                  footer.productLinksHeader
                )}
                {footer.productLinks &&
                  footer.productLinks.map((link: FooterLink, i) => {
                    return footerLink(link, i)
                  })}
              </Box>
            )}
            {!isOnePager && (
              <Box>
                {headerLink(
                  footer.internalLinksHeaderLink,
                  footer.internalLinksHeader
                )}
                {footer.internalLinks &&
                  footer.internalLinks.map((link: FooterLink, i) => {
                    return footerLink(link, i)
                  })}
              </Box>
            )}
            {!isOnePager && (
              <Box>
                {headerLink(
                  footer.externalLinksHeaderLink,
                  footer.externalLinksHeader
                )}
                {footer.externalLinks &&
                  footer.externalLinks.map((link: FooterLink, i) => {
                    return footerLink(link, i)
                  })}

                {footer.email && (
                  <div className={classes.contact}>
                    <Typography
                      variant="body1"
                      className={classes.contactHeader}
                    >
                      {t("footer.email")}
                    </Typography>

                    <LinkText
                      link={footer.email}
                      mailto={true}
                      variant="body1"
                      linkContentClassName={classes.contactLink}
                    >
                      {footer.email}
                    </LinkText>
                  </div>
                )}
                {footer.phone && (
                  <div className={classes.contact}>
                    <Typography
                      variant="body1"
                      className={classes.contactHeader}
                    >
                      {t("footer.telephone")}
                    </Typography>
                    <LinkText
                      link={footer.phone}
                      phone={true}
                      variant="body1"
                      linkContentClassName={classes.contactLink}
                    >
                      {footer.phone}
                    </LinkText>
                  </div>
                )}
              </Box>
            )}
            <Box width={isOnePager ? "100%" : "auto"}>
              {footer.socialLinks && isChina ? (
                <WeChatSocialLink socialLinks={footer.socialLinks} />
              ) : (
                <Box
                  display="flex"
                  flexDirection={
                    isOnePager
                      ? isXsDown
                        ? "column"
                        : "row-reverse"
                      : "column"
                  }
                  justifyContent="space-between"
                >
                  <div className={classes.milkImgContainer}>
                    <MilkLogo className={classes.milkImg} />
                  </div>
                  <Box>
                    {isOnePager && (
                      <Typography
                        variant="preamble"
                        className={classes.followUs}
                      >
                        {t("footer.follow_us")}
                      </Typography>
                    )}
                    <div className={classes.social}>
                      {footer.socialLinks &&
                        footer.socialLinks.map((link: FooterLink, i) => {
                          return (
                            <div key={i}>
                              {link.text.toLowerCase().indexOf("twitter") >
                                -1 && (
                                <IconCircle {...link}>
                                  <TwitterIcon
                                    htmlColor={themeColors.primaryBlue}
                                  />
                                </IconCircle>
                              )}
                              {link.text.toLowerCase().indexOf("facebook") >
                                -1 && (
                                <IconCircle {...link}>
                                  <FacebookIcon />
                                </IconCircle>
                              )}
                              {link.text.toLowerCase().indexOf("instagram") >
                                -1 && (
                                <IconCircle {...link}>
                                  <InstagramIcon />
                                </IconCircle>
                              )}
                              {link.text.toLowerCase().indexOf("youtube") >
                                -1 && (
                                <IconCircle {...link}>
                                  <YoutubeIcon />
                                </IconCircle>
                              )}
                              {link.text.toLowerCase().indexOf("vk") > -1 && (
                                <IconCircle {...link}>
                                  <VkIcon />
                                </IconCircle>
                              )}
                            </div>
                          )
                        })}
                    </div>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {isChina && (
            <Box
              mb={{ xs: 5, sm: 8.5 }}
              mt={{ xs: 5, sm: 3 }}
              display="flex"
              justifyContent={{ xs: "center", sm: "center", md: "end" }}
            >
              <Box clone>
                <MilkLogoChina className={classes.milkLogoChina} />
              </Box>
            </Box>
          )}

          <Grid container>
            <div className={classes.bottomWrapper}>
              <Typography className={classes.delaval} variant="body2">
                {`© ${new Date().getFullYear()} DeLaval`}
              </Typography>
              <div className={classes.terms}>
                {footer.informationLinks &&
                  footer.informationLinks.map((link: FooterLink, i) => (
                    <LinkText
                      onClick={onLinkClick}
                      key={i}
                      link={link.href}
                      external={link.target === "_blank"}
                      variant="body2"
                      linkContentClassName={classes.termsLink}
                    >
                      {link.title === "login" && ( // ! Had to somehow show the icon for now
                        <PersonIcon className={classes.personIcon} />
                      )}
                      {isChina &&
                        link.title &&
                        link.title.toLowerCase().indexOf("securityicon") >
                          -1 && (
                          <Box display="flex" mr={0.25}>
                            <Suspense fallback={<CircleFilled />}>
                              <PublicSecurityChinaIcon />
                            </Suspense>
                          </Box>
                        )}
                      {link.text}
                      {link.target === "_blank" && (
                        <LaunchApp className={classes.launchAppIcon} />
                      )}
                    </LinkText>
                  ))}
              </div>
            </div>
            {paymentMethods && (
              <Box
                pt={3}
                pb={{ xs: 25, sm: 25, md: 0 }}
                display="flex"
                gridGap={16}
                flexWrap="wrap"
              >
                {paymentMethods.map((icon) => {
                  return (
                    <Box width={{ xs: 40, sm: 48 }} key={icon._id}>
                      <ResponsiveImage
                        className={classes.mediaImage}
                        lazy
                        image={icon}
                      />
                    </Box>
                  )
                })}
              </Box>
            )}
          </Grid>
        </Wrapper>
      )}
    </footer>
  )
}
const useStyles = makeStyles(
  ({
    breakpoints,
    spacing,
    common: { gridSpacing, themeColors, pageMaxWidth }
  }) => ({
    "@global": {
      footer: {
        backgroundColor: themeColors.primaryBlue,
        color: themeColors.white
      }
    },
    container: {
      paddingBlock: spacing(4),
      paddingInline: 12,
      [breakpoints.up("sm")]: {
        paddingBlock: spacing(6, 4)
      },
      [breakpoints.up(pageMaxWidth + spacing(30))]: {
        paddingBlock: spacing(8, 4)
      }
    },
    footerTitle: {
      color: themeColors.white,
      fontSize: 24,
      paddingBottom: gridSpacing
    },
    footerLink: {
      color: themeColors.white,
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      paddingBottom: gridSpacing * 0.5,
      "&:hover": {
        textDecoration: "underline"
      }
    },
    bottomWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: spacing(4),
      marginTop: spacing(3),
      borderTop: `1px solid ${themeColors.white}`,
      [breakpoints.down("sm")]: {
        justifyContent: "normal",
        flexWrap: "wrap"
      }
    },
    delaval: {
      color: themeColors.white,
      [breakpoints.down("sm")]: {
        width: "100%",
        paddingBottom: gridSpacing * 0.5
      }
    },
    terms: {
      display: "flex",
      [breakpoints.up("md")]: {
        alignItems: "center"
      },
      [breakpoints.down("sm")]: {
        flexWrap: "wrap",
        width: "100%"
      },
      [breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    termsLink: {
      color: themeColors.white,
      paddingLeft: gridSpacing * 2,
      display: "flex",
      alignItems: "center",
      "&:hover": {
        textDecoration: "underline",
        color: themeColors.white
      },
      [breakpoints.down("sm")]: {
        paddingLeft: 0,
        marginBottom: spacing(1),
        flexWrap: "wrap",
        justifyContent: "flex-start"
      }
    },
    chinaIcon: {
      paddingRight: "2px",
      display: "flex",
      [breakpoints.down("sm")]: {
        paddingLeft: 0
      }
    },
    milkImgContainer: {
      display: "flex",
      justifyContent: "center"
    },
    milkImg: {
      width: 170
    },
    social: {
      marginTop: gridSpacing * 2,
      marginBottom: gridSpacing * 2,
      display: "flex",
      justifyContent: "center"
    },
    launchAppIcon: {
      marginLeft: spacing(1)
    },
    contact: {
      paddingBottom: gridSpacing * 0.5
    },
    contactHeader: {
      color: themeColors.white,
      fontWeight: 700
    },
    contactLink: {
      color: themeColors.primaryCyan,
      "&:hover": {
        textDecoration: "underline"
      }
    },
    personIcon: {
      position: "relative",
      top: -2,
      left: -6
    },
    followUs: {
      padding: spacing(0, 1),
      color: themeColors.white,
      fontWeight: 700,
      [breakpoints.down("xs")]: {
        textAlign: "center"
      }
    },
    milkLogoChina: {
      width: 238,
      height: 108,
      [breakpoints.down("xs")]: {
        width: 238 * 0.7,
        height: 108 * 0.7
      }
    },
    mediaImage: {
      position: "initial"
    }
  })
)

export default Footer
