import i18next from "i18next"
import * as yup from "yup"

export interface FarmFormType {
  name: string
  streetAddress: string
  country: string
  city: string
  zipCode: string
}

const validationSchema = (t: i18next.TranslationFunction) => {
  return yup.object<FarmFormType>().shape({
    name: yup.string().required(t("subscription.farm_name_required_message")),
    streetAddress: yup
      .string()
      .required(t("checkout.street_address_required_message")),
    country: yup.string().required(t("checkout.country_required_message")),
    city: yup.string().required(t("checkout.city_required_message")),
    zipCode: yup.string().required("checkout.zip_code_required_message")
  })
}

export default validationSchema
