import { useTranslation } from "react-i18next"
import { Form as FormikForm } from "formik"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import ContinueButton from "common/ContinueButton"
import CustomTextField from "common/Formik/CustomTextField"

const OrderReferenceForm: React.FC = () => {
  const { t } = useTranslation()

  const classes = useStyles()

  return (
    <FormikForm noValidate id="order-reference-form">
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Box mb={3}>
            <CustomTextField
              name={"orderReference"}
              label={t("checkout.order_reference_label")}
              type="text"
              autoFocus
            />
          </Box>
        </Grid>
        <ContinueButton
          id="submit-order-reference-form"
          type="submit"
          className={classes.saveButton}
        >
          {t("checkout.save_order_reference")}
        </ContinueButton>
      </Grid>
    </FormikForm>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  saveButton: {
    marginBottom: spacing(6)
  }
}))

export default OrderReferenceForm
