import { useTranslation } from "react-i18next"
import { Form as FormikForm } from "formik"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import CustomTextField from "common/Formik/CustomTextField"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import LoginFormSelector from "pages/Login/LoginFormSelector"
import authStore from "store/AuthStore"

const ResetPasswordForm: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { resetPasswordResponse }
  } = useStore(authStore)

  return (
    <div className={classes.root}>
      <FormikForm id="reset-password-form" noValidate>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomTextField
              required
              name="userName"
              label={t("checkout.email_input_label")}
              type="text"
            />
          </Grid>
          {resetPasswordResponse.error && (
            <Typography
              id="reset-password-error-message"
              variant="body2"
              className={classes.errorMessage}
            >
              {t("account.login_error_message")}
            </Typography>
          )}
          <Grid item>
            <Button fullWidth type="submit">
              {resetPasswordResponse.loading && (
                <ButtonLoaderIcon className={classes.loaderSpinner} />
              )}
              {t("account.reset_password_button")}
            </Button>
          </Grid>

          <LoginFormSelector />
        </Grid>
      </FormikForm>
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { gridSpacing, themeColors } }) => ({
    root: {
      width: "100%",
      padding: gridSpacing,
      [breakpoints.down("sm")]: {
        padding: gridSpacing
      }
    },
    errorMessage: {
      paddingTop: spacing(1),
      textAlign: "center",
      color: themeColors.primaryRed,
      paddingBottom: spacing(2)
    },
    loaderSpinner: {
      marginRight: spacing(1)
    }
  })
)

export default ResetPasswordForm
