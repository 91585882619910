import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const Money: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 94 69">
    <g clipPath="url(#Money)">
      <path
        d="M78.57 0C54.45 15.56 28.01-1.17 8.56 17.5l14.78 31.9c20.62-17.5 48.62-.78 70.01-17.5L78.57 0zm-3.5 7.78l10.5 22.17c-16.73 8.95-39.67-1.17-59.51 9.72L15.95 18.28c15.17-10.5 36.95-.39 59.12-10.5zM52.9 28.01l.39.78h-.78l-.39-.78H49.4l-.39-1.56c.78 0 1.56.39 2.33 0h.39c.78-.39.78-1.56-.78-1.95-.78-.39-3.5 0-4.28-2.33-.39-1.17 0-2.33 1.56-3.11l-.39-.78h.78l.78 1.17h2.33l.39 1.55h-2.34c-1.17.39-.78 1.56.39 1.56 1.95.39 3.89.39 4.67 2.33.39 1.56-.39 2.34-1.94 3.12zm5.83-5.45c-1.95-4.28-7-7-11.28-6.22-4.28.78-6.61 4.67-4.67 8.95 1.95 4.67 7.39 7.39 12.06 6.22 3.89-1.17 5.45-5.06 3.89-8.95zm34.23 22.95C66.12 60.68 45.12 39.67 17.5 68.46L0 29.95l5.45-4.28 14.78 32.67c28.78-21.78 43.56-4.67 70.4-17.89 1.16 2.72 1.94 4.28 2.33 5.06z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="Money">
        <path fill="#fff" d="M0 0h93.35v68.46H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default Money
