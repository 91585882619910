import { useEffect } from "react"
import { FastField } from "formik"

import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel, {
  FormControlLabelProps
} from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import FormLabel from "@material-ui/core/FormLabel"
import Radio from "@material-ui/core/Radio"
import { makeStyles, Theme } from "@material-ui/core/styles"

import { ItemSelection } from "blocks/FormContainerBlock/types"
import Typography from "common/Typography"
import CheckboxCheckedForm from "icons/CheckboxCheckedForm"
import CheckboxForm from "icons/CheckboxForm"
import FormikRadioGroup from "./FormikRadioGroup"
import { FormikFieldProps, FormikTransformerProps } from "./types"

type Props = {
  theme: string
  allowMultiSelect: boolean
  items: ItemSelection[]
  id?: string
  helperText?: string
  label?: string
  select?: boolean
  placeholder?: string
} & FormControlLabelProps &
  CheckboxProps

type StyleProps = {
  _theme: string
}

const FormControlLabelComponent: React.FC<FormikTransformerProps<Props>> = ({
  theme,
  allowMultiSelect,
  field,
  form,
  items,
  label,
  className
}) => {
  const { name, value: formikValue } = field
  const { setFieldValue } = form

  const classes = useStyles({
    _theme: theme
  })

  const handleChange = (value: string) => {
    const values = formikValue || []
    const index = values.indexOf(value)
    if (index === -1) {
      values.push(value)
    } else {
      values.splice(index, 1)
    }
    setFieldValue(name, values)
  }

  useEffect(() => {
    const initialValues = items
      .filter((item: ItemSelection) => item.checked === true)
      .map((x: ItemSelection) => {
        return x.value
      })

    setFieldValue(name, initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {formikValue && (
        <FormControl className={className}>
          <FormLabel className={className}>
            <Typography variant="headline6">{label}</Typography>
          </FormLabel>
          {allowMultiSelect ? (
            <FormGroup>
              {items.map((item) => {
                const defaultCheck =
                  formikValue && formikValue.includes(item.value)
                return (
                  <FormControlLabel
                    key={item.value}
                    label={item.caption}
                    control={
                      <Checkbox
                        icon={<CheckboxForm />}
                        checkedIcon={<CheckboxCheckedForm />}
                        color="default"
                        value={item.value}
                        onChange={() => handleChange(item.value)}
                        name={item.caption}
                        defaultChecked={defaultCheck}
                      />
                    }
                  />
                )
              })}
            </FormGroup>
          ) : (
            <FormikRadioGroup name={name}>
              {items.map((item) => {
                const defaultCheck =
                  formikValue && formikValue.includes(item.value)
                return (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={
                      <Radio
                        color="default"
                        checked={defaultCheck}
                        classes={{
                          root: classes.radio
                        }}
                      />
                    }
                    label={item.caption}
                  />
                )
              })}
            </FormikRadioGroup>
          )}
        </FormControl>
      )}
    </>
  )
}

const FormikFormControlField: React.FC<FormikFieldProps<any>> = (props) => {
  return <FastField component={FormControlLabelComponent} {...props} />
}

const useStyles = makeStyles<Theme, StyleProps>(
  ({ common: { themeColors } }) => ({
    radio: {
      backgroundColor: themeColors.white,
      outline: ({ _theme }) => `13px solid ${_theme}`,
      outlineOffset: "-12px",
      "&:hover": {
        backgroundColor: "white"
      }
    }
  })
)

export default FormikFormControlField
