// import { lazy } from "react"

import { BlockProps, ContentProps } from "@starrepublic/epi/cms"

import {
  AccordionBlock,
  BreadCrumbsBlock,
  BundleBlock,
  ChildLinksBlock,
  ChoiceElementBlock,
  CollageBlock,
  ContactBlock,
  ContentCarouselBlock,
  DateTimeElementBlock,
  EmbeddedDemoBlock,
  EnergyCalculatorBlock,
  ExploreBlock,
  FenceCalculatorBlock,
  FormContainerBlock,
  FormDividerElementBlock,
  FormTextElementBlock,
  HeroBlock,
  HighlightBlock,
  ImageFileBlock,
  ImageProductCarouselBlock,
  MediaBlock,
  MediaBrowserBlock,
  NumberElementBlock,
  PageListBlock,
  ProductsCarouselBlock,
  QuotationBlock,
  SearchBlock,
  SectionBlock,
  SelectionElementBlock,
  StatisticsBlock,
  SubmitButtonElementBlock,
  TestimonialBlock,
  TextAndMediaBlock,
  TextAndMediaCarouselBlock,
  TextareaElementBlock,
  TextBlock,
  TextboxElementBlock,
  WebProductBlock
} from "blocks"
import {
  AccountPage,
  CheckoutPage,
  CookiesPage,
  DealerPage,
  ErrorPage,
  ExplorePage,
  InformationPage,
  LearnPage,
  LoginPage,
  ResetPasswordPage,
  SearchResultPage,
  StandardPage,
  StartPage,
  WebNode,
  WebProduct
} from "pages"

// const StandardPage = lazy(() =>
//   import(/* webpackChunkName: 'page-standard' */ "pages/Standard")
// )
// const StartPage = lazy(() =>
//   import(/* webpackChunkName: 'page-start' */ "pages/Start")
// )
// const WebProduct = lazy(() =>
//   import(/* webpackChunkName: 'page-webproduct' */ "pages/WebProduct")
// )
// const WebNode = lazy(() =>
//   import(/* webpackChunkName: 'page-webnode' */ "pages/WebNode")
// )
// const CheckoutPage = lazy(() =>
//   import(/* webpackChunkName: 'page-checkout' */ "pages/Checkout")
// )
// const AccountPage = lazy(() =>
//   import(/* webpackChunkName: 'account-page' */ "pages/AccountPage")
// )
// const LoginPage = lazy(() =>
//   import(/* webpackChunkName: 'page-login' */ "pages/Login")
// )
// const SearchResultPage = lazy(() =>
//   import(/* webpackChunkName: 'page-seachresult' */ "pages/SearchResult")
// )
// const ErrorPage = lazy(() =>
//   import(/* webpackChunkName: 'page-error' */ "pages/Error")
// )
// const ResetPasswordPage = lazy(() =>
//   import(/* webpackChunkName: 'page-resetpassword' */ "pages/ResetPassword")
// )
// const ExplorePage = lazy(() =>
//   import(/* webpackChunkName: 'page-explore' */ "pages/Explore")
// )
// const DealerPage = lazy(() =>
//   import(/* webpackChunkName: 'page-dealer' */ "pages/DealerPage")
// )
// const InformationPage = lazy(() =>
//   import(/* webpackChunkName: 'page-information' */ "pages/InformationPage")
// )
// const LearnPage = lazy(() =>
//   import(/* webpackChunkName: 'page-learn' */ "pages/LearnPage")
// )

export const PageMapping: {
  [key: string]: React.ComponentType<ContentProps>
} = {
  StandardPage,
  WebProduct,
  WebNode,
  CheckoutPage,
  CookiesPage,
  AccountOverviewPage: AccountPage,
  LoginPage,
  SearchResultPage,
  ErrorPage,
  ResetPasswordPage,
  ExplorePage,
  InformationPage,
  LearnPage,
  AccountShoppingListsPage: AccountPage,
  AccountOrdersPage: AccountPage,
  AccountOrganizationPage: AccountPage,
  SubscriptionsPage: AccountPage,
  AccountUsersPage: AccountPage,
  AccountDetailsPage: AccountPage,
  AccountInvoicesPage: AccountPage,
  PartnerPortalStartPage: StartPage,
  PartnerPortalStorePage: StartPage,
  DealerSearchPage: DealerPage,
  StorePage: StartPage,
  SiteStartPage: StartPage
}

export const BlockMapping: {
  [key: string]: React.ComponentType<BlockProps>
} = {
  MediaBrowserBlock,
  AccordionBlock,
  ChildLinksBlock,
  HeroBlock,
  HighlightBlock,
  SectionBlock,
  TextBlock,
  BreadCrumbsBlock,
  ExploreBlock,
  ContentCarouselBlock,
  PageListBlock,
  TextAndMediaBlock,
  TextAndMediaCarouselBlock,
  TestimonialBlock,
  MediaBlock,
  StatisticsBlock,
  ImageProductCarouselBlock,
  BundleBlock,
  EnergyCalculatorBlock,
  ProductsCarouselBlock,
  ContactBlock,
  FenceCalculatorBlock,
  EmbeddedDemoBlock,
  SearchBlock,
  CollageBlock,
  QuotationBlock,
  DealerPage: ContactBlock,
  ImageFile: ImageFileBlock,
  TitleAndPreambleFromCatalogLinkBlock: TextBlock,
  WebProduct: WebProductBlock,
  WebSystem: WebProductBlock,
  WebNode: WebProductBlock
}

export const FormMapping: {
  [key: string]: React.ComponentType<BlockProps>
} = {
  FormContainerBlock,
  FormTextElementBlock,
  TextareaElementBlock,
  TextboxElementBlock,
  SelectionElementBlock,
  SubmitButtonElementBlock,
  ChoiceElementBlock,
  FormDividerElementBlock,
  DateTimeElementBlock,
  NumberElementBlock,
  LocalizableMultipleOrSingleBlock: ChoiceElementBlock,
  LocalizableSelectionBlock: SelectionElementBlock,
  LocalizableFormContainerBlock: FormContainerBlock
  // RecaptchaElementBlock
}
