import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import siteSettingStore from "store/SiteSettingStore"

type Props = {
  showHazardousWarning: boolean
}

const AdditionalInformation: React.FC<Props> = ({ showHazardousWarning }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { safetyInformationUrl }
  } = useStore(siteSettingStore)

  return (
    <>
      {showHazardousWarning && (
        <div className={classes.hazardousWrapper}>
          <Typography variant="h6" className={classes.headline}>
            {t("product.safety_information")}
          </Typography>
          <Typography variant="body1">
            {t("product.dangerous_goods_text")}
          </Typography>
          {safetyInformationUrl && (
            <Typography variant="body1">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
                href={safetyInformationUrl}
              >
                {t("product.safety_information")}
              </a>
            </Typography>
          )}
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { gridSpacing, themeColors } }) => ({
    root: {},
    hazardousWrapper: {
      padding: spacing(2, 1.5)
    },
    headline: {
      paddingBottom: gridSpacing * 0.5,
      lineHeight: 1
    },
    link: {
      color: themeColors.primaryCyan
    }
  })
)

export default AdditionalInformation
