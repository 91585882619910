import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import InStock from "icons/InStock"

type Props = {
  className?: string
  inventoryStatus: string
  compact?: boolean
  isDigitalService?: boolean
}

const StockStatus: React.FC<Props> = ({
  inventoryStatus,
  compact,
  isDigitalService = false
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  let stockStatusText = ""
  let stockStatusClass = ""

  switch (inventoryStatus) {
    case "IN_STOCK":
      stockStatusText = t("product.in_stock")
      stockStatusClass = classes.stockStatusInStock
      break
    case "FEW_IN_STOCK":
      stockStatusText = t("product.few_in_stock")
      stockStatusClass = classes.stockStatusFewInStock
      break
    default:
      stockStatusText = t("product.out_of_stock")
      stockStatusClass = classes.stockStatusOutOfStock
      break
  }

  return (
    <Typography variant="body2" component="div" className={classes.root}>
      {isDigitalService ? (
        t("product.stock_status_digital_service")
      ) : (
        <>
          <InStock
            className={classNames(classes.stockStatusIcon, stockStatusClass)}
          />
          {compact ? null : stockStatusText}
        </>
      )}
    </Typography>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    position: "relative",
    whiteSpace: "nowrap"
  },
  stockStatusIcon: {
    fontSize: 12,
    marginRight: spacing(1) * 0.5
  },
  stockStatusInStock: {
    color: themeColors.secondaryGreen
  },
  stockStatusFewInStock: {
    color: themeColors.secondaryOrange
  },
  stockStatusOutOfStock: {
    color: themeColors.darkGray
  }
}))

export default StockStatus
