import { BlockProps } from "@starrepublic/epi/cms"

import Tooltip from "@material-ui/core/Tooltip"

import CustomTextField from "common/Formik/CustomTextField"

type PickerType = {
  value: number
  text: string
}

type Props = BlockProps<{
  theme: string
  label: string
  formElementId: string
  placeholder: string
  description: string
  pickerType: PickerType
}>

const DateTimeElementBlock: React.FC<Props> = ({
  content: { label, formElementId, placeholder, description, pickerType, theme }
}) => {
  const handleDateTimePicker = () => {
    switch (pickerType.text) {
      case "Date picker":
        return "date"
      case "Time picker":
        return "time"
      case "Date time picker":
        return "datetime-local"
      default:
        return "date"
    }
  }

  return (
    <Tooltip
      placement="top"
      arrow
      title={description ? description : ""}
      interactive
    >
      <div>
        <CustomTextField
          {...{ label, placeholder, theme }}
          name={formElementId}
          shrink
          type={handleDateTimePicker()}
        />
      </div>
    </Tooltip>
  )
}

export default DateTimeElementBlock
