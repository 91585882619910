import { useState } from "react"
import classNames from "classnames"
import useStore from "global-hook-store"

import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Box from "@material-ui/core/Box"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import MyLocationIcon from "@material-ui/icons/MyLocation"

import LinkText from "common/LinkText"
import Typography from "common/Typography"
import ArrowDown from "icons/ArrowDown"
import LocationIcon from "icons/LocationIcon"
import Mail from "icons/Mail"
import PersonIcon from "icons/PersonIcon"
import Phone from "icons/Phone"
import Website from "icons/Website"
import DealerSearchStore, { Dealer } from "store/DealerSearchStore"
import { themeColors } from "theme"

type Props = {
  dealer: Dealer
}

const DealerListItem = ({ dealer }: Props) => {
  const classes = useStyles()
  const isMdUp = useMediaQuery(({ breakpoints }) => breakpoints.up("md"))

  const {
    id,
    name,
    phone,
    email,
    address,
    zipcode,
    dealerType,
    city,
    distance,
    website,
    additionalContacts
  } = dealer

  const {
    state: { selectedDealer },
    actions: { setSelectedDealer }
  } = useStore(DealerSearchStore)

  const dealerSelected = selectedDealer && selectedDealer.id === id

  const [open, setOpen] = useState(dealerSelected !== null && dealerSelected)

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Accordion
        expanded={open}
        className={classNames(classes.root, {
          [classes.selected]: isMdUp && dealerSelected
        })}
      >
        <AccordionSummary
          onClick={() => setSelectedDealer(dealer)}
          IconButtonProps={{
            classes: {
              root: classes.iconButton
            }
          }}
          expandIcon={
            dealerSelected &&
            additionalContacts.length > 1 && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
              >
                <ArrowDown
                  fill={themeColors.baseBlue}
                  className={classes.arrow}
                  onClick={() => {
                    additionalContacts!.length > 1 && setOpen(!open)
                  }}
                />
              </Box>
            )
          }
        >
          <Box>
            <Box pb={isMdUp ? 0.25 : 0}>
              <Typography variant="headline6">{name}</Typography>
              <Typography variant="link2">{dealerType}</Typography>
            </Box>

            <Box display="flex">
              <LocationIcon
                className={classNames(classes.icon, classes.locationIcon)}
              />
              <Box display="flex" flexDirection="column">
                <Typography variant="label">{address}</Typography>
                <Typography variant="label">
                  {zipcode} {city}
                </Typography>
              </Box>
            </Box>

            {isMdUp && dealerSelected && (
              <Box display="flex" flexDirection="column">
                {phone && (
                  <Box display="flex" alignItems="center">
                    <Phone className={classes.icon} />
                    <LinkText variant="label" phone link={phone}>
                      {phone}
                    </LinkText>
                  </Box>
                )}
                {email && (
                  <Box display="flex" alignItems="center">
                    <Mail className={classes.icon} />
                    <LinkText variant="label" mailto link={email}>
                      {email}
                    </LinkText>
                  </Box>
                )}
                {distance && (
                  <Box display="flex" alignItems="center">
                    <MyLocationIcon className={classes.icon} />
                    <Typography variant="label">{distance}</Typography>
                  </Box>
                )}
                {website && (
                  <Box display="flex" alignItems="center">
                    <Website className={classes.icon} />
                    <LinkText variant="label" link={website} external>
                      {website}
                    </LinkText>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Box pl={2} display="flex" flexDirection="column">
            {dealerSelected &&
              additionalContacts.length > 1 &&
              additionalContacts.map((contact, key) => {
                return (
                  <Box
                    key={`${contact.email}-${key}`}
                    pb={key === additionalContacts.length - 1 ? 0 : 2}
                  >
                    <Typography variant="body1">
                      {contact.description}
                    </Typography>
                    <Box display="flex" alignItems="center">
                      <PersonIcon
                        fill={themeColors.black}
                        className={classes.icon}
                      />
                      <Typography variant="label">{contact.name}</Typography>
                    </Box>
                    {contact.phone && (
                      <Box display="flex" alignItems="center">
                        <Phone className={classes.icon} />
                        <LinkText variant="label" phone link={contact.phone}>
                          {contact.phone}
                        </LinkText>
                      </Box>
                    )}
                    {contact.email && (
                      <Box display="flex" alignItems="center">
                        <Mail className={classes.icon} />
                        <LinkText variant="label" mailto link={contact.email}>
                          {contact.email}
                        </LinkText>
                      </Box>
                    )}
                  </Box>
                )
              })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    margin: 0,
    "& .MuiAccordionSummary-root": {
      padding: spacing(0, 1)
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: spacing(1.5, 0)
    },
    "&.Mui-expanded": {
      margin: 0
    },
    "&.MuiAccordion-root:before": {
      opacity: 1
    },
    "& .MuiAccordionDetails-root": {
      padding: 0
    }
  },
  accordionDetails: {
    backgroundColor: themeColors.offWhite
  },
  icon: {
    fontSize: spacing(2),
    marginRight: spacing(1),
    color: themeColors.black
  },
  locationIcon: {
    marginTop: spacing(0.5)
  },
  selected: {
    backgroundColor: themeColors.lightGray
  },
  iconButton: {
    alignSelf: "normal",
    padding: 0,
    "& > span": {
      height: "100%",
      width: "100%"
    }
  },
  arrow: {
    fontSize: spacing(2),
    height: "100%",
    width: 42,
    padding: 12
  }
}))

export default DealerListItem
