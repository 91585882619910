import { Link } from "react-router-dom"
import { BlockProps, editorAttributes } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import { blockDisplay } from "utils/blockDisplay"
import { contrastSwitch } from "utils/contrastSwitch"

import BlockGrid from "common/BlockGrid"
import ResponsiveImage from "common/ResponsiveImage"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import BrochureIcon from "icons/BrochureIcon"
import KeyBenefits from "pages/WebProduct/KeyBenefits"
import { eventPdfTracker } from "services/StarGA"
import { WebProductBlockContent } from "./types"

type Props = BlockProps<WebProductBlockContent>

const WebProductBlock: React.FC<Props> = ({ content, blockInfo }) => {
  const { title, displayImage, text, pdfAssets, _route, sectionTheme } = content

  const classes = useStyles()
  const _display = blockDisplay(blockInfo)
  const link = _route ? _route : ""

  const isFullWidth =
    blockDisplay(blockInfo).full || blockDisplay(blockInfo).full_alt
  const isAutoOrFullWidth = blockDisplay(blockInfo).auto || isFullWidth

  const isSmallWidth =
    blockDisplay(blockInfo).oneThird ||
    blockDisplay(blockInfo).twoThird ||
    blockDisplay(blockInfo).half

  const Image = displayImage && (
    <ResponsiveImage
      className={classes.media}
      lazy
      containerProps={{
        className: classNames(classes.mediaContainer, {
          [classes.halfWidthChild]: isAutoOrFullWidth
        })
      }}
      image={displayImage}
    />
  )

  return (
    <div {...editorAttributes(blockInfo)}>
      <Wrapper
        className={classNames(classes.wrapper, {
          [classes.wrapperFullWidth]: isFullWidth
        })}
      >
        <div
          className={classNames(classes.root, {
            [classes.noRightLeftMargin]: isFullWidth,
            [classes.fullWidth]: isAutoOrFullWidth,
            [classes.alternativeLayout]: _display.full_alt
          })}
        >
          {link ? (
            <Link to={link} className={classes.productLink}>
              {Image}
            </Link>
          ) : (
            Image
          )}
          <BlockGrid
            container
            direction="column"
            className={classNames(classes.bodyContentContainer, {
              [classes.halfWidthChild]: isAutoOrFullWidth
            })}
          >
            <Typography
              textWrap="wrap"
              className={classNames(
                classes.headline,
                classes[
                  contrastSwitch({
                    variant: "title",
                    contrastColor: sectionTheme
                  })
                ],
                {
                  [classes.isSmallWidth]: isSmallWidth
                }
              )}
              variant="headline3"
            >
              {title}
            </Typography>
            {text && (
              <Box py={1}>
                <ThemeXhtmlString content={text} sectionTheme={sectionTheme} />
              </Box>
            )}

            <KeyBenefits
              listItems={content.benefits}
              sectionTheme={sectionTheme}
            />

            <Box
              py={1}
              className={classNames(
                classes[
                  contrastSwitch({
                    variant: "links",
                    contrastColor: sectionTheme
                  })
                ]
              )}
            >
              {pdfAssets.map((pdfAsset, i) => {
                return (
                  <a
                    key={i}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.pdf}
                    href={pdfAsset._url}
                    onClick={() => eventPdfTracker(pdfAsset._url)}
                  >
                    <BrochureIcon className={classes.pdfIcon} />
                    <Typography
                      textWrap="wrap"
                      variant="body1"
                      className={classes.link}
                    >
                      {pdfAsset.name}
                    </Typography>
                  </a>
                )
              })}
            </Box>
          </BlockGrid>
        </div>
      </Wrapper>
    </div>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors, aspectRatio3x2 } }) => ({
    wrapper: {
      padding: 0
    },
    wrapperFullWidth: {
      padding: 0,
      borderBottom: `1px solid ${themeColors.lightGray}`,
      borderTop: `1px solid ${themeColors.lightGray}`,
      marginTop: -1
    },
    root: {
      display: "flex",
      flexDirection: "column",
      [breakpoints.down("xs")]: {
        marginRight: 0,
        marginLeft: 0
      }
    },
    fullWidth: {
      marginLeft: 0,
      marginRight: 0,
      flexDirection: "row",
      alignItems: "center",
      [breakpoints.down("xs")]: {
        flexDirection: "column"
      }
    },
    alternativeLayout: {
      flexDirection: "row-reverse",
      [breakpoints.down("xs")]: {
        flexDirection: "column"
      }
    },
    halfWidthChild: {
      flexGrow: 0,
      flexBasis: "50%",
      margin: spacing(1)
    },
    mediaContainer: {
      backgroundColor: themeColors.white,
      width: "100%",
      padding: 0,
      [breakpoints.up("sm")]: {
        padding: 0
      }
    },
    media: {
      width: "100%",
      display: "block",
      position: "initial",
      aspectRatio: aspectRatio3x2,
      objectFit: "contain"
    },
    bodyContentContainer: {
      height: "100%",
      width: "100%",
      padding: `${spacing(2)}px ${spacing(4)}px`,
      [breakpoints.up("sm")]: {
        padding: `${spacing(2)}px ${spacing(2)}px`
      }
    },
    noRightLeftMargin: {
      margin: `${spacing(2)}px 0`
    },
    headline: {
      marginBottom: spacing(2),
      [breakpoints.down("sm")]: {
        fontSize: "30px"
      }
    },
    isSmallWidth: {
      fontSize: "30px"
    },

    pdf: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      color: "inherit"
    },
    pdfIcon: {
      display: "flex",
      marginRight: spacing(1),
      color: "inherit"
    },
    link: {
      color: "inherit"
    },
    productLink: {
      display: "contents"
    },
    pdfAssets: {
      color: themeColors.colorBaseCyan700
    },
    ["text-base-white"]: {
      color: themeColors.white
    },
    ["text-base-blue-700"]: {
      color: themeColors.colorBaseBlue700
    },
    ["text-base-blue-800"]: {
      color: themeColors.colorBaseBlue800
    },
    ["text-base-blue-900"]: {
      color: themeColors.colorBaseBlue900
    },
    ["text-base-cyan-700"]: {
      color: themeColors.colorBaseCyan700
    }
  })
)

export default WebProductBlock
