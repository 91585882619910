import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const MapIcon: React.FC<Props> = (props) => (
  <SvgIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M1 6v16l7-4 8 4 7-4V2l-7 4-8-4-7 4ZM8 2v16M16 6v16" />
    </g>
  </SvgIcon>
)

export default MapIcon
