import { FC, useEffect, useState } from "react"

import { ChannelManager, Property, Subscription } from "../epi"
import { ContentPropertyInfo, PageInfo } from "../types/content"

type FetchContentProps = {
  fetchContent: (id: string, useCache: boolean) => void
}

type Props = {
  content: any
  propsInfo: ContentPropertyInfo<any>
  pageInfo: PageInfo
  children: (content: any) => JSX.Element
} & FetchContentProps

type State = {
  readonly content?: any
}

type OnPageEditingComponent<T = any> = FC<Props>

const OnPageEditing: OnPageEditingComponent = ({
  children,
  content: propsContent,
  fetchContent,
  propsInfo,
  pageInfo
}) => {
  const [state, setState] = useState<State>({})
  const { content } = state

  const updatePropertyValue = (property: Property) => {
    const { name, value } = property

    const info = propsInfo[name]
    if (!info) return

    if (info.reloadOnEdit) {
      fetchContent(pageInfo._id.toString(), false)
    } else {
      setState((prevState) => ({
        content: {
          ...((prevState.content || {}) as object),
          [name]: value
        }
      }))
    }
  }

  useEffect(() => {
    let mounted = true

    let contentSubscription: Subscription | undefined = undefined
    ;(async () => {
      contentSubscription = await ChannelManager.subscribeToContentSaved(
        (properties) => {
          properties.forEach(updatePropertyValue)
        }
      )
      //to fix race condition
      if (!mounted && contentSubscription) {
        contentSubscription.remove()
      }
    })()

    return () => {
      mounted = false
      contentSubscription && contentSubscription.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setState({
      content: propsContent
    })
  }, [propsContent])

  return content ? children(content) : null
}

export default OnPageEditing
export type { FetchContentProps }
