import i18next from "i18next"
import * as yup from "yup"

import { Address, BillingAddress } from "models/Checkout"

export interface CustomerDetailValues {
  firstName: string
  lastName: string
  companyName: string
  email: string
  password: string
  confirmPassword: string
  vatNumber: string
  differentShippingAddress: boolean
  billingAddress: BillingAddress
  shippingAddresses?: Address[]
}

const addressValidationShape = (
  t: i18next.TranslationFunction,
  postalCodeValidationPatten: string,
  marketId: string
) => ({
  streetAddress: yup
    .string()
    .required(t("checkout.street_address_required_message")),
  countryCode: yup.string().required(t("checkout.country_required_message")),
  zipCode: yup
    .string()
    .required(t("checkout.zip_code_required_message"))
    .when("countryCode", {
      is: (value) => {
        return value === marketId
      },
      then: yup
        .string()
        .matches(
          postalCodeValidationPatten
            ? new RegExp(postalCodeValidationPatten)
            : /^[0-9]{5}$/,
          t("checkout.zip_code_format_message")
        )
    }),
  city: yup.string().required(t("checkout.city_required_message")),
  phone: yup
    .string()
    .required(t("checkout.phone_required_message"))
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
      t("checkout.phone_invalid_message")
    )
})

const validationSchema = (
  t: i18next.TranslationFunction,
  postalCodeValidationPatten: string,
  marketId: string
) => {
  const billingAddressValidationSchema = yup.object<BillingAddress>().shape({
    ...addressValidationShape(t, postalCodeValidationPatten, marketId),
    countryCode: yup.string().notRequired()
  })
  return yup.object<CustomerDetailValues>().shape({
    firstName: yup.string().required(t("checkout.first_name_required_message")),
    lastName: yup.string().required(t("checkout.last_name_required_message")),
    companyName: yup
      .string()
      .required(t("checkout.company_name_required_message")),
    email: yup
      .string()
      .nullable(false)
      .required(t("checkout.email_required_message"))
      .email(t("checkout.email_invalid_message")),
    password: yup
      .string()
      .required(t("checkout.password_required_message"))
      .matches(
        new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        ),
        t("checkout.password_format_message")
      ),
    confirmPassword: yup
      .string()
      .required(t("checkout.confirm_password_required_message"))
      .oneOf(
        [yup.ref("password")],
        t("checkout.confirm_password_do_not_match_message")
      ),
    billingAddress: billingAddressValidationSchema
  })
}

export default validationSchema
