import request from "@starrepublic/epi/api/request"
import qs from "qs"

import Product from "models/Products"
import Variant from "models/Variant"

export type PageDataType = {
  _id: number
  _route: string
  title: string
  score: number
}

export enum SearchResultType {
  Products = "WebProduct",
  Variations = "WebVariation",
  Pages = "PageData"
}

type SearchResultGeneric<T extends SearchResultType> = {
  totalItems: number
  limit: number
  offset: number
  hits: SearchResultContentType<T>
  searchType: T
}

type SearchResultContentType<T extends SearchResultType> =
  T extends SearchResultType.Products
    ? Product[]
    : T extends SearchResultType.Variations
      ? Variant[]
      : T extends SearchResultType.Pages
        ? PageDataType[]
        : null

export type SearchResult =
  | SearchResultGeneric<SearchResultType.Products>
  | SearchResultGeneric<SearchResultType.Variations>
  | SearchResultGeneric<SearchResultType.Pages>

export type SearchApiParams = {
  q: string
  type?: SearchResultType
  limit: number
  offset: number
  sort?: string
}

const search = {
  getSearchResults: ({ q, type, limit, offset, sort }: SearchApiParams) => {
    let query = qs.stringify(
      {
        limit,
        offset,
        sort,
        type,
        q
      },
      { indices: false }
    )

    query = `${query}&type=PageData`
    return request.get<SearchResult[]>(`/search?${query}`)
  },
  getOriginalPartsSearchResults: (
    { q, type, limit, offset, sort }: SearchApiParams,
    rootCategory: string
  ) => {
    const query = qs.stringify(
      {
        limit,
        offset,
        sort,
        type,
        q,
        root: rootCategory
      },
      { indices: false }
    )
    return request.get<SearchResult[]>(`/search?${query}`)
  },
  getRootCategorySearchResults: (
    { q, type, limit, offset, sort }: SearchApiParams,
    rootCategory: string
  ) => {
    const query = qs.stringify(
      {
        limit,
        offset,
        sort,
        type,
        q,
        root: rootCategory
      },
      { indices: false }
    )
    return request.get<SearchResult[]>(`/search?${query}`)
  }
}

export default search
