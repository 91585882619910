import { ChangeEvent, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import ButtonBase from "@material-ui/core/ButtonBase"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import RemoveIcon from "@material-ui/icons/Remove"

import { FenceDataItem, FenceProduct, FenceVariant } from "../types"

import Image from "common/Image"
import PricePerUnit from "common/Product/PricePerUnit"
import ProductPrice from "common/Product/ProductPrice"
import TextField from "common/TextField"
import Typography from "common/Typography"
import AddToCartIcon from "icons/AddToCartIcon"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"
import SiteSettingStore from "store/SiteSettingStore"

type Props = {
  product: FenceProduct
  variant: FenceVariant
  currencySymbol: string
}

const AccessoryVariant = ({ product, variant, currencySymbol }: Props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))

  const textFieldRef = useRef<HTMLInputElement>(null)

  const [count, setCount] = useState<number>(0)
  const [accessoriesChanged, setAccessoriesChanged] = useState(false)
  const { selectedAccessories, setSelectedAccessories } =
    useFenceCalculatorStore()

  const {
    state: { enableEcommerce }
  } = useStore(SiteSettingStore)

  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setCount(parseInt(value, 10) || 0)
    setAccessoriesChanged(true)
  }
  const handleIncrement = () => {
    setCount(count + 1)
    setAccessoriesChanged(true)
  }

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1)
      setAccessoriesChanged(true)
    }
  }
  useEffect(() => {
    const fenceAccessoriesIndex: { [key: string]: FenceDataItem } = {}

    for (const item of selectedAccessories) {
      fenceAccessoriesIndex[item.itemCode] = item
    }

    const targetItem = fenceAccessoriesIndex[variant.articleNumber]
    if (targetItem) {
      //Item found, set quantity on load
      setCount(targetItem.quantity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      if (accessoriesChanged) {
        setSelectedAccessories({
          itemCode: variant.articleNumber,
          quantity: count,
          displayName: product.name,
          price: variant.price
        })
        setAccessoriesChanged(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessoriesChanged]
  )

  return (
    <Box
      p={1}
      position={isSmUp ? "static" : "relative"}
      display="flex"
      alignItems={isSmUp ? "center" : undefined}
      className={classNames(classes.passiveRow, {
        [classes.activeRow]: count > 0
      })}
      my={1}
    >
      <Box width="100%">
        <Box display="flex" alignItems={isSmUp ? "center" : undefined}>
          {product && (
            <Box pr={2}>
              <Image
                lazy
                src={product && product.productImage}
                className={classes.productImage}
              />
            </Box>
          )}
          <Box display="flex" flexDirection={isSmUp ? "row" : "column"}>
            <Box
              display="flex"
              flexDirection={isSmUp ? "row" : "column-reverse"}
              alignItems={isSmUp ? "center" : undefined}
              mr={isSmUp ? 6 : 0}
              pb={isSmUp ? 0 : 3}
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="headline6" style={{ width: 250 }}>
                  {product.name}
                </Typography>
                <Typography variant="headline6" style={{ fontSize: ".75rem" }}>
                  {variant.freeText}
                </Typography>
              </Box>
              <Typography className={classes.articleNumber}>
                {variant.articleNumber}
              </Typography>
            </Box>
            {enableEcommerce && (
              <div className={classes.priceWrapper}>
                {variant.quantity > 1 ? (
                  <PricePerUnit
                    price={`${
                      variant.price.toFixed(2).toString() || ""
                    } ${currencySymbol}`}
                    unit={`${variant.quantity.toString() || ""} units`}
                    size="medium"
                  />
                ) : (
                  <ProductPrice
                    price={{
                      displayPrice: `${
                        variant.price.toFixed(2).toString() || ""
                      } ${currencySymbol}`,
                      amount: variant.quantity
                    }}
                    size="small"
                  />
                )}

                <Typography variant="smallText">
                  {t("product.ex_vat")}
                </Typography>
              </div>
            )}
          </Box>
        </Box>
        {/* TODO: DO we need stock status? */}
        {/* <StockStatus inventoryStatus="IN_STOCK" /> */}
      </Box>
      <Box
        display="flex"
        position={isSmUp ? "static" : "absolute"}
        bottom={0}
        right={0}
      >
        <ButtonBase
          onClick={handleDecrement}
          className={classNames(
            "add-to-cart-button",
            classes.button,
            count > 0 ? "" : classes.disabledButton
          )}
        >
          {
            <div className={classes.buttonText}>
              <RemoveIcon />
            </div>
          }
        </ButtonBase>
        <TextField
          variant="outlined"
          onChange={handleQuantityChange}
          inputRef={textFieldRef}
          InputProps={{
            classes: {
              root: classes.quantityInput,
              notchedOutline: classes.border,
              input: "add-to-cart-quantity-input"
            }
          }}
          inputProps={{
            style: {
              textAlign: "center",
              padding: 0,
              height: "100%",
              fontSize: 14
            },
            min: 0
          }}
          className={classes.quantity}
          value={count}
        />
        <Box>
          <ButtonBase
            onClick={handleIncrement}
            className={classNames("add-to-cart-button", classes.button)}
          >
            {
              <div className={classes.buttonText}>
                <AddToCartIcon />
              </div>
            }
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ common: { themeColors }, spacing, breakpoints }) => ({
    minWidth: {
      width: "1px",
      paddingLeft: 0
    },
    priceWrapper: {
      "& span": {
        fontSize: 14,
        color: themeColors.colorBaseBlue900,
        textAlign: "left"
      },
      "& p": {
        textAlign: "left",
        width: "100%"
      },
      [breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline"
      },
      [breakpoints.down("xs")]: {
        left: spacing(1.5),
        bottom: spacing(1.5)
      }
    },
    productImage: {
      position: "initial",
      width: 64,
      objectFit: "cover"
    },
    border: {
      borderRadius: 0
    },
    quantity: {
      width: 40,
      height: 40,
      borderRadius: 0,
      background: themeColors.white
    },
    quantityInput: {
      color: themeColors.darkGray,
      height: "100%"
    },
    root: {
      display: "flex",
      justifyContent: "flex-end",
      [breakpoints.down("md")]: {
        width: "100%"
      },
      "& div > fieldset": {
        borderBottom: 0,
        borderRight: 0,
        borderColor: themeColors.whiteGray
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          border: "1px solid",
          borderColor: themeColors.darkBlue
        },
        "&.Mui-focused fieldset": {
          borderColor: themeColors.darkBlue,
          border: "2px solid"
        }
      }
    },
    button: {
      cursor: "pointer",
      color: themeColors.white,
      background: themeColors.primaryCyan,
      borderRadius: 0,
      height: 40,
      minWidth: 40
    },
    buttonText: {
      fontSize: 14,
      fontWeight: 700,
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center"
    },
    disabledButton: {
      background: themeColors.lightGray,
      cursor: "default !important",
      color: themeColors.mediumGray
    },
    passiveRow: {
      border: `1px solid ${themeColors.colorBaseGray400}`
    },
    activeRow: {
      backgroundColor: themeColors.secondaryCyan
    },
    articleNumber: {
      fontSize: ".875rem",
      fontWeight: 400,
      color: themeColors.colorBaseGray600,
      width: "85px"
    }
  })
)

export default AccessoryVariant
