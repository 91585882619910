import { CancelToken } from "axios"

import request, { requestWithoutTokenUpdate } from "./request"

export type User = {
  id: string
  email: string
  firstName: string
  lastName: string
  phoneNumber?: string
  isApproved: boolean
  isLocked: boolean
  customerGroup?: any
  preferredCurrency?: string
  preferredLanguage?: string
  organization?: string
  addresses?: any
}

export type RegisterRequest = {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
}

export type TokenResponse = {
  access_token: string
  expires_in: number
  refresh_token: string
}

export type ResetPasswordModel = {
  token: string
  password: string
  email: string
}

const users = {
  getUser: () => request.get<User>("/membership/user"),

  register: (model: RegisterRequest, cancelToken?: CancelToken) =>
    request.post("/membership/user/register", model, { cancelToken }),

  login: (userName: string, password: string, cancelToken?: CancelToken) =>
    request.post<TokenResponse>(
      "/token",
      {
        password,
        username: userName,
        grant_type: "password"
      },
      {
        cancelToken,
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    ),

  switchUser: (impersonateUserId: string) =>
    request.post<TokenResponse>(
      "/token",
      {
        impersonateUserId,
        grant_type: "password"
      },
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    ),

  resetPassword: (userName: string) =>
    request.get(`/membership/user/${userName}/resetpassword`),

  changePassword: (model: ResetPasswordModel) =>
    request.post("/membership/user/resetpassword", model),

  refreshToken: (refreshToken: string) => {
    return requestWithoutTokenUpdate.post<TokenResponse>(
      "/token",
      {
        refresh_token: refreshToken,
        grant_type: "refresh_token"
      },
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      }
    ) as any as Promise<TokenResponse>
  }
}

export default users
