import { Link } from "react-router-dom"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"

import LogoIcon from "icons/Logo"
import SiteSettingStore from "store/SiteSettingStore"

type Props = {
  onClick?: () => void
}

const Logo = ({ onClick }: Props) => {
  const {
    state: { links }
  } = useStore(SiteSettingStore)

  const classes = useStyles()

  return (
    <Link
      to={links && links.home ? links.home.href : "/"}
      className={classes.root}
      onClick={onClick}
    >
      <LogoIcon />
    </Link>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    padding: spacing(1),
    display: "inline-block"
  },
  "& svg": {
    [breakpoints.down("sm")]: {
      height: 20
    }
  }
}))

export default Logo
