import { FC } from "react"

import BlockPreviewPage from "../BlockPreviewPage"

import CmsContext, { BlockComponentMap, PageComponentMap } from "./context"

type Props = {
  pageMap: PageComponentMap
  blockMap: BlockComponentMap
  formMap: BlockComponentMap
}

const CmsProvider: FC<Props> = ({ children, pageMap, blockMap, formMap }) => {
  const isEditMode = window.EPI_EDIT_MODE || false
  const defaultPageMap = { ...pageMap, BlockPreviewPage }

  return (
    <CmsContext.Provider
      value={{ isEditMode, pageMap: defaultPageMap, blockMap, formMap }}
    >
      {children}
    </CmsContext.Provider>
  )
}
export default CmsProvider
