import { useTranslation } from "react-i18next"
import { Formik, FormikActions } from "formik"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"

import Wrapper from "common/Wrapper"
import authStore from "store/AuthStore"
import validationSchema, { LoginFormType } from "./LoginForm/validationSchema"
import LoginForm from "./LoginForm"

type OwnProps = {
  reloadCartOnLogin?: boolean
}

type Props = OwnProps

const initialValues = {
  userName: "",
  password: ""
}
const Login: React.FunctionComponent<Props> = ({ reloadCartOnLogin }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    actions: { login }
  } = useStore(authStore)

  const handleSubmit = async (
    values: LoginFormType,
    { setSubmitting, setStatus }: FormikActions<LoginFormType>
  ) => {
    await login(values)
    setSubmitting(false)
  }

  return (
    <Wrapper
      containerClassName={classes.wrapper}
      className={classes.wrapperRoot}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur
        component={LoginForm}
        validationSchema={validationSchema(t)}
      />
    </Wrapper>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  wrapperRoot: {
    padding: 0,
    flexDirection: "column",
    width: "100%",
    display: "flex",
    flexGrow: 1,
    background: themeColors.offWhite
  },
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    paddingBlock: 0
  }
}))

export default Login
