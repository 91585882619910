import { ImageType } from "types/content/media"
import { Price } from "./Products"

export type ImageAsset = {
  _type: string
  _url: string
  group: string
  dominantColor: string
  width: number
  height: number
  alt?: string
}

export default class Variant {
  _route: string
  _id: number
  code: string
  displayName: string
  _displayPrice: Price
  _discountedPrice?: Price
  displayPriceString: string
  categoryStructure: string
  inventoryStatus: string
  isInventoryTracked: boolean
  status: string
  imageAssets: ImageType[]
  displayImage: ImageAsset
  articleColor: string
  articleFreeText: string
  articleLength: string
  articleNumber: string
  articleSize: string
  articleVolume: string
  articleWeight: string
  lengthUnit: string
  volumeUnit: string
  weightUnit: string
  variationType: string
  variationValue: string
  isDangerousGoods: boolean
  isReturnable: boolean
  isDigitalService: boolean
  variationDiscountedPricePerX: string
  variationPricePerX: string
  variationPricePerXUnitType: string
}
