import { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const Shipping: React.FC<Props> = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip10)">
      <path
        d="M6.5 3.25L18.5 9.25"
        stroke="#103D82"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M23 7L13 12L1 6"
        stroke="#103D82"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M13 12V23"
        stroke="#103D82"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M23 7V17L13 23L1 17V6L11 1L23 7Z"
        stroke="#103D82"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip10">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Shipping
