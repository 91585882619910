import { useEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import PageResolver from "@starrepublic/epi/cms/components/PageResolver"
import { extractPath } from "@starrepublic/epi/utils/location"
import useStore from "global-hook-store"

import Grow from "@material-ui/core/Grow"
import { makeStyles } from "@material-ui/core/styles"

import ErrorMessage from "common/ErrorMessage"
import { browserHistory } from "common/Root"
import Spinner from "common/Spinner"
import useOptimizelyLinkResolver from "hooks/useOptimizelyLinkResolver"
import { pageStore } from "store/PageStore"
import siteSettingStore from "store/SiteSettingStore"

type Props = {
  //TODO: type this correctly
  location: any
}

const InitialSpinner = () => {
  const classes = useStyles()

  return (
    <div className={classes.initialSpinnerRoot}>
      <PageSpinner fetching={true} />
    </div>
  )
}

const PageSpinner = ({ fetching }: { fetching: boolean }) => {
  const classes = useStyles()

  return (
    <div className={classes.pageSpinnerRoot}>
      <Grow
        in={fetching}
        unmountOnExit
        style={{
          transitionDelay: (fetching && "250ms") || "0ms"
        }}
      >
        <div>
          <Spinner className={classes.spinner} fade={false} />
        </div>
      </Grow>
    </div>
  )
}

const PageContainer: React.FC<Props> = ({ location }) => {
  const pageRef = useRef<HTMLDivElement>(null)

  const {
    state: {
      page: { loading, data: page, error },
      fetchLocation
    },
    actions
  } = useStore(pageStore)

  const {
    state: { _settingsCacheKey: siteSettingsCacheKey },
    actions: { fetchSiteSettings }
  } = useStore(siteSettingStore)

  const extractedPath = extractPath(location)

  useEffect(
    () => {
      if (fetchLocation) {
        actions.fetch({ id: extractedPath })
      } else {
        actions.setFetchLocation(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extractedPath]
  )

  useEffect(
    () => {
      if (page && page._settingsCacheKey !== siteSettingsCacheKey) {
        fetchSiteSettings(false)
      }

      if (
        page !== undefined &&
        page !== null &&
        !isNaN(parseInt(extractedPath.replace(/\//g, "").substr(2), 10))
      ) {
        actions.setFetchLocation(false).then(() => {
          browserHistory.replace(page._route)
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page && page._id]
  )

  useOptimizelyLinkResolver(pageRef)

  if (loading && !page) {
    return <InitialSpinner />
  }

  if (error) {
    return (
      <>
        {error === "CONTENT_NOT_FOUND" && (
          <Helmet
            meta={[
              {
                name: "prerender-status-code",
                content: "404"
              }
            ]}
          />
        )}
        <ErrorMessage error={error.toString()} />
      </>
    )
  }

  if (!page) {
    return null
  }
  return (
    <>
      {page && <Helmet title={page.siteTitle} />}
      <PageSpinner fetching={loading} />
      <div ref={pageRef}>
        <PageResolver
          autoScroll
          fetchContent={(id, useCache) => actions.fetch({ id, useCache })}
          content={page}
        />
      </div>
    </>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  initialSpinnerRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10%",
    marginBottom: "10%"
  },
  pageSpinnerRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "fixed",
    zIndex: 99999,
    top: "30%",
    left: 0,
    right: 0,
    width: "100%"
  },
  spinner: {
    boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
    backgroundColor: themeColors.white,
    boxSizing: "content-box",
    padding: spacing(1),
    borderRadius: spacing(4)
  }
}))

export default PageContainer
