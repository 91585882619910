import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Form as FormikForm } from "formik"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import CustomTextField from "common/Formik/CustomTextField"
import FormikCheckbox from "common/Formik/FormikCheckbox"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import Checked from "icons/Checked"
import EnergyCalculator from "store/EnergyCalculatorStore"

const CalculatorContact: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [acceptedFormPolicy, setFormPolicy] = useState(false)

  const {
    state: { labels, result, xhtmlStrings, enableEmailInContactForm }
  } = useStore(EnergyCalculator)

  const handleCheckBox = (e: React.MouseEvent) => {
    setFormPolicy(!acceptedFormPolicy)
  }

  const formSent = result.data && result.data.status === 200 ? true : false
  const loading = result.loading

  return (
    <Box className={classes.root} p={2}>
      <Box maxWidth={496} m="auto">
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          width="100%"
          gridGap={16}
          pb={2}
        >
          <Box display="flex">
            <Box textAlign="center" className={classes.contactLabel}>
              <ThemeXhtmlString content={xhtmlStrings.contactLabel1} />
            </Box>
          </Box>
          <Divider style={{ width: "100%" }} />
          <Typography>{labels.contactLabel2}</Typography>
        </Box>

        <FormikForm data-form-id="newsletter-form" noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTextField
                required
                name="name"
                label={t("account.full_name_label")}
                disabled={formSent}
                type="text"
              />
            </Grid>
            {enableEmailInContactForm && (
              <Grid item xs={12}>
                <CustomTextField
                  required
                  name="email"
                  label={t("account.email_label")}
                  disabled={formSent}
                  type="text"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <CustomTextField
                required
                name="phone"
                label={t("checkout.phone_input_label")}
                disabled={formSent}
                type="text"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextField
                required
                name="postalCode"
                label={t("checkout.zip_code_input_label")}
                disabled={formSent}
                type="text"
              />
            </Grid>
            <Grid container>
              <Grid xs={12} item>
                <Box display="flex" pl={1}>
                  <FormikCheckbox
                    required
                    name="acceptPrivacyPolicy"
                    color="default"
                    checked={acceptedFormPolicy}
                    onClick={handleCheckBox}
                    inputProps={{ "aria-label": "primary checkbox" }}
                    className={classes.checkBox}
                    disabled={formSent}
                  />
                  {xhtmlStrings.checkboxLabel && (
                    <ThemeXhtmlString content={xhtmlStrings.checkboxLabel} />
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid container item justifyContent="center" xs={12}>
              <Button
                className={classes.submitButton}
                variant="primary"
                type="submit"
                disabled={!acceptedFormPolicy || formSent}
              >
                {loading ? (
                  <ButtonLoaderIcon />
                ) : formSent ? (
                  <Checked white />
                ) : (
                  labels.sendFormButton
                )}
              </Button>
            </Grid>
          </Grid>
        </FormikForm>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  root: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: themeColors.white
    }
  },
  checkBox: {
    paddingLeft: 0
  },
  checkboxText: {
    color: themeColors.darkGray
  },
  phone: {
    color: themeColors.primaryCyan
  },
  contactLabel: {
    "& p": {
      margin: 0
    }
  },
  submitButton: {
    minHeight: 44,
    minWidth: 130
  }
}))

export default CalculatorContact
