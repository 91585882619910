import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import { Alert } from "@material-ui/lab"

import Button from "common/Button"
import Typography from "common/Typography"

type AlertStepperProps = {
  alertMessage: string
  confirmButtonText: string
  cancelButtonText: string
  onResetStepper: () => void
  confirmChangeOpen: boolean
  onSetConfirmChangeOpen: (isConfirmChangeOpen: boolean) => void
}

const AlertStepper = ({
  alertMessage,
  confirmButtonText,
  cancelButtonText,
  onResetStepper,
  confirmChangeOpen,
  onSetConfirmChangeOpen
}: AlertStepperProps) => {
  const classes = useStyles()

  const reset = () => {
    onSetConfirmChangeOpen(false)
    onResetStepper()
  }

  return (
    <>
      {confirmChangeOpen && (
        <Box my={2}>
          <Alert
            severity="warning"
            variant="outlined"
            className={classes.alertContainer}
          >
            <Typography variant="body1">{alertMessage}</Typography>
            <Box>
              <Button
                variant="secondary"
                style={{ marginRight: ".25rem", color: "#333333" }}
                onClick={reset}
              >
                {confirmButtonText}
              </Button>
              <Button
                onClick={() => {
                  onSetConfirmChangeOpen(false)
                }}
              >
                {cancelButtonText}
              </Button>
            </Box>
          </Alert>
        </Box>
      )}
    </>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  alertContainer: {
    [breakpoints.up("sm")]: {
      margin: "0 auto"
    },
    background: "#FFF2B3",
    border: "3px solid #F7AA08",
    borderTopStyle: "none",
    borderRightStyle: "none",
    borderBottomStyle: "none",
    "& .MuiAlert-icon": {
      padding: spacing(2),
      margin: 0
    },
    "& .MuiAlert-message > p": {
      padding: spacing(1, 2, 1, 0),
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.4,
      color: "#333333"
    },
    "& .MuiButtonBase-root": {
      background: "#FFF2B3",
      margin: spacing(2, 0, 1),
      padding: spacing(0.5, 2)
    },
    "& .MuiButton-outlinedPrimary": {
      borderColor: "#333333"
    },
    "& .MuiButton-label": {
      color: "#333333"
    }
  }
}))

export default AlertStepper
