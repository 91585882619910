// MediaRenderer.tsx
import { FC } from "react"

import ExternalVideoAndImage from "./ExternalVideoAndImage"
import ImageCollage from "./ImageCollage"
import { TextAndVideoBlockContent } from "./types" // Adjust the import path as necessary

interface MediaRendererProps {
  content: TextAndVideoBlockContent
  showOneImage: boolean
  isAlwaysCarousel?: boolean
  isHalf: boolean
  isBottom: boolean
  isOneThird: boolean
}

const MediaRenderer: FC<MediaRendererProps> = ({
  content,
  showOneImage,
  isAlwaysCarousel,
  isHalf,
  isBottom,
  isOneThird
}) => {
  const { images, video } = content

  const isVideo = video && video.value

  if (images && images.length > 0 && !isVideo) {
    return (
      <ImageCollage
        {...content}
        display={isHalf}
        isBottom={isBottom}
        isOneThird={isOneThird}
        showOneImage={showOneImage}
        isCarousel={isAlwaysCarousel}
      />
    )
  } else if (isVideo) {
    return (
      <ExternalVideoAndImage
        {...content}
        isHalf={isHalf}
        isBottom={isBottom}
        isOneThird={isOneThird}
      />
    )
  }

  return null
}

export default MediaRenderer
