import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"

import EditPen from "common/EditPen"
import Typography from "common/Typography"

type AccountModalProps = {
  title?: string
  preamble?: string
  smallText?: string
  onClose: () => void
  handleEditDialog?: () => void
  modalTrigger: React.ReactNode
  open: boolean
  children: React.ReactNode
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false
  fullScreen?: boolean
  fullWidth?: boolean
}

const AccountModal: React.FC<AccountModalProps> = ({
  title,
  preamble,
  smallText,
  onClose,
  handleEditDialog,
  modalTrigger,
  open,
  children,
  maxWidth = "sm",
  fullScreen,
  fullWidth
}) => {
  const classes = useStyles()

  return (
    <>
      {modalTrigger}
      <Dialog
        aria-labelledby="edit-user-details-modal"
        aria-describedby="edit-user-details-here"
        open={open}
        onClose={onClose}
        className={classes.root}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
      >
        <DialogContent
          className={classes.dialog}
          classes={{ root: classes.dialogContentRoot }}
        >
          <DialogTitle className={classes.dialogTitle} disableTypography>
            <Grid container>
              <Grid className={classes.preambleContainer}>
                {preamble && (
                  <Typography variant="headline4">{preamble}</Typography>
                )}
                {smallText && (
                  <Typography variant="body2">{smallText}</Typography>
                )}
              </Grid>
              {title && (
                <Typography className={classes.title} variant="headline4">
                  {title}
                </Typography>
              )}
              {handleEditDialog && <EditPen onClick={handleEditDialog} />}
            </Grid>
            <IconButton
              id="close-edit_user_details-modal"
              onClick={onClose}
              className={classes.closeButton}
            >
              <div>
                <CloseIcon className={classes.closeIcon} />
              </div>
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.content}>{children}</DialogContent>
        </DialogContent>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    root: {
      overflow: "scroll"
    },
    dialog: {
      outline: 0,
      backgroundColor: themeColors.white,
      height: "100%",
      padding: 0,
      [breakpoints.up("md")]: {
        height: "auto",
        maxHeight: "100%"
      }
    },
    dialogContentRoot: {
      minWidth: 450,
      "&:first-child": {
        paddingTop: 0
      },
      [breakpoints.down("xs")]: {
        minWidth: "100%"
      }
    },
    dialogTitle: {
      display: "flex",
      padding: `0 0 0 ${spacing(3)}px`,
      justifyContent: "space-between",
      alignItems: "center"
    },
    closeButton: {
      padding: spacing(2.5),
      lineHeight: 0
    },
    closeIcon: {
      "& g": {
        stroke: themeColors.darkGray
      }
    },
    content: {
      padding: spacing(3),
      margin: 0
    },
    title: {
      marginRight: spacing(2),
      color: themeColors.primaryBaseBlue
    },
    preambleContainer: {
      paddingTop: spacing(2),
      "& h6": {
        fontSize: 16
      }
    }
  })
)

export default AccountModal
