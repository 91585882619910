import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"

import PricePerUnit from "common/Product/PricePerUnit"
import ProductPrice from "common/Product/ProductPrice"
import StockStatus from "common/Product/StockStatus"
import Typography from "common/Typography"
import { Price } from "models/Products"
import SiteSettingsStore from "store/SiteSettingStore"

type VariantItemProps = {
  variantName: string
  stockStatus: string
  isDigitalService: boolean
  id: number
  price: Price
  variationPricePerX: string
  variationDiscountedPricePerX: string
  variationPricePerXUnitType: string
  discountedPrice?: Price
}

const VariantItem: React.FC<VariantItemProps> = ({
  variantName,
  stockStatus,
  isDigitalService,
  price,
  discountedPrice,
  variationPricePerX,
  variationDiscountedPricePerX,
  variationPricePerXUnitType
}) => {
  const classes = useStyles()

  const {
    state: { enableEcommerce }
  } = useStore(SiteSettingsStore)

  return (
    <Box display="flex" justifyContent="space-between" width={1}>
      <Grid container alignItems="center" spacing={4} wrap="nowrap">
        <Grid item className={classes.variantWrapper}>
          <Typography className={classes.variantName} variant="body2">
            {variantName}
          </Typography>

          {enableEcommerce &&
            (variationPricePerX || variationDiscountedPricePerX) && (
              <Box id="variantPriceItem">
                <PricePerUnit
                  lightGray
                  unit={variationPricePerXUnitType}
                  price={
                    variationDiscountedPricePerX !== null &&
                    variationPricePerX !== variationDiscountedPricePerX
                      ? variationDiscountedPricePerX
                      : variationPricePerX
                  }
                />
              </Box>
            )}
        </Grid>
        {enableEcommerce && (
          <Hidden smDown>
            <Grid
              id="variantPriceItem"
              item
              container
              direction="row"
              wrap="nowrap"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <StockStatus
                  inventoryStatus={stockStatus}
                  isDigitalService={isDigitalService}
                />
              </Grid>
              <Grid />
              <Grid item>
                <ProductPrice
                  price={price}
                  discountedPrice={discountedPrice}
                  size={"small"}
                  isInline={false}
                />
              </Grid>
            </Grid>
          </Hidden>
        )}
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  variantName: {
    color: themeColors.black,
    whiteSpace: "normal"
  },
  variantWrapper: {
    width: "100%"
  }
}))

export default VariantItem
