import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { PageDataType, SearchResultType } from "api/search"
import LinkText from "common/LinkText"
import ResponsiveImage from "common/ResponsiveImage"
import Spinner from "common/Spinner"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import Product from "models/Products"
import SearchStore from "store/SearchStore"
import SiteSettingStore from "store/SiteSettingStore"

type OwnProps = {
  className?: string | undefined
  searchTerm: string
  onClose: () => void
}
type Props = OwnProps

const SearchContent: React.FC<Props> = ({ onClose, searchTerm, className }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    state: { result }
  } = useStore(SearchStore)

  const {
    state: { links }
  } = useStore(SiteSettingStore)

  const renderProducts = () => {
    const lang = `${(links.search && links.search.href) || "/"}?q=${searchTerm}`

    const searchResults =
      result.data &&
      result.data.filter((s) => {
        return s.searchType === SearchResultType.Products
      })

    if (!searchResults) {
      return null
    }

    const searchData = searchResults[0]

    return (
      <Grid item xs={12} md={searchData.hits.length > 0 ? 6 : 12}>
        <div className={classes.searchResultTitleWrapper}>
          <Typography className={classes.searchResultTitle}>
            {`${t("search.product_title")} (${searchData.hits.length})`}
          </Typography>
          {searchData.hits.length <= 0 && (
            <Typography>{t("search.products_not_found")}</Typography>
          )}
          {searchData.totalItems !== null &&
            searchData.totalItems > searchData.hits.length && (
              <LinkText
                id="more-products-search-result-link"
                onClick={onClose}
                link={lang}
                variant="link2"
              >
                {`${t("search.productstotal_title")} (${
                  searchData.totalItems
                })`}
              </LinkText>
            )}
        </div>
        <ul id="products-search-result-list" className={classes.productListing}>
          {(searchData.hits as Product[]).map((p) => {
            return (
              <li className={classes.productAreaWrapper} key={p._id}>
                <LinkText
                  onClick={onClose}
                  link={p._route}
                  className={classes.link}
                >
                  <div className={classes.textImgWrapper}>
                    <div className={classes.imageContainer}>
                      <ResponsiveImage
                        containerProps={{
                          className: classes.imageBackgroundContainer
                        }}
                        className={classes.image}
                        lazy={false}
                        color={p.displayImage && p.displayImage.dominantColor}
                        image={p.displayImage}
                      />
                    </div>
                    {/* TODO: Needs UX on search result */}
                    <Typography variant="link4">
                      {p.displayLowestPriceArticleNumber}&nbsp;
                    </Typography>
                    <Typography variant="link3">
                      &nbsp;{p.displayName}
                    </Typography>
                  </div>
                </LinkText>
              </li>
            )
          })}
        </ul>
      </Grid>
    )
  }

  const renderPages = () => {
    const searchResults =
      result.data &&
      result.data.filter((s) => {
        return s.searchType === SearchResultType.Pages
      })

    if (!searchResults || !searchResults.length) {
      return null
    }

    const searchData = searchResults[0]

    // Hide of no pages is present in seach result. Workaround for Markets without CMS content
    if (searchData.totalItems === 0) {
      return null
    }
    return (
      <Grid item xs={12} md={6}>
        <div className={classes.searchResultTitleWrapper}>
          <Typography className={classes.searchResultTitle}>
            {`${t("search.content_pages_title")} (${searchData.hits.length})`}
          </Typography>
          {/* 
          TODO: This is removed in the MVP scope
          {searchData.totalItems !== null &&
            searchData.totalItems > searchData.hits.length && (
              <LinkText onClick={onClose} link={lang} variant="link2">
                {`${t("search.content_pages_total_title")} (${
                  searchData.totalItems
                })`}
              </LinkText>
            )} */}
        </div>
        <ul id="content-search-result-list" className={classes.productListing}>
          {(searchData.hits as PageDataType[]).map((p) => {
            return (
              <li className={classes.productAreaWrapper} key={p._id}>
                <LinkText
                  onClick={onClose}
                  link={p._route}
                  className={classes.link}
                >
                  <div className={classes.contentTextImgWrapper}>
                    <Typography variant="link3">{p.title}</Typography>
                  </div>
                </LinkText>
              </li>
            )
          })}
        </ul>
      </Grid>
    )
  }

  if (!result.loading && !result.data) {
    return null
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Wrapper className={classes.menuWrapper}>
        {result.loading ? (
          <div className={classes.spinnerWrapper}>
            <Spinner />
          </div>
        ) : (
          <Grid container>
            {renderPages()}
            {renderProducts()}
          </Grid>
        )}
      </Wrapper>
    </div>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    top: 105,
    width: "100%",
    zIndex: 999,
    background: themeColors.white,
    borderBottom: "1px solid lightgray"
  },
  menuWrapper: {
    padding: spacing(4),
    display: "flex",
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
    position: "relative",
    boxShadow: themeColors.boxShadow3
  },
  spinnerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 30
  },
  searchResultTitleWrapper: {
    display: "flex",
    alignItems: "start"
  },
  searchResultTitle: {
    whiteSpace: "nowrap",
    fontWeight: "bold",
    lineHeight: "24px",
    marginRight: spacing(1),
    margin: 0,
    padding: `0px ${spacing(1)}px`
  },
  productListing: {
    padding: `${spacing(1)}px 0 0 0`
  },
  productTitle: {
    display: "flex",
    alignItems: "center"
  },
  productAreaWrapper: {
    display: "flex",
    padding: `0px ${spacing(1)}px`,
    margin: 0,
    flexWrap: "wrap",
    width: "100%"
  },
  link: {
    display: "flex",
    alignItems: "center"
  },
  imageContainer: {
    position: "relative",
    display: "flex",
    height: 56,
    width: 56,
    marginRight: spacing(1)
  },
  imageBackgroundContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0
  },
  image: {
    width: "100%",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)"
  },
  textImgWrapper: {
    display: "flex",
    alignItems: "center"
  },
  contentTextImgWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: spacing(2)
  }
}))

export default SearchContent
