import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const MainsIcon: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 41 40">
    <path
      fill="#fff"
      d="M35.819 10.931a1.689 1.689 0 0 0-2.39 0L29 15.36 24.64 11l4.433-4.43a1.69 1.69 0 0 0-2.39-2.391l-4.433 4.43-3.306-3.302a1.691 1.691 0 0 0-2.39 2.39l.493.49L10 15.24a6.187 6.187 0 0 0 0 8.751l1.812 1.811-6.506 6.506a1.69 1.69 0 0 0 2.39 2.39l6.506-6.505 1.811 1.811a6.19 6.19 0 0 0 8.751 0l7.049-7.05.493.496a1.691 1.691 0 0 0 2.39-2.391l-3.305-3.308 4.432-4.43a1.688 1.688 0 0 0-.004-2.389ZM22.375 27.614a2.812 2.812 0 0 1-3.978 0l-6.01-6.01a2.812 2.812 0 0 1 0-3.979l7.05-7.046 9.985 9.984-7.047 7.05Z"
    />
  </SvgIcon>
)

export default MainsIcon
