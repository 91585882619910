type Props = {
  width?: number | string
  height?: number | string
  dark?: boolean
}

const Logo: React.FC<Props> = ({ dark, width }) => {
  const colorCheck = `${dark ? "#103D82" : "#ffffff"}`

  return (
    <svg width={width} height="24" viewBox="0 0 120 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4154 15.5067C16.8122 15.9867 17.8078 17.2687 17.8078 17.2687C17.8078 17.2687 16.8122 18.5527 16.4154 19.0311C15.9074 19.6443 15.139 20.0371 14.2786 20.0371C12.749 20.0371 11.509 18.7991 11.509 17.2687C11.509 15.7383 12.749 14.4987 14.2786 14.4987C15.139 14.4987 15.9074 14.8947 16.4154 15.5067Z"
        fill={colorCheck}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8389 23.046C23.3393 22.878 22.0537 22.154 20.7813 20.8228C20.5925 20.6252 20.3341 20.3524 20.0845 20.0372C19.7249 20.49 19.3961 20.8832 19.1609 21.1684C18.4557 22.0208 17.7257 22.6688 16.5413 23.046V23.0964H24.8389V23.046Z"
        fill={colorCheck}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6334 11.792H22.2082L20.0842 14.4496L22.333 17.1832L26.6334 11.792Z"
        fill="#FF3300"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1734 13.2839L12.4198 1.59985L-0.000183105 23.0967H12.1406V23.0463C10.051 22.4703 8.01822 20.1843 8.01822 17.2683C8.01822 13.8143 10.821 11.0095 14.279 11.0095C16.2054 11.0095 17.8398 11.8275 19.1214 13.3379L19.1734 13.2839Z"
        fill={colorCheck}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.391 23.2567H120V4.62585H116.391V23.2567Z"
        fill={colorCheck}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.46 9.56421H98.5955L95.6491 19.9306L92.7847 9.56421H88.7147L93.3979 23.257H97.7767L102.46 9.56421Z"
        fill={colorCheck}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.591 23.2566V19.9562H67.0914V4.57581H63.2298V23.2566H74.591Z"
        fill={colorCheck}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.033 18.213C111.033 19.1114 110.47 21.0322 107.809 21.0322C106.603 21.0322 105.993 20.419 105.993 19.3446C105.993 18.265 106.733 17.6778 108.011 17.3998C109.036 17.1654 110.239 16.9878 111.033 16.6046V18.213ZM114.615 23.2571V13.559C114.615 10.6398 112.953 9.13025 108.909 9.13025C104.022 9.13025 103.049 11.6906 102.869 13.6338H106.375C106.603 12.483 107.347 11.8682 108.88 11.8682C110.291 11.8682 111.033 12.3046 111.033 13.351C111.033 13.711 110.904 14.1202 110.367 14.351C109.447 14.735 108.449 14.759 107.347 14.9646C105.531 15.2994 104.636 15.681 103.945 16.1942C102.845 16.9646 102.381 18.0898 102.381 19.5475C102.381 21.6962 103.61 23.6414 106.733 23.6414C108.653 23.6414 110.035 22.9258 111.135 21.6711V23.2571H114.615Z"
        fill={colorCheck}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.5599 18.213C84.5599 19.1114 83.9971 21.0322 81.3347 21.0322C80.1331 21.0322 79.5195 20.419 79.5195 19.3446C79.5195 18.265 80.2607 17.6778 81.5407 17.3998C82.5627 17.1654 83.7655 16.9878 84.5599 16.6046V18.213ZM88.1431 23.2571V13.559C88.1431 10.6398 86.4823 9.13025 82.4363 9.13025C77.5467 9.13025 76.5763 11.6906 76.3959 13.6338H79.9027C80.1331 12.483 80.8739 11.8682 82.4111 11.8682C83.8175 11.8682 84.5599 12.3046 84.5599 13.351C84.5599 13.711 84.4307 14.1202 83.8951 14.351C82.9731 14.735 81.9747 14.759 80.8739 14.9646C79.0563 15.2994 78.1607 15.681 77.4703 16.1942C76.3699 16.9646 75.9095 18.0898 75.9095 19.5475C75.9095 21.6962 77.1355 23.6414 80.2607 23.6414C82.1795 23.6414 83.5627 22.9258 84.6623 21.6711V23.2571H88.1431Z"
        fill={colorCheck}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.9192 14.9647H52.1116C52.264 13.5827 53.0564 12.0475 55.154 12.0475C57.2292 12.0475 57.7892 13.5827 57.9192 14.9647ZM61.6564 17.3695V16.2447C61.6564 12.7139 60.096 9.13074 55.2824 9.13074C49.2948 9.13074 48.4008 13.9155 48.4008 16.6303C48.4008 20.9799 50.7032 23.7175 55.13 23.7175C58.6892 23.7175 61.0676 21.6199 61.478 19.2143H57.7892C57.4832 19.8787 56.9732 20.7755 55.0544 20.7755C53.0832 20.7755 52.2112 19.3171 52.1116 17.3695H61.6564Z"
        fill={colorCheck}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.0931 13.7365C43.0931 17.0369 42.0431 20.0593 38.3571 20.0593H35.2083V7.77327H38.3571C42.0431 7.77327 43.0931 10.0261 43.0931 13.7365ZM47.1367 13.4558C47.1367 8.64137 44.8583 4.57617 39.3543 4.57617H31.3427V23.2566H39.3543C44.0903 23.2566 47.1367 19.801 47.1367 13.4558Z"
        fill={colorCheck}
      />
    </svg>
  )
}

export default Logo
