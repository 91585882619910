import "i18n"

import { FC, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { Router } from "react-router-dom"
import { CmsProvider } from "@starrepublic/epi/cms/components/CmsContext"
import { BlockMapping, FormMapping, PageMapping } from "componentMapping"
import useStore from "global-hook-store"
import { createBrowserHistory } from "history"
import qs from "qs"

import CssBaseLine from "@material-ui/core/CssBaseline"
import { MuiThemeProvider } from "@material-ui/core/styles"

import { SnackbarUtilsConfigurator } from "utils/SnackbarUtils"

import App from "common/App"
import Snackbar from "common/Snackbar"
import StarGAHelmet from "services/StarGAHelmet"
import DrawerStateStore from "store/DrawerStateStore"
import { useHeaderStore } from "store/HeaderStore"
import SiteSettingStore from "store/SiteSettingStore"
import theme from "theme"

import "assets/css/style.css"

const browserHistory = createBrowserHistory()
const query = qs.parse(window.location.search)

const Root: FC = () => {
  const [state, setState] = useState<any>({
    action: browserHistory.action,
    location: browserHistory.location
  })

  const {
    actions: { setAccountDrawer }
  } = useStore(DrawerStateStore)

  const {
    state: { eComMenu }
  } = useStore(SiteSettingStore)

  const memoizedEcomMenu = useMemo(() => eComMenu, [eComMenu])

  const { setMenuCurrentAncestors, init: initHeaderStore } = useHeaderStore()

  useEffect(() => {
    if (memoizedEcomMenu && memoizedEcomMenu.length) {
      initHeaderStore(memoizedEcomMenu)
    }
  }, [memoizedEcomMenu, initHeaderStore])

  useEffect(() => {
    if (query["?login"] === "") {
      setAccountDrawer(true)
    }
  }, [setAccountDrawer])

  useEffect(() => {
    browserHistory.listen(() => {
      setMenuCurrentAncestors()
    })
  }, [setMenuCurrentAncestors])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => browserHistory.listen(setState), [browserHistory])

  return (
    <>
      <StarGAHelmet />
      <Router
        location={state.location}
        navigationType={state.action}
        navigator={browserHistory}
      >
        <MuiThemeProvider theme={theme}>
          <Snackbar>
            <SnackbarUtilsConfigurator />
            <CmsProvider
              blockMap={BlockMapping}
              pageMap={PageMapping}
              formMap={FormMapping}
            >
              <CssBaseLine />
              <App />
            </CmsProvider>
          </Snackbar>
        </MuiThemeProvider>
      </Router>
    </>
  )
}

export default Root
export { browserHistory }
