import { BlockProps, editorAttributes as editor } from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid, { GridSize } from "@material-ui/core/Grid"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"

import { contrastSwitch } from "utils/contrastSwitch"

import Button from "common/Button"
import LinkButton from "common/LinkButton"
import Typography from "common/Typography"
import { HeroBlockContent } from "./types"

type Props = {
  content: HeroBlockContent
  propsInfo: BlockProps<HeroBlockContent>["propsInfo"]
  isOnePager: boolean
  textAlignment: "start" | "center" | "end"
  convertHeadline: boolean
}

type StyleProps = {
  _isOnePager: boolean
  _textAlignment: "start" | "center" | "end"
  _hasSmallTitle: boolean
  _hasCallToActionTitle: boolean
}

const MainContent: React.FC<Props> = ({
  content,
  propsInfo,
  isOnePager,
  textAlignment,
  convertHeadline
}) => {
  const { bigTitle, smallTitle, theme, callToActionUrl, callToActionTitle } =
    content

  const classes = useStyles({
    _isOnePager: isOnePager,
    _textAlignment: textAlignment,
    _hasSmallTitle: smallTitle ? true : false,
    _hasCallToActionTitle: callToActionTitle ? true : false
  })()

  const {
    common: { themeColors }
  } = useTheme()

  const gridSizes = {
    xs: 12,
    sm: isOnePager ? 12 : 8,
    md: 6,
    lg: isOnePager ? 6 : 4
  } as Record<Breakpoint, boolean | GridSize>

  return (
    <Grid
      item
      xs={gridSizes.xs}
      sm={gridSizes.sm}
      md={gridSizes.md}
      lg={gridSizes.lg}
      className={classes.root}
    >
      {bigTitle && (
        <Typography
          textWrap="wrap"
          variant="headline1"
          {...editor(propsInfo.bigTitle)}
          component={convertHeadline ? "h2" : "h1"}
          className={classNames(
            classes.title,
            classes[
              contrastSwitch({
                variant: "title",
                contrastColor:
                  !content.backgroundImage || theme
                    ? theme
                    : themeColors.colorBaseBlue700
              })
            ]
          )}
        >
          {bigTitle}
        </Typography>
      )}
      {smallTitle && (
        <Box mt={isOnePager ? 4 : 0}>
          <Typography
            variant={isOnePager ? "body1" : "subheading1"}
            {...editor(propsInfo.smallTitle)}
            component="h3"
            className={classNames(
              classes.subTitle,
              classes[
                contrastSwitch({
                  variant: "body",
                  contrastColor:
                    !content.backgroundImage || theme
                      ? theme
                      : themeColors.colorBaseBlue700
                })
              ]
            )}
          >
            {smallTitle}
          </Typography>
        </Box>
      )}
      {callToActionTitle && callToActionUrl && (
        <LinkButton link={callToActionUrl}>
          <Button variant="primary" contrastColor={theme}>
            {callToActionTitle}
          </Button>
        </LinkButton>
      )}
    </Grid>
  )
}

const useStyles = ({
  _isOnePager,
  _textAlignment,
  _hasSmallTitle,
  _hasCallToActionTitle
}: StyleProps) =>
  makeStyles(({ breakpoints, spacing, common: { themeColors } }) => ({
    root: {
      flex: "0 0 auto",
      textAlign: _isOnePager ? "left" : "center",
      maxWidth: 604,
      width: "100%",
      padding: _isOnePager ? `${spacing(8)}px 6%` : 0,
      borderRadius: 2,
      [breakpoints.up("md")]: {
        textAlign:
          _textAlignment !== "end" && !_isOnePager ? _textAlignment : "start"
      }
    },
    title: {
      marginBottom: _hasSmallTitle || _hasCallToActionTitle ? spacing(3) : 0,
      [breakpoints.down("xs")]: {
        fontSize: 40
      }
    },
    subTitle: {
      marginBottom: _hasCallToActionTitle ? spacing(4) : 0,
      lineHeight: 1.04
    },
    ["text-base-white"]: {
      color: themeColors.white
    },
    ["text-base-blue-700"]: {
      color: themeColors.colorBaseBlue700
    },
    ["text-base-blue-800"]: {
      color: themeColors.colorBaseBlue800
    },
    ["text-base-blue-900"]: {
      color: themeColors.colorBaseBlue900
    }
  }))

export default MainContent
