import request from "@starrepublic/epi/api/request"

import { Order } from "api/orders"
import Checkout, { PromoCode } from "models/Checkout"

export type CheckoutProperty = {
  name: string
  value: string
}

export type CheckoutModel = {
  deliveryOptionId: string
  paymentOptionId: string
  shippingAddress?: Address
  billingAddress: Address
  properties: CheckoutProperty[]
  customerOrderReference: string
}

export type ShippingCheckModel = {
  shippingPostalCode: string
  shippingCountryCode: string
}

export type Address = {
  firstName: string
  lastName: string

  companyName?: string
  line1: string // streetAddress
  daytimePhoneNumber: string // phone
  eveningPhoneNumber: string // phone
  city: string
  countryCode: string
  countryName?: string
  zipCode: string
  email: string
}

export type SelectedDeliveryAndPaymentModel = {
  deliveryOptionId: string
  paymentOptionId: string
}

export type FinalizeCheckoutModel = {
  payload: string
  resultCode: string
  type: string
}

const checkout = {
  getCheckout: (cartTypeName: string) =>
    request.get<Checkout>(`/carts/${cartTypeName}/checkout`),
  addPromoCode: (cartTypeName: string, couponCode: string) =>
    request.post<PromoCode>(
      `/carts/${cartTypeName}/checkout/coupon/${couponCode}`
    ),
  shippingCheck: (
    cartTypeName: string,
    shippingCheckModel: ShippingCheckModel
  ) =>
    request.post<Checkout>(
      `/carts/${cartTypeName}/checkout/shippingcheck`,
      shippingCheckModel
    ),
  prepareCheckout: (cartTypeName: string, checkoutModel: CheckoutModel) =>
    request.post<Checkout>(
      `/carts/${cartTypeName}/checkout/prepare`,
      checkoutModel
    ),
  finalizeCheckout: (cartTypeName: string) =>
    request.post<Order>(`/carts/${cartTypeName}/checkout/complete`)
}

export default checkout
