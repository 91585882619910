import i18n from "i18n"
import { create } from "zustand"
import { combine } from "zustand/middleware"

import { asyncState } from "utils/zustand-async-state"
import { getZustandHelpers } from "utils/zustand-helpers"

import api from "api"
import { AdyenPayRequest, AdyenPayResponse } from "api/adyenPayment"
import { Address, CheckoutModel } from "api/checkout"
import { Order } from "api/orders"
import Checkout, { DeliveryOption, PromoCode } from "models/Checkout"
import { Address as CheckoutAddress } from "models/Checkout"
import { Address as UserAddress, UserRegistrationModel } from "models/User"
import { AdyenCardState } from "pages/Checkout/Adyen/types"
import { CartTypeName } from "./CartStore"
import MarketStore from "./MarketStore"
import PaymentStore from "./PaymentStore"

type PaymentOptionModel = {
  selectedPaymentOption: string
  selectedSubPaymentOption: string
}
const CART_TYPE: CartTypeName = "basket"
const PRESELECTED_PAYMENT_OPTION = "ErpInvoice"

function keyToNameResolver(key: string) {
  switch (key) {
    case "md":
      return "MD"
    default:
      return key[0].toUpperCase() + key.substring(1)
  }
}
const mapAddress = (email: string, address: UserAddress) => {
  return {
    email,
    firstName: address.firstName,
    lastName: address.lastName,
    companyName: address.companyName,
    line1: address.streetAddress,
    daytimePhoneNumber: address.phone,
    eveningPhoneNumber: address.phone,
    city: address.city,
    countryCode: address.countryCode,
    countryName: "",
    zipCode: address.zipCode
  } as Address
}

const initialStates = {
  checkoutError: "",
  selectedShippingAddress: null as null | CheckoutAddress,
  selectedDeliveryOption: "",
  selectedPaymentOption: PRESELECTED_PAYMENT_OPTION,
  selectedSubPaymentOption: "",
  selectedCardPayment: null as null | AdyenCardState,
  isEditingShippingAddress: false,
  loadingShippingCheck: false,
  isDangerousGoodsTerms: false,
  checkout: asyncState<Checkout | null>(null),
  preparedCheckout: asyncState<Checkout | null>(null),
  confirmedOrder: asyncState<Order | null>(null),
  confirmedPayment: asyncState<AdyenPayResponse | null>(null),
  isEditingOrderReference: false,
  orderReference: "",
  promoCode: asyncState<PromoCode | null>(null)
  // TODO: investigate state
  // payment: asyncState<PaymentsReponse>(),
  // paymentMethodsFlags: asyncState<PaymentMethodFlagsResponse>(),
  // paymentResponse: asyncState<PaymentsReponse>(),
}

export const useCheckoutPaymentStore = create(
  combine(initialStates, (set, get) => {
    const { setAsyncState, reset, setters, resetters } = getZustandHelpers(
      set,
      get,
      initialStates
    )

    return {
      set: {
        ...setters([
          "checkoutError",
          "orderReference",
          "isEditingOrderReference",
          "selectedShippingAddress",
          "isDangerousGoodsTerms",
          "selectedCardPayment",
          "isEditingShippingAddress",
          "loadingShippingCheck",
          "selectedDeliveryOption"
        ])
      },
      reset: {
        ...resetters([
          "checkoutError",
          "promoCode",
          "orderReference",
          "selectedShippingAddress",
          "isDangerousGoodsTerms",
          "confirmedOrder",
          "preparedCheckout",
          "checkout"
        ])
      },
      computed: {
        get availableDeliveryOptions(): DeliveryOption[] {
          const states = get()
          if (!states || !states.checkout) {
            return []
          }
          const selectedLanguage =
            MarketStore.state.defaultMarket &&
            MarketStore.state.defaultMarket.selectedLanguage
          if (!states.checkout.data) {
            return []
          }
          return states.checkout.data.availableDeliveryOptions.deliveryOptions.filter(
            (option: DeliveryOption) => {
              return option.languageId === selectedLanguage
            }
          )
        }
      },
      setPaymentOption: (paymentOption: PaymentOptionModel) => {
        set({
          selectedPaymentOption: paymentOption.selectedPaymentOption,
          selectedSubPaymentOption: paymentOption.selectedSubPaymentOption
        })
      },
      addPromoCode: async (couponCode: string) => {
        const result = await setAsyncState(
          "promoCode",
          api.checkout.addPromoCode(CART_TYPE, couponCode)
        )
        return result
      },
      finalizeCheckout() {
        return setAsyncState(
          "confirmedOrder",
          api.checkout.finalizeCheckout(CART_TYPE)
        )
      },
      getConfirmedOrder(orderId: string) {
        return setAsyncState(
          "confirmedOrder",
          api.orders.getOrderByOrderIdentifier(orderId)
        )
      },
      fetchingCheckout() {
        return setAsyncState("checkout", api.checkout.getCheckout(CART_TYPE))
      },
      resetCheckoutStates() {
        reset()
      },

      shippingCheck: async (loggedInUser: UserRegistrationModel | null) => {
        const states = get()
        if (!loggedInUser || !states.selectedShippingAddress) {
          /*
           This scenario should never happen, sice the user is logged in and
           a shipping is always selected when preparing the checkout
          */

          return { ...states }
        }

        const shippingAddress = mapAddress(
          loggedInUser.email,
          states.selectedShippingAddress
        )

        const response = await api.checkout.shippingCheck(CART_TYPE, {
          shippingPostalCode: shippingAddress.zipCode,
          shippingCountryCode: shippingAddress.countryCode
        })

        const newState = { ...states }

        if (newState.checkout.data) {
          newState.checkout.data.availableDeliveryOptions =
            response.availableDeliveryOptions
        }

        if (
          response.availableDeliveryOptions.deliveryOptions.filter(
            (d) => d.methodId === states.selectedDeliveryOption
          ).length === 0
        ) {
          useCheckoutPaymentStore.setState({
            selectedDeliveryOption:
              response.availableDeliveryOptions.deliveryOptions[0].methodId
          })
        }

        useCheckoutPaymentStore.setState({
          loadingShippingCheck: false
        })

        return newState
      },
      finalizePayment: async (payload: string) => {
        const states = useCheckoutPaymentStore.getState()
        const orderGroupId =
          states.preparedCheckout &&
          states.preparedCheckout.data &&
          states.preparedCheckout.data.cart["orderGroupId"]

        const payRequestBody = {
          orderGroupId,
          reference:
            states.preparedCheckout.data &&
            states.preparedCheckout.data.cart.properties[
              "preGeneratedOrderNumber"
            ]
        } as AdyenPayRequest

        if (states.selectedCardPayment) {
          payRequestBody.holderName =
            states.selectedCardPayment.data.paymentMethod.holderName
          payRequestBody.encryptedCardNumber =
            states.selectedCardPayment.data.paymentMethod.encryptedCardNumber
          payRequestBody.encryptedExpiryMonth =
            states.selectedCardPayment.data.paymentMethod.encryptedExpiryMonth
          payRequestBody.encryptedExpiryYear =
            states.selectedCardPayment.data.paymentMethod.encryptedExpiryYear
          payRequestBody.encryptedSecurityCode =
            states.selectedCardPayment.data.paymentMethod.encryptedSecurityCode
        }

        const response = await setAsyncState(
          "confirmedPayment",
          api.adyenPayment.finalize(payload, payRequestBody)
        )

        if (
          response &&
          response.confirmedPayment &&
          response.confirmedPayment.data
        ) {
          switch (response.confirmedPayment.data.resultCode) {
            case "RedirectShopper":
              switch (response.confirmedPayment.data.action.method) {
                case "GET":
                  window.location.href =
                    response.confirmedPayment.data.action.url
                  break
                case "POST":
                  // eslint-disable-next-line no-case-declarations
                  const form = document.createElement("form")
                  // eslint-disable-next-line no-case-declarations
                  const keys = Object.keys(
                    response.confirmedPayment.data.action.data
                  )
                  form.method = response.confirmedPayment.data.action.method
                  form.action = response.confirmedPayment.data.action.url
                  form.id = "hiddenForm"
                  form.style.overflow = "hidden"
                  form.style.height = "0px"
                  form.style.width = "0px"
                  for (const key of keys) {
                    const currentElement = document.createElement("input")
                    currentElement.type = "hidden"
                    currentElement.name = keyToNameResolver(key)
                    currentElement.value =
                      response.confirmedPayment.data.action.data[key]
                    form.appendChild(currentElement)
                  }
                  document.body.appendChild(form)
                  form.submit()
                  break
              }
              break
            case "Authorised":
              states.getConfirmedOrder(
                response.confirmedPayment.data.orderNumber
              )
              break
            default:
              break
          }
        }

        return get()
      },
      prepareCheckout: async (loggedInUser: UserRegistrationModel | null) => {
        const states = useCheckoutPaymentStore.getState()

        if (!loggedInUser || !states.selectedShippingAddress) {
          /*
           This scenario should never happen, sice the user is logged in and
           a shipping is always selected when preparing the checkout
          */

          return { ...states }
        }

        const selectedDelivery =
          states.computed.availableDeliveryOptions &&
          states.computed.availableDeliveryOptions.find((o: DeliveryOption) => {
            return o.methodId === states.selectedDeliveryOption
          })

        const selectedDeliveryName =
          selectedDelivery && selectedDelivery.displayName

        const selectedPayment =
          states.checkout.data &&
          states.checkout.data.paymentOptions.find((paymentOption) => {
            return paymentOption.id === states.selectedPaymentOption
          })

        const validCouponCode =
          states.promoCode.data && states.promoCode.data.couponCode

        let selectedPaymentName = ""
        if (selectedPayment && selectedPayment.id === "Adyen") {
          const sp =
            PaymentStore.state.paymentMethods.data &&
            PaymentStore.state.paymentMethods.data.paymentMethods &&
            PaymentStore.state.paymentMethods.data.paymentMethods.find((p) => {
              return p.type === states.selectedSubPaymentOption
            })

          selectedPaymentName =
            sp && sp.type === "ebanking_FI"
              ? i18n.t("checkout.finnish_ebanking_payment_name")
              : (sp && sp.name) || ""
        } else {
          selectedPaymentName = (selectedPayment && selectedPayment.name) || ""
        }

        const requestModel = {
          billingAddress: mapAddress(
            loggedInUser.email,
            loggedInUser.billingAddress
          ),
          shippingAddress: mapAddress(
            loggedInUser.email,
            states.selectedShippingAddress
          ),
          couponCode: validCouponCode,
          deliveryOptionId: states.selectedDeliveryOption,
          paymentOptionId: states.selectedPaymentOption,
          customerOrderReference: states.orderReference,
          ReadAndUnderstoodDangerousGoodsTerms: states.isDangerousGoodsTerms,
          properties: [
            {
              name: "selectedShippingAddressId",
              value: states.selectedShippingAddress.shippingAddressId
            },
            {
              name: "selectedSubPaymentType",
              value: states.selectedSubPaymentOption
            },
            {
              name: "selectedDeliveryOptionString",
              value: selectedDeliveryName
            },
            { name: "selectedPaymentOptionString", value: selectedPaymentName }
          ]
        } as CheckoutModel

        return setAsyncState(
          "preparedCheckout",
          api.checkout.prepareCheckout(CART_TYPE, requestModel)
        )
      }
    }
  })
)
