import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"

const PromoCodeIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 22l10-10L11 1H4L1 4v7l11 11z"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M7 9a2 2 0 100-4 2 2 0 000 4z"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </SvgIcon>
)

export default PromoCodeIcon
