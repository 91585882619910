export type Spacing = "small" | "medium" | "large"
type SpacingValues = { [key in Spacing]: number }
// TODO: Update those value based on the design
const SPACING_VALUES: SpacingValues = {
  small: 40,
  medium: 80,
  large: 160
}

export const getSpacingValue = (key: Spacing) => SPACING_VALUES[key]
