import request from "@starrepublic/epi/api/request"

import { OrganizationModel } from "models/Organization"

export type contactRole = [string]

export type Role = {
  text: string
  value: string
  selected: boolean
}

export type Roles = [Role]

export type CustomerContactModel = {
  id: string
  applicationUserId?: string
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
  position?: string
  sapCustomerNumber?: string
  vatNumber?: string
  marketId?: string
  preferedLanguage?: string
  isPartner?: boolean
  preferredBillingAddressId?: string
  preferredShippingAddressId?: string
  roles?: Roles
  organization?: OrganizationModel
}

export type AddContactModel = {
  email: string
  firstName: string
  lastName: string
  phone: string
  position: string
  role: string
  organizationId: string
}

export type UpdateContactModel = {
  id: string
  firstName: string
  lastName: string
  phone: string
  position: string
  role: string
}

const contacts = {
  updateUserDetails: (data: CustomerContactModel) =>
    request.put(`/contacts/`, data),
  addContact: (data: AddContactModel) =>
    request.post(`/contacts/`, data).then((response) => {
      console.log({ response })
    }),
  updateContact: (data: UpdateContactModel) =>
    request.put(`/contacts/${data.id}`, data).then((response) => {
      console.log({ response })
    }),
  // getContactRole: () => request.get<contactRole>
  getRoles: () => request.get<Roles>(`/contacts/roles/`),
  getRolesForUser: (contactId: string) =>
    request.get<Roles>(`/contacts/roles/${contactId}`),
  deleteUser: (contactId: string) => request.delete(`/contacts/${contactId}`),
  acceptTerms: () => request.post(`/contacts/accept-terms`)
}

export default contacts
