import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import ContentArea from "@starrepublic/epi/cms/components/ContentArea"
import useStore from "global-hook-store"

import Card from "@material-ui/core/Card"
import DialogContent from "@material-ui/core/DialogContent"
import Fade from "@material-ui/core/Fade"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Modal from "@material-ui/core/Modal"
import { makeStyles } from "@material-ui/core/styles"
import Clear from "@material-ui/icons/Clear"

import Typography from "common/Typography"
import contactModalStore from "store/ContactModalStore"
import SiteSettingStore from "store/SiteSettingStore"
import snackbarStore from "store/SnackbarStore"
import ContactForm from "./ContactForm"

const ContactModal: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { isOpen, result },
    actions: { reset }
  } = useStore(contactModalStore)

  const {
    actions: { openSnackbar }
  } = useStore(snackbarStore)
  const {
    state: { contactForm }
  } = useStore(SiteSettingStore)

  useEffect(
    () => {
      if (!result.loading && result.data) {
        reset()
        openSnackbar({
          options: { message: t("common.contact_me_modal_success_message") },
          type: "success"
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result]
  )
  const handleClose = () => {
    reset()
  }

  return (
    <Modal
      aria-labelledby="contact-me-modal"
      aria-describedby="contact-me-modal"
      open={isOpen}
      onClose={handleClose}
      className={classes.root}
    >
      <Fade in={isOpen}>
        <div>
          <Grid item xs={12} className={classes.modal}>
            <Card className={classes.card}>
              <DialogContent className={classes.dialogue}>
                {!contactForm && (
                  <Typography
                    variant="headline2"
                    color="textPrimary"
                    className={classes.title}
                  >
                    {t("contact.contact_me_header")}
                  </Typography>
                )}
                <IconButton
                  onClick={handleClose}
                  className={classes.closeButton}
                >
                  <Clear className={classes.closeIcon} />
                </IconButton>
                <div>
                  {contactForm ? (
                    <ContentArea content={[contactForm]} />
                  ) : (
                    <ContactForm />
                  )}
                </div>
              </DialogContent>
            </Card>
          </Grid>
        </div>
      </Fade>
    </Modal>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    overflow: "scroll"
  },
  dialogue: {
    outline: 0,
    padding: `20px ${spacing(4)}px ${spacing(4)}px ${spacing(4)}px`,
    [breakpoints.down("sm")]: {
      padding: 0
    }
  },
  modal: {
    position: "absolute",
    top: 80,
    left: "50%",
    transform: "translateX(-50%)",
    maxWidth: 625,
    width: "100%",
    paddingBottom: 80,
    borderRadius: 0
  },
  card: {
    borderRadius: 0
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0
  },
  closeIcon: {
    color: "#000",
    width: spacing(1) * 3.5,
    height: spacing(1) * 3.5
  },
  title: {
    margin: `${spacing(3)}px 0`,
    textAlign: "center"
  }
}))

export default ContactModal
