import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const HomeDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M16 26.666H5.333v-14L16 4.345l10.667 8.323v14H16Zm0 0v-8"
      fill="none"
    />
  </SvgIcon>
)

export default HomeDeLaval
