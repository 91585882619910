import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import Spinner from "common/Spinner"

const MapLoadingSpinner = () => {
  const classes = useStyles()
  return (
    <>
      <Box className={classes.root} />
      <Box className={classes.spinnerWrapper}>
        <Spinner className={classes.spinner} />
      </Box>
    </>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 10,
    backgroundColor: themeColors.lightGray,
    opacity: 0.25
  },
  spinnerWrapper: {
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100
  },
  spinner: {
    boxShadow: "0px 0px 20px rgba(0,0,0,0.2)",
    backgroundColor: themeColors.white,
    boxSizing: "content-box",
    padding: spacing(1),
    borderRadius: spacing(4)
  }
}))

export default MapLoadingSpinner
