import { Link } from "react-router-dom"
import { MenuItem as MenuItemType } from "@starrepublic/epi/cms"
import classNames from "classnames"
import useStore from "global-hook-store"

import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"

import { countObjectDepth } from "utils/hierarchyDepthCounter"

import Typography from "common/Typography"
import { eventHamburgerMenuTracker } from "services/StarGA"
import { useHeaderStore } from "store/HeaderStore"
import marketStore from "store/MarketStore"

type Props = {
  item: MenuItemType
  goToSubMenu: (item: MenuItemType) => void
  closeDrawer: (isOpen: boolean) => void
  noBoldText: boolean
}

const MenuListItem: React.FC<Props> = ({
  item,
  goToSubMenu,
  closeDrawer,
  noBoldText
}) => {
  const classes = useStyles()
  const hasChildren = item.children && item.children.length > 0
  const { menuAncestorsId, menuCurrentId } = useHeaderStore()
  const {
    state: { markets }
  } = useStore(marketStore)

  return (
    <>
      <ListItem
        button
        component="div"
        disableGutters
        className={classNames(classes.menuItem, {
          [classes.menuItemActive]:
            menuAncestorsId.includes(item._id) || menuCurrentId === item._id
        })}
      >
        <Link
          to={item.url}
          onClick={() => {
            closeDrawer(false)
            eventHamburgerMenuTracker(
              item.url,
              countObjectDepth(item),
              item.title
            )
          }}
          className={classes.itemTitleLink}
        >
          <Typography
            component="span"
            variant="link2"
            className={classNames(classes.itemTitle, {
              [classes.noBoldText]: noBoldText
            })}
          >
            {item.title}
          </Typography>
        </Link>
        {hasChildren && (
          <div
            className={classes.itemSubNavigation}
            onClick={() => goToSubMenu(item)}
          >
            <KeyboardArrowRight className={classes.arrowColor} />
          </div>
        )}
      </ListItem>
      <Divider className={classes.divider} />
    </>
  )
}

const useStyles = makeStyles(({ common: { themeColors }, spacing }) => ({
  menuItem: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    height: 56
  },
  menuItemActive: {
    "&::before": {
      content: "'\\0020'",
      borderLeft: `6px solid ${themeColors.white}`,
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0
    }
  },
  itemTitleLink: {
    flexGrow: 1,
    paddingLeft: spacing(4),
    display: "flex",
    height: "100%",
    alignItems: "center"
  },
  itemTitle: {
    color: themeColors.white
  },
  itemSubNavigation: {
    borderLeft: `1px solid ${themeColors.transparent}`,
    padding: spacing(2),
    display: "flex"
  },
  divider: {
    margin: 0,
    backgroundColor: themeColors.transparent
  },
  arrowColor: {
    color: themeColors.white
  },
  noBoldText: {
    fontWeight: 400
  }
}))

export default MenuListItem
