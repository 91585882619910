import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore, { useLocalStore } from "global-hook-store"

import ButtonBase from "@material-ui/core/ButtonBase"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"

import AddToCartCheckIcon from "icons/AddToCartCheckIcon"
import AddToCartIcon from "icons/AddToCartIcon"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import { Price } from "models/Products"
import AddToCartStore from "store/AddToCartStore"
import CartStore from "store/CartStore"
import snackbarStore, { SnackbarModel } from "store/SnackbarStore"

type Props = {
  code: string
  large?: boolean
  mobile?: boolean
  disabled?: boolean
  amount: number
  name: string
  category: string
  image: string
  variationValue: string
  discountedPrice?: Price
  displayPrice: Price
}

const ENTER_KEY_CODE = 13
const CHECKMARK_TIMEOUT = 2000

const AddToCartContainer: React.FC<Props> = ({
  disabled,
  code,
  large,
  amount,
  name,
  category,
  mobile,
  variationValue,
  image,
  discountedPrice,
  displayPrice
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    actions: { addByItemCode }
  } = useStore(CartStore)

  const {
    actions: { openSnackbar }
  } = useStore(snackbarStore)

  const {
    state: { quantity, isLoading },
    actions: { setQuantity, setIsLoading }
  } = useLocalStore(AddToCartStore)

  const textFieldRef = useRef<HTMLInputElement>(null)

  const [successfullyAdded, setSuccessfullyAdded] = useState(false)

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSuccessfullyAdded(false)
    setQuantity(parseInt(e.target.value, 10) || 0)
  }

  const handleAddToCartButton = async () => {
    if (!isLoading) {
      await setSuccessfullyAdded(false)
      await setIsLoading(true)
      await addByItemCode({
        name,
        quantity,
        category,
        amount: amount || 0,
        cartTypeName: "basket",
        itemCode: code
      }).then((state) => {
        if (state.cart.error) {
          openSnackbar({
            options: {
              message: t(`error.${state.cart.error.toString().toLowerCase()}`)
            },
            type: "error"
          })
          setSuccessfullyAdded(false)
        } else if (
          state.cart.data &&
          state.cart.data.validationIssues &&
          state.cart.data.validationIssues.length > 0
        ) {
          const index = state.cart.data!.validationIssues[0].value.split("|")
          const message = index[1]
          const type = index[0].toLowerCase() as SnackbarModel["type"]
          openSnackbar({
            options: {
              message
            },
            type
          })

          setSuccessfullyAdded(false)
        } else {
          setSuccessfullyAdded(true)
          setTimeout(() => {
            setSuccessfullyAdded(false)
          }, CHECKMARK_TIMEOUT)

          const options = {
            code,
            image,
            displayName: name,
            variationValue,
            discountedPrice,
            displayPrice,
            message: t("cart.item_added_to_cart")
          }

          openSnackbar({
            options,
            type: "product"
          })
        }

        setIsLoading(false)
      })
    }
  }

  useEffect(
    () => {
      const { current } = textFieldRef
      if (current) {
        current.addEventListener("keyup", ({ keyCode }) => {
          if (keyCode === ENTER_KEY_CODE) {
            handleAddToCartButton()
          }
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className={classes.root}>
      {!disabled ? (
        <Hidden smDown>
          <TextField
            variant="outlined"
            onChange={handleQuantityChange}
            onClick={(e) => {
              e.stopPropagation()
            }}
            inputRef={textFieldRef}
            InputProps={{
              classes: {
                root: classes.quantityInput,
                notchedOutline: classes.border,
                input: "add-to-cart-quantity-input"
              }
            }}
            inputProps={{
              style: {
                textAlign: "center",
                padding: 0,
                height: "100%",
                fontSize: 14
              },
              min: 0
            }}
            className={classNames(classes.quantity, {
              [classes.bigQuantity]: large,
              [classes.mobile]: mobile
            })}
            value={quantity}
          />
        </Hidden>
      ) : null}
      <ButtonBase
        onClick={(e) => {
          e.stopPropagation()
          handleAddToCartButton()
        }}
        disabled={disabled}
        className={classNames(
          "add-to-cart-button",
          classes.button,
          disabled ? classes.disabledButton : "",
          {
            [classes.bigButton]: large,
            [classes.mobile]: mobile
          }
        )}
      >
        {
          <div
            className={classNames(classes.buttonText, {
              [classes.buttonTextMobile]: mobile
            })}
          >
            {isLoading ? (
              <ButtonLoaderIcon
                className={classNames({
                  [classes.iconMargin]: large
                })}
              />
            ) : successfullyAdded ? (
              <AddToCartCheckIcon
                className={classNames({
                  [classes.iconMargin]: large
                })}
              />
            ) : (
              <>
                <AddToCartIcon
                  className={classNames(
                    disabled ? classes.disabledButton : "",
                    {
                      [classes.iconMargin]: large
                    }
                  )}
                />
              </>
            )}
            {large ? (
              <span className={classes.buttonInnerText}>
                {t("product.add_to_basket")}
              </span>
            ) : null}
          </div>
        }
      </ButtonBase>
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors }, breakpoints }) => ({
    root: {
      display: "flex",
      justifyContent: "flex-end",
      [breakpoints.down("md")]: {
        width: "100%"
      },
      "& div > fieldset": {
        borderBottom: 0,
        borderRight: 0,
        borderColor: themeColors.whiteGray
      },
      "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
          border: "1px solid",
          borderColor: themeColors.darkBlue
        },
        "&.Mui-focused fieldset": {
          borderColor: themeColors.darkBlue,
          border: "2px solid"
        }
      }
    },
    border: {
      borderRadius: 0
    },
    quantity: {
      width: 56,
      height: 56,
      borderRadius: 0,
      background: themeColors.white
    },
    bigQuantity: {
      width: 56,
      height: 56,
      borderBottom: `1px solid ${themeColors.whiteGray}`
    },
    quantityInput: {
      color: themeColors.darkGray,
      height: "100%"
    },
    button: {
      cursor: "pointer",
      color: themeColors.white,
      background: themeColors.primaryCyan,
      borderRadius: 0,
      height: 56,
      minWidth: 56
    },
    bigButton: {
      height: 56,
      cursor: "pointer"
    },
    buttonText: {
      fontSize: 14,
      fontWeight: 700,
      padding: "8px 12px 8px 12px",
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center"
    },
    buttonInnerText: {
      whiteSpace: "nowrap",
      marginRight: spacing(1)
    },
    iconMargin: {
      width: 32,
      marginRight: 12
    },
    disabledButton: {
      background: themeColors.lightGray,
      cursor: "default !important",
      color: themeColors.mediumGray
    },
    mobile: {
      width: 48,
      height: 48,
      minWidth: 48
    },
    buttonTextMobile: {
      padding: spacing(1)
    }
  })
)

export default AddToCartContainer
