import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import Spinner from "common/Spinner"
import authStore from "store/AuthStore"
import DrawerStateStore from "store/DrawerStateStore"

const DisplayLoginButton: React.FC<{}> = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { user }
  } = useStore(authStore)

  const {
    state: { isMyAccountOpen },
    actions: { setAccountDrawer }
  } = useStore(DrawerStateStore)

  const [displayLoginAction, setDisplayLoginAction] = useState(false)

  useEffect(
    () => {
      user.data ? setDisplayLoginAction(false) : setAccountDrawer(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.data]
  )

  useEffect(
    () => {
      !user.data && !isMyAccountOpen
        ? setDisplayLoginAction(true)
        : setDisplayLoginAction(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMyAccountOpen]
  )

  return (
    <>
      {displayLoginAction ? (
        <>
          <Grid item xs={12} className={classes.loginPrompt}>
            <Typography variant="body1" className={classes.loginText}>
              {t("account.login_to_view_account")}
            </Typography>
            <Button
              className={classes.loginButton}
              onClick={() => setAccountDrawer(true)}
            >
              <Typography component="span" variant="button" color="inherit">
                {t("account.login_button")}
              </Typography>
            </Button>
          </Grid>
        </>
      ) : (
        !user.data && (
          <Grid item xs={12}>
            <Spinner className={classes.spinner} />
          </Grid>
        )
      )}
    </>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  spinner: {
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    marginTop: spacing(5)
  },
  loginPrompt: {
    marginTop: spacing(5),
    marginBottom: spacing(5)
  },
  loginText: {
    textAlign: "center",
    paddingBottom: 20
  },
  loginButton: {
    margin: "0 auto",
    padding: 14,
    width: 140,
    display: "flex",
    justifyContent: "center",
    borderRadius: 3
  },
  breadCrumbs: {
    borderBottom: `1px solid ${themeColors.whiteGray}`
  }
}))

export default DisplayLoginButton
