import { memo, useEffect } from "react"
import { PageInfo } from "@starrepublic/epi/cms"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"

import BreadcrumbsBar from "common/BreadcrumbsBar"
import Wrapper from "common/Wrapper"
import { WebNodeContent } from "pages/WebNode/types"
import ProductListingStore from "store/ProductListingStore"
import LoadMoreButton from "./LoadMoreButton"
import { ProductMenu } from "./ProductMenu"
import ProductQuickViewModal from "./ProductQuickViewModal"
import ProductTable from "./ProductTable"

type Props = {
  content: WebNodeContent
  pageInfo: PageInfo
}

const WebNodeListing = ({ pageInfo, content }: Props) => {
  const classes = useStyles()

  const {
    state: {
      products,
      searchedProductList,
      filteredProducts,
      filterQuery,
      productListRoute
    },
    actions: {
      loadProductList,
      addProducts,
      setProductListRoute,
      resetProductList,
      setNetPriceArticleList
    }
  } = useStore(ProductListingStore)

  useEffect(
    () => {
      if (productListRoute !== pageInfo._route) {
        resetProductList().then(() => {
          setProductListRoute(pageInfo._route).then(() => {
            loadProductList().then(() => {
              addProducts().then(() => {
                setNetPriceArticleList()
              })
            })
          })
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageInfo._route]
  )

  return (
    <Wrapper className={classes.wrapper}>
      <BreadcrumbsBar />
      <Divider />
      <Box pt={3}>
        <Grid className={classes.menuRoot} justifyContent="center" container>
          <Hidden smDown>
            <Grid md={4} lg={3} className={classes.menuWrapper} item>
              <ProductMenu />
            </Grid>
          </Hidden>
          <Grid
            className={classes.productTableContainer}
            md={8}
            lg={9}
            xs={12}
            item
          >
            <ProductTable
              pageInfo={pageInfo}
              content={content}
              products={
                filterQuery.length > 0 && searchedProductList.data
                  ? filteredProducts
                  : products
              }
            />
            <LoadMoreButton route={pageInfo._route} />
          </Grid>

          <ProductQuickViewModal />
        </Grid>
      </Box>
    </Wrapper>
  )
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  menuWrapper: {
    "& > nav": {
      paddingTop: spacing(2)
    }
  },
  menuRoot: {
    flexWrap: "nowrap"
  },
  productTableContainer: {
    [breakpoints.down("sm")]: {
      overflowX: "auto"
    }
  },
  wrapper: {
    [breakpoints.down("sm")]: {
      padding: 0
    }
  }
}))

export default memo(WebNodeListing, (prevProps, nextProps) => {
  return (
    (prevProps.pageInfo && prevProps.pageInfo._route) ===
    (nextProps.pageInfo && nextProps.pageInfo._route)
  )
})
