import { useEffect } from "react"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import MenuItem from "@material-ui/core/MenuItem"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"

import variationComparator from "utils/variationComparator"

import ProductStore from "store/ProductStore"
import { themeColors } from "theme"
import VariantItem from "./VariantItem"

type VariantPickerProps = {
  productName?: string
  route?: string
  defaultVariation: string
}

const VariantDropDownPicker: React.FC<VariantPickerProps> = ({
  defaultVariation
}) => {
  const classes = useStyles()

  const {
    state: {
      selectedVariant: { _id: selectedVariantId },
      productVariations
    },
    actions: { changeVariation }
  } = useStore(ProductStore)

  const handleVariantChange = (e: React.ChangeEvent<{ value: number }>) => {
    const value = e.target.value
    changeVariation(value)
  }

  const setDefaultItem = (defaultValue: string) => {
    if (productVariations && defaultValue) {
      const getDefaultItem = productVariations.find((x) => {
        return x.code === defaultValue
      })
      if (getDefaultItem) {
        changeVariation(getDefaultItem._id)
      }
    } else {
      return selectedVariantId
    }
  }

  useEffect(
    () => {
      window.location.hash
        ? setDefaultItem(window.location.hash.substring(1))
        : setDefaultItem(defaultVariation)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.hash]
  )

  const sortedVariations = productVariations.sort(variationComparator)

  return (
    <Box width={1} className={classes.variantDropDown}>
      <Select
        fullWidth
        id="variant-drop-down-field"
        labelWidth={0}
        disabled={false}
        defaultValue=""
        className={classes.menuItem}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
            }
          }
        }}
        value={selectedVariantId}
        onChange={handleVariantChange}
        input={
          <OutlinedInput
            labelWidth={0}
            style={{
              marginRight: 7,
              width: "100%",
              backgroundColor: themeColors.white
            }}
          />
        }
      >
        {sortedVariations.map((variant, key) => {
          return (
            variant._id && (
              <MenuItem key={key} value={variant._id}>
                <Box
                  py={1}
                  display="flex"
                  justifyContent="space-between"
                  width={1}
                >
                  <VariantItem
                    id={variant._id}
                    price={variant._displayPrice}
                    discountedPrice={variant._discountedPrice}
                    variantName={variant.variationValue}
                    stockStatus={variant.inventoryStatus}
                    isDigitalService={variant.isDigitalService}
                    variationPricePerX={variant.variationPricePerX}
                    variationDiscountedPricePerX={
                      variant.variationDiscountedPricePerX
                    }
                    variationPricePerXUnitType={
                      variant.variationPricePerXUnitType
                    }
                  />
                </Box>
              </MenuItem>
            )
          )
        })}
      </Select>
    </Box>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  variantDropDown: {
    "& .MuiOutlinedInput-input": {
      paddingTop: spacing(1.75),
      paddingBottom: spacing(1.75),
      "& #variantPriceItem": {
        display: "none"
      }
    },
    "& > div > svg": {
      marginRight: spacing(1.5)
    }
  },
  menuItem: {
    "& > div > div": {
      paddingTop: 0,
      paddingBottom: 0
    }
  }
}))

export default VariantDropDownPicker
