import qs from "qs"
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"

import { ApiError } from "../api"
import { TokenResponse } from "../api/users"
import EpiConfig from "../config"
import jwt from "../services/jwt"
import { isDetached, isLocalHost } from "../utils/location"

interface AxiosApiError extends AxiosError {
  response: AxiosResponse<ApiError>
}

const request = axios.create({
  baseURL:
    isLocalHost() || isDetached() ? `${EpiConfig.apiRoot}/api` || "" : "/api",
  withCredentials: true
})

export const requestWithoutTokenUpdate = axios.create({
  baseURL:
    isLocalHost() || isDetached() ? `${EpiConfig.apiRoot}/api` || "" : "/api",
  withCredentials: true
})

const requestInterceptor = async (
  useTokenUpdate: boolean,
  config: AxiosRequestConfig
) => {
  config.baseURL = config.baseURL || EpiConfig.apiRoot

  if (
    config.data &&
    config.headers &&
    config.headers["Content-Type"] === "application/x-www-form-urlencoded"
  ) {
    config.data = qs.stringify(config.data)
  }

  if (jwt.token) {
    if (useTokenUpdate && Date.now() > jwt.tokenExpires) {
      await jwt.updateToken()
    }
    if (config.headers) {
      config.headers.Authorization = `Bearer ${jwt.token}`
    }
  }

  const { EPI_SITE_DATA } = window
  const lang = EPI_SITE_DATA && EPI_SITE_DATA["_lang"]
  if (lang && config.headers) {
    config.headers["Accept-Language"] = lang
  }

  if (config.headers) {
    config.headers["X-Requested-With"] = "XMLHttpRequest" // Detecting XMLHttprequest from javascript, default disabled in axios
  }

  return config
}

requestWithoutTokenUpdate.interceptors.request.use((config) => {
  return requestInterceptor(false, config)
})

request.interceptors.request.use((config) => {
  return requestInterceptor(true, config)
})

const responseInterceptorResponse = (response: AxiosResponse<any>) => {
  if (isTokenResponse(response.data)) {
    jwt.token = response.data.access_token
    jwt.tokenExpires = response.data.expires_in
    jwt.refreshToken = response.data.refresh_token
  }
  return response.data
}

const responseInterceptorError = async (error: any) => {
  if (axios.isCancel(error)) {
    return Promise.reject(error)
  }

  if (!isApiError(error)) {
    return Promise.reject(
      EpiConfig.alwaysReturnApiErrors === "true"
        ? error.response.data
        : "NETWORK_ERROR"
    )
  }

  if (isExpiredRefreshTokenError(error)) {
    jwt.clear() // We clear our tokens
    location.reload() // And reload the page to make sure user is logged out
    return Promise.reject(error.response.data.code)
  }

  if (isExpiredTokenError(error) && !error.config["isRetryRequest"]) {
    if (!jwt.refreshToken) {
      return Promise.reject(error.response.data.code)
    }

    await jwt.updateToken()

    error.config["isRetryRequest"] = true
    return request(error.config)
  }
  return Promise.reject(error.response.data.code)
}

request.interceptors.response.use(
  (response) => {
    return responseInterceptorResponse(response)
  },
  async (error) => {
    return await responseInterceptorError(error)
  }
)

requestWithoutTokenUpdate.interceptors.response.use(
  (response) => {
    return responseInterceptorResponse(response)
  },
  async (error) => {
    return await responseInterceptorError(error)
  }
)

const isExpiredRefreshTokenError = (error: AxiosApiError) =>
  error.config &&
  error.response.status === 400 &&
  (error.response.data.code === "TOKEN_EXPIRED" ||
    error.response.data.code === "INVALID_TOKEN")

const isTokenResponse = (arg: any): arg is TokenResponse =>
  arg.access_token !== undefined && arg.refresh_token !== undefined

const isApiError = (error: any): error is AxiosApiError =>
  error.response &&
  error.response.data &&
  error.response.data.code !== undefined

const isExpiredTokenError = (error: AxiosApiError) =>
  error.config &&
  ((error.response.status === 401 &&
    error.response.data.code === "NOT_AUTHORIZED") ||
    (error.response.status === 400 &&
      error.response.data.code === "TOKEN_EXPIRED"))

export default request
