import {
  BlockProps,
  ContentInfo,
  editorAttributes
} from "@starrepublic/epi/cms"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { blockDisplay } from "utils/blockDisplay"

import AccordionItem from "common/AccordionItem"
import ResponsiveImage from "common/ResponsiveImage"
import Typography from "common/Typography"
import { ImageType } from "types/content/media"

type Item = {
  heading: string
  text: string
  link: string
  readMoreUrl: string
}

type AccordionBlockContent = {
  items: Item[]
  heading: string
  image: ImageType
  theme: string
} & ContentInfo

type Props = BlockProps<AccordionBlockContent>

const dark = "#103D82"

const AccordionBlock: React.FC<Props> = ({ content, blockInfo }) => {
  const classes = useStyles()
  const { items, heading, image, theme } = content

  const isXsDown = useMediaQuery(({ breakpoints }) => breakpoints.down("xs"))
  const _display = blockDisplay(blockInfo)

  return (
    <Box
      className={classes.container}
      bgcolor={theme}
      py={
        !theme || theme !== "#FFFFFF"
          ? {
              xs: 2,
              sm: _display.auto || _display.full ? 3 : 3,
              md: _display.auto || _display.full ? 6 : 3
            }
          : {}
      }
      px={{
        xs: 2,
        sm: _display.half ? 4 : _display.oneThird ? 3 : 3,
        md: _display.half ? 4 : _display.oneThird ? 3 : 6
      }}
    >
      {heading && (
        <Box
          pb={{ xs: 2.5, md: _display.half ? 4 : _display.oneThird ? 3 : 3 }}
        >
          <Typography
            className={classNames({
              [classes.lightText]: theme === dark
            })}
            align="center"
            variant="headline3"
          >
            {heading}
          </Typography>
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="center"
        flexDirection={{
          xs: "column-reverse",
          sm:
            _display.half || _display.oneThird || _display.twoThird
              ? "column-reverse"
              : "row"
        }}
      >
        <Grid
          item
          xs={12}
          sm={_display.auto || _display.full ? 5 : 12}
          md={
            image
              ? _display.half || _display.oneThird || _display.twoThird
                ? 12
                : 4
              : 12
          }
          {...editorAttributes(blockInfo)}
          className={classNames(classes.accordionRoot, {
            [classes.lightBorder]: theme === dark,
            [classes.accordionBorders]:
              _display.half ||
              _display.oneThird ||
              _display.twoThird ||
              isXsDown
          })}
        >
          <Box
            className={classNames({
              [classes.accordionContainer]: !image,
              [classes.withImage]: image,
              [classes.shortContainer]:
                _display.half || _display.oneThird || _display.twoThird
            })}
          >
            {items &&
              items.map((item, i) => (
                <AccordionItem theme={theme} key={i} {...item} />
              ))}
          </Box>
        </Grid>
        {image && (
          <Grid
            item
            xs={12}
            sm={_display.auto || _display.full ? 7 : 12}
            md={
              _display.half || _display.oneThird || _display.twoThird ? 12 : 8
            }
          >
            <ResponsiveImage
              className={classNames(classes.media)}
              containerProps={{
                className:
                  _display.half || _display.oneThird || _display.twoThird
                    ? classes.mediaContainer
                    : classes.mediaContainerBig
              }}
              image={image}
            />
          </Grid>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(
  ({
    breakpoints,
    spacing,
    common: { themeColors, aspectRatio16x9, aspectRatio4x3 }
  }) => ({
    accordionRoot: {
      "& > div:last-child": {
        borderBottom: `1px solid ${themeColors.grayBorder}`
      }
    },
    container: {
      [breakpoints.down("xs")]: {
        "& h3": {
          padding: `0px ${spacing(1)}px`
        }
      }
    },
    lightBorder: {
      "& > div:last-child": {
        borderBottom: `1px solid ${themeColors.lightBorder}`
      }
    },
    accordionBorders: {
      "& > div:last-child": {
        borderBottom: "none"
      },
      "& .MuiAccordion-rounded:first-child > div": {
        borderTop: "none"
      }
    },
    media: {
      width: "100%",
      height: "100%",
      objectFit: "cover"
    },
    mediaContainer: {
      display: "block",
      aspectRatio: aspectRatio16x9,
      paddingBottom: spacing(2),
      [breakpoints.down("sm")]: {
        aspectRatio: aspectRatio4x3
      }
    },
    mediaContainerBig: {
      aspectRatio: aspectRatio4x3,
      [breakpoints.down("sm")]: {
        paddingBottom: spacing(2)
      }
    },
    lightText: {
      color: themeColors.white
    },
    accordionContainer: {
      display: "flex",
      maxWidth: 624,
      flexDirection: "column",
      margin: "0 auto"
    },
    withImage: {
      marginRight: spacing(6),
      [breakpoints.down("sm")]: {
        marginRight: spacing(3)
      },
      [breakpoints.down("xs")]: {
        margin: "0 auto"
      }
    },
    shortContainer: {
      marginRight: spacing(0)
    }
  })
)

export default AccordionBlock
