import { ContentProps } from "@starrepublic/epi/cms/"
import DisplayOptionsContentArea from "@starrepublic/epi/cms/components/DisplayOptionsContentArea"

import DisplayOptions from "utils/ui/DisplayOptions"

import BreadcrumbsBar from "common/BreadcrumbsBar"
import {
  ChildContainer,
  ContainerWrapper,
  SectionComponent
} from "common/contentAreaContainers"
import ProductDetails from "./ProductDetails"
import { WebProductContent } from "./types"

type Props = ContentProps<WebProductContent>

const WebProduct: React.FC<Props> = ({ content, propsInfo, pageInfo }) => (
  <>
    <BreadcrumbsBar />
    <ProductDetails
      content={content}
      pageId={pageInfo!._id}
      pageRoute={pageInfo ? pageInfo._route : "/"}
    />
    <DisplayOptionsContentArea
      content={content.productContentArea}
      propInfo={propsInfo.productContentArea}
      component={SectionComponent}
      pageInfo={pageInfo}
      containWhen={({ _display: display, _type: type }) =>
        (type &&
          type !== "SectionBlock" &&
          display &&
          (display === "auto" || DisplayOptions[display] !== 1)) ||
        false
      }
      childContainer={ChildContainer}
      container={ContainerWrapper}
    />
  </>
)

export default WebProduct
