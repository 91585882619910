import { AsyncState, asyncState, createStore } from "global-hook-store"

import usersApi from "api/users"
import { UserRegistrationModel } from "models/User"
import authStore from "store/AuthStore"

export enum FormType {
  "login",
  "register",
  "forgotPassword"
}

type AccountStoreType = {
  formComponent: FormType
  updatedCustomer: AsyncState<UserRegistrationModel>
  accountDetailsMissing: boolean
}

const accountStore = createStore(
  {
    formComponent: FormType.login,
    isDialogOpen: false,
    updatedCustomer: asyncState<UserRegistrationModel>(),
    get accountDetailsMissing() {
      if (
        authStore.state.user.data &&
        authStore.state.user.data.isPartnerUser
      ) {
        return false
      }

      const userHasVatNumber =
        authStore.state.user.data && authStore.state.user.data.vatNumber
          ? true
          : false

      return !userHasVatNumber
    }
  } as AccountStoreType,
  {
    setFormComponent: (currentState, formComponent: FormType) => ({
      ...currentState,
      formComponent
    }),
    updateCustomer: (_, vatNumber: string, { asyncAction }) => {
      const sapCustomerNumber =
        (authStore.state.user.data &&
          authStore.state.user.data.sapCustomerNumber) ||
        ""
      return asyncAction(
        "updatedCustomer",
        usersApi.updateCustomer(sapCustomerNumber, vatNumber)
      )
    }
  }
)

export default accountStore
