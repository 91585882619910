import i18next from "i18next"
import * as yup from "yup"

export interface LoginFormType {
  userName: string
  password: string
}

const validationSchema = (t: i18next.TranslationFunction) => {
  return yup.object<LoginFormType>().shape({
    userName: yup
      .string()
      .required(t("checkout.email_required_message"))
      .email(t("checkout.email_invalid_message")),
    password: yup.string().required(t("checkout.password_required_message"))
  })
}

export default validationSchema
