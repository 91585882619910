import { FC, memo } from "react"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"

export type ProductBadgeProps = {
  className?: string
  label: string
  discount?: string
  color?: "primary" | "alt"
  isLarge?: boolean
}

const ProductBadge: FC<ProductBadgeProps> = ({
  className,
  label,
  discount,
  color = "primary",
  isLarge
}) => {
  const classes = useStyles()
  const wrapperClass = discount
    ? isLarge
      ? classes.roundedLarge
      : classes.rounded
    : classes.basic

  return (
    <div
      className={classNames(classes.root, wrapperClass, className, {
        [classes.alt]: color === "alt"
      })}
    >
      <div className={classes.content}>
        {discount && (
          <Typography
            variant="body2"
            className={classNames(classes.label, {
              [classes.labelLarge]: isLarge
            })}
          >
            -{discount}
          </Typography>
        )}
        {!discount && (
          <Typography variant="body2" className={classes.label}>
            {label}
          </Typography>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    backgroundColor: themeColors.colorBaseCyan900,
    borderRadius: spacing(10),
    display: "inline-flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    textAlign: "center"
  },
  alt: {
    backgroundColor: themeColors.colorBaseRed600
  },
  basic: {
    padding: spacing(0, 2),
    height: spacing(4)
  },
  rounded: {
    minWidth: 60,
    minHeight: 60,
    "& $label": {
      padding: spacing(0, 1)
    }
  },
  roundedLarge: {
    minWidth: 100,
    minHeight: 100,
    "& $label": {
      padding: spacing(0, 1)
    }
  },
  label: {
    color: themeColors.white,
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: 16
  },
  labelLarge: {
    fontSize: 24
  }
}))

export default memo(ProductBadge)
