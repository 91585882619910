import { SiteSettings } from "../cms/types/content"

import { getCached } from "./utils"

const site = {
  get: <T>() =>
    getCached<SiteSettings<T>>("/site", "EPI_SITE_DATA", () => "/api/site")
}

export default site
