import request from "@starrepublic/epi/api/request"

import Subscription, { Farm } from "models/DigitalService"

export type ActivateSubscriptionPayload = {
  subscriptionId: string
  articleId: string
  farm: Farm
}

export type ActivateSubscriptionResponse = {
  status: string
}

const subscriptions = {
  getFarms: () => request.get<Farm[]>(`/farms/`),
  getSubscriptions: () => request.get<Subscription[]>(`/subscriptions/`),
  activateSubscription: (payload: ActivateSubscriptionPayload) =>
    request.post<ActivateSubscriptionResponse>(`/subscriptions/`, payload)
}

export default subscriptions
