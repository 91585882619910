import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const LocationIcon: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 14 21">
    <svg width="14" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 .857c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7Zm0 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
)

export default LocationIcon
