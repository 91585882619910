import { asyncState, createStore } from "global-hook-store"

const ImpersonationStore = createStore(
  {
    isOpen: false,
    result: asyncState<string>()
  },
  {
    open: (state, _: null) => {
      return { ...state, isOpen: true }
    },
    close: (state, __: null) => {
      return { ...state, isOpen: false }
    },
    reset: (_, __: null, { reset }) => {
      return reset()
    }
  }
)
export default ImpersonationStore
