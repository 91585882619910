import * as React from "react"

import {
  AdditionalBlockInfo,
  ContentReferenceProps,
  DynamicBlock
} from "../types/content"
import editor from "../utils/editorAttributes"

import BlockResolver from "./BlockResolver"
import { GenericRenderer } from "./utils"

type ContentReferenceProperty = DynamicBlock

type Props = {
  content?: ContentReferenceProperty
} & ContentReferenceProps &
  AdditionalBlockInfo

const ContentReference: React.FunctionComponent<Props> = ({
  content,
  pageInfo,
  propInfo,
  component: Component,
  getAdditionalBlockInfo
}) => (
  <GenericRenderer {...editor(propInfo)} component={Component}>
    {content && (
      <BlockResolver
        content={content}
        pageInfo={pageInfo}
        getAdditionalBlockInfo={getAdditionalBlockInfo}
      />
    )}
  </GenericRenderer>
)

export type { ContentReferenceProperty }
export default ContentReference
