import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const InStock: React.FC<Props> = (props) => (
  <SvgIcon width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <rect width="12" height="12" rx="2" fill="currentColor" />
  </SvgIcon>
)

export default InStock
