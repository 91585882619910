import classNames from "classnames"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { isIE } from "utils/UserAgent"

import BlockGrid from "common/BlockGrid"
import HtmlContent from "common/HtmlContent"
import ResponsiveImage from "common/ResponsiveImage"
import { ErrorPageContent } from "."

type CurrentStyle = {
  height: number | string
  backgroundImage: string
}

type ErrorPageComponentProps = {
  content: ErrorPageContent
}

const ErrorPageComponent: React.FC<ErrorPageComponentProps> = ({ content }) => {
  const { image } = content

  const classes = useStyles()
  const isMdUp = useMediaQuery(({ breakpoints }) => breakpoints.up("md"))
  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))

  const heightFactor = 1.33

  let currentStyle = {
    height: isSmUp ? heightFactor && 8 * 66 * heightFactor! : "75vh"
  } as CurrentStyle

  const isIe = isIE()

  if (isIe) {
    currentStyle = {
      height: isSmUp ? heightFactor && 8 * 66 * heightFactor! : "100vh",
      backgroundImage: image ? `url(${image._url})` : ""
    }
  }

  return (
    <>
      <div
        className={classNames(classes.backgroundColorBlock, {
          [classes.fullBackgroundColor]: !isMdUp
        })}
      />
      <div style={currentStyle} className={classes.root}>
        <div className={classes.backgroundColorWrapper} />
        {image && !isIe && (
          <ResponsiveImage
            className={classes.media}
            containerProps={{
              className: classes.mediaContainerLeftFullWidth
            }}
            image={image}
          />
        )}
        <BlockGrid
          container
          direction="column"
          justifyContent={"center"}
          className={classes.bodyContentContainerRight}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} lg={8} className={classes.errorWrapper}>
              <div className={classes.messageWrapper}>
                <Typography variant="body1" component={"div"}>
                  <HtmlContent
                    content={content.notFoundMessage || content.errorMessage}
                  />
                </Typography>
              </div>
            </Grid>
          </Grid>
        </BlockGrid>
      </div>
    </>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    root: {
      display: "block",
      position: "relative",
      overflow: "hidden",
      [breakpoints.down("xs")]: {
        marginRight: 0,
        marginLeft: 0
      },
      [breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        marginLeft: "calc(50% - 50vw)"
      },
      WebkitTapHighlightColor: "transparent",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat"
    },
    backgroundColorWrapper: {
      position: "absolute",
      backgroundColor: themeColors.offWhite
    },
    backgroundColorBlock: {
      width: "100%",
      height: "100%",
      position: "absolute"
    },
    mediaContainerLeftFullWidth: {
      right: 0,
      width: "100%",
      height: "100%"
    },
    media: {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover"
    },
    bodyContentContainerRight: {
      margin: spacing(0, 6),
      width: "50%",
      height: "100%",
      padding: 0,
      right: 0,
      top: 0,
      position: "absolute",
      [breakpoints.down("xs")]: {
        width: `calc(100% - ${spacing(3)}px)`,
        margin: spacing(0, 1.5)
      }
    },
    fullBackgroundColor: {
      width: "100vw",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      marginLeft: "calc(50% - 50vw)"
    },
    errorWrapper: {
      marginTop: 0,
      backgroundColor: themeColors.white,
      padding: spacing(2, 3),
      display: "flex",
      maxWidth: 500,
      alignItems: "flex-start",
      border: `1px solid ${themeColors.colorBaseGray400}`,
      [breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    messageWrapper: {
      marginTop: spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      "& h1, h2, h3": {
        color: themeColors.primaryBlue
      }
    }
  })
)

export default ErrorPageComponent
