type Props = {
  width?: number | string
  height?: number | string
}

const SummaryCow: React.FC<Props> = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M9 9H10"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M14 9H15"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M7 1V4"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M17 1V4"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M18.9752 13.2639L18.0001 4H6.00006L5.0249 13.2639"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M6 4H1C1 6.20911 2.79083 8 5 8H5.57898"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M18 4H23C23 6.20911 21.2092 8 19 8H18.421"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M9 19C9.55228 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18C8 18.5523 8.44772 19 9 19Z"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M15 19C15.5523 19 16 18.5523 16 18C16 17.4477 15.5523 17 15 17C14.4477 17 14 17.4477 14 18C14 18.5523 14.4477 19 15 19Z"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M18 13H6C4.89543 13 4 13.8954 4 15V18C4 20.7614 6.23858 23 9 23H15C17.7614 23 20 20.7614 20 18V15C20 13.8954 19.1046 13 18 13Z"
      stroke="#103D82"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export default SummaryCow
