import { Children, cloneElement } from "react"
import { FastField } from "formik"

import RadioGroup, { RadioGroupProps } from "@material-ui/core/RadioGroup"

import {
  FormikFieldProps,
  FormikTransformer,
  FormikTransformerProps
} from "./types"

type Props = {
  name: string
  disabled?: boolean
} & RadioGroupProps

const transformer: FormikTransformer<RadioGroupProps> = ({
  field,
  form,
  transformValue,
  ...props
}) => {
  const { name, value: formikValue } = field
  const { setFieldValue } = form

  return {
    ...props,
    ...field,
    onChange: (event, value) => {
      setFieldValue(name, value)
    },
    value: field.value || ""
  }
}

const RadioGroupComponent: React.FC<FormikTransformerProps<Props>> = ({
  row,
  children,
  ...props
}) => {
  return (
    <RadioGroup row {...transformer(props)}>
      {Children.map(children, (child: React.ReactElement<any>) =>
        cloneElement(child, {
          disabled: props.form.isSubmitting || props.disabled
        })
      )}
    </RadioGroup>
  )
}

const FormikRadioGroup: React.FC<FormikFieldProps<any>> = (props) => {
  return <FastField component={RadioGroupComponent} {...props} />
}

export default FormikRadioGroup
