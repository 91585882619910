import { Suspense, useEffect } from "react"

import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import FenceIconSkeleton from "common/Skeletons/FenceIconSkeleton"
import TextField from "common/TextField"
import Typography from "common/Typography"
import FencePostsDistanceIcon from "icons/FenceCalculator/FencePostsDistanceIcon"
import WoodenPost from "icons/FenceCalculator/WoodenPost"
import { useFenceCalculatorStore } from "store/FenceCalculatorStore"
import { themeColors } from "theme"
import ProductsContainer from "./ProductsContainer"
import { FenceCalculatorBlockProps, FenceProductVariant } from "./types"
import { deselectProduct, getAllProducts, getSelectedProduct } from "./utils"

type FencePostsProps = {
  content: FenceCalculatorBlockProps
}

const FencePosts = ({ content }: FencePostsProps) => {
  const classes = useStyles()
  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))
  const { postProducts, insulatorProducts, currencySymbol } = content

  const {
    lengthOfFence,
    distanceBetweenPosts,
    setDistanceBetweenPosts,
    selectedCategory,
    categories,
    numberOfPosts,
    selectedPost,
    setSelectedPost,
    selectedInsulator,
    insulatorPackagesNeeded,
    fenceCalculatorLabels,
    setSelectedInsulator,
    setNumberOfPosts,
    selectedAnimal
  } = useFenceCalculatorStore()

  const allInsulatorProducts: FenceProductVariant[] = getAllProducts(
    insulatorProducts,
    [selectedAnimal, selectedCategory].concat()
  )

  const allPostProducts: FenceProductVariant[] = getAllProducts(
    postProducts,
    [selectedAnimal, selectedCategory].concat()
  )

  const isTemporarySelected = selectedCategory === categories[1].category

  const selectProductPost = (articleNumber: string) => {
    const product = getSelectedProduct(allPostProducts, articleNumber)
    if (!product || deselectProduct(selectedPost, product)) {
      return setSelectedPost({} as FenceProductVariant)
    }
    setSelectedPost(product)
  }
  const selectProductInsulator = (articleNumber: string) => {
    const product = getSelectedProduct(allInsulatorProducts, articleNumber)
    if (!product || deselectProduct(selectedInsulator, product)) {
      return setSelectedInsulator({} as FenceProductVariant)
    }
    setSelectedInsulator(product)
  }

  useEffect(() => {
    const numberOfPosts = lengthOfFence / distanceBetweenPosts
    setNumberOfPosts(Math.ceil(numberOfPosts))
  }, [distanceBetweenPosts, lengthOfFence, setNumberOfPosts])

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" py={5} px={6}>
        <Box className={classes.iconContainer}>
          <Suspense fallback={<FenceIconSkeleton />}>
            <FencePostsDistanceIcon className={classes.fenceIcon} />
          </Suspense>
        </Box>
        <Box display="flex" flexDirection="column" mt={2}>
          <Typography variant="subheading1" className={classes.formLabel}>
            {fenceCalculatorLabels.fencePostsDistance}
            <span>{fenceCalculatorLabels.fencePostsDistanceUnit}</span>
          </Typography>
        </Box>
        <Box width={isSmUp ? "50%" : "100%"} pr={isSmUp ? 4 : 0}>
          <TextField
            select
            value={distanceBetweenPosts}
            onChange={(event) =>
              setDistanceBetweenPosts(Number(event.target.value))
            }
            fullWidth
            variant="outlined"
            inputProps={{
              "aria-label": fenceCalculatorLabels.fencePostsDistance
            }}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
          </TextField>
        </Box>
      </Box>
      <Box style={{ background: themeColors.primaryLightCyan }}>
        {isTemporarySelected ? (
          <Box>
            <Typography className={classes.chooseProductLabel}>
              {fenceCalculatorLabels.fencePostsChoose}
            </Typography>
            <ProductsContainer
              products={allPostProducts}
              selectedProduct={selectedPost}
              onSelectProduct={selectProductPost}
              currencySymbol={currencySymbol}
            />
          </Box>
        ) : (
          <>
            <Box py={3} px={2} width={isSmUp ? "50%" : "100%"}>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Grid item xs={6}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    height={107}
                    border={`3px solid ${themeColors.colorBaseBlue700}`}
                    maxWidth={147}
                    bgcolor={themeColors.white}
                  >
                    <WoodenPost style={{ fontSize: 43 }} />
                    <Typography className={classes.infoLabel}>
                      {fenceCalculatorLabels.fencePostsWooden}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography className={classes.lightItalicFont}>
                    {fenceCalculatorLabels.fencePostsWoodenDescription}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {distanceBetweenPosts !== 0 && (
              <Box
                px={2}
                display="flex"
                flexDirection={isSmUp ? "row" : "column"}
              >
                <Typography className={classes.infoLabel}>
                  {fenceCalculatorLabels.fencePostsQuantity}
                  <span>
                    {numberOfPosts} {fenceCalculatorLabels.postsSuffix}
                  </span>
                </Typography>
                <Typography className={classes.lightItalicFont}>
                  ({lengthOfFence} {fenceCalculatorLabels.metresSuffix}{" "}
                  {fenceCalculatorLabels.fence} / {distanceBetweenPosts}{" "}
                  {fenceCalculatorLabels.metresSuffix}{" "}
                  {fenceCalculatorLabels.betweenPostsSuffix})
                </Typography>
              </Box>
            )}
            <Box m={2}>
              <Divider />
            </Box>
          </>
        )}

        {selectedPost.name && distanceBetweenPosts !== 0 && (
          <Box
            pt={3}
            px={2}
            display="flex"
            flexDirection={isSmUp ? "row" : "column"}
          >
            <Typography className={classes.infoLabel}>
              {fenceCalculatorLabels.fencePostsQuantity}
              <span>
                {numberOfPosts} {fenceCalculatorLabels.postsSuffix}
              </span>
            </Typography>
            <Typography className={classes.lightItalicFont}>
              ({lengthOfFence} {fenceCalculatorLabels.metresSuffix}{" "}
              {fenceCalculatorLabels.fence} / {distanceBetweenPosts}{" "}
              {fenceCalculatorLabels.metresSuffix}{" "}
              {fenceCalculatorLabels.betweenPostsSuffix})
            </Typography>
          </Box>
        )}

        {!isTemporarySelected && (
          <Box>
            <Typography className={classes.chooseProductLabel}>
              {fenceCalculatorLabels.fencePostsInsulatorsChoose}
            </Typography>
            <ProductsContainer
              products={allInsulatorProducts}
              selectedProduct={selectedInsulator}
              onSelectProduct={selectProductInsulator}
              currencySymbol={currencySymbol}
            />
            {insulatorPackagesNeeded > 0 && distanceBetweenPosts !== 0 && (
              <Box pt={3} px={2}>
                <Typography className={classes.infoLabel}>
                  {fenceCalculatorLabels.fencePostsInsulatorsQuantity}
                  <span>{insulatorPackagesNeeded}</span>
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    formLabel: {
      fontWeight: 700,
      color: themeColors.black,
      "& span": {
        fontSize: ".875rem",
        marginLeft: ".25rem"
      }
    },
    chooseProductLabel: {
      color: themeColors.black,
      padding: spacing(3, 2),
      fontSize: "1.25rem",
      lineHeight: 1.2,
      fontWeight: 700
    },
    infoLabel: {
      fontSize: ".875rem",
      color: themeColors.primaryBaseBlue,
      fontWeight: 700,
      "& span": {
        color: themeColors.primaryBlue,
        marginLeft: spacing(0.5),
        [breakpoints.up("sm")]: {
          margin: spacing(0, 0.5)
        }
      }
    },
    iconContainer: {
      display: "flex",
      justifyContent: "center",
      [breakpoints.up("sm")]: {
        width: "50%",
        paddingRight: spacing(4)
      }
    },
    fenceIcon: {
      width: 150,
      height: 92
    },
    lightItalicFont: {
      fontSize: "0.875rem",
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: 1.4
    }
  })
)

export default FencePosts
