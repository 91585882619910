import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import classNames from "classnames"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import { makeStyles } from "@material-ui/core/styles"

import AddToCartOrShoppingListContainer from "common/AddToCartOrShoppingListContainer"
import PricePerUnit from "common/Product/PricePerUnit"
import ProductPrice from "common/Product/ProductPrice"
import StockStatus from "common/Product/StockStatus"
import VariantPickerModal from "common/ProductListing/VariantPickerModal"
import ResponsiveImage from "common/ResponsiveImage"
import Typography from "common/Typography"
import Product from "models/Products"
import * as gtm from "services/StarGA"
import SiteSettingsStore from "store/SiteSettingStore"
import ProductBadge, { ProductBadgeProps } from "./ProductBadge"

type Props = {
  product: Product
  gaData?: gtm.gaDataType
  bundleCardSize?: boolean
  slides?: number
}

type StyleProps = {
  _containerHeight: number
  _imageHeight: number
  _enableEcommerce: boolean
  _enableWishlist: boolean
}

const ProductCard: React.FC<Props> = ({
  product,
  gaData,
  bundleCardSize,
  slides
}) => {
  const {
    state: { enableEcommerce, enableWishlist, enableDiscountBadges }
  } = useStore(SiteSettingsStore)

  const heightOnSlides = (slidesCount: number | undefined) => {
    switch (slidesCount) {
      case 2:
        return { containerHeight: 630, imageHeight: 380 }
      case 3:
        return { containerHeight: 476, imageHeight: 242 }
      default:
        return {
          containerHeight: enableEcommerce || enableWishlist ? 400 : 350,
          imageHeight: 150
        }
    }
  }

  const classes = useStyles({
    _containerHeight: heightOnSlides(slides).containerHeight,
    _imageHeight: heightOnSlides(slides).imageHeight,
    _enableEcommerce: enableEcommerce,
    _enableWishlist: enableWishlist
  })()
  const { t } = useTranslation()

  const image =
    product && product.imageAssets && product.imageAssets.length
      ? product.imageAssets[0]
      : product.displayImage

  const variationValue =
    product.variations && product.variations.length && product.variations[0]
      ? product.variations[0].variationValue
      : product.productName2

  const inventoryStatus = product.inventoryStatus === "NOT_IN_STOCK"

  const badgeDiscount: ProductBadgeProps | undefined = useMemo(() => {
    if (
      enableDiscountBadges &&
      product.displayLowestPrice &&
      product.displayLowestDiscountedPrice &&
      product.displayLowestDiscountedPrice.displayPrice !==
        product.displayLowestPrice.displayPrice
    ) {
      const originalPrice = product.displayLowestPrice.amount
      const discountedPrice = product.displayLowestDiscountedPrice.amount
      return {
        label: t("product.discount_label"),
        discount:
          Math.round(
            (100 * (originalPrice - discountedPrice)) / originalPrice
          ) + "%",
        color: "alt"
      }
    }

    return undefined
  }, [product, t, enableDiscountBadges])

  return (
    product && (
      <Card
        className={classNames(classes.root, {
          [classes.bundleCard]: bundleCardSize
        })}
      >
        <div className={classes.productWrapper}>
          <Link
            onClick={(e) => {
              if (!product._route) {
                e.preventDefault()
              }
              gtm.productDetailClick(product, gaData)
            }}
            to={product._route ? product._route : "/"}
            className={classNames("product-page-link", classes.link)}
          >
            <Box position="relative">
              <ResponsiveImage
                containerProps={{
                  className: classNames(classes.imageContainer, {
                    [classes.imageContainerNoPrice]: !enableEcommerce
                  })
                }}
                className={classes.image}
                lazy
                color={image && image.dominantColor}
                image={image}
              />

              <Typography variant="headline6" className={classes.productHeader}>
                {product.productName}
              </Typography>
              {product.productName2 && (
                <Typography variant="body2" className={classes.productVariant}>
                  {product.productName2}
                </Typography>
              )}
              {product.badgeMessage && (
                <ProductBadge
                  label={product.badgeMessage}
                  className={classes.productBadge}
                />
              )}
              {badgeDiscount && (
                <ProductBadge
                  {...badgeDiscount}
                  className={classes.productBadgeDiscount}
                />
              )}
            </Box>
          </Link>
          {enableEcommerce && (
            <div>
              <Box position="absolute" bottom={84}>
                <StockStatus
                  inventoryStatus={product.inventoryStatus}
                  isDigitalService={product.isDigitalService}
                />
              </Box>
              <div className={classes.priceWrapper}>
                {product.isDigitalService ? (
                  <PricePerUnit
                    price={product.displayLowestPricePerX || ""}
                    unit={product.displayLowestPricePerXUnitType || ""}
                    size={"medium"}
                  />
                ) : (
                  <ProductPrice
                    className={classes.price}
                    price={product.displayLowestPrice}
                    discountedPrice={product.displayLowestDiscountedPrice}
                    size={"medium"}
                  />
                )}
                <Typography variant="smallText">
                  {t("product.ex_vat")}
                </Typography>
              </div>
            </div>
          )}
        </div>
        <div className={classes.buttonWrapper}>
          {product.variations.length > 1 ? (
            <VariantPickerModal
              link={product._route}
              productId={product._id}
              productName={product.displayName}
              disabled={enableWishlist ? false : inventoryStatus}
            />
          ) : product.variations.length === 1 ? (
            <AddToCartOrShoppingListContainer
              disabled={inventoryStatus || product.displayLowestPrice === null}
              code={product.variations[0].code}
              large={false}
              amount={
                product.variations[0].displayPrice &&
                product.variations[0].displayPrice.amount
                  ? product.variations[0].displayPrice.amount
                  : 0
              }
              name={product.displayName}
              category={product.categoryStructure}
              image={image && image._url}
              variationValue={variationValue}
              link={product._route}
              digitalService={product["isDigitalService"]}
              displayPrice={product.displayLowestPrice}
              discountedPrice={product.displayLowestDiscountedPrice}
            />
          ) : null}
        </div>
      </Card>
    )
  )
}

const useStyles = ({
  _enableEcommerce,
  _enableWishlist,
  _containerHeight,
  _imageHeight
}: StyleProps) =>
  makeStyles(({ spacing, breakpoints }) => ({
    root: {
      width: "100%",
      height: _containerHeight,
      position: "relative",
      display: "flex",
      flexWrap: "wrap",
      boxShadow: "none",
      [breakpoints.down("md")]: {
        height: _enableEcommerce ? 380 : _enableWishlist ? 300 : 260
      },
      [breakpoints.down("xs")]: {
        height: _enableEcommerce ? 340 : _enableWishlist ? 290 : 250
      }
    },
    bundleCard: {
      width: "100%",
      height: 351
    },
    productWrapper: {
      width: "100%",
      padding: spacing(3),
      position: "relative",
      [breakpoints.down("xs")]: {
        padding: spacing(1.5)
      }
    },
    productBadge: {
      position: "absolute",
      top: 0,
      left: 0
    },
    productBadgeDiscount: {
      position: "absolute",
      top: 0,
      right: 0
    },
    imageContainer: {
      position: "relative",
      height: _imageHeight,
      width: "100%",
      marginBottom: spacing(3),
      [breakpoints.down("md")]: {
        height: "130px !important",
        marginBottom: _enableEcommerce ? spacing(3) : spacing(1.5)
      },
      [breakpoints.down("xs")]: {
        height: "140px !important",
        marginBottom: spacing(1.5)
      }
    },
    imageContainerNoPrice: {
      height: 210,
      [breakpoints.down("xs")]: {
        height: 150
      }
    },
    image: {
      width: "100%",
      maxHeight: "100%",
      objectFit: "contain",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)"
    },
    link: {
      "&:hover": {
        textDecoration: "none"
      }
    },
    productHeader: {
      wordBreak: "break-word",
      position: "relative",
      fontSize: 18,
      width: "100%",
      overflow: "hidden",
      display: "-webkit-box",
      WebkitLineClamp: 3,
      WebkitBoxOrient: "vertical",
      paddingBottom: 0,
      [breakpoints.down("xs")]: {
        fontSize: 16
      }
    },
    productVariant: {
      position: "relative",
      width: "100%",
      textAlign: "left"
    },
    priceWrapper: {
      position: "absolute",
      bottom: spacing(3),
      left: spacing(3),
      [breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "baseline"
      },
      [breakpoints.down("xs")]: {
        left: spacing(1.5),
        bottom: spacing(1.5)
      }
    },
    price: {
      [breakpoints.down("md")]: {
        marginRight: spacing(1)
      }
    },
    buttonWrapper: {
      position: "absolute",
      bottom: 0,
      right: 0
    }
  }))

export default ProductCard
