import { ApiDynamicPage } from "@starrepublic/epi/types"

const cacheLocation = "EPI_CONTENT_DATA"

const pages = {
  getCachedPageContent: () => {
    const cachedValue = window[cacheLocation]
    return Promise.resolve<ApiDynamicPage>(cachedValue as any)
  }
}

export default pages
