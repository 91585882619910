import { SvgIconProps } from "@material-ui/core/SvgIcon"

const Uruguay: React.FC<SvgIconProps> = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 48 48" width="48" height="48" {...props}>
    <g>
      <path
        fill="#E6E6E6"
        d="M48,8c0-1.105-0.895-2-2-2H18v20H0v14c0,1.105,0.895,2,2,2h44c1.105,0,2-0.895,2-2V8z"
      />
      <rect x="18" y="10" fill="#0437AC" width="30" height="4" />
      <rect x="18" y="18" fill="#0437AC" width="30" height="4" />
      <polygon fill="#0437AC" points="0,26 0,30 48,30 48,26 20,26 " />
      <rect y="34" fill="#0437AC" width="48" height="4" />
      <path fill="#E6E6E6" d="M18,26H0V8c0-1.105,0.895-2,2-2h16V26z" />
      <circle fill="#B09405" cx="10" cy="16" r="4" />
    </g>
  </svg>
)

export default Uruguay
