import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Card from "@material-ui/core/Card"
import DialogContent from "@material-ui/core/DialogContent"
import Fade from "@material-ui/core/Fade"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import Modal from "@material-ui/core/Modal"
import { makeStyles } from "@material-ui/core/styles"
import Clear from "@material-ui/icons/Clear"

import Typography from "common/Typography"
import originalPartsStore from "store/OriginalPartsStore"
import siteSettingStore from "store/SiteSettingStore"
import CategoryFilter from "./CategoryFilter"
import LoadingBar from "./LoadingBar"
import PartsTable from "./PartsTable"
import SearchForm from "./SearchForm"

const OriginalParts: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { isOpen },
    actions: { reset, open }
  } = useStore(originalPartsStore)

  const {
    state: { enableOriginalParts }
  } = useStore(siteSettingStore)

  const handleClose = () => {
    reset()
  }

  useEffect(
    () => {
      if (window.location.hash === "#originalparts") {
        open()
        window.location.hash = ""
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.hash]
  )

  if (!enableOriginalParts) {
    return null
  }

  return (
    <Modal
      aria-labelledby="original-parts-modal"
      aria-describedby="shop-original-parts-here"
      open={isOpen}
      onClose={(reason) => {
        if (reason !== "backdropClick") {
          handleClose()
        }
      }}
      className={classes.root}
    >
      <Fade in={isOpen}>
        <div>
          <Grid item xs={12} className={classes.modal}>
            <Card className={classes.card}>
              <DialogContent className={classes.dialogue}>
                <Typography
                  variant="headline2"
                  color="textPrimary"
                  className={classes.title}
                >
                  {t("common.original_parts")}
                </Typography>
                <Typography
                  variant={"body1"}
                  className={classes.additionalInformation}
                >
                  {t("common.original_parts_additional_information")}
                </Typography>
                <IconButton
                  onClick={handleClose}
                  className={classes.closeButton}
                >
                  <Clear className={classes.closeIcon} />
                </IconButton>
                <div>
                  <Grid container>
                    <CategoryFilter />
                    <SearchForm />
                  </Grid>
                  <div className={classes.resultWrapper}>
                    <LoadingBar />
                    <PartsTable />
                  </div>
                </div>
              </DialogContent>
            </Card>
          </Grid>
        </div>
      </Fade>
    </Modal>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    overflow: "scroll"
  },
  dialogue: { outline: 0 },
  modal: {
    position: "absolute",
    top: 80,
    left: "50%",
    transform: "translateX(-50%)",
    maxWidth: 1000,
    width: "100%",
    paddingBottom: 80,
    borderRadius: 0
  },
  card: {
    borderRadius: 0
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0
  },
  closeIcon: {
    color: "#000",
    width: spacing(1) * 3.5,
    height: spacing(1) * 3.5
  },
  title: {
    margin: `${spacing(3)}px 0`,
    textAlign: "center"
  },
  additionalInformation: {
    textAlign: "center",
    marginBottom: spacing(3)
  },
  resultWrapper: {
    padding: `${spacing(3)}px 0`,
    textAlign: "center",
    [breakpoints.down("md")]: {
      overflowY: "auto"
    }
  }
}))

export default OriginalParts
