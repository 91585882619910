import XhtmlString, {
  XhtmlStringProperty
} from "@starrepublic/epi/cms/components/XhtmlString"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

import Typography from "./Typography"

type Props = {
  className?: string
  content: XhtmlStringProperty
  component?: React.ComponentType
}

const HtmlContent = ({ className, component, content }: Props) => {
  const classes = useStyles()

  return (
    <Typography className={classNames(classes.root, className)} component="div">
      <XhtmlString content={content} component={component} />
    </Typography>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    "& p": {
      margin: `0 0 ${spacing(3)}px`
    },
    "& iframe": {
      maxWidth: "100%"
    },
    "& ul": {
      margin: `0 0 ${spacing(4)}px 0`
    }
  }
}))

export default HtmlContent
