import classNames from "classnames"

import Card from "@material-ui/core/Card"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import Radio from "@material-ui/core/Radio"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import { DeliveryOption } from "models/Checkout"
import { useCheckoutPaymentStore } from "store/CheckoutStore"

const DeliveryOptions: React.FC = () => {
  const classes = useStyles()
  const {
    checkout,
    preparedCheckout,
    selectedDeliveryOption,
    computed: { availableDeliveryOptions },
    set: checkoutSet
  } = useCheckoutPaymentStore()

  if (
    !selectedDeliveryOption &&
    checkout.data &&
    checkout.data.availableDeliveryOptions.deliveryOptions.length > 0
  ) {
    checkoutSet.selectedDeliveryOption(
      checkout.data.availableDeliveryOptions.deliveryOptions[0].methodId
    )
  }

  return checkout.data ? (
    <Grid container justifyContent="center" className={classes.root}>
      {availableDeliveryOptions.length > 0 ? (
        availableDeliveryOptions.map((deliveryOption: DeliveryOption) => (
          <Grid
            key={deliveryOption.methodId}
            item
            xs={12}
            className={classNames(classes.gridItem, {
              [classes.activeGridItem]:
                deliveryOption.methodId === selectedDeliveryOption
            })}
          >
            <Card raised className={classes.cardContent}>
              <FormControlLabel
                classes={{
                  root: classes.formControlLabel,
                  label: classes.formControlLabel
                }}
                label={
                  <div className={classes.labelContainer}>
                    <div className={classes.flex}>
                      <Typography variant="headline6">
                        {deliveryOption.displayName}
                      </Typography>
                      <Typography variant="priceSmall">
                        {deliveryOption.basePrice} {deliveryOption.currency}
                      </Typography>
                    </div>
                    <Typography variant="body1" color="textSecondary">
                      {deliveryOption.displayName}
                    </Typography>
                  </div>
                }
                control={
                  <Radio
                    disabled={preparedCheckout.loading}
                    color="default"
                    classes={{
                      colorSecondary: classes.RadioSecondary,
                      checked: classes.RadioSecondary
                    }}
                    onClick={() => {
                      checkoutSet.selectedDeliveryOption(
                        deliveryOption.methodId
                      )
                    }}
                    checked={deliveryOption.methodId === selectedDeliveryOption}
                  />
                }
              />
            </Card>
          </Grid>
        ))
      ) : (
        <Typography
          variant={"body1"}
          style={{ marginBottom: 36, color: "red" }}
        >
          {"No delivery options are available"}
        </Typography>
      )}
    </Grid>
  ) : null
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    margin: 0
  },
  gridItem: {
    borderLeft: `3px solid ${themeColors.white}`
  },
  cardContent: {
    textAlign: "left",
    paddingLeft: spacing(3) - 3,
    "&:last-child": {
      boxShadow: "none"
    }
  },
  activeGridItem: {
    borderLeft: `3px solid ${themeColors.primaryCyan}`
  },
  labelContainer: {
    paddingBottom: spacing(3),
    paddingTop: spacing(3),
    paddingRight: 10
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  formRadioGroup: {
    width: "100%"
  },
  formControlLabel: {
    width: "100%"
  },
  RadioSecondary: {
    color: themeColors.primaryCyan
  }
}))

export default DeliveryOptions
