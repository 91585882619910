import { useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import Popover from "@material-ui/core/Popover"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import CartStore from "store/CartStore"

const OrderOverviewPrice = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const {
    state: { selectedCart, itemsCount }
  } = useStore(CartStore)

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const popoverOpen = Boolean(anchorEl)

  return (
    <Grid container className={classes.root}>
      {selectedCart &&
        selectedCart.properties &&
        selectedCart.properties.numberOfProducts > 0 && (
          <Grid item xs={12} className={classes.row}>
            <div className={classes.textContainer}>
              <div className={classes.leftText2}>
                <Typography variant={"body1"}>
                  {`${selectedCart.properties.numberOfProducts} ${t(
                    "common.items_total"
                  )}`}
                </Typography>
              </div>
            </div>
          </Grid>
        )}
      {selectedCart &&
        selectedCart.properties &&
        selectedCart.properties.numberOfDigitalServices > 0 && (
          <Grid item xs={12} className={classes.row}>
            <div className={classes.textContainer}>
              <div className={classes.leftText2}>
                <Typography variant={"body1"} component="span">
                  {`${selectedCart.properties.numberOfDigitalServices} ${t(
                    "common.subscriptions_total"
                  )}`}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.actionRequiredText}
                  onClick={handlePopoverOpen}
                  component="span"
                >
                  {t("common.action_required")}
                </Typography>
                <Popover
                  open={popoverOpen}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                  }}
                >
                  <Typography variant="body1" className={classes.popoverText}>
                    {t("common.subscriptions_action_required_helper")}
                  </Typography>
                </Popover>
              </div>
            </div>
          </Grid>
        )}
      <Grid item xs={12} className={classes.row}>
        <div className={classes.textContainer}>
          <div className={classes.leftText}>
            <Typography variant={"body1"} className={classes.bold}>
              {t("common.subtotal")}
            </Typography>
          </div>
        </div>
        <div className={classes.rightText}>
          <Typography variant={"body1"} className={classes.bold}>
            {selectedCart.subTotalString}
          </Typography>
        </div>
      </Grid>
      {/* {selectedCart["discountTotalString"] && (
        <Grid item xs={12} className={classes.row}>
          <div className={classes.textContainer}>
            <div className={classes.leftText}>
              <Typography variant={"body2"} className={classes.bold}>
                {t("checkout.discount")}
              </Typography>
            </div>
          </div>
          <div className={classes.rightText}>
            <Typography variant={"body2"} className={classes.red}>
              {/* TODO: Add discountTotalString to api-model */}
      {/*selectedCart["discountTotalString"]}
            </Typography>
          </div>
        </Grid>
      )} */}
      {/* <Grid item xs={12} className={classes.row}>
        <div className={classes.textContainer}>
          <div className={classes.leftText}>
            <Typography variant={"body2"} className={classes.bold}>
              {t("common.shipping")}
            </Typography>
          </div>
        </div>
        <div className={classes.rightText}>
          <Typography variant={"body2"}>
            {selectedCart.shippingTotalString}
          </Typography>
        </div>
      </Grid> */}
      {/* <Grid item xs={12} className={classes.row}>
        <div className={classes.textContainer}>
          <div className={classes.leftText}>
            <Typography variant={"body2"} className={classes.bold}>
              {t("common.taxes")}
            </Typography>
          </div>
        </div>
        <div className={classes.rightText}>
          <Typography variant={"body2"}>
            {selectedCart.taxTotalString}
          </Typography>
        </div>
      </Grid> */}
      {/* <Divider className={classes.border} /> */}
      {/* <Grid item xs={12} className={classes.row}>
        <div className={classes.textContainer}>
          <div className={classes.leftText}>
            <Typography variant={"priceSmall"}>
              {t("checkout.total_including_vat")}
            </Typography>
          </div>
        </div>
        <div className={classes.rightText}>
          <Typography variant={"priceSmall"}>
            {selectedCart.totalString}
          </Typography>
        </div>
      </Grid> */}
    </Grid>
  )
}

const useStyles = makeStyles(({ common, breakpoints, spacing }) => ({
  root: {
    width: "100%",
    position: "relative",
    padding: common.gridSpacing,
    [breakpoints.up("sm")]: {
      paddingLeft: "40%"
    }
  },
  row: {
    display: "flex",
    width: "100%"
  },
  textContainer: {
    flexWrap: "wrap",
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    [breakpoints.up("sm")]: {
      flexWrap: "nowrap",
      width: "auto",
      maxWidth: "100%",
      whiteSpace: "nowrap"
    }
  },
  leftText: {
    width: "100%",
    [breakpoints.up("sm")]: {
      whiteSpace: "nowrap"
    }
  },
  leftText2: {
    width: "100%"
  },
  rightText: {
    width: "100%",
    display: "flex",
    textAlign: "right",
    justifyContent: "flex-end"
  },
  border: {
    width: "100%",
    marginTop: common.gridSpacing,
    marginBottom: common.gridSpacing,
    backgroundColor: "transparent",
    borderBottom: `1px dashed ${common.themeColors.lightGray}`
  },
  red: {
    color: common.themeColors.primaryRed
  },
  bold: {
    fontWeight: "bold"
  },
  totalItems: {
    fontWeight: "bold",
    display: "flex"
  },
  actionRequiredText: {
    color: common.themeColors.primaryCyan,
    fontWeight: "bold",
    cursor: "pointer",
    marginLeft: spacing(1)
  },
  popoverText: {
    padding: spacing(2),
    maxWidth: 470
  }
}))

export default OrderOverviewPrice
