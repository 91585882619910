import request from "@starrepublic/epi/api/request"

import { ImageAsset } from "models/Variant"

export type LineItem = {
  lineItemId: 0
  variant: string
  code: string
  displayName: string
  quantity: 0
  placedPrice: 0
  placedPriceString: string
  extendedPlacedPrice: 0
  extendedPlacedPriceString: string
  extendedPrice: 0
  extendedPriceString: string
  discountedPrice: 0
  discountedPriceString: string
  subTotal: string
  isReturnable: boolean
  subTotalString: string
  image: ImageAsset
  properties: {
    imageUrl: string
  }
}

export type Shipment = {
  lineItems: LineItem[]
  orderShipmentStatus: string
  shippingMethod: string
  shippingAddress: {
    id: string
    firstName: string
    lastName: string
    organization: string
    line1: string
    line2: string
    city: string
    countryCode: string
    countryName: string
    zipCode: string
    regionCode: string
    regionName: string
    state: string
    daytimePhoneNumber: string
    eveningPhoneNumber: string
    faxNumber: string
    email: string
  }
}

export type Order = {
  id: number
  isPending: boolean
  name: string
  status: string
  currency: string
  language: string
  countries: [
    {
      id: 0
      name: string
      code: string
    }
  ]
  customerId: string
  created: string
  modified: string
  marketId: string
  marketName: string
  addressId: string
  shippingTotal: 0
  shippingTotalString: string
  taxTotal: 0
  taxTotalString: string
  subTotal: 0
  subTotalString: string
  total: 0
  totalString: string
  billingCurrency: string
  owner: string
  handlingTotal: 0
  handlingTotalString: string
  lineItems: LineItem[]
  deliveryOptionId: string
  paymentOptionId: string
  shipments: Shipment[]
  discountTotalString: string
  numberOfItems: number
  billingAddress: {
    id: string
    firstName: string
    lastName: string
    organization: string
    line1: string
    line2: string
    city: string
    countryCode: string
    countryName: string
    zipCode: string
    regionCode: string
    regionName: string
    state: string
    daytimePhoneNumber: string
    eveningPhoneNumber: string
    faxNumber: string
    email: string
  }
  validationIssues: [
    {
      key: string
      value: string
    }
  ]
  properties: {
    preGeneratedOrderNumber: number
    totalCostIncVatString: string
    sapVatAmountString: string
    shippingTrackingUrls: string
    trackingNumber: string
    selectedDeliveryOptionString: string
    selectedPaymentOptionString: string
    digitalServiceErrors?: DigitalServiceError[]
    customerOrderReference?: string
  }
  orderGroupId: 0
}

export type CondenseOrder = {
  id: string
  orderGroupId: number
  orderReference: number | null
  status: string
  created: string
  numberOfItems: number
  total: number
  totalString: string
  totalIncTax: number
  totalIncTaxString: string
}

type DigitalServiceError = {
  variantId: string
  displayName: string
  url: string | null
}

type ReturnLineItem = {
  code: string
  quantity: number
  reason: string
}

export type ReturnModel = {
  shipmentId: number
  lineItems: ReturnLineItem[]
  comment: string
  returnWarehouseId: string
}

const orders = {
  getOrders: () => request.get<Order[]>(`/orders`),
  getOrder: (orderId: string) => request.get<Order>(`/orders/${orderId}`),
  getCondenseOrders: () => request.get<any[]>(`dev/orders`),
  submitReturn: (orderGroupId: string, model: ReturnModel) =>
    request.post<Order>(`/orders/${orderGroupId}/return`, model),
  getOrderByOrderIdentifier: (identifier: string) =>
    request.get<Order>(`/orders/${identifier}`)
}

export default orders
