import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { scrollToElement } from "utils/ui/scroll"

import CheckoutSectionHeader from "../../common/CheckoutSectionHeader"
import ContainerWrapper from "../../common/ContainerWrapper"

import Button from "common/Button"
import Spinner from "common/Spinner"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import CheckboxCheckedForm from "icons/CheckboxCheckedForm"
import CheckboxForm from "icons/CheckboxForm"
import PaymentIcon from "icons/Payment"
import { ScrollContainers } from "pages/Checkout/Checkout"
import AccountStore from "store/AccountStore"
import authStore from "store/AuthStore"
import CartStore from "store/CartStore"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import PaymentStore from "store/PaymentStore"
import siteSettingStore from "store/SiteSettingStore"
import OrderReference from "./OrderReference"
import PaymentOptions from "./PaymentOptions"

const TIMEOUT = 15000

const Payment: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { cart }
  } = useStore(CartStore)

  const {
    checkout,
    preparedCheckout,
    selectedPaymentOption,
    selectedSubPaymentOption,
    selectedDeliveryOption,
    selectedCardPayment,
    isEditingShippingAddress,
    isDangerousGoodsTerms,
    isEditingOrderReference,
    prepareCheckout,
    set: checkoutSet,
    reset: checkoutReset
  } = useCheckoutPaymentStore()

  const {
    state: { terms }
  } = useStore(siteSettingStore)

  const {
    state: { user }
  } = useStore(authStore)

  const {
    actions: { getPaymentMethods }
  } = useStore(PaymentStore)

  const {
    state: { accountDetailsMissing }
  } = useStore(AccountStore)

  const isLoggedIn = !user.loading && user.data != null
  const [validationError, setValidationError] = useState("")
  const [isDangerousGoods, setDangerousGoods] = useState<boolean | null>(false)
  const [continueButtonText, setContinueButtonText] = useState(
    t("checkout.continue_button_label")
  )
  const isDisabled =
    (!selectedSubPaymentOption && !selectedPaymentOption) ||
    preparedCheckout.loading

  useEffect(
    () => {
      if (
        checkout.data &&
        checkout.data.cart &&
        checkout.data.paymentOptions.find((p) => p.id === "Adyen")
      ) {
        getPaymentMethods({
          toCountryCode: checkout.data.cart.marketId,
          locale: checkout.data.cart.language,
          cartName: checkout.data.cart.name
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getPaymentMethods]
  )

  useEffect(() => {
    setValidationError("")
  }, [selectedPaymentOption, selectedSubPaymentOption])

  const continueClick = async () => {
    setValidationError("")

    if (
      checkout.data &&
      checkout.data.cart &&
      checkout.data.cart.properties &&
      checkout.data.cart.properties.numberOfDigitalServices > 0 &&
      checkout.data.cart.properties.numberOfProducts === 0
    ) {
      await prepareCheckout(user.data)
      return
    }

    if (!selectedDeliveryOption || !selectedPaymentOption) {
      setValidationError(t("checkout.no_delivery_or_payment_has_been_choosen"))
      scrollToElement(ScrollContainers.paymentOptionsErrorMessage)
      return
    }
    if (isEditingShippingAddress) {
      setValidationError(
        t("checkout.save_shipping_address_before_proceeding_error")
      )
      scrollToElement(ScrollContainers.paymentOptionsErrorMessage)
      return
    }

    if (
      selectedPaymentOption === "Adyen" &&
      selectedSubPaymentOption === "scheme" &&
      (selectedCardPayment === null || !selectedCardPayment.isValid)
    ) {
      setValidationError(t("checkout.adyen_cardpayment_not_valid"))
      return
    }
    await prepareCheckout(user.data).then(() => {
      checkoutReset.isDangerousGoodsTerms()
    })
  }

  const handleDangerousGoodsTerms = () => {
    checkoutSet.isDangerousGoodsTerms(!isDangerousGoodsTerms)
  }

  useEffect(
    () => {
      let continueTimeout: ReturnType<typeof setTimeout>
      if (preparedCheckout.loading) {
        continueTimeout = setTimeout(() => {
          setContinueButtonText(t("checkout.continue_button_timeoutmessage"))
        }, TIMEOUT)
      }
      return () => {
        clearTimeout(continueTimeout)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preparedCheckout.loading]
  )

  const cartIncludesDangerousItem = () => {
    setDangerousGoods(
      cart.data &&
        cart.data.lineItems.filter((item) => {
          return item.properties.isDangerousGoods
        }).length > 0
    )
  }

  useEffect(
    () => {
      cartIncludesDangerousItem()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart.loading && user.data]
  )

  return (
    <div id={ScrollContainers.paymentOptions} className={classes.root}>
      <ContainerWrapper isActive={isLoggedIn && !accountDetailsMissing}>
        <CheckoutSectionHeader
          title={t("checkout.payment_title")}
          Icon={PaymentIcon}
        />
        {isLoggedIn && !accountDetailsMissing && (
          <>
            {!checkout.loading && checkout.data ? (
              <>
                <PaymentOptions />
                {(preparedCheckout.error || validationError) && (
                  <div
                    id={ScrollContainers.paymentOptionsErrorMessage}
                    className={classes.errorWrapper}
                  >
                    {(validationError || isEditingShippingAddress) && (
                      <Typography className={classes.errorMessage}>
                        {validationError}
                      </Typography>
                    )}
                    {preparedCheckout.error && (
                      <Typography
                        id="prepare-checkout-error-message"
                        className={classes.errorMessage}
                      >
                        {t(
                          `error.${preparedCheckout.error
                            .toString()
                            .toLowerCase()}`
                        )}
                      </Typography>
                    )}
                  </div>
                )}
                <Grid
                  direction="column"
                  alignContent="center"
                  container
                  justifyContent="center"
                >
                  {isDangerousGoods && (
                    <Grid item xs={12} sm={6}>
                      <Box display="flex" justifyContent="center" py={4}>
                        <Checkbox
                          icon={<CheckboxForm />}
                          checkedIcon={<CheckboxCheckedForm />}
                          disabled={preparedCheckout.loading}
                          name="ReadAndUnderstoodDangerousGoodsTerms"
                          checked={isDangerousGoodsTerms}
                          onChange={handleDangerousGoodsTerms}
                          color="default"
                          value={isDangerousGoodsTerms}
                        />
                        <ThemeXhtmlString
                          content={terms.dangerousGoodsCheckoutTerms}
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <OrderReference />
                  </Grid>
                </Grid>
                {!isEditingOrderReference && (
                  <Grid container justifyContent="center">
                    <Grid
                      item
                      xs={12}
                      className={classes.continueButtonContainer}
                    >
                      <Button
                        variant="secondary"
                        id="prepare-checkout-button"
                        onClick={continueClick}
                        disabled={isDisabled}
                        className={classNames({
                          [classes.disabled]: isDisabled === true
                        })}
                      >
                        {preparedCheckout.loading && (
                          <ButtonLoaderIcon className={classes.buttonSpinner} />
                        )}
                        {continueButtonText}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <div className={classes.spinnerWrapper}>
                <Spinner />
              </div>
            )}
          </>
        )}
      </ContainerWrapper>
    </div>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    width: "100%"
  },
  continueButtonContainer: {
    paddingTop: spacing(4),
    paddingBottom: spacing(6),
    display: "flex",
    justifyContent: "center"
  },
  errorWrapper: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    maxWidth: "100%"
  },
  errorMessage: {
    paddingTop: spacing(3),
    textAlign: "center",
    color: themeColors.primaryRed
  },
  spinnerWrapper: {
    width: "100%",
    textAlign: "center",
    padding: spacing(3)
  },
  buttonSpinner: {
    marginRight: spacing(1)
  },
  disabled: {
    backgroundColor: "transparent",
    "& span": {
      color: themeColors.gray
    }
  }
}))

export default Payment
