import useStore from "global-hook-store"

import ProductListingStore from "store/ProductListingStore"
import LoadMore from "./LoadMore"

type Props = {
  route: string
}

const LoadMoreButton = ({ route }: Props) => {
  const {
    state: { productList, products, filteredProducts, searchedProductList },
    actions: {
      loadProductList,
      addProducts,
      addFilteredProducts,
      quickFilterSearch,
      setNetPriceArticleList
    }
  } = useStore(ProductListingStore)

  const filterMode = searchedProductList.data !== null

  const handleLoadMore = () => {
    if (filterMode) {
      quickFilterSearch().then(() => {
        addFilteredProducts()
      })
      return
    }

    loadProductList().then(() => {
      addProducts()
    })
  }

  const showingNumberOfProducts = filterMode
    ? (filteredProducts && filteredProducts.length) || 0
    : (products && products.length) || 0

  const totalNumberOfProducts = filterMode
    ? (searchedProductList.data && searchedProductList.data[0].totalItems) || 0
    : (productList.data && productList.data.totalItems) || 0

  return (
    <>
      {productList.data && (
        <LoadMore
          route={route}
          totalNumberOfProducts={totalNumberOfProducts}
          showingNumberOfProducts={showingNumberOfProducts}
          handleLoadMore={handleLoadMore}
        />
      )}
    </>
  )
}

export default LoadMoreButton
