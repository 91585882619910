import { useTranslation } from "react-i18next"

import Box from "@material-ui/core/Box"

import Typography from "common/Typography"

type DisplayNumberOfDealersShowingProps = {
  dealersInList: number
  totalNumberOfDealers: number
}
export const DisplayNumberOfDealersShowing = ({
  dealersInList,
  totalNumberOfDealers
}: DisplayNumberOfDealersShowingProps) => {
  const { t } = useTranslation()

  return (
    <Box py={1}>
      {totalNumberOfDealers > 0 ? (
        <Typography variant="smallText">
          {t("contact.showing_x_of_y_dealers")
            .replace("{X}", dealersInList)
            .replace("{Y}", totalNumberOfDealers)}
        </Typography>
      ) : (
        <Typography variant="smallText">
          {t("contact.showing_x_dealers").replace("{x}", dealersInList)}
        </Typography>
      )}
    </Box>
  )
}
