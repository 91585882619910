import { ApiDynamicPage } from "../types"

import request from "./request"
import { getCached, getIdentifier } from "./utils"

const pages = {
  getContentByIdentifier: (identifier: string, useCache: boolean = true) => {
    const id = getIdentifier(identifier)
    const url = `/contents/${id}`
    return (
      (useCache &&
        getCached<ApiDynamicPage>(
          `/contents/${id}`,
          "EPI_CONTENT_DATA",
          (lang) => `/api/contents/${lang}/${id}`,
          true
        )) ||
      request.get(url)
    )
  }
}

export default pages
