import classNames from "classnames"

import { ButtonProps } from "@material-ui/core/Button"
import ButtonBase, { ButtonBaseProps } from "@material-ui/core/ButtonBase"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"

type Props = {
  className?: string
  reversed?: boolean
  disabled?: boolean
  spinner?: boolean
  lightBorder?: boolean
} & ButtonBaseProps &
  Pick<ButtonProps, "size">

const ContinueButton: React.FunctionComponent<Props> = ({
  className,
  children,
  reversed = false,
  disabled = false,
  spinner = false,
  lightBorder = false,
  size = "large",
  ...rest
}) => {
  const classes = useStyles()

  return (
    <ButtonBase
      className={classNames(
        classes.root,
        className,
        {
          [classes.reversedRoot]: reversed,
          [classes.lightBorder]: lightBorder,
          [classes.disabled]: disabled
        },
        classes[`size-${size}`]
      )}
      {...rest}
      disabled={disabled}
    >
      <Typography
        variant="button1"
        className={classNames({
          [classes.content]: !reversed,
          [classes.reversedContent]: reversed
        })}
      >
        <Grid className={classes.iconContainer}>
          {spinner && <ButtonLoaderIcon />}
        </Grid>
        {children}
      </Typography>
    </ButtonBase>
  )
}

const useStyles = makeStyles(
  ({
    spacing,
    breakpoints,
    common: { themeColors, gridSpacing },
    palette
  }) => ({
    root: {
      minHeight: 44,
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      border: `2px solid ${themeColors.primaryCyan}`,
      [breakpoints.down("sm")]: {
        width: "100%",
        margin: `0 ${gridSpacing}px`
      },
      "& span": {
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap"
      }
    },
    reversedRoot: {
      background: themeColors.primaryCyan,
      [breakpoints.down("sm")]: {
        width: "100%",
        margin: 0
      }
    },
    reversedContent: {
      position: "relative",
      color: themeColors.white
    },
    content: {
      position: "relative",
      color: themeColors.primaryCyan
    },
    disabled: {
      border: `2px solid ${palette.text.disabled}`,
      background: palette.text.disabled,
      "& span": {
        color: themeColors.white
      }
    },
    iconContainer: {
      display: "flex",
      alignItems: "center",
      height: spacing(2),
      width: spacing(2),
      position: "absolute",
      left: -`${spacing(3)}`
    },
    lightBorder: {
      border: `1px solid ${themeColors.primaryLightCyan}`,
      "& span": {
        color: themeColors.white
      }
    },
    [`size-small`]: {
      minHeight: 32,
      "&$root > span": {
        paddingTop: spacing(0.5),
        paddingBottom: spacing(0.5)
      }
    },
    [`size-medium`]: {
      "&$root > span": { paddingTop: spacing(1), paddingBottom: spacing(1) }
    },
    [`size-large`]: {
      "&$root > span": { paddingTop: spacing(2), paddingBottom: spacing(2) }
    }
  })
)

export default ContinueButton
