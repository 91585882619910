import { useTranslation } from "react-i18next"
import useStore, { useLocalStore } from "global-hook-store"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import { ImageProductCarouselContent } from "blocks/ImageProductCarouselBlock/types"
import Button from "common/Button"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import AddToCartStore from "store/AddToCartStore"
import CartStore from "store/CartStore"
import OrderStore from "store/OrderStore"
import snackbarStore, { SnackbarModel } from "store/SnackbarStore"

type Props = { tabletMaxWidth: number } & ImageProductCarouselContent
const TextContent: React.FC<Props> = (props) => {
  const {
    header,
    body,
    linkText,
    items,
    displayLowestDiscountedPrice,
    displayLowestPrice
  } = props
  const textBoxBig = items.length > 2
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { isLoading },
    actions: { setIsLoading }
  } = useLocalStore(AddToCartStore)

  const {
    actions: { openSnackbar }
  } = useStore(snackbarStore)

  const {
    actions: { getByCartTypeName }
  } = useStore(CartStore)

  const {
    actions: { addItemsToCart }
  } = useStore(OrderStore)

  const itemsInBundle = items.map((x) => {
    return {
      itemCode: x.displayLowestPriceArticleNumber,
      quantity: 1,
      displayName: x.displayName
    }
  })

  const handleAddItemsToCart = async () => {
    if (!isLoading) {
      await setIsLoading(true)
      await addItemsToCart(itemsInBundle).then((state) => {
        if (state.bundleToCart.error) {
          openSnackbar({
            options: {
              message: t(
                `error.${state.bundleToCart.error.toString().toLowerCase()}`
              )
            },
            type: "error"
          })
        } else if (
          state.bundleToCart.data &&
          state.bundleToCart.data.validationIssues &&
          state.bundleToCart.data.validationIssues.length > 0
        ) {
          const index =
            state.bundleToCart.data!.validationIssues[0].value.split("|")
          const message = index[1]
          const type = index[0].toLowerCase() as SnackbarModel["type"]
          openSnackbar({
            options: {
              message
            },
            type
          })
        } else {
          const options = {
            code: "",
            image: "",
            displayName: header,
            variationValue: "",
            discountedPrice: displayLowestDiscountedPrice,
            displayPrice: displayLowestPrice,
            message: t("cart.bundle_products_added_to_cart")
          }

          openSnackbar({
            options,
            type: "product"
          })
        }
        getByCartTypeName("basket")
        setIsLoading(false)
      })
    }
  }

  return (
    <Box
      mr={{ xs: 0, sm: 3 }}
      maxWidth={{ xs: "100%", sm: "100%", md: textBoxBig ? 350 : 394 }}
      className={classes.text}
    >
      <Typography variant="headline2">{header}</Typography>
      <ThemeXhtmlString content={body} />
      <Box pb={2} display="flex" flexDirection="column" gridGap={8}>
        {displayLowestDiscountedPrice &&
          displayLowestDiscountedPrice.amount !== displayLowestPrice.amount && (
            <Typography className={classes.discount} variant="priceMedium">
              {displayLowestPrice.displayPrice}
            </Typography>
          )}
        <Box display="flex" alignItems="end" gridGap={8}>
          {displayLowestPrice && (
            <Typography variant="priceLarge">
              {displayLowestDiscountedPrice.displayPrice}
            </Typography>
          )}
          <Typography variant="smallText">{t("product.ex_vat")}</Typography>
        </Box>
      </Box>
      {linkText && (
        <Box pb={{ xs: 2, sm: 2, md: 0 }}>
          <Button onClick={handleAddItemsToCart}>{linkText}</Button>
        </Box>
      )}
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints, common: { themeColors } }) => ({
  text: {
    "& > div p": {
      color: themeColors.white
    },
    "& span": {
      color: themeColors.white
    },
    "& h2": {
      fontSize: 40,
      [breakpoints.down("sm")]: {
        fontSize: 36
      },
      [breakpoints.down("xs")]: {
        fontSize: 28
      },
      color: themeColors.white
    }
  },
  discount: {
    fontWeight: 400,
    textDecoration: "line-through",
    opacity: 0.8
  }
}))

export default TextContent
