import { useEffect, useMemo } from "react"
import useStore from "global-hook-store"

import AppBar from "@material-ui/core/AppBar"

import useDebouncedResizeObserver from "utils/CustomeHooks/useDebouncedResizeObserver"

import HeaderContent from "common/Header/HeaderContent"
import { useHeaderStore } from "store/HeaderStore"
import pageStore from "store/PageStore"
import SubHeaderExplore from "./SubHeader/Explore"
import CheckoutHeader from "./CheckoutHeader"
import HideOnScroll from "./HideOnScroll"
import UpperSaleBanner from "./UpperSaleBanner"

const Header: React.FC = () => {
  const { set: headerSet } = useHeaderStore()
  const { ref: fixedHeaderRef, height: fixedHeaderHeight } =
    useDebouncedResizeObserver(500)

  const {
    state: { page }
  } = useStore(pageStore)

  const memoizedPageData = useMemo(() => page.data, [page.data])
  const memoizedFixedHeaderHeight = useMemo(
    () => fixedHeaderHeight,
    [fixedHeaderHeight]
  )

  const isCheckoutPage =
    memoizedPageData && memoizedPageData._type === "CheckoutPage"
  const isExplorePage =
    memoizedPageData && memoizedPageData._type === "ExplorePage"
  const exploreMenu = memoizedPageData && memoizedPageData.exploreMenu

  useEffect(() => {
    if (memoizedFixedHeaderHeight) {
      headerSet.headerHeight(memoizedFixedHeaderHeight)
    }
  }, [memoizedFixedHeaderHeight, headerSet])

  return (
    <HideOnScroll>
      <AppBar component="header" ref={fixedHeaderRef}>
        <UpperSaleBanner />
        {isCheckoutPage ? <CheckoutHeader /> : <HeaderContent />}
        {isExplorePage && exploreMenu && (
          <SubHeaderExplore menu={exploreMenu} />
        )}
      </AppBar>
    </HideOnScroll>
  )
}

export default Header
