import { Variation } from "@starrepublic/epi/api/products"
import { AsyncState, asyncState, createStore } from "global-hook-store"

import variationComparator from "utils/variationComparator"

import pricingDetailsApi, { PricingDetailsModel } from "api/pricingDetails"
import productsApi from "api/products"
import Variant from "models/Variant"

type Conditions = {
  baseValue: string
  currency: string
  itemNumber: null
  type: string
  unit: string
  value: string
  value2: string
}

export type NetPriceModel = {
  conditions: Conditions[]
  netPriceString: string
  articleNumber: string
}

type ProductStoreType = {
  productVariations: Array<Variation<Variant>>
  selectedVariant: Variation<Variant>
  selectedVariantId: number
  error?: string
  isFetching: boolean
  pricingDetails: any
  netPriceModalIsOpen: boolean
  netPrices: AsyncState<NetPriceModel[]>
}

const priceAscending = (a: Variation<Variant>, b: Variation<Variant>) => {
  if (a._displayPrice === null || b._displayPrice === null) {
    return 0
  }
  return a._displayPrice.amount - b._displayPrice.amount
}

const ProductStore = createStore(
  {
    pricingDetails: asyncState<PricingDetailsModel>(),
    netPrices: asyncState<NetPriceModel[]>(),
    productVariations: [],
    isFetching: true,
    selectedVariantId: 0,
    netPriceModalIsOpen: false,

    get selectedVariant() {
      if (this.selectedVariantId === 0) {
        return this.productVariations[0] || new Variant()
      }

      for (const variation of this.productVariations) {
        if (variation._id === this.selectedVariantId) return variation
      }
    }
  } as ProductStoreType,
  {
    fetchVariations: async (currentState, productId: number) => {
      try {
        const response = await productsApi.getVariations(productId)

        const sortedVariations = response.value.sort(variationComparator)

        const variantsInStock = response.value.filter((v) => {
          return v.inventoryStatus !== "NOT_IN_STOCK"
        })

        let selectedVariantId = 0

        if (response.value.length > 0) {
          selectedVariantId =
            variantsInStock.length > 0
              ? variantsInStock.sort(priceAscending)[0]._id
              : sortedVariations.sort(priceAscending)[0]._id
        }

        return {
          ...currentState,
          selectedVariantId,
          productVariations: sortedVariations,
          isFetching: false
        }
      } catch (err) {
        return { ...currentState, err, isFetching: false }
      }
    },
    changeVariation: (currentState, variantId: number) => ({
      ...currentState,
      selectedVariantId: variantId
    }),
    getPricingDetails: async (_state, _payload: string, { asyncAction }) =>
      asyncAction(
        "pricingDetails",
        pricingDetailsApi.getPricingDetails(_payload)
      ),
    open: (state, _: null) => {
      return { ...state, netPriceModalIsOpen: true }
    },
    close: (state, _: null) => {
      return { ...state, netPriceModalIsOpen: false }
    },
    getNetPrices: async (_state, payload: string[], { asyncAction }) =>
      asyncAction("netPrices", pricingDetailsApi.getNetPrices(payload)),
    resetProduct: (_, __: null, { reset }) => reset()
  }
)

export default ProductStore
