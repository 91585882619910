import { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const Delivered: React.FC<Props> = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C12.411 16 16 12.411 16 8C16 3.589 12.411 0 8 0ZM8 14C4.691 14 2 11.309 2 8C2 4.691 4.691 2 8 2C11.309 2 14 4.691 14 8C14 11.309 11.309 14 8 14Z"
      fill="#77AD1C"
    />
    <path
      d="M6.99994 11.4139L3.58594 7.99994L4.99994 6.58594L6.99994 8.58594L10.9999 4.58594L12.4139 5.99994L6.99994 11.4139Z"
      fill="#77AD1C"
    />
  </svg>
)

export default Delivered
