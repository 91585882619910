import { useTranslation } from "react-i18next"
import { Formik, FormikActions } from "formik"
import useStore from "global-hook-store"

import { EnergyCalculatorContactPayload } from "api/energyCalculatorContact"
import EnergyCalculatorStore from "store/EnergyCalculatorStore"
import CalculatorContact from "./CalculatorContact"
import validationSchema from "./validationSchema"

const initialValues = {
  name: "",
  phone: "",
  postalCode: "",
  acceptPrivacyPolicy: false
}

const CalculactionForm: React.FC = () => {
  const { t } = useTranslation()

  const {
    state: { enableEmailInContactForm },
    actions: { send: energyCalculatorContact }
  } = useStore(EnergyCalculatorStore)

  const handleSubmit = async (
    values: EnergyCalculatorContactPayload,
    { setSubmitting }: FormikActions<EnergyCalculatorContactPayload>
  ) => {
    const payload = {
      name: values.name,
      phone: values.phone,
      postalCode: values.postalCode,
      acceptPrivacyPolicy: values.acceptPrivacyPolicy
    } as EnergyCalculatorContactPayload

    energyCalculatorContact({ ...payload, email: values.email }).then(() => {
      setSubmitting(false)
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur
      component={CalculatorContact}
      validationSchema={validationSchema(t, enableEmailInContactForm)}
    />
  )
}

export default CalculactionForm
