import { ComponentType, FC } from "react"

const GenericRenderer: FC<{
  component?: ComponentType
  [key: string]: any
}> = ({ children, component: Component, ...props }) => {
  if (!Component) {
    return <div {...props}>{children}</div>
  }

  return <Component {...props}>{children}</Component>
}

export { GenericRenderer }
