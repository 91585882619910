import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"
import i18nCountries from "i18n-iso-countries"

import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import { Order } from "api/orders"
import Returns from "common/Returns"
import Typography from "common/Typography"
import Delivered from "icons/Delivered"
import LaunchApp from "icons/LaunchApp"
import Pending from "icons/Pending"
import ReOrder from "icons/ReOrder"
import { OrderStatus } from "models/Order"
import OrderStore from "store/OrderStore"
import LineItems from "./LineItems"
import OrderSummary from "./OrderSummary"

type Props = {
  order: Order | null
}

const OrderContent: React.FC<Props> = ({ order }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { reorderCart },
    actions: { reorder }
  } = useStore(OrderStore)

  const shippingAddress =
    (order && order.shipments && order.shipments[0].shippingAddress) || null

  const shippingMethod =
    (order && order.shipments && order.shipments[0].shippingMethod) || null

  const shippingTrackingUrls =
    order && order.properties.shippingTrackingUrls
      ? order.properties.shippingTrackingUrls.split(";")
      : null

  const handleReorder = async () => {
    if (!reorderCart.loading && order) {
      await reorder(order.orderGroupId)
    }
  }

  return (
    <>
      {order && (
        <Grid container>
          <div className={classes.linkWrapper}>
            <div className={classes.orderAction}>
              <Returns orderRow={order} />
            </div>
            <div className={classes.orderAction}>
              <Typography
                onClick={handleReorder}
                variant="link3"
                className={classes.link}
              >
                <ReOrder
                  className={classNames(classes.reOrderIcon, {
                    [classes.loading]: reorderCart.loading
                  })}
                />
                {t("account.re_order")}
              </Typography>
            </div>
          </div>

          <Grid
            item
            xs={12}
            className={classNames(classes.shippingAddress, classes.sideBars)}
          >
            <Grid container>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="smallText" className={classes.label}>
                        {t("account.order_status_label")}
                      </Typography>
                    </TableCell>

                    <TableCell
                      className={classNames(
                        classes.addressTable,
                        classes.tableCell
                      )}
                    >
                      <Typography variant="body2">
                        {order.status === OrderStatus.completed ? (
                          <>
                            <Delivered className={classes.marginRight} />
                            {t("account.delivered")}
                          </>
                        ) : (
                          <>
                            <Pending className={classes.marginRight} />
                            {t("account.pending")}
                          </>
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="smallText" className={classes.label}>
                        {t("account.order_shipping_adress_label")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={classNames(
                        classes.addressTable,
                        classes.tableCell
                      )}
                    >
                      <Typography variant="body2">
                        {shippingAddress && shippingAddress.line1}
                        {`, ${shippingAddress && shippingAddress.zipCode} ${
                          shippingAddress && shippingAddress.city
                        } `}
                        {shippingAddress &&
                          i18nCountries.getName(
                            shippingAddress.countryCode,
                            "en"
                          )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow />
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="flex-end"
            className={classNames(classes.orderReference, classes.sideBars)}
          >
            <Table size="small">
              <TableBody>
                {order.properties.customerOrderReference ? (
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography className={classes.label} variant="smallText">
                        {t("checkout.order_reference")}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="body2">
                        {order.properties.customerOrderReference}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow />
                )}
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="smallText" className={classes.label}>
                      {t("account.order_shipping_method_label")}
                    </Typography>
                  </TableCell>

                  <TableCell
                    className={classNames(
                      classes.addressTable,
                      classes.tableCell
                    )}
                  >
                    <Grid container>
                      <Typography
                        className={classes.marginRight}
                        variant="body2"
                      >
                        {shippingMethod}
                      </Typography>
                      {shippingTrackingUrls !== null &&
                        shippingTrackingUrls.map((url, i) =>
                          url.length > 0 ? (
                            <div key={i}>
                              <a
                                href={url}
                                target="_blank"
                                rel="noreferrer noopener"
                              >
                                <Typography
                                  variant="link3"
                                  className={classes.trackingLink}
                                >
                                  {`${t("account.tracking_link_text")} ${
                                    shippingTrackingUrls.length > 1
                                      ? `#${i + 1}`
                                      : ""
                                  }`}{" "}
                                  <LaunchApp />
                                </Typography>
                              </a>
                            </div>
                          ) : null
                        )}
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <OrderSummary order={order} />
          <Grid className={classes.itemsContainer} item xs={12}>
            <LineItems items={order.lineItems} />
          </Grid>
        </Grid>
      )}
    </>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors, gridSpacing } }) => ({
    itemsContainer: {
      marginTop: gridSpacing
    },
    shippingAddress: {
      border: `1px solid ${themeColors.whiteGray}`,
      marginBottom: gridSpacing,
      marginTop: gridSpacing
    },
    sideBars: {
      padding: `${gridSpacing}px 0px`,
      width: "100%"
    },
    label: {
      whiteSpace: "nowrap",
      color: themeColors.gray
    },
    mobileSummary: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: gridSpacing,
      width: "100%"
    },
    orderReference: {
      backgroundColor: themeColors.offWhite,
      border: `1px solid ${themeColors.whiteGray}`,
      marginBottom: gridSpacing
    },
    trackingLink: {
      display: "inline-block"
    },
    orderAction: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    verticalSeparator: {
      borderLeft: `2px solid ${themeColors.lightGray}`,
      height: spacing(2),
      marginRight: spacing(2) + 4,
      marginLeft: spacing(2) + 4
    },
    reOrderIcon: {
      marginRight: spacing(1)
    },
    viewOrderDetails: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: gridSpacing
    },
    link: {
      borderLeft: `1px solid ${themeColors.mediumGray}`,
      paddingLeft: gridSpacing / 2,
      cursor: "pointer"
    },
    "@keyframes rotate": {
      "0%": { transform: "rotate(0deg)" },
      "100%": { transform: "rotate(360deg)" }
    },
    loading: {
      animationName: "$rotate",
      animationIterationCount: "infinite",
      animationTimingFunction: "linear",
      animationDuration: "1s"
    },
    linkWrapper: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "flex-end"
    },
    tableCell: {
      border: "none"
    },
    addressTable: {
      width: "100%"
    },
    marginRight: {
      marginRight: spacing(1)
    }
  })
)

export default OrderContent
