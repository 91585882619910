import { useRef } from "react"
import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"
import useResizeObserver from "use-resize-observer"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { blockDisplay } from "utils/blockDisplay"

import BodyContent from "blocks/BodyContent"
import BlockGrid from "common/BlockGrid"
import ResponsiveImage from "common/ResponsiveImage"
import theme from "theme"
import { BodyContent as BodyContentType } from "types"
import { CollageBlockContent } from "./types"

const positions = {
  top: "Top",
  bottom: "Bottom",
  right: "Right",
  left: "Left",
  center: "Center"
}

type Props = BlockProps<CollageBlockContent & BodyContentType>

const CollageBlock: React.FC<Props> = ({ content, blockInfo, propsInfo }) => {
  const _display = blockDisplay(blockInfo)
  const { image, backgroundColor, contentAlignment, horizontalImageAlignment } =
    content

  const classes = useStyles()

  const isSmDown = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"))
  const isPageMaxWidthDown = useMediaQuery(({ breakpoints }) =>
    breakpoints.down(theme.common.pageMaxWidth + theme.spacing(2))
  )
  const contentRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const verticalStandView = _display.oneThird || _display.half

  const contentToTheLeft =
    contentAlignment && contentAlignment.text === positions.left ? true : false

  const { height: contentHeight } = useResizeObserver({
    ref: contentRef
  })
  const { height: containerHeight } = useResizeObserver({
    ref: containerRef
  })
  const calculateMargin = () => {
    if (containerHeight && contentHeight && contentHeight > containerHeight) {
      return (contentHeight - containerHeight) / 2 / 8
    }
  }

  return (
    <Box clone my={isSmDown ? 0 : calculateMargin()}>
      <Grid
        ref={containerRef}
        container
        justifyContent={contentToTheLeft ? "flex-end" : "flex-start"}
        style={{ backgroundColor }}
        className={classNames(classes.root, {
          [classes.verticalStand]: verticalStandView
        })}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={
            verticalStandView
              ? 12
              : isPageMaxWidthDown && _display.twoThird
                ? 12
                : 7
          }
        >
          {image && (
            <ResponsiveImage
              className={classNames(classes.media, {
                [classes.alignmentTop]:
                  horizontalImageAlignment &&
                  horizontalImageAlignment.text === positions.top,
                [classes.alignmentCenter]:
                  horizontalImageAlignment &&
                  horizontalImageAlignment.text === positions.center,
                [classes.alignmentBottom]:
                  horizontalImageAlignment &&
                  horizontalImageAlignment.text === positions.bottom
              })}
              containerProps={{
                className:
                  _display.twoThird || _display.oneThird
                    ? classes.narrowMadiaContainer
                    : contentToTheLeft
                      ? classes.mediaContainerRight
                      : classes.mediaContainerLeft
              }}
              image={image}
            />
          )}
        </Grid>
        <BlockGrid
          xs={12}
          item
          container
          direction="column"
          justifyContent={"center"}
          className={classNames(classes.bodyContentContainerRight, {
            [classes.bodyContentContainerSmall]:
              isSmDown ||
              verticalStandView ||
              (isPageMaxWidthDown && _display.twoThird),
            [classes.bodyContentContainerLeft]: contentToTheLeft
          })}
        >
          <Box
            {...{ ref: contentRef }}
            zIndex={1}
            pr={
              !contentToTheLeft && !verticalStandView
                ? {
                    xs: 0,
                    sm: 0,
                    md: 6,
                    lg: 8
                  }
                : 0
            }
            pl={
              contentToTheLeft && !verticalStandView
                ? {
                    xs: 0,
                    sm: 0,
                    md: 6,
                    lg: 8
                  }
                : 0
            }
            className={classNames({
              [classes.fullWidthWrapper]: _display.full,
              [classes.noPadding]: isPageMaxWidthDown && _display.twoThird
            })}
            justifyContent={contentToTheLeft ? "start" : "end"}
          >
            {content && (content.body || content.title || content.ctaText) && (
              <Box maxWidth="540px" minHeight="268px">
                <BodyContent
                  border
                  content={{
                    ...content,
                    link: content.ctaLink,
                    linkText: content.ctaText,
                    horizontalAlignment: { value: 0, text: positions.left }
                  }}
                  propsInfo={propsInfo}
                  backgroundColor={"#ffffff"}
                  display={blockInfo!["_display"]}
                  buttonAlignment={"start"}
                />
              </Box>
            )}
          </Box>
        </BlockGrid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(
  ({
    breakpoints,
    spacing,
    common: { pageMaxWidth, aspectRatio16x9, aspectRatio4x3 }
  }) => ({
    root: {
      position: "relative",
      alignItems: "center"
    },
    verticalStand: {
      height: "100%",
      alignItems: "flex-start",
      alignContent: "flex-start"
    },
    fullWidthWrapper: {
      maxWidth: pageMaxWidth,
      width: "100%",
      margin: "0 auto",
      display: "flex",
      [breakpoints.down("md")]: {
        maxWidth: breakpoints.values.md
      },
      [breakpoints.down("sm")]: {
        maxWidth: "fit-content"
      }
    },
    mediaContainerLeft: {
      aspectRatio: aspectRatio16x9,
      height: "100%",
      display: "block",
      left: 0,
      [breakpoints.down("md")]: {
        aspectRatio: aspectRatio4x3
      },
      [breakpoints.down("sm")]: {
        aspectRatio: aspectRatio16x9
      },
      [breakpoints.down("xs")]: {
        aspectRatio: aspectRatio4x3,
        position: "relative"
      }
    },
    mediaContainerRight: {
      aspectRatio: aspectRatio16x9,
      height: "100%",
      display: "block",
      right: 0,
      [breakpoints.down("md")]: {
        aspectRatio: aspectRatio4x3
      },
      [breakpoints.down("sm")]: {
        aspectRatio: aspectRatio16x9
      },
      [breakpoints.down("xs")]: {
        aspectRatio: aspectRatio4x3,
        position: "relative"
      }
    },
    narrowMadiaContainer: {
      aspectRatio: aspectRatio4x3,
      position: "relative"
    },
    media: {
      display: "block",
      width: "100%",
      height: "100%",
      objectFit: "cover"
    },
    bodyContentContainerRight: {
      alignItems: "end",
      position: "absolute"
    },
    bodyContentContainerLeft: {
      alignItems: "start",
      position: "absolute"
    },
    bodyContentContainerSmall: {
      padding: spacing(0, 2, 5, 2),
      alignItems: "center",
      position: "relative",
      margin: "0 auto",
      marginTop: "-15%"
    },
    alignmentTop: {
      objectPosition: "top"
    },
    alignmentCenter: {
      objectPosition: "center"
    },
    alignmentBottom: {
      objectPosition: "bottom"
    },
    noPadding: {
      padding: 0
    }
  })
)

export default CollageBlock
