import { useEffect, useRef, useState } from "react"
import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"
import useResizeObserver from "use-resize-observer"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { blockDisplay } from "utils/blockDisplay"
import { contrastSwitch } from "utils/contrastSwitch"
import { useMediaQueries } from "utils/CustomeHooks/useMediaQueries"
import { scrollToElement } from "utils/ui/scroll"

import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import { pageMaxWidth } from "theme"
import LinkOrButton from "./LinkOrButton"
import MediaRenderer from "./MediaRenderer"
import { TextAndVideoBlockContent } from "./types"

type Props = {
  showOneImage: boolean
  isCarousel?: boolean
} & BlockProps<TextAndVideoBlockContent>
const TextAndMediaBlock: React.FC<Props> = ({
  content,
  blockInfo,
  showOneImage,
  isCarousel
}) => {
  const classes = useStyles()
  const {
    title,
    body,
    theme,
    link,
    linkText,
    contentPosition,
    images,
    video,
    preTitle,
    titleShouldBeH1,
    showAsLinkButton = true,
    showAsPrimaryButton = false,
    isCarousel: isAlwaysCarousel
  } = content

  const _display = blockDisplay(blockInfo)
  const isBottom = contentPosition === "bottom"

  const contentRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [totalContentHeight, setTotalContentHeight] = useState(0)

  const { isMdUp, isSmDown } = useMediaQueries()

  const isHalf = _display.half || _display.twoThird
  const isAuto = _display.auto
  const isOneThird = _display.oneThird
  const useAlternativeFullLayout = contentPosition === "right" ? true : false
  const useAlternativeFullLayoutLeft = contentPosition === "left" ? true : false
  const isCarouselLeftFull = isCarousel && useAlternativeFullLayout && isMdUp
  const isCarouselRightFull = isCarousel && contentPosition === "left" && isMdUp

  const anchorLink = link && link.split("#").pop()

  const scrollToEl = () => {
    anchorLink && scrollToElement(anchorLink)
  }

  const { height: contentHeight } = useResizeObserver({
    ref: contentRef
  })

  useEffect(() => {
    if (contentRef.current && contentHeight) {
      const paddingTop = parseFloat(
        window.getComputedStyle(contentRef.current).paddingTop
      )
      const paddingBottom = parseFloat(
        window.getComputedStyle(contentRef.current).paddingBottom
      )
      setTotalContentHeight(contentHeight + paddingTop + paddingBottom)
    }
  }, [contentHeight, contentRef])

  const isImage = images && images.length > 1
  const isVideo = video && video.value
  const containerWidth = pageMaxWidth / 2 // Calculates the width of the container by dividing the maximum page width (pageMaxWidth) by 2.
  const videoRatio = 16 / 9 // Defines the aspect ratio for videos as 16:9.
  const imageRatioAuto = 4 / 3 // Defines the aspect ratio for images as 4:3.
  const typeOfMedia = isVideo ? videoRatio : imageRatioAuto // Determines the aspect ratio to use based on whether the media is a video or an image.
  const calcHeight = containerWidth / typeOfMedia // Calculates the height of the media content by dividing the container width by the chosen aspect ratio (typeOfMedia).
  const isContentBigger = totalContentHeight > calcHeight && isAuto && isMdUp // Determines if the total content height is greater than the calculated height for the media content.

  return (
    <Box
      className={classNames({
        [classes.fullWidthContainer]: _display.full
      })}
      height="100%"
      bgcolor={theme}
      p={
        showOneImage ||
        isHalf ||
        isBottom ||
        isOneThird ||
        isVideo ||
        isAlwaysCarousel ||
        isSmDown
          ? 0
          : isImage
            ? 2
            : 0
      }
    >
      <Grid
        container
        justifyContent="center"
        className={classNames(classes.root, {
          [classes.fullWidth]: _display.full,
          [classes.alternativeLayout]: useAlternativeFullLayout,
          [classes.alternativeLayoutFalse]: !useAlternativeFullLayout,
          [classes.setItemsTop]: isContentBigger
        })}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={isHalf || isOneThird || isBottom ? 12 : 6}
          ref={containerRef}
          className={classNames({
            [classes.mediumPaddingRight]:
              isContentBigger && useAlternativeFullLayout,
            [classes.mediumPaddingLeft]:
              isContentBigger && useAlternativeFullLayoutLeft
          })}
        >
          <MediaRenderer
            content={content}
            showOneImage={showOneImage}
            isAlwaysCarousel={isAlwaysCarousel}
            isHalf={isHalf}
            isBottom={isBottom}
            isOneThird={isOneThird}
          />
        </Grid>
        {(title || body) && (
          <Grid
            ref={contentRef}
            item
            xs={12}
            sm={12}
            md={isHalf || isOneThird || isBottom ? 12 : 6}
            className={classNames(classes.bodyContentContainer, {
              [classes.oneThirdContainerWithNoBackgroundColor]:
                !theme && isOneThird,
              [classes.oneThirdContainerNoTitle]: !title,
              [classes.largeLeftPadding]: isCarouselLeftFull,
              [classes.largeRightPadding]: isCarouselRightFull
            })}
          >
            <Box maxWidth={isHalf || isBottom ? "100%" : 580}>
              {preTitle && (
                <Typography
                  textWrap="wrap"
                  className={classNames(
                    classes.preTitle,
                    classes[
                      contrastSwitch({
                        variant: "preTitle",
                        contrastColor: theme
                      })
                    ],
                    { [classes.preTitleSmall]: isOneThird || isHalf }
                  )}
                  variant="headline4"
                >
                  {preTitle}
                </Typography>
              )}
              {title && (
                <Typography
                  textWrap="wrap"
                  className={classNames(
                    classes.title,
                    classes[
                      contrastSwitch({
                        variant: "title",
                        contrastColor: theme
                      })
                    ],
                    {
                      [classes.oneThirdHeadline]: isOneThird
                    }
                  )}
                  variant={titleShouldBeH1 ? "headline1" : "headline3"}
                >
                  {title}
                </Typography>
              )}
              {body && (
                <Box
                  mt={!title ? 2 : 0}
                  pb={!title && isBottom ? 0.5 : 0}
                  className={
                    classes[
                      contrastSwitch({
                        variant: "body",
                        contrastColor: theme
                      })
                    ]
                  }
                >
                  <ThemeXhtmlString sectionTheme={theme} content={body} />
                </Box>
              )}
              <LinkOrButton
                linkText={linkText}
                link={link}
                showAsLinkButton={showAsLinkButton}
                showAsPrimaryButton={showAsPrimaryButton}
                theme={theme}
                onClick={scrollToEl}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors } }) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [breakpoints.down("xs")]: {
        marginRight: 0,
        marginLeft: 0
      }
    },
    preTitle: {
      fontSize: 24,
      lineHeight: "130%",
      color: themeColors.colorBaseCyan900,
      paddingBottom: spacing(0.5),
      textTransform: "uppercase",
      [breakpoints.down("sm")]: {
        fontSize: 20
      }
    },
    preTitleSmall: {
      fontSize: 16,
      lineHeight: "130%",
      paddingBottom: spacing(0.5),
      [breakpoints.down("sm")]: {
        fontSize: 20
      }
    },
    title: {
      fontSize: 40,
      color: themeColors.primaryBlue,
      marginBottom: spacing(2),
      [breakpoints.down("sm")]: {
        fontSize: "28px",
        lineHeight: "36px"
      }
    },
    oneThirdHeadline: {
      fontSize: "28px"
    },
    fullWidth: {
      flexDirection: "row",
      alignItems: "center"
    },
    fullWidthContainer: {
      margin: spacing(1.5, 0)
    },
    alternativeLayout: {
      flexDirection: "row-reverse"
    },
    alternativeLayoutFalse: {
      flexDirection: "row"
    },
    bodyContentContainer: {
      height: "100%",
      width: "100%",
      padding: spacing(4, 4),
      [breakpoints.up("md")]: {
        padding: spacing(4, 4)
      },
      [breakpoints.down("sm")]: {
        padding: spacing(2)
      }
    },
    ["text-base-white"]: {
      color: themeColors.white
    },
    ["text-base-cyan-500"]: {
      color: themeColors.colorBaseCyan500
    },
    ["text-base-blue-700"]: {
      color: themeColors.colorBaseBlue700
    },
    ["text-base-blue-800"]: {
      color: themeColors.colorBaseBlue800
    },
    ["text-base-blue-900"]: {
      color: themeColors.colorBaseBlue900
    },
    isHalf: {
      margin: spacing(1)
    },
    oneThirdContainerWithNoBackgroundColor: {
      padding: spacing(4, 2)
    },
    oneThirdContainerNoTitle: {
      padding: spacing(1, 4)
    },
    largeLeftPadding: {
      paddingLeft: spacing(9)
    },
    largeRightPadding: {
      paddingRight: spacing(9)
    },
    mediumPaddingRight: {
      paddingTop: spacing(4),
      paddingBottom: spacing(4),
      paddingRight: spacing(4)
    },
    mediumPaddingLeft: {
      paddingTop: spacing(4),
      paddingBottom: spacing(4),
      paddingLeft: spacing(4)
    },
    setItemsTop: {
      alignItems: "start"
    }
  })
)

export default TextAndMediaBlock
