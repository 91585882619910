import { useEffect, useState } from "react"
import { ContentProps } from "@starrepublic/epi/cms"
import useStore from "global-hook-store"
import qs from "qs"

import { makeStyles } from "@material-ui/core/styles"

import Wrapper from "common/Wrapper"
import authStore from "store/AuthStore"
import CartStore from "store/CartStore"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import OrderDetails from "./Sections/OrderDetails"
import PromoCode from "./Sections/PromoCode"
import Checkout from "./Checkout"
import Confirmation from "./Confirmation"
import Error from "./Error"
import Loading from "./Loading"

type OwnProps = ContentProps

type Props = OwnProps

const CheckoutPage: React.FC<Props> = () => {
  const classes = useStyles()
  const [isCheckout, setIsCheckout] = useState(true)

  const {
    fetchingCheckout,
    checkout,
    getConfirmedOrder,
    confirmedOrder,
    set: checkoutSet,
    reset: checkoutReset
  } = useCheckoutPaymentStore()

  const {
    state: { cart },
    actions: { getByCartTypeName }
  } = useStore(CartStore)

  const {
    actions: { getUser }
  } = useStore(authStore)

  useEffect(() => {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })

    if (query && query.order) {
      setIsCheckout(false)
      getConfirmedOrder(query.order.toString())
    } else {
      fetchingCheckout()
    }

    if (query && query.paymentStatus && query.paymentStatus === "failed") {
      checkoutSet.checkoutError("payment_status_failed")
    }

    if (query && query.paymentStatus && query.paymentStatus === "error") {
      checkoutSet.checkoutError("payment_status_error")
    }

    getUser()

    return () => {
      checkoutReset.confirmedOrder()
      checkoutReset.checkout()
      checkoutReset.preparedCheckout()
      checkoutReset.selectedShippingAddress()
      checkoutReset.orderReference()
      checkoutReset.checkoutError()
      getByCartTypeName("basket")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLoading = isCheckout
    ? checkout.loading || (!checkout.data && !checkout.error)
    : confirmedOrder.loading

  const noLineItemsInCart = isCheckout
    ? cart.data && cart.data.lineItems.length === 0
    : isCheckout

  return isLoading || noLineItemsInCart ? (
    <Wrapper
      containerClassName={classes.wrapper}
      className={classes.wrapperRoot}
    >
      <OrderDetails />
      <PromoCode />

      {!noLineItemsInCart && <Loading />}
    </Wrapper>
  ) : checkout.error || confirmedOrder.error ? (
    <Error error={checkout.error || confirmedOrder.error || ""} />
  ) : confirmedOrder.data ? (
    <Confirmation />
  ) : (
    <>
      <Checkout />
    </>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  wrapperRoot: {
    background: themeColors.offWhite
  },
  wrapper: {
    background: themeColors.offWhite
  }
}))

export default CheckoutPage
