import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const UsersDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M28.274 29.334a8.674 8.674 0 0 0-5.257-7.974M4.264 29.334a8.672 8.672 0 0 1 17.344 0m.702-22.329a5.708 5.708 0 0 1-1.648 10.948m-1.572-7.706a6.154 6.154 0 1 1-12.308 0 6.154 6.154 0 0 1 12.308 0Z"
      fill="none"
    />
  </SvgIcon>
)

export default UsersDeLaval
