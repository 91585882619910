import { FC } from "react"
import classNames from "classnames"

import { makeStyles, Theme } from "@material-ui/core/styles"
import MuiTypography, { TypographyProps } from "@material-ui/core/Typography"
import { CSSProperties } from "@material-ui/styles"

import { Omit } from "utils/types"

import {
  customToMuiVariantMapping,
  customTypography,
  CustomVariant
} from "theme/typography"

const componentMapping: {
  [key in CustomVariant]: React.ElementType<React.HTMLAttributes<HTMLElement>>
} = {
  headline1: "h1",
  headline2: "h2",
  headline3: "h3",
  headline4: "h4",
  headline5: "h5",
  headline6: "h6",
  subheading1: "h4",
  preamble: "h5",
  body: "p",
  body1: "p",
  body2: "p",
  body3: "p",
  link1: "span",
  link2: "span",
  link3: "span",
  link4: "span",
  label: "label",
  badge: "span",
  caption: "caption",
  button1: "span",
  priceLarge: "span",
  priceMedium: "span",
  priceSmall: "span",
  priceTiny: "span",
  priceDiscount: "span",
  smallText: "p",
  listElement1: "li",
  listElement2: "li",
  breadCrumbs: "span",
  tag: "span",
  th: "span",
  title: "h2"
}

type OwnProps = {
  variant?: CustomVariant
  className?: string
  isDarkTheme?: boolean
  bold?: boolean
  textWrap?: "wrap" | "balance" | "pretty" | "nowrap"
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>
} & Omit<TypographyProps, "variant">

type StyleProps = {
  textWrap: string
}

const Typography: FC<OwnProps> = ({
  component,
  children,
  className,
  paragraph,
  variant = "body1",
  isDarkTheme,
  bold,
  textWrap = "balance",
  ...other
}) => {
  const classes = useStyles({ textWrap })

  return typeof children !== "string" ? (
    <MuiTypography
      variant={customToMuiVariantMapping[variant]}
      component={component || componentMapping[variant]}
      className={classNames(className, classes.balance, classes[variant], {
        [classes.lightText]: isDarkTheme,
        [classes.bold]: bold
      })}
      {...other}
    >
      {children}
    </MuiTypography>
  ) : (
    <MuiTypography
      variant={customToMuiVariantMapping[variant]}
      component={component || componentMapping[variant]}
      className={classNames(className, classes.balance, classes[variant], {
        [classes.lightText]: isDarkTheme,
        [classes.bold]: bold
      })}
      {...other}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  )
}

const useStyles = makeStyles<Theme, StyleProps>(({ common }) => ({
  ...(customTypography as Record<CustomVariant, CSSProperties>),
  lightText: {
    color: common && common.themeColors.white
  },
  bold: {
    fontWeight: 700
  },
  balance: {
    textWrap: ({ textWrap }) => textWrap
  }
}))

export default Typography
