import { AsyncState, asyncState, createStore } from "global-hook-store"

import SubscriptionApi, {
  ActivateSubscriptionPayload,
  ActivateSubscriptionResponse
} from "api/subscriptions"
import Subscription, { Farm } from "models/DigitalService"

type SubscriptionStoreType = {
  open: boolean
  editingSubscription: Subscription | null
  mySubscriptions: AsyncState<Subscription[]>
  myFarms: AsyncState<Farm[]>
  activatedSubscription: AsyncState<ActivateSubscriptionResponse>
}
const subscriptionsStore = createStore(
  {
    open: false,
    editingSubscription: null,
    mySubscriptions: asyncState<Subscription[]>(),
    myFarms: asyncState<Farm[]>(),
    activatedSubscription: asyncState<ActivateSubscriptionResponse>()
  } as SubscriptionStoreType,
  {
    setOpen: (state, open: boolean) => {
      return { ...state, open }
    },
    setEditingSubscription: (state, editingSubscription: Subscription) => {
      return { ...state, editingSubscription }
    },
    getSubscriptions: (_, __: null, { asyncAction }) =>
      asyncAction("mySubscriptions", SubscriptionApi.getSubscriptions()),
    getFarms: (_, __: null, { asyncAction }) =>
      asyncAction("myFarms", SubscriptionApi.getFarms()),
    activateSubscription: async (
      _,
      payload: ActivateSubscriptionPayload,
      { asyncAction }
    ) =>
      asyncAction(
        "activatedSubscription",
        SubscriptionApi.activateSubscription(payload)
      ),
    resetActivatedSubscription: (_, __: null, { reset }) =>
      reset("activatedSubscription")
  }
)

export default subscriptionsStore
