import classNames from "classnames"

import Card from "@material-ui/core/Card"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import { useCheckoutPaymentStore } from "store/CheckoutStore"

type Props = { isSelected: boolean; name: string; id: string; onClick: any }

const PaymentMethod: React.FC<Props> = ({ isSelected, name, id, onClick }) => {
  const classes = useStyles()
  const { preparedCheckout } = useCheckoutPaymentStore()

  return (
    <Grid item xs={12} className={classes.gridItem}>
      <Card
        raised
        className={classNames(classes.cardContent, {
          [classes.activeCard]: isSelected
        })}
      >
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="paymentOptions"
            name="paymentOptions"
            value={id}
            id={`payment-method-${id.toLowerCase()}-${name
              .toLowerCase()
              .replace(" ", "-")}-option`}
            onClick={onClick}
          >
            <FormControlLabel
              classes={{
                root: classes.formControlLabel,
                label: classes.formControlLabel
              }}
              label={
                <div className={classes.labelContainer}>
                  <Typography variant="headline6">{name}</Typography>
                </div>
              }
              value={id}
              name="payment.option"
              control={
                <Radio
                  disabled={preparedCheckout.loading}
                  color="default"
                  classes={{
                    colorSecondary: classes.RadioSecondary,
                    checked: classes.RadioSecondary
                  }}
                  checked={isSelected}
                  onClick={onClick}
                />
              }
            />
          </RadioGroup>
        </FormControl>
      </Card>
    </Grid>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    margin: 0
  },
  gridItem: {},
  cardContent: {
    textAlign: "left",
    paddingLeft: spacing(3 - 1),
    borderLeft: `3px solid ${themeColors.white}`,
    borderBottom: `1px solid ${themeColors.lightGray}`,
    "&:last-child": {
      boxShadow: "none"
    }
  },
  activeCard: {
    borderLeft: `3px solid ${themeColors.primaryCyan}`,
    borderBottom: `1px solid ${themeColors.lightGray}`
  },
  labelContainer: {
    paddingBottom: spacing(3),
    paddingTop: spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  formControlLabel: {
    width: "100%"
  },
  RadioSecondary: {
    color: themeColors.primaryCyan
  }
}))

export default PaymentMethod
