import { Maybe } from "utils/types"

export type ApiEnumProperty = {
  value: number
  text: string
}

const apiEnumToValue = (property: Maybe<ApiEnumProperty>, defaultValue = "") =>
  !property || property.text == null
    ? defaultValue
    : property.text.toLowerCase()

export { apiEnumToValue }
