import { DynamicBlock } from "@starrepublic/epi/cms"

import Grid, { GridSize } from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import DisplayOptions from "utils/ui/DisplayOptions"

import Wrapper from "./Wrapper"

const displayOptionsGridConverter: (
  value: number,
  blockViewType: string
) => {
  [key: string]: GridSize
} = (value, blockViewType) => ({
  xs: 12,
  sm: 12,
  md: blockViewType === "gridView" ? 12 : ((value * 12) as GridSize),
  lg:
    (blockViewType === "gridView" && value < 0.5) ||
    (blockViewType === "gridView" && value > 0.5 && value < 1)
      ? 12
      : ((value * 12) as GridSize)
})

const ChildContainer: React.FC<{
  block: DynamicBlock
}> = ({ block, children }) => {
  return (
    <Grid
      style={{ width: "100%" }}
      key={block._id}
      item
      {...displayOptionsGridConverter(
        DisplayOptions[block._display!],
        block.listItemsViewType
      )}
    >
      {children}
    </Grid>
  )
}

const SectionComponent: React.FC = ({ children }) => {
  const classes = useStyles()
  return <section className={classes.sectionComponent}>{children}</section>
}

const ArticleComponent: React.FC = ({ children }) => (
  <article>{children}</article>
)

const ContainerWrapper: React.FC = ({ children }) => (
  <Wrapper grid>{children}</Wrapper>
)

const ContainerNarrowWrapper: React.FC = ({ children }) => (
  <Wrapper narrow grid>
    {children}
  </Wrapper>
)

const useStyles = makeStyles(({ common: { gridSpacing } }) => ({
  sectionComponent: {
    display: "flex",
    flexDirection: "column"
  }
}))

export {
  SectionComponent,
  ArticleComponent,
  ContainerWrapper,
  ContainerNarrowWrapper,
  ChildContainer
}
