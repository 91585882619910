import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const CheckboxCheckedForm: React.FC<Props> = (props) => (
  <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M3 3h18v18H3z" />
    <path d="m5 12.2 5 4.8 9-8.7L17.6 7 10 14.3l-3.6-3.5L5 12.2Z" fill="#fff" />
  </SvgIcon>
)

export default CheckboxCheckedForm
