import { create } from "zustand"
import { combine } from "zustand/middleware"

import {
  FenceCalculatorLabels,
  FenceDataItem,
  FenceProductVariant,
  TotalFenceItems
} from "blocks/FenceCalculatorBlock/types"

interface FenceCalculatorState {
  step: number
  setStep: (step: number) => void
  resetStepper: (step: number) => void
  clearValues: () => void
  animals: { animal: string }[]
  selectedAnimal: string
  setSelectedAnimal: (animal: string) => void
  categories: { category: string }[]
  selectedCategory: string
  setSelectedCategory: (category: string) => void
  geoLocation: { lat: number; lng: number }
  setGeoLocation: (coordinates: { lat: number; lng: number }) => void
  lengthOfFence: number
  setLengthOfFence: (length: number) => void
  numberOfWireRows: number
  setNumberOfWireRows: (rows: number) => void
  totalLengthOfFence: number
  setTotalLengthOfFence: (total: number) => void
  selectedWire: FenceProductVariant
  setSelectedWire: (wire: FenceProductVariant) => void
  numberOfWirePacks: number
  setNumberOfWirePacks: (packs: number) => void
  distanceBetweenPosts: number
  setDistanceBetweenPosts: (distance: number) => void
  numberOfPosts: number
  setNumberOfPosts: (posts: number) => void
  selectedPost: FenceProductVariant
  setSelectedPost: (post: FenceProductVariant) => void
  numberOfCornerPosts: number
  setNumberOfCornerPosts: (posts: number) => void
  numberOfFreeStandingGateOpenings: number
  setNumberOfFreeStandingGateOpenings: (openings: number) => void
  numberOfGateOpeningsInCorners: number
  setNumberOfGateOpeningsInCorners: (openings: number) => void
  selectedGateInsulator: FenceProductVariant
  gateInsulatorPackagesNeeded: number
  insulatorPackagesNeeded: number
  setSelectedGateInsulator: (gateInsulator: FenceProductVariant) => void
  selectedInsulator: FenceProductVariant
  setSelectedInsulator: (insulator: FenceProductVariant) => void
  selectedGateHandle: FenceProductVariant
  setSelectedGateHandle: (gateHandle: FenceProductVariant) => void
  selectedEnergizer: FenceProductVariant
  setSelectedEnergizer: (energizer: FenceProductVariant) => void
  powerSources: { powerSource: string }[]
  selectedEnergizerPowerSource: string
  setSelectedEnergizerPowerSource: (powerSource: string) => void
  selectedAccessories: FenceDataItem[]
  setSelectedAccessories: ({
    itemCode,
    quantity,
    displayName,
    price
  }: FenceDataItem) => void
  selectedProducts: FenceDataItem[]
  setSelectedProducts: ({
    itemKey,
    itemCode,
    quantity,
    displayName,
    price
  }: FenceDataItem) => void
  totalItems: TotalFenceItems[]
  setTotalItems: () => void
  fenceCalculatorLabels: FenceCalculatorLabels
  setFenceCalculatorLabels: (labels: FenceCalculatorLabels) => void
  maxCapacityExceeded: boolean
  setMaxCapacityExceeded: (maxCapacityExceeded: boolean) => void
}

const initialStates = {
  step: 0,
  animals: [{ animal: "Cow" }, { animal: "Horse" }], // TODO: animal: "sheep" is unavailable for MVP - Add for later version
  selectedAnimal: "",
  categories: [{ category: "Permanent" }, { category: "Temporary" }],
  selectedCategory: "",
  geoLocation: { lat: 57.7089, lng: 11.9746 },
  lengthOfFence: 0,
  numberOfWireRows: 2,
  totalLengthOfFence: 0,
  selectedWire: {} as FenceProductVariant,
  numberOfWirePacks: 0,
  distanceBetweenPosts: 4,
  numberOfPosts: 0,
  selectedPost: {} as FenceProductVariant,
  numberOfCornerPosts: 0,
  numberOfFreeStandingGateOpenings: 0,
  numberOfGateOpeningsInCorners: 0,
  selectedGateInsulator: {} as FenceProductVariant,
  selectedInsulator: {} as FenceProductVariant,
  insulatorPackagesNeeded: 0,
  selectedGateHandle: {} as FenceProductVariant,
  gateInsulatorPackagesNeeded: 0,
  selectedEnergizer: {} as FenceProductVariant,
  powerSources: [{ powerSource: "Battery" }, { powerSource: "Mains" }],
  selectedEnergizerPowerSource: "Mains",
  selectedAccessories: [] as FenceDataItem[],
  selectedProducts: [] as FenceDataItem[],
  totalItems: [] as TotalFenceItems[],
  fenceCalculatorLabels: {} as FenceCalculatorLabels,
  maxCapacityExceeded: false
}

export const useFenceCalculatorStore = create<FenceCalculatorState>(
  combine(initialStates, (set, get) => ({
    setStep: (step) => set((state) => ({ step: (state.step = step) })),
    resetStepper: (step: number) =>
      set(() => ({ step, selectedAnimal: initialStates.selectedAnimal })),
    clearValues: () =>
      set(() => ({
        selectedAnimal: "",
        selectedCategory: "",
        geoLocation: { lat: 57.7089, lng: 11.9746 },
        lengthOfFence: 0,
        numberOfWireRows: 2,
        selectedWire: {} as FenceProductVariant,
        distanceBetweenPosts: 4,
        selectedPost: {} as FenceProductVariant,
        numberOfCornerPosts: 0,
        selectedGateInsulator: {} as FenceProductVariant,
        selectedInsulator: {} as FenceProductVariant,
        selectedGateHandle: {} as FenceProductVariant,
        numberOfFreeStandingGateOpenings: 0,
        numberOfGateOpeningsInCorners: 0,
        selectedEnergizer: {} as FenceProductVariant,
        selectedEnergizerPowerSource: "Mains",
        selectedAccessories: [],
        maxCapacityExceeded: false
      })),
    setSelectedAnimal: (animal: string) =>
      set(() => ({ selectedAnimal: animal })),
    setSelectedCategory: (category: string) =>
      set(() => ({ selectedCategory: category })),
    setGeoLocation: (coordinates: { lat: number; lng: number }) =>
      set(() => ({ geoLocation: coordinates })),
    setLengthOfFence: (length: number) =>
      set((state) => ({
        lengthOfFence: length,
        numberOfPosts: length / state.distanceBetweenPosts
      })),
    setNumberOfWireRows: (rows: number) =>
      set(() => ({ numberOfWireRows: rows })),
    setTotalLengthOfFence: (total: number) =>
      set(() => ({ totalLengthOfFence: total })),
    setSelectedWire: (wire: FenceProductVariant) =>
      set(() => ({
        selectedWire: wire
      })),
    setNumberOfWirePacks: (packs: number) =>
      set(() => ({ numberOfWirePacks: packs })),
    setDistanceBetweenPosts: (distance: number) =>
      set(() => ({ distanceBetweenPosts: distance })),
    setNumberOfPosts: (posts: number) => set(() => ({ numberOfPosts: posts })),
    setSelectedPost: (post: FenceProductVariant) =>
      set(() => ({ selectedPost: post })),
    setNumberOfCornerPosts: (posts: number) =>
      set(() => ({ numberOfCornerPosts: posts })),
    setNumberOfFreeStandingGateOpenings: (openings: number) =>
      set(() => ({ numberOfFreeStandingGateOpenings: openings })),
    setNumberOfGateOpeningsInCorners: (openings: number) =>
      set(() => ({ numberOfGateOpeningsInCorners: openings })),
    setSelectedGateInsulator: (gateInsulator: FenceProductVariant) =>
      set(() => ({ selectedGateInsulator: gateInsulator })),
    setSelectedInsulator: (insulator: FenceProductVariant) =>
      set(() => ({ selectedInsulator: insulator })),
    setSelectedGateHandle: (gateHandle: FenceProductVariant) =>
      set(() => ({ selectedGateHandle: gateHandle })),
    setSelectedEnergizer: (energizer: FenceProductVariant) =>
      set(() => ({ selectedEnergizer: energizer })),
    setSelectedEnergizerPowerSource: (powerSource: string) =>
      set(() => ({ selectedEnergizerPowerSource: powerSource })),
    setFenceCalculatorLabels: (fenceCalculatorLabels: FenceCalculatorLabels) =>
      set(() => ({ fenceCalculatorLabels })),
    setSelectedAccessories: ({ itemCode, quantity, displayName, price }) =>
      set((state) => {
        const existingAccessoriesIndex = state.selectedAccessories.findIndex(
          (selectedAccessories) => selectedAccessories.itemCode === itemCode
        )
        if (existingAccessoriesIndex === -1) {
          // Item does not exist, add a new one
          return {
            selectedAccessories: [
              ...state.selectedAccessories,
              {
                itemCode,
                quantity,
                displayName,
                price
              }
            ]
          }
        } else {
          // Item already exists, update it
          const updatedAccessories = [...state.selectedAccessories]
          if (quantity > 0) {
            updatedAccessories[existingAccessoriesIndex] = {
              ...updatedAccessories[existingAccessoriesIndex],
              quantity
            }
          } else {
            // Item exists but quantity is less then 0, so remove it
            updatedAccessories.splice(existingAccessoriesIndex, 1)
          }
          return {
            selectedAccessories: updatedAccessories
          }
        }
      }),
    setSelectedProducts: ({
      itemKey,
      itemCode,
      quantity,
      displayName,
      price
    }) =>
      set((state) => {
        const existingAccessoriesIndex = state.selectedProducts.findIndex(
          (selectedProducts) => selectedProducts.itemKey === itemKey
        )
        if (existingAccessoriesIndex === -1) {
          // Item does not exist, add a new one
          return {
            selectedProducts: [
              ...state.selectedProducts,
              {
                itemKey,
                itemCode,
                quantity,
                displayName,
                price
              }
            ]
          }
        } else {
          // Item already exists, update it
          const updatedAccessories = [...state.selectedProducts]
          if (quantity > 0) {
            updatedAccessories[existingAccessoriesIndex] = {
              ...updatedAccessories[existingAccessoriesIndex],
              quantity
            }
          } else {
            // Item exists but quantity is less then 0, so remove it
            updatedAccessories.splice(existingAccessoriesIndex, 1)
          }
          return {
            selectedProducts: updatedAccessories
          }
        }
      }),
    setTotalItems: () => {
      return set((state) => {
        const postsAndWireRows = state.numberOfPosts * state.numberOfWireRows
        const calculatedInsulatorNeeded =
          (state.numberOfFreeStandingGateOpenings +
            state.numberOfGateOpeningsInCorners) *
            state.numberOfWireRows +
          postsAndWireRows

        const insulatorPackagesNeeded =
          state.selectedInsulator &&
          state.selectedInsulator.variant &&
          Math.ceil(
            calculatedInsulatorNeeded / state.selectedInsulator.variant.quantity
          )

        const calculatedGateInsulatorNeeded =
          (state.numberOfFreeStandingGateOpenings +
            state.numberOfGateOpeningsInCorners) *
          state.numberOfWireRows

        const gateInsulatorPackagesNeeded =
          state.selectedGateInsulator &&
          state.selectedGateInsulator.variant &&
          Math.ceil(
            calculatedGateInsulatorNeeded /
              state.selectedGateInsulator.variant.quantity
          )

        const gateHandlePackagesNeeded =
          state.selectedGateHandle &&
          state.selectedGateHandle.variant &&
          Math.ceil(
            ((state.numberOfFreeStandingGateOpenings +
              state.numberOfGateOpeningsInCorners) *
              state.numberOfWireRows) /
              state.selectedGateHandle.variant.quantity
          )

        const totalItems = [
          {
            ...state.selectedWire,
            variant: {
              ...state.selectedWire.variant,
              quantity: state.numberOfWirePacks
            }
          },
          {
            ...state.selectedPost,
            variant: {
              ...state.selectedPost.variant,
              quantity: state.numberOfPosts
            }
          },
          {
            ...state.selectedGateInsulator,
            variant: {
              ...state.selectedGateInsulator.variant,
              quantity: gateInsulatorPackagesNeeded
            }
          },
          {
            ...state.selectedInsulator,
            variant: {
              ...state.selectedInsulator.variant,
              quantity: insulatorPackagesNeeded
            }
          },
          {
            ...state.selectedGateHandle,
            variant: {
              ...state.selectedGateHandle.variant,
              quantity: gateHandlePackagesNeeded
            }
          },
          {
            ...state.selectedEnergizer,
            variant: { ...state.selectedEnergizer.variant }
          }
        ]
        const filteredVariants = totalItems.filter((variant) => {
          return variant.webProductId
        })
        return {
          totalItems: filteredVariants,
          insulatorPackagesNeeded,
          gateInsulatorPackagesNeeded
        }
      })
    },
    setMaxCapacityExceeded: (maxCapacityExceeded) =>
      set(() => ({
        maxCapacityExceeded
      }))
  }))
)
