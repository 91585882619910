import * as React from "react"

import {
  AdditionalBlockInfo,
  ContentReferenceProps,
  DynamicBlock
} from "../types/content"
import editor from "../utils/editorAttributes"

import BlockResolver from "./BlockResolver"
import { GenericRenderer } from "./utils"

type ContentAreaProperty = DynamicBlock[]

type Props = {
  childContainer?: React.ComponentType<{ block: DynamicBlock }>
  content: ContentAreaProperty | undefined
} & ContentReferenceProps &
  AdditionalBlockInfo

const ContentArea: React.FunctionComponent<Props> = ({
  content,
  pageInfo,
  propInfo,
  component: Component,
  childContainer: ChildContainer,
  getAdditionalBlockInfo
}) => (
  <GenericRenderer {...editor(propInfo)} component={Component}>
    {content &&
      content
        .filter((contentItem) => !!contentItem)
        .map((contentItem, index) => {
          const resolver = (
            <BlockResolver
              key={index}
              content={contentItem}
              pageInfo={pageInfo}
              getAdditionalBlockInfo={getAdditionalBlockInfo}
            />
          )
          if (ChildContainer) {
            return (
              <ChildContainer block={contentItem} key={index}>
                {resolver}
              </ChildContainer>
            )
          }
          return resolver
        })}
  </GenericRenderer>
)

export type { ContentAreaProperty, Props as ContentAreaProps }
export default ContentArea
