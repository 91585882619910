import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Clear from "@material-ui/icons/Clear"
import WarningIcon from "@material-ui/icons/Warning"

import { SendToSelfModel } from "api/anonymousShoppingList"
import Spinner from "common/Spinner"
import Typography from "common/Typography"
import Confirmation from "icons/Confirmation"
import AnonymousShoppingListStore from "store/AnonymousShoppingListStore"
import authStore from "store/AuthStore"

const SendShoppingListModal: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const textFieldRef = useRef<HTMLInputElement>(null)

  const {
    state: { user }
  } = useStore(authStore)

  const [userEmail, setEmail] = useState(
    user && user.data && user.data.email ? user.data.email : ""
  )
  const [sentEmailText, setSentEmailText] = useState(false)
  const [badRequestText, setBadRequestText] = useState("")

  const {
    state: { shoppingList, isOpen, isLoading },
    actions: { sendToSelf, close, loading }
  } = useStore(AnonymousShoppingListStore)

  const handleClose = () => {
    close()
    setSentEmailText(false)
  }

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleSendListButton = () => {
    if (shoppingList.data && shoppingList.data.articles.length > 0) {
      loading(true)
      sendToSelf({ toEmail: userEmail } as SendToSelfModel).then((res) => {
        if (res.sentToSelf && res.sentToSelf.error) {
          setBadRequestText(res.sentToSelf && (res.sentToSelf.error as string))
          loading(false)
        } else {
          loading(false)
          setSentEmailText(true)
        }
      })
    }
  }

  return (
    <>
      <Dialog
        aria-labelledby="send-shoppinglist-dialog"
        aria-describedby="send-your-shoppinglist-here"
        open={isOpen}
        onClose={handleClose}
        className={classes.root}
      >
        <DialogContent>
          <Grid container justifyContent="flex-end">
            <IconButton className={classes.closeButton} onClick={() => close()}>
              <Clear className={classes.Icon} />
            </IconButton>
          </Grid>
          <Grid spacing={1} container alignItems="center" direction="column">
            <Grid item>
              <Typography
                align="center"
                variant="headline3"
                color="textPrimary"
                className={classes.headerTitle}
              >
                {t("shopping.send_as_email")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                align="center"
                variant="preamble"
                color="textPrimary"
                className={classes.headerTitle}
              >
                {t("shopping.send_as_email_preamble")}
              </Typography>
            </Grid>
          </Grid>
          <Box py={4}>
            <Grid spacing={2} container justifyContent="center">
              {isLoading ? (
                <div className={classes.spinnerWrapper}>
                  <Spinner />
                </div>
              ) : (
                <>
                  <Grid item xs={12}>
                    {sentEmailText && (
                      <Box display="flex" justifyContent="center" my={2}>
                        <Typography
                          align="center"
                          variant="headline6"
                          color="textPrimary"
                          className={classes.sentText}
                        >
                          {t("shopping.email_sent")}
                        </Typography>
                        <Confirmation className={classes.Icon} />
                      </Box>
                    )}
                    {badRequestText && (
                      <Box display="flex" my={3}>
                        <Typography
                          align="center"
                          variant="headline6"
                          color="textPrimary"
                          className={classes.sentText}
                        >
                          {badRequestText}
                        </Typography>
                        <WarningIcon className={classes.Icon} />
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="outlined-required"
                      InputLabelProps={{
                        className: classes.label
                      }}
                      variant="outlined"
                      label={t("checkout.email_input_label")}
                      onChange={handleEmail}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                      inputRef={textFieldRef}
                      value={userEmail}
                      InputProps={{
                        className: classes.input,
                        fullWidth: true
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  className={classes.button}
                  onClick={() => handleSendListButton()}
                >
                  <Typography variant="link2">{t("shopping.send")}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(
  ({ spacing, common: { themeColors }, breakpoints }) => ({
    root: {
      "& .MuiDialog-paperWidthSm": {
        width: "100%"
      }
    },
    headerTitle: {
      flexGrow: 2
    },
    Icon: {
      width: spacing(3),
      height: spacing(3)
    },
    closeButton: {
      padding: 0
    },
    field: {
      width: "100%"
    },
    label: {
      fontWeight: 300,
      fontSize: 16,
      color: themeColors.gray
    },
    sentText: {
      marginRight: spacing(2)
    },
    spinnerWrapper: {
      display: "flex",
      margin: 30
    },
    button: {
      padding: spacing(2),
      backgroundColor: themeColors.primaryCyan,
      "&:hover": {
        backgroundColor: themeColors.primaryCyan
      },
      "& span": {
        color: themeColors.white
      }
    },
    input: {
      padding: spacing(1),
      boxSizing: "border-box",
      "& input": {
        fontWeight: 300,
        fontSize: 16,
        paddingLeft: spacing(1),
        "&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus, ":
          {
            "-webkit-text-fill-color": themeColors.darkGray,
            "-webkit-box-shadow": " 0 0 0px 1000px transparent inset",
            transition: "background-color 5000s ease-in-out 0s"
          }
      },
      "& select": {
        "&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus, ":
          {
            "-webkit-text-fill-color": themeColors.darkGray,
            "-webkit-box-shadow": " 0 0 0px 1000px transparent inset",
            transition: "background-color 5000s ease-in-out 0s",
            paddingLeft: spacing(1)
          }
      }
    }
  })
)

export default SendShoppingListModal
