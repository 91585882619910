import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Form as FormikForm } from "formik"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Grow from "@material-ui/core/Grow"
import { makeStyles } from "@material-ui/core/styles"
import ErrorIcon from "@material-ui/icons/Error"

import { scrollToElement } from "utils/ui/scroll"

import Button from "common/Button"
import CustomTextField from "common/Formik/CustomTextField"
import Typography from "common/Typography"
import ButtonLoaderIcon from "icons/ButtonLoaderIcon"
import { ScrollContainers } from "pages/Checkout/Checkout"
import { useCheckoutPaymentStore } from "store/CheckoutStore"

const HighlightString: React.FC<{
  className?: string
  translation: string
  onClick: () => void
}> = ({ onClick, className, translation }) => {
  const parts = translation.split(/\{(.*)\}/)
  const highlight = translation.substring(
    translation.lastIndexOf("{") + 1,
    translation.lastIndexOf("}")
  )

  return (
    <Box mt={2}>
      <Typography variant="body3">
        {parts.map((part, i) =>
          highlight === part ? (
            <span onClick={onClick} className={className} key={i}>
              {part}
            </span>
          ) : (
            part
          )
        )}
      </Typography>
    </Box>
  )
}

const PromoField: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    preparedCheckout,
    resetCheckoutStates,
    fetchingCheckout,
    promoCode,
    reset: checkoutReset
  } = useCheckoutPaymentStore()

  useEffect(
    () => {
      if (promoCode.data) {
        checkoutReset.promoCode()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.href]
  )

  const isValid =
    promoCode.data && promoCode.data.isValid === true ? true : false

  const reset = async () => {
    await resetCheckoutStates()
    await fetchingCheckout()
  }

  const scrollToSummary = () => {
    scrollToElement(ScrollContainers.orderSummary)
  }

  const handleReset = () => {
    if (promoCode.data && preparedCheckout.data === null) {
      checkoutReset.promoCode()
    } else {
      reset()
    }
  }

  return (
    <FormikForm id="promo-code">
      <Grow
        in={isValid}
        {...(isValid ? { timeout: 1000 } : {})}
        unmountOnExit={true}
      >
        <Grid>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-between"
          >
            <Typography variant="priceDiscount">{`${
              promoCode.data && promoCode.data.percentage
            }% ${t("checkout.promo_code_discount")}`}</Typography>
            <Typography
              className={classes.reset}
              variant="link3"
              onClick={() => handleReset()}
            >
              {t("checkout.promo_code_remove")}
            </Typography>
          </Grid>
          <Grid>
            <HighlightString
              className={classes.summaryInfo}
              onClick={() => scrollToSummary()}
              translation={t("checkout.promo_code_summary_text")}
            />
          </Grid>
        </Grid>
      </Grow>

      <Grid container spacing={2} justifyContent="center">
        <Grow in={isValid ? false : true} unmountOnExit={true}>
          <Grid item xs={12}>
            <CustomTextField
              required
              name="promoCode"
              label={t("checkout.promo_code_placeholder")}
            />
          </Grid>
        </Grow>

        <Grid item xs={12}>
          {promoCode.data && promoCode.data.isValid === false && (
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              className={classes.error}
            >
              <ErrorIcon />
              <Typography variant="body2">
                {t("checkout.promo_code_error")}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grow in={isValid ? false : true} unmountOnExit={true}>
          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              isLoading={promoCode.loading}
              endIcon={promoCode.loading && <ButtonLoaderIcon />}
              variant="secondary"
              type="submit"
            >
              {t("checkout.promo_code_add")}
            </Button>
          </Grid>
        </Grow>
      </Grid>
    </FormikForm>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    error: {
      color: themeColors.secondaryRed,
      "& p": {
        paddingLeft: spacing(1),
        color: themeColors.secondaryRed
      }
    },
    reset: {
      "&:hover": {
        cursor: "pointer"
      }
    },
    summaryInfo: {
      color: themeColors.primaryCyan,
      cursor: "pointer",
      fontFamily: "HelveticaNeue, Arial, sans-serif"
    }
  })
)

export default PromoField
