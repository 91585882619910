import { Link } from "react-router-dom"
import classNames from "classnames"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import ResponsiveImage from "common/ResponsiveImage"
import Typography from "common/Typography"
import { themeColors } from "theme"
import { ChildLinksContent } from "./types"

type Props = {
  sectionTheme?: string
} & ChildLinksContent

const MEDIA_BG_RATIO = 1.605

const GridViewCard: React.FC<Props> = ({
  sectionTheme = "",
  image,
  url,
  text,
  heading
}) => {
  const classes = useStyles()

  return (
    <Grid>
      <Box>
        <Link to={url}>
          <ResponsiveImage
            className={classes.media}
            containerProps={{
              className: classes.mediaContainer
            }}
            image={image}
          />
        </Link>
      </Box>
      <Box className={classes.contentRoot}>
        <Box mt={2} mb={1}>
          <Typography
            className={classNames(classes.headline, {
              [classes.themedColor]: sectionTheme === themeColors.primaryBlue
            })}
            variant="headline5"
          >
            {heading}
          </Typography>
        </Box>
        <Grid>
          <Typography
            className={classNames(classes.preamble, {
              [classes.themedColor]: sectionTheme === themeColors.primaryBlue
            })}
            variant="preamble"
          >
            {text}
          </Typography>
        </Grid>
      </Box>
    </Grid>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  media: {
    position: "absolute",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  mediaContainer: {
    position: "relative",
    paddingBottom: `${100 / MEDIA_BG_RATIO}%`,
    flex: 0
  },
  contentRoot: {
    paddingBottom: spacing(1),
    [breakpoints.up("md")]: {
      paddingBottom: spacing(2)
    }
  },
  preamble: {
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": 5,
    "-webkit-box-orient": "vertical",
    [breakpoints.down("xs")]: {
      fontSize: spacing(1.5)
    }
  },
  headline: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  themedColor: {
    color: themeColors.white
  }
}))

export default GridViewCard
