import i18next from "i18next"
import * as yup from "yup"

import { EnergyCalculatorContactPayload } from "api/energyCalculatorContact"

const validationSchema = (
  t: i18next.TranslationFunction,
  enableEmail: boolean
) => {
  return yup.object<EnergyCalculatorContactPayload>().shape({
    name: yup.string().required(t("account.full_name_required_message")),
    phone: yup.string().required(t("checkout.phone_required_message")),
    postalCode: yup.string().required(t("checkout.zip_code_required_message")),
    email: enableEmail
      ? yup.string().email().required(t("checkout.email_required_message"))
      : yup.string()
  })
}

export default validationSchema
