import { ChangeEvent, useRef } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import useStore from "global-hook-store"

import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import TextField from "@material-ui/core/TextField"

import Image from "common/Image"
import Typography from "common/Typography"
import CheckboxCheckedForm from "icons/CheckboxCheckedForm"
import CheckboxForm from "icons/CheckboxForm"
import ReturnStore, { EditLineItemPayload } from "store/ReturnStore"

type Props = {
  className?: string
}

const ReturnItems: React.FC<Props> = ({ className }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { lineItems, itemsSelected, returnOrder },
    actions: { editLineItem }
  } = useStore(ReturnStore)

  const textFieldRef = useRef<HTMLInputElement>(null)

  const handleSelected = (item: EditLineItemPayload) => {
    editLineItem({
      code: item.code,
      quantity: item.quantity,
      selected: !item.selected
    })
  }

  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: EditLineItemPayload
  ) => {
    editLineItem({
      code: item.code,
      quantity: parseInt(e.target.value, 10) || 0,
      selected: item.selected
    })
  }

  return (
    <>
      <Table className={classNames(classes.table, className)}>
        <TableBody>
          {lineItems &&
            lineItems.map((item, index) => {
              const cellClasses = classNames(classes.tableCell, {
                [classes.noBorder]: index === lineItems.length - 1
              })
              return (
                <TableRow
                  key={`${item.code}-${index}`}
                  className={classes.tableRow}
                >
                  <TableCell className={cellClasses}>
                    <Checkbox
                      icon={<CheckboxForm />}
                      checkedIcon={<CheckboxCheckedForm />}
                      disabled={item.isReturnable || returnOrder.loading}
                      color="default"
                      checked={item.selected}
                      onClick={() => handleSelected(item)}
                      className={classes.returnCheckbox}
                    />
                  </TableCell>
                  <TableCell className={cellClasses}>
                    <Hidden smDown>
                      <Image
                        relative
                        containerProps={{
                          className: classes.imageBackgroundContainer
                        }}
                        className={classes.image}
                        lazy
                        src={item.imageUrl && item.imageUrl}
                      />
                    </Hidden>
                  </TableCell>
                  <TableCell className={cellClasses}>
                    <Hidden smDown>
                      <Typography variant="body1">{item.code}</Typography>
                    </Hidden>
                    <Hidden mdUp>
                      <Typography
                        className={classes.productInfoMobile}
                        variant="body1"
                      >
                        {item.code}
                      </Typography>
                      <Typography
                        className={classes.productInfoMobile}
                        variant="link2"
                      >
                        {item.displayName}
                      </Typography>
                    </Hidden>
                  </TableCell>
                  <TableCell className={cellClasses}>
                    <Hidden smDown>
                      <Typography variant="link2">
                        {item.displayName}
                      </Typography>
                    </Hidden>
                  </TableCell>
                  <Hidden smDown>
                    <TableCell className={cellClasses}>
                      <TextField
                        disabled={!item.selected || returnOrder.loading}
                        variant="outlined"
                        onChange={(e) => {
                          handleQuantityChange(
                            e as ChangeEvent<HTMLInputElement>,
                            item
                          )
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        inputRef={textFieldRef}
                        InputProps={{
                          classes: {
                            root: classes.quantityInput,
                            notchedOutline: classes.border
                          }
                        }}
                        inputProps={{
                          style: {
                            textAlign: "center",
                            padding: 0,
                            height: "100%",
                            fontSize: 14
                          },
                          min: 0
                        }}
                        className={classes.ammount}
                        value={item.quantity}
                      />
                    </TableCell>
                  </Hidden>

                  <Hidden mdUp>
                    <TableCell
                      align="right"
                      style={{ whiteSpace: "nowrap" }}
                      className={cellClasses}
                    >
                      <TextField
                        variant="outlined"
                        disabled={!item.selected || returnOrder.loading}
                        onChange={(e) => {
                          handleQuantityChange(
                            e as ChangeEvent<HTMLInputElement>,
                            item
                          )
                        }}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        inputRef={textFieldRef}
                        InputProps={{
                          classes: {
                            root: classes.quantityInput,
                            notchedOutline: classes.border
                          }
                        }}
                        inputProps={{
                          style: {
                            textAlign: "center",
                            padding: 0,
                            height: "100%",
                            fontSize: 14
                          },
                          min: 0
                        }}
                        className={classes.ammount}
                        value={item.quantity}
                      />
                    </TableCell>
                  </Hidden>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.noOfReturning}>
          {itemsSelected > 0
            ? `${itemsSelected} ${t("account.return_rows_selected")}`
            : ""}
        </Typography>
      </Grid>
    </>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors, gridSpacing } }) => ({
    root: {},
    table: {
      borderCollapse: "separate",
      "& td": {
        paddingTop: gridSpacing * 1,
        paddingBottom: gridSpacing * 1,
        borderBottom: `1px solid ${themeColors.lightGray}`,
        [breakpoints.down("sm")]: {
          "&:nth-child(2)": {
            paddingRight: gridSpacing * 0.5
          }
        }
      }
    },
    imageBackgroundContainer: {
      display: "flex"
    },
    image: {
      height: 38,
      position: "relative",
      [breakpoints.down("sm")]: {
        paddingLeft: gridSpacing,
        paddingRight: gridSpacing * 0.5
      }
    },
    tableRow: {},
    tableCell: {
      paddingTop: gridSpacing,
      paddingBottom: gridSpacing,
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      [breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0
      },
      "&:last-child": {
        paddingRight: spacing(2)
      }
    },
    noBorder: { borderBottom: "none" },
    productInfoMobile: {
      fontSize: 14
    },
    quantityInput: {
      color: themeColors.darkGray,
      height: "100%"
    },
    border: {
      borderRadius: 2
    },
    ammount: {
      width: 48,
      height: 40,
      borderRadius: 2,
      background: themeColors.white
    },
    returnCheckbox: {
      alignSelf: "flex-start",
      padding: 0
    },
    noOfReturning: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: spacing(0.5)
    }
  })
)

export default ReturnItems
