import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { useLocalStore } from "global-hook-store"
import qs from "qs"

import Card from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import { SearchResultType } from "api/search"
import ProductCard from "common/Product/ProductCard"
import Typography from "common/Typography"
import LoadMore from "common/WebNodeListing/LoadMoreButton/LoadMore"
import OnLoadSpinner from "common/WebNodeListing/OnLoadSpinner"
import Wrapper from "common/Wrapper"
import Product from "models/Products"
import SearchStore from "store/SearchStore"

type OwnProps = {
  className?: string | undefined
}

const NUMBER_OF_HITS = 20

type Props = OwnProps

const SearchResult: React.FC<Props> = ({ className }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    state: { result },
    actions: { asyncSearch, forceLoading }
  } = useLocalStore(SearchStore)

  const [allProducts, setAllProducts] = useState([] as Product[])
  const [totalItems, setTotalItems] = useState(0)
  const [searchQuery, setSearchQuery] = useState("")

  const performSearch = async (resetSearch?: boolean) => {
    forceLoading()
    if (resetSearch) {
      setAllProducts([] as Product[])
    }

    const { q: query } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })

    if (query) {
      setSearchQuery(query.toString())
      let offset = 0
      if (!resetSearch && result.data) {
        offset = result.data[0].offset + NUMBER_OF_HITS
      }
      await asyncSearch({
        offset,
        type: SearchResultType.Products,
        q: query.toString(),
        limit: NUMBER_OF_HITS
      })
    }
  }

  useEffect(
    () => {
      performSearch(true)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [window.location.search]
  )

  useEffect(
    () => {
      if (result.data && result.data[0].hits) {
        const pr = allProducts.concat(result.data[0].hits as Product[])
        setAllProducts(pr)
        setTotalItems(result.data[0].totalItems)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result.data]
  )

  const renderProducts = () => {
    if (allProducts && allProducts.length > 0) {
      return (
        <>
          <Wrapper style={{ marginBottom: "20px" }}>
            <Typography variant="headline1">
              {`${t("search.results_for")}: ${searchQuery}`}
            </Typography>
          </Wrapper>
          <Wrapper
            className={classes.wrapper}
            hidden={allProducts.length === 0}
          >
            <Card className={classes.filterCard}>
              <Typography variant="body2">
                {t("product.showing_x_products").replace("{value}", totalItems)}
              </Typography>
            </Card>
            <Card>
              <Wrapper className={classes.productWrapper}>
                <Grid container>
                  {allProducts &&
                    allProducts.map((product, index) => (
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        className={classes.grid}
                        key={index}
                      >
                        <ProductCard product={product} />
                      </Grid>
                    ))}
                </Grid>
              </Wrapper>
            </Card>
            <LoadMore
              route={searchQuery}
              totalNumberOfProducts={totalItems}
              showingNumberOfProducts={allProducts.length}
              handleLoadMore={performSearch}
            />
          </Wrapper>
        </>
      )
    }

    return (
      <Typography className={classes.searchResultTitle}>
        {t("search.no_results")}
      </Typography>
    )
  }

  return (
    <div className={classNames(classes.root, className)}>
      <Grid container>
        <Grid item md={9} className={classes.menuWrapperContainer}>
          <Wrapper className={classes.menuWrapper}>
            {result.loading && <OnLoadSpinner />}
            {renderProducts()}
          </Wrapper>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors, gridSpacing } }) => ({
    root: {
      width: "100%",
      zIndex: 999,
      position: "relative",
      background: themeColors.white,
      [breakpoints.down("sm")]: {
        position: "relative",
        top: 0
      }
    },
    title: {
      color: themeColors.darkGray,
      fontWeight: "bold",
      fontSize: 24
    },
    menuWrapperContainer: {
      margin: "0 auto",
      [breakpoints.down("sm")]: {
        width: "calc(100% - 30px)"
      }
    },
    menuWrapper: {
      padding: 0,
      paddingTop: gridSpacing,
      paddingBottom: gridSpacing,
      position: "relative",
      [breakpoints.down("sm")]: {
        padding: "12px 0"
      }
    },
    searchResultTitle: {
      fontWeight: "bold",
      fontSize: 18,
      [breakpoints.down("sm")]: {
        margin: "40px 0"
      }
    },
    grid: {
      position: "relative",
      width: "100%",
      height: "100%",
      borderRight: `1px solid ${themeColors.lightGray}`,
      borderBottom: `1px solid ${themeColors.lightGray}`
    },
    wrapper: {
      padding: 0,
      marginBottom: gridSpacing,
      [breakpoints.up("md")]: {
        paddingLeft: gridSpacing
      }
    },
    filterWrapper: {
      marginBottom: gridSpacing,
      padding: 0,
      [breakpoints.up("md")]: {
        paddingLeft: gridSpacing
      }
    },
    filterCard: {
      padding: gridSpacing
    },
    productWrapper: {
      padding: 0,
      borderTop: `1px solid ${themeColors.lightGray}`,
      borderLeft: `1px solid ${themeColors.lightGray}`,
      marginTop: gridSpacing * 0.5,
      marginBottom: gridSpacing * 0.5,
      [breakpoints.down("sm")]: {
        margin: 0
      }
    },
    spinnerWrapper: {
      textAlign: "center",
      padding: gridSpacing
    },
    loadMore: {
      marginRight: spacing(1)
    },
    ctaButton: {
      marginTop: spacing(6),
      border: "none",
      padding: `${spacing(2)}px ${spacing(3)}px`,
      textTransform: "none",
      "& span": {
        color: themeColors.white
      }
    }
  })
)

export default SearchResult
