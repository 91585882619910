import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
type Props = SvgIconProps

const DeviceOnDeLaval: React.FC<Props> = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M13.333 21.333V24m5.334-2.667V24M16 17.333h9.333V28H6.667V17.333H16Zm0 0v-4m-5.942-.358A6.666 6.666 0 0 1 16 9.333c2.593 0 4.84 1.481 5.943 3.643m4.662-2.479A12 12 0 0 0 15.938 4a12 12 0 0 0-10.752 6.667"
      fill="none"
    />
  </SvgIcon>
)

export default DeviceOnDeLaval
