import i18next from "i18next"
import * as yup from "yup"

const validationSchema = (t: i18next.TranslationFunction) => {
  return yup.object().shape({
    orderReference: yup
      .string()
      .max(12, t("checkout.order_reference_too_long_validation_error"))
  })
}

export default validationSchema
