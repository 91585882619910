import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Formik, FormikActions } from "formik"
import useStore from "global-hook-store"

import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"

import MissingAccountInformation from "common/MissingAccountInformation"
import Spinner from "common/Spinner"
import Typography from "common/Typography"
import { Address } from "models/Checkout"
import authStore from "store/AuthStore"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import marketStore from "store/MarketStore"
import siteSettingStore from "store/SiteSettingStore"
import CustomerShippingAddress from "./CustomerShippingAddress"
import ShippingAdressList from "./ShippingAdressList"
import validationSchema from "./validationSchema"

const emptyInitialValues = {
  firstName: "",
  lastName: "",
  companyName: "",
  streetAddress: "",
  countryCode: "",
  zipCode: "",
  city: "",
  phone: ""
} as Address

const CustomerDetailsSummary: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    preparedCheckout,
    checkout,
    shippingCheck,
    set: checkoutSet,
    reset: checkoutReset
  } = useCheckoutPaymentStore()

  const {
    state: { countries, defaultMarket }
  } = useStore(marketStore)

  const {
    state: {
      enableAddNewShippingAddressInCheckout,
      postalCodeValidationPattern
    }
  } = useStore(siteSettingStore)

  if (countries.length > 0 && defaultMarket) {
    const initialCountry = countries.find((c) => {
      return c.alpha2 === defaultMarket.marketId.value
    })
    if (initialCountry) {
      emptyInitialValues.countryCode = initialCountry.alpha2
    }
  }

  const {
    state: { user }
  } = useStore(authStore)

  const filteredShippingAddresses =
    (user.data &&
      user.data.shippingAddresses.filter((address) => {
        return address.countryCode === defaultMarket!.marketId.value
      })) ||
    []

  const defaultId = user.data && user.data.defaultShippingAddressId
  const defaultShippingAddress = filteredShippingAddresses.find(
    (a) => a.shippingAddressId === defaultId
  )

  const defaultMarketValue =
    defaultMarket && defaultMarket.marketId ? defaultMarket.marketId.value : ""

  const [newAddress, setNewAddress] = useState({} as Address)
  const [selectedShipping, setSelectedShipping] = useState(
    filteredShippingAddresses.length > 0 ? 0 : -1
  )
  const [initialValues, setInitalValues] = useState(emptyInitialValues)

  useEffect(() => {
    if (filteredShippingAddresses.length > 0) {
      checkoutSet.selectedShippingAddress(
        defaultShippingAddress || filteredShippingAddresses[0]
      )
    }

    if (defaultShippingAddress) {
      const defaultShippingIndex = filteredShippingAddresses.findIndex(
        (address) => {
          return (
            address.shippingAddressId ===
            defaultShippingAddress.shippingAddressId
          )
        }
      )
      setSelectedShipping(defaultShippingIndex)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSelectShipping = async (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = e.target.value as number
    setSelectedShipping(value)
    checkoutSet.isEditingShippingAddress(value === -1)

    if (value > -1 && user.data) {
      checkoutSet.selectedShippingAddress(
        value < filteredShippingAddresses.length
          ? filteredShippingAddresses[value]
          : newAddress
      )
      checkoutSet.loadingShippingCheck(true)
      shippingCheck(user.data)
    }
    if (selectedShipping > -1 && value === -1) {
      setInitalValues({
        ...emptyInitialValues,
        companyName: user.data ? user.data.companyName : ""
      })
    }
  }

  const addNewShippingAddress = (address?: Address | null) => {
    setInitalValues(
      address || {
        ...emptyInitialValues,
        companyName: user.data ? user.data.companyName : ""
      }
    )
    setSelectedShipping(-1)
    checkoutSet.isEditingShippingAddress(true)
  }

  const renderAddress = (
    address: Address,
    companyName?: string,
    vatNumber?: string
  ) => {
    const country = countries.find((c) => {
      return c.alpha2 === address.countryCode
    })
    return (
      <div className={classes.addressWrapper}>
        {companyName && (
          <Typography
            variant="headline4"
            className={classes.billingCompanyName}
          >
            {companyName}
            {vatNumber && (
              <Typography variant="body1" className={classes.headerBadge}>
                {vatNumber}
              </Typography>
            )}
          </Typography>
        )}
        <Typography variant="body1">
          {`${address.firstName} ${address.lastName} ${
            address.phone && `(${address.phone})`
          }`}
          <br />
          {address.streetAddress} <br />
          {`${address.zipCode} ${address.city}`}
          <br />
          {country && country.name}
        </Typography>
      </div>
    )
  }

  const renderShippingAddress = (address: Address | null) => {
    if (!address) return null
    const country = countries.find((c) => {
      return c.alpha2 === address.countryCode
    })
    return (
      <Typography variant="body1">
        {`${address.firstName} ${address.lastName} ${
          address.phone && `(${address.phone})`
        }`}
        <br />
        {address.streetAddress} <br />
        {`${address.zipCode} ${address.city}`}
        <br />
        {country && country.name}
      </Typography>
    )
  }

  const handleNewShippingAddressSubmit = async (
    values: Address,
    { setSubmitting }: FormikActions<Address>
  ) => {
    setSubmitting(true)
    setNewAddress(values)
    checkoutSet.selectedShippingAddress(values)
    if (user.data) {
      setSelectedShipping(filteredShippingAddresses.length)
    }
    checkoutSet.isEditingShippingAddress(false)
    setSubmitting(false)
  }

  const selectedAddress = user.data
    ? selectedShipping === filteredShippingAddresses.length
      ? newAddress
      : filteredShippingAddresses[selectedShipping]
    : null

  return !user.loading && user.data ? (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="preamble"
            className={classNames(classes.preamble, classes.spacing)}
          >
            {t("checkout.billing_information")}
          </Typography>
          {renderAddress(
            user.data.billingAddress as Address,
            user.data.companyName,
            user.data.vatNumber
          )}
        </Grid>
        <MissingAccountInformation />
        <Grid
          item
          xs={12}
          className={
            checkout.data &&
            checkout.data.cart &&
            checkout.data.cart.properties &&
            checkout.data.cart.properties.numberOfProducts === 0 &&
            checkout.data.cart.properties.numberOfDigitalServices > 0
              ? classes.notVisible
              : ""
          }
        >
          <Typography
            variant="preamble"
            className={classNames(classes.preamble, classes.spacing)}
          >
            {t("checkout.shipping_information")}
          </Typography>
          {!preparedCheckout.data ? (
            <Select
              id="shippingSelector-field"
              disabled={preparedCheckout.loading}
              className={classes.shippingSelector}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
                  }
                }
              }}
              value={selectedShipping}
              onChange={handleSelectShipping}
              input={
                <OutlinedInput labelWidth={8} style={{ marginRight: 7 }} />
              }
            >
              {filteredShippingAddresses.map((address, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    <ShippingAdressList
                      shippingAddressId={address.shippingAddressId}
                      streetAddress={address.streetAddress}
                      city={address.city}
                      zipCode={address.zipCode}
                    />
                  </MenuItem>
                )
              })}
              {newAddress && newAddress.streetAddress && (
                <MenuItem value={filteredShippingAddresses.length}>
                  <Typography>{`${newAddress.streetAddress}, ${newAddress.zipCode} ${newAddress.city}`}</Typography>
                </MenuItem>
              )}
              {enableAddNewShippingAddressInCheckout && (
                <MenuItem value="-1">
                  <Typography>
                    {t("checkout.add_new_shipping_address_label")}
                  </Typography>
                </MenuItem>
              )}
            </Select>
          ) : null}
          {selectedShipping > -1 ? (
            <div className={classes.addressWrapper}>
              {/* TODO: As for now it's not possible to update a existing shipping address field values in SAP... */}
              {/* ...since we include the shipping address id in the payload and SAP will just find the id and return the "not updated" shipping address. */}
              {/* Therefore this "edit-button" is commented out for now. */}
              {/* {enableAddNewShippingAddressInCheckout && (
                <span className={classes.editWrapper}>
                  <EditPen
                    onClick={() => {
                      preparedCheckout.data
                        ? checkoutReset.preparedCheckout()
                        : addNewShippingAddress(selectedAddress)
                    }}
                  />
                </span>
              )} */}
              {user.data.companyName && selectedAddress && (
                <Typography
                  variant="headline4"
                  className={classes.billingCompanyName}
                >
                  {selectedAddress.companyName || user.data.companyName}
                </Typography>
              )}
              {renderShippingAddress(selectedAddress)}
            </div>
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          className={
            checkout.data &&
            checkout.data.cart &&
            checkout.data.cart.properties &&
            checkout.data.cart.properties.numberOfProducts === 0 &&
            checkout.data.cart.properties.numberOfDigitalServices > 0
              ? classes.notVisible
              : ""
          }
        >
          {selectedShipping === -1 ? (
            <Formik
              initialValues={initialValues}
              onSubmit={handleNewShippingAddressSubmit}
              validateOnChange={false}
              validateOnBlur
              component={CustomerShippingAddress}
              validationSchema={validationSchema(
                t,
                postalCodeValidationPattern,
                defaultMarketValue
              )}
            />
          ) : preparedCheckout.data ? null : (
            enableAddNewShippingAddressInCheckout && (
              <div className={classes.newShippingAddressWrapper}>
                <Typography
                  variant="link3"
                  component="span"
                  onClick={() => addNewShippingAddress()}
                  className={classes.pointer}
                >
                  {t("checkout.add_new_shipping_address_link_text")}
                </Typography>
              </div>
            )
          )}
        </Grid>
      </Grid>
    </div>
  ) : (
    <Spinner />
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors, gridSpacing } }) => ({
    root: {
      paddingLeft: spacing(6),
      paddingRight: spacing(6),
      paddingBottom: spacing(4),
      [breakpoints.down("sm")]: {
        padding: spacing(3)
      }
    },
    preamble: {
      textAlign: "left",
      color: themeColors.darkGray,
      fontWeight: "bold",
      marginBottom: spacing(2)
    },
    spacing: {
      marginTop: spacing(3)
    },
    headerBadge: {
      display: "inline-block",
      marginLeft: spacing(2)
    },
    billingCompanyName: {
      marginBottom: spacing(2)
    },
    addressWrapper: {
      border: `1px solid ${themeColors.lightGray}`,
      padding: spacing(3),
      marginBottom: spacing(3)
    },
    shippingSelector: {
      width: "100%",
      padding: spacing(1),
      marginBottom: spacing(3),
      "& svg": {
        right: 8
      },
      "& > div > div > div:nth-child(2)": {
        display: "none"
      }
    },
    newShippingAddressWrapper: {
      textAlign: "center",
      borderTop: `1px dashed ${themeColors.lightGray}`,
      paddingTop: spacing(3)
    },
    pointer: {
      cursor: "pointer"
    },
    editWrapper: {
      float: "right"
    },
    notVisible: {
      display: "none"
    }
  })
)

export default CustomerDetailsSummary
