import { makeStyles } from "@material-ui/core/styles"

import { PdfAssets } from "models/Products"
import HazardousWarning from "./HazardousWarning"
import Information from "./Information"

type Props = {
  link?: string
  showHazardousWarning?: boolean
  pdfAssets?: PdfAssets[]
}

const AdditionalInformation: React.FC<Props> = ({
  showHazardousWarning,
  pdfAssets
}) => {
  const classes = useStyles()

  return showHazardousWarning || (pdfAssets && pdfAssets.length > 0) ? (
    <div className={classes.root}>
      {showHazardousWarning && (
        <HazardousWarning showHazardousWarning={showHazardousWarning} />
      )}
      {showHazardousWarning && pdfAssets && pdfAssets.length > 0}
      <Information pdfAssets={pdfAssets} />
    </div>
  ) : null
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100%"
  }
}))

export default AdditionalInformation
