import { BlockProps } from "@starrepublic/epi/cms"

import Tooltip from "@material-ui/core/Tooltip"

import CustomChoiceSelection from "common/Formik/CustomChoiceSelection"
import { ItemSelection } from "./types"

type Props = BlockProps<{
  theme: string
  label: string
  formElementId: string
  description: string
  allowMultiSelect: boolean
  items: ItemSelection[]
}>

const ChoiceElementBlock: React.FC<Props> = ({
  content: { label, formElementId, description, items, allowMultiSelect, theme }
}) => (
  <Tooltip placement="top" arrow title={description ? description : ""}>
    <div>
      <CustomChoiceSelection
        {...{ label, theme }}
        name={formElementId}
        items={items}
        allowMultiSelect={allowMultiSelect}
      />
    </div>
  </Tooltip>
)

export default ChoiceElementBlock
