import { FC, Fragment } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"

import Spinner from "common/Spinner"
import Typography from "common/Typography"
import ArrowDown from "icons/ArrowDown"
import { useCheckoutPaymentStore } from "store/CheckoutStore"
import paymentStore from "store/PaymentStore"
import PaymentExpansionPanel from "./PaymentExpansionPanel"
import PaymentMethod from "./PaymentMethod"

const PaymentOptions: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { checkout } = useCheckoutPaymentStore()

  const { setPaymentOption, selectedSubPaymentOption, selectedPaymentOption } =
    useCheckoutPaymentStore()

  const {
    state: { paymentMethods }
  } = useStore(paymentStore)

  return checkout.data ? (
    <Grid container justifyContent="center" className={classes.root}>
      {/* ----> Adyen card payments <---- */}
      {paymentMethods.loading ? (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          p={2}
          className={classes.borderBottom}
        >
          <Spinner />
        </Box>
      ) : paymentMethods &&
        paymentMethods.data &&
        paymentMethods.data.paymentMethods &&
        paymentMethods.data.paymentMethods.length > 0 ? (
        paymentMethods.data.paymentMethods.map(
          (pm, i) =>
            ["scheme", "ebanking_FI"].indexOf(pm.type) > -1 && (
              <Fragment
                key={
                  i
                  // TODO: Enable these when klarana is golive ready
                  // "klarna",
                  // "klarna_paynow",
                  // "klarna_account"
                }
              >
                <PaymentMethod
                  name={
                    pm.type === "ebanking_FI"
                      ? t("checkout.finnish_ebanking_payment_name")
                      : pm.name
                  }
                  id={"Adyen"}
                  isSelected={
                    "Adyen" === selectedPaymentOption &&
                    selectedSubPaymentOption === pm.type
                  }
                  onClick={() => {
                    setPaymentOption({
                      selectedPaymentOption: "Adyen",
                      selectedSubPaymentOption: pm.type
                    })
                  }}
                />
                {selectedSubPaymentOption === pm.type &&
                  pm.type === "scheme" && (
                    <PaymentExpansionPanel
                      expansionIcon={<ArrowDown fontSize="small" />}
                    />
                  )}
              </Fragment>
            )
        )
      ) : null}

      {/* ----> Invoice payment <---- */}
      {checkout.data.paymentOptions.length > 0 ? (
        checkout.data.paymentOptions.map((paymentOption) =>
          paymentOption.id !== "Adyen" ? (
            <PaymentMethod
              key={paymentOption.id}
              name={paymentOption.name}
              id={paymentOption.id}
              isSelected={paymentOption.id === selectedPaymentOption}
              onClick={async () => {
                setPaymentOption({
                  selectedPaymentOption: paymentOption.id,
                  selectedSubPaymentOption: ""
                })
              }}
            />
          ) : null
        )
      ) : (
        <Typography variant={"body1"} className={classes.error}>
          {"No payment options are available"}
        </Typography>
      )}
    </Grid>
  ) : null
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    width: "100%",
    margin: 0
  },
  borderBottom: {
    borderBottom: `1px solid ${themeColors.lightGray}`
  },
  error: {
    color: themeColors.primaryRed,
    paddingTop: spacing(2)
  }
}))

export default PaymentOptions
