import classNames from "classnames"
import useStore from "global-hook-store"

import ListItemIcon from "@material-ui/core/ListItemIcon"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import Popover from "@material-ui/core/Popover"
import { makeStyles } from "@material-ui/core/styles"

import MarketFlag from "common/MarketFlag"
import Typography from "common/Typography"
import marketStore from "store/MarketStore"

type Props = {
  anchorEl: HTMLElement | null
  onClose: () => void
}

const MarketsMenu: React.FunctionComponent<Props> = ({ anchorEl, onClose }) => {
  const classes = useStyles()
  const open = Boolean(anchorEl)
  const id = open ? "market-popover" : undefined

  const {
    state: { availableMarkets },
    actions: { changeMarket }
  } = useStore(marketStore)

  const handleChangeMarket = (
    market: any,
    language: string,
    redirectUrl: string
  ) => {
    changeMarket({ market, language, redirectUrl })
    onClose()
  }

  return (
    <>
      <Popover
        id={id}
        open={open}
        elevation={3}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Paper className={classes.marketMenu}>
          <div>
            <MenuList>
              {availableMarkets.map((market, marketIndex) =>
                market.languages.map((language, languageIndex) => (
                  <MenuItem
                    id={`market-manager-option-${language.toLowerCase()}`}
                    key={`market-${marketIndex}-language-${languageIndex}`}
                    className={classNames({
                      [classes.selectedItem]: market.isSelected
                    })}
                    onClick={() =>
                      handleChangeMarket(
                        market,
                        language,
                        market.baseUrls[languageIndex]
                      )
                    }
                  >
                    <ListItemIcon className={classes.flagContainer}>
                      <MarketFlag
                        countryAlpha2={market.marketId.value}
                        className={classes.marketFlag}
                      />
                    </ListItemIcon>
                    <Typography variant="body1">
                      {market.nativeNames[languageIndex]}
                    </Typography>
                  </MenuItem>
                ))
              )}
            </MenuList>
          </div>
        </Paper>
      </Popover>
    </>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, common: { themeColors } }) => ({
    marketMenu: {
      maxWidth: "300px",
      position: "relative",
      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
      maxHeight: 500,
      overflow: "auto",
      [breakpoints.down("sm")]: {
        top: 0
      },
      "& ul": {
        paddingTop: 0,
        paddingBottom: 0,
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        "& li": {
          width: "100%",
          boxSizing: "inherit",
          paddingTop: spacing(2),
          paddingBottom: spacing(2)
        }
      }
    },
    marketFlag: {
      width: spacing(2.48),
      height: spacing(2.4)
    },
    flagContainer: {
      minWidth: spacing(4)
    },
    selectedItem: {
      backgroundColor: themeColors.whiteGray
    }
  })
)

export default MarketsMenu
