import { BlockProps } from "@starrepublic/epi/cms"
import {
  CmsContextProps,
  withCmsContext
} from "@starrepublic/epi/cms/components/CmsContext"
import classNames from "classnames"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"
import withWidth, { WithWidth } from "@material-ui/core/withWidth"

import BlockBackground from "blocks/BlockBackground"
import BlockGrid from "common/BlockGrid"
import { useHeaderStore } from "store/HeaderStore"
import PageStore from "store/PageStore"
import SiteSettingsStore from "store/SiteSettingStore"
import MainContent from "./MainContent"
import { HeroBlockContent } from "./types"
import { getTextAlignment } from "./utils"

type OwnProps = BlockProps<HeroBlockContent>

type Props = CmsContextProps & OwnProps & WithWidth

type StyleProps = {
  _isOnePager: boolean
  _headerHeight: number | undefined
  _isLarge: boolean
  _textAlignment: string
}

const HeroBlock: React.FC<Props> = (props) => {
  const { content, propsInfo, blockInfo } = props
  const { headerHeight } = useHeaderStore()

  const {
    state: { _siteType }
  } = useStore(SiteSettingsStore)

  const {
    state: { page }
  } = useStore(PageStore)

  const isStartPage = page.data && page.data._type === "SiteStartPage"
  const isMainContent = page.data && page.data?.mainContent

  const isOnePager =
    _siteType !== "cmsOnePager" ? false : isStartPage ? true : false
  const textAlignment = getTextAlignment(content.textAlignment)

  const classes = useStyles({
    _isOnePager: isOnePager,
    _headerHeight: headerHeight,
    _isLarge: content.large,
    _textAlignment: textAlignment
  })()

  const blockId = blockInfo?._id
  const blockIsInList = isMainContent?.some((block) => block._id === blockId)
  const isHeroTabContentUsed =
    (page.data?.hero && page.data?.hero.backgroundImage) ||
    (page.data?.hero && page.data?.hero.bigTitle) ||
    (page.data?.hero && page.data?.hero.smallTitle)
      ? true
      : false

  /**
   * Determines whether to convert the headline from an `h1` to an `h2` tag.
   * This is necessary to ensure that there are no multiple `h1` tags on the page,
   * which can negatively impact SEO and accessibility.
   *
   * The conversion is triggered if the current block is part of the main content
   * and if the hero tab content is being used (indicated by the presence of a background image,
   * big title, or small title in the hero section).
   */
  const convertHeadline = blockIsInList && isHeroTabContentUsed ? true : false

  return (
    <BlockBackground
      content={content}
      className={classNames(classes.root, { [classes.isLarge]: content.large })}
      blockInfo={blockInfo}
      isOnePager={isOnePager}
    >
      <BlockGrid
        container
        direction="column"
        wrap="nowrap"
        justifyContent={"center"}
        alignItems={isOnePager ? "flex-start" : "center"}
        className={classes.heroGrid}
      >
        <MainContent
          convertHeadline={convertHeadline}
          content={content}
          propsInfo={propsInfo}
          isOnePager={isOnePager}
          textAlignment={textAlignment}
        />
      </BlockGrid>
    </BlockBackground>
  )
}

const useStyles = ({
  _isOnePager,
  _headerHeight,
  _isLarge,
  _textAlignment
}: StyleProps) =>
  makeStyles(({ breakpoints, spacing }) => ({
    root: {
      position: "relative"
    },
    isLarge: {
      "& > div:nth-child(3)": {
        aspectRatio: 720 / 239,
        display: "flex",
        alignItems: "center"
      }
    },
    heroGrid: {
      position: "relative",
      alignItems: "center",
      padding: _isLarge ? spacing(9.5, 0) : spacing(4.5, 0),
      [breakpoints.up("sm")]: {
        padding: _isLarge ? spacing(15.5, 0) : spacing(6.5, 0),
        minHeight: _isOnePager
          ? `calc(100vh - ${
              _headerHeight === undefined ? 80 : _headerHeight
            }px)`
          : 0
      },
      [breakpoints.up("md")]: {
        alignItems: _textAlignment
      },
      [breakpoints.between("xs", "sm")]: {
        minHeight: _isOnePager ? 410 : 0
      }
    }
  }))

export default withCmsContext(withWidth()(HeroBlock))
