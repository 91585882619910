import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useStore from "global-hook-store"

import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import Typography from "common/Typography"
import { LineItem } from "models/Cart"
import { checkoutStepAction } from "services/StarGA"
import CartStore from "store/CartStore"
import { themeColors } from "theme"
import OrderOverviewPrice from "./OrderOverviewPrice"
import OrderOverviewTableRow from "./OrderOverviewTableRow"

type Props = {
  stockStatus?: boolean
  onItemClick?: () => void
}

const OrderOverview: React.FC<Props> = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    state: { selectedCart, cart }
  } = useStore(CartStore)

  useEffect(
    () => {
      if (!cart.loading && cart.data) {
        checkoutStepAction(1, cart.data.lineItems)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart, checkoutStepAction]
  )

  return (
    <div className={classes.root}>
      {!selectedCart || selectedCart.lineItems.length === 0 ? (
        <>
          <Typography
            variant="headline6"
            align="center"
            className={classes.cartMessage}
          >
            {t("cart.empty_cart")}
          </Typography>
        </>
      ) : (
        <>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell className={classes.product}>
                  {t("cart.product_table_header")}
                </TableCell>
                <Hidden smDown>
                  <TableCell>{t("cart.price_table_header")}</TableCell>
                  <TableCell>{t("cart.quantity_table_header")}</TableCell>
                  <TableCell align="right">
                    {t("cart.total_table_header")}
                  </TableCell>
                </Hidden>
                <Hidden mdUp>
                  <TableCell />
                </Hidden>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedCart!.lineItems.map((lineItem: LineItem) => (
                <OrderOverviewTableRow
                  key={lineItem.lineItemId}
                  item={lineItem}
                />
              ))}
            </TableBody>
          </Table>
          <OrderOverviewPrice />
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column"
  },
  table: {
    borderCollapse: "separate",
    "& th": {
      color: themeColors.darkGray,
      whiteSpace: "nowrap",
      fontWeight: 700
    }
  },
  product: {
    paddingLeft: 0
  },
  cartMessage: {
    marginTop: spacing(2) + 4,
    marginBottom: spacing(2) + 4
  }
}))

export default OrderOverview
