import useStore from "global-hook-store"

import Badge from "@material-ui/core/Badge"
import { makeStyles } from "@material-ui/core/styles"

import Typography from "common/Typography"
import ShoppingBasket from "icons/ShoppingBasket"
import CartStore from "store/CartStore"

const ShowCartButton: React.FC = () => {
  const classes = useStyles()
  const {
    state: { itemsCount }
  } = useStore(CartStore)

  return (
    <div className={classes.root}>
      <Badge
        overlap="rectangular"
        badgeContent={
          itemsCount && (
            <Typography variant="smallText" className={classes.badgeContent}>
              {itemsCount}
            </Typography>
          )
        }
        classes={{
          badge: classes.badge
        }}
      >
        <ShoppingBasket />
      </Badge>
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    fontSize: 16
  },
  badge: {
    backgroundColor: themeColors.primaryRed
  },
  badgeContent: {
    color: themeColors.white,
    fontWeight: "bold"
  }
}))

export default ShowCartButton
