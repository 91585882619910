import request from "@starrepublic/epi/api/request"

type PriceCondition = {
  type: string
  description: string
  rate: string
  unit: string
  value: string
}

export type PricingDetailsModel = {
  articleNumber: string
  netPrice: number
  vatAmount: number
  conditions: PriceCondition[]
}

const pricingDetails = {
  getPricingDetails: (articleNumber: string) =>
    request.get<PricingDetailsModel>(
      `/pricing-details?articleNumber=${articleNumber}`
    ),
  getNetPrices: (articleNumbers: string[]) =>
    request.post(`/pricing-details/`, articleNumbers)
}

export default pricingDetails
