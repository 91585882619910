import { throwDevError } from "../utils/logging"

import {
  ChannelManager,
  ContentSavedTopic,
  EpiContext,
  EpiReadyTopic
} from "./types"

const ensure = (predicate: () => boolean, timeout: number) => {
  const start = Date.now()
  return new Promise((resolve, reject) => {
    ;(function waitForPredicate() {
      if (predicate()) return resolve(true)
      if (Date.now() - start >= timeout) {
        return reject()
      } else {
        setTimeout(waitForPredicate, 30)
      }
    })()
  })
}

const waitForEpiContext = () =>
  ensure(() => (window.epi && window.epi.beta ? true : false), 5000)

const epiContextPromise: Promise<EpiContext | undefined> = new Promise(
  (resolve) => {
    const fn = async () => {
      try {
        await waitForEpiContext()
      } catch {
        return resolve(undefined)
      }
      const { epi } = window
      if (!epi) {
        return resolve(undefined)
      }

      if (epi.beta && epi.beta.ready) {
        return resolve(epi.beta)
      }

      if (epi.subscribe) {
        const subscription = epi.subscribe(EpiReadyTopic, () => {
          resolve(window.epi ? window.epi.beta : undefined)
          subscription && subscription.remove && subscription.remove()
        })
      } else {
        resolve(undefined)
      }
    }
    if (document.readyState === "complete") {
      fn()
    } else {
      window.addEventListener("load", fn)
    }
  }
)

const connector: ChannelManager = {
  subscribeToContentSaved: async (callback) => {
    const { epi } = window

    try {
      await waitForEpiContext()
    } catch {
      return
    }

    if (!epi || !epi.subscribe) {
      throwDevError(
        "Epi connector is missing on window object, on-page-editing might not work correctly"
      )
      return
    }

    const subscription = epi!.subscribe(
      ContentSavedTopic,
      (propertyDetails) => {
        // TODO: check if update was successful and process validation errors
        callback(propertyDetails.properties)
      }
    )

    return subscription
  },
  isEditMode: async () => {
    if (!window["EPI_EDIT_MODE"]) {
      return false
    }
    const epiContext = await epiContextPromise
    const isEditMode = epiContext
      ? epiContext.ready && epiContext.inEditMode && epiContext.isEditable
      : false

    return isEditMode
  }
}

export default connector
