import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"

import Breadcrumbs from "common/Breadcrumbs"
import Wrapper from "common/Wrapper"
import pageStore from "store/PageStore"

const BreadcrumbsBar: React.FC = () => {
  const classes = useStyles()
  const {
    state: { page }
  } = useStore(pageStore)

  if (page.data && page.data._parents.length < 1) {
    return null
  }

  return (
    <Wrapper containerClassName={classes.containerRoot}>
      <Breadcrumbs
        showCurrent={true}
        className={classes.breadcrumbsContainer}
      />
    </Wrapper>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  containerRoot: {
    padding: `${spacing(1)}px 0`
  },
  breadcrumbsContainer: {
    textAlign: "center",
    marginBottom: 0
  }
}))

export default BreadcrumbsBar
