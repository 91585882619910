import { forwardRef, PropsWithChildren } from "react"
import clsx from "clsx"

import { createStyles, makeStyles } from "@material-ui/core/styles"

type CarouselThumbnailsProps = {
  thumbnailPosition: "left" | "bottom"
}

const useStyles = makeStyles(({ spacing, breakpoints }) =>
  createStyles({
    thumbnails: {
      objectFit: "contain",
      [breakpoints.up("md")]: {
        maxWidth: "72px",
        marginRight: spacing(8)
      },
      [breakpoints.down("sm")]: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "12px"
      }
    },
    left: {
      [breakpoints.up("md")]: {
        order: -1
      },
      [breakpoints.down("sm")]: {
        order: "0!important"
      }
    },
    bottom: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      maxWidth: "none",
      "& button": {
        margin: `${spacing(4)} ${spacing(2)}`
      }
    }
  })
)

const CarouselThumbnails = forwardRef<
  HTMLDivElement,
  PropsWithChildren<CarouselThumbnailsProps>
>(({ thumbnailPosition, children }, ref) => {
  const classes = useStyles({ thumbnailPosition })
  return (
    <div
      ref={ref}
      className={clsx(classes.thumbnails, classes[thumbnailPosition])}
    >
      {children}
    </div>
  )
})

export default CarouselThumbnails
