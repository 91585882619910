import classNames from "classnames"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"
import withWidth, { isWidthDown, WithWidth } from "@material-ui/core/withWidth"

import Typography from "common/Typography"
import PageStore from "store/PageStore"
import LinkText from "./LinkText"

type OwnProps = {
  arrow?: boolean
  showCurrent?: boolean
  className?: string
}

type Props = OwnProps & WithWidth

const Arrow: React.FC<{ className: string }> = ({ className }) => (
  <span className={className}>/</span>
)

const Breadcrumbs: React.FC<Props> = ({
  arrow,
  showCurrent,
  className,
  width
}) => {
  const classes = useStyles()

  const {
    state: { page }
  } = useStore(PageStore)

  const parents = (page.data && page.data._parents) || []

  return parents ? (
    <div className={classNames(classes.root, className)}>
      {parents.map((link, index) => {
        const numberOfParents = showCurrent
          ? parents.length
          : parents.length - 1

        const breadCrumbsMob =
          isWidthDown("sm", width) && index + 1 < numberOfParents
            ? "..."
            : link.title

        return (
          <div key={index} className={classes.item}>
            <LinkText
              variant="body1"
              link={link.url}
              linkText={breadCrumbsMob}
              className={classNames(classes.list, {
                [classes.paddingLeftZero]: index === 0,
                [classes.paddingRightZero]: index === parents.length - 1
              })}
            />
            {index < parents.length - 1 &&
              ((arrow && <Arrow className={classes.slash} />) || (
                <Typography
                  variant="body2"
                  className={classes.slash}
                  color="textSecondary"
                >
                  /
                </Typography>
              ))}
          </div>
        )
      })}
      {showCurrent ? (
        <div className={classes.currentItem}>
          <Typography
            variant="body1"
            className={classes.slash}
            color="textSecondary"
          >
            {"/"}
          </Typography>
          <Typography variant="body1" className={classes.currentListItem}>
            {page.data && page.data.title}
          </Typography>
        </div>
      ) : null}
    </div>
  ) : null
}

const useStyles = makeStyles(({ common, spacing }) => ({
  root: {
    marginBottom: common.gridSpacing * 0.5,
    textAlign: "left"
  },
  item: {
    display: "inline-block"
  },
  slash: {
    display: "inline-block",
    fontSize: 12,
    color: common.themeColors.mediumGray,
    padding: `${spacing(1)}px`
  },
  list: {
    "& span": {
      textDecoration: "none",
      fontSize: 12,
      color: common.themeColors.mediumGray,
      "&:hover": {
        color: common.themeColors.mediumGray
      }
    }
  },
  currentItem: {
    display: "inline-block",
    textDecoration: "none",
    paddingLeft: 0,
    color: common.themeColors.gray
  },
  currentListItem: {
    display: "inline-block",
    textDecoration: "none",
    fontSize: 12,
    color: common.themeColors.darkerGray
  },
  paddingLeftZero: {
    paddingLeft: 0
  },
  paddingRightZero: {
    paddingRight: 0
  }
}))

export default withWidth()(Breadcrumbs)
