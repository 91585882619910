const UserAgents = {
  isIOS: false
}

const UserAgent = () => {
  const isIOSDevice =
    typeof window !== "undefined" &&
    window.navigator &&
    window.navigator.platform &&
    /iP(ad|hone|od)/.test(window.navigator.platform)

  isIOSDevice ? (UserAgents.isIOS = true) : (UserAgents.isIOS = false)

  return UserAgents
}

export const isIE = () => {
  const userAgent = window.navigator.userAgent
  const is_ie =
    userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1

  return is_ie
}

export default UserAgent
