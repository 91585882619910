import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Formik, FormikActions } from "formik"
import useStore from "global-hook-store"

import { makeStyles } from "@material-ui/core/styles"

import authStore from "store/AuthStore"
import snackbarStore from "store/SnackbarStore"
import validationSchema, {
  ResetPasswordFormType
} from "./ResetPasswordForm/validationSchema"
import ResetPasswordForm from "./ResetPasswordForm"

const initialValues = {
  userName: ""
}
const ResetPassword: React.FunctionComponent = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const {
    state: { resetPasswordResponse },
    actions: { resetPassword, resetResetPasswordResponse }
  } = useStore(authStore)

  const {
    actions: { openSnackbar }
  } = useStore(snackbarStore)

  useEffect(() => {
    if (!resetPasswordResponse.loading && resetPasswordResponse.data === "") {
      openSnackbar({
        options: { message: t("account.change_password_link_sent") },
        type: "success"
      })
      resetResetPasswordResponse()
    }
  }, [
    resetPasswordResponse.loading,
    resetPasswordResponse.data,
    openSnackbar,
    t,
    resetResetPasswordResponse
  ])

  const handleSubmit = async (
    values: ResetPasswordFormType,
    { setSubmitting, setStatus }: FormikActions<ResetPasswordFormType>
  ) => {
    setSubmitting(true)
    await resetPassword(values.userName)
    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur
      component={ResetPasswordForm}
      validationSchema={validationSchema(t)}
    />
  )
}

const useStyles = makeStyles(() => ({}))

export default ResetPassword
