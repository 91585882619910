import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { MenuItem } from "@starrepublic/epi/cms"

import { makeStyles } from "@material-ui/core/styles"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import TreeView from "@material-ui/lab/TreeView"

import { browserHistory } from "common/Root"
import Typography from "common/Typography"
import TreeViewMenuItem from "./TreeViewMenuItem"

const getUrlPathsArray = () =>
  window.location.pathname
    .split("/")
    .filter((a) => {
      return a.length
    })
    .map((_, index, initialValue) => {
      return `/${initialValue.slice(0, index + 1).join("/")}/`
    })
    .splice(1)

const renderMenuItem = (item: MenuItem) => (
  <TreeViewMenuItem
    key={item._id}
    nodeId={item.url}
    label={item.title}
    item={item}
    // eslint-disable-next-line react/no-children-prop
    children={
      item.children && item.children.length > 0
        ? item.children.map(renderMenuItem)
        : undefined
    }
  />
)

type TreeViewMenuProps = {
  items?: MenuItem[]
}

const TreeViewMenu = (props: TreeViewMenuProps) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [expanded, setExpanded] = useState(getUrlPathsArray())

  useEffect(
    () => {
      browserHistory.listen(() => {
        setExpanded(getUrlPathsArray())
      })
      setExpanded(getUrlPathsArray())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (!props.items) {
    return null
  }

  const handleToggle = (event: React.ChangeEvent, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  return (
    <>
      <Typography className={classes.navigationHeader} variant="headline5">
        {t("product.category_navigation_header")}
      </Typography>
      <TreeView
        className={classes.root}
        expanded={expanded}
        selected={window.location.pathname}
        defaultCollapseIcon={<RemoveIcon />}
        defaultExpandIcon={<AddIcon />}
        onNodeToggle={handleToggle}
      >
        {props.items.map((item) => {
          return renderMenuItem(item)
        })}
      </TreeView>
    </>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    flexGrow: 1,
    maxWidth: 400,
    marginBottom: spacing(2)
  },
  navigationHeader: {
    marginBottom: spacing(2)
  }
}))

export default TreeViewMenu
