import { createRef, useEffect } from "react"
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock"

import List from "@material-ui/core/List"
import { makeStyles } from "@material-ui/core/styles"

import UserAgent from "utils/UserAgent"

import { LineItem } from "models/Cart"
import CardItem from "./CardItem"

type Props = {
  lineItems: LineItem[]
  currency: string
  isFetching: boolean
  stockStatus?: boolean
  onItemClick?: () => void
}

const CardItemList: React.FC<Props> = ({
  lineItems,
  currency,
  stockStatus = false,
  isFetching,
  onItemClick
}) => {
  const classes = useStyles()
  const targetRef = createRef<HTMLDivElement>()
  const uA = UserAgent()

  useEffect(
    () => {
      if (uA.isIOS) {
        disableBodyScroll(targetRef.current as HTMLElement)
      }
      return () => {
        if (uA.isIOS) {
          clearAllBodyScrollLocks()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <div className={classes.root} ref={targetRef}>
      <List className={classes.list}>
        {lineItems &&
          lineItems.length &&
          lineItems.map((item, index) => (
            <CardItem
              key={`${item.code}-${index}`}
              item={item}
              currency={currency}
              stockStatus={stockStatus}
              isFetching={isFetching}
              onItemClick={onItemClick}
            />
          ))}
      </List>
    </div>
  )
}

const useStyles = makeStyles(({ spacing, common: { themeColors } }) => ({
  root: {
    flex: 1,
    overflowY: "auto"
  },
  list: {
    padding: 0,
    flex: 1,
    "& li": {
      borderBottom: `1px solid ${themeColors.lightGray}`
    },
    "& li:last-child": {
      marginBottom: spacing(3)
    }
  }
}))

export default CardItemList
