import { BlockProps } from "@starrepublic/epi/cms"
import classNames from "classnames"

import { makeStyles } from "@material-ui/core/styles"

import Button from "common/Button"
import LinkButton from "common/LinkButton"
import ThemeXhtmlString from "common/ThemeXhtmlString"
import Typography from "common/Typography"
import ArrowRight from "icons/ArrowRight"
import { ImageProductCarouselContent } from "./types"

type Props = BlockProps<ImageProductCarouselContent>

const positions = {
  top: "Top",
  bottom: "Bottom",
  right: "Right",
  left: "Left",
  center: "Center"
}

const TitleAndTextPosition: React.FC<Props> = ({ content }) => {
  const { imageTitle, body, contentPosition, linkText, link } = content
  const positionLeft =
    contentPosition && contentPosition.text === positions.left ? true : false

  const classes = useStyles()

  return (
    <>
      {imageTitle && (
        <div
          className={classNames({
            [classes.titleLeft]: !positionLeft,
            [classes.titleRight]: positionLeft
          })}
        >
          <Typography variant="headline4">{imageTitle}</Typography>
          <ThemeXhtmlString content={body || ""} />

          {linkText && link && (
            <LinkButton link={link}>
              <Button
                contrastColor={content.backgroundColor}
                endIcon={<ArrowRight />}
              >
                {linkText}
              </Button>
            </LinkButton>
          )}
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles(
  ({ breakpoints, spacing, common: { themeColors, gridSpacing } }) => ({
    titleRight: {
      marginLeft: spacing(6),
      maxWidth: 325,
      "& h4": {
        color: themeColors.white
      },
      "& > div p": {
        color: themeColors.white
      },
      [breakpoints.down("sm")]: {
        margin: spacing(2, 0, 0, 0),
        textAlign: "start",
        maxWidth: "none"
      }
    },
    titleLeft: {
      "& h4": {
        color: themeColors.white
      },
      marginRight: spacing(6),
      maxWidth: 325,
      "& > div p": {
        color: themeColors.white
      },
      [breakpoints.down("sm")]: {
        maxWidth: "none",
        margin: spacing(2, 0, 0, 0),
        textAlign: "start"
      }
    }
  })
)

export default TitleAndTextPosition
