type Props = {
  className?: string
}

const CircleFilled: React.FC<Props> = (props) => (
  <svg
    height="48"
    width="48"
    fill="currentColor"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="24" cy="24" r="24" />
  </svg>
)

export default CircleFilled
