import { MenuItem } from "@starrepublic/epi/cms"

import { makeStyles } from "@material-ui/core/styles"

import { useHeaderStore } from "store/HeaderStore"
import MenuFooter from "./MenuFooter"
import MenuHeader from "./MenuHeader"
import MenuList from "./MenuList"

type Props = {
  onItemClick?: () => void
  onClose: () => void
}

export type CurrentMenuItemType = {
  id?: string
  title?: string
  subMenuStack?: MenuItem[]
  parentItem?: CurrentMenuItemType
  url?: string
  rootTitle?: string
  menuType?: string
}

const ProductMenu: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles()

  const { currentMenuItem, set: headerSet } = useHeaderStore()

  const goToSubMenu = (item: MenuItem) => {
    headerSet.currentMenuItem({
      id: item._id,
      subMenuStack: item.children,
      parentItem: currentMenuItem,
      url: item.url,
      title: item.title,
      rootTitle: !currentMenuItem.parentItem
        ? item.title
        : currentMenuItem.rootTitle || currentMenuItem.title,
      menuType: item["menuType"]
    })
  }

  const goBack = () => {
    if (currentMenuItem.parentItem) {
      headerSet.currentMenuItem({
        ...currentMenuItem.parentItem
      })
    }
  }

  return (
    <div className={classes.root}>
      <MenuHeader
        hasBackBtn={currentMenuItem.parentItem ? true : false}
        onBackButtonClick={goBack}
        onCloseButtonClick={onClose}
        title={currentMenuItem.rootTitle}
      />
      <MenuList
        goToSubMenu={goToSubMenu}
        closeDrawer={onClose}
        items={currentMenuItem.subMenuStack}
        id={currentMenuItem.id}
        title={
          currentMenuItem.title !== currentMenuItem.rootTitle
            ? currentMenuItem.title
            : undefined
        }
        url={currentMenuItem.url}
        menuType={currentMenuItem.menuType}
      />
      <MenuFooter />
    </div>
  )
}

const useStyles = makeStyles(({ common: { themeColors } }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: themeColors.primaryBlue
  }
}))

export default ProductMenu
