import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"

type Props = SvgIconProps

const Remove: React.FC<Props> = (props) => (
  <SvgIcon
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 6h-4v2H7v2h10V8h-3V6zM14 16h-4v-4H8v5a1 1 0 001 1h6a1 1 0 001-1v-5h-2v4z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default Remove
