// useMediaQueries.ts
import useMediaQuery from "@material-ui/core/useMediaQuery"

export const useMediaQueries = () => {
  const isXsUp = useMediaQuery(({ breakpoints }) => breakpoints.up("xs"))
  const isXsDown = useMediaQuery(({ breakpoints }) => breakpoints.down("xs"))
  const isSmUp = useMediaQuery(({ breakpoints }) => breakpoints.up("sm"))
  const isSmDown = useMediaQuery(({ breakpoints }) => breakpoints.down("sm"))
  const isMdUp = useMediaQuery(({ breakpoints }) => breakpoints.up("md"))
  const isMdDown = useMediaQuery(({ breakpoints }) => breakpoints.down("md"))
  const isLgUp = useMediaQuery(({ breakpoints }) => breakpoints.up("lg"))
  const isLgDown = useMediaQuery(({ breakpoints }) => breakpoints.down("lg"))
  const isXlUp = useMediaQuery(({ breakpoints }) => breakpoints.up("xl"))

  return {
    isXsUp,
    isXsDown,
    isSmUp,
    isSmDown,
    isMdUp,
    isMdDown,
    isLgUp,
    isLgDown,
    isXlUp
  }
}
