import { makeStyles, Theme } from "@material-ui/core/styles"

import FormikFormControlField from "./FormikFormControlField"

type Item = {
  caption: string
  value: string
  checked: boolean
}

type Props = {
  theme: string
  name: string
  label: string
  items: Item[]
  allowMultiSelect: boolean
  caption?: string
  children?: React.ReactNode
}

type StyleProps = {
  _darkTheme: boolean
  _useDarkComponents: boolean
  _labelContrast: boolean
  _extraDarkLabel: boolean
}

const CustomChoiceSelection: React.FC<Props> = ({
  theme,
  name,
  items,
  label,
  children,
  allowMultiSelect
}) => {
  const dark = theme === "#103D82" || theme === "#3472D5"
  const useDarkComponents =
    theme === "#103D82" || theme === "#75CFFF" || theme === "#00A6FF"

  const labelContrast = theme === "#3472D5" || theme === "#103D82"
  const extraDarkLabel = theme === "#00A6FF"

  const classes = useStyles({
    _darkTheme: dark,
    _useDarkComponents: useDarkComponents,
    _labelContrast: labelContrast,
    _extraDarkLabel: extraDarkLabel
  })

  return (
    <FormikFormControlField
      theme={theme}
      className={classes.root}
      items={items}
      name={name}
      label={label}
      allowMultiSelect={allowMultiSelect}
    >
      {children}
    </FormikFormControlField>
  )
}

const useStyles = makeStyles<Theme, StyleProps>(
  ({ common: { themeColors } }) => ({
    root: {
      "& .MuiFormLabel-root": {
        color: ({ _darkTheme }) =>
          _darkTheme ? themeColors.white : themeColors.colorBaseBlue700
      },
      "& .MuiFormLabel-root > h6": {
        color: ({ _labelContrast, _extraDarkLabel }) =>
          _labelContrast
            ? themeColors.white
            : _extraDarkLabel
              ? themeColors.colorBaseBlue800
              : themeColors.colorBaseBlue700
      },
      "& .MuiFormControlLabel-label": {
        fontSize: 16,
        fontWeight: 400,
        fontFamily: "HelveticaNeue !important"
      },
      "& .MuiFormGroup-root": {
        color: ({ _darkTheme }) =>
          _darkTheme ? themeColors.white : themeColors.colorBaseGray800
      },
      "& .MuiButtonBase-root.MuiRadio-root, .MuiButtonBase-root.MuiCheckbox-root":
        {
          color: ({ _darkTheme, _useDarkComponents }) =>
            _darkTheme
              ? !_useDarkComponents
                ? themeColors.colorBaseBlue700
                : themeColors.colorBaseGray700
              : _useDarkComponents
                ? themeColors.colorBaseBlue700
                : themeColors.colorBaseGray700,
          "&:hover": {
            color: ({ _darkTheme, _useDarkComponents }) =>
              _darkTheme
                ? !_useDarkComponents
                  ? themeColors.colorBaseBlue700
                  : themeColors.primaryCyan
                : _useDarkComponents
                  ? themeColors.colorBaseBlue700
                  : themeColors.primaryCyan
          }
        },
      "& .MuiButtonBase-root.MuiRadio-root.Mui-checked, .MuiButtonBase-root.MuiCheckbox-root.Mui-checked":
        {
          color: ({ _darkTheme, _useDarkComponents }) =>
            _darkTheme
              ? !_useDarkComponents
                ? themeColors.colorBaseBlue700
                : themeColors.primaryCyan
              : _useDarkComponents
                ? themeColors.colorBaseBlue700
                : themeColors.primaryCyan
        },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: themeColors.colorBaseCyan700,
        borderWidth: "2px"
      }
    }
  })
)

export default CustomChoiceSelection
